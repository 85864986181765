import * as Sentry from '@sentry/browser';

export class SentryService {
  private dsn: string;
  private env: string;
  private denyUrls?: RegExp[]; // see https://docs.sentry.io/platforms/javascript/configuration/filtering/

  constructor(dsn: string, env?: string, denyUrls?: RegExp[]) {
    this.dsn = dsn;
    this.env = env || 'production';
    this.denyUrls = denyUrls;
  }

  public report = Sentry.captureException;

  public init = () =>
    Sentry.init({
      dsn: this.dsn,
      environment: this.env,
      denyUrls: this.denyUrls,
    });

  public captureException(err: any, context?: any) {
    Sentry.captureException(err, context);
  }

  public captureMessage(message: any) {
    Sentry.captureMessage(message);
  }
}
