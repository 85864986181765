import { KonakartService } from '../services/konakart';
import { couponStoreApi } from '../store/couponStore';
import { useOrderStore } from '../store/orderStore';
import { T_OrderRequest } from '../types';
import { useFreeProduct } from './useFreeProduct';

/**
 * For all subsequent calls other than the first updateOrder/updateCampaignOrder on load.
 * This also ensures the free product toast is called after each order
 * @param konakartService
 */
function useUpdateCart(konakartService: KonakartService, checkFreeProduct: boolean = true) {
  const { updateOrderMethod } = useOrderStore(s => ({
    updateOrderMethod: s.updateOrder,
  }));
  const { toggleCheckFreeProduct } = useFreeProduct(konakartService);

  const updateOrder = async (customerId: string, products: T_OrderRequest[]) => {
    await updateOrderMethod(konakartService, customerId, products, couponStoreApi.getState().coupons).then(() => {
      if (checkFreeProduct) {
        toggleCheckFreeProduct();
      }
    });
  };

  return { updateOrder };
}

export { useUpdateCart };
