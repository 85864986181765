import React from 'react';
import { Logo } from './skyLogo';
import cx from 'classnames';

interface Props {
  height?: number;
}

const ImgPlaceholder = ({ height }: Props) => {
  const parentClassName = cx('m-auto', { 'w-full px-8': !height });
  return (
    <div className="h-full w-full bg-gray-dark relative flex flex-col">
      <div className={parentClassName}>
        <Logo height={height} color="white" />
      </div>
    </div>
  );
};

export { ImgPlaceholder };
