import React from 'react';
import cx from 'classnames';

interface Props {
  hidden: boolean;
}

const MeshNotification = ({ hidden }: Props) => {
  const classes = cx('w-full', { hidden });
  return (
    <div className={classes}>
      <div className="flex pb-4 w-full">
        <p className="sky-label md:sky-label">
          Our Sky WiFi Routers have the latest WiFi 6 technology and we include use of a Sky WiFi Router with your Sky
          Broadband plan. Set up is super simple with our hassle-free setup guides.
        </p>
      </div>
    </div>
  );
};

export { MeshNotification };
