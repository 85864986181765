import { AxiosInstance } from 'axios';

export class BookATechService {
  private orderAgent: AxiosInstance;

  constructor(orderAgent: AxiosInstance) {
    this.orderAgent = orderAgent;
  }

  public getTechnicianSchedule = async (
    houseNumber: string | undefined = undefined,
    orderType: string | undefined = undefined,
    callCode: string | undefined = undefined,
    fromDate: Date | string,
    toDate: Date | string
  ) => {
    try {
      let response = await this.orderAgent.get<any>(
        `/order/tech-schedule?houseNumber=${houseNumber}&orderType=${orderType}&callCode=${callCode}&fromDate=${fromDate}&toDate=${toDate}`
      );
      return response.data;
    } catch (err) {
      console.log(err, 'from tech schedule api');
    }
  };

  // public postRescheduleBooking = async (
  //     accountNo: string,
  //     houseNumber: string,
  //     workOrderNumber: string,
  //     comments: string,
  //     timeSlotCode: string,
  //     startTime: string,
  // ) => {
  //   try{
  //     let response = await this.orderAgent.post<any>()
  //   }
  // }
}
