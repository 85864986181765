import { useEffect, useState } from 'react';
import { OrderService } from '../services/order';
import { VoiceService } from '../services/voice';

const COUNT = 8;

export function useNewPhoneNumber(
  voiceService: VoiceService,
  orderService: OrderService,
  tuiNumber: string,
  currentSelectedPhoneNumber = ''
) {
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getNewPhoneNumbers = async () => {
      const { locationId } = (await orderService.getLocationIdFromTui(tuiNumber)) || {};
      if (locationId) {
        const numbers = await voiceService.getPhoneNumberOptions({ locationId, count: COUNT });
        if (currentSelectedPhoneNumber.length > 0 && !numbers.includes(currentSelectedPhoneNumber)) {
          numbers[COUNT - 1] = currentSelectedPhoneNumber;
        }
        numbers.sort();
        setPhoneNumbers(numbers);
      }
      setLoading(false);
    };

    getNewPhoneNumbers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    phoneNumbers,
    loading,
  };
}
