import React, { ReactNode } from 'react';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Accordion } from '../Accordion';
import './style.css';

interface TermsAndConditionContentProps {
  content: TermsAndConditionContentType[];
}

export type TermsAndConditionContentType = {
  bgTitle: string;
  internalName: string;
  isExpandable: boolean;
  slug: string;
  subTitle?: string;
  title?: string;
  terms?: Document;
};

export const TermsAndConditionContent = ({ content }: TermsAndConditionContentProps) => {
  const tableProps = {
    style: { borderWidth: '1px' },
    className: 'border-collapse border-black text-left',
  };

  const tableBodyStyle = {
    style: { ...tableProps.style },
    className: `${tableProps.className} py-2 w-full md:w-4/5 lg:w-3/5`,
  };

  const renderOptions: Options = {
    renderText: (text: string) =>
      text
        .split('\n')
        .reduce(
          (children: any, textSegment: any, index: number) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),

    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
        <h1 className="py-2 mx-auto text-2xl md:text-3xl text-black">{children}</h1>
      ),
      [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => <p className="text-lg text-black">{children}</p>,
      [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
        <a href={node?.data?.uri} className="sky-text-daylight">
          {children}
        </a>
      ),
      [BLOCKS.OL_LIST]: (_, children: ReactNode) => {
        return content[0]?.slug === 'sky-rewards-terms' ? (
          <div className="px-8 pb-8 lg:px-0 container mx-auto container">
            <ol className="text-lg text-black orderedList">{children}</ol>
          </div>
        ) : content[0]?.slug === 'online-and-mobile-terms' ? (
          <ol className="text-lg text-black online-mobile-terms-orderedList">{children}</ol>
        ) : (
          <ol className="text-lg text-black orderedList">{children}</ol>
        );
      },
      [BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg text-black unorderedList">{children}</ul>,
      [BLOCKS.TABLE]: (_, children: ReactNode) => (
        <table {...tableBodyStyle}>
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_, children: ReactNode) => <th {...tableProps}>{children}</th>,
      [BLOCKS.TABLE_ROW]: (_, children: ReactNode) => <tr {...tableProps}>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (_, children: ReactNode) => <td {...tableProps}>{children}</td>,
      [BLOCKS.EMBEDDED_ENTRY]: (node: any) => <DisplayContents content={node?.data?.target?.fields} />,
    },
  };

  const Title = ({ text }: { text?: string }) =>
    text ? (
      <div className="px-4 py-18 sky-blue-gradient text-center text-white">
        <h3 className="sky-h3 md:sky-h3">{text}</h3>
      </div>
    ) : (
      <></>
    );

  const SubTitle = ({ text }: { text?: string }) =>
    text ? <div className="text-lg text-black text-center font-semibold">{text}</div> : <></>;

  const TermsContent = ({ terms }: { terms?: Document }) =>
    terms ? <>{documentToReactComponents(terms, renderOptions)}</> : <></>;

  const DisplayContents = ({
    content: { bgTitle, subTitle, terms, isExpandable, title },
  }: {
    content: TermsAndConditionContentType;
  }) => {
    const isEmbeddedContent = terms?.content?.some((c: any) => c?.nodeType === BLOCKS.EMBEDDED_ENTRY);
    return isExpandable ? (
      <div className="md:w-11/12 md:container mx-auto">
        <Accordion title={title!}>
          <div className="px-12 py-4 sky-bg-gray-lighter">
            <TermsContent terms={terms} />
          </div>
        </Accordion>
      </div>
    ) : (
      <>
        <Title text={bgTitle} />
        <SubTitle text={subTitle} />
        {bgTitle && !isEmbeddedContent ? (
          <div className="px-8 pb-8 lg:px-0 container mx-auto">
            <TermsContent terms={terms} />
          </div>
        ) : (
          <TermsContent terms={terms} />
        )}
      </>
    );
  };
  return (
    <div className="flex flex-col mx-auto justify-start gap-4">
      {(content ?? [])?.map((c, index) => (
        <DisplayContents key={`terms-${index}`} content={c} />
      ))}
    </div>
  );
};
