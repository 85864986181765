import { ConfirmationScreen, Loader, ErrorScreen } from '@sky-tv-group/components';
import {
  parseQuery,
  useAccountDetailsStore,
  useBroadbandDeliveryStore,
  useBroadbandStore,
  useCustomerStore,
  useAnalytics,
  orderStoreApi,
  PACKAGE_TYPE,
  categoryIds,
  couponStoreApi,
  PAYMENT_METHOD,
  useNewAcquisitionOrderStore,
  useboxesStore,
  errorScreenMsg,
  skyCrewContactlinks,
  errorScreenLinkTrext,
} from '@sky-tv-group/shared';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { konakartService, myAccountV2SWR, orderService } from '../../services';
import { PaymentCallback } from './payment.callback';

const Confirmation = () => {
  const { paymentURL, firstName, accountNumber, workOrderNumber, email } = useNewAcquisitionOrderStore();
  const { clearAccountDetails } = useAccountDetailsStore(s => ({
    accountDetails: s.accountDetails,
    clearAccountDetails: s.clearAccountDetails,
  }));
  const { clearBoxStore } = useboxesStore();
  const { clearBroadbandDeliveryStore } = useBroadbandDeliveryStore(s => ({
    clearBroadbandDeliveryStore: s.clearBroadbandDeliveryStore,
  }));
  const { clearBroadbandStore } = useBroadbandStore(s => ({
    clearBroadbandStore: s.clearBroadbandStore,
  }));

  const { getTempCustomerId } = useCustomerStore(s => ({
    setExistingCustomerId: s.setExistingCustomerId,
    getTempCustomerId: s.getTempCustomerId,
  }));
  let location = useLocation();
  let { DpsTxnRef } = parseQuery(location.search);
  const orderMsg = myAccountV2SWR.useGetOrderConfirmation();
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    /**
     * CLEAR ALL STORES
     */
    const clear = async () => {
      const customerId = await getTempCustomerId(konakartService);
      await konakartService.updateOrder(customerId, []);
      clearAccountDetails();
      clearBroadbandDeliveryStore();
      clearBroadbandStore();
      clearBoxStore();
    };
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pay = () => {
    window.location.href = paymentURL;
  };

  // Used for Analytics
  const { paymentInfoEntered } = useAnalytics();
  const couponStore = couponStoreApi.getState();
  const orderStore = orderStoreApi.getState();
  //search for any broadband products included in order
  const broadbandProducts = orderStore?.order?.orderProducts.filter(
    p => p.categoryId === categoryIds.broadband || p.categoryId === categoryIds.broadbandDevices
  );
  const packageType = sessionStorage.getItem('packageType');

  useEffect(() => {
    const trackPaymentInfoSegmentEvent = () => {
      let { callback } = parseQuery(location.search);
      //If a user successfully enters credit card details via payment express, then call this segment event
      if (callback === 'success') {
        paymentInfoEntered(
          workOrderNumber,
          false,
          PAYMENT_METHOD.CREDIT,
          orderStore.order,
          couponStore.coupons,
          packageType
        );
      }
    };
    trackPaymentInfoSegmentEvent();
    // eslint-disable-next-line
  }, []);

  let orderScreen;
  if (orderMsg.isValidating) {
    orderScreen = <Loader bgOpacity={false} />;
  }
  if (orderMsg.error) {
    orderScreen = (
      <ErrorScreen errorMsg={errorScreenMsg} linkText={errorScreenLinkTrext} linkURL={skyCrewContactlinks} />
    );
  }
  if (orderMsg?.data) {
    orderScreen = (
      <ConfirmationScreen
        firstName={firstName}
        accountNumber={accountNumber}
        email={email}
        data={orderMsg?.data?.data}
        orderService={orderService}
        packageType={packageType}
        setEmailError={setEmailError}
      />
    );
  }

  return (emailError?
    <>
      <div className=" margin-120">
        <ErrorScreen errorMsg={errorScreenMsg} linkText={errorScreenLinkTrext} linkURL={skyCrewContactlinks} />
      </div>
      <PaymentCallback />
    </>:
    <>
      <div className=" margin-120">{orderScreen}</div>
      <PaymentCallback />
    </>
  );
};

export { Confirmation };
