import React, { ComponentProps } from 'react';
import { animated, useTransition } from 'react-spring';
import { useLockBodyScroll } from '@sky-tv-group/shared';
import { default as RModal } from 'react-modal';
import { closex } from './icon';

interface BottomSheetProps {
  showCloseButton?: boolean;
  onRequestClose: () => void;
}

export const BottomSheet: React.FC<BottomSheetProps & ComponentProps<typeof RModal>> = ({
  showCloseButton = true,
  onRequestClose,
  children,
  ...rest
}) => {
  useLockBodyScroll();
  const transition = useTransition(rest?.isOpen, null, {
    from: { bottom: -100 },
    enter: { bottom: -50 },
    leave: { bottom: -100 },
    config: { duration: 300 },
  });
  return (
    <>
      {transition?.map(({ item, props, key }) =>
        item ? (
          <RModal
            key={key}
            overlayClassName="w-full min-h-screen flex flex-col justify-end items-center bg-black bg-opacity-50"
            className="w-full"
            shouldCloseOnOverlayClick={true}
            onRequestClose={onRequestClose}
            {...rest}>
            <animated.div style={props} className="relative">
              <div
                className="bg-white p-4 py-6 min-h-screen relative"
                style={{ borderTopLeftRadius: 24, borderTopRightRadius: 24 }}>
                {showCloseButton && (
                  <div className="absolute top-1 right-1 h-full z-1">
                    <button
                      onClick={onRequestClose}
                      className="text-3xl rounded-full text-white bg-blue-pure w-12 h-12 sticky top-1 z-10">
                      {closex}
                    </button>
                  </div>
                )}
                {showCloseButton ? <div className="relative">{children}</div> : children}
              </div>
            </animated.div>
          </RModal>
        ) : null
      )}
    </>
  );
};
