import cx from 'classnames';
import React from 'react';

export interface TextProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
  onChange: (value: string) => void;
  ref?: React.MutableRefObject<HTMLInputElement | null>;
}

/**
 * A Control text input element
 *
 * @example
 * // To pass error styles down:
 * <TextInput type="text" className="sky-input--error" />
 */
const TextInput = React.forwardRef<HTMLInputElement, TextProps>(({ onChange, className, ...props }: TextProps, ref) => {
  const classes = cx('sky-input sky-input--text sky-h7-reg rounded', className);
  return <input ref={ref} className={classes} {...props} onChange={event => onChange(event.currentTarget.value)} />;
});

export interface TextAreaProps extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'onChange'> {
  onChange: (value: string) => void;
}
/**
 * A Control text area element
 *
 * @example
 * // To pass error styles down:
 * <TextArea type="text" className="sky-input--error" />
 */
const TextArea = ({ onChange, className, ...props }: TextAreaProps) => {
  const classes = cx('sky-input sky-input--text sky-h7-reg rounded', className);
  return <textarea className={classes} {...props} onChange={event => onChange(event.currentTarget.value)} />;
};

export interface CheckboxProps
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'type' | 'value'
  > {
  onChange: (value: boolean) => void;
}

/**
 * A Control checkbox input element
 *
 * @param props
 * @param props.onChange a callback that gives true if the checkbox is checked
 */
const CheckboxInput = ({ onChange, checked, className, ...props }: CheckboxProps) => {
  const classNames = cx('sky-input sky-input--checkbox', className);
  return (
    <input
      className={classNames}
      {...props}
      checked={checked}
      type="checkbox"
      onChange={event => onChange(event.currentTarget.checked)}
    />
  );
};

export { TextInput, CheckboxInput, TextArea };
