export const homePhoneText = {
  labelText: {
    accountNumber: 'Enter the account number of your current home phone provider',
    haveExistingPhone: 'Do you have a home phone already?',
    enterPhoneNumber: "What's the number?",
    currentProviderSameAsBroadband: 'Is your current home phone provider the same as your current broadband provider?',
    selectCurrentHomeProvider: 'Who provides your home phone?',
    selectCurrentProviderTextInput: ' Enter your current home phone provider',
    keepCurrentPhoneNumber: 'Do you want to keep your current home phone number?',
    directoryListing: 'Directory listing',
    chooseNewNumber: 'Choose from one of these numbers',
    newNumberReservation:
      'We’ve reserved them for you for the next 10 minutes.  However, the chosen number is not guaranteed until connection is complete.',
    na: 'Not Applicable',
    naMsg: 'The phone number entered is not valid for your address',
    title: 'Home Phone',
  },
};
