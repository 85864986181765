import { useEffect, useState } from 'react';
import { T_Notification, NotificationService } from '@sky-tv-group/shared';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
interface useNotificationProps {
  notificationsEndpoint: string;
  isSignedIn?: boolean;
  domain: string;
  specialHandler?: string;
}

const useNotification = ({ notificationsEndpoint, isSignedIn, domain, specialHandler }: useNotificationProps) => {
  const [cookies, setCookie] = useCookies(['dn']);
  const [notifications, setNotifications] = useState<T_Notification[]>([]);
  let [dismissedNotifications, setDismissedNotifications] = useState<string>(cookies.dn ?? ''); // set the dismissedNotifications from localStorage
  let notificationService = new NotificationService(axios.create({ baseURL: notificationsEndpoint }));

  let currLocation = useLocation();

  // Get Notifications and set the notifications after Filtering them based on the user state (signed in or out). Scope = ["loggedIn", "loggedOut", "global"]
  useEffect(() => {
    notificationService.getNotifications().then(result => setNotifications(filterNotifications(result)));
    //eslint-disable-next-line
  }, [currLocation]);

  // Check whether the current page is the target of the notification
  let isTargetPage = (pages: string[]) => {
    return pages.some(page => window.location.href.split('?')[0].localeCompare(page) === 0);
  };
  let isExcludedPage = (pages: string[]) => {
    return pages.filter(Boolean).some(page => window.location.href.includes(page));
  };

  // Filter Notifications based on dissmised notifications, user state, special scenarios handler and page target
  let filterNotifications = (notifications: T_Notification[]) => {
    return notifications
      .filter(notif => !dismissedNotifications?.includes(notif.id)) // Filter notifications that are not dismissed
      .filter(notif => notif.scope !== (isSignedIn ? 'loggedOut' : 'loggedIn')) // Filter notifications based on the state of the user
      .filter(notif => (notif.specialHandler ? notif.specialHandler === specialHandler : notif)) // Filter notifications that matches the special handler (if not, just return the notification)
      .filter(notif => (notif.pageTarget[0] ? isTargetPage(notif.pageTarget) : notif))
      .filter(notif => !isExcludedPage(notif.pageExclude));
  };

  // Dismiss the notification
  let dismissNotification = (id: string) => {
    dismissedNotifications = dismissedNotifications !== null ? dismissedNotifications.concat(id) : id;
    setDismissedNotifications(dismissedNotifications);
  };

  // Dismiss the notification
  let engageNotification = (id: string, url: string) => {
    dismissNotification(id);
    window.location.href = url;
  };

  useEffect(() => {
    if (!dismissedNotifications) {
      return;
    }
    setNotifications(filterNotifications(notifications));
    setCookie('dn', dismissedNotifications, { domain });
    //eslint-disable-next-line
  }, [dismissedNotifications]);

  return { notifications, dismissNotification, dismissedNotifications, engageNotification };
};

export { useNotification };
