import get from 'lodash/get';
import { formatAddress, useNewAcquisitionOrderStore } from '@sky-tv-group/shared';
import { addressService, prequalService } from '../services';

export const useConsumeQueryAddressId = () => {
  const {
    setSelectedTuiAddress,
    setGuestPrequal,
    setSelectedAddressId,
    setSelectedInstallationAddress,
  } = useNewAcquisitionOrderStore();

  const handleQuery = async (addressId: string) => {
    /* Check if there's an addressId passed.
    E.g. http://localhost:3100/build-my-plan/tv-packages?addressId=1006721579
    */
    if (addressId) {
      try {
        setSelectedAddressId(addressId);

        const addressDetails = await addressService.getAddressDetail(addressId);
        const tui = get(addressDetails, 'references.tui', undefined);

        if (!tui) {
          throw new Error('Error');
        }

        setSelectedTuiAddress(tui);

        const prequal = await prequalService.getPrequalDetails(tui);
        if (prequal) {
          const selectedAddressLines = formatAddress(
            addressDetails.formatted_delivery_address.line1,
            addressDetails.formatted_delivery_address.line2,
            addressDetails.formatted_delivery_address.line3,
            addressDetails.formatted_delivery_address.line4
          );

          setGuestPrequal(prequal);

          /* Address label. */
          const addressLabel =
            addressDetails?.formatted_delivery_address?.line1 +
            ' ' +
            addressDetails?.formatted_delivery_address?.line2 +
            ' ' +
            addressDetails?.formatted_delivery_address?.line3 +
            ' ' +
            addressDetails?.formatted_delivery_address?.line4;

          setSelectedInstallationAddress(addressLabel, selectedAddressLines);
        }
      } catch (err) {
        console.log('Error');
      }
    }
  };

  return handleQuery;
};
