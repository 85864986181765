import { T_Coupon, T_Product } from '../../../types';
import {
  removeProductRemovesFreeProduct,
  removeProductRemovesFreeProductAndChargesItAtDefault,
  removeProductRemovesFreeProductAndChargesItAtPromotionalPricing,
} from '../helper/text';

/**
 * Scenario when removal is only for Free Product
 * @param productName
 * @param freeProductObject
 * @param initiallyAdded
 * @param couponObject
 */
export const onlyFreeProductRemoval = (
  productName: string,
  freeProductObject: Pick<T_Product, 'id' | 'name' | 'priceIncTax'>,
  initiallyAdded?: boolean,
  couponObject?: Pick<T_Coupon, 'custom1'>
): string => {
  // If we try to remove a product, that is associated with free product, but a campaign exists that adds the free product as a regular product
  // The offer is not associated with the product being removed, its associated with the free product
  // Free product wont be removed from the cart. It will just be charged at a promotional price instead.
  if (couponObject && isCouponAssociatedWithFreeProduct(couponObject, freeProductObject.id)) {
    return removeProductRemovesFreeProductAndChargesItAtPromotionalPricing(productName, freeProductObject.name);
  }

  // Removing a product that has no offer. But will remove free product if removed
  if (initiallyAdded) {
    return removeProductRemovesFreeProductAndChargesItAtDefault(
      productName,
      freeProductObject.name,
      freeProductObject.priceIncTax
    );
  } else {
    return removeProductRemovesFreeProduct(productName, freeProductObject.name);
  }
};

const isCouponAssociatedWithFreeProduct = (couponObject: Pick<T_Coupon, 'custom1'>, freeProductObjectId: number) => {
  return couponObject.custom1
    .split(',')
    .map(x => Number(x))
    .some(x => x === freeProductObjectId);
};
