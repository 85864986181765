import { formatMoneyWithoutSignSplit } from '@sky-tv-group/shared';
import React from 'react';

interface Props {
  price: number;
  productWasPrice?: number | any;
  containerClasses?: string;
  numberStyles?: string;
  left?: string;
  monthly?: boolean;
}

export const FancyPrice = React.memo(
  ({
    price,
    productWasPrice,
    numberStyles = 'md:sky-h1-black sky-h1 font-bold w-full',
    containerClasses = 'text-navy mb-2 relative mx-6',
    left = '100%',
    monthly = true,
  }: Props) => {
    return (
      <div className={containerClasses}>
        <div className="sky-h6-black absolute top-0" style={{ right: '100%' }}>
          {productWasPrice ? <span className="relative line-through left-[-10px]">${productWasPrice}</span> : null}
          <span className="sky-h6-black">$</span>
        </div>
        <h3 className={numberStyles} style={{ lineHeight: 0.8 }}>
          {formatMoneyWithoutSignSplit(price)[0]}
        </h3>
        <div className="absolute top-0" style={{ left: left }}>
          <h5 className="sky-h6">.{formatMoneyWithoutSignSplit(price)[1]}</h5>
        </div>
        <div className="absolute bottom-0" style={{ left: left }}>
          {monthly && <div className="text-xs">{'/mth'}</div>}
        </div>
      </div>
    );
  }
);
