import React, { useEffect } from 'react';
import { Field, Form } from 'react-jeff';
import cx from 'classnames';
import { TextInput } from '../input';
import { numberFilter } from '@sky-tv-group/shared';
import { SelectElement } from './selectValidated';

interface IPhoneNumberValidationProps {
  areaCodeField: Field<string, string>;
  areaCodeFieldContainerClasses?: string;
  areaCodeOptions: string[];
  lineNumberField: Field<string, string>;
  lineNumberMaxLength?: number;
  lineNumberFieldPlaceholderText?: string;
  lineNumberFieldContainerClasses?: string;
  id: string;
  form: Form<string>;
}

const ValidatedPhoneNumberDropdown: React.FunctionComponent<IPhoneNumberValidationProps> = ({
  areaCodeField,
  areaCodeFieldContainerClasses,
  areaCodeOptions,
  lineNumberField,
  lineNumberMaxLength,
  lineNumberFieldPlaceholderText,
  lineNumberFieldContainerClasses,
  id,
  form,
}) => {
  const refLineNumber = React.useRef<HTMLInputElement>(null);
  const errorAreaCodePresent = areaCodeField.errors.length > 0;
  const errorLineNumberPresent = lineNumberField.errors.length > 0;

  const inputAreaCodeClassName = cx('w-full sky-input--number', {
    'sky-input--error': errorAreaCodePresent,
  });
  const inputLineNumberClassName = cx('w-full sky-input--number', {
    'sky-input--error': errorLineNumberPresent,
  });

  const combinedErrors = [...areaCodeField.errors, ...lineNumberField.errors];
  const errorClassName = cx('', { 'text-error': combinedErrors.length });
  const areaCodeId = `${id}-phone-number-area-code`;
  const lineNumberId = `${id}-phone-number-line-number`;
  const areaCodeContainerClasses = areaCodeFieldContainerClasses
    ? `pr-2 ${areaCodeFieldContainerClasses}`
    : 'pr-2 w-4/12';
  const lineNumberContainerClasses = lineNumberFieldContainerClasses
    ? `pl-2 ${lineNumberFieldContainerClasses}`
    : 'pl-2 w-8/12';

  useEffect(() => {
    areaCodeField.props.onChange(areaCodeOptions[0]);
  }, [areaCodeOptions]);

  return (
    <>
      <div className="flex flex-row">
        <div className={areaCodeContainerClasses}>
          <SelectElement
            id={areaCodeId}
            className={inputAreaCodeClassName}
            {...areaCodeField.props}
            onChange={areaCodeField.props.onChange}
            fieldValidation={areaCodeField}
            formValidation={form}>
            {areaCodeOptions.map(f => (
              <option key={f} value={f}>
                {f}
              </option>
            ))}
          </SelectElement>
          {areaCodeField.errors.length !== 0 && <p className={errorClassName}>{areaCodeField.errors[0]}</p>}
        </div>
        <div className={lineNumberContainerClasses}>
          <TextInput
            id={lineNumberId}
            placeholder={lineNumberFieldPlaceholderText || '0000000'}
            className={inputLineNumberClassName}
            {...lineNumberField.props}
            onChange={numberFilter(lineNumberField.props.onChange)}
            maxLength={lineNumberMaxLength}
            ref={refLineNumber}
          />
          {lineNumberField.errors.length !== 0 && <p className={errorClassName}>{lineNumberField.errors[0]}</p>}
        </div>
      </div>
    </>
  );
};

export { ValidatedPhoneNumberDropdown };
