import React from 'react';
import cx from 'classnames';
import { CheckIcon, NextIcon } from '../icon';
import { IceBreadcrumb } from '@sky-tv-group/shared/src/types';
import { Link } from 'react-router-dom';

interface Props {
  crumbs: IceBreadcrumb[];
  current: number;
  progress: { [key: string]: boolean };
}

export let ProgressBar = ({ current, progress, crumbs }: Props) => {
  return (
    <div className="flex flex-row justify-center mt-2 items-center md:flex-wrap" data-testid="progess-bar">
      {crumbs.map(({ title, clickable, href }, index) => {
        let isCurrent = index === current;
        let isFinished = index < current;
        let isTodo = index > current;
        let isClickable = clickable && !isCurrent && progress[href];

        return (
          <div
            key={title}
            id={`progressbar-${title.replace(/ /g, '')}`}
            className={cx('flex sky-h6-reg items-center md:mr-3 p-1', {
              'txt-gray': isTodo,
              'text-blue-pure': isFinished || isClickable,
              'sky-text-midnight': isCurrent,
              'sky-h6-black': isCurrent,
            })}>
            <div
              className={cx(
                'h-8 md:h-6 w-8 md:w-6 mr-2 rounded-full text-center text-13px flex justify-center items-center',
                {
                  'bg-blue-pure': isFinished || isClickable,
                  'sky-bg-midnight text-white': isCurrent,
                  'border-2 border-gray-lightestBorder txt-gray': isTodo && !isClickable,
                }
              )}>
              {isFinished || isClickable ? <CheckIcon color="white" /> : <div className="mt-0.5">{index + 1}</div>}
            </div>
            <>
              {isClickable ? (
                <Link to={href} className="cursor-pointer hover:underline">
                  {/* Desktop Label */}
                  <span className="hidden lg:block ">{title}</span>
                  {/* Mobile Label */}
                  {isCurrent && <span className="lg:hidden pt-0.5">{title}</span>}
                </Link>
              ) : (
                <span>
                  {/* Desktop Label */}
                  <span className="hidden lg:block ">{title}</span>
                  {/* Mobile Label */}
                  {isCurrent && <span className="lg:hidden pt-0.5">{title}</span>}
                </span>
              )}
            </>
            {index < crumbs.length - 1 && (
              <div className="hidden lg:block ml-2 -mt-0.5 ">
                <NextIcon color={isFinished || isClickable ? '#0057FF' : '#A6A6A6'} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
