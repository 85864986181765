import React from 'react';
import { T_Product_LR_Popup } from '@sky-tv-group/shared';

export interface IWhatsHotCardProps {
  backgroundColorGradient: string;
  popUpCard?: T_Product_LR_Popup;
}

export const WhatsHotCard = ({ backgroundColorGradient, popUpCard }: IWhatsHotCardProps) => {
  return (
    <div
      className="w-full flex-none relative
                            xl:w-4/12 h-auto
                            md:w-6/12 md:flex-grow md:overflow-hidden group">
      <div className="object-cover w-full h-full">
        <img className="w-full h-full block" src={popUpCard?.popupCardImageURL} alt="" />
      </div>
      <div
        className={`absolute top-0 left-0 h-full w-full 
                    overflow-hidden ${backgroundColorGradient}
                    transform -translate-x-full group-hover:translate-x-0
                    transition duration-500 flex flex-col items-start`}>
        <div className="sky-text-white flex flex-col pt-4 px-4 overflow-auto h-85% mb-14">
          <h3 className="sky-h3-bold sm:sky-h5-bold md:sky-h5-bold">{popUpCard?.popupCardTitle}</h3>
          <h4 className="sky-h4-reg sm:sky-h6 md:sky-h6">{popUpCard?.popupCardSubtitle}</h4>
          <div className="mt-7 overflow-auto">
            <p className="sky-h5 sky-text-white">{popUpCard?.popupCardDescription}</p>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 mr-3 mb-3">
          <img className="h-auto w-16" src={popUpCard?.popupCardIconURL} alt="channel logo" />
        </div>
      </div>
    </div>
  );
};
