import { categoryIds, orderStoreApi, productSkuIds, T_OrderProduct } from '@sky-tv-group/shared';
import { BreadcrumbRoute, IceBreadcrumb } from '@sky-tv-group/shared/src/types';
import { IceRoute } from '../types';

const productIcons = [
  {
    sku: productSkuIds.broadbandLightningFastWiFi.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Broadband_Plan_Orange.png',
  },
  {
    sku: productSkuIds.broadbandLightningFastWiFiBoost.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Broadband_Plan_Orange.png',
  },
  {
    sku: productSkuIds.broadbandWifi100.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Broadband_Plan_Orange.png',
  },
  {
    sku: productSkuIds.broadbandWifi100Boost.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Broadband_Plan_Orange.png',
  },
  {
    sku: productSkuIds.skyRouter.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Sky_WiFi_Router_Orange.png',
  },
  {
    sku: productSkuIds.meshDevice.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Sky_WiFi_Booster_Orange.png',
  },
  {
    sku: productSkuIds.meshOccurrence.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Sky_WiFi_Booster_Orange.png',
  },
  {
    sku: productSkuIds.broadbandSelfInstall.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/DIY_Set-up_Self-install_Orange.png',
  },
  {
    sku: productSkuIds.broadbandBringYourOwnDevice.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Use_My_Own_Equipment_Orange.png',
  },
  {
    sku: productSkuIds.broadbandStaticIP.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Static_IP_Orange.png',
  },
  {
    sku: productSkuIds.voice.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Home_Phone_Orange.png',
  },
  {
    sku: productSkuIds.voiceAustraliaCalls.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Australia_Calls_Orange.png',
  },
  {
    sku: productSkuIds.voiceNationalCalls.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Mobile__National_Calls_Orange.png',
  },
  {
    sku: productSkuIds.voiceTop10Calls.primary,
    svg:
      'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/International_Calling_Top_10_Orange.png',
  },
  {
    sku: productSkuIds.voiceTop20Calls.primary,
    svg:
      'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/International_Calling_Top_20_Orange.png',
  },
];

/** Temporary helper to get Orange branded images. */
export function getProductOrangeImage(sku: string): string | undefined {
  return productIcons.find(productIcon => productIcon.sku === sku)?.svg;
}

export const hasProduct = (categoryId: number) => {
  return orderStoreApi.getState().order?.orderProducts.find(p => p.categoryId === categoryId) !== undefined;
};

export const isDeliveryOnly = () => {
  const orderProducts = orderStoreApi.getState().order?.orderProducts ?? [];

  return !!orderProducts.reduce((prev, curr) => {
    // If we've already determined a visit is required, don't bother checking again
    if (!prev) return prev;

    // If there are no boxes, don't worry about a delivery
    if (!orderProducts.filter(p => p.categoryId === categoryIds.box).length) return false;

    // If there is a box that isn't a skypod, then a visit is required
    if (curr.product.categoryId === categoryIds.box && curr.product.sku !== productSkuIds.skyPod.primary) {
      return false;
    }

    return true;
  }, true);
};

export const focusOnAddressSelector = () => {
  const addressSelectorInputField = document.getElementById('address');
  if (addressSelectorInputField) {
    addressSelectorInputField.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    addressSelectorInputField.focus({ preventScroll: true });
  }
};

export const isPlanSelected = (
  index: number,
  selectedBBProduct: T_OrderProduct | undefined,
  lfwProductSku: string,
  wifi100ProductSku: string
) => {
  if (selectedBBProduct === undefined) return false;

  if ((index === 0 || index === 1) && selectedBBProduct.product.sku === lfwProductSku) {
    return true;
  } else if (index > 1 && selectedBBProduct.product.sku === wifi100ProductSku) {
    return true;
  }

  return false;
};

// Additional validation around what is clickable may come, based on UX testing
export const formatBreadcrumbs = (routes: BreadcrumbRoute[]): IceBreadcrumb[] => {
  return routes.reduce((prev: IceBreadcrumb[], route: IceRoute) => {
    let clickable = false;
    let href = route.path;

    switch (route.path) {
      case '/tv-packages': {
        clickable = true;
        href = `/build-my-plan${route.path}`;
        break;
      }
      case '/sky-box': {
        clickable = true;
        href = `/build-my-plan${route.path}`;
        break;
      }
      case '/broadband': {
        href = `/build-my-plan${route.path}`;
        clickable = true;
        break;
      }
      case '/checkout': {
        href = `/build-my-plan${route.path}`;
        clickable = true;
        break;
      }
      case '/checkout/account-form': {
        href = '/checkout/account-form';
        clickable = true;
        break;
      }
      case '/checkout/dth-form': {
        href = '/checkout/dth-form';
        clickable = true;
        break;
      }
      case '../checkout/Confirm': {
        href = '/checkout/confirm';
        clickable = false;
        break;
      }
    }

    prev.push({
      title: route.title,
      href,
      clickable,
    });

    return prev;
  }, []);
};

export const isPlanSelectedBasedOnSku = (sku: string, selectedBBProduct: T_OrderProduct | undefined) => {
  if (selectedBBProduct === undefined) return false;

  let _sku = selectedBBProduct.product.sku;

  if (selectedBBProduct.product.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary) {
    _sku = productSkuIds.broadbandLightningFastWiFi.primary;
  } else if (selectedBBProduct.product.sku === productSkuIds.broadbandWifi100Boost.primary) {
    _sku = productSkuIds.broadbandWifi100.primary;
  } else if (selectedBBProduct.product.sku === productSkuIds.broadbandEssnFibBoost.primary) {
    _sku = productSkuIds.broadbandEssnFibre.primary;
  }

  return _sku === sku;
};
