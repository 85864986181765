import { categoryIds, productSkuIds } from '../config';
import { KonakartService } from '../services/konakart';
import { useProductStore } from '../store/productStore';
import { useCartContainer } from './useCartContainer';
import { T_Product, T_OrderProduct, CouponType } from '../types';
import { freeProductStoreApi } from '../store/freeProductStore';

function useRemoveProduct(konakartService: KonakartService, cartType: CouponType) {
  const { products } = useProductStore(s => ({ products: s.products }));
  const { toggleProduct } = useCartContainer(konakartService, cartType);

  /**
   * Use this when working with OrderProducts.
   * As order products for some reason do not have the depends on field for product. Which will not work on the dependencyCheck.
   * @param productId
   * @param update
   */
  const toggleCartProduct = async (productId: number, update: boolean = true): Promise<void> => {
    const product = products.find(p => p.id === productId);
    if (product != null) {
      return await toggleProduct(product, update);
    }
  };

  const isNotSkyStarter = (product: Pick<T_Product, 'sku' | 'custom3'>) => {
    return !(
      product.sku === productSkuIds.starter.primary && product.custom3 === productSkuIds.starter.customAttribute
    );
  };

  const isNotPaperBilling = (product: Pick<T_Product, 'sku'>) => {
    return product.sku !== productSkuIds.paperBilling.primary;
  };

  // Whether or not, the product is a box.
  const isNotABox = (product: Pick<T_Product, 'categoryId'>) => {
    return !(product.categoryId === categoryIds.box);
  };

  const isNotBroadbandProduct = (product: T_Product) => {
    return !(
      product.categoryId === categoryIds.broadbandServices || product.categoryId === categoryIds.broadbandDevices
    );
  };

  // Whether or not, the product is removable at review page.
  const canRemoveProductAtReviewPage = (product: T_Product, orderProducts: T_OrderProduct[]) => {
    // If it's not Starter and it's not a box, you can remove it.
    if (
      isNotSkyStarter(product) &&
      isNotABox(product) &&
      isNotPaperBilling(product) &&
      isNotBroadbandProduct(product) &&
      product.id !== freeProductStoreApi.getState().freeProduct?.productId
    ) {
      return true;
    }
    // If it's a box and there's more than 1 in the cart, you may remove it.
    const numberOfBoxes = orderProducts.filter(p => p.product.categoryId === categoryIds.box);
    if (!isNotABox(product) && isNotBroadbandProduct(product) && numberOfBoxes?.length > 1) {
      return true;
    }
    return false;
  };

  const canRemoveFromCart = (product: T_Product) => {
    // product in T_OrderProduct has no info of quantityBought
    // get quantityBought from productStore.products
    const productWithQuantity = products.find(p => p.id === product.id);
    const isProductNotAlreadyPurchased = (productWithQuantity?.quantityBought ?? 0) <= 0;
    // if user bought the package, cant remove it .
    return (
      isNotSkyStarter(product) &&
      isNotABox(product) &&
      isNotPaperBilling(product) &&
      isNotBroadbandProduct(product) &&
      product.id !== freeProductStoreApi.getState().freeProduct?.productId &&
      isProductNotAlreadyPurchased
    );
  };

  return {
    isNotSkyStarter,
    isNotBroadbandProduct,
    toggleCartProduct,
    canRemoveProductAtReviewPage,
    canRemoveFromCart,
  };
}

export { useRemoveProduct };
