import React from 'react';
import { Field } from 'react-jeff';
import cx from 'classnames';
import { TextInput } from '../input';
import { numberFilter } from '@sky-tv-group/shared';

interface IPhoneNumberValidationProps {
  areaCodeField: Field<string, string>;
  areaCodeFieldContainerClasses?: string;
  areaCodeFieldPlaceholderText?: string;
  lineNumberField: Field<string, string>;
  lineNumberFieldContainerClasses?: string;
  lineNumberFieldPlaceholderText?: string;
  onAreaCodeFieldChange: (value: string) => void;
  onLineNumberFieldChange: (value: string) => void;
  id: string;
  areaCodeMaxLength?: number;
  lineNumberMaxLength?: number;
  flowBothFields?: boolean;
}

const ValidatedPhoneNumber: React.FunctionComponent<IPhoneNumberValidationProps> = ({
  areaCodeField,
  areaCodeFieldPlaceholderText,
  areaCodeFieldContainerClasses,
  areaCodeMaxLength = 4,
  lineNumberMaxLength,
  lineNumberField,
  lineNumberFieldPlaceholderText,
  lineNumberFieldContainerClasses,
  onAreaCodeFieldChange,
  onLineNumberFieldChange,
  id,
  flowBothFields = false,
}) => {
  const refAreaCode = React.useRef<HTMLInputElement>(null);
  const refLineNumber = React.useRef<HTMLInputElement>(null);
  const errorAreaCodePresent = areaCodeField.errors.length > 0;
  const errorLineNumberPresent = lineNumberField.errors.length > 0;

  const inputAreaCodeClassName = cx('w-full sky-input--number', {
    'sky-input--error': errorAreaCodePresent,
  });
  const inputLineNumberClassName = cx('w-full sky-input--number', {
    'sky-input--error': errorLineNumberPresent,
  });

  const combinedErrors = [...areaCodeField.errors, ...lineNumberField.errors];
  const errorClassName = cx('', { 'text-error': combinedErrors.length });
  const areaCodeId = `${id}-phone-number-area-code`;
  const lineNumberId = `${id}-phone-number-line-number`;
  const areaCodeContainerClasses = areaCodeFieldContainerClasses
    ? `pr-2 ${areaCodeFieldContainerClasses}`
    : 'pr-2 w-4/12';
  const lineNumberContainerClasses = lineNumberFieldContainerClasses
    ? `pl-2 ${lineNumberFieldContainerClasses}`
    : 'pl-2 w-8/12';

  const KEY_CODES = {
    BACKSPACE: 8,
    TAB: 9,
    SHIFT: 16,
  };
  const onAreaCodeKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (refAreaCode.current?.value ?? '').length === areaCodeMaxLength &&
      event.keyCode !== KEY_CODES.BACKSPACE &&
      event.keyCode !== KEY_CODES.TAB &&
      event.keyCode !== KEY_CODES.SHIFT
    ) {
      refLineNumber.current?.focus();
    }
  };

  const onLineNumberKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (lineNumberField.props.value.length === 0 && event.keyCode === KEY_CODES.BACKSPACE) {
      refAreaCode.current?.focus();
    }
  };

  return (
    <>
      <div className="flex flex-row">
        <div className={areaCodeContainerClasses}>
          <TextInput
            id={areaCodeId}
            placeholder={areaCodeFieldPlaceholderText || '000'}
            className={inputAreaCodeClassName}
            {...areaCodeField.props}
            onChange={numberFilter(onAreaCodeFieldChange)}
            maxLength={areaCodeMaxLength}
            onKeyUp={flowBothFields ? onAreaCodeKeyUp : undefined}
            ref={refAreaCode}
          />
        </div>
        <div className={lineNumberContainerClasses}>
          <TextInput
            id={lineNumberId}
            placeholder={lineNumberFieldPlaceholderText || '0000000'}
            className={inputLineNumberClassName}
            {...lineNumberField.props}
            onChange={numberFilter(onLineNumberFieldChange)}
            maxLength={lineNumberMaxLength}
            onKeyDown={flowBothFields ? onLineNumberKeyDown : undefined}
            ref={refLineNumber}
          />
        </div>
      </div>
      {combinedErrors.length !== 0 && <p className={errorClassName}>{combinedErrors[0]}</p>}
    </>
  );
};

export { ValidatedPhoneNumber };
