import { SKY_REWARD_TEMPLATE_TYPE, BFriday } from '@sky-tv-group/shared';
import React from 'react';
import { MarketingRectangle } from './MarketingRectangle';

interface SkyRewardsTemplateProps {
  headline?: React.ReactNode;
  description?: JSX.Element;
  offerPrice?: number;
  rrpPrice?: number;
  templateType: SKY_REWARD_TEMPLATE_TYPE | null;
  couponCode?: string;
  discountText?: string;
}

export const SkyRewardsTemplate = ({
  headline,
  description,
  offerPrice,
  rrpPrice,
  templateType,
  couponCode,
  discountText,
}: SkyRewardsTemplateProps) => {
  const bgStyle =
    templateType === SKY_REWARD_TEMPLATE_TYPE.REWARD
      ? {
          background: `radial-gradient(90.31% 65.26% at 50% 20.74%, #254AB3 0%, rgba(0, 1, 58, 0.00) 100%), #00013A`,
        }
      : {
          backgroundImage: `url(${'https://static.sky.co.nz/sky/skymarketing/new-sky-box-bg-01.jpg'})`,
        };
  return (
    <>
      <div
        className="relative z-0 bg-no-repeat bg-auto rounded-lg"
        style={
          couponCode === BFriday.BFridayUpgarade.cCode
            ? { minHeight: '355px', ...bgStyle }
            : { minHeight: '373px', ...bgStyle }
        }>
        {templateType === SKY_REWARD_TEMPLATE_TYPE.REWARD && (
          <>
            <MarketingRectangle
              mRectColor={'linear-gradient(180deg, #0D1137 -116.59%, #364D9E -4.73%, #812DCD 99.54%)'}
              mRectTag={couponCode === BFriday.BFridayUpgarade.cCode ? 'Black Friday Special' : 'Special Offer'}
            />
            <img
              style={
                couponCode === BFriday.BFridayUpgarade.cCode
                  ? { height: '135px', width: '100%' }
                  : { height: '160px', width: '100%' }
              }
              className="pt-8 px-5"
              src={
                couponCode === BFriday.BFridayUpgarade.cCode
                  ? 'https://static.sky.co.nz/sky/skymarketing/NSB_Bfriday.png'
                  : 'https://static.sky.co.nz/sky/skymarketing/nSB_display_rewards.png'
              }
            />
          </>
        )}
        <div
          className={
            couponCode === BFriday.BFridayUpgarade.cCode
              ? 'z-1 text-white mx-5 mb-4 bottom-0'
              : 'absolute z-1 text-white mx-5 mb-4 bottom-0'
          }>
          <h3
            className={
              couponCode === BFriday.BFridayUpgarade.cCode
                ? 'sky-h5-bold font-skyBlack mb-1.5'
                : 'sky-h3-bold font-skyBlack mb-3'
            }>
            {headline}
          </h3>
          {(templateType === SKY_REWARD_TEMPLATE_TYPE.REWARD || templateType === SKY_REWARD_TEMPLATE_TYPE.ROI) && (
            <>
              {/* Dynamic description for Reward offers */}
              {templateType === SKY_REWARD_TEMPLATE_TYPE.REWARD && (
                <div className={couponCode === BFriday.BFridayUpgarade.cCode ? 'sm:sky-sub mb-1.5' : 'sm:sky-sub mb-3'}>
                  {description}
                </div>
              )}
              {/* Hard coded description for ROI offer */}
              {templateType === SKY_REWARD_TEMPLATE_TYPE.ROI && (
                <div className="sm:sky-sub mb-6 mr-12">
                  <p>
                    To thank you for registering your interest you’re eligible for a special offer when you upgrade to
                    the New Sky Box
                  </p>
                </div>
              )}
              <div className="flex flex-row flex-nowrap">
                {templateType === SKY_REWARD_TEMPLATE_TYPE.REWARD ? (
                  <>
                    <span
                      className="font-skyBlack "
                      style={
                        couponCode === BFriday.BFridayUpgarade.cCode
                          ? { fontSize: 16, lineHeight: '20px', right: '100%', top: 6 }
                          : { fontSize: 20, lineHeight: '24px', right: '100%', top: 6 }
                      }>
                      $
                    </span>
                    <span
                      className="font-skyBlack"
                      style={
                        couponCode === BFriday.BFridayUpgarade.cCode
                          ? { fontSize: 30, lineHeight: '30px' }
                          : { fontSize: 46, lineHeight: '56px' }
                      }>
                      <span className="flex flex-row items-start">{offerPrice}</span>
                      {discountText && couponCode === BFriday.BFridayUpgarade.cCode ? (
                        <MarketingRectangle
                          containerStyle={
                            couponCode === BFriday.BFridayUpgarade.cCode && screen.width < 668
                              ? { left: '2.5rem', top: '21rem' }
                              : screen.width < 1181
                              ? { left: '2.5rem', top: '17.5rem' }
                              : { left: '2.5rem', top: '16.5rem' }
                          }
                          mRectTag={discountText}
                          mRectColor="#9100D4"
                          mRectTagClasses={
                            couponCode === BFriday.BFridayUpgarade.cCode
                              ? 'text-white text-center sky-sub pl-2 pr-4'
                              : 'text-white text-center sky-h7-bold pl-2 pr-4'
                          }
                        />
                      ) : null}
                    </span>
                  </>
                ) : (
                  <h2 className="md:sky-h2-black sky-h2 font-bold leading-none mr-1.5" style={{ fontSize: '55px' }}>
                    ${offerPrice}
                  </h2>
                )}
                <div className="flex flex-col-reverse flex-nowrap">
                  {templateType === SKY_REWARD_TEMPLATE_TYPE.REWARD ? (
                    <div className="sky-text-xs">/one-off charge</div>
                  ) : (
                    <div className="sky-sub">
                      Price without <br></br> offer <span>${rrpPrice}</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {templateType === SKY_REWARD_TEMPLATE_TYPE.NON_REWARD && (
            <>
              <div className="sm:sky-sub mb-6 mr-12">
                <p>
                  The new SKy Box is here, offering the ultimate entertainment experience. Get yours today.
                  <br />
                  <br />
                  with a one-off payment of
                </p>
              </div>
              <div className="flex flex-col">
                <h2 className="md:sky-h2-black sky-h2 font-bold leading-none mr-1.5" style={{ fontSize: '55px' }}>
                  ${offerPrice}
                </h2>
              </div>
            </>
          )}
          {templateType === SKY_REWARD_TEMPLATE_TYPE.REWARD &&
            (couponCode === BFriday.BFridayUpgarade.cCode ? (
              <p className={couponCode === BFriday.BFridayUpgarade.cCode ? 'sky-text-xxs pb-3' : 'sky-text-xxs pb-6'}>
                Usual charge is ${rrpPrice ?? 200} Offer limited to 1 device only per account. Offer available until 27
                November or whilst stocks last.
                {BFriday.BFridayUpgarade.cCode ? (
                  <a href="https://www.sky.co.nz/blackfridayoffer#terms" className="sm:sky-sub mb-6 mr-12">
                    <u> Offer T&Cs </u> apply.
                  </a>
                ) : (
                  <a href="https://www.sky.co.nz/skyrewards-nsb-upgrade-terms" className="sm:sky-sub mb-6 mr-12">
                    <u> Offer T&Cs </u> apply.
                  </a>
                )}
              </p>
            ) : (
              <p className="sky-text-xxs">Usual charge is ${rrpPrice ?? 200}.</p>
            ))}
          {templateType === SKY_REWARD_TEMPLATE_TYPE.ROI && (
            <a href="https://www.sky.co.nz/skyrewards-nsb-upgrade-terms" className="sm:sky-sub mb-6 mr-12">
              Offer T&Cs apply
            </a>
          )}
        </div>
      </div>
    </>
  );
};

interface CustomerGuaranteeTemplateProps {
  logoUrl?: string;
  headline?: string;
  description?: JSX.Element;
}

export const CustomerGuaranteeTemplate = ({ logoUrl, headline, description }: CustomerGuaranteeTemplateProps) => {
  return (
    <>
      <div
        className="relative z-0 bg-no-repeat bg-auto rounded-lg"
        style={{
          backgroundImage: `url(${'https://static.sky.co.nz/sky/skymarketing/new-sky-box-bg-02.jpg'})`,
          minHeight: '370px',
          backgroundSize: '100%',
        }}>
        <MarketingRectangle
          mRectColor={'linear-gradient(180deg, #0D1137 -116.59%, #364D9E -4.73%, #812DCD 99.54%)'}
          mRectTag={'Exclusive'}
        />
        <div className="absolute z-1 text-white mx-5 mb-4 bottom-0">
          <div className="flex flex-row flex-nowrap mb-3 items-center">
            <div className="mr-3">
              <img className="h-8 w-8" src={logoUrl} alt="logo" />
            </div>
            <div className="sm:sky-sub font-bold pr-10">{headline}</div>
          </div>
          <div className="sky-sub mb-1">{description}</div>
        </div>
      </div>
    </>
  );
};
