import React, { useState } from 'react';
import { productSkuIds, T_Product, KonakartService, useLockBodyScrollOnValue } from '@sky-tv-group/shared';
import { Button } from '../button';
import { FancyPrice } from '../FancyPrice';
import { Aspect } from '../aspect';
import { useModal } from '../ExploreModal/useModal';
import ExploreModal from '../ExploreModal/ExploreModal';
import { VERTICAL_VIDEO_PLAYER_URL } from '../../config';
import { IPopUpContentConfig } from '@sky-tv-group/shared/src/types';
export interface IIcedPackageCardProps {
  kkService: KonakartService;
  product: T_Product;
  imageBackgroundClassName?: string;
  imageClassName?: string;
  toggleProduct: (product: T_Product) => Promise<void>;
  removable?: boolean;
  showVideo?: boolean;
  subscribed?: boolean;
  skyPriceChangePopUp?: IPopUpContentConfig[] | undefined;
  disabled?: boolean;
  handleToggle?: (inCart: boolean, product: T_Product) => Promise<void>;
  badge?: string;
  hideContract?: boolean;
}

const IcedPackageCard: React.FC<IIcedPackageCardProps> = ({
  kkService,
  product,
  toggleProduct,
  removable = true,
  showVideo = false,
  subscribed = false,
  skyPriceChangePopUp,
  disabled = false,
  handleToggle,
  badge,
  hideContract = false,
}) => {
  const isInCart = product.quantityInCart > 0;
  const skystarterid = product.sku === productSkuIds.starter.primary ? product.sku : '';
  // If already subscribed you can not remove
  removable = subscribed ? false : removable;
  const labelInCart = subscribed ? 'Subscribed' : removable ? 'Added' : 'Included';
  const preventRemove = !removable && product.quantityInCart > 0;
  const isPackage = (product.categoryName ?? '').includes('package');
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [isLoading, setIsLoading] = useState(false);

  const onProductToggle = async () => {
    setIsDisabled(true);
    setIsLoading(true);
    handleToggle && (await handleToggle(isInCart, product));
    setIsDisabled(false);
    setIsLoading(false);
  };

  const { showModal, toggleModal } = useModal();
  useLockBodyScrollOnValue(showModal);

  /* Sonarcloud: Ternary operators should not be nested.
  Previous ternary operator: isInCart || disabled ? (removable && !disabled ? 'pink' : 'disabled') : 'pure'
  */
  function getColorMode() {
    if (isInCart || disabled) {
      if (removable && !disabled) {
        return 'pink';
      } else {
        return 'disabled';
      }
    }
    return 'pure';
  }
  const showExplore = product?.showExplorePopup ? 'h-full py-3 relative' : ' h-full py-3 relative w-80%';
  return (
    <div className={showExplore}>
      {badge && (
        <div className="absolute w-16 z-10" style={{ right: 12, top: -20 }}>
          <img src={badge} />
        </div>
      )}
      <div
        className={`card border-#F2F2F2 flex flex-1 flex-col overflow-hidden rounded-lg h-full text-navy`}
        style={{ backgroundColor: '#FBFBFB' }}
        data-testid={`ice-package-card-${product.id}`}>
        <div className="">
          {skystarterid ? (
            <div>
              {!isPackage ||
                (showVideo && product?.videoUrl && (
                  <iframe
                    title={product?.videoUrl}
                    src={VERTICAL_VIDEO_PLAYER_URL + product?.videoUrl}
                    allow="encrypted-media"
                    className="w-full h-full hidden"></iframe>
                ))}
              {((!isPackage && !showVideo) || (showVideo && !product?.videoUrl)) && (
                <img alt={product.name} src={product.custom1} className="m-auto hidden"></img>
              )}
            </div>
          ) : (
            <Aspect ratio={9 / 16}>
              {showVideo && product?.videoUrl && (
                <iframe
                  title={product?.videoUrl}
                  src={VERTICAL_VIDEO_PLAYER_URL + product?.videoUrl}
                  allow="encrypted-media"
                  className="w-full h-full"></iframe>
              )}
              {(!showVideo || (showVideo && !product?.videoUrl)) && (
                <img alt={product.name} src={product.custom1} className="m-auto"></img>
              )}
            </Aspect>
          )}
        </div>
        <div
          className={`flex-1 p-5 flex rounded-t-none border-t-2 rounded-lg flex-col items-center justify-between ${
            subscribed || !isInCart ? 'border-2' : 'border-2'
          }`}>
          {/* Logo image URL. */}
          <div className="pb-4 flex justify-center items-center">
            {product.logoImageUrl && (
              <img alt={product.name} src={product.logoImageUrl} className={`w-auto ${isPackage ? 'h-8' : 'h-14'}`} />
            )}
            {!product.logoImageUrl && <div className="md:sky-h4-black sky-h5-black">{product.name}</div>}
          </div>
          {skystarterid ? (
            <div className="sky-h6-reg mb-0 text-center md:w-72" style={{ fontSize: '14px' }}>
              {product.description}
            </div>
          ) : (
            <div className="sky-h6-reg mb-5 text-center h-28 md:w-72">{product.description}</div>
          )}

          {/* Channel rail for a particular package. */}
          {skystarterid ? (
            <>
              {product.model === 'Channel' ? null : (
                <div className="flex 2xl:gap-4 lg:gap-1 gap-4 items-center pb-5 pt-5">
                  {product?.iconImageUrl?.map(
                    (d, index) =>
                      index < 5 && (
                        <div key={index} className="flex-col flex-1 w-12 h-12">
                          <img className="w-full h-full object-contain" src={d} alt={'Channel logo'} />
                        </div>
                      )
                  )}
                  <label className="textbox">& more</label>
                </div>
              )}
            </>
          ) : (
            <>
              {product.model === 'Channel' ? null : (
                <div className="flex 2xl:gap-4 lg:gap-1 gap-4 items-center pb-5 pt-10">
                  {product?.iconImageUrl?.map(
                    (d, index) =>
                      index < 5 && (
                        <div key={index} className="flex-col flex-1 w-12 h-12">
                          <img className="w-full h-full object-contain" src={d} alt={'Channel logo'} />
                        </div>
                      )
                  )}
                </div>
              )}
            </>
          )}
          {!skystarterid ? (
            <>
              {/* Contract. */}
              {hideContract || (
                <div>
                  {product.contractDescription && <p className="sm:sky-sub mb-5">{product.contractDescription}</p>}
                </div>
              )}
              <FancyPrice price={product.price0} numberStyles="md:sky-h1-black sky-h1 font-bold w-full text-68px" />
            </>
          ) : (
            <>
              <FancyPrice price={product.price0} numberStyles="md:sky-h1-black sky-h1 font-bold w-full text-68px" />
              {/* Contract. */}
              {hideContract || (
                <div>
                  {product.contractDescription && <p className="sm:sky-sub mb-1">{product.contractDescription}</p>}
                </div>
              )}
            </>
          )}
          {!skystarterid ? (
            <div className="flex flex-row justify-around">
              {product?.showExplorePopup && (
                <Button onClick={toggleModal} className="w-full" variant="secondary" colorMode="pure">
                  {product.linkLabel}
                </Button>
              )}
              {!skystarterid && (
                <div>
                  <Button
                    className="m-0"
                    colorMode={getColorMode()}
                    icon={isLoading ? 'loading' : isInCart ? (removable ? 'check' : undefined) : 'add'}
                    variant="primary"
                    data-testid={'add-product-' + product.sku}
                    onClick={onProductToggle}
                    disabled={isDisabled || preventRemove}>
                    {product.quantityInCart > 0 ? labelInCart : 'Add'}
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-row justify-around w-full">
              {/* Explore button. */}
              {product?.showExplorePopup && (
                <Button onClick={toggleModal} className="w-full" variant="secondary" colorMode="pure">
                  {product.linkLabel}
                </Button>
              )}
              {!skystarterid && (
                <div>
                  <Button
                    className="m-0"
                    colorMode={getColorMode()}
                    icon={isLoading ? 'loading' : isInCart ? (removable ? 'check' : undefined) : 'add'}
                    variant="primary"
                    data-testid={'add-product-' + product.sku}
                    onClick={onProductToggle}
                    disabled={isDisabled || preventRemove}>
                    {product.quantityInCart > 0 ? labelInCart : 'Add'}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ExploreModal
        isShown={showModal}
        kkService={kkService}
        toggleProduct={toggleProduct}
        hide={toggleModal}
        product={product}
        subscribed={subscribed}
        skyPriceChangePopUp={skyPriceChangePopUp}
        disabled={disabled}
      />
    </div>
  );
};

export { IcedPackageCard };
