import {
  couponStoreApi,
  CouponTypeValue,
  customerStoreApi,
  productSkuIds,
  productStoreApi,
  T_Product,
  useCartContainer,
  useCustomerStore,
  useNewAcquisitionOrderStore,
  useOrderStore,
} from '@sky-tv-group/shared';
import { useAuth0 } from '../store/authStore/authStore';
import { konakartService } from '../services';
import { v4 as uuidv4 } from 'uuid';

function useChoosePlan() {
  const { isAuthenticated } = useAuth0();
  const cartType = isAuthenticated ? CouponTypeValue.Upgrade : CouponTypeValue.Acquisition;
  const {
    meshDevice,
    routerDevice,
    broadband,
    broadbandProductInCart,
    toggleProduct,
    applyProduct,
    // starter,
    broadbandDiscounts,
    broadbandServices,
    broadbandOneOffFees,
  } = useCartContainer(konakartService, cartType, false);

  const {
    //   broadbandProductSelected,
    //   setBroadbandProductSelected,
    //   selectedIsSkyStarterCustomer,
    isUpdatingPlan,
    setIsUpdatingPlan,
    //   hasActiveOrPendingBroadbandPackage,
  } = useNewAcquisitionOrderStore();

  let { updateOrder, orderRequest } = useOrderStore();

  const { sessionId, setSessionId, isStaffAccount } = useCustomerStore(s => ({
    customerId: s.kk,
    sessionId: s.sessionId,
    setSessionId: s.setSessionId,
    isStaffAccount: s.isStaffAccount,
  }));

  const broadbandProducts = [...broadband, ...broadbandDiscounts];

  /** Broadband plans. */
  const plan1g = broadbandProducts.find(p => p.sku === productSkuIds.broadbandLightningFastWiFi.primary);
  const plan100m = broadbandProducts.find(p => p.sku === productSkuIds.broadbandWifi100.primary);
  const discount1g = broadbandProducts.find(p => p.sku === productSkuIds.broadbandStarterDiscountOneGig.primary);
  const discount100m = broadbandProducts.find(p => p.sku === productSkuIds.broadbandStarterDiscountHundredMeg.primary);

  const hasMeshDevice = meshDevice ? meshDevice.quantityInCart > 0 : false;

  const lfwProductSku = !hasMeshDevice
    ? productSkuIds.broadbandLightningFastWiFi.primary
    : productSkuIds.broadbandLightningFastWiFiBoost.primary;
  const wifi100Sku = !hasMeshDevice
    ? productSkuIds.broadbandWifi100.primary
    : productSkuIds.broadbandWifi100Boost.primary;

  const fibreStarterSku = !hasMeshDevice
    ? productSkuIds.broadbandEssnFibre.primary
    : productSkuIds.broadbandEssnFibBoost.primary;

  const lfwProduct = broadbandProducts.find(p => p.sku === lfwProductSku);
  const wifi100Product = broadbandProducts.find(p => p.sku === wifi100Sku);
  const fibreStarterProduct = broadbandProducts.find(p => p.sku === fibreStarterSku);

  const selfInstallService = broadbandServices.find(p => p.sku === productSkuIds.broadbandSelfInstall.primary);
  const selfInstallOTC = broadbandOneOffFees.find(p => p.sku === productSkuIds.broadbandSelfInstallOTC.primary);

  const toggleBroadbandProduct = async (product: T_Product) => {
    if (broadbandProductInCart) {
      if (product.id !== broadbandProductInCart?.productId) {
        // Toggle off the bb in cart silently and toggle the selected product.
        applyProduct(broadbandProductInCart.productId, 0);
        await toggleProduct(product, true, false);
      }
    } else {
      await toggleProduct(product, true, false);
    }
  };

  const selectProduct = async (product: T_Product | undefined, bundle?: boolean) => {
    if (product && !isUpdatingPlan) {
      setIsUpdatingPlan(true);

      // pass in coupon that is from session storage
      await updateOrder(
        konakartService,
        customerStoreApi.getState().kk,
        orderRequest?.length ? orderRequest : productStoreApi.getState().getBasketItemsToAddToOrder(isStaffAccount),
        couponStoreApi.getState().coupons
      );

      await toggleBroadbandProduct(product);

      setIsUpdatingPlan(false);
    }
  };

  /**
   * Add broadband to cart including default bb products (sky router and self-install service)
   */
  const getSkyBroadbandAction = (product?: T_Product, isBundle?: boolean) => {
    if (!broadbandProductInCart) {
      const defaultSku = product // if a product is passed, add that
        ? product.sku
        : productSkuIds.broadbandLightningFastWiFi.primary;

      const defaultProduct = broadband.find(p => p.sku === defaultSku);
      if (routerDevice && selfInstallService && selfInstallOTC) {
        applyProduct(routerDevice.id, 1);
        applyProduct(selfInstallService.id, 1);
        applyProduct(selfInstallOTC.id, 1);
      }

      if (defaultProduct) {
        // setBroadbandProductSelected(defaultProduct);
        selectProduct(defaultProduct, isBundle);
      }
      // For the old plan selector, when a user Get Sky Broadband Now, generate a session ID for it if it does not exist yet.  This is to be used later for determining whether this will become an abandoned cart.
      if (!sessionId) {
        setSessionId(uuidv4());
      }
    }
  };

  return {
    meshDevice,
    broadband,
    broadbandDiscounts,
    broadbandProducts,
    broadbandProductInCart,
    plan1g,
    plan100m,
    discount1g,
    discount100m,
    lfwProduct,
    lfwProductSku,
    wifi100Product,
    fibreStarterProduct,
    wifi100Sku,
    selfInstallService,
    selfInstallOTC,
    hasMeshDevice,
    getSkyBroadbandAction,
    selectProduct,
  };
}

export { useChoosePlan };
