import React from 'react';
import { errorPageIcon } from '../icon';
import { HEADER_HEIGHT } from '../header';

interface Props {
  headerText: string;
  lineOne: string;
  lineTwo: string;
}

const ErrorContainer = ({ headerText, lineOne, lineTwo }: Props) => {
  return (
    <div
      className="flex flex-col justify-center md:flex-row md:w-2/3 md:px-0 md:items-center md:m-auto px-4 h-screen align-middle self-center"
      style={{ height: `calc(100vh - ${HEADER_HEIGHT})` }}>
      <div className="md:w-1/2 w-full">
        <span className="flex algin-center justify-center">{errorPageIcon}</span>
      </div>
      <div className="md:w-1/2 w-full pt-8 md:pt-0">
        <h1 className="text-center md:text-left sky-h1 md:sky-h1 text-blue-light font-bold pb-8">{headerText}</h1>
        {lineOne && <h4 className="sky-h4 md:sky-h4 font-bold text-center md:text-left">{lineOne}</h4>}
        {lineTwo && <h4 className="sky-h4 md:sky-h4 font-bold text-center md:text-left">{lineTwo}</h4>}
      </div>
    </div>
  );
};

export { ErrorContainer };
