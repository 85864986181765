import { broadbandCoupons, voiceCategories } from '../../../config';
import { AddressPrequalification } from '../../../services/prequal';
import { IBroadbandInstallation } from '../../../store/broadbandStore';
import {
  Campaign,
  CouponTypes,
  Product,
  Service,
  ServiceOccurrence,
  ServiceStatus,
  T_Coupon,
  T_Tracking,
} from '../../../types';
import { VoiceDataForm } from '../../voiceFormFields';
import { BBPlanIndex } from '../dataServices/dataServiceBuilder';
import {
  BillingOccurrences,
  CustomerInfo,
  getAddedProducts,
  getCouponCampaign,
  isOneGigBroadbandPlan,
  ProductData,
} from '../helper';
import { buildDetailsForVoice } from './voiceOrderDetails';

export const setupVoiceServices = (
  voicePackages: ProductData[],
  voiceDetails: VoiceDataForm | undefined,
  prequalData: AddressPrequalification | undefined,
  broadbandDetails: Pick<IBroadbandInstallation, 'currentServiceProvider' | 'otherServiceProvider'> | undefined,
  tracking: T_Tracking | undefined,
  telephoneServiceOccurrence: ServiceOccurrence[] | undefined,
  currentlySubscribedBroadbandPackageSku: string | undefined,
  bbPlanIndex: BBPlanIndex
) => {
  let voiceServices: Service[] = [];

  const addedVoiceItem = getAddedProducts(voicePackages);
  const firstOccurrenceId = telephoneServiceOccurrence?.find(x => x.status === ServiceStatus.Active)?.occurrenceNumber;

  let actions = [
    {
      add: addedVoiceItem,
    },
  ];

  if (addedVoiceItem.length > 0 && voicePackages.length > 0) {
    voiceServices = [
      {
        ...(firstOccurrenceId ? { id: `${firstOccurrenceId}` } : null),
        actions: actions,
        details: [
          buildDetailsForVoice(
            voiceDetails,
            prequalData,
            voicePackages,
            broadbandDetails,
            tracking,
            currentlySubscribedBroadbandPackageSku,
            bbPlanIndex
          ),
        ],
      },
    ];
  }

  return voiceServices;
};

const createVoiceServiceProduct = (campaigns: Campaign[], voiceServices: Service[]): Product => {
  return {
    type: 'voice',
    campaigns: campaigns,
    services: voiceServices,
  };
};

export let buildVoiceServiceProduct = async (
  orderPackages: ProductData[],
  coupons: Pick<T_Coupon, 'description' | 'couponCode' | 'custom5'>[] | undefined,
  voiceDetails: VoiceDataForm | undefined,
  customer: CustomerInfo,
  getPrequalData: (addressId: string) => Promise<AddressPrequalification | undefined>,
  broadbandDetails: Pick<IBroadbandInstallation, 'currentServiceProvider' | 'otherServiceProvider'> | undefined,
  voiceServiceOccurrence: ServiceOccurrence[] | undefined,
  currentlySubscribedBroadbandPackageSku: string | undefined,
  billingServices: BillingOccurrences | undefined,
  hasActiveBroadbandPackage?: boolean,
  bbDetails?: T_Tracking
): Promise<Product> => {
  let campaigns: Campaign[] = getCouponCampaign(coupons, c => (c.custom5 === CouponTypes.Voice || c.custom5 ===CouponTypes.Broadband));
  let prequalData = await getPrequalData(customer.tuiAddressCode!);
  let voicePackages = orderPackages.filter(p => voiceCategories.includes(p.categoryId));

  const bbPlanIndex = isOneGigBroadbandPlan(hasActiveBroadbandPackage, billingServices, orderPackages);
  const voiceServices = setupVoiceServices(
    voicePackages,
    voiceDetails,
    prequalData,
    broadbandDetails,
    bbDetails,
    voiceServiceOccurrence,
    currentlySubscribedBroadbandPackageSku,
    bbPlanIndex
  );

  if (voiceServices.length > 0 && broadbandCoupons.voice) {
    campaigns = [...campaigns, broadbandCoupons.voice];
  }

  return createVoiceServiceProduct(campaigns, voiceServices);
};
