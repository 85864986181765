import { useEffect, useState } from 'react';

export const useModal = () => {
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }

  return {
    showModal,
    toggleModal,
  };
};

export const useEditModal = () => {
  const [showEditModal, setShowEditModal] = useState(false);

  function toggleEditModal() {
    setShowEditModal(!showEditModal);
  }

  return {
    showEditModal,
    toggleEditModal,
  };
};

export const useHash = (hide: any, isShown: boolean) => {
  useEffect(() => {
    if (isShown) {
      window.location.hash = 'explore';
      let listener: any = window.addEventListener(
        'hashchange',
        () => {
          if (window.location.hash.indexOf('#explore') === -1) {
            hide();
          }
        },
        false
      );
      return () => {
        window.removeEventListener('hashchange', listener);
      };
    } else if (!isShown) {
      window.history.pushState('', document.title, window.location.pathname + window.location.search);
    }
  }, [isShown]);
};
