import React from 'react';

const Aspect: React.FC<{ children: any; ratio: number }> = ({ children, ratio }) => {
  return (
    <div className="relative w-full" style={{ paddingBottom: `${ratio * 100}%` }}>
      <div className="absolute w-full h-full">{children}</div>
    </div>
  );
};

export { Aspect };
