import { useField } from 'react-jeff';

const initialReviewState = {
  termsAndConditionsAccepted: false,
  offerTermsAndConditionsAccepted: false,
  keyThingsToKnowField: false,
  getMarketingMessages: false,
  giveCreditCheckConsent: false,
};

export function termsAccepted(value: boolean) {
  const errors = [];
  if (!value) errors.push('You must accept these terms');
  return errors;
}

export function useTermsAndConditions() {
  return useField({
    defaultValue: initialReviewState.termsAndConditionsAccepted,
    required: true,
    validations: [termsAccepted],
  });
}

export function useOfferTermsAndConditions() {
  return useField({
    defaultValue: initialReviewState.offerTermsAndConditionsAccepted,
    required: true,
    validations: [termsAccepted],
  });
}

export function useKeyThingsToKnowField() {
  return useField({
    defaultValue: initialReviewState.keyThingsToKnowField,
    required: true,
    validations: [termsAccepted],
  });
}

export function useReceiveMarketingMessagesCheckbox() {
  return useField({
    defaultValue: initialReviewState.getMarketingMessages,
    required: false,
  });
}

export function useGiveCreditCheckPermission() {
  return useField({
    defaultValue: initialReviewState.giveCreditCheckConsent,
    required: true,
    validations: [termsAccepted],
  });
}
