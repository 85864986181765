import React, { useState } from 'react';
import { T_Product, useProductWithCouponRemoval, useToastContainer } from '@sky-tv-group/shared';
import { Button } from '../button';
import { BaseProductItem } from '../carouselPackages/BaseProductItem';
import { IPopUpContentConfig } from '@sky-tv-group/shared/src/types';

export interface ICarouselProductItemProps {
  product: T_Product;
  imageBackgroundClassName?: string;
  imageClassName?: string;
  toggleProduct: (product: T_Product) => Promise<void>;
  removable?: boolean;
  skyPriceChangePopUp?: IPopUpContentConfig[] | undefined;
}
const CarouselProductItem: React.FC<ICarouselProductItemProps> = ({
  product,
  toggleProduct,
  imageClassName,
  imageBackgroundClassName,
  removable = true,
  skyPriceChangePopUp,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { addToast } = useToastContainer();
  const handleToggle = async () => {
    setIsDisabled(true);
    if (!isInCart && skyPriceChangePopUp) {
      Object.entries(skyPriceChangePopUp)?.map(([key, popUp]) => {
        if (popUp.sku === product.sku) {
          addToast({
            title: popUp?.title,
            message: `${popUp?.bodyCopy} <a href="${popUp?.link}" target="_blank" class="text-blue-light underline">Find out more here</a>`,
            type: 'offer',
          });
        }
      });
    }
    await toggleProduct(product);
    setIsDisabled(false);
  };

  const isInCart = product.quantityInCart > 0;
  const labelInCart = removable ? 'Remove' : 'Selected';
  const preventRemove = !removable && product.quantityInCart > 0;
  return (
    <BaseProductItem
      product={product}
      imageClassName={imageClassName}
      imageBackgroundClassName={imageBackgroundClassName}>
      <div className="flex-col flex items-center mb-10">
        <Button
          colorMode={isInCart ? 'success' : 'light'}
          variant={isInCart ? 'primary' : 'secondary'}
          data-testid={'add-product-' + product.sku}
          onClick={handleToggle}
          disabled={isDisabled || preventRemove}>
          {product.quantityInCart > 0 ? labelInCart : 'Add'}
        </Button>
        <div className="p-1">
          <a href={product.url} className="text-blue-light hover:text-navy sky-h7-bold">
            Learn more
          </a>
        </div>
      </div>
    </BaseProductItem>
  );
};

export { CarouselProductItem };
