import { useState, useEffect } from 'react';
import * as Fields from './voiceFormFields';
import { Field, useForm } from 'react-jeff';
import { MyAccountSWR } from '../swr/myaccount';
import { ServiceProvider, ServiceProviderService } from '../services/serviceProvider';
import { useSplitIO } from './useSplitIO';
import { SPLITIO_KEY } from '../config';
import { useManagePackage } from './useManagePackage';
import { useVoiceStore } from '../store/voiceStore';
import { useBroadbandStore } from '../store/broadbandStore';
import { AddressPrequalification } from '../services/prequal';
import { HomePhoneFormFieldProps } from './voiceFormFields';
import { useBroadbandDeliveryStore } from '../store/broadbandDeliveryStore';

function useVoiceDetails(
  myAccountSWR: MyAccountSWR,
  serviceProviderService: ServiceProviderService,
  confirmOrder: () => void
) {
  let { hasActiveBroadbandPackage } = myAccountSWR.usePackages();
  const [directoryListingEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_VOICE_DIRECTORY_LISTING);
  const [providers, setProviders] = useState<ServiceProvider[]>([]);
  let { data: customer } = myAccountSWR.useCustomer();
  let [submitted, setSubmitted] = useState(0);

  const { broadbandInCart, voiceInCart } = useManagePackage(myAccountSWR);

  const { details, setDetails, setIsValid } = useVoiceStore();
  const { details: bbDetails } = useBroadbandStore();
  let [prequalData] = useBroadbandDeliveryStore(s => [s.prequalData]);
  // must have prequal at this stage
  let prequal = new AddressPrequalification(prequalData?.data);

  const currentProviderInputField = Fields.useCurrentProviderInputField(details.currentProvider);
  const directoryListingDropDownField = Fields.useDirectoryListingDropDownField(details.directoryListing);
  const existingHomePhoneDropDownField = Fields.useExistingHomePhoneDropDownField(details.isUsingExistingPhone);
  const keepSamePhoneNumberDropDownField = Fields.useKeepSamePhoneNumberDropDownField(
    details.isKeepExistingPhoneNumber
  );
  const phoneNumberAreaCodeInputField = Fields.usePhoneNumberAreaCodeInputField(details.phoneNumberAreaCode);
  const phoneNumberLineNumberInputField = Fields.usePhoneNumberLineNumberInputField(details.phoneNumberLineNumber);
  const currentProviderDropdownField = Fields.useCurrentProviderDropdownField(details.currentProviderDropDown);
  const phoneNumberSelectList = Fields.usePhoneNumberSelectList(details.phoneNumberFromList);
  const notShowPhoneProviderMatchBroadbandDropDownField =
    hasActiveBroadbandPackage || prequal.isNewFibreJourney() || prequal.isIntactONTJourney();
  const phoneProviderMatchBroadbandDropDownField = Fields.usePhoneProviderMatchBroadbandDropDownField(
    notShowPhoneProviderMatchBroadbandDropDownField ? 'No' : details.isPhoneProviderMatchBroadband
  );
  const accountNumberField = Fields.useAccountNumberField(details.accountNumber);

  enum Options {
    Yes = 'Yes',
    No = 'No',
    Other = 'Other',
  }

  function hasExistingNumber(): boolean {
    return existingHomePhoneDropDownField.props.value === Options.Yes;
  }

  function hasSameProvider(): boolean {
    return phoneProviderMatchBroadbandDropDownField.props.value === Options.Yes;
  }

  function keepSameNumber(): boolean {
    return keepSamePhoneNumberDropDownField.props.value === Options.Yes;
  }
  function dontKeepSameNumber(): boolean {
    return keepSamePhoneNumberDropDownField.props.value === Options.No;
  }

  function otherPhoneProvider(): boolean {
    return currentProviderDropdownField.props.value === Options.Other;
  }

  function requireAccountNumber(): boolean {
    return (
      phoneProviderMatchBroadbandDropDownField.props.value === 'No' ||
      (!!broadbandInCart && !!voiceInCart && phoneProviderMatchBroadbandDropDownField.props.value === 'Yes')
    );
  }

  function allowDirectoryListing(): boolean {
    if (directoryListingEnabled) {
      return !hasExistingNumber() || (hasExistingNumber() && !keepSameNumber());
    }
    return false;
  }

  useEffect(() => {
    if (
      !!broadbandInCart &&
      !!voiceInCart &&
      phoneProviderMatchBroadbandDropDownField.props.value === 'Yes' &&
      !details.accountNumber
    ) {
      accountNumberField.setValue(bbDetails?.providerAccountNumber ?? '');
    }
    // eslint-disable-next-line
  }, [broadbandInCart, voiceInCart, phoneProviderMatchBroadbandDropDownField.props.value]);

  let showHomePhoneSelector = !hasExistingNumber() || (hasExistingNumber() && dontKeepSameNumber());

  const homePhoneFields: HomePhoneFormFieldProps = {
    currentProviderInputField,
    directoryListingDropDownField,
    existingHomePhoneDropDownField,
    keepSamePhoneNumberDropDownField,
    phoneNumberAreaCodeInputField,
    phoneNumberLineNumberInputField,
    phoneProviderMatchBroadbandDropDownField,
    currentProviderDropdownField,
    phoneNumberSelectList,
    accountNumberField,
  };

  useEffect(() => {
    setDetails({
      isUsingExistingPhone: existingHomePhoneDropDownField.props.value,
      phoneNumberAreaCode: phoneNumberAreaCodeInputField.props.value,
      phoneNumberLineNumber: phoneNumberLineNumberInputField.props.value,
      isPhoneProviderMatchBroadband: phoneProviderMatchBroadbandDropDownField.props.value,
      currentProvider: currentProviderInputField.props.value,
      currentProviderDropDown: currentProviderDropdownField.props.value,
      isKeepExistingPhoneNumber: keepSamePhoneNumberDropDownField.props.value,
      directoryListing: directoryListingDropDownField.props.value,
      phoneNumberFromList: phoneNumberSelectList.props.value,
      accountNumber: accountNumberField.props.value,
    });
  }, [
    setDetails,
    currentProviderInputField.props.value,
    directoryListingDropDownField.props.value,
    existingHomePhoneDropDownField.props.value,
    keepSamePhoneNumberDropDownField.props.value,
    phoneNumberAreaCodeInputField.props.value,
    phoneNumberLineNumberInputField.props.value,
    phoneProviderMatchBroadbandDropDownField.props.value,
    currentProviderDropdownField.props.value,
    phoneNumberSelectList.props.value,
    accountNumberField.props.value,
  ]);

  const toReview = async () => {
    await form.submit();
  };

  let formFields: Field<any, any>[] = [existingHomePhoneDropDownField];
  if (allowDirectoryListing()) {
    formFields.push(directoryListingDropDownField);
  }
  if (hasExistingNumber()) {
    formFields.push(keepSamePhoneNumberDropDownField);
    if (keepSameNumber()) {
      formFields.push(accountNumberField);
      formFields.push(phoneNumberAreaCodeInputField);
      formFields.push(phoneNumberLineNumberInputField);
      formFields.push(phoneProviderMatchBroadbandDropDownField);
      if (!hasSameProvider()) {
        formFields.push(currentProviderDropdownField);
      }
    } else {
      formFields.push(phoneNumberSelectList);
    }
  } else {
    formFields.push(phoneNumberSelectList);
  }
  if (otherPhoneProvider() && keepSameNumber()) {
    formFields.push(currentProviderInputField);
  }

  let form = useForm({
    fields: formFields,
    onSubmit: async () => {
      // need to validate form
      // fix the bug where user come back from review page, and click the submit button without updating any fields.
      // https://jira.skytv.co.nz/browse/WEBDXP-2784
      // form.valid wont be updated right after form.validate() is called, so need to wait for next render pass.
      await form.validate();
      setSubmitted(submitted + 1);
    },
  });

  useEffect(() => {
    if (submitted > 0 && form.valid) {
      confirmOrder();
    }
    // eslint-disable-next-line
  }, [submitted]);

  useEffect(() => {
    setIsValid(form.valid);
  }, [form.valid, setIsValid]);

  useEffect(() => {
    async function getData() {
      let data: ServiceProvider[] = await serviceProviderService.getVoiceProviders();
      setProviders(data);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  return {
    form,
    providers,
    homePhoneFields,
    customer,
    hasExistingNumber,
    hasSameProvider,
    keepSameNumber,
    otherPhoneProvider,
    requireAccountNumber,
    broadbandInCart,
    toReview,
    showHomePhoneSelector,
    notShowPhoneProviderMatchBroadbandDropDownField,
  };
}

export { useVoiceDetails };
