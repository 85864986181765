import create from 'zustand';

import { ReduxDevTools } from '../helpers/storeLogger';
import { LocalStatePersistence } from '../helpers/storePersister';
import { T_Coupon, T_Product } from '../types';
import { localStorage } from '../helpers/localStorage';
import { removeCacheCoupons } from '../config';

// the store for managing products in the cart
interface CouponStore {
  coupons: T_Coupon[];
  applyCouponToStore: (coupon: T_Coupon) => void;
  applyMultipleCouponsToStore: (coupon: T_Coupon[]) => void;
  clearCoupon: (coupon: T_Coupon, track?: boolean) => void;
  clearCouponFromCode: (couponCode: string, track?: boolean) => void;
  getCouponForProduct: (product: Pick<T_Product, 'id'>) => T_Coupon | undefined;
  hasSameCouponTypeAndCategory: (coupon: T_Coupon) => boolean;
  removeAllCoupons: () => void;
  getCouponFromCode: (couponCode: string) => T_Coupon | undefined;
}

const storageStateName = 'Coupon Store';
let getLocalData = JSON.parse(localStorage.getItem(storageStateName) ?? '{}');
const { coupons } = getLocalData;

const updatedCoupons = coupons?.filter(
  (item: { couponCode: string }) => !removeCacheCoupons?.includes(item.couponCode)
);
getLocalData.coupons = updatedCoupons;
localStorage.setItem(storageStateName, JSON.stringify(getLocalData));

const getStorageState = JSON.parse(localStorage.getItem(storageStateName) ?? '{}');
/**
 * Responsible for maintaining knowledge of the coupon that will be tied to konakart store. Adds to  storage.
 * IMPORTANT: Does not do any update to konakart. That should be done via order store.
 */
const [useCouponStore, couponStoreApi] = create<CouponStore>(
  ReduxDevTools(
    LocalStatePersistence(
      (set, get) => ({
        coupons: [],
        ...getStorageState,

        applyCouponToStore: newCoupon => {
          const { coupons } = get();

          // if coupon already in store, do nothing
          if (coupons.find(cp => cp.id === newCoupon.id)) {
            return;
          }

          set({ coupons: [...coupons, newCoupon] }, `apply ${newCoupon.couponCode} to store`);
        },
        applyMultipleCouponsToStore: newCoupon => {
          newCoupon?.filter((item: { couponCode: string }) => !removeCacheCoupons?.includes(item.couponCode));
          set({ coupons: [...newCoupon] }, `apply multiple coupons to store`);
        },

        clearCoupon: (couponToRemove, track = true) => {
          const { coupons } = get();
          const coupon = coupons.find(cp => cp.id === couponToRemove.id);

          if (!coupon) {
            return;
          }

          set(
            { coupons: coupons.filter(cp => cp.couponCode !== couponToRemove.couponCode) },
            `clear ${couponToRemove.couponCode} coupon`
          );
        },

        clearCouponFromCode: (couponCode, track = true) => {
          const { coupons } = get();
          const coupon = coupons.find(cp => cp.couponCode === couponCode);

          if (!coupon) {
            return;
          }

          set({ coupons: coupons.filter(cp => cp.couponCode !== couponCode) }, `clear ${couponCode} coupon`);
        },

        removeAllCoupons: () => {
          set({ coupons: [] }, 'remove all coupons');
        },

        getCouponForProduct: product => {
          const { coupons } = get();
          return coupons.find(cp =>
            cp.custom1
              .split(',')
              .map(id => Number(id))
              .includes(product.id)
          );
        },

        getCouponFromCode: couponCode => {
          const { coupons } = get();
          return coupons.find(c => c.couponCode === couponCode);
        },

        hasSameCouponTypeAndCategory: coupon => {
          const { coupons } = get();
          // custom3 - ACQUISITION | UPGRADE
          // custom5 - CABLE | BROADBAND | VOICE
          return coupons.some(
            existing =>
              existing.custom3.toLowerCase() === coupon.custom3.toLowerCase() &&
              existing.custom5.toLowerCase() === coupon.custom5.toLowerCase()
          );
        },
      }),
      storageStateName
    ),
    'Coupon Store'
  )
);

export { useCouponStore, couponStoreApi };
