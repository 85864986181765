import { format } from 'date-fns';
import {
  broadbandCategories,
  broadbandProvisioningSkus,
  categoryIds,
  productSkuIds,
  broadbandDiscounts,
} from '../../../config';
import { AddressService } from '../../../services/address';
import { AddressPrequalification } from '../../../services/prequal';
import { TrackerService } from '../../../services/tracker';
import { IBroadbandDelivery } from '../../../store/broadbandDeliveryStore';
import { IBroadbandInstallation } from '../../../store/broadbandStore';
import { checkProductIsBroadbandMeshPackage } from '../../../swr/helper';
import {
  Campaign,
  CouponTypes,
  ServiceOccurrence,
  T_Coupon,
  BroadbandService,
  BroadbandProduct,
  ServiceStatus,
  Services,
  T_Tracking,
} from '../../../types';
import { ProductData, getCouponCampaign, getAddedProducts, getRemovedBroadbandPackage, CustomerInfo } from '../helper';
import { isVoiceAdded } from '../voiceServices/voiceOrderDetails';
import { BroadbandActions, buildActions, RemoveAddons } from './broadbandOrderActions/broadbandOrderActions';
import { getProductKey } from './broadbandOrderDetails/broadbandOrderDetails';
import {
  buildDeliveryDetails,
  DeliveryDetails,
} from './broadbandOrderDetails/buildDeliveryDetails/buildDeliveryDetails';
import { buildDetails, Details } from './broadbandOrderDetails/buildDetails/buildDetails';
import {
  buildNonDeliveryDetails,
  NonDeliveryDetails,
} from './broadbandOrderDetails/buildNonDeliveryDetails/buildNonDeliveryDetails';

export type BBPlanIndex =
  | 0 // fibre pro
  | 1 // fibre every
  | 2; //fibre starter

export const setupDataServices = (
  details: Pick<IBroadbandInstallation, 'installationDate' | 'installationTime'> | undefined,
  hasPerfectInstall: boolean,
  refinedActions: BroadbandActions | undefined,
  refinedDetails: Details[] | DeliveryDetails[] | NonDeliveryDetails[],
  dataServiceOccurrence: ServiceOccurrence[] | undefined
) => {
  let dataServices: BroadbandService[] = [];
  if (refinedActions) {
    // retrieve the first active status in the occurrences
    const firstOccurrenceId = dataServiceOccurrence?.find(x => x.status === ServiceStatus.Active)?.occurrenceNumber;

    // look in the removed items for a "mesh". If it is there then we may need to create a second entry for the dataServices
    const highestMeshOccurrenceId = Math.max(
      ...(dataServiceOccurrence
        ?.filter(
          x =>
            x.status === ServiceStatus.Active &&
            x.serviceDetails?.find(sd => sd.serviceCode === productSkuIds.meshDevice.primary)
        )
        ?.map(y => y.occurrenceNumber) ?? [-1])
    );

    let meshRemovalBroadbandService: BroadbandService | undefined = undefined;
    let actionsToAddToFirstOccurrence = refinedActions;
    const meshRemoval = refinedActions?.remove.find(r => (r as RemoveAddons).mesh);

    // If a broadband only account is upgrading Static IP or home phone and is not a plan swap, no need to remove mesh.
    if (meshRemoval && highestMeshOccurrenceId > firstOccurrenceId!) {
      actionsToAddToFirstOccurrence = {
        add: refinedActions.add,
        remove: refinedActions.remove.filter(r => !(r as RemoveAddons).mesh),
      };

      meshRemovalBroadbandService = {
        actions: [
          {
            remove: [meshRemoval!],
          },
        ],
        id: `${highestMeshOccurrenceId}`,
        details: [],
      };
    }

    const id = firstOccurrenceId ? `${firstOccurrenceId}` : undefined;
    const installDate = hasPerfectInstall ? format(details!.installationDate!, 'yyyy-MM-dd') : '';
    const installTime = hasPerfectInstall ? 'PM' : '';

    let broadbandService: BroadbandService = {
      actions: [actionsToAddToFirstOccurrence],
      details: refinedDetails,
      id: id,
      installDate: details ? installDate : undefined,
      installTime: details ? installTime : undefined,
    };

    dataServices.push(broadbandService);

    if (meshRemovalBroadbandService) {
      dataServices.push(meshRemovalBroadbandService);
    }
  }

  return dataServices;
};

const createDataServiceProduct = (campaigns: Campaign[], dataServices: BroadbandService[]): BroadbandProduct => {
  return {
    type: 'data',
    campaigns: campaigns,
    services: dataServices,
  };
};

const getDataProductsAddRemove = (
  broadbandPackages: ProductData[],
  currentlySubscribedBroadbandPackage: ProductData | undefined,
  newBroadbandPackageSku: string | undefined,
  swappingBroadbandPlans: boolean,
  currentProvisioningCode: string | undefined,
  newProvisioningCode: string | undefined
) => {
  let addedDataItems = getAddedProducts(broadbandPackages);
  const removedDataItems = getRemovedBroadbandPackage(currentlySubscribedBroadbandPackage, newBroadbandPackageSku);

  //we are swapping broadband plans
  if (swappingBroadbandPlans) {
    //filter the related products away - only interested in the main product.
    addedDataItems = addedDataItems.filter(item => item === newBroadbandPackageSku);

    //when swapping between plans - need to add/remove the related provisioning
    if (removedDataItems?.length && currentProvisioningCode !== newProvisioningCode) {
      removedDataItems.push(currentProvisioningCode!);
    }

    if (addedDataItems?.length && currentProvisioningCode !== newProvisioningCode) {
      addedDataItems.push(newProvisioningCode!);
    }
  }
  return { addedDataItems, removedDataItems };
};

function checkIfAddingBroadbandPackage(addedDataItems: string[]) {
  // Check if broadband package included
  const hasPackageWithMesh =
    addedDataItems.includes(productSkuIds.broadbandLightningFastWiFi.primary) ||
    addedDataItems.includes(productSkuIds.broadbandWifi100.primary) ||
    addedDataItems.includes(productSkuIds.broadbandLightningFastWiFiBoost.primary) ||
    addedDataItems.includes(productSkuIds.broadbandWifi100Boost.primary);
  return hasPackageWithMesh;
}

function checkIfAddingMesh(addedDataItems: string[], hasActiveOrPendingMeshBroadbandPackage?: boolean) {
  // If coming from acquisition flow, mesh may already be added, if so return true
  // User will also be a new customer so don't have to worry about existing broadbandPackages
  if (addedDataItems.includes(productSkuIds.meshDevice.primary)) {
    return true;
  }
  // Currently two packages with mesh, otherwise known as boost
  const hasPackageWithMesh =
    addedDataItems.includes(productSkuIds.broadbandLightningFastWiFiBoost.primary) ||
    addedDataItems.includes(productSkuIds.broadbandWifi100Boost.primary);

  // Only add mesh addon if the user doesn't already have a package with mesh
  // e.g. The user may be switching from one mesh packages to another in which case now addons needed
  if (hasPackageWithMesh && !hasActiveOrPendingMeshBroadbandPackage) {
    return true;
  }
  return false;
}

const checkIfRemovingMeshPackage = (
  addingMeshPackage: boolean,
  currentlySubscribedBroadbandPackageSku: string | undefined,
  addedBroadbandPackages: string[] | undefined
): boolean => {
  // if current has mesh, but newly added has not
  if (
    currentlySubscribedBroadbandPackageSku &&
    checkProductIsBroadbandMeshPackage(currentlySubscribedBroadbandPackageSku) &&
    !addedBroadbandPackages?.find(p => checkProductIsBroadbandMeshPackage(p))
  ) {
    return true;
  }
  //if swapping and the original has mesh and new has mesh then don't need to remove mesh
  if (
    addedBroadbandPackages?.length &&
    currentlySubscribedBroadbandPackageSku &&
    checkProductIsBroadbandMeshPackage(currentlySubscribedBroadbandPackageSku) &&
    addedBroadbandPackages?.find(p => checkProductIsBroadbandMeshPackage(p))
  ) {
    return false;
  }

  // If adding mesh package or no currently subscribed broadband package don't worry about removing mesh
  if (addingMeshPackage || !currentlySubscribedBroadbandPackageSku) {
    return false;
  }

  // If you're not adding a mesh package and you're currently subscribed to a boost plan,
  // it means you're just upgrading an addon (e.g. Static IP or home phone) so no need to remove mesh from the payload.
  if (!addingMeshPackage && checkProductIsBroadbandMeshPackage(currentlySubscribedBroadbandPackageSku)) {
    return false;
  }

  // If the existing package has Mesh, than return true to removing
  const existingPackageHasMesh = checkProductIsBroadbandMeshPackage(currentlySubscribedBroadbandPackageSku);

  return existingPackageHasMesh;
};

export let buildDataServiceProduct = async (
  trackerService: TrackerService,
  orderPackages: ProductData[],
  coupons: Pick<T_Coupon, 'description' | 'couponCode' | 'custom5'>[] | undefined,
  details: IBroadbandInstallation | undefined,
  deliveryDetails: IBroadbandDelivery | undefined,
  customer: CustomerInfo,
  getPrequalData: (addressId: string) => Promise<AddressPrequalification | undefined>,
  billing:
    | Pick<
        Services,
        'CableServiceOccurrence' | 'DataServiceOccurrence' | 'TelephoneServiceOccurrence' | 'OTTServiceOccurrence'
      >
    | undefined,
  addressService: AddressService,
  currentlySubscribedBroadbandPackage: ProductData | undefined,
  hasActiveOrPendingMeshBroadbandPackage?: boolean,
  bbDetails?: T_Tracking
): Promise<BroadbandProduct> => {
  // DTH campaign mustn't affect broadband campaigns, setting existingCampaign to false
  let campaigns = getCouponCampaign(coupons, c => c.custom5 === CouponTypes.Broadband);
  // Can be an array of three types
  // 1) Details - full object for new customers, includes all delivery details
  // 2) DeliveryDetails - Simplified version of Details, specifically only needs details related to delivery
  // 3) NonDeliveryDetails - Most simple version, for existing customers ordering a bb product that does not require delivery (e.g. static ip)
  let refinedDetails: Details[] | DeliveryDetails[] | NonDeliveryDetails[];
  let dataServices: BroadbandService[] = [];
  const dataServiceOccurrence: ServiceOccurrence[] | undefined = billing?.DataServiceOccurrence;

  const order = await analyseOrder(
    orderPackages,
    getPrequalData,
    customer.tuiAddressCode,
    billing,
    currentlySubscribedBroadbandPackage,
    hasActiveOrPendingMeshBroadbandPackage,
    bbDetails
  );

  const refinedActions = await buildActions(
    order.addedDataItems,
    order.removedDataItems,
    order.addingMeshPackage,
    order.removingMeshPackage,
    order.hasModem,
    order.hasStaticIp,
    order.hasPerfectInstall,
    order.availableDiscounts,
    order.currentDiscounts,
    campaigns.map(c => c.id)
  );

  // new bb customer
  if (details && deliveryDetails && customer && !currentlySubscribedBroadbandPackage) {
    refinedDetails = await buildDetails(
      details,
      deliveryDetails,
      customer,
      false,
      order.addingMeshPackage,
      order.hasStaticIp,
      order.hasPerfectInstall,
      getPrequalData,
      order.isVoiceProductAdded,
      false,
      addressService,
      order.bbPlanIndex,
      order.installType,
      order.hasModem,
      bbDetails?.dataProviderUserId
    );
    dataServices = setupDataServices(
      details,
      order.hasPerfectInstall,
      refinedActions,
      refinedDetails as Details[],
      dataServiceOccurrence
    );
  }

  // Customers are either an existing customer or a new sign up
  // Active broadband package implies existing customer
  if (currentlySubscribedBroadbandPackage) {
    // Implies an existing bb wants to add bb products that need to be delivered (e.g. mesh)
    if (currentlySubscribedBroadbandPackage && deliveryDetails) {
      // Will result in a simplified version of build details as broadband is already setup
      refinedDetails = await buildDeliveryDetails(
        deliveryDetails,
        customer,
        order.addingMeshPackage,
        order.hasStaticIp,
        getPrequalData,
        order.isVoiceProductAdded,
        trackerService,
        addressService,
        order.bbPlanIndex,
        order.isBoostUpgradeOnly,
        bbDetails?.dataProviderUserId,
        order.productId,
        order.availableProductKey
      );
      dataServices = setupDataServices(
        undefined,
        order.hasPerfectInstall,
        refinedActions,
        refinedDetails,
        dataServiceOccurrence
      );
    } else {
      // If user isn't changing broadband packages and has staticIp then addOnOnly is true
      const addonOnly = !checkIfAddingBroadbandPackage(order.addedDataItems) && order.hasStaticIp ? true : false;
      // If the user is an existing bb customer but they do not need the product delivered e.g. static ip or upgrading from one mesh package to another mesh package
      refinedDetails = await buildNonDeliveryDetails(
        customer,
        order.hasStaticIp,
        trackerService,
        addonOnly,
        order.bbPlanIndex,
        order.isBoostUpgradeOnly,
        getPrequalData,
        bbDetails?.dataProviderUserId,
        order.productId,
        order.availableProductKey,
        order.isVoiceProductAdded
      );
      dataServices = setupDataServices(
        undefined,
        order.hasPerfectInstall,
        refinedActions,
        refinedDetails,
        dataServiceOccurrence
      );
    }
  } else {
    // New broadband customer, may or may not be ordering other broadband products (e.g. mesh and static ip)
    if (details && deliveryDetails && customer) {
      // Full build details as there are many fields needed for a new bb customer
      refinedDetails = await buildDetails(
        details,
        deliveryDetails,
        customer,
        false,
        order.addingMeshPackage,
        order.hasStaticIp,
        order.hasPerfectInstall,
        getPrequalData,
        order.isVoiceProductAdded,
        false,
        addressService,
        order.bbPlanIndex,
        order.installType,
        order.hasModem,
        bbDetails?.dataProviderUserId
      );
      dataServices = setupDataServices(
        details,
        order.hasPerfectInstall,
        refinedActions,
        refinedDetails as Details[],
        dataServiceOccurrence
      );
    }
  }

  return createDataServiceProduct(campaigns, dataServices);
};

function isBoostOnlyUpgradeChange(
  currentlySubscribedBroadbandPackage: ProductData | undefined,
  newBroadbandPackage: ProductData | undefined
) {
  if (!currentlySubscribedBroadbandPackage || !newBroadbandPackage) {
    return false;
  }

  if (
    currentlySubscribedBroadbandPackage!.sku === productSkuIds.broadbandWifi100.primary &&
    newBroadbandPackage!.sku === productSkuIds.broadbandWifi100Boost.primary
  ) {
    return true;
  }

  if (
    currentlySubscribedBroadbandPackage!.sku === productSkuIds.broadbandLightningFastWiFi.primary &&
    newBroadbandPackage!.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary
  ) {
    return true;
  }

  if (
    newBroadbandPackage!.sku === productSkuIds.broadbandWifi100.primary &&
    currentlySubscribedBroadbandPackage!.sku === productSkuIds.broadbandWifi100Boost.primary
  ) {
    return true;
  }

  if (
    newBroadbandPackage!.sku === productSkuIds.broadbandLightningFastWiFi.primary &&
    currentlySubscribedBroadbandPackage!.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary
  ) {
    return true;
  }

  return false;
}

const getProvisioningCode = (codes: string | undefined | null) =>
  codes
    ?.split(',')
    .find(s => s === broadbandProvisioningSkus.Provision100 || s === broadbandProvisioningSkus.Provision1G) ?? '';

const analyseOrder = async (
  orderPackages: ProductData[],
  getPrequalData: (addressId: string) => Promise<AddressPrequalification | undefined>,
  addressId: string | undefined,
  billing:
    | Pick<
        Services,
        'CableServiceOccurrence' | 'DataServiceOccurrence' | 'TelephoneServiceOccurrence' | 'OTTServiceOccurrence'
      >
    | undefined,
  currentlySubscribedBroadbandPackage: ProductData | undefined,
  hasActiveOrPendingMeshBroadbandPackage?: boolean,
  tracking?: T_Tracking
) => {
  const broadbandPackages = orderPackages.filter(p => broadbandCategories.includes(p.categoryId)) ?? [];
  const newBroadbandPackage = broadbandPackages.find(p => p.categoryId === categoryIds.broadband);
  const broadbandUpgradeDowngrade =
    currentlySubscribedBroadbandPackage &&
    newBroadbandPackage &&
    currentlySubscribedBroadbandPackage?.sku !== newBroadbandPackage?.sku;

  const isBoostUpgradeOnly = isBoostOnlyUpgradeChange(currentlySubscribedBroadbandPackage, newBroadbandPackage);
  const newProvisioningCode = getProvisioningCode(newBroadbandPackage?.custom3);
  const currentProvisioningCode = getProvisioningCode(currentlySubscribedBroadbandPackage?.custom3);

  // Check for current and available broadband discounts, so we can decide if
  // we add them or remove them from the payload
  const orderServices = orderPackages.flatMap(p => [p.sku, ...(p.custom3?.split(',') ?? [])]);
  const dataServices =
    billing?.DataServiceOccurrence?.flatMap(x => x.serviceDetails)
      .filter(sd => sd.quantity > 0)
      .map(sd => sd.serviceCode) ?? [];
  const cableServices =
    billing?.CableServiceOccurrence?.flatMap(x => x.serviceDetails)
      .filter(sd => sd.quantity > 0)
      .map(sd => sd.serviceCode) ?? [];
  const services = [...dataServices, ...cableServices, ...orderServices];

  const currentDiscounts: string[] =
    services.filter(value => broadbandDiscounts.map((x: { discount: string }) => x.discount).includes(value)) ?? [];
  const availableDiscounts: string[] = broadbandDiscounts
    .map((item: any) => {
      // if newBroadbandPackage is not null , check it,
      // otherwise check currentlySubscribedBroadbandPackage (means no package changed)
      if (item.preRequisites.some((value: string) => services.includes(value))) {
        if (newBroadbandPackage) {
          if (item.appliesTo.includes(newBroadbandPackage?.sku)) {
            return item.discount;
          }
        } else {
          if (item.appliesTo.includes(currentlySubscribedBroadbandPackage?.sku)) {
            return item.discount;
          }
        }
      }
    })
    .filter(Boolean);

  const { addedDataItems, removedDataItems } = getDataProductsAddRemove(
    broadbandPackages,
    currentlySubscribedBroadbandPackage,
    newBroadbandPackage?.sku,
    broadbandUpgradeDowngrade ?? false,
    currentProvisioningCode,
    newProvisioningCode
  );

  const addingMeshPackage = checkIfAddingMesh(addedDataItems, hasActiveOrPendingMeshBroadbandPackage);
  const removingMeshPackage = checkIfRemovingMeshPackage(
    addingMeshPackage,
    currentlySubscribedBroadbandPackage?.sku,
    addedDataItems
  );
  const hasModem = addedDataItems.includes(productSkuIds.skyRouter.primary);
  const hasPerfectInstall = addedDataItems.includes(productSkuIds.broadbandPerfectInstall.primary);
  const hasStaticIp = addedDataItems.includes(productSkuIds.broadbandStaticIP.primary);
  const userAlreadyHasVoice = (billing?.TelephoneServiceOccurrence ?? []).some(t => t.status === ServiceStatus.Active);

  const isVoiceProductAdded = isVoiceAdded(orderPackages) || userAlreadyHasVoice;
  // const isOneGigPlan =
  //   addedDataItems.includes(productSkuIds.broadbandLightningFastWiFiBoost.primary) ||
  //   addedDataItems.includes(productSkuIds.broadbandLightningFastWiFi.primary);

  const bbPlanIndex: BBPlanIndex = [
    productSkuIds.broadbandLightningFastWiFiBoost.primary,
    productSkuIds.broadbandLightningFastWiFi.primary,
  ].some(id => addedDataItems.includes(id))
    ? 0
    : [productSkuIds.broadbandWifi100.primary, productSkuIds.broadbandWifi100Boost.primary].some(id =>
        addedDataItems.includes(id)
      )
    ? 1
    : 2;

  const isSelfInstall = addedDataItems.includes(productSkuIds.broadbandSelfInstall.primary);
  const isByod = addedDataItems.includes(productSkuIds.broadbandBringYourOwnDevice.primary);
  const installType = isSelfInstall
    ? productSkuIds.broadbandSelfInstall.label
    : isByod
    ? productSkuIds.broadbandBringYourOwnDevice.label
    : '';

  let productId: string | undefined = undefined;
  if (broadbandUpgradeDowngrade) {
    const prequalData = await getPrequalData(addressId!);
    productId = getProductKey(prequalData, bbPlanIndex, isVoiceProductAdded);
  }

  const availableProductKey =
    tracking?.bbDetails?.find(details => details.type === 'internet')?.coreProductKey ?? undefined;

  return {
    broadbandPackages,
    newBroadbandPackage,
    broadbandUpgradeDowngrade,
    isBoostUpgradeOnly,
    addedDataItems,
    removedDataItems,
    addingMeshPackage,
    removingMeshPackage,
    hasModem,
    hasPerfectInstall,
    hasStaticIp,
    isVoiceProductAdded,
    bbPlanIndex,
    isSelfInstall,
    isByod,
    installType,
    availableDiscounts,
    currentDiscounts,
    productId,
    availableProductKey,
  };
};
