import { useState } from 'react';
import { addHours, format, startOfToday } from 'date-fns';
import {
  InstallationTimeCodesHalfDay,
  TechLookupOrderType,
  TechLookupProductType,
  TechLookupTechType,
  TechnicianDateSlots,
  TechnicianSlot,
} from '../types';
import { TechnicianAvailabilityService } from '../services/technicianAvailability';

function useTechnicianAvailableSlots(
  techService: TechnicianAvailabilityService,
  techType: TechLookupTechType,
  productType: TechLookupProductType,
  orderType: TechLookupOrderType
) {
  //Technician Lookup values
  let [loadingTechnicianAvailableDates, setLoadingTechnicianAvailableDates] = useState(false);
  let [errorTechnicianAvailableDates, setErrorTechnicianAvailableDates] = useState(false);
  let [technicianSlots, setTechnicianSlots] = useState<TechnicianDateSlots[]>([]);

  const getTechnicianDates = async (
    startDate: Date,
    endDate: Date,
    tui: string | undefined = undefined,
    houseNumber: string | undefined = undefined,
    orderId: string | undefined = undefined,
    coreProductId: string | undefined = undefined,
    appointmentType: string | undefined = undefined,
    callCode: string | undefined = undefined
  ) => {
    setLoadingTechnicianAvailableDates(true);
    try {
      let techLookup: TechnicianDateSlots[] = await techService.getTechnicianAvailability(
        techType,
        formatDate(startDate),
        formatDate(endDate),
        true,
        productType,
        orderId ?? '',
        tui ?? '',
        houseNumber ?? '',
        orderType,
        coreProductId ?? '',
        appointmentType ?? '',
        callCode
      );

      setTechnicianSlots(techLookup);
      setErrorTechnicianAvailableDates(techLookup.length < 1);
      setLoadingTechnicianAvailableDates(false);
      return techLookup;
    } catch (err) {
      console.error(err);
      setErrorTechnicianAvailableDates(true);
      setLoadingTechnicianAvailableDates(false);
    }
  };
  // eslint-disable-next-line
  let trasnformTechnicianTimeSlotsToAMPM = (dateSlots: TechnicianDateSlots[]) => {
    let transformedTimeSlots: TechnicianDateSlots[] = [];
    dateSlots.forEach(dateSlots => {
      const transformedtechnicianDateSlot: TechnicianDateSlots = {
        date: dateSlots.date,
        slots: [],
      };

      let amAdded: boolean = false;
      let pmAdded: boolean = false;

      dateSlots.slots.forEach(slot => {
        if (!amAdded && (slot.code === 'AM1' || slot.code === 'AM2')) {
          const transformedTimeslot: TechnicianSlot = {
            code: 'AM',
            displayName: InstallationTimeCodesHalfDay.AM,
            startTime: UpdateTimeOnDate(dateSlots.date, 8, 0, 0),
            endTime: UpdateTimeOnDate(dateSlots.date, 11, 59, 59),
          };

          transformedtechnicianDateSlot.slots.push(transformedTimeslot);
          amAdded = true;
        }

        if (!pmAdded && (slot.code === 'PM1' || slot.code === 'PM2')) {
          const transformedTimeslot: TechnicianSlot = {
            code: 'PM',
            displayName: InstallationTimeCodesHalfDay.PM,
            startTime: UpdateTimeOnDate(dateSlots.date, 12, 0, 0),
            endTime: UpdateTimeOnDate(dateSlots.date, 17, 59, 59),
          };

          transformedtechnicianDateSlot.slots.push(transformedTimeslot);
          pmAdded = true;
        }
      });

      if (transformedtechnicianDateSlot.slots.length > 0) {
        transformedTimeSlots.push(transformedtechnicianDateSlot);
      }
    });

    return transformedTimeSlots;
  };

  let UpdateTimeOnDate = (date: Date, hours: number, min: number, sec: number) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, min, sec, 0);
  };

  let getTechnicianTimeSlots = (date: Date) => {
    const dateOnly = getDateOnly(date);
    return technicianSlots.find(ts => getDateOnly(ts.date) === dateOnly)?.slots ?? [];
  };

  const getDateOnly = (date: Date) => {
    return date.setHours(0, 0, 0, 0);
  };

  const formatDate = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
  };

  const buildSlot = (code: string, start: number, end: number) => {
    const startingHour = start <= 12 ? start : start - 12;
    const endingHour = end <= 12 ? end : end - 12;

    return {
      code: code,
      startTime: addHours(startOfToday(), start),
      endTime: addHours(startOfToday(), end),
      displayName: `${startingHour}${start < 12 ? 'a' : 'p'}m - ${endingHour}${end < 12 ? 'a' : 'p'}m`,
    } as TechnicianSlot;
  };

  const allTimeslots = [buildSlot('AM', 8, 12), buildSlot('PM', 12, 18)] as TechnicianSlot[];
  const allTimeslotsShouldUseLookUp = [
    buildSlot('AM1', 8, 9),
    buildSlot('AM2', 9, 12),
    buildSlot('PM1', 12, 16),
    buildSlot('PM2', 16, 18),
  ] as TechnicianSlot[];

  return {
    loadingTechnicianAvailableDates,
    errorTechnicianAvailableDates,
    getTechnicianDates,
    getTechnicianTimeSlots,
    allTimeslots,
    allTimeslotsShouldUseLookUp,
    technicianSlots,
  };
}

export { useTechnicianAvailableSlots };
