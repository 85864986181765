interface InitProps {
  token: string;
  externalId: string; // user’s id unique to your system
  firstName: string; // user’s first name
  lastName: string; // user’s last name
  email: string; // user’s email address
  phone: string; // phone number without country code
  phoneCountryCode: string;
}

const ElementID = 'freshchat-js-sdk';
const Config = {
  token: 'f7a3de55-1cd8-46fc-9890-ed25063b4bca',
  host: 'https://wchat.freshchat.com',
} as const;

let getFCInstance = () => {
  return (window as any).fcWidget;
};

let disableFC = () => {
  getFCInstance()?.destroy();
};

let initFC = (props: Partial<InitProps>) => {
  let initFreshChat = () => {
    getFCInstance()?.init({
      ...Config,
      ...props,
    });
  };

  let d = window.document;
  let e = d.getElementById(ElementID);
  if (e) {
    initFreshChat();
  } else {
    let s = d.createElement('script');
    s.id = ElementID;
    s.async = true;
    s.onload = initFreshChat;
    s.src = 'https://wchat.freshchat.com/js/widget.js';
    d.head.appendChild(s);
  }
};

export { initFC, disableFC, getFCInstance };
