import React from 'react';
import { Facebook, Insta, Twitter, Youtube } from '../footer/icons';
import { Link } from '@sky-tv-group/shared';
interface Props {
  socialIcons: Link[];
}
export let SocialButtons = ({ socialIcons }: Props) => {
  return (
    <>
      {socialIcons.map(social => {
        return (
          // hover:bg-social-facebook, hover:bg-social-twitter, hover:bg-social-youtube, hover:bg-social-instagram
          <div
            key={social.title}
            className={`w-12 h-12 text-white m-2  border border-blue-darker rounded-full bg-white hover:bg-social-${social.title}`}>
            <a href={social.link} className="p-2 block text-black hover:text-white">
              {(function() {
                switch (social.title) {
                  case 'facebook':
                    return <Facebook />;
                  case 'twitter':
                    return <Twitter />;
                  case 'youtube':
                    return <Youtube />;
                  case 'instagram':
                    return <Insta />;
                }
              })()}
            </a>
          </div>
        );
      })}
    </>
  );
};
