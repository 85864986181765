import { useField } from 'react-jeff';
import { IBroadbandDelivery } from '../store/broadbandDeliveryStore';
import { IBroadbandInstallation } from '../store/broadbandStore';
import { NullableAddressLookup } from '../types';
import {
  validateMobileNumberAreaCode,
  validateMobileNumberLineNumber,
  validateOtherServiceProvider,
  validateProviderAccountNumber,
  validateRequiredDate,
} from './validations';

import { unformattedTelPhoneNumber } from '../config';

export function useSkyBroadbandFields(details: Partial<IBroadbandInstallation & IBroadbandDelivery>) {
  let installationAddressField = useField({
    defaultValue: '',
    required: true,
    readOnly: true,
  });

  // Default value of the delivery address
  let defaultDeliveryAddressField = useField({
    defaultValue: ['', '', '', ''],
    required: true,
    readOnly: true,
  });

  let mobileNumberAreaCodeField = useField({
    defaultValue: details?.mobileNumberAreaCode || '',
    validations: [validateMobileNumberAreaCode],
    required: true,
  });

  let mobileNumberLineNumberField = useField({
    defaultValue: details?.mobileNumberLineNumber || '',
    validations: [validateMobileNumberLineNumber],
    required: true,
  });

  let emailField = useField({
    defaultValue: details?.email || '',
    required: true,
    validations: [validateContactEmail],
  });

  let currentServiceProviderField = useField({
    defaultValue: details?.currentServiceProvider || '',
    required: true,
    validations: [],
  });

  let providerAccountNumberField = useField({
    defaultValue: details?.providerAccountNumber || '',
    validations: [validateProviderAccountNumber],
    required: true,
  });

  let otherServiceProviderField = useField({
    defaultValue: details?.otherServiceProvider || '',
    required: false,
    validations: [validateOtherServiceProvider],
  });

  let installationDateField = useField<null | Date, string>({
    defaultValue: details?.installationDate || null,
    required: true,
    // React Native doesn't support excluding certain dates.
    validations: [validateRequiredDate('Installation Date')],
  });

  let installationTimeField = useField({
    defaultValue: details?.installationTime || 'AM',
    required: true,
  });

  let activationDateField = useField<null | Date, string>({
    defaultValue: details?.activationDate || null,
    required: true,
    validations: [validateRequiredDate('Activation Date')],
  });

  let useDifferentDeliveryAddressField = useField<boolean, string>({
    defaultValue: details?.useDifferentDeliveryAddress ?? false,
  });

  function validateAddress(addressType: string) {
    return (value: NullableAddressLookup) => {
      let errs: string[] = [];
      if (useDifferentDeliveryAddressField.value) {
        if (!value) errs.push(addressType + ' address is required');
        if (value) {
          if (value.label.length === 0) errs.push(addressType + ' address is required');
          if (value.label.length < 3) errs.push('Please enter at least 3 characters');
          if (value.id.length === 0)
            errs.push(
              `We can’t find that address in our system, please call our friendly Sky Crew on ${unformattedTelPhoneNumber} to get you started`
            );
        }
      }

      return errs;
    };
  }

  function validateContactEmail(value: string) {
    let errs: string[] = [];
    if (!value) errs.push('Email is required');
    if (value.length === 0) errs.push('Email is required');
    if (value.length > 80) errs.push('Email must be 80 characters of less');
    if (!/(.+)@(.+){1,}\.(.+){2,}/.test(value)) errs.push('Must be a valid email');

    return errs;
  }

  function validateDeliveryAddress(value: NullableAddressLookup) {
    return validateAddress('Delivery')(value);
  }

  let deliveryAddressField = useField<NullableAddressLookup, string>({
    defaultValue: {
      id: details?.deliveryAddress?.id || '',
      label: details?.deliveryAddress?.label || '',
    },
    validations: [validateDeliveryAddress],
  });

  return {
    deliveryAddressField,
    activationDateField,
    installationTimeField,
    installationDateField,
    otherServiceProviderField,
    currentServiceProviderField,
    providerAccountNumberField,
    mobileNumberLineNumberField,
    mobileNumberAreaCodeField,
    emailField,
    defaultDeliveryAddressField,
    installationAddressField,
    useDifferentDeliveryAddressField,
  };
}
