import './index.css';
import './main.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import { Root } from './Root';
import { sentryService } from './services/';


sentryService.init();
Modal.setAppElement('#root');
ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
