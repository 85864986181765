import { shallow } from 'zustand/shallow';
import { StoreToastProps, useToastStore } from '../store/toastStore';

function useToastContainer() {
  // the list of toasts keyed by a unique ID
  const [
    toasts,
    removeToast,
    removeAllToasts,
    storeAddToast,
    ,
    addDepToast,
    removeDepToast,
    getDepToast,
    getOfferToast,
  ] = useToastStore(
    state => [
      state.toasts,
      state.removeToast,
      state.removeAllToasts,
      state.addToast,
      state.depToasts,
      state.addDepToast,
      state.removeDepToast,
      state.getDepToast,
      state.getOfferToast,
    ],
    shallow
  );
  const toastKeys = Object.keys(toasts);

  /**
   * Gets a Dependency Toast Id from the map by product id.
   * Will return an empty string if not found
   */
  const hasDependencyToast = (productIds: number | number[]): boolean => {
    const [_, depToastId] = getDepToast(productIds);
    return depToastId.length > 0;
  };

  /**
   * Adds a toast to the list of toasts to display.
   * @param toastProps Toast to add's props.
   * @param toastProps.time The time in seconds to show this toast for, by default it will stay until closed.
   * @returns the new id for the added toast so it can be removed programatically later.
   */
  const addToast = (toastProps: StoreToastProps): string => {
    const toastId = `${toastProps.type}-${Date.now().toString()}`;
    storeAddToast(toastId, toastProps);
    return toastId;
  };
  /**
   * Awaitable Toast
   * onClose is required to be awaitable too.
   * @param toastProps
   */
  const addAwaitableToast = (
    toastProps: Omit<StoreToastProps, 'onClose'> & {
      onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any>;
    }
  ) => {
    const toastId = `${toastProps.type}-${Date.now().toString()}`;
    let promise = new Promise((resolve, reject) => {
      storeAddToast(toastId, {
        ...toastProps,
        onClose: async (e: any) => {
          await toastProps.onClose?.(e);
          resolve(undefined);
        },
      });
    });
    return { toastId, promise };
  };

  type SuccessToastProps = Omit<StoreToastProps, 'time' | 'title' | 'type'> & { title?: string; time?: number };
  /**
   * Adds a success toast to the list of toasts.
   * @param toastProps The props for the toast.
   * @param toastProps.title The title to show in the toast, defaults to 'Success'
   * @param toastProps.time The time in seconds to show this toast for, defaults to 5
   */
  const addSuccessToast = (toastProps: SuccessToastProps): string => {
    const { title, time, ...props } = toastProps;
    return addToast({ ...props, type: 'success', title: title ?? 'Success', time: time });
  };

  /**
   * Adds an error toast to the list of toasts.
   * @param toastProps The props for the toast.
   * @param toastProps.title The title to show in the toast, defaults to 'Success'
   * @param toastProps.time The time in seconds to show this toast for, defaults to 5
   */
  const addErrorToast = (toastProps: SuccessToastProps): string => {
    const { title, time, ...props } = toastProps;
    return addToast({ ...props, type: 'error', title: title ?? 'Error', time: time });
  };

  /**
   * Adds a reference by id of a toast stating there is a
   * dependency before you can add the product you last clicked.
   * @param productIds the id of the product(s) which are a dependency
   * @param toastId the id of the toast which is being displayed
   */
  const addToDependencyToasts = (productIds: number | number[], toastId: string): void => {
    const productIdString = productIds instanceof Array ? productIds.join() : productIds.toString();
    return addDepToast(productIdString, toastId);
  };

  /**
   * Removes the reference of a toast from the map of productIds and toasts
   * @param productIds the id(s) of the product(s) which are the dependecies
   */
  const removeFromDependencyToasts = (productIds: number | number[]): void => {
    // find if any of the dep toast keys include the productIds passed in
    const [depToastKey, productToRemove] = getDepToast(productIds);
    // if not return without doing anything
    if (productToRemove.length < 1) {
      return;
    }
    // otherwise remove the toast we found
    return removeDepToast(depToastKey);
  };

  return {
    toasts,
    toastKeys,
    getDependencyToastId: getDepToast,
    getOfferToast,
    hasDependencyToast,
    addToast,
    addAwaitableToast,
    removeToast,
    removeAllToasts,
    addSuccessToast,
    addErrorToast,
    addToDependencyToasts,
    removeFromDependencyToasts,
  };
}

export { useToastContainer };
