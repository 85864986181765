import React, { FunctionComponent } from 'react';
import { closex } from '../icon';

export interface ITopBarProps {
  hide: () => void;
  children: JSX.Element;
  theme?: 'WHITE' | 'BLACK';
}

export const TopBar: FunctionComponent<ITopBarProps> = ({ hide, children, theme }) => {
  return (
    <div className={`h-20 ${theme === 'BLACK' ? 'bg-black' : 'bg-white'} shadow-md sticky z-50 top-0`}>
      <div className="container m-auto flex justify-between items-center h-full px-12 md:px-0">
        <button className="text-3xl rounded-full md:w-11 md:h-11 w-8 h-8 text-white bg-blue-pure" onClick={hide}>
          {closex}
        </button>
        <div className="flex-1">
          {children}
          <div className="w-8"></div>
        </div>
      </div>
    </div>
  );
};
