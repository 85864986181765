import { defaultICOMSPin } from '../../../config';
import { AddressService } from '../../../services/address';
import {
  CustomerDetails,
  InstallationDetail,
  OwnershipFields,
  PaymentInfo,
  PropertyDetail,
  YourDetails,
} from '../../../types';
import {
  getDateOfBirth,
  getPrimaryPhoneNumber,
  getServiceAddress,
  getDeliveryAddress,
  getBillingAddress,
} from './helpers';

let getAccountNumber = (account: string[]) => {
  let suffix = account[account.length - 1];
  suffix = ('0' + suffix).slice(-3);
  account[account.length - 1] = suffix;
  return account.join('');
};

// Gets the Customer Details
export const createCustomerDetails = async (
  yourDetail: YourDetails,
  installationDetails: InstallationDetail,
  propertyDetail: PropertyDetail,
  addressService: AddressService,
  marketingEmails?: boolean,
  paymentInfo?: PaymentInfo
): Promise<CustomerDetails> => {
  const formattedDateOfBirth = getDateOfBirth(yourDetail.dateOfBirth);
  const ownership = installationDetails.rentOrOwn === OwnershipFields.Own;
  const serviceAddress = await getServiceAddress(addressService, propertyDetail.address);
  const primaryPhoneNumber = getPrimaryPhoneNumber(yourDetail.phoneAreaCode, yourDetail.phoneTelephoneNumber);

  let customerDetails: CustomerDetails = {
    email: yourDetail.email,
    billViaEmail: propertyDetail.paperlessBilling.toString(),
    icomsPin: defaultICOMSPin,
    dateOfBirth: formattedDateOfBirth,
    firstName: yourDetail.firstName,
    houseOwnership: ownership.toString(),
    lastName: yourDetail.familyName,
    phoneNumbers: [primaryPhoneNumber],
    serviceAddress: serviceAddress,
    marketingEmails,
  };
  if (paymentInfo?.isDD) {
    customerDetails.directDebit = {
      accountType: 'Cheque',
      accountHolder: paymentInfo.ddAccountName ?? '',
      bankAccount: getAccountNumber(paymentInfo.ddAccountNumber!),
    };
  } else {
    customerDetails.creditCardPayment = 'true';
  }

  // add billing address if it is different than installation address
  if (!propertyDetail.billingAddressSameAsInstallationAddress) {
    const billingAddress = await getBillingAddress(addressService, propertyDetail.billingAddress);
    customerDetails = { ...customerDetails, billingAddress };
  }

  return customerDetails;
};
