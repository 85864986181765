import { T_Product } from '..';
import { KonakartService } from '../services/konakart';
import { CouponTypeValue } from '../types';
import { useProductWithCouponRemoval } from './useProductWithCouponRemoval';
import { useRemoveProduct } from './useRemoveProduct';

// Updated to only remove products and not boxes
export const useHandleRemove = (konakartService: KonakartService) => {
  const { handleRemovalOfT_OrderProductWithToast } = useProductWithCouponRemoval(konakartService);
  const { toggleCartProduct } = useRemoveProduct(konakartService, CouponTypeValue.Acquisition);

  const handleRemove = async (product: T_Product) => {
    await handleRemovalOfT_OrderProductWithToast({ name: product.name, id: product.id }, toggleCartProduct, true);
  };

  return handleRemove;
};
