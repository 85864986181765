import React, { useMemo } from 'react';
import cx from 'classnames';
import { ScrollDirection, useMediaQuery, useScrollDirection } from '@sky-tv-group/shared';
import { useAcquisitionJourneyState } from '../provider/AcquisitionJourney';

export const BreadcrumbContainer = ({
  progressBarStatus,
  className,
}: {
  progressBarStatus?: boolean;
  className?: string;
}) => {
  const { progressBar } = useAcquisitionJourneyState();
  const isMobile = useMediaQuery('(max-width: 480px)');
  const scrollDirection = useScrollDirection(isMobile);
  const containerClassNames = useMemo(
    () =>
      cx(
        'flex flex-col items-center z-20 sticky bg-white shadow top-4 lg:w-full transition-all delay-500 duration-200 ease-out',
        className,
        { '-top-5': isMobile && scrollDirection === ScrollDirection.DOWN }
      ),
    [isMobile, scrollDirection]
  );
  return (
    <div className={containerClassNames}>
      <div className="container m-auto p-2 lg:py-4 justify-center lg:pr-20 lg:flex lg:flex-col xl:flex-row items-center">
        {progressBar(progressBarStatus)}
      </div>
    </div>
  );
};
