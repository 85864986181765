export type YesNoValueType = 'YES' | 'NO';

export type SkyCustomerYesNoType = {
  YES: 'YES';
  NO: 'NO';
};

export const SkyCustomerYesNoOption: SkyCustomerYesNoType = {
  YES: 'YES',
  NO: 'NO',
};

export interface IceRoute {
  path: string;
  title: string;
  component: () => JSX.Element;
  exact: boolean;
}

export enum SkyAppsPathName {
  MY_ACCOUNT_APP = '/my-account-app',
  SKY_GO = '/skygo',
  SKY_TV_GUIDE = '/sky-apps/sky-tv-guide',
}

export const IceWebTitle: string = 'Shopping Cart | Sky';
