/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useCheckYourAddress } from '../../hooks/useCheckYourAddress';
import { format, parseISO } from 'date-fns';
import { useAuth0 } from '../../store/authStore/authStore';
import { useNewAcquisitionOrderStore } from '@sky-tv-group/shared';

type FibreDescriptionProps = {
  clearInstallationAddressField: () => void;
};

const FibreDescription = ({ clearInstallationAddressField }: FibreDescriptionProps) => {
  const { isAuthenticated } = useAuth0(s => ({
    isAuthenticated: s.isAuthenticated,
  }));

  const { fibreETA, isFibreOk, isFibreFuture, isFibreNeverOrUnknown } = useCheckYourAddress();
  const descriptionStart = 'Fibre is not due to be available in your street';
  const descriptionEnd = ", so we'll be in touch nearer the time so you can start enjoying Sky Broadband!";

  const formatDate = (date: Date | undefined): string => {
    return date ? format(parseISO(date.toString()), 'MMM-yyyy') : '';
  };

  const fibreETADate = formatDate(fibreETA());
  const crownfibreURL = 'http://www.crownfibre.govt.nz';

  const { selectedTuiAddress } = useNewAcquisitionOrderStore(s => ({
    selectedTuiAddress: s.selectedTuiAddress,
  }));

  if (isFibreOk()) {
    return (
      <div className="font-normal text-14px ml-0 text-black border-skyGreenDark">
        {/* Not your address?{' '} */}
        {/* <button
        className="font-normal text-14px ml-0 text-bborange pb-4 underline"
        onClick={clearInstallationAddressField}>
         </button> */}
        Perfect! Fibre is available at your place!
      </div>
    );
  } else if (isFibreFuture()) {
    return (
      <>
        <div className="sky-h5-reg text-13px ml-0 pb-3 sky-text-white">
          {descriptionStart} {fibreETADate ? 'until ' + fibreETADate : fibreETADate}
          {descriptionEnd}
        </div>
        <div>
          {/* Not your address?{' '} */}
          <button
            className="font-normal text-14px ml-0 text-bborange pb-4 underline"
            onClick={clearInstallationAddressField}>
            Try another address
          </button>
        </div>
      </>
    );
  } else if (isFibreNeverOrUnknown()) {
    return (
      <>
        <div className="pb-2 sky-h5-reg text-13px border-orange text-black">
          Unfortunately fibre is not available in your area. There are no current government plans to roll out fibre to your address.
          Visit <a href={crownfibreURL} target="_blank" rel="noreferrer noopener" className="underline sky-text-blue ">
            www.crownfibre.govt.nz
          </a>{' '} to find out more.
        </div>
        <div>
          {/* Not your address?{' '} */}
          {/* <button
            className="font-normal text-14px ml-0 text-bborange pb-4 underline"
            onClick={clearInstallationAddressField}>
            Try another address
          </button> */}
        </div>
      </>
    );
  } 
  // else if (!isAuthenticated || selectedTuiAddress === '') {
  //   return (
  //     <div className="sky-h5-reg text-13px py-3 sky-text-white">
  //       See if fibre is available at {isAuthenticated ? 'another address' : 'your place'}{' '}
  //     </div>
  //   );
  // } 
  else {
    return <div></div>;
  }
};

export { FibreDescription };
