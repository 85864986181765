import create from 'zustand';
import { parseISO } from 'date-fns';
import { InstallationTime, InstallationTimeCodes, NullableDate } from '../types';
import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';
import { sessionStorage } from '../helpers/sessionStorage';

const sessionStorageStateName = 'Upgradable Box Installation Store';
const getSessionStorageState = (): IUpgradableBoxesStore => {
  const state = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}', (key, value) => {
    const dateKeys = ['installationDate'];
    // if its not a date don't worry about it
    if (!dateKeys.includes(key)) return value;
    // otherwise parse the ISO string
    return parseISO(value);
  });
  return state;
};

export interface IUpgradableBoxesStore {
  installationDate: NullableDate;
  installationTime: InstallationTime;
  isValid: boolean;
  boxInstallRequired: boolean;
}

const InitialState: IUpgradableBoxesStore = {
  installationDate: null,
  installationTime: InstallationTimeCodes.AM,
  isValid: true,
  boxInstallRequired: false,
};

interface UpgradableBoxesStore {
  details: IUpgradableBoxesStore;
  errorAvailableDates: boolean;
  setDetails: (value: IUpgradableBoxesStore) => void;
  setErrorAvailableDates: (value: boolean) => void;
}

const [useUpgradableBoxesStore, upgradableBoxesStoreApi] = create<UpgradableBoxesStore>(
  ReduxDevTools(
    StatePersistence(
      set => ({
        details: InitialState,
        errorAvailableDates: false,
        ...getSessionStorageState(),
        setDetails: (value: IUpgradableBoxesStore) => {
          set({ details: value }, 'set upgradable box details');
        },
        setErrorAvailableDates: (value: boolean) => {
          set({ errorAvailableDates: value }, 'set error Technician Available Dates');
        },
      }),
      sessionStorageStateName
    ),
    'Upgradable Box Installation Details'
  )
);

export { useUpgradableBoxesStore, upgradableBoxesStoreApi };
