import React from 'react';

export const VTVStandalonePreOrderInfo = () => {
  return (
    <div className="text-center sky-h6-bold text-midnight mx-4 md:mx-0 pb-10">
      <h3 className="sky-h3-bold">Pre-order today</h3>
      <p>
        It’s important to pre-order your Sky Pod or Sky Box so we can get you set up before VodafoneTV closes. If
        choosing a Sky Box you’ll need a Sky Technician to install or replace your satellite dish, so book now as
        technicians will book up fast.
      </p>
    </div>
  );
};
