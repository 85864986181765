import { AxiosInstance } from 'axios';

import { AddressLookup, T_AddressLookUpDetail, FormattedAddress, StructuredAddress, ReferenceAddress } from '../types';
import { getCityValue } from '../helpers/orderDataBuilders/cableServices/helpers';

export class AddressService {
  private addressAgent: AxiosInstance;
  constructor(addressAgent: AxiosInstance) {
    this.addressAgent = addressAgent;
  }
  public getAddress = async (term: string, type: string) =>
    (
      await this.addressAgent.get<AddressLookup[]>('/lookup?', {
        params: {
          term,
          type,
        },
      })
    ).data;

  public getAddressDetail = async (id: string) => {
    let detail = (
      await this.addressAgent.get<T_AddressLookUpDetail>('/search?', {
        params: {
          id,
        },
      })
    ).data;

    return new AddressDetail(detail);
  };
}

export class AddressDetail implements T_AddressLookUpDetail {
  formatted_address: FormattedAddress;
  structured_address: StructuredAddress;
  references: ReferenceAddress;
  formatted_delivery_address: FormattedAddress;

  constructor(source: T_AddressLookUpDetail) {
    this.formatted_address = source.formatted_address;
    this.structured_address = source.structured_address;
    this.references = source.references;

    if (source.formatted_address.line1.match(/^PO BOX/)) {
      this.formatted_delivery_address = {
        line1: source.formatted_address.line1,
        line2: source.formatted_address.line3,
        line3: source.formatted_address.line4,
        line4: source.structured_address.postcode,
      };
    } else {
      this.formatted_delivery_address = {
        line1: `${source.structured_address.street_number} ${source.structured_address.street_name} ${source.structured_address.road_type}`,
        line2: source.structured_address.suburb,
        line3: getCityValue(source.structured_address.town,source.structured_address.region),
        line4: source.structured_address.postcode,
      };
    }
  }
}
