import React from 'react';
import { Warning2 } from './icon';

export const VTVInformation = ({ preOrder = true }: { preOrder?: boolean }) => {
  const active = false;
  return (
    <div className={` ${active ? 'h-auto' : 'h-96 md:h-auto'} bg-gray-cool rounded-md relative my-6`}>
      <div className=" h-full flex flex-col md:flex-row p-5 pb-0 md:p-3 overflow-hidden">
        <div className="pb-2 md:pr-3">
          <Warning2 />
        </div>
        {preOrder ? (
          <div>
            <h3 className="sky-h5-black pb-4 md:pb-1 text-midnight">{'Important pre-order information:'}</h3>
            <ul className="-ml-4 list-disc sky-text-gray-darkest px-0 md:px-8 sky-h7-reg list-inside md:list-outside">
              <li>
                If you select a Sky Box you’ll need to book a Sky technician to install or replace your satellite dish.
                There is no additional cost for a standard installation.
              </li>
              <li>
                We estimate Sky Boxes and Sky Pods will ship from mid-February and may take up to 3-4 weeks to arrive.
              </li>
              <li>
                Your new subscription pricing and special offer will begin when you activate your new device by turning
                it on, logging into My Account with your username and password and following the setup instructions.
                We’ll also charge any associated one-off fees at this point.
              </li>
              <li>
                You can cancel any time before activation. You will need to return your unused Sky Box or Sky Pod, and
                at your request we'll remove any satellite dish we've installed as part of your pre-order, at Sky’s
                cost.
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <h3 className="sky-h5-black pb-4 md:pb-1 text-midnight">{'Key things to know:'}</h3>
            <ul className="-ml-4 list-disc sky-text-gray-darkest px-0 md:px-8 sky-h7-reg list-inside md:list-outside">
              <li>
                If you select a Sky Box you’ll need to book a Sky technician to install or replace your satellite dish.
                There is no additional cost for a standard installation.
              </li>
              <li>
                We estimate Sky Boxes and Sky Pods will take up 3 working days to arrive once shipped. We’ll let you
                know when your package is on its way.
              </li>
              <li>
                Your new subscription pricing and special offer will begin when you activate your new device by turning
                it on, logging into My Account with your username and password and following the setup instructions.
                We’ll also charge any associated one-off fees at this point.
              </li>
              <li>
                You can cancel any time before activation. You will need to return your unused Sky Box or Sky Pod, and
                at your request we'll remove any satellite dish we've installed as part of your pre-order, at Sky’s
                cost.
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
