import { useField } from 'react-jeff';
import { InitialAccountDetails, useAccountDetailsStore } from '../../store/accountDetailsStore';
import {
  validateAreaCode,
  validateBankCard,
  validateBillingAddress,
  validateDeliveryAddress,
  validateDateOfBirth,
  validateEmail,
  validateFamilyName,
  validateFirstName,
  validateInstallationAddress,
  validateInstallationDate,
  validateOwnership,
  validateTelephoneNumber,
  validationNumbers,
  validateDDAccountName,
} from '../validations';

// Your Details
export function useYourDetailsFirstNameField() {
  return useField({
    defaultValue: InitialAccountDetails.yourDetail.firstName,
    required: true,
    validations: [validateFirstName],
  });
}

export function useYourDetailsFamilyNameField() {
  return useField({
    defaultValue: InitialAccountDetails.yourDetail.familyName,
    required: true,
    validations: [validateFamilyName],
  });
}

export function useYourDetailsEmailField() {
  return useField({
    defaultValue: InitialAccountDetails.yourDetail.email,
    required: true,
    validations: [validateEmail],
  });
}

export function useYourDetailsPhoneAreaCodeField() {
  return useField({
    defaultValue: InitialAccountDetails.yourDetail.phoneAreaCode,
    required: true,
    validations: [validateAreaCode],
  });
}

export function useYourDetailsPhoneTelephoneNumberField() {
  return useField({
    defaultValue: InitialAccountDetails.yourDetail.phoneTelephoneNumber,
    required: true,
    validations: [validateTelephoneNumber],
  });
}

export function useYourDetailsDateOfBirthField() {
  return useField({
    defaultValue: InitialAccountDetails.yourDetail.dateOfBirth,
    required: true,
    validations: [validateDateOfBirth],
  });
}

// -- Property Details
export function usePropertyDetailsAddressField() {
  const address = useAccountDetailsStore(s => s.accountDetails?.propertyDetail?.address);
  return useField({
    defaultValue: address ?? InitialAccountDetails.propertyDetail.address,
    required: true,
    validations: [validateInstallationAddress],
  });
}

export function usePropertyDetailsPaperlessBillingField() {
  return useField({
    defaultValue: InitialAccountDetails.propertyDetail.paperlessBilling,
    required: true,
    validations: [],
  });
}

export function usePropertyDetailsAddressSameAsInstallationAddressField() {
  return useField({
    defaultValue: InitialAccountDetails.propertyDetail.billingAddressSameAsInstallationAddress,
    required: true,
    validations: [],
  });
}

export function usePropertyDetailsBillingAddressField() {
  return useField({
    defaultValue: InitialAccountDetails.propertyDetail.billingAddress,
    required: false,
    validations: [validateBillingAddress],
  });
}

export function usePropertyDetailsUseDifferentDeliveryAddressField() {
  let useDifferentDeliveryAddress = useAccountDetailsStore(
    s => s.accountDetails.propertyDetail.useDifferentDeliveryAddress
  );
  return useField({
    defaultValue: useDifferentDeliveryAddress,
    required: true,
    validations: [],
  });
}

export function usePropertyDetailsDeliveryAddressField() {
  //Show field as empty if the delivery address set in the store is just the same address as the property address.
  let deliveryAddressFromStore = useAccountDetailsStore(s => s.accountDetails.propertyDetail.deliveryAddress);
  let addressFromStore = useAccountDetailsStore(s => s.accountDetails.propertyDetail.address);
  let addressIsSameAsDeliveryAddress = addressFromStore?.id === deliveryAddressFromStore?.id;
  return useField({
    defaultValue:
      !addressIsSameAsDeliveryAddress && deliveryAddressFromStore
        ? deliveryAddressFromStore
        : InitialAccountDetails.propertyDetail?.deliveryAddress,
    validations: [validateDeliveryAddress],
  });
}

export function usePropertyDetailServiceContactEmailAddressField() {
  return useField({
    defaultValue: InitialAccountDetails.propertyDetail.serviceContactEmail,
    required: false,
    validations: [validateEmail],
  });
}

// -- Installation Details

export function useInstallationDetailsInstallationDateField() {
  const installationDate = useAccountDetailsStore(s => s.accountDetails.installationDetail.installationDate);
  return useField({
    defaultValue: installationDate ?? InitialAccountDetails.installationDetail.installationDate,
    required: true,
    validations: [validateInstallationDate],
  });
}

export function useInstallationDetailsInstallationTimeField() {
  const installationTime = useAccountDetailsStore(s => s.accountDetails.installationDetail.installationTime);

  return useField({
    defaultValue: installationTime ?? InitialAccountDetails.installationDetail.installationTime,
    required: true,
    validations: [],
  });
}

export function useInstallationDetailsOwnershipField() {
  return useField({
    defaultValue: InitialAccountDetails.installationDetail.rentOrOwn,
    required: true,
    validations: [validateOwnership],
  });
}

// let name = useField({
//   defaultValue: '',
//   required: true,
// });
// let bank = useField({
//   defaultValue: '',
//   required: true,
// });
// let account = useField({
//   defaultValue: ['', '', '', ''],
//   required: true,
//   validations: [validationNumbers, validateBankCard],
// });

export function useDDAccountName() {
  const ddAccountName = useAccountDetailsStore(s => s.accountDetails.paymentInfo.ddAccountName);

  return useField({
    defaultValue: ddAccountName ?? '',
    required: true,
    validations: [validateDDAccountName],
  });
}

export function useDDTC() {
  return useField({
    defaultValue: '',
    required: true,
  });
}

export function useDDBankName() {
  const ddBankName = useAccountDetailsStore(s => s.accountDetails.paymentInfo.ddBankName);

  return useField({
    defaultValue: ddBankName ?? '',
    required: true,
  });
}

export function useDDAccountNumber() {
  const ddAccountNumber = useAccountDetailsStore(s => s.accountDetails.paymentInfo.ddAccountNumber);

  return useField({
    defaultValue: ddAccountNumber ?? ['', '', '', ''],
    required: true,
    validations: [validationNumbers, validateBankCard],
  });
}

export function useCCAccountNumber() {
  const ccAccountNumber = useAccountDetailsStore(s => s.accountDetails.paymentInfo.ccAccountNumber);

  return useField({
    defaultValue: ccAccountNumber ?? '',
    required: true,
  });
}
export function useCCHolderName() {
  const ccHolderName = useAccountDetailsStore(s => s.accountDetails.paymentInfo.ccHolderName);

  return useField({
    defaultValue: ccHolderName ?? '',
    required: true,
  });
}
export function useCCExpire() {
  const ccExpire = useAccountDetailsStore(s => s.accountDetails.paymentInfo.ccExpire);

  return useField({
    defaultValue: ccExpire ?? '',
    required: true,
  });
}
export function useCCCVC() {
  const ccCVC = useAccountDetailsStore(s => s.accountDetails.paymentInfo.ccCVC);

  return useField({
    defaultValue: ccCVC ?? '',
    required: true,
  });
}
