import { AddressPrequalification } from '../../../../../services/prequal';
import { TrackerService } from '../../../../../services/tracker';
import { CustomerInfo } from '../../../helper';
import { BBPlanIndex } from '../../dataServiceBuilder';
import { DetailAddons, getDetailAddOns, getProduct, getProviderAccountId } from '../broadbandOrderDetails';

export interface NonDeliveryDetails {
  addons?: DetailAddons[];
}

/**
 * Builds out a non delivery detail object array which represents a payload object for broadband sent to ICOMS
 * A simplified version of the build details function, intended to be used when the user is an existing customer and is ordering a product(s) that does not need to be delivered
 * @param customer - Customer object needed to get delivery information and site info
 * @param hasStaticIp - True if the user is ordering static ip
 * @param trackingService - Tracking service used to get the users provider account id
 * @param dataProviderUserId - Internet service provider user id needed to build delivery information
 * @param productId - The product key that broadband is being changed to
 * @returns a non delivery details array containing broadband order information needed to build the payload that is sent to ICOMS
 */
export const buildNonDeliveryDetails = async (
  customer: CustomerInfo,
  hasStaticIp: boolean,
  trackingService: TrackerService,
  addonOnly: boolean,
  bbPlanIndex: BBPlanIndex,
  isBoostUpgradeOnly: boolean,
  getPrequalData: (addressId: string) => Promise<AddressPrequalification | undefined>,
  dataProviderUserId?: string,
  productId?: string,
  availableProductKey?: string,
  isVoiceProductAdded = false
): Promise<NonDeliveryDetails[]> => {
  // Modem only available for new broadband customers
  const hasModem = false;
  // Non delivery so physical hardware must be false including mesh
  const hasMesh = false;

  let prequalData = await getPrequalData(customer.tuiAddressCode!);

  const addOnArray = await getDetailAddOns(hasModem, hasMesh, hasStaticIp, prequalData);
  const addOnOnly = addonOnly || isBoostUpgradeOnly;

  let detailObject = {
    ...(addOnOnly ? { addonOnly: addOnOnly } : null),
    ...(isBoostUpgradeOnly ? { hardwareOnly: isBoostUpgradeOnly } : null),
    providerAccountId: await getProviderAccountId(customer, trackingService),
    ...(productId?.length ? { productId: productId } : null),
    broadbandProduct: getProduct(prequalData, bbPlanIndex, isVoiceProductAdded),
    ...(dataProviderUserId !== '' ? { dataProviderUserId: dataProviderUserId } : null),
    ...(availableProductKey?.length ? { availableProductKey: availableProductKey } : null),
    ...(addOnArray?.length ? { addons: addOnArray } : null),
  };

  return [detailObject];
};
