import { useEffect, useState } from 'react';
import { ServiceProvider, ServiceProviderService } from '../services/serviceProvider';

export const useServiceProviderData = (serviceProviderService: ServiceProviderService) => {
  const [providers, setProviders] = useState<ServiceProvider[]>([]);

  useEffect(() => {
    async function getData() {
      let data: ServiceProvider[] = await serviceProviderService.getProviders();

      setProviders(data);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    providers,
  };
};
