import React, { useState } from 'react';
import { T_Product, formatMoneyWithoutSign, useProductWithCouponRemoval, KonakartService } from '@sky-tv-group/shared';
import { Button } from '../button';

export interface IPackageCardProps {
  kkService: KonakartService;
  product: T_Product;
  imageBackgroundClassName?: string;
  imageClassName?: string;
  toggleProduct: (product: T_Product) => Promise<void>;
  removable?: boolean;
}

const PackageCard: React.FC<IPackageCardProps> = ({ kkService, product, toggleProduct, removable = true }) => {
  const isInCart = product.quantityInCart > 0;
  const labelInCart = removable ? 'Remove' : 'Selected';
  const preventRemove = !removable && product.quantityInCart > 0;

  const [isDisabled, setIsDisabled] = useState(false);

  const { handleRemovalOfT_ProductWithToast } = useProductWithCouponRemoval(kkService);
  const handleToggle = async () => {
    setIsDisabled(true);
    await handleRemovalOfT_ProductWithToast(product, toggleProduct, true);
    setIsDisabled(false);
  };

  return (
    <div className={`justify-between w-full`}>
      <div className="shadow-xl flex flex-col h-full p-4">
        <div style={{ flexBasis: '30%' }}>
          <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
            <div className="absolute w-full h-full">
              <img className="object-cover" src={product.custom1} alt={product.description} />
            </div>
          </div>
        </div>

        <div style={{ flexBasis: '60%' }} className="flex-grow">
          <div className="flex flex-col text-navy mt-4 p-4">
            {/* Desktop. */}
            <h4 className="sm:sky-h3-bold md:sky-h4-bold hidden md:block">{product.name}</h4>
            <h4 className="sm:sky-h3-bold md:sky-h4-bold pb-3 hidden md:block">
              <span>$</span>
              {formatMoneyWithoutSign(product?.priceIncTax)}
              <span className="text-xl">/mth</span>
            </h4>
            {/* Mobile. */}
            <h4 className="sky-h4 font-bold md:hidden pb-3 w-full">
              {product.name} &ndash; <span>$</span>
              {formatMoneyWithoutSign(product?.priceIncTax)}
              <span className="text-xl">/mth</span>
            </h4>
            <p className="w-full sky-h5 md:sky-h7">{product.description}</p>
            <div className="py-1">
              <a href={product.url} className="text-blue-light hover:text-navy underline">
                Learn more
              </a>
            </div>
          </div>
        </div>

        <div className="my-10 p-4">
          <Button
            className="m-0"
            colorMode={isInCart ? 'success' : 'light'}
            variant="primary"
            data-testid={'add-product-' + product.sku}
            onClick={handleToggle}
            disabled={isDisabled || preventRemove}>
            {product.quantityInCart > 0 ? labelInCart : 'Add'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { PackageCard };
