import React, { useState } from 'react';
import { caret } from '@sky-tv-group/components';

interface AccordionProps {
  title: string;
  summary: string;
  url: string;
}

const Accordion = ({ title, summary, url }: AccordionProps) => {
  const [active, setActiveState] = useState(false);

  return (
    <div className="py-6 border-t" data-testid={`accordion-${title}`}>
      <div
        onClick={() => setActiveState(!active)}
        className="cursor-pointer flex flex-row items-center justify-between">
        <h5 className="sky-text-midnight p-4 sky-h6-bold md:sky-h6-bold">{title}</h5>
        <button className={`${active ? 'transform -rotate-180' : ''} w-8 h-8`}>{caret}</button>
      </div>
      <div className={`${active ? 'h-auto' : 'h-0'} overflow-hidden`}>
        <h4 className="md:flex pb-0 relative text-gray-darker p-4 sky-h6 md:sky-h7">{summary}</h4>
        <h4 className="md:flex mb-2 relative sky-text-blue p-4 sky-h6 md:sky-h7">
          {url && (
            <a target="blank" href={url}>
              More Details
            </a>
          )}
        </h4>
      </div>
    </div>
  );
};

export { Accordion };
