import React from 'react';
import { HomeAddress } from '../icon';
import { telPhoneNumber } from '@sky-tv-group/shared';

interface ICurrentAddressProps {
  address: string;
  containerClass?: string;
  isBoxUpgrade?: boolean;
}

const CurrentAddress = ({ address, containerClass = 'sky-h5 flex px-4 pb-8', isBoxUpgrade }: ICurrentAddressProps) => {
  return (
    <div className={containerClass}>
      <div className="pr-4">
        <HomeAddress />
      </div>
      {`We have your current address as: ${address}, if your address has changed please call us on ${telPhoneNumber} to update your
      address${isBoxUpgrade ? ' and complete your new Sky Box upgrade.' : '.'}`}
    </div>
  );
};

export { CurrentAddress };
