import { AddressService } from '../../../../services/address';
import { AddressPrequalification } from '../../../../services/prequal';
import { IBroadbandDelivery } from '../../../../store/broadbandDeliveryStore';
import { IBroadbandInstallation } from '../../../../store/broadbandStore';
import { TrackerService } from '../../../../services/tracker';
import { CustomerInfo } from '../../helper';
import { BBPlanIndex } from '../dataServiceBuilder';

export interface SiteInfo {
  siteContactName: string;
  siteContactNumber: string;
  siteContactEmail: string;
  siteAccessInformation: string;
}

export interface LosingProviderDetails {
  serviceProvider: string;
  accountName: string;
  accountNumber: string;
}

export interface DetailAddons {
  type: 'modem' | 'mesh' | 'static_ip';
  productId: string;
  ratePlan?: string;
}

export interface DeliveryInfo {
  contactName: string;
  contactNumber: string;
  contactEmail: string;
  notes: string;
}

export interface DeliveryAddress {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  tui: string;
  deliveryInfo: DeliveryInfo;
}

///////////////////////////////////////////////////////////////////////////////////////
// Below functions are shared by multiple  detail builders and as such are exported  //
///////////////////////////////////////////////////////////////////////////////////////

export const getProviderAccountId = async (customer: CustomerInfo, trackingService: TrackerService) => {
  let providerAccountId = '';
  try {
    if (customer.houseNumber) {
      providerAccountId = (await trackingService.getBroadbandDetails(customer.houseNumber)).providerAccountId;
    }
  } catch {
    // in case when endpoint is not available, use empty string
    providerAccountId = '';
  }
  return providerAccountId;
};

export const getProductKey = (
  prequalData: AddressPrequalification | undefined,
  bbPlanIndex: BBPlanIndex,
  voiceAdded: boolean = false
) => prequalData?.getProductKey(bbPlanIndex, voiceAdded) ?? '';

export const getLocationId = (prequalData: AddressPrequalification | undefined) => prequalData?.getLocationId() ?? '';

export const getLSP = (prequalData: AddressPrequalification | undefined, details: IBroadbandInstallation) => {
  if (prequalData?.isIntactONTJourney() || prequalData?.isNewFibreJourney()) {
    return {};
  }

  return {
    serviceProvider: details.currentServiceProvider, //details.currentServiceProvider !== 'Other' ? details.currentServiceProvider : details.otherServiceProvider,
    accountName: '',
    accountNumber: details.providerAccountNumber !== '' ? details.providerAccountNumber : 'chorusacc01',
  };
};

export const getProduct = (
  prequalData: AddressPrequalification | undefined,
  bbPlanIndex: BBPlanIndex,
  voiceAdded: boolean = false
) => prequalData?.getProduct(bbPlanIndex, voiceAdded) || '';

export const getDetailAddOns = async (
  hasModem: boolean,
  hasMesh: boolean,
  hasStaticIp: boolean,
  prequalData: AddressPrequalification | undefined
): Promise<DetailAddons[]> => {
  let addOns: DetailAddons[] = [];

  if (hasModem) {
    //locate the modem model -- it is the router that has no other part
    const productId = prequalData?.getModemModel();
    // Add modem
    addOns.push({
      type: 'modem',
      productId: productId!,
    });
  }

  if (hasMesh) {
    const meshproductId = prequalData?.getSingleMeshDeviceModel();

    // Add single mesh device
    addOns.push({
      type: 'mesh',
      productId: meshproductId!,
    });
  }

  if (hasStaticIp) {
    const staticIpProductID = prequalData?.getStaticIPProductKey();

    // Add static_ip
    addOns.push({
      type: 'static_ip',
      productId: 'static_ip',
      ratePlan: staticIpProductID ? staticIpProductID : 'Sky_static_ip',
    });
  }
  return addOns;
};

export const getDeliveryInformation = async (
  deliveryDetails: IBroadbandDelivery,
  customer: CustomerInfo,
  email: string,
  contactNumber: string,
  addressService: AddressService
): Promise<DeliveryAddress> => {
  let tui = customer.tuiAddressCode!;
  let deliveryAddress = !deliveryDetails.useDifferentDeliveryAddress
    ? deliveryDetails.defaultDeliveryAddress
    : undefined;

  // If not using default, use the user selected address
  if (!deliveryAddress) {
    // If not using the default, use whatever address the user selected. Update the tui to match
    const installationAddressDetails = await addressService.getAddressDetail(deliveryDetails.deliveryAddress!.id);
    tui = installationAddressDetails.references.tui!;

    deliveryAddress = [
      installationAddressDetails.formatted_delivery_address.line1,
      installationAddressDetails.formatted_delivery_address.line2,
      installationAddressDetails.formatted_delivery_address.line3,
      installationAddressDetails.formatted_delivery_address.line4,
    ];
  }

  // Use billing details first else use primary
  const firstName = customer.billingFirstname || customer.firstName;
  const lastName = customer.billingLastName || customer.lastName;

  return {
    line1: deliveryAddress[0],
    line2: deliveryAddress[1],
    line3: deliveryAddress[2],
    line4: deliveryAddress[3],
    tui: tui,
    deliveryInfo: {
      contactName: `${firstName} ${lastName}`,
      contactNumber: contactNumber,
      contactEmail: email,
      notes: '',
    },
  };
};
