import {
  SPLITIO_KEY,
  useSplitIO,
  SALESFORCE_CHART_SCRIPT_MYACCOUNT,
  SALESFORCE_CHART_SCRIPT_CART,
} from '@sky-tv-group/shared';
import { useEffect } from 'react';

interface SalesforceChatProps {
  target: 'account' | 'cart' | 'broadband';
}

let SalesForceChat = ({ target = 'account' }: SalesforceChatProps) => {
  let splitFlag: string, src: string;

  switch (target) {
    case 'account':
      splitFlag = SPLITIO_KEY.SKY_WEB_SALESFORCE_CHAT_MYACCOUNT;
      src = SALESFORCE_CHART_SCRIPT_MYACCOUNT;
      break;
    case 'cart':
      splitFlag = SPLITIO_KEY.SKY_WEB_SALESFORCE_CHAT_CART;
      src = SALESFORCE_CHART_SCRIPT_CART;
      break;
    case 'broadband':
      splitFlag = SPLITIO_KEY.SKY_WEB_SALESFORCE_CHAT_BROADBAND;
      src = SALESFORCE_CHART_SCRIPT_CART;
      break;
    default:
      splitFlag = SPLITIO_KEY.SKY_WEB_SALESFORCE_CHAT_MYACCOUNT;
      src = SALESFORCE_CHART_SCRIPT_MYACCOUNT;
      break;
  }

  let [enabled] = useSplitIO(splitFlag);

  useEffect(() => {
    if (!enabled) return;
    let s = document.createElement('script');
    s.setAttribute('src', src);
    document.body.appendChild(s);
  }, [enabled]);

  return null;
};

export { SalesForceChat };
