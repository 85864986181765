import { SplitFactory } from '@splitsoftware/splitio-react';
import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { swrConfig } from '@sky-tv-group/shared';
import { SalesForceChat } from '@sky-tv-group/components';
import App from './App';
import { ACCOUNT_URL, SPLIT_AUTHORIZATION_KEY, SPLIT_KEY } from './config';
import history from './history';
import { ScrollToTop } from './ScrollToTop';
import { Auth0Provider } from './store/authStore/auth0';
import config from './store/authStore/auth0.config';
import { useAuth0 } from './store/authStore/authStore';
import { UIAcquisitionJourneyProvider } from './provider/AcquisitionJourney';

export function Root() {
  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: SPLIT_AUTHORIZATION_KEY,
      key: SPLIT_KEY,
    },
  };

  const onRedirectCallback = () => {
    window.history.replaceState({}, document.title, '/');
  };

  function AuthenticatedApp() {
    const { loading, isAuthenticated } = useAuth0();
    useEffect(() => {
      if (isAuthenticated) {
        window.location.href = ACCOUNT_URL;
      }
    }, [isAuthenticated]);
    return loading ? null : (
      <UIAcquisitionJourneyProvider>
        <App />
      </UIAcquisitionJourneyProvider>
    );
  }

  return (
    <SplitFactory config={sdkConfig} updateOnSdkUpdate={true}>
      <SWRConfig value={swrConfig}>
        <Auth0Provider
          config={{
            domain: config.domain,
            client_id: config.clientId,
            redirect_uri: window.location.origin,
            audience: config.audience,
          }}
          onRedirectCallback={onRedirectCallback}>
          <Router history={history}>
            <ScrollToTop />
            <Switch>
              <Route path="/">
                <AuthenticatedApp />
              </Route>
            </Switch>
          </Router>
          <SalesForceChat target="cart" />
        </Auth0Provider>
      </SWRConfig>
    </SplitFactory>
  );
}
