import React, { useState } from 'react';
import { Button } from '../button';
import { ButtonProps } from '../button';
import { Aspect } from '../aspect';
import { FancyPrice } from '../FancyPrice';
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../../config';
import { formatMoney } from '@sky-tv-group/shared';

export interface GeneralActionableCardDetailsTCProps {
  title: string;
  url: string;
}

export interface GeneralActionableCardDetailsProps {
  id: string;
  title: string;
  image: string;
  video?: string;
  description: string;
  note?: string;
  terms?: GeneralActionableCardDetailsTCProps;
  icons?: string[];
  logo?: string;
  price: number;
  wasPrice?: number;
}

export interface GeneralActionableCardProps {
  details: GeneralActionableCardDetailsProps;
  imageBackgroundClassName?: string;
  imageClassName?: string;
  onPrimaryAction: Function;
  removable?: boolean;
  showVideo?: boolean;
  disabled?: boolean;
  primaryButtonText: string;
  primaryButton: ButtonProps;
  lozenge?: React.ReactNode;
  monthly?: boolean;
}

const GeneralActionableCard: React.FC<GeneralActionableCardProps> = ({
  details,
  onPrimaryAction,
  removable = true,
  showVideo = false,
  primaryButtonText,
  primaryButton,
  disabled = false,
  lozenge,
  monthly = true,
}) => {
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [isLoading, setIsLoading] = useState(false);

  const onProductToggle = async () => {
    setIsDisabled(true);
    setIsLoading(true);
    await onPrimaryAction();
    setIsDisabled(false);
    setIsLoading(false);
  };

  return (
    <>
      {lozenge}
      <div
        className="card border flex flex-col justify-start overflow-hidden rounded-md text-navy general-action-card"
        data-testid={`GeneralActionableCard-${details.id}`}>
        <div className="w-full">
          <Aspect ratio={1 / 2}>
            {showVideo && details?.video && (
              <iframe
                title={details.title}
                src={HORIZONTAL_VIDEO_PLAYER_URL + details.video}
                allow="encrypted-media"
                className="w-full h-full"></iframe>
            )}
            {!showVideo && <img className="w-full h-full" src={details.image} alt={details.title}></img>}
          </Aspect>
        </div>

        <div className="flex-1 p-5 gap-y-5 flex flex-col items-center lg:relative">
          {/* Logo image URL. */}
          {details.logo && <img src={details.logo} className="h-10 max-w-full mb-5" alt={details.title} />}
          {!details.logo && <div className="md:sky-h4-black sky-h5-black mb-5 text-center">{details.title}</div>}

          <div className="sm:sky-h7-reg text-15px mb-5 text-center">{details.description}</div>

          {/* Channel rail for a particular package. */}
          <div className={`flex w-full gap-2 items-left justify-left ${details.note ? 'mb-8' : 'mb-3'}`}>
            {details?.icons?.map(
              (d, index) =>
                index < 5 && (
                  <div key={index} className="flex-col w-12 h-10">
                    <img className="w-full h-full object-contain" src={d} alt={'Channel logo'} />
                  </div>
                )
            )}
          </div>

          {/* Contract. */}
          <div>{details.note && <p className="sm:sky-sub mb-5">{details.note}</p>}</div>
          <div className="flex justify-start mb-5 pl-28">
            <div className="flex flex-wrap relative -ml-10">
              {details.wasPrice && (
                <div className="absolute left-0 transform -translate-x-full">
                  <span className="line-through">{formatMoney(Number(details.wasPrice))}</span>
                </div>
              )}

              <FancyPrice
                price={details.price}
                numberStyles="md:sky-h1-black sky-h1 font-bold w-full text-68px"
                containerClasses="text-navy relative mx-6"
                monthly={monthly}
              />
            </div>
          </div>
          <div>
            <p className="sm:sky-sub lg:leading-10 mb-5">
              {details.terms && details.terms.url && (
                <>
                  <a className="underline" href={details.terms.url} target="_blank">
                    {details.terms.title}
                  </a>{' '}
                  apply.
                </>
              )}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <Button
              className="lg:absolute bottom-1"
              colorMode={primaryButton.colorMode}
              icon={isLoading ? 'loading' : primaryButton.icon}
              variant="primary"
              data-testid={'add-product-' + details.id}
              onClick={onProductToggle}
              disabled={isDisabled || !removable}>
              {primaryButtonText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export { GeneralActionableCard };
