// File to store any confirmed copy for shared components, use json config for any copy that is unconfirmed or will change frequently.

import { unformattedTelPhoneNumber } from "@sky-tv-group/shared";

const boxUpgradeModalCopy = {
  internetRequirementMessage:
    "You'll need an internet connection to watch Sky TV services through your new Sky Box, data usage will apply. For the best Sky on demand experience we recommend a minimum download speed of 100Mbps",
  internetRequirementMessageSkyPodIncluded:
    "You'll need an internet connection to watch Sky TV services through your new Sky Box or Sky Pod, data usage fees will apply. For the best Sky on demand experience we recommend a minimum download speed of 10Mbps.",
  paymentOptionsCopy:
    'Choose from a non-refundable one-off fee or an ongoing monthly fee to watch Sky TV services through the new Sky Box for as long as you subscribe to Sky Starter. Usual monthly subscription charges and T&Cs will apply.',
  skyTechnicianCopy:
    'As you already have Sky this means you already have a satellite and there is no need for a technician to visit. Refer to the Quick Start Guide received with your new Sky Box. This will guide you through the process to activate your box. If you would still like support to set up - we can arrange a visit from a technician for a one-off fee.',
  recordingLossMessage:
    "Your new Sky Box won't have your old recordings so you’ll need to watch these before you activate your new Sky Box and also set up series links to your new Box. Your recordings will be deleted from your current box once you send it back.",
  recordingLossMessageSkyPod:
    "Your Sky Pod won't have your old recordings so you’ll need to watch these before you activate your Sky Pod. Your recordings will be deleted from your current box once you send it back.",
  csgInfo:
    'Your new Sky Box upgrade includes a 30-day Satisfaction Guarantee. In the unlikely event you’re not loving your new Sky Box experience within 30 days of activation, you can send it back for a refund and return to your previous Sky Box setup. Usual monthly subscription and additional data usage charges apply.',
} as const;

const newSkyBoxImportantInfo = {
  title: 'Here’s a few things to know about your new Sky Box',
  satisfactionGuarantee:
    'Your new Sky Box upgrade includes a 30-day Satisfaction Guarantee. In the unlikely event you’re not loving your new Sky Box experience within 30 days of activation, you can send it back for a refund and return to your previous Sky Box setup. Usual monthly subscription and additional data usage charges apply.',
  paymentOptions:
    'There’s payment options to suit you. Choose below from a one-off payment for the use of your new Sky Box or a $10 per month recurring fee.',
  internetRequirement:
    'Your new Sky Box connects to your internet, so it’s worth checking your internet plan is grunty enough to stream your favourite sport & entertainment and will cover the extra data usage. For the best Sky On Demand experience, we recommend a minimum download speed of 10Mbps.',
  recordingLoss:
    "Your new Sky Box won't have your old recordings so you’ll need to watch these before you activate your new Sky Box and also set up series links to your New Box. Your recordings will be deleted from your current box once you send it back.",
} as const;

const newSkyBoxwithoutRecordingInfo = {
  upcomingUpdatesInfo: {
    title: 'Upcoming Updates to new Sky Box',
    description:
      'Alternative Audio is not currently available on the new Sky Box: We are busy working on adding these features and they will be delivered in subsequent software updates to your Box, for more information please see ',
  },
  internetInfo: {
    title: 'Internet Connection',
    description:
      `For the best experience on your new Sky box, we recommend a minimum download speed of 100mbps. Data usage fees will apply. If your home lacks an internet connection or your service coverage is poor, please contact our friendly Sky crew on ${unformattedTelPhoneNumber} for alternative solutions to enjoy Sky. `,
  },
  soundSystemInfo: {
    title: 'Looking for technical specifications or have more questions?',
    description: 'Check out our FAQ page https://help.sky.co.nz/s/article/New-Sky-Box-FAQs',
  },
  digitalMusicInfo: {
    title: 'Digital Music ',
    description:
      'The new Sky Box does not support Digital Music channels, however, there are music apps available (e.g. Spotify) on the Apps page. ',
  },
  remindersInfo: {
    titel: 'Digital Music and Setting Reminders ',
    description:
      'These are not currently available on the new Sky experience. You can access music apps (like Spotify) on the Apps page. ',
  },
};

const newSkyBoxwithRecordingInfo = {
  internetInfo: {
    title: 'Internet Connection',
    description:
      `For the best experience on your new Sky box, we recommend a minimum download speed of 100mbps. Data usage fees will apply. If your home lacks an internet connection or your service coverage is poor, please contact our friendly Sky crew on ${unformattedTelPhoneNumber} for alternative solutions to enjoy Sky.`,
  },
  recordingInfo: {
    title: 'Sky Box Recordings',
    description:
      "Your new Sky Box won't have your old recordings so you’ll need to watch these before you activate your new Sky Box and also set up series links to your new Box. Your recordings will be deleted from your current box once you send it back. ",
  },
  soundSystemInfo: {
    title: 'Looking for more technical specifications or have more questions?',
    description: 'Check out our FAQ page',
  },
  digitalMusicInfo: {
    title: 'Digital Music ',
    description:
      'The new Sky Box does not support Digital Music channels, however, there are music apps available (e.g. Spotify) on the Apps page.',
  },
  remindersInfo: {
    title: 'Digital Music and Setting Reminders',
    description:
      'These are not currently available on the new Sky experience. You can access music apps (like Spotify) on the Apps page.',
  },
};

const SkyPodwithRecording = {
  upcomingUpdatesInfo: {
    title: 'Upcoming Updates to Sky Pod',
    description:
      'Alternative Audio is not currently available on the Sky Pod. We are busy working on adding these features and they will be delivered in subsequent software updates to your Pod, for more information please see ',
  },
  internetInfo: {
    title: 'Internet Connection',
    description:
      `For the best experience on your new Sky box, we recommend a minimum download speed of 100mbps. Data usage fees will apply. If your home lacks an internet connection or your service coverage is poor, please contact our friendly Sky crew on ${unformattedTelPhoneNumber} for alternative solutions to enjoy Sky.`,
  },
  recordingInfo: {
    title: 'Sky Box Recordings',
    description:
      "Your Sky Pod won't have your old recordings so you’ll need to watch these before you activate your Sky Pod. Your recordings will be deleted from your current box once you send it back. ",
  },
  TVNZChannelsInfo: {
    title: 'TVNZ Live Channels on Sky Pod',
    description:
      'You can access TVNZ’s live Channels directly via the TVNZ app but not currently on the Sky Pod TV Guide.',
  },
  digitalMusicInfo: {
    title: 'Digital Music And Remainders',
    description:
      'These are not currently available on the new Sky experience. You can access music apps (like Spotify) on the Apps page.',
  },
  soundSystemInfo: {
    title: 'Looking for more technical specifications or have more questions?',
    description:
      'We recommend seeking technical support from the manufacturers to determine if home hub or sound system products can work with Sky TV equipment. While it is possible for these systems to be compatible, Sky does not support or install such systems and is unable to provide technical assistance in the event of any issues.',
  },
};

const SkyPodwithoutRecoding = {
  upcomingUpdatesInfo: {
    title: 'Upcoming Updates to Sky Pod',
    description:
      'Note:the following features are not currently available on the Sky Pod:Pay Per View,Sky Box Office,closed captions and alternative audio,We are busy working on them and they will be delivered in subsequent product updates,for more information see ',
  },
  internetInfo: {
    title: 'Internet Connection',
    description:
      "You'll need an internet connection to watch Sky TV services through your Sky Pod,data usage fees will apply.For the best sky on demand experience we recommend minimum download speed of 100Mbps.",
  },
  soundSystemInfo: {
    title: 'Home Hub Sound System Support',
    description:
      'We recommend seeking technical support from the manufacturers to determine if home hub or sound system products can work with Sky TV equipment.While it is possible for these systems to be compatible,Sky does not support or install such systems and is unable to provide technical assistance in the event of any issues.',
  },
  TVNZChannelsInfo: {
    title: 'TVNZ Channels',
    description:
      'TVNZ channels,including TVNZ 1,TVNZ 2 and Duke,can be found on the TVNZ+ app,but not on the Sky Pod TV Guide.',
  },
  digitalMusicInfo: {
    title: 'Digital Music',
    description:
      'The Sky Pod does not support Digital Music channels,however,there are music apps available(e.g.Spotify)on the Apps page.',
  },
};

const newSkyBoxInfoURL = 'https://help.sky.co.nz/s/products-and-services/newskybox#comingSoon';
const skyPodInfoURL = 'https://help.sky.co.nz/s/products-and-services/skypodhelp#ppv';

export {
  boxUpgradeModalCopy,
  newSkyBoxImportantInfo,
  newSkyBoxInfoURL,
  skyPodInfoURL,
  newSkyBoxwithRecordingInfo,
  newSkyBoxwithoutRecordingInfo,
  SkyPodwithRecording,
};
