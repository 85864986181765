import { toastStoreApi } from '../store/toastStore';
import { AxiosRequestConfig } from 'axios';
import { urlParamsStoreApi } from '../store/urlParamsStore';

export let kkServiceResponseInterceptor = async (err: any) => {
  let toastId = `kk-service-error-${Date.now().toString()}`;
  toastStoreApi.getState().addToast(toastId, {
    type: 'error',
    title: 'Error',
    message: 'Sorry, looks like something went wrong. Please refresh your browser and try again.',
  });
  return Promise.reject(err);
};

export const headerChannelInterceptor = (channel: string) => async (config: AxiosRequestConfig) => {
  let channelFromStore = urlParamsStoreApi.getState().selectedSrc;

  if (
    channelFromStore !== null &&
    channelFromStore !== undefined &&
    channelFromStore !== '' &&
    channelFromStore !== channel
  ) {
    channel = channelFromStore;
  }
  config.headers['channel'] = channel;
  return config;
};
