import React from 'react';

interface Props {
  loginRedirectUrl: string;
}

const Login = ({ loginRedirectUrl }: Props) => {
  return (
    <div className="w-full md:w-auto p-2 font-normal text-center sky-h7-reg md:sky-h7-reg">
      Are you already a customer?{' '}
      <a href={loginRedirectUrl} className="text-blue-pure sky-h7-bold md:sky-h7-bold ">
        Log in
      </a>
    </div>
  );
};

export { Login };
