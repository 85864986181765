import { useState, useEffect } from 'react';
import { Field, useForm } from 'react-jeff';
import { PHONE_AREA_CODES } from '../config';
import { JSONConfigService } from '../services/jsonConfig';
import { AddressPrequalification, PrequalService } from '../services/prequal';
import { ServiceProviderService } from '../services/serviceProvider';
import { useAccountDetailsStore } from '../store/accountDetailsStore';
import { IArrowDelivery, useArrowDeliveryStore } from '../store/arrowDeliveryStore';
import { IBroadbandDelivery } from '../store/broadbandDeliveryStore';
import { IBroadbandInstallation } from '../store/broadbandStore';
import { NullableAddressLookup, InstallationType } from '../types';
import { useServiceProviderData } from './serviceProviderDataHook';
import { useBroadband } from './useBroadband';
import { useBroadbandInstallationDate } from './useBroadbandInstallationDate';
import { useSiteAccessInformationFields } from './useSiteAccessInformationFields';
import { useSkyBroadbandFields } from './useSkyBroadbandFields';

export interface SiteAccessInformationFormFieldProps {
  notesField: Field<string, string>;
  contactNameField: Field<string, string>;
}

// Customer info we only need in this hook (should come from MSL customer endpoint for authenticated accounts or from form inputs)
// Later on we can fetch from other sources as e.g. broadband compare and other 3rd party integrations
export interface CustomerInstallationDetails {
  contactName: string; // firstname + lastname
  contactEmail: string;
  tuiAddressCode: string;
  formattedAddress: string;
  addressLines: string[];
  phoneAreaCode: string;
  phoneTelephoneNumber: string;
}

export interface SkyBroadbandFormFieldsProps {
  installationAddressField: Field<string, string>;
  mobileNumberAreaCodeField: Field<string, string>;
  mobileNumberLineNumberField: Field<string, string>;
  emailField: Field<string, string>;
  currentServiceProviderField: Field<string, string>;
  otherServiceProviderField: Field<string, string>;
  providerAccountNumberField: Field<string, string>;
  useDifferentDeliveryAddressField: Field<boolean, string>;
  installationDateField: Field<Date | null, string>;
  installationTimeField: Field<string, string>;
  defaultDeliveryAddressField: Field<string[], string>;
  activationDateField: Field<Date | null, string>;
  deliveryAddressField: Field<NullableAddressLookup, string>;
}

function useInstallationForm(
  configService: JSONConfigService,
  prequalService: PrequalService,
  serviceProviderService: ServiceProviderService,
  installationType: InstallationType,
  customerDetails?: CustomerInstallationDetails
) {
  const [isShowSiteAccessInfo, setIsShowSiteAccessInfo] = useState(false);
  const [prequalData, setPrequalData] = useState<AddressPrequalification | null>(null);

  const {
    details,
    deliveryDetails,
    getPrequalData,
    setBroadbandDetails,
    setBroadbandDeliveryDetails,
    setIsValid,
  } = useBroadband(prequalService);

  const [accountDetails] = useAccountDetailsStore(state => [state.accountDetails]);
  const { setArrowDeliveryDetails, details: arrowDeliveryDetails } = useArrowDeliveryStore();

  const {
    deliveryAddressField,
    activationDateField,
    installationTimeField,
    installationDateField,
    otherServiceProviderField,
    currentServiceProviderField,
    providerAccountNumberField,
    mobileNumberLineNumberField,
    mobileNumberAreaCodeField,
    emailField,
    defaultDeliveryAddressField,
    installationAddressField,
    useDifferentDeliveryAddressField,
  } = useSkyBroadbandFields({ ...details, ...deliveryDetails });

  const {
    earliestInstallationDate,
    earliestActivationDate,
    installDates,
    activationDates,
    selectedActivationDate,
  } = useBroadbandInstallationDate(configService, prequalData, installationType, activationDateField?.value);

  const { notesField, contactNameField } = useSiteAccessInformationFields();

  // Set initial form values on load
  useEffect(() => {
    if (prequalData) {
      activationDateField.setValue(selectedActivationDate ?? earliestActivationDate);
      installationDateField.setValue(earliestInstallationDate);
      installationTimeField.setValue('AM');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prequalData, earliestActivationDate, earliestInstallationDate]);

  //Default phone number from Details screen if it is a mobile number
  useEffect(() => {
    if (
      accountDetails?.yourDetail?.phoneAreaCode &&
      accountDetails?.yourDetail?.phoneAreaCode !== '' &&
      PHONE_AREA_CODES.includes(accountDetails?.yourDetail?.phoneAreaCode)
    ) {
      if (!mobileNumberLineNumberField.value || mobileNumberLineNumberField.value === '') {
        mobileNumberLineNumberField.setValue(accountDetails?.yourDetail?.phoneTelephoneNumber);
      }

      if (!mobileNumberAreaCodeField.value || mobileNumberAreaCodeField.value === '') {
        mobileNumberAreaCodeField.setValue(accountDetails?.yourDetail?.phoneAreaCode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetails]);

  const { providers } = useServiceProviderData(serviceProviderService);

  useEffect(() => {
    async function getData(tuiAddressCode: string) {
      let prequal = await getPrequalData(tuiAddressCode);
      setPrequalData(prequal!);
    }

    if (customerDetails) {
      installationAddressField.setValue(customerDetails.formattedAddress);
      defaultDeliveryAddressField.setValue(customerDetails.addressLines);
      contactNameField.setValue(customerDetails.contactName);
      emailField.setValue(customerDetails.contactEmail);
      mobileNumberAreaCodeField.setValue(customerDetails.phoneAreaCode);
      mobileNumberLineNumberField.setValue(customerDetails.phoneTelephoneNumber);
      getData(customerDetails.tuiAddressCode);
    }

    if (details) {
      // no need to add details to useEffect dependencies as this are fetched from session storage
      contactNameField.setValue(details.contactName);
      notesField.setValue(details.notes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetails]);

  // otherServiceProviderField.props['onFocus'] = () => {
  //   //do nothing but without this you get a focus js error in the browser
  // };

  const toggleAccessInfoFields = (on: boolean) => {
    contactNameField.setRequired(on);
    emailField.setRequired(on);
    setIsShowSiteAccessInfo(on);
  };

  /**
   * Depends on
   * 1) Installation Type
   * 2) Installation Type + Journey
   * 3) Journey
   * The last one is the most important and decides whether required or not
   */
  useEffect(() => {
    // set states of fields based on values (these must exist on the page and after journeys)
    // otherServiceProviderField.setRequired(currentServiceProviderField.value === 'Other');
    // otherServiceProviderField.setDisabled(currentServiceProviderField.value !== 'Other');

    // Depends on Installation Type
    deliveryAddressField.setRequired(
      installationType === InstallationType.SelfInstall && !useDifferentDeliveryAddressField.value
    );
    deliveryAddressField.setDisabled(
      installationType === InstallationType.SelfInstall && useDifferentDeliveryAddressField.value
    );

    prequalData?.isNewFibreJourney() ? toggleAccessInfoFields(true) : toggleAccessInfoFields(false);

    // Journeys
    if (prequalData?.isIntactONTJourney() || prequalData?.isChorus()) {
      providerAccountNumberField.setRequired(false);
    }
    if (prequalData?.isIntactONTJourney() || prequalData?.isNewFibreJourney()) {
      // otherServiceProviderField.setRequired(false);
      // otherServiceProviderField.setDisabled(true);
      currentServiceProviderField.setRequired(false);
      providerAccountNumberField.setRequired(false);
    }
    if (prequalData?.isNewFibreJourney()) {
      activationDateField.setRequired(false);
      if (prequalData?.consentRequired() || prequalData?.isLfcDoesntTellUsIfConsentRequired()) {
        installationDateField.setRequired(false);
        installationTimeField.setRequired(false);
      }
    } else {
      installationDateField.setRequired(false);
      installationTimeField.setRequired(false);
    }

    // eslint-disable-next-line
  }, [installationType, currentServiceProviderField]);

  const fields: SkyBroadbandFormFieldsProps = {
    installationAddressField,
    mobileNumberAreaCodeField,
    mobileNumberLineNumberField,
    emailField,
    currentServiceProviderField,
    otherServiceProviderField,
    providerAccountNumberField,
    installationDateField,
    installationTimeField,
    activationDateField,
    useDifferentDeliveryAddressField,
    deliveryAddressField,
    defaultDeliveryAddressField,
  };

  const siteAccessInformationFields: SiteAccessInformationFormFieldProps = {
    contactNameField,
    notesField,
  };

  let form = useForm({
    fields: [
      installationAddressField,
      mobileNumberAreaCodeField,
      mobileNumberLineNumberField,
      otherServiceProviderField,
      providerAccountNumberField,
      installationDateField,
      installationTimeField,
      activationDateField,
      useDifferentDeliveryAddressField,
      deliveryAddressField,
      defaultDeliveryAddressField,
      contactNameField,
      emailField,
      notesField,
    ],
    onSubmit: () => {
      //nothing to do -- submit is called manually
    },
  });

  let arrowDeliveryForm = useForm({
    fields: [
      installationAddressField,
      useDifferentDeliveryAddressField,
      deliveryAddressField,
      defaultDeliveryAddressField,
    ],
    onSubmit: () => {
      //nothing to do -- submit is called manually
    },
  });

  useEffect(() => {
    if (arrowDeliveryForm.valid) {
      const arrowDeliveryData: IArrowDelivery = {
        contactName: contactNameField.value,
        mobileNumberAreaCode: mobileNumberAreaCodeField.value,
        mobileNumberLineNumber: mobileNumberLineNumberField.value,
        email: emailField.value,
        installationAddress: installationAddressField.value,
        useDifferentDeliveryAddress: useDifferentDeliveryAddressField.value,
        defaultDeliveryAddress: defaultDeliveryAddressField.value,
        deliveryAddress: deliveryAddressField.value,
      };
      setArrowDeliveryDetails(arrowDeliveryData);
      //todo add/remove the service fee as required
    }
    setIsValid(arrowDeliveryForm.valid);
    // eslint-disable-next-line
  }, [
    arrowDeliveryForm.valid,
    installationAddressField.value,
    useDifferentDeliveryAddressField.value,
    defaultDeliveryAddressField.value,
    deliveryAddressField.value,
    emailField.value,
  ]);

  useEffect(() => {
    if (form.valid) {
      const broadbandData: IBroadbandInstallation = {
        currentServiceProvider: currentServiceProviderField.value,
        otherServiceProvider: otherServiceProviderField.value,
        providerAccountNumber: providerAccountNumberField.value,
        installationDate: installationDateField.value || undefined,
        installationTime: installationTimeField.value,
        activationDate: (activationDateField?.value || installationDateField?.value)!,
        contactName: contactNameField.value,
        notes: notesField.value,
        consentRequired: prequalData?.isConsentRequired(),
      };

      const broadbandDeliveryData: IBroadbandDelivery = {
        mobileNumberAreaCode: mobileNumberAreaCodeField.value,
        mobileNumberLineNumber: mobileNumberLineNumberField.value,
        email: emailField.value,
        installationAddress: installationAddressField.value,
        useDifferentDeliveryAddress: useDifferentDeliveryAddressField.value,
        defaultDeliveryAddress: defaultDeliveryAddressField.value,
        deliveryAddress: deliveryAddressField.value,
      };
      setBroadbandDetails(broadbandData);
      setBroadbandDeliveryDetails(broadbandDeliveryData);
      //todo add/remove the service fee as required
    }
    setIsValid(form.valid);
    // eslint-disable-next-line
  }, [
    form.valid,
    installationAddressField.value,
    mobileNumberAreaCodeField.value,
    mobileNumberLineNumberField.value,
    currentServiceProviderField.value,
    otherServiceProviderField.value,
    installationTimeField.value,
    activationDateField.value,
    useDifferentDeliveryAddressField.value,
    defaultDeliveryAddressField.value,
    deliveryAddressField.value,
    contactNameField.value,
    emailField.value,
    notesField.value,
    installationDateField.value,
    providerAccountNumberField.value,
  ]);

  return {
    form,
    fields,
    arrowDeliveryForm,
    siteAccessInformationFields,
    prequalData,
    providers,
    isShowSiteAccessInfo,
    installDates,
    activationDates,
  };
}

export { useInstallationForm };
