import { useState, useEffect } from 'react';
import { categoryIds } from '../config';
import { KonakartService } from '../services/konakart';
import { AddressPrequalification, PrequalService } from '../services/prequal';
import { useOrderStore } from '../store/orderStore';
import { useProductStore } from '../store/productStore';
import { MyAccountSWR } from '../swr/myaccount';
import { CouponTypeValue, T_Product } from '../types';
import { getTuiAddressCode } from './customer';
import { useBroadband } from './useBroadband';
import { useCartContainer } from './useCartContainer';

export let useVoice = (
  kkService: KonakartService,
  prequalService: PrequalService,
  myAccountSWR: MyAccountSWR,
  prefetchedPrequal?: AddressPrequalification,
  checkFreeProduct: boolean = true
) => {
  const [availableProductsPrequal, setAvailableProductsPrequal] = useState<string[]>([]);
  let { data: customer } = myAccountSWR.useCustomer();
  let { toggleProduct } = useCartContainer(kkService, CouponTypeValue.Upgrade, checkFreeProduct);
  const { getPrequalData } = useBroadband(prequalService);
  /**
   * Load prequal and see what addons we can show to the user based on what is available to the customer
   */
  useEffect(() => {
    let isMounted = true;
    async function getData() {
      const prequal = prefetchedPrequal ?? (await getPrequalData(getTuiAddressCode(customer!)));
      if (isMounted) {
        setAvailableProductsPrequal(prequal?.getAddonKeys?.() ?? []);
      }
    }
    getData();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, prefetchedPrequal]);

  let [voiceProducts, voiceAddonsProducts, voiceCrossCountryProducts] = useProductStore(s => {
    let sortedProduct = s.products.sort((a, b) => b.rating - a.rating);
    return [
      sortedProduct.filter(product => product.categoryId === categoryIds.voice),
      sortedProduct.filter(
        product =>
          product.categoryId === categoryIds.voiceAddons &&
          product.model &&
          availableProductsPrequal.includes(product.model)
      ),
      sortedProduct.filter(
        product =>
          product.categoryId === categoryIds.voiceCrossCountry &&
          product.model &&
          availableProductsPrequal.includes(product.model)
      ),
    ];
  });
  let orderProducts = useOrderStore(s => s.order?.orderProducts);

  let toggle = async (product: T_Product) => {
    return toggleProduct(product, true);
  };

  let toggleCrossCountry = async (product: T_Product) => {
    voiceCrossCountryProducts.filter(p => p.quantityInCart > 0).forEach(p => toggleProduct(p, false));
    return toggleProduct(product, true);
  };

  let isHomePhoneOn = orderProducts?.some(p => p.categoryId === categoryIds.voice);

  return {
    voiceProducts,
    voiceAddonsProducts,
    voiceCrossCountryProducts,
    toggleCrossCountry,
    toggle,
    isHomePhoneOn,
  };
};
