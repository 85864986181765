import { IUpgradableBoxesStore } from '@sky-tv-group/shared/src/store/upgradableBoxesStore';

import {
  getAddedProducts,
  getCouponCampaign,
  ProductData,
  getAddedMultiroomProducts,
  CustomerInfo,
  getArrowDeliveryInformation,
} from '../helper';

import { createCableServicesOccurrences } from './createCableServicesOccurrences';
import { createCableMultiroomOccurrences } from './createCableMultiroomOccurences';
import {
  getInstallationDateTime,
  isAddingMultiroom,
  isTechInstall,
  isTechVisitRequired,
  isDeliveryOnly,
} from './helpers';
import { handleUpgradableBoxLogic } from './handleUpgradableBoxLogic';
import { handleOneTimeChargeLogic } from './handleOneTimeChargeCableLogic';
import {
  broadbandCategories,
  categoryIds,
  couponCodes,
  CreditCampaignCodes,
  decoderHDCompatibilityCodes,
  productSkuIds,
  SPLITIO_KEY,
  voiceCategories,
  VTVMigrationType,
} from '../../../config';
import {
  Campaign,
  CouponTypes,
  Product,
  Service,
  Services,
  T_Coupon,
  T_Product,
  ServiceCodesModify,
  Box,
  DeliveryAddress,
} from '../../../types';
import { IArrowDelivery } from '../../../store/arrowDeliveryStore';
import { AddressService } from '../../../services/address';
import { useIdentifyBox } from '../../boxes/useIdentifyBox';
import { getTreatment } from '../../useSplitIO';
import { CreditOTCCode } from '../../../config';
import { BoxTypes } from '../../../store/boxesStore';

/**
 * Filter out the box products as they will be handled separately
 * Filter out the recording products as they will be handled separately
 * Filter out the monthly fees as they will be handled separately
 * Filter out one of fees as they need to be added to the first occurrence only
 * Returns array of strings for the service codes to add to all box occurrences
 * @param orderPackages
 */
const getCableItems = (orderPackages: ProductData[]) => {
  return getAddedProducts(
    orderPackages.filter(
      p =>
        broadbandCategories.indexOf(p.categoryId) < 0 &&
        voiceCategories.indexOf(p.categoryId) < 0 &&
        p.categoryId !== categoryIds.box &&
        p.categoryId !== categoryIds.recording &&
        p.categoryId !== categoryIds.monthlyFee &&
        p.categoryId !== categoryIds.oneOffFee &&
        p.categoryId !== categoryIds.technicianFee
    ) ?? []
  );
};

/**
 * Retrieves the new multiroom boxes
 * @param orderPackages
 */
const getMultiroomBoxServiceCodes = (orderPackages: T_Product[]) => {
  let skus = getAddedMultiroomProducts(orderPackages);
  // acquisition flow , add 207 to skus list.
  if (!skus.includes(productSkuIds.multiroom.primary)) {
    skus.push(productSkuIds.multiroom.primary);
  }
  return skus;
};

/**
 * Filter out the one of fees boxes
 * Don't include New Sky Box codes = 643, 830 and 838.
 * Returns array of strings for the service codes pertaining to one off fees
 * @param orderPackages
 */
const getOneTimeChargeCableItems = (orderPackages: ProductData[]) => {
  return getAddedProducts(
    orderPackages.filter(
      p =>
        broadbandCategories.indexOf(p.categoryId) < 0 &&
        p.categoryId === categoryIds.oneOffFee &&
        p.sku !== productSkuIds.arrowBoxOneOff.primary &&
        p.sku !== productSkuIds.arrowUpfrontBoxFee.primary &&
        p.sku !== productSkuIds.skyPodOneOffFee.primary &&
        p.sku !== productSkuIds.ArrowTechVisit.primary
    ) ?? []
  );
};

/**
 * Returns array of strings for the service codes pertaining to technician fees
 * @param orderPackages
 */
const getTechnicianFeeCableItems = (orderPackages: ProductData[]) => {
  return getAddedProducts(
    orderPackages.filter(
      p => broadbandCategories.indexOf(p.categoryId) < 0 && p.categoryId === categoryIds.technicianFee
    ) ?? []
  );
};

/**
 * Checks wether we are upgrading to a new sky box.
 *
 * @param boxes
 * @param box
 */
const isBoxSwap = (boxes: Box[] | undefined, box: Box) => {
  // current box we are upgrading
  const fromBox = box.upgradeFrom
    ? boxes?.find(b => b.occurence?.occurrenceNumber === box.upgradeFrom?.occurrenceNumber)
    : undefined;
  const fromBoxProduct = fromBox?.products.find(p => p.categoryId === categoryIds.box);
  const upradeBoxProduct = box?.products.find(p => p.categoryId === categoryIds.box);

  return fromBoxProduct && upradeBoxProduct && fromBoxProduct?.sku !== upradeBoxProduct?.sku;
};

// NOTE: This a wrong way to use this hook.
// Can get away with this call for now, since there is no hooks used inside `useIdentifyBox`
const { isArrowBox, isSkyPod } = useIdentifyBox();

/**
 * Setup the cable services
 * @param orderPackages
 * @param billingServices
 * @param upgradableBoxDetails
 */
export const setupCableServices = async (
  orderPackages: ProductData[],
  downgradedPackages: ProductData[],
  billingServices: Pick<Services, 'CableServiceOccurrence'> | undefined,
  upgradableBoxDetails:
    | Pick<IUpgradableBoxesStore, 'boxInstallRequired' | 'installationDate' | 'installationTime'>
    | undefined,
  addressService: AddressService,
  customer: CustomerInfo,
  fusionRegion?: string,
  boxes?: Box[],
  arrowDeliveryDetails?: IArrowDelivery | undefined,
  wasTechInstallSelected?: boolean,
  wasAddingMultiroom?: boolean,
  isDeliveryOnlyRequired?: boolean,
  vtvTransfer?: boolean,
  vtvTransferType?: VTVMigrationType
) => {
  let cableServices: Service[] = [];
  let occurrence = billingServices?.CableServiceOccurrence ?? [];

  // Create cable services based on occurrence and products being added
  const addedCableItem = getCableItems(orderPackages);
  const removedCableItems = getCableItems(downgradedPackages);
  const requiresTechVisit = isTechVisitRequired(boxes ? boxes : [], vtvTransfer);
  cableServices = createCableServicesOccurrences(addedCableItem, removedCableItems, occurrence);
  const hasUpgradeOrNew = (boxes ?? []).filter(b => b.boxType !== BoxTypes.SUBSCRIBED).length > 0;

  // TODO Handle digi rental accounts.
  // Checks if a existing occurences are in digital rental and appropriately add/remove skus
  // const serviceOccurrence = getServiceOccurence(billingServices);
  // cableServices = handleBroadcastTierUprade(serviceOccurrence, cableServices);

  // One time charge items to be added on first occurrence
  const oneTimeChargeItems = getOneTimeChargeCableItems(orderPackages);
  cableServices = handleOneTimeChargeLogic(occurrence, oneTimeChargeItems, cableServices);

  // Technician Fee items to be added on first occurrence
  const technicianFeeItems = getTechnicianFeeCableItems(orderPackages);
  cableServices = handleOneTimeChargeLogic(occurrence, technicianFeeItems, cableServices);

  const isVTVStandalone = vtvTransfer && vtvTransferType === VTVMigrationType.STANDALONE;
  const { enabled: dartPreorderEnabled } = await getTreatment(SPLITIO_KEY.SKYWEB_VTV_DART_PREORDER);

  // Adding multiroom logic, including VTV orders as all boxes are considered NEW
  const multiroomBoxes = boxes?.filter(p => p.boxType === BoxTypes.NEW);
  const boxUpgrades = boxes?.filter(p => p.boxType === BoxTypes.UPGRADE);

  // Checks if at least one box is a pod to arrow upgrade
  const skyPodToArrowUpgrade = boxUpgrades?.some(
    b => b.upgradeFrom?.entitlements.some(e => isSkyPod(e.code)) && isArrowBox(b)
  );

  let deliveryDetails: DeliveryAddress | undefined;

  if (
    (arrowDeliveryDetails && hasUpgradeOrNew && !wasTechInstallSelected && !requiresTechVisit) ||
    (arrowDeliveryDetails && (isDeliveryOnlyRequired || (dartPreorderEnabled && isVTVStandalone)))
  ) {
    deliveryDetails = await getArrowDeliveryInformation(
      arrowDeliveryDetails,
      customer,
      arrowDeliveryDetails.email,
      arrowDeliveryDetails.mobileNumberAreaCode.concat(arrowDeliveryDetails.mobileNumberLineNumber),
      addressService
    );
  }

  // Checks if all new multiroom boxes are all pods
  const allNewBoxesIsSkyPod = multiroomBoxes?.every(b => isSkyPod(b));

  // Only multiroom
  if (multiroomBoxes && multiroomBoxes.length > 0) {
    multiroomBoxes.forEach(m => {
      let addCableMultiroom = getMultiroomBoxServiceCodes(m.products);

      // Handle install codes.
      if (wasTechInstallSelected) {
        if (!addCableMultiroom.includes(productSkuIds.ArrowTechVisit.primary)) {
          addCableMultiroom.push(productSkuIds.ArrowTechInstall.primary);
        }
      } else {
        if (allNewBoxesIsSkyPod && !skyPodToArrowUpgrade) {
          addCableMultiroom.push(productSkuIds.ArrowSelfInstall.primary);
        } else {
          addCableMultiroom.push(
            isArrowBox(m) || isSkyPod(m) ? productSkuIds.ArrowTechInstall.primary : productSkuIds.skyBoxOTC.primary
          );
        }
      }

      const codes = [...addCableMultiroom, ...addedCableItem];
      cableServices = createCableMultiroomOccurrences(
        codes,
        upgradableBoxDetails,
        cableServices,
        fusionRegion,
        vtvTransfer,
        vtvTransferType,
        deliveryDetails
      );
    });
  }

  // Upgrading box logic
  if (boxUpgrades && boxUpgrades.length > 0) {
    await Promise.all(
      boxUpgrades.map(async m => {
        const boxSwap = isBoxSwap(boxes, m);
        const existingEntitlements = m.upgradeFrom?.entitlements.map(e => e.code) ?? [];
        const addCableMultiroom = getMultiroomBoxServiceCodes(m.products).filter(
          sku => !existingEntitlements.includes(sku)
        );
        const removeCable = m.removedProducts ? getMultiroomBoxServiceCodes(m.removedProducts) : [];
        const addDeliveryAddressToPayload = !wasTechInstallSelected;

        /* Handle install codes. */
        if (wasTechInstallSelected) {
          if (!addCableMultiroom.includes(productSkuIds.ArrowTechVisit.primary)) {
            addCableMultiroom.push(productSkuIds.ArrowTechInstall.primary);
          }
        } else if ((wasAddingMultiroom && !allNewBoxesIsSkyPod) || skyPodToArrowUpgrade) {
          addCableMultiroom.push(productSkuIds.ArrowTechInstall.primary);
        } else {
          addCableMultiroom.push(productSkuIds.ArrowSelfInstall.primary);
        }

        const upgradeFromPod = existingEntitlements.some(code => isSkyPod(code));
        // Add decoder compat codes when customer upgrades a Sky Pod
        if (boxSwap && upgradeFromPod) {
          addCableMultiroom.push(...decoderHDCompatibilityCodes);
        }

        const addCableBox: ServiceCodesModify = {
          customerAction: boxSwap ? 'swap' : '',
          addedItem: addCableMultiroom,
          removedItem: removeCable,
        };

        cableServices = await handleUpgradableBoxLogic(
          addCableBox,
          cableServices,
          m.upgradeFrom?.occurrenceNumber,
          deliveryDetails,
          addDeliveryAddressToPayload
        );
      })
    );
  }

  return cableServices;
};

/**
 * Creates the Cable Service Product
 * @param campaigns
 * @param cableServices
 */
const createCableServiceProduct = (
  campaigns: Campaign[],
  cableServices: Service[],
  upgradableBoxDetails:
    | Pick<IUpgradableBoxesStore, 'boxInstallRequired' | 'installationDate' | 'installationTime'>
    | undefined,
  boxes: Box[] | undefined,
  wasTechInstallSelected: boolean,
  requiresTechVisit: boolean
): Product => {
  let product = {
    type: 'cable',
    campaigns: campaigns,
    services: cableServices,
    installType: '',
    installDate: '',
    installTime: '',
  };

  // handle 01276 01277
  if (campaigns.some(c => c.id === couponCodes.oneMonthOnUsWithSport)) {
    product.services = cableServices.map(c => {
      // 1276 will not work with 242 and 1277 requires 242, 238P, 260P – 1277 will not work with 238 or 260.
      return {
        ...c,
        actions: c.actions.map(action => {
          let add = [...(action.add ?? [])];
          add = add.filter(a => a !== '238' && a !== '260');
          add.push('238P');
          add.push('260P');

          return { ...action, add: add };
        }),
      };
    });
  }

  // has new sky box upfront fee credit offer
  if (campaigns.some(c => Object.values(CreditCampaignCodes.NEW_SKY_BOX).includes(c.id))) {
    let addedOTC = false;
    product.services = product.services.map(c => {
      return {
        ...c,
        actions: c.actions.map(action => {
          let add = [...(action.add ?? [])];
          // has upfront box fee, can only add it once
          if (!addedOTC && add.includes(productSkuIds.arrowUpfrontBoxFee.primary)) {
            add.push(CreditOTCCode.NEW_SKY_BOX_UPFRONT);
            addedOTC = true;
          }
          return { ...action, add: add };
        }),
      };
    });
  }

  const hasUpgradeOrNew = (boxes ?? []).filter(b => b.boxType !== BoxTypes.SUBSCRIBED).length > 0;

  if (hasUpgradeOrNew) {
    /* Tech install was selected by user or was adding fusion box. */
    if (wasTechInstallSelected || requiresTechVisit) {
      let dateTime = getInstallationDateTime(upgradableBoxDetails);
      product.installDate = dateTime[0];
      product.installTime = dateTime[1];
      product.installType = 'TECH';
    } else {
      product.installType = 'SELF';
    }
  }
  return product;
};

/**
 * Builds the cable service product to be consumed in the order request
 * @param orderPackages
 * @param billingServices
 * @param upgradableBoxDetails
 * @param coupon
 * @param existingCampaign
 */
export const buildCableServiceProduct = async (
  orderPackages: ProductData[],
  dthProductDowngrades: ProductData[],
  billingServices: Pick<Services, 'CableServiceOccurrence'> | undefined,
  upgradableBoxDetails:
    | Pick<IUpgradableBoxesStore, 'boxInstallRequired' | 'installationDate' | 'installationTime'>
    | undefined,
  coupons: Pick<T_Coupon, 'description' | 'couponCode' | 'custom5'>[] | undefined,
  addressService: AddressService,
  customer: CustomerInfo,
  fusionRegion?: string,
  boxes?: Box[],
  arrowDeliveryDetails?: IArrowDelivery | undefined,
  vtvTransfer?: boolean,
  vtvTransferType?: VTVMigrationType
) => {
  const campaigns = getCouponCampaign(coupons, c => c.custom5 === CouponTypes.Cable);
  const wasTechInstallSelected = isTechInstall(orderPackages);
  const isDeliveryOnlyRequired = isDeliveryOnly(orderPackages);
  const wasAddingMultiroom = isAddingMultiroom(boxes ? boxes : []);
  const requiresTechVisit = isTechVisitRequired(boxes ? boxes : [], vtvTransfer);
  const cableServices = await setupCableServices(
    orderPackages,
    dthProductDowngrades,
    billingServices,
    upgradableBoxDetails,
    addressService,
    customer,
    fusionRegion,
    boxes,
    arrowDeliveryDetails,
    wasTechInstallSelected,
    wasAddingMultiroom,
    isDeliveryOnlyRequired,
    vtvTransfer,
    vtvTransferType
  );

  return createCableServiceProduct(
    campaigns,
    cableServices,
    upgradableBoxDetails,
    boxes,
    wasTechInstallSelected,
    requiresTechVisit
  );
};
