import create from 'zustand';
import { parseISO } from 'date-fns';
import { AccountDetails, OwnershipFields } from '../types';
import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';
import { sessionStorage } from '../helpers/sessionStorage';

export const InitialAccountDetails: AccountDetails = {
  yourDetail: {
    firstName: '',
    familyName: '',
    email: '',
    phoneAreaCode: '',
    phoneTelephoneNumber: '',
    dateOfBirth: null,
    emailConsent: false,
  },
  propertyDetail: {
    address: null,
    paperlessBilling: true,
    billingAddressSameAsInstallationAddress: true,
    billingAddress: null,
    serviceContactEmail: '',
    useDifferentDeliveryAddress: false,
    deliveryAddress: null,
  },
  installationDetail: {
    installationDate: null,
    installationTime: '',
    rentOrOwn: OwnershipFields.Empty,
  },
  paymentInfo: {
    isDD: true,
  },
};

const sessionStorageStateName = 'Account Details';
const getSessionStorageState = (): AccountDetails => {
  const state = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}', (key, value) => {
    const dateKeys = ['dateOfBirth', 'installationDate'];
    // if its not a date dont worry about it
    if (!dateKeys.includes(key)) return value;
    // otherwise parse the ISO string
    return value ? parseISO(value) : null;
  });
  return state;
};

interface AccountDetailsStore {
  accountDetails: AccountDetails;
  setAccountDetails: (value: AccountDetails) => void;
  clearAccountDetails: () => void;
}

const [useAccountDetailsStore, accountDetailsStoreApi] = create<AccountDetailsStore>(
  ReduxDevTools(
    StatePersistence(
      set => ({
        accountDetails: InitialAccountDetails,
        ...getSessionStorageState(),
        setAccountDetails: (value: AccountDetails) => {
          set({ accountDetails: value }, 'setAccountDetails');
        },
        clearAccountDetails: () => {
          set({ accountDetails: InitialAccountDetails }, 'clearAccountDetails');
          sessionStorage.removeItem(sessionStorageStateName);
        },
      }),
      sessionStorageStateName
    ),
    'Account Details'
  )
);

export { useAccountDetailsStore, accountDetailsStoreApi };
