import { T_Occurrence, T_Product } from '../../types';
import { productSkuIds } from '../../config';
import { productStoreApi } from '../../store/productStore';

function useIdentifyRecording() {
  const products = productStoreApi.getState().products;

  /**
   * VOD only.
   */
  const vodOnly = products.filter(p => p.sku === productSkuIds.noRecording.primary);

  /**
   * Monthly recording fee (500GB for old boxes).
   */
  const monthlyRecordingFee = products.filter(p => p.sku === productSkuIds.arrowBoxRecording.primary);

  /**
   * Upfront recording fee (500GB for old boxes).
   */
  const upfrontRecordingFee = products.filter(p => p.sku === productSkuIds.upfrontRecording.primary);

  /**
   * 1TB storage.
   */
  const recordingWith1TB = products.filter(p => p.sku === productSkuIds.recording1TB.primary);

  /*
    Get the appropiate recording product from KK.
    Steps fo this function:
    - Check if a user recording enabled for the box. We can test this by
      checking if they first have VOD, if so show the VOD no recording SKK
    - If no VOD, check if a recording SKU exists on the box and if not, they box
      doesn't have recording enabled, so return undefined to show there is no recording
    - Lasting if there is a recording enabled SKU, find the correct recording product
   */
  let getRecording = (box: T_Occurrence | undefined) => {
    /*
      First check if the user has recording enabled on the current box, if
      so we can check what the recording is
     */

    /* Check VOD only. */
    const noRecording = box?.entitlements.filter(e => e.code === productSkuIds.noRecording.primary);
    if (noRecording && noRecording.length > 0) {
      return noRecording && noRecording.length > 0 ? vodOnly[0] : undefined;
    }

    /* Check for Recording is enabled. */
    const hasArrowBoxRecording = box?.entitlements.filter(e => e.code === productSkuIds.arrowBoxRecording.primary);
    const hasUpFrontRecording = box?.entitlements.filter(e => e.code === productSkuIds.upfrontRecording.primary);
    if (!hasArrowBoxRecording?.length && !hasUpFrontRecording?.length) {
      return undefined;
    }

    /*
      At this point we know that recording is enabled on this box
     */

    /* Box has 1TB recording. */
    const recording1TB = box?.entitlements.filter(e => e.code === productSkuIds.recording1TB.primary);
    if (recording1TB && recording1TB.length > 0) {
      return recordingWith1TB[0];
    }

    /* If not, check if user has monthly recording. */
    const recording = box?.entitlements.filter(e => e.code === productSkuIds.arrowBoxRecording.primary);
    if (recording && recording.length > 0) {
      return monthlyRecordingFee[0];
    }

    /* If not, check if user has upfront recording. */
    const upfront = box?.entitlements.filter(e => e.code === productSkuIds.upfrontRecording.primary);
    if (upfront && upfront.length > 0) {
      return upfrontRecordingFee[0];
    }
  };

  let getAdditionalRecording = (box: T_Occurrence) => {
    const recording1TB = box?.entitlements.find(e => e.code === productSkuIds.recording1TB.primary);
    if (recording1TB) {
      const recording = box?.entitlements.find(e => e.code === productSkuIds.arrowBoxRecording.primary);
      if (recording) return monthlyRecordingFee[0];
    }
  };

  /* Get the appropiate recording product from an array of products.
   */
  let getRecordingInProducts = (products: T_Product[]) => {
    const recording = products.filter(e => e.sku === productSkuIds.arrowBoxRecording.primary);

    /* Box has recording. */
    if (recording && recording.length > 0) {
      const recording1TB = products.filter(e => e.sku === productSkuIds.recording1TB.primary);
      return recording1TB && recording1TB.length > 0 ? recordingWith1TB[0] : monthlyRecordingFee[0];
    }

    /* Check VOD only, otherwise TODO handle upfront, etc. ? */
    const noRecording = products.filter(e => e.sku === productSkuIds.noRecording.primary);
    return noRecording && noRecording.length > 0 ? vodOnly[0] : undefined;
  };

  return {
    getRecording,
    getRecordingInProducts,
    getAdditionalRecording,
  };
}

export { useIdentifyRecording };
