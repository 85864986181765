import React from 'react';

import { Toast } from './toast';
import { useToastContainer } from '@sky-tv-group/shared';
const ToastContainer = () => {
  const { removeToast, toasts, toastKeys } = useToastContainer();
  return (
    <div
      id="toast-container"
      className="z-1100 w-full fixed top-0 flex flex-col items-center md:right-0 md:w-1/2 mdlg:w-6/10 lg:w-1/2 xl:w-6/14 2xl:w-5/14">
      {toastKeys.map(key => {
        const props = toasts[key];
        return (
          <Toast
            key={key}
            {...props}
            onClose={e => {
              props.onClose?.(e);
              removeToast(key);
            }}></Toast>
        );
      })}
    </div>
  );
};

export { ToastContainer };
