import React, { ComponentProps } from 'react';
import { default as RModal } from 'react-modal';

import { useLockBodyScroll } from '@sky-tv-group/shared';

interface ModalProps {
  customWidthClass?: string;
  showCloseButton?: boolean;
}
export let Modal: React.FC<ModalProps & ComponentProps<typeof RModal>> = ({
  showCloseButton = false,
  customWidthClass,
  children,
  ...rest
}) => {
  useLockBodyScroll();
  let { onRequestClose } = rest;
  return (
    <RModal overlayClassName="modal-overlay" className="modal-self" shouldCloseOnOverlayClick={true} {...rest}>
      <div className={`${customWidthClass ?? 'w-256'} bg-white relative`} style={{ maxWidth: '96vw' }}>
        {showCloseButton && (
          <button onClick={onRequestClose} className="p-2 w-12 h-12 absolute top-0 right-0 z-10">
            {close}
          </button>
        )}
        {children}
      </div>
    </RModal>
  );
};
