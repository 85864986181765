import { Aspect, Button, Image, FancyPrice } from '@sky-tv-group/components';
import React from 'react';

export let TVBroadband = () => {
  return (
    <div>
      {/* card component 1*/}
      <div className="card border-2 flex flex-col md:flex-row overflow-hidden rounded mb-6">
        <div style={{ maxWidth: 440, maxHeight: 330 }} className="flex-1 md:h-full">
          <Aspect ratio={3 / 4}>
            <Image className="h-full w-full object-cover" src={'https://static.sky.co.nz/img/demo/2.png'} alt={''} />
          </Aspect>
        </div>
        <div className="flex-1 p-5 flex flex-col items-center">
          <div className="md:sky-h4-black sky-h5-black mb-5">Sky Starter</div>
          <div className="">
            <img src="https://static.sky.co.nz/img/demo/logos.png" alt="" />
          </div>
          <div className="flex-1"></div>
          <FancyPrice price={140.99} />
          <div className="flex flex-row">
            <Button variant="secondary" colorMode="midnight">
              Explore
            </Button>
            <Button variant="primary" colorMode="light">
              Get Started
            </Button>
          </div>
        </div>
      </div>
      {/* card component 2*/}
      <div className="card border-2 flex flex-col md:flex-row overflow-hidden rounded mb-6 relative">
        <div className="absolute top-1 left-1 z-5 bg-pink text-white md:sky-h6-bold p-2 pr-0 rounded">
          <div
            className="bg-pink absolute w-6 h-full top-0 rounded"
            style={{ left: '100%', transform: 'skew(-15deg,0) translate(-10px,0)', zIndex: -1 }}></div>
          <span className="z-5"> Best value</span>
        </div>
        <div style={{ maxWidth: 440, maxHeight: 330 }} className="flex-1">
          <Aspect ratio={3 / 4}>
            <Image className="h-full w-full object-cover" src={'https://static.sky.co.nz/img/demo/1.png'} alt={''} />
          </Aspect>
        </div>
        <div className="flex-1 p-5 flex flex-col items-center md:items-start">
          <div className="md:sky-h4-black sky-h5-black mb-5">Sky TV Lightning Fast Deal</div>
          <div className="">
            <img src="https://static.sky.co.nz/img/demo/logos.png" alt="" />
          </div>
          <div className="md:sky-h7-reg mb-5">Save $20/mth off Sky Broadband with Sky TV, Fibre</div>
          <div className="md:sky-copyright mb-5">Sky TV on a 12 month contract. Offer T&Cs and exit fees apply.</div>
          <div className="flex-1"></div>
          <div className="flex justify-between items-center  md:flex-row flex-col-reverse w-full">
            <div className="flex flex-row">
              <Button variant="secondary" colorMode="midnight" className="md:ml-0">
                Explore
              </Button>
              <Button variant="primary" colorMode="light">
                Get Started
              </Button>
            </div>
            <div>
              <FancyPrice price={140.99} />
            </div>
          </div>
        </div>
      </div>

      <div className="p-24"></div>
    </div>
  );
};
