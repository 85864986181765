import React from 'react';
import classnames from 'classnames';
export interface OneMonthOnUsProps {
  className?: string;
  copy?: string;
  tAndCLink?: string;
  showShown?: boolean;
  learnMoreLink?: string;
  boldText?: string;
  children?: React.ReactNode;
  bottom?: React.ReactNode;
  icon?: string;
  bgColor?: string;
  gradient?: string;
}

const generateText = ({ text, boldText }: { text: string; boldText?: string }) => {
  if (text?.includes(boldText as string)) {
    const newText = text.replace(
      boldText as string,
      `<span style="font-size: 14px;font-weight: 800">${boldText}</span>`
    );
    return <span dangerouslySetInnerHTML={{ __html: newText }}></span>;
  }

  return text;
};

const OneMonthOnUs = React.memo(
  ({
    className = '',
    copy = '',
    showShown = true,
    learnMoreLink,
    tAndCLink,
    boldText,
    children,
    bottom,
    icon = 'https://static.sky.co.nz/sky/icons/1monthonus.svg',
    bgColor = '#00013A',
    gradient = 'radial-gradient(circle at top left,#363fbc 0,#00b1eb 100%)',
  }: OneMonthOnUsProps) => {
    if (!showShown) return null;
    return (
      <div className={classnames('flex flex-col justify-center items-center  m-auto', className)}>
        <div className="flex flex-row relative w-full text-navy bg-white border rounded-md mh-120 md:mh-142">
          {/* desktop */}
          <div
            className="hidden md:flex h-full w-20 sm:w-40  items-start rounded-l-md absolute "
            style={{ background: gradient }}>
            <img
              alt={'one month on us'}
              src={icon}
              className="absolute  block lg:hidden"
              style={{
                width: 110,
                height: 110,
                left: 80,
                top: 13,
              }}
            />
            <img
              alt={'one month on us'}
              src={icon}
              className=" absolute   hidden lg:block"
              style={{
                width: 101,
                height: 101,
                left: '68%',
                top: 11,
              }}
            />
          </div>
          {/* mobile */}
          <div className="md:hidden block w-32">
            <img
              alt={'one month on us'}
              src={icon}
              className="absolute  block lg:hidden"
              style={{
                width: 100,
                height: 100,
                left: 10,
                top: 25,
                zIndex: 7,
              }}
            />
            <div className="h-full w-24 flex items-start rounded-l-md absolute" style={{ background: gradient }}></div>
          </div>
          <div
            className="text-left flex flex-col sm:flex-row justify-start flex-grow pr-4 pt-6 pb-4 items-center container"
            style={{
              backgroundColor: bgColor,
              color: 'white',
              borderRadius: '0.5rem',
            }}>
            {children}
            {copy && (
              <h3 className="sky-h6-black ml-40 sm:ml-60 flex flex-col sm:flex-row">
                <span
                  className="sky-h6-reg"
                  style={{
                    fontSize: '14px',
                  }}>
                  {generateText({ text: copy, boldText })}
                  &nbsp;
                  {tAndCLink && (
                    <a
                      href={tAndCLink}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="underline md:whitespace-no-wrap">
                      {'Offer T&Cs apply'}
                    </a>
                  )}
                  {'.'}
                </span>
                {learnMoreLink && (
                  <div
                    style={{
                      textAlign: 'left',
                    }}>
                    <a
                      href={learnMoreLink}
                      className="sky-button sky-button--primary-dark fr-banner-carousel-slide-primary mt-4 sm:mt-0"
                      style={{
                        display: 'block',
                        width: '138px',
                      }}>
                      Learn More
                    </a>
                  </div>
                )}
              </h3>
            )}
          </div>
        </div>
        {bottom}
      </div>
    );
  }
);

export { OneMonthOnUs };
