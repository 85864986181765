import { useEffect } from 'react';

import { addBusinessDays, addDays, addMonths, eachWeekendOfInterval, getDay, parseISO } from 'date-fns';
import { useField, useForm } from 'react-jeff';
import { useUpgradableBoxes } from './useUpgradableBoxes';
import { isValid } from 'date-fns';
import { JSONConfigService } from '../services/jsonConfig';
import { KonakartService } from '../services/konakart';
import { useUpgradableBoxesStore } from '../store/upgradableBoxesStore';
import { MyAccountSWR } from '../swr/myaccount';
import { useJSONConfig } from './useJSONConfig';
import { validateRequiredDate } from './validations';
import { InstallationTime, InstallationTimeCodeKeys, InstallationTimeCodes, NullableDate } from '../types';

function useUpgradableBoxesForm(
  configService: JSONConfigService,
  kkService: KonakartService,
  myAccountSWR: MyAccountSWR
) {
  // store
  const { setDetails, details, errorAvailableDates } = useUpgradableBoxesStore();
  // hooks
  const config = useJSONConfig(configService);

  const dayAfterTomorrowDate: Date = addDays(new Date(), 2);
  const maxInstallationDate: Date = addMonths(dayAfterTomorrowDate, 3);

  const excludeInstallationSundayDates: Date[] = eachWeekendOfInterval({
    start: dayAfterTomorrowDate,
    end: maxInstallationDate,
  }).filter(date => getDay(date) === 0);

  const excludeDates = ((config?.excludeInstallationDates ?? []) as string[])
    .map(str => parseISO(str))
    .concat(excludeInstallationSundayDates);

  function useInstallationDetailsInstallationDateField() {
    return useField<NullableDate>({
      // The value field in the React Native date-picker cannot be null, so adding a default value here.
      defaultValue: null,
      required: true,
      // React Native date picker can also not exclude dates. This is checked here.
      validations: [validateRequiredDate('Installation Date')],
    });
  }

  function useInstallationDetailsInstallationTimeField() {
    return useField<InstallationTime>({
      defaultValue: InstallationTimeCodeKeys.AM,
      required: true,
      validations: [],
    });
  }

  const installationDetailsInstallationDateField = useInstallationDetailsInstallationDateField();
  const installationDetailsInstallationTimeField = useInstallationDetailsInstallationTimeField();

  // Form - validation component
  const form = useForm({
    fields: [installationDetailsInstallationTimeField, installationDetailsInstallationDateField],
    onSubmit: () => {}, // nothing to submit
  });

  /**
   * On load set the installation time and date based on store value
   */
  useEffect(() => {
    if (isValid(details.installationDate)) {
      installationDetailsInstallationDateField.setValue(details.installationDate);
    }
    installationDetailsInstallationTimeField.setValue(details.installationTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the upgrade box store to manage the date / time information stored
   */
  useEffect(() => {
    setDetails({
      isValid: form.valid,
      installationDate:
        installationDetailsInstallationDateField.value == null
          ? addDays(new Date(), 2)
          : installationDetailsInstallationDateField.value,
      installationTime: installationDetailsInstallationTimeField.value,
      boxInstallRequired: true,
    });
  }, [
    form.valid,
    installationDetailsInstallationDateField.value,
    installationDetailsInstallationTimeField.value,
    setDetails,
    errorAvailableDates,
  ]);

  return {
    dayAfterTomorrowDate,
    maxInstallationDate,
    form,
    installationDetailsInstallationDateField,
    installationDetailsInstallationTimeField,
  };
}

export { useUpgradableBoxesForm };
