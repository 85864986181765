import { format } from 'date-fns';
import { categoryIds, productSkuIds } from '../../../config';
import { AddressService } from '../../../services/address';
import { IUpgradableBoxesStore } from '../../../store/upgradableBoxesStore';
import {
  BillingAddress,
  Campaign,
  InstallationTime,
  NullableAddressLookup,
  NullableDate,
  OrderServiceAddress,
  ServiceOccurrence,
  T_Coupon,
  T_OrderProduct,
  Box,
} from '../../../types';
import { formatAddress } from '../../formatAddress';
import { ProductData } from '../helper';
import { BoxTypes } from '../../../store/boxesStore';
import { useIdentifyBox } from '../../boxes/useIdentifyBox';

/**
 * Method to get the custom 7 services that is passed to the first occurrence to add and remove
 * e.g. 618,614,612:620,615 add 618,614,612 and remove 620,615
 * @param orderProducts
 */
export const getUpgradableProductCodes = (orderProducts: ProductData[], occurence: ServiceOccurrence[]) => {
  let addedItem: string[] = [];
  let removedItem: string[] = [];
  let customerAction: string = '';

  orderProducts.forEach(p => {
    // TODO: Check adding New Sky Box as a multiroom for an existing user.
    /** If custom 7 is empty and they're not adding a Sky box multiroom, it means they're upgrading to Arrow. */
    // if (!p.custom7 && p.sku !== productSkuIds.skyBoxMultiroom.primary) {
    //   /** For Arrow, just add the SKU. */
    //   addedItem = [...p.sku.split(',')];
    //   customerAction = 'swap';
    //   /** For Arrow, upgrade */
    //   if (p.upgradeFrom) {
    //     removedItem = [...p.upgradeFrom];
    //   }

    //   return { addedItem, removedItem };
    // }

    let temporarySplitArray = p.custom7?.split(':');
    if (temporarySplitArray) {
      if (temporarySplitArray[0]) {
        addedItem = [...addedItem, ...temporarySplitArray[0].split(',')];
      }
      if (temporarySplitArray[1]) {
        removedItem = [...removedItem, ...temporarySplitArray[1].split(',')];
      }
    }
  });
  return { customerAction, addedItem, removedItem };
};

/**
 *
 * @param date
 * @param time
 */

const getDateTimeFormatted = (date: NullableDate, time: InstallationTime): [string, string] => {
  if (date && time) {
    return [format(date, 'yyyy-MM-dd'), time];
  }
  return ['', ''];
};

/**
 *
 * @param upgradableBoxDetails
 */
export const getInstallationDateTime = (
  upgradableBoxDetails:
    | Pick<IUpgradableBoxesStore, 'boxInstallRequired' | 'installationDate' | 'installationTime'>
    | undefined
): [string, string] => {
  let dateTime: [string, string] = ['', ''];

  if (upgradableBoxDetails && upgradableBoxDetails.boxInstallRequired) {
    dateTime = getDateTimeFormatted(upgradableBoxDetails.installationDate, upgradableBoxDetails.installationTime);
  }
  return dateTime;
};

export const formatDate = (date: Date): string => {
  return format(date, 'yyyy-MM-dd');
};

export const getPrimaryPhoneNumber = (phoneAreaCode: string, phoneTelephoneNumber: string) => {
  return {
    type: 'primary',
    phoneNumber: {
      countryCode: '+64',
      areaCode: phoneAreaCode,
      number: phoneTelephoneNumber,
    },
  };
};

export const getInstallationTimeCode = (installationTime: string, technicianLookupEnabled: boolean): string => {
  switch (installationTime) {
    case 'AM1': {
      return technicianLookupEnabled ? 'AM1' : 'AM';
    }
    case 'AM2': {
      return technicianLookupEnabled ? 'AM2' : 'AM';
    }
    case 'PM1': {
      return technicianLookupEnabled ? 'PM1' : 'PM';
    }
    case 'PM2': {
      return technicianLookupEnabled ? 'PM2' : 'PM';
    }
    default: {
      return '';
    }
  }
};

export const getDateOfBirth = (dateOfBirth: NullableDate): string => {
  if (!dateOfBirth) {
    throw new Error('Date of birth cannot be null');
  }
  return formatDate(dateOfBirth);
};

export const getInstallationDate = (installationDate: NullableDate): string => {
  if (!installationDate) {
    throw new Error('Installation cannot be null');
  }
  return formatDate(installationDate);
};

export const getCouponCampaign = (coupons: Pick<T_Coupon, 'couponCode' | 'description'>[] | undefined): Campaign[] => {
  let campaigns = coupons?.map(coupon => ({
    id: coupon.couponCode,
    description: coupon.description,
  }));
  return campaigns ?? [];
};

// Return array of ICOMS codes of the passed order products.
export const getAddedProducts = (products: Pick<T_OrderProduct, 'product'>[]): string[] => {
  const dataCategoryIds = [
    categoryIds.broadband,
    categoryIds.broadbandServices,
    categoryIds.broadbandOneOffFee,
    categoryIds.broadbandDevices,
    categoryIds.voice,
    categoryIds.voiceAddons,
    categoryIds.voiceCrossCountry,
    categoryIds.broadbandTechnicianFee,
  ];
  // products added
  let addedItems: string[] = [];
  // kk products that wil have the correct skuids
  products
    .filter(p => !dataCategoryIds.includes(p.product.categoryId))
    .forEach(p => {
      let tempSplitArray = p.product.sku.split(',').map(x => x.trim());
      let tempCustomServiceCodesSplit: string[] = [];
      if (p.product.custom3) {
        tempCustomServiceCodesSplit = p.product.custom3.split(',').map(x => x.trim());
      }
      addedItems = [...addedItems, ...tempSplitArray, ...tempCustomServiceCodesSplit];
    });
  return addedItems;
};

export const getServiceAddress = async (
  addressService: AddressService,
  address: NullableAddressLookup
): Promise<OrderServiceAddress> => {
  if (!address) {
    throw new Error('Address cannot be null');
  }

  const installationAddressDetails = await addressService.getAddressDetail(address.id);

  // incorrect type of address -- needs to be physical and returns a tui
  if (!installationAddressDetails.references) {
    throw new Error('No address found');
  }

  if (!installationAddressDetails.references.tui) {
    throw new Error('Incorrect type of address provided');
  }

  const { structured_address: structuredAddress } = installationAddressDetails;
  const installationAddressFormatted = formatAddress(
    // Not all addresses have road_abv and returning null causes problems in ICOMS.
    structuredAddress.road_abv
      ? `${structuredAddress.street_number} ${structuredAddress.street_name} ${structuredAddress.road_abv}`
      : `${structuredAddress.street_number} ${structuredAddress.street_name}`,
    structuredAddress.suburb,
    getCityValue(structuredAddress.town,structuredAddress.region),
    structuredAddress.postcode
  );

  const serviceAddress: OrderServiceAddress = {
    street: installationAddressFormatted.line1,
    suburb: installationAddressFormatted.line2,
    city: installationAddressFormatted.line3,
    postCode: installationAddressFormatted.line4,
    tuiid: installationAddressDetails.references.tui,
  };

  return serviceAddress;
};

export const getDeliveryAddress = async (
  addressService: AddressService,
  address: NullableAddressLookup
): Promise<OrderServiceAddress> => {
  if (!address) {
    throw new Error('Address cannot be null');
  }

  const deliveryAddressDetails = await addressService.getAddressDetail(address.id);

  // incorrect type of address -- needs to be physical and returns a tui
  if (!deliveryAddressDetails.references) {
    throw new Error('No address found');
  }

  if (!deliveryAddressDetails.references.tui) {
    throw new Error('Incorrect type of address provided');
  }

  const { structured_address: structuredAddress } = deliveryAddressDetails;
  const installationAddressFormatted = formatAddress(
    // Not all addresses have road_abv and returning null causes problems in ICOMS.
    structuredAddress.road_abv
      ? `${structuredAddress.street_number} ${structuredAddress.street_name} ${structuredAddress.road_abv}`
      : `${structuredAddress.street_number} ${structuredAddress.street_name}`,
    structuredAddress.suburb,
    getCityValue(structuredAddress.town,structuredAddress.region),
    structuredAddress.postcode
  );

  const serviceAddress: OrderServiceAddress = {
    street: installationAddressFormatted.line1,
    suburb: installationAddressFormatted.line2,
    city: installationAddressFormatted.line3,
    postCode: installationAddressFormatted.line4,
    tuiid: deliveryAddressDetails.references.tui,
  };

  return serviceAddress;
};

export const getBillingAddress = async (
  addressService: AddressService,
  address: NullableAddressLookup
): Promise<BillingAddress> => {
  if (!address) {
    throw new Error('Billing address is empty');
  }

  const billingAddressDetails = await addressService.getAddressDetail(address.id);

  // incorrect type of address -- needs to return dpid for postal
  if (!billingAddressDetails.references) {
    throw new Error('No address found');
  }

  if (!billingAddressDetails.references.dpid) {
    throw new Error('Incorrect type of address provided for billing address');
  }

  const billingFormattedAddress = formatAddress(
    billingAddressDetails.formatted_address.line1,
    billingAddressDetails.formatted_address.line2,
    billingAddressDetails.formatted_address.line3,
    billingAddressDetails.formatted_address.line4
  );

  return {
    dpid: billingAddressDetails.references.dpid,
    street: billingFormattedAddress.line1,
    suburb: billingFormattedAddress.line2,
    city: billingFormattedAddress.line3,
    postcode: billingFormattedAddress.line4,
  };
};

/* Check if user is adding a multiroom. */
export const isAddingMultiroom = (box: Box[]) => {
  let multiroomsAdded = box.filter(c => c.boxType === 'NEW');
  return multiroomsAdded.length > 0;
};

/* Check if user is adding a Fusion multiroom. */
export const isAddingFusionMultiroom = (box: Box[]) => {
  let multiroomsAdded = box?.filter(c => c.boxType === 'NEW');
  const fusionBox = multiroomsAdded.find(op => op.products.find(p => p.sku === productSkuIds.skyBoxCharge.primary));
  return multiroomsAdded.length > 0 && fusionBox ? true : false;
};

/* Check if user is adding a Fusion multiroom. */
export const isFusionCodeInThere = (box: string[]) => {
  let multiroomsAdded = box.find(() => productSkuIds.skyBoxCharge.primary);
  return multiroomsAdded ? true : false;
};

/* Check if a unique box is already in the cart for adding multiroom. */
export const isMultiroomAlreadyAdded = (actualMultiroomsBeingAdded: ProductData[], sku: string) => {
  let multiroomsAdded = actualMultiroomsBeingAdded.filter(c => c.sku === sku);
  return multiroomsAdded.length > 0;
};

/* Check if user is adding a multiroom or upgrading a box. */
export const isAddingOrUpgradingBox = (box: Box[]) => {
  let multiroomsAdded = box.filter(c => c.boxType === 'UPGRADE' || c.boxType === 'NEW');
  return multiroomsAdded.length > 0;
};

/* Check if tech install was selected by customer. */
export const isTechInstall = (orderPackages: ProductData[]) => {
  const techVisit = orderPackages.find(op => op.sku === productSkuIds.ArrowTechVisit.primary);
  return techVisit ? true : false;
};

/* Check if tech install was selected by customer. */
export const isDeliveryOnly = (orderPackages: ProductData[]) => {
  const boxes = orderPackages.filter(op => op.categoryId === categoryIds.box) ?? [];
  const requireDeliveryOnly = boxes?.length > 0 && boxes.every(op => op.sku === productSkuIds.skyPod.primary);
  return requireDeliveryOnly;
};

// Checks if boxes added requires a tech visit
export const isTechVisitRequired = (boxes: Box[], vtvTransfer: boolean = false) => {
  const { isArrowBox, isSkyPod } = useIdentifyBox();

  // picks NEW boxes and fusion to arrow upgrades
  const newOrUpgradedBoxesFromStore = boxes.filter(b => b.boxType === BoxTypes.NEW || b.boxType === BoxTypes.UPGRADE);
  //adding new sky or arrow box
  const newSkyBoxesToStore = boxes.filter(b => b.boxType === BoxTypes.NEW && isArrowBox(b));
  // all arrow upgrade are SELF install unless its a NEW arrow box
  const upgradingToArrowOnly = newOrUpgradedBoxesFromStore.every(b => b.boxType === BoxTypes.UPGRADE && isArrowBox(b));
  // pod upgrade to arrow are TECH install
  const hasArrowUpgradeFromPod = newOrUpgradedBoxesFromStore.some(
    b => b.upgradeFrom?.entitlements.some(c => isSkyPod(c.code)) && isArrowBox(b)
  );

  const allNewEntitlements = newOrUpgradedBoxesFromStore.map(b => b.products.map(p => p.sku)).flatMap(p => p);
  const allBoxesThatNeedsVisit: string[] = [
    productSkuIds.skyBoxCharge.primary,
    productSkuIds.arrowBoxBlack.primary,
    ...(vtvTransfer || hasArrowUpgradeFromPod || newSkyBoxesToStore?.length > 0
      ? [productSkuIds.arrowBox.primary]
      : []),
  ];

  return allNewEntitlements.some(ent => allBoxesThatNeedsVisit.includes(ent));
};

export const getCityValue = (town:any,region:string) => {
  let city = town || region;
  if(city && city.length > 25){
    city = city.substring(0,25)
  }
  return city;
}
