import { categoryIds, CheckoutStates } from '@sky-tv-group/shared';
import { hasProduct, isDeliveryOnly } from '../../helper';
import history from '../../history';

const hasBroadband = () => hasProduct(categoryIds.broadband);
const hasVoice = () => hasProduct(categoryIds.voice);
const hasBox = () => hasProduct(categoryIds.box);
const requireDeliveryOnly = () => isDeliveryOnly();

const detailsPath = '/checkout/account-form';
const tvPath = '/checkout/dth-form';
const bbPath = '/checkout/broadband-form';
const voicePath = '/checkout/voice-form';
const confirmPath = '/checkout/confirm';

export const back = (page: string) => {
  let url = '';
  switch (page) {
    case CheckoutStates.TV: {
      url = detailsPath;
      break;
    }
    case CheckoutStates.BROADBAND: {
      if (hasBox() && !requireDeliveryOnly()) url = tvPath;
      else url = detailsPath;
      break;
    }
    case CheckoutStates.VOICE: {
      url = bbPath;
      break;
    }
    case CheckoutStates.REVIEW: {
      if (hasVoice()) url = voicePath;
      else if (hasBroadband()) url = bbPath;
      else if (hasBox() && !requireDeliveryOnly()) url = tvPath;
      else url = detailsPath;
      break;
    }
  }

  history.push(url);
};

export const next = (page: string) => {
  let url = '';
  switch (page) {
    case CheckoutStates.DETAILS: {
      if (hasBox() && !requireDeliveryOnly()) url = tvPath;
      else if (hasBroadband()) url = bbPath;
      else if (hasVoice()) url = voicePath;
      else url = confirmPath;
      break;
    }
    case CheckoutStates.TV: {
      if (hasBroadband()) url = bbPath;
      else if (hasVoice()) url = voicePath;
      else url = confirmPath;
      break;
    }
    case CheckoutStates.BROADBAND: {
      if (hasVoice()) url = voicePath;
      else url = confirmPath;
      break;
    }
    case CheckoutStates.VOICE: {
      url = confirmPath;
      break;
    }
  }

  history.push(url);
};

export const edit = (page: string) => {
  let url = '';
  switch (page) {
    case CheckoutStates.DETAILS: {
      url = detailsPath;
      break;
    }
    case CheckoutStates.TV: {
      url = tvPath;
      break;
    }
    case CheckoutStates.BROADBAND: {
      url = bbPath;
      break;
    }
    case CheckoutStates.VOICE: {
      url = voicePath;
      break;
    }
  }

  history.push(url);
};
