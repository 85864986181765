import React from 'react';
import { SelectElement, ValidatedPhoneNumber, ValidatedTextInput, blueIcon } from '../../../index';
import {
  DirectoryListingCodes,
  ServiceProvider,
  YesNoOptionCheckCodes,
  T_Customer,
  useSplitIO,
  SPLITIO_KEY,
  useNewPhoneNumber,
  HomePhoneFormFieldProps,
  OrderService,
  VoiceService,
  numberFilter,
} from '@sky-tv-group/shared';
import { Form } from 'react-jeff';
import { homePhoneText } from './homePhoneText';
import { HomePhoneSelector } from './HomePhoneSelector';

import Tippy from '@tippyjs/react';

interface HomePhoneProps {
  form: Form<string>;
  providers: ServiceProvider[];
  fields: HomePhoneFormFieldProps;
  customer: T_Customer;
  hasExistingNumber: () => boolean;
  hasSameProvider: () => boolean;
  keepSameNumber: () => boolean;
  otherPhoneProvider: () => boolean;
  requireAccountNumber: () => boolean;
  showHomePhoneSelector: boolean;
  notShowPhoneProviderMatchBroadbandDropDownField: boolean;
  orderService: OrderService;
  voiceService: VoiceService;
  selectedTui?: string | undefined;
  labelEnabled: boolean;
}
const HomePhone: React.FunctionComponent<HomePhoneProps> = ({
  form,
  providers,
  fields,
  customer,
  hasExistingNumber,
  hasSameProvider,
  keepSameNumber,
  otherPhoneProvider,
  requireAccountNumber,
  showHomePhoneSelector,
  notShowPhoneProviderMatchBroadbandDropDownField,
  orderService,
  voiceService,
  selectedTui,
  labelEnabled,
}) => {
  const [directoryListingEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_VOICE_DIRECTORY_LISTING);
  const options = [YesNoOptionCheckCodes.SELECT_OPTION_BELOW, YesNoOptionCheckCodes.YES, YesNoOptionCheckCodes.NO];

  const { phoneNumbers, loading } = useNewPhoneNumber(
    voiceService,
    orderService,
    selectedTui ? selectedTui : customer?.tuiAddressCode,
    fields.phoneNumberSelectList.props.value
  );

  let directoryListings: string[] = [
    DirectoryListingCodes.UNAVAILABLE_TO_EMERGENCY_SERVICES,
    DirectoryListingCodes.PUBLISHED,
    DirectoryListingCodes.UNPUBLISHED,
  ];

  if (hasExistingNumber() && keepSameNumber()) {
    directoryListings = [
      DirectoryListingCodes.NO_CHANGE_TO_EXISTING_DIRECTORY_LISTING,
      DirectoryListingCodes.AVAILABLE_TO_EMERGENCY_SERVICES,
      ...directoryListings,
    ];
  } else {
    directoryListings = [DirectoryListingCodes.AVAILABLE_TO_EMERGENCY_SERVICES, ...directoryListings];
  }

  return (
    <>
      {labelEnabled && (
        <div className="pt-8">
          <h4 className="bg-gray-light sky-h3 md:sky-h4 text-center h-24 flex justify-center items-center font-bold">
            {homePhoneText.labelText.title}
          </h4>
        </div>
      )}
      <div className="flex flex-col items-end md:flex-row md:flex-wrap md:pb-6">
        <div className="md:w-6/12 w-full pr-4 pt-4 pb-4">
          <label className="block sky-h7-reg mb-2">{homePhoneText.labelText.haveExistingPhone}</label>
          <SelectElement
            id="broadband-voice-existing-phone-number"
            className="w-full"
            fieldValidation={fields.existingHomePhoneDropDownField}
            formValidation={form}
            onChange={fields.existingHomePhoneDropDownField.props.onChange}
            value={fields.existingHomePhoneDropDownField.props.value}>
            {options.map(option => {
              return (
                <option
                  className="sky-input sky-input--option"
                  key={option.value === '' ? 'empty' : option.value}
                  value={option.value}
                  disabled={option.value === ''}>
                  {option.text}
                </option>
              );
            })}
          </SelectElement>
        </div>
        {/* Has an existing number */}
        {hasExistingNumber() && (
          <>
            <div className="md:w-6/12 w-full md:pl-4 pt-4 pb-4">
              <label className="block sky-h7-reg mb-2">{homePhoneText.labelText.keepCurrentPhoneNumber}</label>
              <SelectElement
                id="broadband-voice-keep-existing-number"
                style={{ paddingRight: '2rem' }} // Override padding from 'sky-input--text' class as Options are long.
                className="w-full truncate"
                fieldValidation={fields.keepSamePhoneNumberDropDownField}
                formValidation={form}
                onChange={fields.keepSamePhoneNumberDropDownField.props.onChange}
                value={fields.keepSamePhoneNumberDropDownField.props.value}>
                {options.map(option => {
                  return (
                    <option
                      className="sky-input sky-input--option"
                      key={option.value === '' ? 'empty' : option.value}
                      value={option.value}
                      disabled={option.value === ''}>
                      {option.text}
                    </option>
                  );
                })}
              </SelectElement>
            </div>
            {keepSameNumber() && (
              <>
                <div className="lg:w-2/6 w-full md:pr-4 pt-4 pb-4">
                  <label className="block sky-h7-reg mb-2">{homePhoneText.labelText.enterPhoneNumber}</label>
                  <ValidatedPhoneNumber
                    areaCodeField={fields.phoneNumberAreaCodeInputField}
                    areaCodeFieldPlaceholderText={'00'}
                    areaCodeFieldContainerClasses={'w-3/12 xl:w-3/12'}
                    lineNumberField={fields.phoneNumberLineNumberInputField}
                    lineNumberFieldPlaceholderText={'0000000'}
                    lineNumberFieldContainerClasses={'w-9/12 xl:w-9/12'}
                    onAreaCodeFieldChange={fields.phoneNumberAreaCodeInputField.props.onChange}
                    onLineNumberFieldChange={fields.phoneNumberLineNumberInputField.props.onChange}
                    id="broadband-voice-phone-number"
                    areaCodeMaxLength={2}
                    lineNumberMaxLength={7}
                  />
                </div>
                {notShowPhoneProviderMatchBroadbandDropDownField ? null : (
                  <div className="lg:w-4/6 w-full md:pl-4 pt-4 pb-4">
                    <label className="block sky-h7-reg mb-2">
                      {homePhoneText.labelText.currentProviderSameAsBroadband}
                    </label>
                    <SelectElement
                      id="broadband-voice-provider-same-as-broadband"
                      className="w-full"
                      fieldValidation={fields.phoneProviderMatchBroadbandDropDownField}
                      formValidation={form}
                      onChange={fields.phoneProviderMatchBroadbandDropDownField.props.onChange}
                      value={fields.phoneProviderMatchBroadbandDropDownField.props.value}>
                      {options.map((option, index) => {
                        return (
                          <option
                            className="sky-input sky-input--option"
                            key={index}
                            value={option.value}
                            disabled={option.value === ''}>
                            {option.text}
                          </option>
                        );
                      })}
                    </SelectElement>
                  </div>
                )}
                {/* Home phone provider is not same as the broadband provider */}
                {!hasSameProvider() && providers.length > 0 && (
                  <>
                    <div className="md:w-6/12 w-full md:pr-4 pt-4 pb-4">
                      <label className="block sky-h7-reg mb-2">
                        {homePhoneText.labelText.selectCurrentHomeProvider}
                      </label>
                      <SelectElement
                        id="broadband-voice-existing-provider"
                        className="w-full"
                        fieldValidation={fields.currentProviderDropdownField}
                        formValidation={form}
                        onChange={fields.currentProviderDropdownField.props.onChange}
                        value={fields.currentProviderDropdownField.props.value}>
                        <option className="sky-input sky-input--option" value="" disabled>
                          Select an option
                        </option>
                        {providers.map(item => (
                          <option className="sky-input sky-input--option" key={item.code} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                      </SelectElement>
                    </div>
                    {/* User selected provider not within list */}
                    {otherPhoneProvider() && (
                      <div className="md:w-6/12 w-full p-4">
                        <label className="block sky-h7-reg mb-2">
                          {homePhoneText.labelText.selectCurrentProviderTextInput}
                        </label>
                        <ValidatedTextInput
                          fieldValidation={fields.currentProviderInputField}
                          formValidation={form}
                          id="broadband-voice-existing-provider-other"
                          className="w-full"
                          type="text"
                          onChange={fields.currentProviderInputField.props.onChange}
                          placeholder="Enter current home phone provider"
                        />
                      </div>
                    )}
                  </>
                )}
                {requireAccountNumber() && (
                  <div className="md:w-6/12 w-full md:pr-4 pt-4 pb-4">
                    <div className="flex">
                      <label className=" sky-h7-reg mb-2 inline-block">{homePhoneText.labelText.accountNumber} </label>
                      <Tippy
                        placement="right"
                        content="This is to make sure we are changing over the correct phone number and your current home phone provider knows which account to stop billing">
                        <div className="p-1">{blueIcon}</div>
                      </Tippy>
                    </div>

                    <ValidatedTextInput
                      fieldValidation={fields.accountNumberField}
                      formValidation={form}
                      id="broadband-voice-account-number"
                      className="w-full"
                      type="text"
                      onChange={numberFilter(fields.accountNumberField.props.onChange)}
                      placeholder="Enter account number"
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        {directoryListingEnabled && (
          <div className="w-full p-4">
            <label className="block sky-h7-reg mb-2">{homePhoneText.labelText.directoryListing}</label>
            <SelectElement
              id="broadband-voice-keep-existing-number"
              className="w-full"
              fieldValidation={fields.directoryListingDropDownField}
              formValidation={form}
              onChange={fields.directoryListingDropDownField.props.onChange}
              value={fields.directoryListingDropDownField.props.value}>
              {directoryListings.map(option => {
                return (
                  <option className="sky-input sky-input--option" key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </SelectElement>
          </div>
        )}

        {showHomePhoneSelector && (
          <HomePhoneSelector
            labelText={homePhoneText.labelText.chooseNewNumber}
            reservationText={homePhoneText.labelText.newNumberReservation}
            numbers={phoneNumbers}
            loading={loading}
            field={fields.phoneNumberSelectList}
          />
        )}
      </div>
    </>
  );
};

export { HomePhone };
