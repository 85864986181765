import { SWRConfiguration } from 'swr';

export let swrConfig: SWRConfiguration = {
  revalidateOnFocus: false,
  // 1min for all endpoints, except /billingg is 10min
  dedupingInterval: 1 * 60 * 1000,

  onErrorRetry: (error, key, option, revalidate, { retryCount = 0, dedupe }) => {
    if (retryCount >= 3) return;

    setTimeout(() => {
      revalidate({ retryCount, dedupe: true });
    }, 5000);
  },
};
