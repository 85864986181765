import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  FormElement,
  Loader,
  ConditionsCheckbox,
  AccountDetailsCheckbox,
  AccountDetailsReview,
  InstallationDetailsReview,
  Button,
  Login,
} from '@sky-tv-group/components';

import { useReview } from '../../hooks/useReview';

import {
  SPLITIO_KEY,
  useSplitIO,
  useReceiveMarketingMessagesCheckbox,
  useAccountDetailsStore,
  formatMoney,
  useTotals,
  links,
  useProductInCart,
  CheckoutStates,
  useboxesStore,
  productSkuIds,
} from '@sky-tv-group/shared';
import { useCheckoutManager } from '../../hooks/useCheckoutManager';

import { myAccountSWR } from '../../services';
import { back, edit } from './navigation';
import {
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS_DOMESTIC,
  IS_CAPTCHA_ENABLED,
  CAPTCHA_KEY,
  ACCOUNT_URL,
} from '../../config';
import { useAcquisitionJourneyState } from '../../provider/AcquisitionJourney';
import { BackButton } from '../../components/back-button';
import { BreadcrumbContainer } from '../../components/BreadcrumbContainer';

const Confirm = () => {
  const [enableMarketingMessage] = useSplitIO(SPLITIO_KEY.SKYWEB_ALLOW_MARKETING_MESSAGE);
  const { hasPackage, hasBroadband } = useCheckoutManager();
  const {
    form,
    prequal,
    submitting,
    captchaRef,
    accountDetails,
    broadbandDetails,
    broadbandDeliveryDetails,
    keyThingsToKnowField,
    termsAndConditionsField,
  } = useReview();

  // Checkbox fields - might need to
  const marketingMessagesCheckbox = useReceiveMarketingMessagesCheckbox();
  const { setAccountDetails } = useAccountDetailsStore();
  const totals = useTotals(myAccountSWR);
  const { broadbandProductInCart } = useProductInCart();
  const { progressBar } = useAcquisitionJourneyState();
  const { requireDeliveryOnly } = useProductInCart();

  const boxes = useboxesStore(s => s.boxes);

  const hasNonPod = boxes.some(
    b => b.boxType === 'NEW' && !b.products.some(p => p.sku === productSkuIds.skyPod.primary)
  );

  const isNewFiber = prequal?.isNewFibreJourney();
  const showBroadbandInstallationDetails =
    hasBroadband &&
    (broadbandDeliveryDetails?.installationAddress ||
      (isNewFiber ? broadbandDetails?.installationDate : broadbandDetails?.activationDate) ||
      (isNewFiber ? broadbandDetails?.installationTime : undefined));

  const onTermsAndConditionsChanged = (value: boolean) => termsAndConditionsField.setValue(value);
  const onKeyThingsToKnowFieldChanged = (value: boolean) => keyThingsToKnowField.setValue(value);

  const sectionTitle = requireDeliveryOnly ? 'Delivery Details' : 'Installation Details';

  let stickyRef = useRef<HTMLDivElement>(null) as any;

  function handleStickyBarScroll() {
    let footerElement = document.querySelector('#footerTermsAndCond') as any;
    if (stickyRef.current) {
      if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
        footerElement.style.marginBottom = stickyRef.current.offsetHeight + 'px';
      } else {
        footerElement.style.marginBottom = '0px';
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleStickyBarScroll);
    return () => {
      window.removeEventListener('scroll', handleStickyBarScroll);
    };
  }, []);

  // Set installation address from check address results
  useEffect(() => {
    handleMarketingMaterials(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = (state: string) => {
    edit(state);
  };

  const handleMarketingMaterials = (value: boolean) => {
    marketingMessagesCheckbox.setValue(value);
    accountDetails.marketingEmails = value;
    setAccountDetails(accountDetails);
  };

  const handleBack = () => {
    back(CheckoutStates.REVIEW);
  };

  return (
    <div>
      <BreadcrumbContainer progressBarStatus={false} />
      <div className="container m-auto md:pb-10 lg:mt-16 lg:px-6 mt-4 text-navy" data-testid="confirm-form">
        <div className="flex flex-col justify-center items-center py-4 md:w-100 lg:hidden">
          <BackButton
            buttontext={broadbandProductInCart ? 'Go back to Broadband Installation' : 'Go back to Sky TV Installation'}
            onClick={() => back(CheckoutStates.REVIEW)}
          />
        </div>
        <FormElement {...form.props}>
          <h4 className="md:sky-h5-black sky-h5-black sky-text-midnight pb-10">Account Details</h4>
          <AccountDetailsReview accountDetails={accountDetails} edit={edit} showSectionHeader={false} />

          <h4 className="md:sky-h5-black sky-h5-black sky-text-midnight py-10">{sectionTitle}</h4>
          <InstallationDetailsReview
            hasPackage={hasPackage && hasNonPod}
            hasBroadband={hasBroadband}
            edit={onEdit}
            accountDetails={accountDetails}
            broadbandDetails={broadbandDetails}
            broadbandDeliveryDetails={broadbandDeliveryDetails}
            isNewFiber={prequal?.isNewFibreJourney()}
            showSectionHeader={false}
            showMainHeader={false}
            showBroadbandInstallationDetails={!!showBroadbandInstallationDetails}
          />

          <div className="flex flex-col p-4 mb-4">
            <h4 className="flex-1 sky-h6-bold md:sky-h6-bold mb-4">Terms & Conditions</h4>
            <ConditionsCheckbox id="key-things" field={keyThingsToKnowField} onChange={onKeyThingsToKnowFieldChanged}>
              <label htmlFor="key-things">
                I have read the &nbsp;
                <a
                  className="underline cursor-pointer sky-text-daylight"
                  href={links.tvKeyThings}
                  target="_blank"
                  rel="noreferrer noopener">
                  Key Things to Know
                </a>
              </label>
            </ConditionsCheckbox>
            <ConditionsCheckbox
              id="broadband-terms"
              field={termsAndConditionsField}
              onChange={onTermsAndConditionsChanged}>
              <label htmlFor="broadband-terms">
                I accept the &nbsp;
                <a
                  className="underline cursor-pointer sky-text-daylight"
                  href={TERMS_AND_CONDITIONS_DOMESTIC}
                  target="_blank"
                  rel="noreferrer noopener">
                  Sky Consumer Terms
                </a>
              </label>
              ,&nbsp;
              {broadbandProductInCart && (
                <a
                  className="underline cursor-pointer sky-text-daylight"
                  href={links.bbTerms}
                  target="_blank"
                  rel="noreferrer noopener">
                  Sky Broadband Terms,
                </a>
              )}
              &nbsp; and &nbsp;
              <a
                className="underline cursor-pointer sky-text-daylight"
                href={PRIVACY_POLICY}
                target="_blank"
                rel="noreferrer noopener">
                Privacy Policy
              </a>
            </ConditionsCheckbox>

            {enableMarketingMessage && (
              <AccountDetailsCheckbox
                marketingMessagesCheckbox={marketingMessagesCheckbox}
                onChange={handleMarketingMaterials}
              />
            )}
          </div>
          {IS_CAPTCHA_ENABLED && (
            <ReCAPTCHA
              ref={captchaRef as any}
              size="invisible"
              badge="bottomright"
              sitekey={CAPTCHA_KEY}
              theme="light"
            />
          )}
          {submitting && (
            <div className="w-full h-full z-50 fixed bottom-0 top-0 right-0 left-0">
              <Loader bgOpacity={true} bgColor={'bg-black'} />
            </div>
          )}

          <div className=" rounded-lg border overflow-hidden text-white mt-12">
            <div className="flex justify-between bg-blue-pure p-4 md:sky-h5-bold ">
              <div className=" text-white">Your First Bill</div>
              <div>
                <strong>{formatMoney(totals.priceYouPayToday)}</strong>
              </div>
            </div>
            <div
              className="block fixed
             min-w-full bottom-0 lg:static z-50 md:static left-0"
              ref={stickyRef}>
              <div
                className={`flex justify-between md:p-12 lg:p-12 lg:static md:static min-w-full bottom-0 z-50 lg:shadow-none md:shadow-none lg:rounded-none md:rounded-none sticky-custom bg-white left-0 p-5`}>
                <Button
                  data-testid="back-to-dth-form-button"
                  colorMode="light"
                  variant="secondary"
                  onClick={handleBack}
                  disabled={false}
                  className="hidden lg:block md:block">
                  Back
                </Button>
                <Button
                  type="submit"
                  disabled={submitting}
                  className="min-w-full min-w-fit sky-bg-lime"
                  colorMode="success">
                  {submitting ? 'Submitting' : 'Place Order'}
                </Button>
              </div>
            </div>
          </div>
        </FormElement>
      </div>
    </div>
  );
};

export { Confirm };
