export const redirectToSubdomain = (
  app: 'ICE' | 'MY_ACCOUNT' | 'BROADBAND',
  path?: string,
  openInNewTab = true
): void => {
  let domain = process.env.REACT_APP_DOMAIN;

  // Handle sub domain extension
  switch (app) {
    case 'ICE':
      /* Comment for local testing. */
      domain = `get.${domain}`;
      /* Uncomment for local testing. */
      // domain = `localhost:3100`;
      break;
    case 'MY_ACCOUNT':
      domain = `account.${domain}`;
      break;
    case 'BROADBAND':
      domain = `broadband.${domain}`;
      break;
  }

  if (path) domain = `${domain}${path}`;

  /* Comment for local testing. */
  domain = `https://${domain}`;
  /* Uncomment for local testing. */
  // domain = `http://${domain}`;

  if (openInNewTab) {
    window.open(domain, '_blank')?.focus();
  } else {
    window.location.href = domain;
  }
};
