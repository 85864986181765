import React from 'react';
import cx from 'classnames';
import { useActor } from '@xstate/react';
import {
  CheckoutNavigationContext,
  CheckoutNavigationContextType,
  ICheckoutRoute,
  CheckoutStates,
} from '@sky-tv-group/shared';
import { StepperLine } from './StepperLine';
import { StepperDot } from './StepperDot';
import { useCheckoutSticky } from './useCheckoutSticky';
import { AnyEventObject, Interpreter } from 'xstate';

interface Props {
  allowedRoutes: string[];
  routes: ICheckoutRoute[];
  headerRef: React.RefObject<HTMLDivElement>;
  service: Interpreter<
    CheckoutNavigationContext,
    any,
    AnyEventObject,
    { value: any; context: CheckoutNavigationContext }
  >;
}

const CheckoutNavigation = ({ allowedRoutes, routes, headerRef, service }: Props) => {
  const [state] = useActor(service);
  const { sticking } = useCheckoutSticky(headerRef);

  const isValid = (type: string): boolean => {
    const context = state ? state.context[type as CheckoutNavigationContextType] : null;
    return context ? context.valid : false;
  };

  const hasVisited = (stateIndex: number) => {
    const currentStateIndex = state ? allowedRoutes.indexOf(state.value as string) : 0;
    return stateIndex <= currentStateIndex;
  };

  const classes = cx('sticky m-auto pt-10 px-8 md:px-12 pb-16 top-4 bg-white z-10', {
    'shadow-bottom': sticking,
  });

  return (
    <div className={classes}>
      <div className="md:container m-auto">
        <div className="w-full sm:w-3/4 m-auto">
          <div className="flex items-center">
            {routes
              .filter(r => allowedRoutes.includes(r.type))
              .map((route, index) => {
                return (
                  <React.Fragment key={route.type}>
                    {index !== 0 && <StepperLine active={hasVisited(index) || isValid(route.type)} />}
                    <StepperDot
                      title={route.title}
                      index={index + 1}
                      visited={hasVisited(index) || isValid(route.type)} // if state has been visited, show blue
                      valid={route.type !== CheckoutStates.REVIEW && isValid(route.type)} // current form page is valid, show check icon
                      match={state ? state.matches(route.type) : false} // url matches the state, show blue but don't show check icon
                    />
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { CheckoutNavigation };
