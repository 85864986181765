import { useCallback } from 'react';

import { AddressService } from '../services/address';
import { accountDetailsStoreApi, InitialAccountDetails, useAccountDetailsStore } from '../store/accountDetailsStore';
import { useSplitIO } from './useSplitIO';
import { SPLITIO_KEY } from '../config';
import { NullableAddressLookup, NullableDate, Ownership } from '../types';
import {
  validateAreaCode,
  validateBillingAddress,
  validateDateOfBirth,
  validateEmail,
  validateFamilyName,
  validateFirstName,
  validateInstallationAddress,
  validateTelephoneNumber,
} from './validations';

/**
 * Checkout Hook that interacts with the checkoutStore for managing checkout process
 */
function useAccountDetails(addressService: AddressService) {
  const [accountDetails, setAccountDetails] = useAccountDetailsStore(state => [
    state.accountDetails,
    state.setAccountDetails,
  ]);

  const [technicianLookupEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_TECHNICIAN_LOOKUP);
  const yourDetail = accountDetails.yourDetail;
  const propertyDetail = accountDetails.propertyDetail;
  const installationDetails = accountDetails.installationDetail;
  const marketingEmails = accountDetails.marketingEmails;
  const paymentInfo = accountDetails.paymentInfo;

  const updateMarketingEmails = useCallback(
    (value: boolean) => {
      setAccountDetails({ ...accountDetails, marketingEmails: value });
    },
    [accountDetails, setAccountDetails]
  );

  const updateFirstName = useCallback(
    (value: string) => {
      setAccountDetails({ ...accountDetails, yourDetail: { ...accountDetails.yourDetail, firstName: value } });
    },
    [accountDetails, setAccountDetails]
  );

  const updateFamilyName = useCallback(
    (value: string) => {
      setAccountDetails({ ...accountDetails, yourDetail: { ...accountDetails.yourDetail, familyName: value } });
    },
    [accountDetails, setAccountDetails]
  );

  const updateEmail = useCallback(
    (value: string) => {
      setAccountDetails({ ...accountDetails, yourDetail: { ...accountDetails.yourDetail, email: value } });
    },
    [accountDetails, setAccountDetails]
  );

  const updateAreaCode = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        yourDetail: { ...accountDetails.yourDetail, phoneAreaCode: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateTelephoneNumber = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        yourDetail: { ...accountDetails.yourDetail, phoneTelephoneNumber: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateDateOfBirth = useCallback(
    (value: NullableDate) => {
      setAccountDetails({ ...accountDetails, yourDetail: { ...accountDetails.yourDetail, dateOfBirth: value } });
    },
    [accountDetails, setAccountDetails]
  );

  const updateEmailConsent = useCallback(
    (value: boolean) => {
      setAccountDetails({ ...accountDetails, yourDetail: { ...accountDetails.yourDetail, emailConsent: value } });
    },
    [accountDetails, setAccountDetails]
  );
  // Property Details
  const updateAddress = useCallback(
    (value: NullableAddressLookup) => {
      const updateTui = async () => {
        if (value && value?.id !== '') {
          let installationAddressDetails = await addressService.getAddressDetail(value?.id);
          value.tui = installationAddressDetails?.references?.tui;
        }
      };
      updateTui().then(() => {
        if (technicianLookupEnabled) {
          setAccountDetails({
            ...accountDetails,
            propertyDetail: { ...accountDetails.propertyDetail, address: value },
            installationDetail: { rentOrOwn: '', installationDate: null, installationTime: '' },
          });
        } else {
          setAccountDetails({
            ...accountDetails,
            propertyDetail: { ...accountDetails.propertyDetail, address: value },
          });
        }
      });
    },
    [accountDetails, setAccountDetails, technicianLookupEnabled]
  );

  const updatePaperlessBilling = useCallback(
    (value: boolean) => {
      setAccountDetails({
        ...accountDetails,
        propertyDetail: { ...accountDetails.propertyDetail, paperlessBilling: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateServiceContactEmail = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        propertyDetail: { ...accountDetails.propertyDetail, serviceContactEmail: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateBillingAddressSameAsInstallation = useCallback(
    (value: boolean) => {
      setAccountDetails({
        ...accountDetails,
        propertyDetail: { ...accountDetails.propertyDetail, billingAddressSameAsInstallationAddress: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateBillingAddress = useCallback(
    (value: NullableAddressLookup) => {
      setAccountDetails({
        ...accountDetails,
        propertyDetail: { ...accountDetails.propertyDetail, billingAddress: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateDeliveryAddressSameAsInstallation = useCallback(
    (value: boolean) => {
      setAccountDetails({
        ...accountDetails,
        propertyDetail: { ...accountDetails.propertyDetail, useDifferentDeliveryAddress: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateDeliveryAddress = useCallback(
    (value: NullableAddressLookup) => {
      const updateTui = async () => {
        if (value && value?.id !== '') {
          let deliveryAddressDetails = await addressService.getAddressDetail(value?.id);
          value.tui = deliveryAddressDetails?.references?.tui;
        }
      };
      updateTui().then(() => {
        setAccountDetails({
          ...accountDetails,
          propertyDetail: {
            ...accountDetailsStoreApi.getState().accountDetails.propertyDetail,
            deliveryAddress: value,
          },
        });
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateInstallationDate = useCallback(
    (value: NullableDate) => {
      setAccountDetails({
        ...accountDetails,
        installationDetail: { ...accountDetails.installationDetail, installationDate: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateInstallationTime = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        installationDetail: { ...accountDetails.installationDetail, installationTime: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateRentOrOwn = useCallback(
    (value: Ownership) => {
      setAccountDetails({
        ...accountDetails,
        installationDetail: { ...accountDetails.installationDetail, rentOrOwn: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateIsDD = useCallback(
    (value: boolean) => {
      setAccountDetails({
        ...accountDetails,
        paymentInfo: { ...accountDetails.paymentInfo, isDD: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateDDAccountName = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        paymentInfo: { ...accountDetails.paymentInfo, ddAccountName: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateDDAccountNumber = useCallback(
    (value: string[]) => {
      setAccountDetails({
        ...accountDetails,
        paymentInfo: { ...accountDetails.paymentInfo, ddAccountNumber: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateDDBankName = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        paymentInfo: { ...accountDetails.paymentInfo, ddBankName: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateCCAccountNumber = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        paymentInfo: { ...accountDetails.paymentInfo, ccAccountNumber: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const updateCCHolderName = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        paymentInfo: { ...accountDetails.paymentInfo, ccHolderName: value },
      });
    },
    [accountDetails, setAccountDetails]
  );
  const updateCCExpire = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        paymentInfo: { ...accountDetails.paymentInfo, ccExpire: value },
      });
    },
    [accountDetails, setAccountDetails]
  );
  const updateCCCVC = useCallback(
    (value: string) => {
      setAccountDetails({
        ...accountDetails,
        paymentInfo: { ...accountDetails.paymentInfo, ccCVC: value },
      });
    },
    [accountDetails, setAccountDetails]
  );

  const manualValidation = (): string[] => {
    const errors = [
      ...validateEmail(yourDetail.email),
      ...validateTelephoneNumber(yourDetail.phoneTelephoneNumber),
      ...validateAreaCode(yourDetail.phoneAreaCode),
      ...validateDateOfBirth(yourDetail.dateOfBirth),
      ...validateBillingAddress(propertyDetail.billingAddress),
      ...validateInstallationAddress(propertyDetail.address),
      ...validateFirstName(yourDetail.firstName),
      ...validateFamilyName(yourDetail.familyName),
    ];
    return errors;
  };

  const clearAccountDetails = useCallback(() => {
    setAccountDetails(InitialAccountDetails);
  }, [setAccountDetails]);

  return {
    yourDetail,
    propertyDetail,
    paymentInfo,
    installationDetails,
    marketingEmails,
    updateMarketingEmails,
    updateFirstName,
    updateFamilyName,
    updateDateOfBirth,
    updateEmail,
    updateEmailConsent,
    updateAreaCode,
    updateTelephoneNumber,
    updateAddress,
    updatePaperlessBilling,
    updateServiceContactEmail,
    updateBillingAddressSameAsInstallation,
    updateBillingAddress,
    updateDeliveryAddressSameAsInstallation,
    updateDeliveryAddress,
    updateInstallationDate,
    updateInstallationTime,
    updateRentOrOwn,
    manualValidation,
    clearAccountDetails,
    updateIsDD,
    updateDDAccountName,
    updateDDAccountNumber,
    updateDDBankName,
    updateCCAccountNumber,
    updateCCHolderName,
    updateCCExpire,
    updateCCCVC,
  };
}

export { useAccountDetails };
