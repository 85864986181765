import React, { useState } from 'react';
import { T_Product } from '@sky-tv-group/shared';
import { ICELineProductItem } from '../box-line-items/ICELineProductItem';
import { Switch } from '../switch';

export interface IICEBoxProductItemProps {
  product: T_Product;
  toggleProduct?: (product: T_Product) => Promise<void>;
  removable?: boolean;
}

const ICEBoxProductItem: React.FC<IICEBoxProductItemProps> = ({ product, toggleProduct, removable = true }) => {
  const isInCart = product.quantityInCart > 0;
  const [isDisabled, setIsDisabled] = useState(false);
  const handleToggle = async () => {
    setIsDisabled(true);
    if (!isInCart) {
      if (toggleProduct) {
        await toggleProduct(product);
      }
    }
    setIsDisabled(false);
  };

  return (
    <ICELineProductItem product={product} showDescription={true}>
      {removable && <Switch value={isInCart} onChange={handleToggle} disable={isDisabled || isInCart} />}
    </ICELineProductItem>
  );
};

export { ICEBoxProductItem };
