import React from 'react';
import { T_Icon_URL } from '@sky-tv-group/shared';
export interface IChannelsProps {
  channelLogos: T_Icon_URL[];
}

export const Channels = ({ channelLogos }: IChannelsProps) => {
  return (
    <div className="flex flex-row items-center col-gap-5 row-gap-12 flex-wrap sm:overflow-hidden">
      {channelLogos?.map(channelLogo => (
        <div className="w-18 h-18 p-1" key={channelLogo.logo_url}>
          <img className="w-full h-full object-contain" src={channelLogo?.logo_url} alt="" />
        </div>
      ))}
    </div>
  );
};
