import React, { useEffect } from 'react';
import cx from 'classnames';
import { Field, Form } from 'react-jeff';
import { DateRange, InstallationTimeCodes } from '@sky-tv-group/shared';
import { ValidationDatePicker } from '../../validation/dateValidated';
import { SelectElement } from '../../validation/selectValidated';

interface Props {
  installationDateField: Field<Date | null, string>;
  installationTimeField: Field<string, string>;
  installDates: DateRange;
  form: Form<string>;
  hidden: boolean;
  handleCallback?: (data?: any) => void;
}

const BroadbandInstallationDate = ({
  installationDateField,
  installationTimeField,
  installDates,
  form,
  hidden,
  handleCallback,
}: Props) => {
  const classes = cx('w-full', { hidden });
  useEffect(() => {
    handleCallback?.(installDates);
  }, [installDates]);
  return (
    <div className={classes}>
      <div className="flex flex-col">
        <div className="pb-4 md:w-1/2">
          <div>
            <label className="block sky-h7-reg mb-2 text-midnight">Ideal installation date</label>
            <ValidationDatePicker
              placeholderText="dd/mm/yyyy"
              id="broadband-installation-date"
              className="sky-input sky-input--text sky-h5 md:sky-h7 w-full"
              formValidation={form}
              fieldValidation={installationDateField}
              minDate={installDates.earliest}
              maxDate={installDates.latest}
              selected={installDates.earliest}
              excludeDates={installDates.exclude}
            />
          </div>
        </div>
        <div className="pb-4 md:w-1/2">
          <div>
            <label className="block sky-h7-reg mb-2">Ideal installation time</label>
            <SelectElement
              id="broadband-installation-time"
              className="sky-input sky-input--text sky-h5 md:sky-h7 w-full"
              fieldValidation={installationTimeField}
              formValidation={form}
              onChange={installationTimeField.props.onChange}
              value={installationTimeField.value}>
              <option value="AM">{InstallationTimeCodes.AM}</option>
              <option value="PM">{InstallationTimeCodes.PM}</option>
            </SelectElement>
          </div>
        </div>
      </div>
      <div className="flex pb-4 w-full">
        <p className="sky-h7-reg">
          We aim to get you connected on this date, but will keep you posted in case there need to be changes.
        </p>
      </div>
    </div>
  );
};

export { BroadbandInstallationDate };
