import create from 'zustand';
import { VoiceDataForm } from '../helpers/voiceFormFields';
import { sessionStorage } from '../helpers/sessionStorage';
import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';

const sessionStorageStateName = 'Voice Store';
const getSessionStorageState = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}');

interface VoiceStore {
  isValid: boolean;
  details: VoiceDataForm;
  setIsValid: (value: boolean) => void;
  setDetails: (value: VoiceDataForm) => void;
}

const [useVoiceStore, voiceStoreApi] = create<VoiceStore>(
  ReduxDevTools(
    StatePersistence(
      (set, get) => ({
        isValid: false,
        details: {},
        ...getSessionStorageState,
        setDetails: (value: VoiceDataForm) => {
          set({ details: value }, 'setVoiceFormDetails');
        },
        setIsValid: (value: boolean) => {
          set({ isValid: value }, 'set voice form valid');
        },
      }),
      sessionStorageStateName
    ),
    'Voice Store'
  )
);

export { useVoiceStore, voiceStoreApi };
