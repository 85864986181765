import { useRef, useCallback } from 'react';

type QueryStateFnAsync = (params: Record<string, string | null>) => Promise<void>;
type QueryStateFnSync = (params: Record<string, string | null>) => void;
type QueryStateFn = QueryStateFnAsync | QueryStateFnSync;

const getQuery = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryVal = (key: string) => {
  // create a URL search params instance of key
  // so we can support "three&four" syntax in useQueryState hook
  // to handle both three and four query parameters
  const keyParams = new URLSearchParams(key);
  const searchParams: Record<string, string | null> = {};

  for (const [key] of Array.from(keyParams.entries())) {
    const value = getQuery().get(key);
    searchParams[key] = value;
  }

  return searchParams;
};

/**
 * useQueryState hook gets search parameter values in window.location and executes
 * the pass in `func` when the `key` params is found.
 *
 * How to:
 *
 * // Gets the `id` params in query and runs the console.log()
 * useQueryState("id", (query) => console.log(query.id));
 *
 * // Gets both `id` and `address` params in query and runs the console.log()
 * useQueryState("id&address", (query) => console.log(query.id, query.address));
 *
 * @param key query parameters to fetch from URL
 * @param func callback function to run when values in "key" is found
 * @returns function to run the callback func
 */
function useQueryState(key: string, func: QueryStateFn) {
  const done = useRef(false);
  const keyValues = key && key !== '' ? getQueryVal(key) : {};
  const runner = useCallback(async () => {
    const hasValues = keyValues && Object.keys(keyValues).some(k => keyValues[k]);
    if (hasValues && !done.current) {
      await func(keyValues);
    }
    done.current = true;
  }, [key, func]);

  return runner;
}

export { useQueryState };
