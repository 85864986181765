import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  BlockLoader,
  MenuItemsType,
  TermsAndConditionContent,
  TermsAndConditionContentType,
  TermsAndConditionMenu,
} from '@sky-tv-group/components';
import { myAccountV2SWR } from '../../services';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
interface TermsAndConditionScreenProps {
  menus: MenuItemsType[];
}

export const TermsAndConditionScreen = ({ menus }: TermsAndConditionScreenProps) => {
  const { pathname } = useLocation();
  const { slug, text }: MenuItemsType = (menus ?? []).find(m => m.slug === pathname.replace('/', ''))!;
  const { data: termsContent, isValidating } = myAccountV2SWR.useGetTermsAndConditionContentGQL(slug);
  useDocumentTitle(`${text} - Sky`);
  return (
    <>
      <TermsAndConditionMenu menus={menus} selectedMenu={slug} />
      {isValidating ? (
        <div className="h-128">
          <BlockLoader />
        </div>
      ) : (
        <TermsAndConditionContent content={termsContent?.data?.termsconditions as TermsAndConditionContentType[]} />
      )}
    </>
  );
};
