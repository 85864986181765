import React, { FC, useEffect } from 'react';
import {
  Box,
  SPLITIO_KEY,
  useIdentifyBox,
  T_Billing,
  useSplitIO,
  categoryIds,
  BoxUpgradeStatus,
  useEligibilityOffers,
  useCustomerStore,
  useAddUpdateBox,
  useCoupon,
  useCouponStore,
  BFriday,
  useboxesStore,
  BoxTypes,
  NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US,
  useIdentifyRecording,
  productSkuIds,
  useProductStore,
  useCartContainer,
  KonakartService,
} from '@sky-tv-group/shared';
import { ThumbsUp } from './icon';
import { ICEBoxCard } from './box-line-items/ICEBoxCard';
import {BoxSelectableCard} from './box-line-items/BoxSelectableCard';
import { CarouselContainer } from './carousel/CarouselContainer';
import { CustomerGuaranteeTemplate, SkyRewardsTemplate } from './arrow-marketing/NewMarketingCardTemplates';
import { Product, T_Product } from '@sky-tv-group/shared/src/types';
import { ModalConfirmParams } from './multiroom-modal';
import { Button } from './button';
import { Bin } from './running-total/RunningTotalIcons';
import { useState } from 'react';
import { Success } from './icon';
import { BlockLoader, Loader } from './loader';
const slickSettings = {
  autoSlidesToShow: true,
  variableWidth: true,
  infinite: false,
  arrows: false,
  dots: false,
  customClassName: 'custom-carousel slick-slide-px-r-20 slick-slide-px-r-30  -mr-10vw md:mr-0',
  responsiveBlock: [],
};

export enum SourceJourney {
  WEB_ICE = 'WEB-ICE',
  WEB_ACCOUNT = 'WEB-ACCOUNT',
  WEB_DART = 'WEB-DART',
}

type SkyBoxPrimaryProps = {
  box?: Box;
  billing?: T_Billing;
  heading: string;
  mySkySubscribed?: boolean;
  source?: SourceJourney;
  // Opens the manage nickname modal
  manageNicknameButton?: (box: Box) => JSX.Element;
  // The add box button for acquisition
  addBoxButton?: () => JSX.Element;
  // Edit box button for acquisition
  editBoxButton?: (box: Box) => JSX.Element;
  // Opens edit/upgrade box button modal element for upgrade journey
  updateBoxButton?: (box: Box) => JSX.Element | undefined;
  products:T_Product[];
  confirm:(params:ModalConfirmParams)=>void;
  changeProductsByQuantity: (productsToUpdate: T_Product[], byQuantity?: number | undefined, needUpdate?: boolean | undefined, trackEvent?: boolean | undefined) => Promise<void>;
};

const SkyBoxPrimary: FC<SkyBoxPrimaryProps> = ({
  box,
  billing,
  heading,
  mySkySubscribed,
  manageNicknameButton,
  updateBoxButton,
  addBoxButton,
  editBoxButton,
  source,
products,
confirm,
changeProductsByQuantity
}) => {
  const [showNewSkyBox] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX);
  const [newSkyBoxCSGEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX_CSG);

  const { getBox, getBoxStatus, isBoxEligibleForCSG, doesBoxHaveFreeMySky } = useIdentifyBox();
  const { anyArrowLoyaltyOffers, arrowLoyaltyOffers } = useEligibilityOffers();
  const currentUserArrowLoyaltyOfferDetails = useEligibilityOffers().currentUserArrowLoyaltyOfferDetails();
  const { couponRewardTemplateType } = useCoupon();
  const { coupons } = useCouponStore();
  const {boxes, removeBox} = useboxesStore();

  // check if Arrow Loyalty Offers coupons is already in store or product(s) is subscribed
  const isArrowLoyaltyOffersSubscribed = (coupons ?? [])?.some(
    c => c?.couponCode === currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode
  );

  // offer banner should always be visible until customer reaches the max limit
  const isBoxReachesMaxLimit =
    (boxes?.filter(b => b?.boxType === BoxTypes.NEW || b.boxType === BoxTypes.UPGRADE)?.length ?? 0) >= 5;

  const showOfferBanner = !(currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode ===
  NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US
    ? isBoxReachesMaxLimit
    : isArrowLoyaltyOffersSubscribed);

  const triggerBoxUpgrade = () => {
    document.getElementById('updateBoxButton')?.click();
  };



  let isReacordingAvailable = (box:Box | undefined)=>{
if(box){
  let recording = box.products.find(p=>p.sku === productSkuIds.arrowBoxRecording.primary);
  return recording;
}
return;
  }
const [isError, setError] = useState(false);
const hasprimaryBox = boxes.some(box => box.primary === true);
useEffect(()=>{
  if(isError && hasprimaryBox){
    setError(false);
  }
},[isError,hasprimaryBox]);
  function handleError(e:any){
    let text = e.target.innerHTML;
    if( text && (text === 'Next: Add Broadband' || text=== 'Or, Checkout Now')){
      setError(true);
    }
  }
  useEffect(()=>{
    document.addEventListener('click',handleError)

    return(()=>{
      document.removeEventListener('click',handleError);
    })
  },[])
  let child;

  const [parentLoading, setParentLoading] = useState(false);

  const [isRecordingLoaded, setRecordingloaded] = useState(false);



  const customerGuaranteeDesc = (
    <p>
      Satisfaction Guarantee applicable for 30 days after activation of the new Sky Box. Usual monthly subscription and
      additional data usage charges apply. Click here for full{' '}
      <a href="https://sky.co.nz/csg-terms" className="underline" target="_blank" rel="noopener noreferrer">
        T&Cs.
      </a>
    </p>
  );



  return (
    <>
      <div className="flex flex-row justify-between items-center">
        {source === SourceJourney.WEB_ICE ? (
          <h3 className="relative card text-navy flex flex-wrap items-center" data-testid={heading}>
            <span className="sky-h3-black font-black md:sky-h4-black">{heading.split(' ')[0]}</span>
            <span className="sky-h3-reg font-normal md:sky-h4-reg ml-2">{heading.split('Choose')}</span>
          </h3>
        ) : (
          <h3
            className="relative card sky-h5-black md:sky-h5-black text-navy flex flex-wrap items-center"
            data-testid={heading}>
            {heading} 
          </h3>
        )}
      </div>
      {mySkySubscribed && (
        <div className="sky-h5 flex pt-8">
          <ThumbsUp />
          <div className="pt-1">
            Great news! With the new Sky Box you'll get the full 1TB of storage while saving $5 per month.
          </div>
        </div>
      )}
      {isError ? <p className='sky-text-red my-4'>You must choose your primary device before continuing. Please select one of the below options.</p>:''}
      <div className="mt-6 flex gap-4">
      { box && box.boxType === 'NEW' ?
      <>
        <div className=' md:h-32 flex w-full flex-col md:flex-row gap-2'>
          <div className=' h-full w-2/6 md:w-1/6 flex flex-col'>
            <div className='flext relative' style={{height:"75%"}}><img src={box.serviceCode === '616' ? "https://static.sky.co.nz/sky/box/sky_pod_without_remote.png" :box.products.find(p=>categoryIds.box === p.categoryId )?.custom1} alt="box.png"  className=' w-full h-full object-contain'/><span className='relative' style={{top:"-100%", right:"-70%"}}><Success /></span></div>
            <div className='flex justify md:justify-center' style={{height:"25%"}}><p>{box.serviceCode ===productSkuIds.arrowBox.primary ?"new Sky Box" :box.name}</p></div>
          </div>
          <div className='recordingContainer flex flex-col justify-center w-full md:w-4/6 relative'>
          {isRecordingLoaded ?<div className="relative h-10"> <Loader bgColor='bg-white'></Loader> </div>: (<>
          {box.serviceCode === '616' ? <p className='text-base font-bold'> Recording not available.</p> :isReacordingAvailable(box) ?<p className='flex font-semibold text-base'style={{fontFamily:"MarkPro",}}><span className='mr-2 cursor-pointer relative' style={{top:"2px"}} onClick={()=>{
           document.body.style.pointerEvents="none";
            setRecordingloaded(true);
            setTimeout(async ()=>{
             await  confirm({
              selected:box.serviceCode!,
              oneoff: true,
              recording: false,
              primary: true,
              editing: box,
          });
          setRecordingloaded(false);
          document.body.style.pointerEvents="all"
        },1000)
      }
           }><Bin /></span ><span className=' font-black mr-2'>1TB </span> My Sky recording</p> :<><p className='text-sm lg:text-lg' > <span className='text-blue-pure cursor-pointer font-black' onClick={()=>{
           document.body.style.pointerEvents="none"
            setRecordingloaded(true)
             setTimeout(async ()=>{
             await confirm({
                selected: box.serviceCode!,
                oneoff: true,
                recording: true,
                primary: true,
                editing: box,
            })
            setRecordingloaded(false)
            document.body.style.pointerEvents="all"},1000) }}><span className='text-xl text-blue-pure ' style={{marginLeft:"-5px"}}>+&nbsp;</span> Add my sky recording</span> for $15/mth</p>
            <p className='text-sm mx-4'>With recording you can pause, rewind and record live TV. Never miss an episode again with our smart series link feature.</p>
</>}</>)}
</div>
          <div className='buttonContainer flex flex-col justify-center items-center w-full md:w-1/6'>
            <Button variant='secondary' colorMode='pure' className='w-full h-12 text-base md:text-sm'
            onClick={ ()=>{
              document.body.style.pointerEvents="none"
              setParentLoading(true);
              setTimeout(async ()=>{
              setError(false);
             await confirm({
              selected: box.serviceCode === productSkuIds.arrowBox.primary ? productSkuIds.skyPod.primary :productSkuIds.arrowBox.primary ,
              oneoff:true,
              recording:false,
              primary:true,
              editing:box,
            })
            setParentLoading(false);
            document.body.style.pointerEvents="all"
            },1000)
            }
          }>{!parentLoading ? "Switch Device":<div className='relative w-24'><Loader ></Loader></div>}</Button>
          </div>
        </div>
        </> :
      <>
       <div className='hidden md:flex gap-4 ' style={{maxWidth: products.length <3? "80%":"100%"}}>{products.map(p=> <BoxSelectableCard parentLoading={parentLoading} setParentLoading = {setParentLoading} box ={p} confirm ={confirm} isPrimary={true}   className={isError ? "border-red":""}/> )}</div>
       <div className='flex gap-4 md:hidden overflow-x-auto' style={{scrollSnapType:"x mandatory"}}>{products.map(p=> <div style={{minWidth:"90%"}}><BoxSelectableCard parentLoading={parentLoading}  setParentLoading = {setParentLoading} box ={p} confirm ={confirm}  isPrimary={true}  className={` ${isError ? "border-red":''}`}/> </div>)}</div>
          {showNewSkyBox && source !== 'WEB-ICE' && anyArrowLoyaltyOffers && showOfferBanner && (
            <>
              <div className="w-270px mt-6 pb-12">
                <SkyRewardsTemplate
                  headline={
                    currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode ===
                    NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US ? (
                      <span>Enjoy the new Sky Box on us!</span>
                    ) : (
                      <span className="absolute" style={{ top: -45 }}>
                        experience
                        <br />
                        New Sky Box{' '}
                      </span>
                    )
                  }
                  description={
                    currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode ===
                    NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US ? (
                      <p>Your search is over! get satellite, streaming and Sky On Demand all in one place.</p>
                    ) : (
                      <p>
                        As a loyal Sky customer, get a Sky Rewards discount of $
                        {currentUserArrowLoyaltyOfferDetails.discount ?? 150} when you upgrade to receive your Sky
                        Services through the new Sky Box, with a one off payment of
                      </p>
                    )
                  }
                  offerPrice={currentUserArrowLoyaltyOfferDetails.discountedPrice ?? 50}
                  rrpPrice={currentUserArrowLoyaltyOfferDetails.originalPrice ?? 200}
                  templateType={couponRewardTemplateType(currentUserArrowLoyaltyOfferDetails.coupon?.couponCode)}
                  couponCode={currentUserArrowLoyaltyOfferDetails.coupon?.couponCode}
                  discountText="You save $200"
                />
              </div>
            </>
          )}
          {!!showNewSkyBox && !!newSkyBoxCSGEnabled && source !== SourceJourney.WEB_ICE && (
            <div className="w-270px mt-6 pb-12">
              <CustomerGuaranteeTemplate
                logoUrl={'https://static.sky.co.nz/sky/skymarketing/sky-30-day.png'}
                headline={'SATISFACTION GUARANTEE'}
                description={customerGuaranteeDesc}
              />
            </div>
          )}
</>
}
      </div>
    </>
  );
};

export { SkyBoxPrimary };
