import { TVPackages } from './TVPackages';
import { SkyBox } from './SkyBox';
import { Review } from './Review';
import { AbandonedCartReview } from './AbandonedCartReview';
import { Broadband } from './broadband/Broadband';
import { Confirm } from '../Checkout/Confirm';
import { AccountForm } from '../Checkout/AccountForm';
import { DTHForm } from '../Checkout/DTHForm';

export const routes = [
  {
    path: '/tv-packages',
    title: 'TV',
    component: TVPackages,
    exact: true,
  },
  {
    path: '/sky-box',
    title: 'Device',
    component: SkyBox,
    exact: true,
  },
  {
    path: '/broadband',
    title: 'Broadband',
    // placeholder
    component: Broadband,
    exact: true,
  },
  {
    path: '/checkout',
    title: 'Review',
    component: Review,
    exact: true,
  },
  {
    path: '/checkout/saved-cart',
    title: 'Review Abandoned Cart',
    component: AbandonedCartReview,
    exact: true,
  },
  {
    path: '/checkout/account-form',
    title: 'About You',
    component: AccountForm,
    exact: true,
  },
  {
    path: '/checkout/dth-form',
    title: 'Installation',
    component: DTHForm,
    exact: true,
  },
  {
    path: '../checkout/Confirm',
    title: 'Order Details',
    component: Confirm,
    exact: true,
  },
];
