/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-jeff';
import { addDays, addMonths, getDay, parseISO, eachWeekendOfInterval, isValid } from 'date-fns';

import {
  NullableDate,
  TechLookupTechType,
  TechLookupProductType,
  TechLookupOrderType,
  TechnicianSlot,
  useTechnicianAvailableSlots,
  useSplitIO,
  SPLITIO_KEY,
  useJSONConfig,
  useAccountDetails,
  AddressService,
  JSONConfigService,
  TechnicianAvailabilityService,
  InstallationTimeCodeKeys,
  UserGroupType,
  DartMigrationCallCode,
  InstallationTimeCodes,
} from '@sky-tv-group/shared';
import { Loader } from '../loader';
import { ValidationDatePicker } from '../validation/dateValidated';
import { SelectElement } from '../validation/selectValidated';
import { CurrentAddress } from '../address/CurrentAddress';
import { New_InstallationTimeCodes, T_Customer, T_Profile } from '@sky-tv-group/shared/src/types';

interface IInstallationDetailsProps {
  titleText: string;
  titleClass?: string;
  currentAddress?: string;
  message?: string;
  formValidation: Form<string>;
  installationDateField: Field<NullableDate, string>;
  installationTimeField: Field<string, string>;
  setInstallationOptionsAvailability: (available: boolean) => void;
  addressService: AddressService;
  configService: JSONConfigService;
  technicianLookupService: TechnicianAvailabilityService;
  customer?: T_Customer;
  profile?: T_Profile;
  handleCallback?: (data?: any) => void;
}

const isUserType = (userGroupTypes: UserGroupType[], groupType: UserGroupType) => {
  return userGroupTypes.some(ugt => ugt === groupType);
};

const InstallationDetails: React.FunctionComponent<IInstallationDetailsProps> = ({
  titleText,
  titleClass,
  currentAddress,
  message,
  formValidation,
  installationDateField,
  installationTimeField,
  setInstallationOptionsAvailability,
  addressService,
  configService,
  technicianLookupService,
  customer,
  profile,
  handleCallback,
}) => {
  const [shouldUseLookup] = useSplitIO(SPLITIO_KEY.SKYWEB_TECHNICIAN_LOOKUP);
  const { installationDetails, propertyDetail, updateInstallationDate, updateInstallationTime } = useAccountDetails(
    addressService
  );

  const tui = customer ? customer?.tuiAddressCode : propertyDetail?.address?.tui;
  const userGroupTypes = (profile?.groups ?? []) as UserGroupType[];
  const isDartMigration = isUserType(userGroupTypes, UserGroupType.SKY_VTV_CUSTOMER);

  const _titleClass = titleClass
    ? titleClass
    : 'bg-gray-light sky-h3 md:sky-h4 text-center h-24 p-4 flex justify-center items-center font-bold';

  const {
    loadingTechnicianAvailableDates,
    errorTechnicianAvailableDates,
    getTechnicianDates,
    getTechnicianTimeSlots,
    allTimeslots,
    allTimeslotsShouldUseLookUp,
    technicianSlots,
  } = useTechnicianAvailableSlots(
    technicianLookupService,
    TechLookupTechType.SKY,
    TechLookupProductType.CABLE,
    TechLookupOrderType.INSTALL
  );
  const config = useJSONConfig(configService);

  //Technician Lookup values
  let [availableDates, setAvailableDates] = useState<Date[] | undefined>(undefined);
  let [excludeDates, setExcludeDates] = useState<Date[] | undefined>(undefined);
  let [availableTimeSlots, setAvailableTimeSlots] = useState<TechnicianSlot[]>([]);
  let [showTimeSlots, setShowTimeSlots] = useState<boolean>(true);
  const [manipulatedSlots, SetManipulatedSlots] = useState<TechnicianSlot[]>([]);

  // update fields to session storage state when changed
  useEffect(() => {
    setShowTimeSlots(true);
    if (installationDetails.installationDate === installationDateField.value) {
      installationTimeField.setValue(installationDetails.installationTime);
    } else {
      installationTimeField.setValue('');
    }

    updateInstallationDate(installationDateField.value);
    // Check for Available Time slots for the selected date
    if (installationDateField.value) {
      let slots;

      if (shouldUseLookup && !errorTechnicianAvailableDates) {
        slots = getTechnicianTimeSlots(installationDateField.value);

        if (slots.find(ts => ts.code === InstallationTimeCodeKeys.ALLDAY)) {
          setShowTimeSlots(false);
          installationTimeField.setValue(InstallationTimeCodeKeys.ALLDAY);
        }
      } else if (shouldUseLookup) {
        slots = allTimeslotsShouldUseLookUp;
      } else {
        slots = allTimeslots;
      }

      setAvailableTimeSlots(slots);
    }
  }, [installationDateField.value, technicianSlots, errorTechnicianAvailableDates]);

  //update the installation date to null if the technician slots gets updated
  useEffect(()=>{
    if(installationDateField.value){
    installationDateField.setValue(null)
    }
  },[technicianSlots, errorTechnicianAvailableDates])

  useEffect(() => {
    handleCallback?.(technicianSlots);
  }, [technicianSlots]);

  useEffect(() => {
    updateInstallationTime(installationTimeField.value);
  }, [installationTimeField.value]);

  useEffect(() => {
    setInstallationOptionsAvailability(!errorTechnicianAvailableDates);
  }, [errorTechnicianAvailableDates]);

  // initialize values from session storage
  useEffect(() => {
    if (!shouldUseLookup) {
      if (isValid(installationDetails.installationDate)) {
        installationDateField.setValue(installationDetails.installationDate);
      }
      if (installationDetails.installationTime) {
        installationTimeField.setValue(installationDetails.installationTime);
      }
    }
  }, []);


// Manipulate the time slots to display on the web
  useEffect(() => {
    if (availableTimeSlots) {
      let filteredTimeSlots = availableTimeSlots.filter((slot: any) => slot.code !== InstallationTimeCodeKeys.AM1 && slot.code !== InstallationTimeCodeKeys.PM2);
      const slotData = filteredTimeSlots.map((slot) => {
        if (slot.displayName === InstallationTimeCodes.AM2 || slot.code === InstallationTimeCodeKeys.AM2) {
          return { ...slot, displayName: New_InstallationTimeCodes.AM2 };
        } else if (slot.displayName === InstallationTimeCodes.PM1 || slot.code === InstallationTimeCodeKeys.PM1) {
          return { ...slot, displayName: New_InstallationTimeCodes.PM1 };
        } else if (slot.displayName === InstallationTimeCodes.ALLDAY || slot.code === InstallationTimeCodeKeys.ALLDAY) {
          return { ...slot, displayName: New_InstallationTimeCodes.ALLDAY };
        }
        return slot;
      })
      SetManipulatedSlots(slotData)
    }

  },[installationDateField.value,availableTimeSlots]);

  const dayAfterTomorrowDate: Date = addDays(new Date(), 2);
  const maxInstallationDate: Date = addMonths(dayAfterTomorrowDate, 3);
  // Get Technician Available Dates based on the selected Address
  useEffect(() => {
    const getDates = async () => {
      if (!tui) {
        return;
      }
      let tuiUnavailable = tui === '0';
      installationDateField.value = null;
      setExcludeDates(((config?.excludeInstallationDates ?? []) as string[]).map(str => parseISO(str)));
      if (shouldUseLookup) {
        installationTimeField.value = '';
        getTechnicianDates(
          dayAfterTomorrowDate,
          maxInstallationDate,
          tuiUnavailable ? undefined : tui,
          tuiUnavailable ? customer?.houseNumber : undefined,
          undefined,
          undefined,
          undefined,
          isDartMigration ? DartMigrationCallCode : undefined
        ).then((dates) => {
          if (dates && dates.length > 0) {
            // setAvailableDates(dates.map((d) => d.date));
            const filteredDates = dates.filter(date => {
              const datesArr =  date?.slots.map(d => d.code)
              return !(datesArr.length === 2 && datesArr.includes('AM1') && datesArr.includes('PM2'))
            })
            setAvailableDates(filteredDates.map((d) => d.date));
          } else {
            installationDateField.setValue(new Date('9999-12-31'));
            installationTimeField.setValue('AM1');
            getAllDates();
          }
        });
      } else {
        getAllDates();
      }
    };

    const getAllDates = () => {
      setAvailableDates(undefined);
      const dayAfterTomorrowDate: Date = addDays(new Date(), 2);
      const maxInstallationDate: Date = addMonths(dayAfterTomorrowDate, 3);
      const excludeInstallationSundayDates: Date[] = eachWeekendOfInterval({
        start: dayAfterTomorrowDate,
        end: maxInstallationDate,
      }).filter(date => getDay(date) === 0);

      setExcludeDates(
        ((config?.excludeInstallationDates ?? []) as string[])
          .map(str => parseISO(str))
          .concat(excludeInstallationSundayDates)
      );
    };

    getDates();
  }, [propertyDetail.address]);

  return (
    <>
      {titleText && <h4 className={_titleClass}>{titleText}</h4>}
      {currentAddress && <CurrentAddress address={currentAddress} containerClass="sky-h5 flex py-8"></CurrentAddress>}

      {/* Latest designs does not have Covid 19 message - 19/04/2022 */}
      {/* <div>
        <p className="bg-gray-dark p-3 rounded m-2">
          Sky is obligated to ensure safe working conditions for our technicians and customers. All of our technicians
          are fully vaccinated and are required to wear masks while visiting customer homes to install or repair Sky
          services. <br />
          <br />
          If you, or anyone in your household, is awaiting the results of a COVID-19 test, are a confirmed COVID-19
          close contact or experiencing any COVID-19 symptoms, please contact our friendly NZ based team on 0800 64 64
          64 to discuss your installation.
        </p>
      </div> */}

      <div className="p-6 md:p-0 md:py-6">
        {loadingTechnicianAvailableDates ? (
          <div className="relative h-32">
            <Loader bgColor="sky-bg-transparent" bgOpacity={false} />
          </div>
        ) : technicianSlots.length > 0 ? (
          <div className="flex flex-col md:flex-row">
            <div className="pb-4 md:pb-0 w-full md:w-1/2">
              <label className="block sky-label md:sky-label mb-2">Ideal installation date</label>
              <ValidationDatePicker
                fieldValidation={installationDateField}
                placeholderText="dd/mm/yyyy"
                id="account-details-installation-details-installation-date"
                formValidation={formValidation}
                className="w-full"
                includeDates={availableDates}
                excludeDates={excludeDates}
                minDate={dayAfterTomorrowDate}
                maxDate={maxInstallationDate}
                disabled={!tui}
              />
              {!tui && <div className="sky-text-gray-darker">Ideal installation time</div>}
            </div>
            {showTimeSlots && (
              <div className="pb-4 md:pb-0 md:pl-2 w-full md:w-1/2">
                <label className="block sky-label md:sky-label mb-2">Please select preferred installation time</label>
                <SelectElement
                  id="account-details-installation-details-installation-time"
                  className="w-full"
                  fieldValidation={installationTimeField}
                  formValidation={formValidation}
                  onChange={installationTimeField.props.onChange}
                  value={installationTimeField.value}
                  disabled={!tui}
                  data-testid="account-details-installation-details-installation-time">
                  <option value="" disabled>
                    Select an Option
                  </option>
                  {manipulatedSlots.map(slot => (
                  // {availableTimeSlots.map(slot => (
                    <option key={slot.code} value={slot.code}>
                      {slot.displayName}
                    </option>
                  ))}
                </SelectElement>
              </div>
            )}
          </div>
        ) : technicianSlots?.length < 1 && errorTechnicianAvailableDates ? (
          <div className=" w-full mt-4 rounded-md border-l-6 border-blue-light bg-blue-cool inline-block px-40px py-4 sky-h6-reg mb-8">
            One of our friendly crew will be in touch to schedule an installation for your new Sky device.
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export { InstallationDetails };
