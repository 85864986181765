import { productSkuIds } from '../config';

enum newNames {
  broadbandWifi100 = 'Fibre Everyday',
  broadbandWifi100Boost = 'Fibre Everyday Boost',
  broadbandLightningFastWiFi = 'Fibre Pro',
  broadbandLightningFastWiFiBoost = 'Fibre Pro Boost',
  broadbandStarterWiFi = 'Fibre Starter',
  broadbandStarterWiFiBoost = 'Fibre Starter Boost',
}

export interface bbNameInterface {
  broadbandWifi100: { label: string; primary: string; price0?: number; price1?: number };
  broadbandWifi100Boost: { label: string; primary: string; price0?: number; price1?: number };
  broadbandLightningFastWiFi: { label: string; primary: string; price0?: number; price1?: number };
  broadbandLightningFastWiFiBoost: { label: string; primary: string; price0?: number; price1?: number };
  broadbandStarterWiFi: { label: string; primary: string; price0?: number; price1?: number };
  broadbandStarterWiFiBoost: { label: string; primary: string; price0?: number; price1?: number };
}

export const bbNamesOld: bbNameInterface = {
  broadbandWifi100: {
    label: productSkuIds.broadbandWifi100.label,
    primary: productSkuIds.broadbandWifi100.primary,
    price0: 79,
    price1: 69,
  },
  broadbandWifi100Boost: {
    label: productSkuIds.broadbandWifi100Boost.label,
    primary: productSkuIds.broadbandWifi100Boost.primary,
    price0: 89,
    price1: 79,
  },
  broadbandLightningFastWiFi: {
    label: productSkuIds.broadbandLightningFastWiFi.label,
    primary: productSkuIds.broadbandLightningFastWiFi.primary,
    price0: 99,
    price1: 79,
  },
  broadbandLightningFastWiFiBoost: {
    label: productSkuIds.broadbandLightningFastWiFiBoost.label,
    primary: productSkuIds.broadbandLightningFastWiFiBoost.primary,
    price0: 109,
    price1: 89,
  },
  broadbandStarterWiFi: {
    label: productSkuIds.broadbandEssnFibre.label,
    primary: productSkuIds.broadbandEssnFibre.primary,
    price0: 60,
    price1: 60,
  },
  broadbandStarterWiFiBoost: {
    label: productSkuIds.broadbandEssnFibBoost.label,
    primary: productSkuIds.broadbandEssnFibBoost.primary,
    price0: 70,
    price1: 70,
  },
};

export const BBList = Object.keys(bbNamesOld).map(item => {
  // @ts-ignore
  return bbNamesOld[item].primary;
});

export const bbNamesNew: bbNameInterface = {
  broadbandWifi100: {
    label: newNames.broadbandWifi100,
    primary: productSkuIds.broadbandWifi100.primary,
    price0: 85,
    price1: 75,
  },
  broadbandWifi100Boost: {
    label: newNames.broadbandWifi100Boost,
    primary: productSkuIds.broadbandWifi100Boost.primary,
    price0: 95,
    price1: 85,
  },
  broadbandLightningFastWiFi: {
    label: newNames.broadbandLightningFastWiFi,
    primary: productSkuIds.broadbandLightningFastWiFi.primary,
    price0: 105,
    price1: 85,
  },
  broadbandLightningFastWiFiBoost: {
    label: newNames.broadbandLightningFastWiFiBoost,
    primary: productSkuIds.broadbandLightningFastWiFiBoost.primary,
    price0: 115,
    price1: 95,
  },
  broadbandStarterWiFi: {
    label: newNames.broadbandStarterWiFi,
    primary: productSkuIds.broadbandEssnFibre.primary,
    price0: 105,
    price1: 85,
  },
  broadbandStarterWiFiBoost: {
    label: newNames.broadbandStarterWiFiBoost,
    primary: productSkuIds.broadbandEssnFibBoost.primary,
    price0: 115,
    price1: 95,
  },
};

export const getBBname = async () => {
  return bbNamesNew;
};

export const useBBname = () => {
  return bbNamesNew;
};
