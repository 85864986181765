import { useLayoutEffect, useRef } from 'react';

export function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyleBody = window.getComputedStyle(document.body).overflow;
    const originalStyleHTML = window.getComputedStyle(document.documentElement).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyleBody;
      document.documentElement.style.overflow = originalStyleHTML;
    };
  }, []); // Empty array ensures effect is only run on mount and unmount
}

// lock window based on a value
export function useLockBodyScrollOnValue(lock: boolean) {
  let originalStyleBody = useRef('');
  let originalStyleHTML = useRef('');

  useLayoutEffect(() => {
    if (lock) {
      // Get original body overflow
      originalStyleBody.current = window.getComputedStyle(document.body).overflow;
      originalStyleHTML.current = window.getComputedStyle(document.documentElement).overflow;

      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      if (originalStyleBody.current) {
        document.body.style.overflow = originalStyleBody.current;
        document.documentElement.style.overflow = originalStyleHTML.current;
      }
    }
  }, [lock]);
}
