import { Customer, Profile } from '@sky-tv-group/graphql';
import { format, parseISO } from 'date-fns';

import { Address, FreeformAddress, T_AddressLookUpDetail, T_Customer, T_Profile } from '../types';

export function getDOB(customer: T_Customer) {
  let primary = customer.Parties.find(p => p.types['MP:Primary'] !== undefined)?.types?.['MP:Primary'];
  return primary && primary?.personalDetails.dob ? format(parseISO(primary?.personalDetails.dob), 'P') : '';
}

export function getPhone(customer: T_Customer) {
  let primary = customer.Parties.find(p => p.types['MP:Primary'] !== undefined)?.types?.['MP:Primary'];
  let phone = {
    number: '',
    areaCode: '',
  };

  let hp = (primary?.contactDetails ?? []).find(c => c.type === 'HP');
  let bp = (primary?.contactDetails ?? []).find(c => c.type === 'BP');
  let op = (primary?.contactDetails ?? []).find(c => c.type === 'OP');

  if (hp) {
    phone.areaCode = hp?.areaCode ?? '';
    phone.number = hp?.number ?? '';
  } else if (bp) {
    phone.areaCode = bp?.areaCode ?? '';
    phone.number = bp?.number ?? '';
  } else {
    phone.areaCode = op?.areaCode ?? '';
    phone.number = op?.number ?? '';
  }
  return phone;
}

export function addressToArray(address: Address) {
  const addressArray = [
    `${address?.houseNumber || ''} ${address?.streetName || ''}`.trim() || '',
    address?.suburb || '',
    address?.city || '',
    address?.postCode || '',
  ];
  return addressArray;
}

export function freeFormAddressToArray(address: FreeformAddress) {
  const streetNumberAndName = address?.address1;
  const optionalAddressLine = address?.address2;
  const suburb = address?.address3;
  const cityAndPostcode = address?.address4;

  const combinedFreeFormAddress = [
    streetNumberAndName || '',
    optionalAddressLine || '',
    suburb || '',
    cityAndPostcode || '',
  ];
  return combinedFreeFormAddress;
}
export function getDeliveryAddressAsArray(customer: T_Customer) {
  const deliveryAddress = customer.Parties.find(p => p.types['BT:Billing'] !== undefined);

  const freeFormAddress = deliveryAddress?.types['BT:Billing']?.contactAddress.freeFormAddress;
  // In order of priority for which address to return. If the address is undefined return the next
  // 1) Billing Free Form Address
  if (freeFormAddress) {
    return freeFormAddressToArray(freeFormAddress);
  }

  const streetAddress = deliveryAddress?.types['BT:Billing']?.contactAddress.streetAddress;
  // 2) Billing Service Address
  if (streetAddress) {
    return addressToArray(streetAddress);
  }

  // 3) Primary Service Address
  const serviceAddress = customer?.serviceAddress?.primaryAddress ?? '';

  return addressToArray(serviceAddress);
}
export function getContactName(customer: T_Customer) {
  const primaryDetails = customer.Parties.find(p => p.id === '1')?.types['MP:Primary'] || undefined;
  let fullName = `${primaryDetails?.personalDetails.firstName ?? ''} ${primaryDetails?.personalDetails.lastName ??
    ''}`.trim(); // Backtick for chrome `
  let siteContactName = fullName.replace(/[^-a-zA-Z.' ]/g, '');
  return siteContactName;
}
export function getTuiAddressCode(customer: T_Customer) {
  return customer?.tuiAddressCode ? customer?.tuiAddressCode : '';
}
export function getBillingParty(customer: T_Customer) {
  return customer.Parties.find(p => p.types['BT:Billing'] !== undefined);
}
export function getBilling(customer: T_Customer) {
  return customer.Parties.find(p => p.types['BT:Billing'] !== undefined)?.types?.['BT:Billing'];
}

// use always 'Service Address' instead of Primary Party to retrieve Service Address.
// the reason? MSL has an outstanding bug ('outdated address in Primary Party').
export function getServiceAddress(customer: T_Customer) {
  let primary = customer?.serviceAddress?.primaryAddress ?? '';

  return `${primary?.houseNumber ?? ''} ${primary?.streetName ?? ''} ${primary?.suburb ?? ''} ${primary?.city ??
    ''} ${primary?.postCode ?? ''}
    `.trim();
}

export function getServiceAddressAsArray(customer: T_Customer) {
  let result = [
    `${customer?.serviceAddress?.primaryAddress.houseNumber ?? ''} ${customer?.serviceAddress?.primaryAddress
      .streetName ?? ''}`.trim(),
    customer?.serviceAddress?.primaryAddress.suburb ?? '',
    customer?.serviceAddress?.primaryAddress.city ?? '',
    customer?.serviceAddress?.primaryAddress.postCode ?? '',
  ];
  return result;
}

export function getAddressStr(address: Address | FreeformAddress) {
  return 'address1' in address
    ? `${address.address1 ?? ''} ${address.address2 ?? ''}, ${address.address3 ?? ''}, ${address.address4 ?? ''}`
    : `${address.houseNumber} ${address.streetName}, ${address.suburb}, ${address.city}, ${address.postCode}`;
}

export function getBillingAddress(customer: T_Customer) {
  let billing = customer.Parties.find(p => p.types['BT:Billing'] !== undefined)?.types?.['BT:Billing'];
  let billingAddress = billing?.contactAddress.freeFormAddress ?? billing?.contactAddress.streetAddress;

  return billingAddress ? getAddressStr(billingAddress) : '';
}

export function getAuthedParty(customer: T_Customer) {
  let authorised = customer?.Parties.find(p => p.types['AP:Authorised'] !== undefined);
  return authorised;
}
export function getAuthed(customer: T_Customer) {
  let authorised = customer?.Parties.find(p => p.types['AP:Authorised'] !== undefined)?.types?.['AP:Authorised'];
  return authorised;
}
export function getAuthedPerson(customer: T_Customer) {
  let authorised = getAuthed(customer);
  return authorised?.personalDetails;
}

export function getAddressFromAddressLookupDetail(addressDetail: T_AddressLookUpDetail) {
  return {
    address1: addressDetail.formatted_address.line1 ?? '',
    address2: addressDetail.formatted_address.line2 ?? '',
    address3: addressDetail.formatted_address.line3 ?? '',
    address4: addressDetail.formatted_address.line4 ?? '',
  };
}

export function padLeft(src: string, length: number, char: string) {
  let r = src;
  while (r.length < length) {
    r = char + r;
  }
  return r;
}

export const PHONE_LENGTH = 7;
export function getAuthedNumber(customer: T_Customer) {
  let authorised = getAuthed(customer);
  let number = authorised?.contactDetails?.[0].number ?? '';
  if (number.length > 0 && number.length < PHONE_LENGTH) {
    number = padLeft(number, PHONE_LENGTH, '0');
  }
  return [authorised?.contactDetails?.[0].areaCode ?? '', number];
}

export function combineAddressArray(address: string[]) {
  const line1 = address[0] || '';
  const line2 = address[1] || '';
  const line3 = address[2] || '';
  const line4 = address[3] || '';
  return `${line1} ${line2} ${line3} ${line4} `.trim(); // Backtick for Chrome `
}
