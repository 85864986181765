import { parseISO } from 'date-fns';
import create from 'zustand';
import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';
import { sessionStorage } from '../helpers/sessionStorage';

const sessionStorageStateName = 'Broadband Store';
const getSessionStorageState = (): Partial<BroadbandStore> => {
  const state = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}', (key, value) => {
    const dateKeys = ['activationDate', 'installationDate'];
    // if its not a date dont worry about it
    if (!dateKeys.includes(key)) return value;
    // otherwise parse the ISO string
    return parseISO(value);
  });
  return state;
};

export interface IBroadbandInstallation {
  currentServiceProvider: string;
  otherServiceProvider: string;
  providerAccountNumber: string;
  installationDate: Date | undefined;
  installationTime?: string;
  activationDate: Date | null;
  contactName: string;
  notes: string;
  consentRequired?: boolean;
}

interface BroadbandStore {
  details?: IBroadbandInstallation;
  isValid: boolean;
  broadbandJourney?: string;
  isChangingProvider: boolean;
  setBroadbandDetails: (value: IBroadbandInstallation) => void;
  setIsValid: (value: boolean) => void;
  setIsChangingProvider: (value: boolean) => void;
  setBroadbandJourney: (value: string) => void;
  clearBroadbandStore: () => void;
}

let [useBroadbandStore, broadbandStoreAPI] = create<BroadbandStore>(
  ReduxDevTools(
    StatePersistence(set => {
      return {
        details: undefined,
        isValid: false,
        prequalData: undefined,
        broadbandJourney: '',
        isChangingProvider: false,
        ...getSessionStorageState(),
        setBroadbandDetails: (value: IBroadbandInstallation) => {
          set({ details: value }, 'setBroadbandDetails');
        },
        setIsValid: (value: boolean) => {
          set({ isValid: value }, 'setIsValid');
        },
        setBroadbandJourney: (value: string) => {
          set({ broadbandJourney: value }, 'setBroadbandJourney');
        },
        clearBroadbandStore: () => {
          set({ details: undefined, isValid: false }, 'clearBroadbandStore');
          sessionStorage.removeItem(sessionStorageStateName);
        },
        setIsChangingProvider: (value: boolean) => {
          set({ isChangingProvider: value }, 'setIsChangingProvider');
        },
      };
    }, sessionStorageStateName),
    'Broadband Store'
  )
);

export { useBroadbandStore, broadbandStoreAPI };
