import React, { useRef } from 'react';
import { SkyAppMenu } from './SkyAppMenu';
import { SkyAppsPathName } from '../../types';
import { myAccountV2SWR } from '../../services';
import { BlockLoader } from '@sky-tv-group/components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

export const MyAccountAppScreen = () => {
  const slug = SkyAppsPathName.MY_ACCOUNT_APP?.replace('/', '');
  const targetRef = useRef<HTMLDivElement>(null);
  const scrollToTarget = () => {
    targetRef?.current?.scrollIntoView({ behaviour: 'smooth' } as object);
  };
  const { data: myAccountAppContent, isValidating } = myAccountV2SWR.useGetComposePageGQL(slug);
  useDocumentTitle(myAccountAppContent?.data?.composePage?.[0]?.title as string);
  const [myAccountWebContentBanner, myAccountComponent, myAccountTwoTileComponent, downloadAppSection] =
    myAccountAppContent?.data?.composePage?.[0]?.content?.content ?? [];
  return (
    <>
      <SkyAppMenu selectedLink={SkyAppsPathName.MY_ACCOUNT_APP} />
      {isValidating ? (
        <div className="h-128">
          <BlockLoader />
        </div>
      ) : (
        <>
          {/* <> */}
          {myAccountWebContentBanner && (
            <>
              {/* Desktop view */}
              <div
                className="hidden lg:flex relative w-100 h-100 d-flex  bg-center bg-cover  px-6"
                style={{
                  backgroundImage: `url(${myAccountWebContentBanner?.bannerContent?.heroImage?.url})`,
                  height: '620px',
                }}>
                <div className="absolute font-bold text-white" style={{ top: '16rem', left: '9rem' }}>
                  <h1 className="sky-h2 md:sky-h1 text-white">
                    {documentToReactComponents(myAccountWebContentBanner?.bannerContent?.heading)}
                  </h1>
                </div>
                <div className="absolute" style={{ top: '30rem', left: '38rem' }}>
                  <button
                    className="bg-gray-300 hover:bg-social-twitter hover:text-white text-dark font-bold py-4 px-20 rounded-full"
                    onClick={scrollToTarget}>
                    {myAccountWebContentBanner?.bannerContent?.callToActionText}
                  </button>
                </div>
              </div>
              {/* Mobile view */}
              <div
                className="flex bg-no-repeat bg-cover md:hidden lg:hidden bg-center"
                style={{
                  backgroundImage: `url(${myAccountWebContentBanner?.bannerContent?.heroImage?.url})`,
                  height: '75vw',
                }}></div>
              <div
                className="relative md:hidden lg:hidden"
                style={{ backgroundColor: '#00013a', width: '100%', height: '7rem' }}>
                <div className="absolute" style={{ top: '-6rem', left: '10rem' }}>
                  <button
                    className="bg-gray-300 hover:bg-social-twitter hover:text-white text-dark font-bold py-4 px-20 rounded-full"
                    onClick={scrollToTarget}>
                    {myAccountWebContentBanner?.bannerContent?.callToActionText}
                  </button>
                </div>
                <div className="absolute" style={{ top: '1.5rem', left: '1.5rem', fontSize: '2.3rem' }}>
                  <h1 className="sky-h2 md:sky-h1 text-white mb-2">
                    {documentToReactComponents(myAccountWebContentBanner?.bannerContent?.heading)}
                  </h1>
                </div>
                <div className="absolute" style={{ width: '31rem', top: '5rem', left: '1.5rem' }}>
                  <hr />
                </div>
              </div>
            </>
          )}

          {myAccountComponent && (
            <>
              <h1
                className="sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3 text-center text-5xl p-2"
                style={{ color: '#00013A', fontFamily: 'MarkPro-Bold SKY' }}
                dangerouslySetInnerHTML={{ __html: myAccountComponent.componentCardSectionTitle as string }}></h1>
              <div className={`py-3 px-2 grid lg:grid-cols-3 lg:px-10  grid-cols-1 gap-4`}>
                {myAccountComponent?.componentCards?.map(({ cardImageUrl, cardTitle, cardDescription }) => {
                  return (
                    <div className="card m-2 overflow-hidden shadow-none" style={{ borderRadius: 0 }}>
                      <div>
                        <img
                          src={cardImageUrl?.url as string}
                          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, width: '35rem' }}
                        />
                      </div>

                      <div className="mt-2 py-3">
                        <div
                          className="sky-h4 sm:sky-h4 md:sky-h4 py-4"
                          style={{ color: '#000000', fontSize: '2rem', fontWeight: 'bold' }}>
                          <div>{cardTitle}</div>
                        </div>

                        <div className="sky-h6 sm:sky-h7 md:sky-h7">
                          <p>{cardDescription}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {myAccountTwoTileComponent && (
            <>
              <h1
                className="sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3 text-center text-5xl p-2"
                style={{ color: '#00013A', fontFamily: 'MarkPro-Bold SKY' }}
                dangerouslySetInnerHTML={{
                  __html: myAccountTwoTileComponent.componentCardSectionTitle as string,
                }}></h1>
              <div className={`py-3 px-2 grid lg:grid-cols-2 lg:px-20  grid-cols-1 gap-4`}>
                {myAccountTwoTileComponent?.componentCards?.map(({ cardImageUrl, cardTitle, cardDescription }) => {
                  return (
                    <div className="card m-2 overflow-hidden shadow-none" style={{ borderRadius: 0 }}>
                      <div>
                        <img
                          src={cardImageUrl?.url as string}
                          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, width: '45rem' }}
                        />
                      </div>

                      <div className="mt-2 py-3">
                        <div
                          className="sky-h4 sm:sky-h4 md:sky-h4 py-4"
                          style={{ color: '#000000', fontSize: '2rem', fontWeight: 'bold' }}>
                          <div>{cardTitle}</div>
                        </div>

                        <div className="sky-h6 sm:sky-h7 md:sky-h7">
                          <p>{cardDescription}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {downloadAppSection && (
            <div ref={targetRef}>
              <h1
                className="sky-h4 sm:sky-h4 md:sky-h4 custom-card-font-bold mt-3 text-center p-2"
                style={{ color: '#000000', fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{ __html: downloadAppSection.componentCardSectionTitle as string }}></h1>
              <div className={`flex justify-center flex-col lg:flex-row items-center py-10 mb-20`}>
                {downloadAppSection?.componentCards?.map(({ cardImageLinkUrl, cardImageUrl }) => {
                  return (
                    <div className="card m-2 overflow-hidden shadow-none" style={{ borderRadius: 0 }}>
                      <div>
                        <a href={cardImageLinkUrl as string} target="_blank">
                          <img
                            src={cardImageUrl?.url as string}
                            style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, width: '15rem' }}
                          />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
