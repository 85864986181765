import { CreditCampaignCodes, CreditOTCCode, productSkuIds } from '../../../../config';

interface ActionAddons {
  [key: string]: string[];
}

export interface RemoveAddons {
  [key: string]: string[] | string;
}

export const buildActions = async (
  addedDataItems: string[],
  removedDataItems: string[],
  addingMeshPackage: boolean,
  removingMeshPackage: boolean,
  hasRouter: boolean,
  hasStaticIp: boolean,
  hasPerfectIntall: boolean,
  availableDiscounts: string[],
  currentDiscounts: string[],
  campaignCodes?: string[]
): Promise<BroadbandActions | undefined> => {
  let broadbandActions: BroadbandActions | undefined = undefined;
  const discountsToAdd = availableDiscounts.filter(item => !currentDiscounts.includes(item));
  const discountsToRemove = currentDiscounts.filter(item => !availableDiscounts.includes(item));

  if (addedDataItems.length > 0 || removedDataItems.length > 0 || addingMeshPackage || hasRouter) {
    broadbandActions = {
      add: await getActionAddOns(
        addedDataItems,
        addingMeshPackage,
        hasRouter,
        hasStaticIp,
        hasPerfectIntall,
        discountsToAdd
      ),
      remove: await getActionRemovedAddOns(removedDataItems, removingMeshPackage, discountsToRemove),
    };
  }

  // if campaigns includes a broadband credit offer, add the product's credit OTC service code
  const hasCreditOffer = campaignCodes?.some(c => Object.values(CreditCampaignCodes.BROADBAND).includes(c));
  if (broadbandActions && hasCreditOffer) {
    const BROADBAND_100 = [productSkuIds.broadbandWifi100.primary, productSkuIds.broadbandWifi100Boost.primary];
    const BROADBAND_1G = [
      productSkuIds.broadbandLightningFastWiFi.primary,
      productSkuIds.broadbandLightningFastWiFiBoost.primary,
    ];
   //related to creditOTC in new order.
   /* if (BROADBAND_100.some(code => broadbandActions?.add.includes(code))) {
      broadbandActions.add.push(CreditOTCCode.BROADBAND_100);
    } else if (BROADBAND_1G.some(code => broadbandActions?.add.includes(code))) {
      broadbandActions.add.push(CreditOTCCode.BROADBAND_1G);
    }*/
  }

  return broadbandActions;
};

export interface BroadbandActions {
  add: Array<ActionAddons | string>;
  remove: Array<RemoveAddons | string>;
}

const getActionAddOns = async (
  addedDataItems: string[],
  addingMeshPackage: boolean,
  hasModem: boolean,
  hasStaticIp: boolean,
  hasPerfectInstall: boolean,
  discountsToAdd: string[]
): Promise<Array<ActionAddons | string>> => {
  let addOns: Array<ActionAddons | string> = [];
  let duplicates = [] as string[];

  if (hasModem) {
    const skyRouter = productSkuIds.skyRouter.primary;
    // Add single mesh device
    addOns.push({
      modem: [skyRouter],
    });
    duplicates.push(skyRouter);
  }

  if (addingMeshPackage) {
    const broadbandForcedOccurrence = productSkuIds.broadbandForcedOccurrence.primary;
    const meshOccurrence = productSkuIds.meshOccurrence.primary;
    const meshSku = productSkuIds.meshDevice.primary;
    // Add single mesh device
    addOns.push({
      mesh: [broadbandForcedOccurrence, meshOccurrence, meshSku],
    });
    duplicates.push(meshOccurrence, meshSku);
  }

  if (hasStaticIp) {
    const staticIpId = productSkuIds.broadbandStaticIP.primary;

    addOns.push({
      static_ip: [staticIpId],
    });
    duplicates.push(staticIpId);
  }

  if (hasPerfectInstall) {
    const perfectInstallId = productSkuIds.broadbandPerfectInstall.primary;

    addOns.push(perfectInstallId);
    duplicates.push(perfectInstallId);
  }

  const itemsToAdd = addedDataItems.filter(x => !duplicates.includes(x));
  addOns.push(...itemsToAdd);
  addOns.push(...discountsToAdd);
  return addOns;
};

const getActionRemovedAddOns = async (
  removedDataItem: string[],
  removingMeshPackage: boolean,
  discountsToRemove: string[]
): Promise<Array<RemoveAddons | string>> => {
  let removedAddOns: Array<RemoveAddons | string> = [];
  let duplicates = [] as string[];

  if (removingMeshPackage) {
    const broadbandForcedOccurrence = productSkuIds.broadbandForcedOccurrence.primary;
    const meshOccurrence = productSkuIds.meshOccurrence.primary;
    const meshSku = productSkuIds.meshDevice.primary;
    // Remove single mesh device
    removedAddOns.push({
      mesh: [broadbandForcedOccurrence, meshOccurrence, meshSku],
    });
    duplicates.push(meshOccurrence, meshSku);
  }

  removedAddOns.push(...removedDataItem);
  removedAddOns.push(...discountsToRemove);
  return removedAddOns;
};
