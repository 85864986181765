import { categoryIds, BRAND, CATEGORY } from '../config';

export const useBrand = (categoryId: number) =>
  categoryId === categoryIds.broadband ||
  categoryId === categoryIds.broadbandServices ||
  categoryId === categoryIds.broadbandOneOffFee ||
  categoryId === categoryIds.broadbandDevices ||
  categoryId === categoryIds.voice ||
  categoryId === categoryIds.voiceAddons ||
  categoryId === categoryIds.voiceCrossCountry
    ? BRAND.BROADBAND
    : BRAND.DTH;

export const useCategory = (categoryId: number) =>
  categoryId === categoryIds.broadband ||
  categoryId === categoryIds.broadbandServices ||
  categoryId === categoryIds.broadbandOneOffFee ||
  categoryId === categoryIds.broadbandDevices ||
  categoryId === categoryIds.voice ||
  categoryId === categoryIds.voiceAddons ||
  categoryId === categoryIds.voiceCrossCountry ||
  categoryId === categoryIds.broadbandTechnicianFee
    ? CATEGORY.BROADBAND
    : CATEGORY.DTH;
