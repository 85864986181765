import React, { useState } from 'react';

import cx from 'classnames';
import { useTransition, animated } from 'react-spring';
import { caret } from '../icon';
import { Link } from '@sky-tv-group/shared';
interface MenuProps {
  link: Link;
  onClick: (link: Link) => void;
}
const Menu = ({ link, onClick }: MenuProps) => {
  let currentUrl = window.location.href;
  return (
    <div className="p-8">
      <div className="border-t border-white border-opacity-50">
        {/* header */}
        {link.title && (
          <button
            key={link.title}
            onClick={() => onClick(link)}
            className={
              'block relative w-full text-blue-darker sky-h4 border-b border-white border-opacity-50 py-4 font-bold hover:text-blue-light'
            }>
            <div className="text-left h-8 leading-8">{link.title}</div>
            <div
              className="w-8 h-8  absolute transform rotate-90 -translate-y-1/2"
              style={{
                right: '100%',
                top: '50%',
              }}>
              {caret}
            </div>
          </button>
        )}
        {/* children links */}
        {link.children?.map(link => (
          <button
            key={link.title}
            onClick={() => onClick(link)}
            className={cx(
              'flex items-center justify-between  w-full  text-blue-darker sky-h4 border-b border-gray-dark border-opacity-50 py-4 hover:font-bold hover:text-blue-light',
              {
                'underline font-bold': link.link && currentUrl.includes(link.link!),
              }
            )}>
            <div className="text-left h-8 leading-8">{link.title}</div>
            {link.children && <div className="w-8 h-8 transform -rotate-90">{caret}</div>}
          </button>
        ))}
      </div>
    </div>
  );
};

let setParent = (link: Link, parent?: Link) => {
  let newLink = { ...link };
  if (newLink.children) {
    newLink.children = newLink.children.map(l => setParent(l, newLink));
  }
  newLink.parent = parent;
  return newLink;
};

interface SideNavProps {
  link: Link;
  close: () => void;
}
const SideNav = ({ link, close }: SideNavProps) => {
  const [currentLink, set] = useState(() => setParent(link));
  const [forward, setDirection] = useState(true);

  let click = (link: Link) => {
    if (link === currentLink && link.parent) {
      set(link.parent);
      setDirection(false);
    } else if (link.children) {
      set(link);
      setDirection(true);
    } else if (link.link) {
      window.location.href = link.link;
      close();
    } else {
      // impossible here
    }
  };

  const transitions = useTransition(currentLink, item => item.title ?? '', {
    initial: null,
    from: { opacity: 0, transform: forward ? 'translate3d(100%,0,0)' : 'translate3d(-100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: forward ? 'translate3d(-100%,0,0)' : 'translate3d(100%,0,0)' },
  });

  return (
    <div className="menus relative h-full overflow-hidden">
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props} className="absolute inset-0">
          <Menu link={item} onClick={click} />
        </animated.div>
      ))}
    </div>
  );
};

export { SideNav };
