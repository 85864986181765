import { createBrowserHistory } from 'history';
import { useAnalytics } from '@sky-tv-group/shared';

const history = createBrowserHistory();
const BUILD_MY_PLAN = '/build-my-plan';

history.listen(location => {
  /* If pathname ends with /build-my-plan, it means the page hasn't loaded completely so ignore to stop triggering duplicate page events. */
  const { page } = useAnalytics();
  if (!location?.pathname.endsWith(BUILD_MY_PLAN)) {
    page();
  }

  if ('skus' in (location.state ?? {})) return;
});

export default history;
