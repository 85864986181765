import { format } from 'date-fns';
import { productSkuIds } from '../../../../../config';
import { AddressService } from '../../../../../services/address';
import { AddressPrequalification } from '../../../../../services/prequal';
import { IBroadbandDelivery } from '../../../../../store/broadbandDeliveryStore';
import { IBroadbandInstallation } from '../../../../../store/broadbandStore';
import { FibreCompanies } from '../../../../../types';
import { CustomerInfo } from '../../../helper';
import {
  DeliveryAddress,
  DetailAddons,
  getDeliveryInformation,
  getDetailAddOns,
  getLocationId,
  getLSP,
  getProduct,
  getProductKey,
  LosingProviderDetails,
  SiteInfo,
} from '../broadbandOrderDetails';
import { BBPlanIndex } from '../../dataServiceBuilder';

export interface Details {
  addonOnly: boolean;
  hardwareOnly: boolean;
  activationDate: string | null;
  activationTime: string | null;
  productId: string;
  connectionType: ConnectionType;
  siteInfo: SiteInfo | null;
  losingServiceProviderDetails: LosingProviderDetails | {};
  broadbandProduct: string;
  addons: DetailAddons[];
  deliveryAddress: DeliveryAddress | {};
  providerUserId?: string;
  lfc?: string;
}

/**
 * Builds out a detail object which represents a payload object for broadband sent to ICOMS
 * @param details - A details containing information relevent to a new broadband customer
 * @param deliveryDetails - A secondary details object containing information specfic for delivery (e.g. address information)
 * @param customer - Customer object needed to get delivery information and site info
 * @param hasOrderedOnlyHardware - True if the user is only ordering hardware e.g. router or mesh device
 * @param hasMesh - True if the user is ordering a mesh device (sky booster)
 * @param hasStaticIp - True if the user is ordering static ip
 * @param getPrequalData - A function that gets prequal data
 * @param voiceAdded - True if the user is ordering voice
 * @param hasActiveBroadbandPackage - True if the user is already a broadband user
 * @param addressService - Address service needed to build delivery information
 * @param providerUserId - Internet service provider user id needed to build delivery information
 * @returns A details array containing broadband order information needed to build the payload that is sent to ICOMS
 */
export const buildDetails = async (
  details: IBroadbandInstallation,
  deliveryDetails: IBroadbandDelivery,
  customer: CustomerInfo,
  hasOrderedOnlyHardware: boolean,
  hasMesh: boolean,
  hasStaticIp: boolean,
  hasPerfectInstall: boolean,
  getPrequalData: (addressId: string) => Promise<AddressPrequalification | undefined>,
  voiceAdded: boolean,
  hasActiveBroadbandPackage: boolean,
  addressService: AddressService,
  bbPlanIndex: BBPlanIndex,
  installType: string,
  hasRouter: boolean,
  providerUserId?: string
): Promise<Details[]> => {
  let prequalData = await getPrequalData(customer.tuiAddressCode!);

  let detailObject = {
    addonOnly: false, //todo check when to set this to true
    hardwareOnly: hasOrderedOnlyHardware,
    productId: getProductKey(prequalData, bbPlanIndex, voiceAdded),
    activationDate: getActivationDate(details, prequalData),
    locationId: getLocationId(prequalData),
    connectionType: getConnectionType(prequalData),
    broadbandProduct: getProduct(prequalData, bbPlanIndex, voiceAdded),
    activationTime: getActivationTime(details, prequalData),
    siteInfo: getSiteInfo(
      customer,
      deliveryDetails.mobileNumberAreaCode.concat(deliveryDetails.mobileNumberLineNumber),
      deliveryDetails.email,
      details.contactName,
      details.notes
    ),
    installType: installType,
    losingServiceProviderDetails: getLSP(prequalData, details),
    addons: await getDetailAddOns(hasRouter, hasMesh, hasStaticIp, prequalData),
    deliveryAddress: isSelfInstall(installType)
      ? await getDeliveryInformation(
          deliveryDetails,
          customer,
          deliveryDetails.email,
          deliveryDetails.mobileNumberAreaCode.concat(deliveryDetails.mobileNumberLineNumber),
          addressService
        )
      : {},
    skyTech: hasPerfectInstall,
    ...(!hasActiveBroadbandPackage
      ? {
          consentRequired: details.consentRequired,
        }
      : null),
    ...(providerUserId !== '' ? { providerUserId: providerUserId } : null),
    lfc: getLFC(prequalData),
    // If has staticip set ipAddress to static otherwise null
    ...(hasStaticIp ? { ipAddressAssignment: 'static' } : null),
  };

  return [detailObject];
};

/////////////////////////////////////////////////////////////////////////////////////////////////
// Below functions are specific to this details builder and as such are not exported           //
// If any of these functions are needed for other builders move to shared file                 //
/////////////////////////////////////////////////////////////////////////////////////////////////

const isSelfInstall = (installType: string) => installType === productSkuIds.broadbandSelfInstall.label;

const getActivationDate = (details: IBroadbandInstallation, prequalData: AddressPrequalification | undefined) => {
  if (
    prequalData?.consentRequired() ||
    (prequalData?.isNewFibreJourney() && prequalData?.isLfcDoesntTellUsIfConsentRequired())
  ) {
    return null;
  }
  // Return installation date for activation date if it's a chorus new fibre journey where consent is not required.
  if (prequalData?.isNewFibreJourney() && prequalData?.isChorus() && !prequalData?.consentRequired()) {
    return format(details.installationDate!, 'yyyy-MM-dd');
  }
  return format(details.activationDate!, 'yyyy-MM-dd');
};

const getActivationTime = (details: IBroadbandInstallation, prequalData: AddressPrequalification | undefined) => {
  if (
    prequalData?.consentRequired() ||
    (prequalData?.isNewFibreJourney() && prequalData?.isLfcDoesntTellUsIfConsentRequired())
  ) {
    return null;
  }
  if (prequalData?.isNewFibreJourney() && details.installationTime === 'PM') {
    return '13:00';
  }
  return '09:00';
};

export const getLFC = (prequalData: AddressPrequalification | undefined) => {
  if (!prequalData) {
    return undefined;
  }

  switch (prequalData.getLFC()) {
    case FibreCompanies.Chorus:
      return 'chorus';
    case FibreCompanies.Enable:
      return 'enable';
    case FibreCompanies.NorthPower:
      return 'northpower';
    case FibreCompanies.UFF:
      return 'uff';
    default:
      return undefined;
  }
};

type ConnectionType = 'Migrate' | 'New' | 'Connect';

const getConnectionType = (prequalData: AddressPrequalification | undefined): ConnectionType =>
  prequalData?.isNewFibreJourney() ? 'New' : prequalData?.isIntactONTJourney() ? 'Connect' : 'Migrate';

const getSiteInfo = (
  customer: CustomerInfo,
  contactNumber: string,
  email: string,
  contactName: string,
  notes: string
) => {
  let siteContactName = '';

  // If user entered contact name use that, otherwise use default name
  if (contactName.trim()) {
    siteContactName = contactName.trim();
  } else {
    const fullName = `${customer.firstName ?? ''} ${customer.lastName ?? ''}`.trim(); // Backtick for chrome `
    siteContactName = fullName.replace(/[0-9]/g, '');
  }
  // Same for other fields, use user entered value, otherwise use a default
  return {
    siteContactName,
    siteContactNumber: contactNumber,
    siteContactEmail: email ? email : customer.serviceContactEmail,
    siteAccessInformation: notes ? notes : 'n/a',
  };
};
