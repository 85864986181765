import { AxiosInstance } from 'axios';
import { article, articles } from '../types';

export class FaqService {
  private agentFaq: AxiosInstance;
  constructor(agentFaq: AxiosInstance) {
    this.agentFaq = agentFaq;
  }

  public getArticles = async (url: string) => {
    let { data } = await this.agentFaq.get<articles>(url, {});
    return data;
  };
}
