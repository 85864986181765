import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  boxUpgradeModalCopy,
  formatMoneyTwoDecimalPlaces,
  newSkyBoxImportantInfo,
  productSkuIds,
  SPLITIO_KEY,
  T_Product,
  useEligibilityOffers,
  useLockBodyScroll,
  useSplitIO,
  useCoupon,
  T_Coupon,
  couponStoreApi,
  couponCodes,
  SKY_REWARD_TEMPLATE_TYPE,
} from '@sky-tv-group/shared';
import AnimatedModal from '../AnimatedModal';
import { CurrentAddress } from '../address/CurrentAddress';
import {
  Checked,
  HardDrive,
  InternetSymbol,
  LoyaltyDiscount,
  MonthlyPayment,
  OneOffPayment,
  SpeechBubble,
  Unchecked,
} from '../icon';
import { CarouselContainer } from '../carousel/CarouselContainer';
import { ICEBoxCardSelectable } from '../box-line-items/ICEBoxCardSelectable';
import { Button } from '../button';
import { MarketingRectangle } from '../arrow-marketing/MarketingRectangle';
import { SourceJourney } from '../SkyBoxPrimary';
import { SkyBoxDescription } from '../SkyBoxDescription';

const multiroomModalCopy = {
  addMultiroomCopy:
    "Get another Sky Device installed so that people in another room can watch different channels (Max. 5). We'll send a technician to get your Multiroom up and running at no additional charge. ",
  addMultiroomCopyforPod:
    'Get another Sky Device installed so that people in another room can watch different channels (Max. 5).',
  multiroomChargesMessage: '- Multiroom costs an additional $25/month per device',
};

const slickSettings = {
  autoSlidesToShow: true,
  variableWidth: true,
  infinite: false,
  arrows: false,
  dots: false,
  customClassName: 'custom-carousel slick-slide-px-r-20',
  responsiveBlock: [],
};

interface ArrowFeesProducts {
  arrowUpfront?: T_Product;
  arrowMonthly?: T_Product;
}
export interface ModalConfirmParams {
  // selected product SKU
  selected: string;
  // if customer selected oneoff fee, applicable only to arrow boxes
  oneoff: boolean;
  // customer added mysky recording
  recording: boolean;
  // if box is to become primary
  primary: boolean;
  // existing product
  editing?: Box;
}

export interface MultiroomModalProps {
  // Konakart box type products selectable inside the modal.
  // This products should be populated by the parent container depending on which journey
  // the user is and also the eligibility of the account.
  products: T_Product[];

  // Customer address
  address: string;

  // Controlled header text
  header: string;

  // The existing box we are editing, controls wether we are in "Edit" or "Add" mode
  editingBox?: Box;

  // reusing MultiroomModal for adding primary box
  isPrimary?: boolean;

  // Konakart product fees for arrow, this is being passed as props because the prices
  // might differ based on the eligibility API.
  arrowFees?: ArrowFeesProducts;

  // Callback to either add or change the existing box
  confirm?: (params: ModalConfirmParams) => void;

  // Cancel the multiroom
  cancel?: (box: Box) => void;

  // useModal() hook toggle function, should be already handled in
  toggleModal: () => void;

  // Used for pre-selecting a box product when modal opens w/o adding the actual box to cart and boxes store
  preSelectedBoxSKU?: string;

  source?: SourceJourney;
}

/**
 * Component used for Multiroom aquisition/upgrade/edit and Primary Box aquisition
 */
export const MultiroomModal: FC<MultiroomModalProps> = ({
  editingBox,
  header,
  products,
  address,
  toggleModal,
  arrowFees,
  confirm,
  cancel,
  isPrimary = false,
  preSelectedBoxSKU,
  source,
}) => {
  const [newSkyBoxCSGEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX_CSG);
  const [showDelayedArrowInstallation] = useSplitIO(SPLITIO_KEY.SKYWEB_SHOW_DELAYED_ARROW_INSTALLATION);
  const [acqFusionBoxHide] = useSplitIO(SPLITIO_KEY.SKYWEB_ACQ_HIDE_MNTH_ONEOF_CHRGE);

  // initial selection flags
  const isEditingArrow = editingBox?.serviceCode?.includes(productSkuIds.arrowBox.primary);
  const isRecordingSelected = editingBox?.products.some(p => p.sku.includes(productSkuIds.arrowBoxRecording.primary));
  const isOneoffSelected = editingBox?.products.some(p => p.sku.includes(productSkuIds.arrowBoxOneOff.primary));
  const isEditingSkyBox = editingBox?.serviceCode
    ? productSkuIds.skyBox.primary.includes(editingBox?.serviceCode)
    : false;

  // box and options selection states
  const [selectedProductSKU, selectedProductSKUSet] = useState(preSelectedBoxSKU ?? editingBox?.serviceCode ?? '');
  const [paymentOneoff, setPaymentOneoff] = useState(true); // useState(!isOneoffSelected ? false : true);
  const [arrowRecordingSelected, setArrowRecordingSelection] = useState(isRecordingSelected ? true : false);

  const isArrowSelected = selectedProductSKU.includes(productSkuIds.arrowBox.primary);
  const isSkyBox = productSkuIds.skyBox.primary.includes(selectedProductSKU);
  const isSkyPodSelected = selectedProductSKU === productSkuIds.skyPod.primary;
  const paymentOptionsRef = useRef<HTMLInputElement>(null);
  const recordingRef = useRef<HTMLInputElement>(null);
  const isSkyPodAvailable = products.some(prod => prod.sku === productSkuIds.skyPod.primary);
  const isNewSkyBoxAvailable = products.some(prod => prod.sku === productSkuIds.arrowBox.primary);

  // eligibilty stuff
  const discountedBoxFeeIfAvailable = useEligibilityOffers().discountedBoxFeeIfAvailable();
  const arrowLoyaltyOffers = useEligibilityOffers().anyArrowLoyaltyOffers;
  useLockBodyScroll();
  // scroll to payment options when arrow is selected but not on first render.
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  useEffect(() => {
    if ((isArrowSelected || isSkyPodSelected) && paymentOptionsRef.current && !firstRender.current && !isEditingArrow) {
      paymentOptionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArrowSelected, isSkyPodSelected]);

  useEffect(() => {
    if (selectedProductSKU !== editingBox?.serviceCode) {
      setArrowRecordingSelection(false);
      if (source === SourceJourney.WEB_ICE || arrowLoyaltyOffers) {
        setPaymentOneoff(true);
      } else {
        setPaymentOneoff(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductSKU, source]);

  //scrolling to myrecording if skybox selected
  useEffect(() => {
    if (isSkyBox && recordingRef.current && !firstRender.current && !isEditingSkyBox) {
      recordingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductSKU]);

  // have we changed the existing options?
  const changed =
    editingBox?.serviceCode !== selectedProductSKU ||
    isRecordingSelected !== arrowRecordingSelected ||
    isOneoffSelected !== paymentOneoff;

  const chosenOneoffWithOffer = editingBox?.products.find(
    p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary && p.price0 !== p.priceIncTax
  );
  const has01292 = couponStoreApi
    .getState()
    .coupons.find((c: T_Coupon) => c.couponCode === couponCodes.offer50WOShohoHindi);

  const onToggleModal = (cb: Function) => {
    toggleModal();
    window.document.body.style.cursor = 'progress';
    setTimeout(async () => {
      await cb();
      window.document.body.style.cursor = 'default';
    }, 0);
  };

  const currentUserArrowLoyaltyOfferDetails = useEligibilityOffers().currentUserArrowLoyaltyOfferDetails();
  const rewardTemplateType = useCoupon().couponRewardTemplateType(
    currentUserArrowLoyaltyOfferDetails.coupon?.couponCode
  );

  return (
    <AnimatedModal isShown={true} toggleModal={toggleModal} isBoxModal={true}>
      <div className="mx-5 lg:mx-40 xl:mx-56 mt-12 gap-12 text-navy flex flex-col divide-y last:mb-12">
        <div>
          <div className="flex flex-row justify-between items-center">
            <h3
              className="relative card sky-h5-black md:sky-h5-black text-navy flex flex-wrap items-center"
              data-testid={header}>
              {header}&nbsp;
              {!isPrimary && (
                <small className="md:sky-h7-reg sky-h7">{multiroomModalCopy.multiroomChargesMessage}</small>
              )}
            </h3>
          </div>
          {!isPrimary && (
            <h4 className="md:flex relative pt-4 pb-4 items-center sky-h7-reg text-13px text-navy">
              {isSkyPodSelected || !selectedProductSKU.length
                ? multiroomModalCopy.addMultiroomCopyforPod
                : multiroomModalCopy.addMultiroomCopy}
            </h4>
          )}
          {address && (
            <CurrentAddress
              address={address ? address : ''}
              containerClass={`sky-h7-reg md:sky-h7-reg flex ${isPrimary ? 'pt-6' : 'pb-6'}`}
            />
          )}
          <div className="block md:hidden">
            <CarouselContainer {...slickSettings}>
              {products?.map(product => {
                if (product.sku === productSkuIds.skyBoxCharge.primary && acqFusionBoxHide) {
                  return;
                } else {
                  return (
                    <div className="w-270px mt-6 flex" key={product.id}>
                      <ICEBoxCardSelectable
                        editingBox={editingBox}
                        product={product}
                        handleProductSelection={selectedProductSKUSet}
                        isProductSelected={selectedProductSKU === product.sku}
                        isMultiroom={true}
                      />
                    </div>
                  );
                }
              })}
            </CarouselContainer>
          </div>
          <div className="hidden mt-4 md:block">
            <div className="flex flex-row gap-6 pb-10 xl:pb-0 w-858px h-325px">
              {products?.map(product => {
                if (product.sku === productSkuIds.skyBoxCharge.primary && acqFusionBoxHide) {
                  return;
                } else {
                  return (
                    <div className="w-270px h-325px  flex " key={product.id}>
                      <ICEBoxCardSelectable
                        editingBox={editingBox}
                        product={product}
                        handleProductSelection={selectedProductSKUSet}
                        isProductSelected={selectedProductSKU === product.sku}
                        isMultiroom={true}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          {(isNewSkyBoxAvailable || isSkyPodAvailable) && (
            <>
              {isArrowSelected && showDelayedArrowInstallation ? (
                <p className="pt-4">
                  Due to the popularity of the new Sky Box there may be some delay in processing your order and shipping
                  your device. We will get it on its way to you as soon as possible and will notify you when it is being
                  shipped.
                </p>
              ) : null}

              <div className="flex flex-col pt-8">
                <span className="ml-3 mt-3 sky-h7-reg md:sky-h7-reg">
                  {isSkyPodAvailable &&
                  isSkyPodSelected &&
                  (editingBox?.boxType === 'UPGRADE' || editingBox?.boxType === 'SUBSCRIBED')
                    ? "Your Sky Pod won't have your old recordings so you’ll need to watch these before you activate your Sky Pod. Your recordings will be deleted from your current box once you send it back."
                    : ''}
                </span>

                {(isArrowSelected || isSkyPodSelected) && (
                  <div className="flex" ref={paymentOptionsRef}>
                    <SkyBoxDescription
                      isSkyPodSelected={isSkyPodSelected}
                      isArrowSelected={isArrowSelected}
                      source={source}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {isArrowSelected &&
            newSkyBoxCSGEnabled &&
            source !== SourceJourney.WEB_ICE &&
            source !== SourceJourney.WEB_DART && (
              <div className="flex pt-5">
                <span>
                  <SpeechBubble />
                </span>
                <span className="ml-3 sky-h7-reg md:sky-h7-reg">
                  {newSkyBoxImportantInfo.satisfactionGuarantee} Click here for full{' '}
                  <a className="text-blue-pure" href="https://sky.co.nz/csg-terms">
                    T&Cs
                  </a>
                </span>
              </div>
            )}
        </div>

        {isArrowSelected && (
          <>
            <div
              id="main-container"
              className={`${
                acqFusionBoxHide ? 'hidden' : 'flex'
              }  md:flex lg:flex flex-row items-start gap-10 p-0 mb-12 w-920px h-547px`}>
              <div
                id="payment-container"
                className={` ${
                  acqFusionBoxHide ? 'hidden' : 'flex'
                } flex-col items-start gap-4 pt-12 h-547px w-564px overflow-x-scroll scrollNone`}>
                <h2 className="sky-h5-black md:sky-h5-black">Choose the payment option to suit you </h2>
                <div className="flex">
                  <p className="sky-h6-reg md:sky-h6-reg w-540">{boxUpgradeModalCopy.paymentOptionsCopy}</p>
                </div>
                <div className="flex flex-row items-start gap-6 p-0 mt-4 h-363px w-800px">
                  <div
                    className={`payment-options-card ${paymentOneoff ? 'checked' : undefined}`}
                    onClick={() => {
                      setPaymentOneoff(true);
                    }}>
                    {(chosenOneoffWithOffer || discountedBoxFeeIfAvailable.isDiscounted) && (
                      <MarketingRectangle
                        mRectColor={
                          'linear-gradient(270deg, #9100D4 -5.35%, #9100D4 -0.07%, #9100D4 57.93%, #9100D4 126.47%)'
                        }
                        mRectTag={`You save $${
                          chosenOneoffWithOffer
                            ? chosenOneoffWithOffer.price0 - chosenOneoffWithOffer.priceIncTax
                            : discountedBoxFeeIfAvailable.originalPrice - discountedBoxFeeIfAvailable.price
                        }`}
                        mRectTagClasses={'text-white text-center sky-h5-bold py-2 pl-3 pr-12'}
                      />
                    )}
                    {has01292 && (
                      <MarketingRectangle
                        mRectColor={
                          'linear-gradient(270deg, #9100D4 -5.35%, #9100D4 -0.07%, #9100D4 57.93%, #9100D4 126.47%)'
                        }
                        mRectTag={`You save $100`}
                        mRectTagClasses={'text-white text-center sky-h5-bold py-2 pl-3 pr-12'}
                      />
                    )}
                    <button className="absolute top-0 right-0" style={{ marginTop: '10px', marginRight: '10px' }}>
                      {paymentOneoff ? <Checked /> : <Unchecked />}
                    </button>
                    <div className="content-wrapper">
                      {chosenOneoffWithOffer || discountedBoxFeeIfAvailable.isDiscounted ? (
                        <LoyaltyDiscount />
                      ) : (
                        <OneOffPayment />
                      )}
                      <p
                        className="inline-flex flex-row text-midnight items-center sky-h5-black"
                        style={{ marginTop: '15px' }}>
                        {'One-off Charge'}
                      </p>
                      <p
                        className="inline-flex flex-row text-midnight items-center sky-h6 mx-2 text-center"
                        style={{ marginTop: '10px' }}>
                        {arrowFees?.arrowUpfront?.description}
                      </p>
                      <div className="text-black text-center" style={{ marginTop: '40px' }}>
                        {rewardTemplateType === SKY_REWARD_TEMPLATE_TYPE.REWARD &&
                        (chosenOneoffWithOffer || discountedBoxFeeIfAvailable.isDiscounted) ? (
                          <>
                            <small className="align-top sky-sub sky-text-red line-through">
                              ${formatMoneyTwoDecimalPlaces(arrowFees?.arrowUpfront?.price0 ?? 200)}
                            </small>
                            <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                              $
                            </small>
                            <span className="sky-h5-bold inline-block">
                              {' '}
                              {formatMoneyTwoDecimalPlaces(
                                chosenOneoffWithOffer
                                  ? chosenOneoffWithOffer.priceIncTax
                                  : discountedBoxFeeIfAvailable.price
                              )}
                            </span>
                            <small className="align-bottom sky-sub">/one-off charge</small>
                          </>
                        ) : (
                          <>
                            {has01292 && arrowFees?.arrowUpfront?.price0 && (
                              <small className=" align-top sky-text-red line-through " style={{ fontSize: '10px' }}>
                                {`$${formatMoneyTwoDecimalPlaces(arrowFees?.arrowUpfront?.price0)} `}
                              </small>
                            )}
                            <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                              $
                            </small>
                            <span className="sky-h5-bold inline-block">
                              {' '}
                              {has01292
                                ? arrowFees?.arrowUpfront?.price0 &&
                                  formatMoneyTwoDecimalPlaces(arrowFees?.arrowUpfront?.price0 / 2)
                                : formatMoneyTwoDecimalPlaces(
                                    chosenOneoffWithOffer
                                      ? chosenOneoffWithOffer.priceIncTax
                                      : discountedBoxFeeIfAvailable.price
                                  )}
                            </span>
                            <span className=" align-bottom sky-sub">{'/one-off charge '}</span>
                            {(chosenOneoffWithOffer || discountedBoxFeeIfAvailable.isDiscounted) && !has01292 && (
                              <>
                                <br></br>
                                <small className="align-bottom">
                                  (price without offer $
                                  {chosenOneoffWithOffer
                                    ? chosenOneoffWithOffer.price0
                                    : discountedBoxFeeIfAvailable.originalPrice}
                                  ){' '}
                                </small>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`payment-options-card ${!paymentOneoff ? 'checked' : undefined}`}
                    onClick={() => {
                      setPaymentOneoff(false);
                    }}>
                    <button className="absolute top-0 right-0" style={{ marginTop: '10px', marginRight: '10px' }}>
                      {!paymentOneoff ? <Checked /> : <Unchecked />}
                    </button>
                    <div className="content-wrapper">
                      <MonthlyPayment />
                      <p
                        className="inline-flex flex-row text-midnight items-center sky-h5-black"
                        style={{ marginTop: '15px' }}>
                        {'Pay Monthly'}
                      </p>
                      <p
                        className="inline-flex flex-row text-midnight items-center sky-h6 mx-2 text-center"
                        style={{ marginTop: '10px' }}>
                        {arrowFees?.arrowMonthly?.description}
                      </p>
                      <div className="text-black" style={{ marginTop: '10px' }}>
                        <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                          $
                        </small>
                        <span className="sky-h5-bold inline-block"> {'10'}</span>
                        <small className="align-bottom sky-sub">/mth</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`flex-wrap sm:flex-no-wrap sm:flex-row border verticalBorder hidden ${
                  acqFusionBoxHide ? 'md:hidden' : 'md:flex'
                }`}></div>
              <div id="mysky-container" className="hidden md:block">
                {selectedProductSKU && !isSkyPodSelected && (
                  <div className="flex flex-col items-start gap-4 pt-12 h-503px w-270px">
                    <h2 className="sky-h5-black md:sky-h5-black">Add My Sky Recording</h2>
                    <div className="flex">
                      <p className="sky-h6-reg md:sky-h6-reg">
                        With My Sky you can pause, rewind and record live TV. Never miss an episode again with our smart
                        series link feature.
                      </p>
                    </div>
                    <div className="flex flex-row mt-4 w-270px mh-370px">
                      <div
                        className={`border border-gray-dark rounded-md relative hover:border-gray-copy ${
                          arrowRecordingSelected ? 'border-gray-copy' : undefined
                        }`}
                        onClick={() => {
                          setArrowRecordingSelection(!arrowRecordingSelected);
                        }}>
                        <button
                          className="absolute top-0 right-0"
                          style={{ marginTop: '10px', marginRight: '10px' }}
                          onClick={() => setArrowRecordingSelection(!arrowRecordingSelected)}>
                          {arrowRecordingSelected ? <Checked /> : <Unchecked />}
                        </button>
                        <div className="flex flex-col py-12 items-center">
                          <HardDrive bgColor="#F2F2F2" />
                          <p
                            className="inline-flex flex-row text-midnight items-center sky-h5-black"
                            style={{ marginTop: '20px' }}>
                            {isArrowSelected ? '1TB Recording Storage' : '500GB Recording Storage'}
                          </p>
                          <p
                            className="inline-flex flex-row text-midnight items-center sky-h6 mx-2 text-center"
                            style={{ marginTop: '10px' }}>
                            You choose when to watch your favourite shows
                          </p>
                          <div className="text-black mt-20">
                            <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                              $
                            </small>
                            <span className="sky-h5-bold inline-block"> {'15'}</span>
                            <small className="align-bottom sky-sub">/mth</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {isSkyBox && selectedProductSKU && !isSkyPodSelected && (
          <div ref={recordingRef}>
            <h2 className="sky-h5-black md:sky-h5-black py-12">Add My Sky Recording</h2>
            <div className="flex flex-row overflow-x-auto">
              <div
                className={`w-270px h-270px border border-gray-dark rounded-md relative hover:border-gray-copy ${
                  arrowRecordingSelected ? 'border-gray-copy' : undefined
                }`}
                onClick={() => {
                  setArrowRecordingSelection(!arrowRecordingSelected);
                }}>
                <button
                  className="absolute top-0 right-0"
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  onClick={() => setArrowRecordingSelection(!arrowRecordingSelected)}>
                  {arrowRecordingSelected ? <Checked /> : <Unchecked />}
                </button>
                <div className="flex flex-col py-12 items-center">
                  <HardDrive bgColor="#F2F2F2" />
                  <p
                    className="inline-flex flex-row text-midnight items-center sky-h5-black"
                    style={{ marginTop: '20px' }}>
                    {isArrowSelected ? '1TB Recording Storage' : '500GB Recording Storage'}
                  </p>
                  <div className="text-black" style={{ marginTop: '10px' }}>
                    <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                      $
                    </small>
                    <span className="sky-h5-bold inline-block"> {'15'}</span>
                    <small className="align-bottom sky-sub">/mth</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isSkyBox && selectedProductSKU && !isSkyPodSelected && (
          <div ref={recordingRef} className="md:hidden block">
            <h2 className="sky-h5-black md:sky-h5-black py-12">Add My Sky Recording</h2>
            <div className={`flex flex-row overflow-x-auto ${acqFusionBoxHide ? 'justify-center' : ''}`}>
              <div
                className={`w-270px h-270px border border-gray-dark rounded-md relative hover:border-gray-copy ${
                  arrowRecordingSelected ? 'border-gray-copy' : undefined
                }`}
                onClick={() => {
                  setArrowRecordingSelection(!arrowRecordingSelected);
                }}>
                <button
                  className="absolute top-0 right-0"
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  onClick={() => setArrowRecordingSelection(!arrowRecordingSelected)}>
                  {arrowRecordingSelected ? <Checked /> : <Unchecked />}
                </button>
                <div className="flex flex-col py-12 items-center">
                  <HardDrive bgColor="#F2F2F2" />
                  <p
                    className="inline-flex flex-row text-midnight items-center sky-h5-black"
                    style={{ marginTop: '20px' }}>
                    {isArrowSelected ? '1TB Recording Storage' : '500GB Recording Storage'}
                  </p>
                  <div className="text-black" style={{ marginTop: '10px' }}>
                    <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                      $
                    </small>
                    <span className="sky-h5-bold inline-block"> {'15'}</span>
                    <small className="align-bottom sky-sub">/mth</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!selectedProductSKU && confirm && !editingBox && (
          <div
            className={`flex addbox  border-t py-8 my-8 mr-4 items-stretch ${
              address ? 'items-stretch xl:flex-row-reverse md:flex-row-reverse' : ''
            }`}>
            <Button
              colorMode="disabled"
              className="addboxbtn"
              disabled={true}
              templateType={rewardTemplateType}
              onClick={() => {}}>
              Add Device
            </Button>
            <Button colorMode="pure" variant="secondary" className="addboxbtn" onClick={toggleModal}>
              Cancel
            </Button>
          </div>
        )}

        {selectedProductSKU && confirm && !editingBox && (
          <div
            className={`flex addbox  border-t py-8 my-8 mr-4  items-stretch ${
              address ? 'items-stretch xl:flex-row-reverse md:flex-row-reverse' : ''
            }`}>
            <Button
              colorMode="pure"
              className="addboxbtn"
              templateType={rewardTemplateType}
              onClick={() => {
                onToggleModal(() => {
                  confirm({
                    selected: selectedProductSKU,
                    oneoff: paymentOneoff,
                    recording: arrowRecordingSelected,
                    primary: isPrimary,
                  });
                });
              }}>
              Add Device
            </Button>
            <Button colorMode="pure" variant="secondary" className="addboxbtn" onClick={toggleModal}>
              Cancel
            </Button>
          </div>
        )}
        {selectedProductSKU && confirm && editingBox && (
          <div
            className={`addbox border-t py-8 my-8 mr-4 items-stretch${
              address ? 'items-stretch xl:flex-row-reverse md:flex-row-reverse' : ''
            }`}>
            {!changed ? (
              <Button
                variant="primary"
                className="addboxbtn"
                templateType={rewardTemplateType}
                colorMode={'pure'}
                disabled={false}
                onClick={toggleModal}>
                Close
              </Button>
            ) : (
              <div
                className={`editbox items-stretch ${
                  address ? 'items-stretch xl:flex-row-reverse md:flex-row-reverse' : ''
                }`}>
                <Button
                  colorMode={!changed ? 'disabled' : 'pure'}
                  className="editboxbtn"
                  templateType={rewardTemplateType}
                  onClick={() => {
                    onToggleModal(() => {
                      confirm({
                        selected: selectedProductSKU,
                        oneoff: paymentOneoff,
                        recording: arrowRecordingSelected,
                        primary: isPrimary,
                        editing: editingBox,
                      });
                    });
                  }}
                  disabled={!changed}>
                  Confirm Changes
                </Button>
                <Button
                  colorMode={!changed ? 'disabled' : 'pure'}
                  variant="secondary"
                  className="editboxbtn"
                  templateType={rewardTemplateType}
                  onClick={toggleModal}
                  disabled={!changed}>
                  Discard Changes
                </Button>
              </div>
            )}
            {cancel && (
              <Button
                colorMode="pure"
                variant="secondary"
                className="addboxbtn"
                templateType={rewardTemplateType}
                onClick={() => {
                  onToggleModal(() => {
                    cancel(editingBox);
                  });
                }}>
                Remove Device
              </Button>
            )}
          </div>
        )}
      </div>
    </AnimatedModal>
  );
};
