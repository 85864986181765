import useSWR from 'swr';
import { MyAccountV2Service } from '..';

export class MyAccountV2SWR {
  private myAccountService: MyAccountV2Service;

  // Note: Can't use auto assign of class properties feature of typescript constructors
  // because babel does not support it currently.
  // The change is to allow WEB-50 (debugging of shared files) to work
  constructor(myAccountService: MyAccountV2Service) {
    this.myAccountService = myAccountService;
  }

  useGetMoreChoicesForYou = (usePreview: boolean = false) => {
    const fetcher = () => this.myAccountService.getMoreChoicesForYou({ usePreview });
    const result = useSWR('/more-choices-for-you', fetcher, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result.data;
  };

  useGetOrderConfirmation = (usePreview: boolean = false) => {
    const fetcher = () => this.myAccountService.getOrderConfirmation({ usePreview });
    const result = useSWR('/orderConfirmation', fetcher, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result;
  };

  useGetProductCards = () => {
    const result = useSWR('/get-product-cards', this.myAccountService.getProductCards, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result.data;
  };

  useUserInformationGQL = () => {
    const result = useSWR('/user-information-gql', this.myAccountService.getUserInformation, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result.data;
  };

  useGetEligibilityOffers = () => {
    const result = useSWR('/get-eligibility-offers', this.myAccountService.getEligibilityOffers, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result.data;
  };

  useGetTermsAndConditionMenuGQL = (usePreview: boolean = false) => {
    const fetcher = () => this.myAccountService.getTermsAndContionMenus({ usePreview });
    const result = useSWR('/terms-and-condition-menus-gql', fetcher, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result;
  };

  useGetTermsAndConditionContentGQL = (slug: string, usePreview: boolean = false) => {
    const fetcher = () => this.myAccountService.getTermsAndContionContent({ usePreview, slug });
    const result = useSWR(`/terms-and-condition-content-gql/${slug}`, fetcher, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result;
  };

  useGetComposePageGQL = (slug: string, usePreview: boolean = false) => {
    const fetcher = () => this.myAccountService.getComposePage({ usePreview, slug });
    const result = useSWR(`/compose-page-gql/${slug}`, fetcher, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result;
  };

  useGetMenuSectionListGQL = (tags: string, usePreview: boolean = false) => {
    const fetcher = () => this.myAccountService.getMenuListItem({ usePreview, tags });
    const result = useSWR(`/menu-list-item-gql/${tags}`, fetcher, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result;
  };

  useGetComponentCardSectionGQL = (usePreview: boolean = false, sectionName: string) => {
    const fetcher = () => this.myAccountService.getComponentCardSection({ usePreview, sectionName });
    const result = useSWR(`/component-card-section/${sectionName}`, fetcher, {
      dedupingInterval: 10 * 60 * 1000,
    });
    return result;
  };
}
