import { useToastContainer } from './useToastContainer';
import { productStoreApi, useProductStore } from '../store/productStore';
import { useCartContainer } from './useCartContainer';
import { SellingStatus, CouponType, T_Occurrence } from '../types';
import { useCouponCode } from './useCouponCode';
import { KonakartService } from '../services/konakart';
import { categoryIds, messages, productSkuIds, SPLITIO_KEY } from '../config';
import { useCouponStore } from '../store/couponStore';
import { useAddUpdateBox } from './boxes/useAddUpdateBox';
import { useSplitIO } from './useSplitIO';
import { useboxesStore } from '../store/boxesStore';

function useQueryAddToCart(konakartService: KonakartService, couponType: CouponType) {
  const [arrowEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX);
  const { addToast } = useToastContainer();
  const { applyCouponCodeToCart } = useCouponCode(konakartService);
  const { addPrimaryBoxFromSKU } = useAddUpdateBox();
  const { coupons } = useCouponStore(s => ({
    coupons: s.coupons,
  }));
  const { products, changeProductQuantity } = useProductStore(s => ({
    products: s.products,
    changeProductQuantity: s.changeProductQuantity,
  }));
  const { toggleProduct, addSkyBox, digitalRentalToggleProduct } = useCartContainer(konakartService, couponType);
  const clearBoxStore = useboxesStore(s => s.clearBoxStore);

  /* Check URL for couponCode */
  const queryLoadCoupons = async (couponCode: string) => {
    // If there's no products returned yet in KK do nothing, scenario's impossible but
    // adding here just in case KK is quite slow returning the products
    if (products && products.length > 0) {
      // if there is a couponCode and the cart doesn't have any coupon applied to it yet.
      if (couponCode) {
        try {
          // Actually apply the coupon.
          await applyCouponCodeToCart(couponCode, couponType);
        } catch (err) {
          addToast({
            title: 'Alert',
            message: messages.couponError,
            type: 'error',
          });
        }
      }
    }
  };

  const queryLoadCouponsBroadcasTier = async (couponCode: string, boxes: T_Occurrence[]) => {
    // If there's no products returned yet in KK do nothing, scenario's impossible but
    // adding here just in case KK is quite slow returning the products
    if (products?.length < 1) return;
    // if there's no existing coupon, apply couponCode
    if (couponCode && coupons.length === 0) {
      try {
        // base requirement is to add starter so add it first if it's not yet in cart
        const starter = productStoreApi
          .getState()
          .products.find(
            p => p.sku === productSkuIds.starter.primary && p.custom3 === productSkuIds.starter.customAttribute
          );
        if (starter && starter.quantityInCart === 0) {
          await digitalRentalToggleProduct(starter!, boxes ?? []);
        }
        await applyCouponCodeToCart(couponCode, couponType);
      } catch (err) {
        addToast({
          title: 'Alert',
          message: messages.couponError,
          type: 'error',
        });
      }
    }
  };

  /**
   * Check URL for skus.
   * @param arrayOfSkus
   * @param hasCampaign
   */
  const queryLoadSkus = async (arrayOfSkus: string[], broadbandJourney?: string) => {
    if (products && products.length > 0) {
      // check if there are legacy items from skus - if there are remove those
      arrayOfSkus = removeIfLegacy(arrayOfSkus);
      // If arrow is not yet enabled, remove arrow box skus
      if (!arrowEnabled) {
        arrayOfSkus = arrayOfSkus.filter(
          sku =>
            !([
              productSkuIds.arrowBox.primary,
              productSkuIds.arrowUpfrontBoxFee.primary,
              productSkuIds.arrowBoxOneOff.primary,
              productSkuIds.arrowBoxBlack.primary,
              productSkuIds.arrowMonthly.primary,
              productSkuIds.arrowBoxRecording.primary,
            ] as string[]).includes(sku)
        );
      }

      // check the length of skus again after removing legacy items
      // if we have items that means need to manually add starter to trigger possible promos
      if (arrayOfSkus.length === 0) return;

      await checkAndAddStarter();

      const products = productStoreApi.getState().products;
      const broabandProductSKUs = products?.filter(p => p.categoryId === categoryIds.broadband).map(p => p.sku);
      const boxProductSKUs = products?.filter(p => p.categoryId === categoryIds.box).map(p => p.sku);

      // If broadband is being added, remove exsting broadband and accessories in cart
      if (arrayOfSkus.some(s => broabandProductSKUs.includes(s))) {
        const bbProductsInCart = productStoreApi
          .getState()
          .products?.filter(p => p.categoryId === categoryIds.broadband && p.quantityInCart > 0);

        for (let i = 0; i < bbProductsInCart.length; i++) {
          await toggleProduct(bbProductsInCart[i], false, true);
        }
      }

      // If a box is being added, remove existing boxes and box related products in cart
      // and clear box store
      if (arrayOfSkus.some(s => boxProductSKUs.includes(s))) {
        const boxOneOffsSKUs: string[] = [
          productSkuIds.arrowBoxOneOff.primary,
          productSkuIds.skyPodOneOffFee.primary,
          productSkuIds.arrowUpfrontBoxFee.primary,
          productSkuIds.ArrowSelfInstall.primary,
          productSkuIds.ArrowTechVisit.primary,
          productSkuIds.noRecording.primary,
          productSkuIds.arrowBoxRecording.primary,
        ];

        clearBoxStore();

        const boxRelatedProductsInCart = productStoreApi
          .getState()
          .products?.filter(
            p =>
              (p.categoryId === categoryIds.box ||
                p.categoryId === categoryIds.monthlyFee ||
                boxOneOffsSKUs.includes(p.sku)) &&
              p.quantityInCart > 0
          );

        for (let i = 0; i < boxRelatedProductsInCart.length; i++) {
          await toggleProduct(boxRelatedProductsInCart[i], false, true);
        }
      }

      // Add box skus to box store
      const boxProducts = addPrimaryBoxFromSKU(arrayOfSkus);
      const toUpdate = [
        ...arrayOfSkus
          .map(sku => productStoreApi.getState().products?.find(p => p.sku === sku))
          .filter(p => p && !boxProducts.some(bp => bp.sku === p.sku)),
        ...boxProducts,
      ];

      for (let i = 0; i < toUpdate.length; i++) {
        const p = toUpdate[i];
        if (p && p.quantityBought === 0 && p.quantityInCart === 0) {
          await toggleProduct(p, i === toUpdate.length - 1, true, broadbandJourney);
        } else {
          continue;
        }
      }
    }
  };

  const queryLoadSkusBroadcastTier = async (arrayOfSkus: string[], boxes: T_Occurrence[]) => {
    if (products && products.length > 0) {
      // check if there are legacy items from skus - if there are remove those
      arrayOfSkus = removeIfLegacy(arrayOfSkus);
      if (arrayOfSkus.length === 0) {
        return;
      }
      // non box related products
      arrayOfSkus.forEach(async (sku, i) => {
        let productToAdd = products.find(p => p.sku === sku);
        if (productToAdd && productToAdd.quantityBought === 0 && productToAdd.quantityInCart === 0) {
          await digitalRentalToggleProduct(productToAdd, boxes, i === arrayOfSkus.length - 1);
        }
      });
    }
  };

  const removeIfLegacy = (arrayOfSkus: string[]) => {
    arrayOfSkus = arrayOfSkus.filter(sku => {
      let productToAdd = products.find(p => p.sku === sku);
      return (
        productToAdd && productToAdd.custom5 !== SellingStatus.Legacy && productToAdd.custom5 !== SellingStatus.Upfront
      );
    });
    return arrayOfSkus;
  };
  /**
   * Add Starter if not part of skus or if not added before adding sku products to the cart
   * @param arrayOfSkus
   */
  const checkAndAddStarter = async () => {
    // base requirement is to add starter so if starter not part of skus passed in try to add
    let { products } = productStoreApi.getState();
    const starter = products.find(
      p => p.sku === productSkuIds.starter.primary && p.custom3 === productSkuIds.starter.customAttribute
    );
    // check if starter is not already added
    if (starter && starter.quantityInCart === 0 && starter.quantityBought === 0) {
      // if not added - add by default
      await toggleProduct(starter);
    }
  };

  /**
   * Add Sky Box if not part of skus or if not added before adding sku products to the cart
   * @param arrayOfSkus
   */
  const checkAndAddSkyBox = async (arrayOfSkus: string[]) => {
    let { products } = productStoreApi.getState();
    const boxes = products.filter(p => p.categoryId === categoryIds.box);
    const currentBox = boxes.find(b => b.quantityInCart > 0);
    const boxSkuInQuery =
      arrayOfSkus.includes(productSkuIds.skyBox.primary) ||
      arrayOfSkus.includes(productSkuIds.mySky.primary) ||
      arrayOfSkus.includes(productSkuIds.mySkyPlus.primary);
    if (!boxSkuInQuery) {
      // check if no box is not already added
      if (!currentBox) {
        // if no box not added already - add Sky Box by default
        await addSkyBox();
      }
    } else {
      // If there's already a box as part of the skus:
      if (currentBox) {
        // if there's a box already in the cart - remove it to give place to the Box in the query
        changeProductQuantity(currentBox.id, 0);
      }
    }
  };

  return { queryLoadSkus, queryLoadCoupons, queryLoadSkusBroadcastTier, queryLoadCouponsBroadcasTier };
}

export { useQueryAddToCart };
