import { StateCreatorWithNamedSet, SetStateWithName, GetState, StoreApiWithDevTools, PartialState } from 'zustand';

/**
 * A middleware for Zustand that sends information to the Redux dev tools.
 * To use this in an `@sky` package, you need to copy the zustand.d.ts file to the root of the package.
 *
 * @example
 * interface StoreShape {
 *     stateItems: string[];
 *     pushItem: (item: string) => void;
 *     popItem: () => string;
 * }
 *
 *const [useItemStore, itemStoreApi] = create<StoreShape>(
 *    // Appply the middleware
 *    ReduxDevTools((set, get) => ({
 *        stateItems: [],
 *        pushItem: (item: string) => {
 *            const currentStateItems = get().stateItems;
 *            // provide a name for the set action that the Redux devtools can pickup
 *            set({ stateItems: [item, ...currentStateItems]}, 'pushItem');
 *        },
 *        popItem: () => {
 *            const [firstItem, ...rest] = get().stateItems;
 *            // optionally dont provide a name which will log the action with a warning.
 *            set({ stateItems: rest });
 *            return firstItem;
 *        }
 *    }),
 *    // provide a store name
 *    'Item Store');
 *);
 *
 * @param createState The function normally provided to the create method
 * @param name The name of store to show in the devtools
 */
const ReduxDevTools = <TState>(createState: StateCreatorWithNamedSet<TState>, name: string) => (
  set: SetStateWithName<TState>,
  get: GetState<TState>,
  api: StoreApiWithDevTools<TState>
): TState => {
  let extension: any | undefined;
  try {
    extension = (window as any).__REDUX_DEVTOOLS_EXTENSION__ || (window as any).top.__REDUX_DEVTOOLS_EXTENSION__;
  } catch {}
  if (!extension) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Please install/enable Redux devtools extension');
    }
    api.devtools = null;
    return createState(set, get, api);
  }
  const namedSet = (state: PartialState<TState>, actionName?: string) => {
    set(state);
    if (name) {
      api.devtools!.send(api.devtools!.prefix + (actionName ?? `set[name not provided]`), get());
    }
  };
  const initialState = createState(namedSet, get, api);
  if (!api.devtools) {
    api.devtools = extension.connect({ name });
    api.devtools!.prefix = name ? `${name} > ` : '';
    api.devtools!.init(initialState);
    api.devtools!.subscribe(message => {
      // if this is a state dispatch from devtools.send
      if (message.type === 'DISPATCH' && message.state) {
        // ignoreState if its from the dev tools rewinding.
        const ignoreState = message.payload.type === 'JUMP_TO_ACTION' || message.payload.type === 'JUMP_TO_STATE';
        namedSet(
          JSON.parse(message.state),
          // for if the redux middleware is used
          // !initialState.dispatch &&
          !ignoreState ? 'setState' : undefined
        );
      }
    });
  }
  return initialState;
};

export { ReduxDevTools };
