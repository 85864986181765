import { useState } from 'react';
import _uniqby from 'lodash.uniqby';
import {
  broadbandCategories,
  categoryIds,
  voiceCategories,
  categoryIdsExcludedFromOneMonthOnUs,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ,
  couponCodes,
  productSkuIds,
  CreditCampaignCodes,
  NSB_ROI_COUPON,
  SKY_REWARD_TEMPLATE_TYPE,
  NSB_NON_REWARD_COUPON,
} from '../config';

import { useFreeProductStore } from '../store/freeProductStore';
import { CouponType, NullableDate, T_Coupon, T_OrderProduct } from '../types';
import {
  EffectiveMonthlyDateAfterOfferPricing,
  formatEffectiveMonthlyDateAfterOfferPricing,
  formatMoney,
  formatDate,
} from './format';
import { parseISO } from 'date-fns';

function useCoupon() {
  const [couponCodeValue, setCouponCodeValue] = useState('');
  const [couponType, setCouponType] = useState<CouponType>();
  const [hasError, setHasError] = useState(false);
  const { freeProduct } = useFreeProductStore();

  const onApplyClicked = async (
    onApplyCoupon: (couponCode: string, type?: CouponType) => Promise<boolean>
  ): Promise<void> => {
    const success = await onApplyCoupon(couponCodeValue, couponType);

    // if applying the code was successful, clear the field
    if (success) {
      setCouponCodeValue('');
      setHasError(false);
    } else {
      // Otherwise, put the focus back on the input field.
      setHasError(true);
    }
  };

  const getOfferTextForNSBOffers = (coupon: T_Coupon) => {
    return coupon.description;
  };

  /**
   * Generates offer ends message for use in summary footer and review pages.
   *
   * @param coupon Coupon
   * @param addedProducts Products in the cart related to the Coupon
   * @param installationDate Installation Date
   * @returns
   */
  const getOfferEndsText = (
    coupon: T_Coupon,
    addedProducts: T_OrderProduct[],
    installationDate: NullableDate,
    showOfferDate: boolean = true,
    existingOffer: boolean = true,
    campaignEndDate?: string
  ) => {
    if (
      coupon?.couponCode === couponCodes.offer50WOShohoHindi ||
      coupon?.couponCode === couponCodes.offer50WShohoHindi
    ) {
      addedProducts = addedProducts.filter(p => p.product.sku !== productSkuIds.arrowBoxRecording.primary);
    }
    if (
      coupon?.couponCode === NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON ||
      coupon?.couponCode === NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ
    ) {
      return `Standard charges for your plan/services will apply after your first month on us period ends.
        <a
          href="https://www.sky.co.nz/offer-terms"
          target="_blank"
          rel="noreferrer noopener"
          class="underline md:whitespace-no-wrap">
          Offer T&Cs apply
        </a>.
        see <a
        href="https://www.sky.co.nz/offer-terms"
        target="_blank"
        rel="noreferrer noopener"
        class="underline md:whitespace-no-wrap">
        https://www.sky.co.nz/offer-terms
      </a>`;
    }
    if (
      [
        CreditCampaignCodes.BROADBAND.CREDIT_BB_DTH,
        CreditCampaignCodes.BROADBAND.CREDIT_BB_ONLY,
        CreditCampaignCodes.BB_SPEED_UPGRADE.BB_FP_SPEED_UPGRADE,
        CreditCampaignCodes.BB_SPEED_UPGRADE.BB_FP_BOOST_SPEED_UPGRADE,
        couponCodes.offer50WOShohoHindi,
        couponCodes.bundleCoupon_dth,
        couponCodes.bundlecoupon_BB,
      ].includes(coupon.couponCode)
    ) {
      return coupon.description;
    }

    if (CreditCampaignCodes.NEW_SKY_BOX.UPFRONT_ACQUISITION === coupon.couponCode) {
      return getOfferTextForNSBOffers(coupon);
    }

    const couponProducts = coupon.custom1.split(',').map(Number);
    // Coupons configured w/o product id (custom1) in KK are considered dynamic
    const isDynamicCoupon = couponProducts.length === 1 && couponProducts[0] === 0;
    const hindiProducts = addedProducts.filter(p => p.categoryId === categoryIds.hindiChannels);

    let offerProducts = !isDynamicCoupon
      ? addedProducts.filter(p => couponProducts?.includes(p.productId))
      : addedProducts.filter(
          p =>
            // add all added products to offer text except for below conditions
            // If one Hindi Product show it else it is consider as a Hindi Bundle and take care seperately
            (hindiProducts.length === 1 || p.categoryId !== categoryIds.hindiChannels) &&
            isProductIncludedInDynamicCoupon(p)
        );

    // If there's only one hindi product and , add it to the main offer text
    // else create a separate hindi promotions offer
    if (isDynamicCoupon && hindiProducts.length === 1) {
      offerProducts.push(...hindiProducts);
    }

    if (coupon.couponCode === couponCodes.oneMonthOnUsWithSport) {
      showOfferDate = false;
    }
    let starterTxt = '';
    if (coupon.couponCode === couponCodes.offer50WOShohoHindi) {
      starterTxt = 'Sky Starter on a 12 month contract.';
    }
    let offerEndsText =
      campaignEndDate && showOfferDate
        ? `After offer expires on  ${formatDate(parseISO(campaignEndDate))}, `
        : showOfferDate
        ? `${starterTxt}After offer expires on  ${formatEffectiveMonthlyDateAfterOfferPricing(
            coupon?.custom4 ?? '',
            installationDate
          )}, `
        : 'After offer expires, ';

    const pricingText = (p: T_OrderProduct) =>
      `${p.product.name} is ${formatMoney(p.product.priceIncTax * p.quantity)}/mth`;

    //dedup
    offerProducts = _uniqby(offerProducts, op => `${op.productId} ${op.product.priceIncTax}`);

    offerProducts.forEach((op, index) => {
      if (op.quantity === 0) return;
      if (
        coupon.couponCode === couponCodes.oneMonthOnUsWithSport &&
        (op.product.sku === productSkuIds.entertainment.primary || op.product.sku === productSkuIds.soho.primary)
      ) {
        return;
      }
      if (op.product.sku)
        if (offerProducts.length === 1) {
          offerEndsText += pricingText(op);
        } else if (offerProducts.length === 2) {
          if (index === offerProducts.length - 1) {
            offerEndsText += `${' and'} ${pricingText(op)}`;
          } else {
            offerEndsText += pricingText(op);
          }
        } else {
          if (index === offerProducts.length - 1 && existingOffer) {
            offerEndsText += `${' and'} ${pricingText(op)}`;
          } else if (index > 0 && index <= offerProducts.length - 2 && existingOffer) {
            offerEndsText += `,  ${pricingText(op)}`;
          } else if (index === 0) {
            offerEndsText += `${pricingText(op)}`;
          }
        }
    });

    // Hard coded Hindi Bundle
    // if (hindiProducts.length > 1 && !isBundleOfferAdded) {
    //   const len = hindiProducts.length;
      // offerEndsText += ` and Hindi bundle is ${len === 3 ? '$19.99' : '$14.99'}`;
    // }

    offerEndsText += '.';
    if (coupon.couponCode === couponCodes.oneMonthOnUsWithSport) {
      offerEndsText += ' Sky Entertainment & SoHo will be removed from your subscription.';
    }

    return offerEndsText;
  };

  const getOldestOfferEndDate = (
    coupons: T_Coupon[],
    addedProducts: T_OrderProduct[],
    installationDate: NullableDate
  ) => {
    let oldestofferEndDate: Date | null = null;

    coupons.forEach((coupon, index) => {
      const date = EffectiveMonthlyDateAfterOfferPricing(coupon?.custom4 ?? '', installationDate);

      if (date != null) {
        if (oldestofferEndDate == null) {
          oldestofferEndDate = date;
        } else if (date > oldestofferEndDate) {
          oldestofferEndDate = date;
        }
      }
    });

    return oldestofferEndDate;
  };

  const hasDynamicCoupon = (coupons?: T_Coupon[]) => {
    let isDynamicCoupon = false;
    if (!coupons) return isDynamicCoupon;
    for (const coupon of coupons) {
      const couponProducts = coupon.custom1.split(',').map(Number);
      // Coupons configured w/o product id (custom1) in KK are considered dynamic
      if (couponProducts.length === 1 && couponProducts[0] === 0) {
        isDynamicCoupon = true;
        break;
      }
    }
    return isDynamicCoupon;
  };

  const isProductIncludedInDynamicCoupon = (p: T_OrderProduct) => {
    // if dynamic coupon, show all added products except for categories and products below
    return (
      p.productId !== freeProduct?.productId &&
      p.product.price0 !== 0 &&
      !categoryIdsExcludedFromOneMonthOnUs.includes(p.categoryId) &&
      !broadbandCategories.includes(p.categoryId) &&
      !voiceCategories.includes(p.categoryId)
    );
  };

  const getCouponRelatedProducts = (coupon: T_Coupon, addedProducts: T_OrderProduct[]) => {
    const idsToAdd = coupon?.custom1.split(',').map(x => Number(x));
    const relatedProducts = hasDynamicCoupon([coupon])
      ? addedProducts.filter(p => isProductIncludedInDynamicCoupon(p))
      : addedProducts.filter(p => idsToAdd?.includes(p.productId));
    return relatedProducts;
  };

  const couponRewardTemplateType = (couponCode: string | undefined) => {
    if (!couponCode) return null;
    if (couponCode === NSB_ROI_COUPON) return SKY_REWARD_TEMPLATE_TYPE.ROI;
    else if (couponCode === NSB_NON_REWARD_COUPON) return SKY_REWARD_TEMPLATE_TYPE.NON_REWARD;
    else return SKY_REWARD_TEMPLATE_TYPE.REWARD;
  };

  return {
    couponCodeValue,
    setCouponCodeValue,
    hasError,
    setHasError,
    onApplyClicked,
    couponType,
    setCouponType,
    getOfferEndsText,
    getCouponRelatedProducts,
    hasDynamicCoupon,
    isProductIncludedInDynamicCoupon,
    getOldestOfferEndDate,
    couponRewardTemplateType,
  };
}

export { useCoupon };
