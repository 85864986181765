import useSWR, { mutate } from 'swr';
import { WifiService, T_WifiSettings } from '../services/wifi';

export class WifiSWR {
  constructor(private wifiService: WifiService) {}
  useWifiSettings = (providerAccountId?: string) => {
    return useSWR(providerAccountId ? `/wifiSettings/${providerAccountId}` : null, () =>
      this.wifiService.getWifiSetting(providerAccountId)
    );
  };
  updateWifiSettings = async (providerAccountId: string, body: T_WifiSettings) => {
    let data = await this.wifiService.setWifiSetting(providerAccountId, body);
    mutate(`/wifiSettings/${providerAccountId}`, data, false);
  };

  useDiagnosticsTest = (providerAccountId?: string) => {
    return useSWR(providerAccountId ? `diagnostics/test/${providerAccountId}` : null, async () =>
      this.wifiService.getDiagnosticsTest(providerAccountId!)
    );
  };
}
