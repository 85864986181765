import cx from 'classnames';
import React from 'react';

interface Props {
  value: boolean;
  disable?: boolean;
  dataTestId?: string;
  onChange: (a: boolean) => void;
}
let Switch: React.FC<Props> = ({ value, disable, dataTestId, onChange }) => {
  let btnClass = cx('transition-all duration-300 rounded-full flex items-center p-1 w-16', {
    'justify-end bg-success': value,
    'justify-start bg-gray-dark': !value,
    'cursor-not-allowed': disable,
    'cursor-pointer': !disable,
  });
  return (
    <button
      type="button"
      disabled={disable}
      className={btnClass}
      onClick={() => onChange(!value)}
      data-testid={'switch-button-' + dataTestId}>
      <div className="rounded-full bg-white w-6 h-6"></div>
    </button>
  );
};

export { Switch };
