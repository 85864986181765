import { Field, useField } from 'react-jeff';
import { DirectoryListingCodes, DirectoryListingTypes, YesNoOptionCheckCodes, YesNoOptionValues } from '../types';
import {
  validateLandlineAreaCode,
  validateLandlineNumber,
  validatePhoneNumberFromList,
  validateProviderAccountNumber,
  validateYesNoOption,
} from './validations';

export interface HomePhoneFormFieldProps {
  currentProviderInputField: Field<string, string>;
  directoryListingDropDownField: Field<DirectoryListingTypes, string>;
  existingHomePhoneDropDownField: Field<YesNoOptionValues, string>;
  keepSamePhoneNumberDropDownField: Field<YesNoOptionValues, string>;
  phoneNumberAreaCodeInputField: Field<string, string>;
  phoneNumberLineNumberInputField: Field<string, string>;
  phoneProviderMatchBroadbandDropDownField: Field<YesNoOptionValues, string>;
  currentProviderDropdownField: Field<string, string>;
  phoneNumberSelectList: Field<string, string>;
  accountNumberField: Field<string, string>;
}

export interface VoiceDataForm {
  isUsingExistingPhone: YesNoOptionValues;
  phoneNumberAreaCode: string;
  phoneNumberLineNumber: string;
  isPhoneProviderMatchBroadband: YesNoOptionValues;
  currentProvider: string;
  currentProviderDropDown: string;
  isKeepExistingPhoneNumber: YesNoOptionValues;
  directoryListing: DirectoryListingTypes;
  phoneNumberFromList: string;
  accountNumber: string;
}

export const initialVoiceDataFormState: VoiceDataForm = {
  isUsingExistingPhone: YesNoOptionCheckCodes.YES.value,
  phoneNumberAreaCode: '',
  phoneNumberLineNumber: '',
  isPhoneProviderMatchBroadband: YesNoOptionCheckCodes.SELECT_OPTION_BELOW.value,
  currentProvider: '',
  currentProviderDropDown: '',
  isKeepExistingPhoneNumber: YesNoOptionCheckCodes.SELECT_OPTION_BELOW.value,
  directoryListing: DirectoryListingCodes.NO_CHANGE_TO_EXISTING_DIRECTORY_LISTING,
  phoneNumberFromList: '',
  accountNumber: '',
};

export function useExistingHomePhoneDropDownField(value: YesNoOptionValues) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.isUsingExistingPhone,
    required: true,
    validations: [validateYesNoOption],
  });
}

export function usePhoneNumberAreaCodeInputField(value: string) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.phoneNumberAreaCode,
    required: true,
    validations: [validateLandlineAreaCode],
  });
}

export function usePhoneNumberLineNumberInputField(value: string) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.phoneNumberLineNumber,
    required: true,
    validations: [validateLandlineNumber],
  });
}

export function usePhoneProviderMatchBroadbandDropDownField(value: YesNoOptionValues) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.isPhoneProviderMatchBroadband,
    required: true,
    validations: [validateYesNoOption],
  });
}

export function useCurrentProviderDropdownField(value: string) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.currentProviderDropDown,
    required: true,
    validations: [],
  });
}

export function useCurrentProviderInputField(value: string) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.currentProvider,
    required: true,
    validations: [],
  });
}

export function useKeepSamePhoneNumberDropDownField(value: YesNoOptionValues) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.isKeepExistingPhoneNumber,
    required: true,
    validations: [validateYesNoOption],
  });
}

export function useDirectoryListingDropDownField(value: DirectoryListingTypes) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.directoryListing,
    required: true,
    validations: [],
  });
}

export function usePhoneNumberSelectList(value: string) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.phoneNumberFromList,
    required: true,
    validations: [validatePhoneNumberFromList],
  });
}
export function useAccountNumberField(value: string) {
  return useField({
    defaultValue: value ?? initialVoiceDataFormState.accountNumber,
    required: true,
    validations: [validateProviderAccountNumber],
  });
}
