import React ,{useState,useEffect} from 'react';
import { format, isValid } from 'date-fns';
import {
  AccountDetails,
  IBroadbandDelivery,
  IBroadbandInstallation,
  Ownership,
  OwnershipFields,
  CheckoutStates,
  GetTechBookingSlotRangeByCode,
  useProductInCart,
} from '@sky-tv-group/shared';
import { SectionHeader } from '../../header/SectionHeader';
import { arrow, SquareEditIcon } from '../../icon';
import { IArrowDelivery } from '@sky-tv-group/shared/dist/store/arrowDeliveryStore';
import { FormWrapper } from '../../FormWrapper';

interface SectionDetailsProps {
  header: string;
  installationAddress?: string;
  deliveryAddress?: string;
  installationDate: Date | null | undefined;
  installationTime?: string;
  rentOrOwn?: Ownership;
  onEdit: () => void;
  showSectionHeader?: boolean;
}

const SectionDetails = ({
  header,
  installationAddress,
  deliveryAddress,
  installationDate,
  installationTime,
  rentOrOwn,
  onEdit,
  showSectionHeader = true,
}: SectionDetailsProps) => {

   const [slotUnavailability,SetSlotUnavailability] = useState(false)

  useEffect(()=>{
    if(installationDate && isValid(installationDate)){
      const noSlotAvailabilityDate = format(new Date(installationDate), 'd MMM YYY')
      if(noSlotAvailabilityDate === '31 Dec 10000' || noSlotAvailabilityDate === '31 Dec 9999' ){
         SetSlotUnavailability(true)
        }
     }
  },[])

  return (
    <FormWrapper>
      <div className="flex w-full md:mr-4 last:mr-0">
        <div className="w-full flex-1 mx-4 md:mx-0">
          <div className="flex-col">
            <div className="flex w-full items-center mb-8">
              <h4 className="flex-1 sky-h6-black md:sky-h7-black">{header}</h4>
              <p className=" text-blue-pure cursor-pointer flex flex-row ml-10" onClick={onEdit}>
                Edit
                <span className="ml-2">
                  <SquareEditIcon />
                </span>
              </p>
            </div>
            {installationAddress && (
              <div className="w-full mb-4 text-midnight">
                <p className="text-12px md:text-15px font-bold mb-1">To be installed at</p>
                <p className="text-12px md:text-15px">{installationAddress ?? ''}</p>
              </div>
            )}
            {deliveryAddress && (
              <div className="w-full mb-4 text-midnight">
                <p className="text-12px md:text-15px font-bold mb-1">Box Delivery address</p>
                <p className="text-12px md:text-15px">{deliveryAddress}</p>
              </div>
            )}
            {installationDate && isValid(installationDate) && (
              <div className="w-full mb-4 text-midnight">
                <p className="text-12px md:text-15px font-bold mb-1">{`Preferred ${
                  installationTime ? 'installation' : 'activation'
                } date`}</p>
                <p className="text-12px md:text-15px">{`${slotUnavailability ? 'One of our friendly crew will be in touch to schedule an installation for your new Sky device. ' : format(new Date(installationDate), 'd MMM YYY')}`}</p>
                </div>
            )}
            {installationTime && !slotUnavailability && (
              <div className="w-full mb-4 text-midnight">
                <p className="text-12px md:text-15px font-bold mb-1">Between</p>
                <p className="text-12px md:text-15px">{GetTechBookingSlotRangeByCode(installationTime)}</p>
              </div>
            )}
            {rentOrOwn && !slotUnavailability && (
              <div className="w-full mb-4 text-midnight">
                <p className="text-12px md:text-15px font-bold mb-1">Home ownership</p>
                <p className="text-12px md:text-15px">
                  {rentOrOwn === OwnershipFields.Own ? 'You own this property' : 'You rent this property'}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

interface InstallationDetailsReviewProps {
  edit: (state: string, isDart?: boolean) => void;
  accountDetails: AccountDetails;
  hasPackage?: boolean;
  hasBroadband?: boolean;
  broadbandDetails?: IBroadbandInstallation;
  broadbandDeliveryDetails?: IBroadbandDelivery;
  arrowDeliveryDetails?: IArrowDelivery;
  isNewFiber?: boolean;
  showSectionHeader?: boolean;
  showMainHeader?: boolean;
  showArrowDeliveryDetails?: boolean;
  showBroadbandDeliveryDetails?: boolean;
  isDartCheckout?: boolean;
  showBroadbandInstallationDetails?: boolean;
  isSkyPODUpgrade?: boolean;
}

const InstallationDetailsReview = ({
  hasPackage = false,
  hasBroadband = false,
  edit,
  accountDetails,
  broadbandDetails,
  broadbandDeliveryDetails,
  arrowDeliveryDetails,
  isNewFiber,
  showSectionHeader = true,
  showMainHeader = true,
  showArrowDeliveryDetails,
  showBroadbandDeliveryDetails,
  isDartCheckout,
  showBroadbandInstallationDetails,
  isSkyPODUpgrade,
}: InstallationDetailsReviewProps) => {
  const { requireDeliveryOnly } = useProductInCart();

  return (
    <>
      {showMainHeader && <SectionHeader titleText="Installation Details" />}
      <div className="flex flex-col gap-5 w-full mb-10">
        <div>
          {(hasPackage || isSkyPODUpgrade) && (
            <SectionDetails
              header={'Sky Installation Details'}
              installationAddress={accountDetails.propertyDetail.address?.label}
              installationDate={accountDetails.installationDetail.installationDate}
              installationTime={accountDetails.installationDetail.installationTime}
              rentOrOwn={accountDetails.installationDetail.rentOrOwn}
              onEdit={() => edit(CheckoutStates.TV, isDartCheckout)}
              showSectionHeader={showSectionHeader}
            />
          )}
        </div>
        {showBroadbandInstallationDetails && (
          <SectionDetails
            header={'Broadband Installation Details'}
            installationAddress={broadbandDeliveryDetails?.installationAddress}
            installationDate={isNewFiber ? broadbandDetails?.installationDate : broadbandDetails?.activationDate}
            installationTime={isNewFiber ? broadbandDetails?.installationTime : undefined}
            rentOrOwn={accountDetails.installationDetail.rentOrOwn}
            onEdit={() => edit(CheckoutStates.BROADBAND)}
            showSectionHeader={showSectionHeader}
          />
        )}
        {arrowDeliveryDetails && showArrowDeliveryDetails && !isSkyPODUpgrade && (
          <SectionDetails
            header={'Delivery Details'}
            deliveryAddress={
              arrowDeliveryDetails?.useDifferentDeliveryAddress
                ? arrowDeliveryDetails?.deliveryAddress?.label
                : arrowDeliveryDetails?.installationAddress
            }
            installationDate={null}
            onEdit={() => edit(CheckoutStates.DELIVERY_DETAILS, isDartCheckout)}
            showSectionHeader={showSectionHeader}
          />
        )}
        {(requireDeliveryOnly || !isSkyPODUpgrade) &&
          !showArrowDeliveryDetails &&
          (accountDetails.propertyDetail.deliveryAddress ||
            arrowDeliveryDetails?.deliveryAddress?.label ||
            arrowDeliveryDetails?.defaultDeliveryAddress) && (
            <SectionDetails
              header={'Delivery Details'}
              deliveryAddress={
                accountDetails.propertyDetail.deliveryAddress?.label ||
                arrowDeliveryDetails?.deliveryAddress?.label ||
                arrowDeliveryDetails?.defaultDeliveryAddress.join(' ')
              }
              installationDate={null}
              onEdit={() => edit(CheckoutStates.DELIVERY_DETAILS, isDartCheckout)}
              showSectionHeader={showSectionHeader}
            />
          )}
      </div>
    </>
  );
};

export { InstallationDetailsReview };
