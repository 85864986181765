import React from 'react';
import cx from 'classnames';
import { MarketingRectangle } from './arrow-marketing/MarketingRectangle';
import { PromoCard } from '@sky-tv-group/graphql';
import { formatMoneyAutoDecimal } from '@sky-tv-group/shared';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export interface OfferBannerProps {
  promos: PromoCard;
  className?: string;
}

function wrapDecimalInSpan(num: number): string {
  const [whole, decimal] = num.toFixed(2).split('.');
  return `${whole}<span class="text-lg" style="line-height: 0; margin-top: 19px;">.${decimal}</span>`;
}

export const OfferBanner: React.FC<OfferBannerProps> = ({ promos, className }) => {
  const styles = cx(className, [
    /* add default classes here */
    'pt-8 pb-4',
  ]);
  return (
    <div className={styles}>
      {promos?.lozengeText ? (
        <div className="relative">
          <MarketingRectangle
            containerClasses=""
            mRectTag={promos?.lozengeText}
            mRectColor="#9100D4"
            mRectTagClasses="text-white text-center sky-h4-bold py-sky-xs pl-3 pr-4"
          />
        </div>
      ) : null}
      <div
        className="flex flex-col md:flex-row p-5 items-center justify-start gap-4 md:gap-10 rounded-lg text-white"
        style={{ background: promos?.bgColor ?? '' }}>
        <img
          className="object-contain object-center h-auto w-80"
          src={promos?.imageSrc?.url!}
          alt={promos?.imageSrc?.text!}
        />
        <div className="flex flex-col items-start justify-start">
          <h1 className="sky-heading-7 ">{promos?.title}</h1>
          <p className="sky-text-xs font-normal">{promos?.subtitle}</p>
          <div className="text-white py-4">
            {promos?.fromPrice !== promos?.discountedPrice?.toString() &&
              (promos?.fromPrice ? (
                <p className="line-through " style={{ fontSize: '14px', lineHeight: '18px' }}>
                  {formatMoneyAutoDecimal(+promos?.fromPrice)}
                </p>
              ) : (
                <p className=" " style={{ fontSize: '14px', lineHeight: '18px' }}>
                  From
                </p>
              ))}
            {promos?.discountedPrice !== null ? (
              <div>
                <div className="ml-4 pr-2 mr-8 relative inline-block">
                  <span
                    className="font-skyBlack absolute "
                    style={{ fontSize: 20, lineHeight: '24px', right: '100%', top: 6 }}>
                    $
                  </span>
                  <span className="font-skyBlack" style={{ fontSize: 46, lineHeight: '56px' }}>
                    <span
                      className="flex flex-row items-start"
                      dangerouslySetInnerHTML={{
                        __html: wrapDecimalInSpan(+formatMoneyAutoDecimal(promos?.discountedPrice).substring(1)),
                      }}
                    />
                  </span>
                  {promos?.billingFrequency === 'Monthly' && (
                    <span
                      className=" absolute  "
                      style={{ fontSize: 12, lineHeight: '16px', left: 'calc(100% - 33px)', bottom: 6 }}>
                      /mth
                    </span>
                  )}
                  {promos?.billingFrequency === 'One Time Payment' && (
                    <span
                      className="absolute min-w-40"
                      style={{ fontSize: 12, lineHeight: '16px', left: 'calc(100% - 33px)', bottom: 6 }}>
                      /one-off charge
                    </span>
                  )}
                  {promos?.discountText ? (
                    <MarketingRectangle
                      left={0}
                      top={0}
                      containerClasses=""
                      containerStyle={{ left: '100%', top: 6 }}
                      mRectTag={promos?.discountText}
                      mRectColor="#9100D4"
                      mRectTagClasses="text-white text-center sky-h7-bold pl-2 py-1 pr-4"
                    />
                  ) : null}
                </div>
              </div>
            ) : null}

            {promos?.termsAndConditions ? (
              <p className="" style={{ fontSize: 10, lineHeight: '12px' }}>
                {documentToReactComponents(promos?.termsAndConditions)}
              </p>
            ) : null}
            {promos?.disclaimer ? (
              <p className="" style={{ fontSize: 6, lineHeight: '8px' }}>
                {promos?.disclaimer}
              </p>
            ) : null}
          </div>
          <a
            target="_blank"
            href={promos?.CTAButtonUrl!}
            className="px-4 mx-0 py-3 sky-button sky-button--secondary-dark min-w-full md:min-w-56">
            {promos?.CTAButtonLabel}
          </a>
        </div>
      </div>
    </div>
  );
};
