import { AxiosInstance } from 'axios';

import { T_ROI_CUSTOMER } from '../types';

export class RoiConnectService {
  private agent: AxiosInstance;
  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }

  public createRoi = async (payLoad: object | any) => {
    try {
      const { data } = await this.agent.post<T_ROI_CUSTOMER>('/create', payLoad);
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  public updateRoi = async (payLoad: object | any) => {
    try {
      const { data } = await this.agent.put<T_ROI_CUSTOMER>('/update', payLoad);
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  public getRoi = async (payLoad: object | any) => {
    try {
      const { data } = await this.agent.post<T_ROI_CUSTOMER>('/get', payLoad);
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}
