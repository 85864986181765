import { categoryIds } from '../../../config';
import { AddressPrequalification } from '../../../services/prequal';
import { IBroadbandInstallation } from '../../../store/broadbandStore';
import { T_Tracking, VoiceDetails, LosingServiceProviderDetails, Addons, T_Product } from '../../../types';
import { VoiceDataForm } from '../../voiceFormFields';
import { BBPlanIndex } from '../dataServices/dataServiceBuilder';
import { ProductData } from '../helper';

export const isVoiceAdded = (orderPackages: ProductData[]): boolean => {
  return orderPackages?.some(p => p.categoryId === categoryIds.voice);
};

export const buildDetailsForVoice = (
  voiceDetails: VoiceDataForm | undefined,
  prequal: AddressPrequalification | undefined,
  orderProducts: ProductData[],
  broadbandDetails: Pick<IBroadbandInstallation, 'currentServiceProvider' | 'otherServiceProvider'> | undefined,
  tracking: T_Tracking | undefined,
  currentlySubscribedBroadbandPackageSku: string | undefined,
  bbPlanIndex: BBPlanIndex
): VoiceDetails => {
  const isVoiceProductAdded = isVoiceAdded(orderProducts);

  return {
    productId: prequal?.getProductKey(bbPlanIndex, true) || '',
    broadbandProduct: prequal?.getProduct(bbPlanIndex, true) || '',
    newPhoneNumber: getNewPhoneNumber(voiceDetails),
    losingServiceProviderDetails: buildLosingServiceProviderDetails(voiceDetails, broadbandDetails),
    addons: getAddons(prequal, orderProducts),

    ...(currentlySubscribedBroadbandPackageSku && isVoiceProductAdded
      ? {
          voiceAction: 'add',
          voiceProduct: prequal?.getVoiceProductKey(bbPlanIndex) || '',
          installType: 'SelfInstall',
          availableProductKey:
            tracking?.bbDetails?.find(details => details.type === 'internet')?.coreProductKey ?? undefined,
          providerAccountId: tracking?.providerAccountId,
          dataProviderUserId: tracking?.dataProviderUserId,
          // when user has bb+voice, only  change voice products
          voiceProviderUserId: getvoiceProviderUserId(voiceDetails) ?? tracking?.voiceProviderUserId,
        }
      : {}),
  };
};

const buildLosingServiceProviderDetails = (
  voiceDetails: VoiceDataForm | undefined,
  broadbandDetails: Pick<IBroadbandInstallation, 'currentServiceProvider' | 'otherServiceProvider'> | undefined
): LosingServiceProviderDetails | undefined => {
  // We only care about losing service provider if its porting in existing number (want existing number)
  // That occurs only if we have existing number, and want to keep it
  if (voiceDetails && voiceDetails.isUsingExistingPhone === 'Yes' && voiceDetails.isKeepExistingPhoneNumber === 'Yes') {
    let serviceProvider = undefined;
    // if the providers are matching for BB and Voice
    if (voiceDetails.isPhoneProviderMatchBroadband === 'Yes' && broadbandDetails) {
      // Use the explicit input field if value is other, otherwise use the dropdown field value
      // const serviceProviderBroadband =
      //   broadbandDetails.currentServiceProvider !== 'Other'
      //     ? broadbandDetails.currentServiceProvider
      //     : broadbandDetails.otherServiceProvider;
      serviceProvider = broadbandDetails.currentServiceProvider;
    } else {
      // if the provider is different to the broadband provider, check if its not 'Not Shown' option.
      // Use the dropdown and if its the 'Other' option use that field as service provider
      const serviceProviderVoice =
        voiceDetails.currentProviderDropDown !== 'Other'
          ? voiceDetails.currentProviderDropDown
          : voiceDetails.currentProvider;
      serviceProvider = serviceProviderVoice;
    }

    // account number also incorporates the BB logic
    return {
      existingPhoneNumber: `${voiceDetails.phoneNumberAreaCode}${voiceDetails.phoneNumberLineNumber}`,
      accountNumber: voiceDetails.accountNumber,
      portIn: true,
      serviceProvider: serviceProvider,
    };
  }
  return undefined;
};

const getNewPhoneNumber = (voiceDetails: VoiceDataForm | undefined): string | undefined => {
  // unable to know correctly if the number selected means that they want a new number
  // if a number is selected from the phone number list and
  //  (using existing number and is going to not keep the same number OR
  //  does not have an existing number)
  return voiceDetails &&
    voiceDetails.phoneNumberFromList &&
    ((voiceDetails.isUsingExistingPhone === 'Yes' && voiceDetails.isKeepExistingPhoneNumber === 'No') ||
      voiceDetails.isUsingExistingPhone === 'No')
    ? voiceDetails.phoneNumberFromList
    : undefined;
};

const getvoiceProviderUserId = (voiceDetails: VoiceDataForm | undefined): string | undefined => {
  if (!voiceDetails) return undefined;
  if (
    voiceDetails.isUsingExistingPhone === 'No' ||
    (voiceDetails.isUsingExistingPhone === 'Yes' && voiceDetails.isKeepExistingPhoneNumber === 'No')
  ) {
    return voiceDetails?.phoneNumberFromList;
  } else return voiceDetails.phoneNumberAreaCode + voiceDetails.phoneNumberLineNumber;
};

export const getAddons = (prequal: AddressPrequalification | undefined, orderProducts: ProductData[]): Addons[] => {
  // forcefuly tell its returning strings without undefined
  const keyArray: string[] = orderProducts.map(op => op.model).filter((item): item is string => !!item);
  const addons = prequal?.getRespectiveAddons(keyArray);

  // explicit addons for voice are type plans but endpoint has no type field
  const mappedAddons =
    addons?.map(x => ({
      productId: x.key,
      ratePlan: x.planDetails?.length ? x.planDetails[0].key : '',
      type: 'plan',
    })) ?? [];
  return mappedAddons;
};
