import cx from 'classnames';
import React, { useRef } from 'react';

interface Props {
  value: string[];
  onChange: (val: string[]) => void;
  lengths?: number[];
  labels?: string[];
  error?: boolean;
}

const WIDTH = 2.5;

/**
 * A input component for pin code and bank account scenario
 *
 * @component
 * @example
 * const value = ['','','']
 * const type = 'input'
 * const lengths = [3,2,3]
 * const labels = ['l1','l2','l3']
 * const onChange = (value)=>{}
 * return (
 *   <DigiInput value={value} type={type} lengths={lengths} onChange={onChange} />
 * )
 */
let DigiInput = ({
  value,
  onChange,
  type = 'password',
  lengths = [1, 1, 1, 1],
  labels = [],
  className = 'text-center',
  error = false,
  ...rest
}: Props & Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'value'>) => {
  let digits = [...value];
  let ref = useRef<HTMLDivElement>(null);

  let handleInput = (i: number, val: string) => {
    digits[i] = val;
    if (val && val.length === lengths[i]) {
      let parent = ref.current;
      let last = i === digits.length - 1;
      if (parent && !last) {
        let input = parent.querySelectorAll('input')[i + 1] as HTMLInputElement;
        input?.focus();
      }
    }
    onChange(digits);
  };
  return (
    <div ref={ref} className="flex w-full">
      {digits.map((digi, i) => {
        return (
          <div
            key={i}
            className="min-w-0 mx-2"
            style={{
              flex: lengths[i],
            }}>
            {labels[i] && <label className="block font-bold">{labels[i]}</label>}
            <input
              type={type}
              className={cx('p-3 w-full  border ', className, {
                'border-error focus:border-error': error,
                'border-gray-border focus:border-blue-light': !error,
              })}
              value={digi}
              key={i}
              maxLength={lengths[i]}
              onChange={e => handleInput(i, e.target.value)}
              {...rest}
            />
          </div>
        );
      })}
    </div>
  );
};

export { DigiInput };
