import { ServiceStatus, T_Product } from '../types';

// Sets the product to state where it wasn in cart and was not bought
export const formatProduct = (product: T_Product) =>
  ({
    ...product,
    quantityBought: 0,
    quantityInCart: 0,
    downgrade: false,
    occurrenceNumber: undefined,
    priceIncTax: product.price0,
    inPromotion: false,
    serviceStatus: ServiceStatus.Active,
  } as T_Product);
