import React from 'react';
import cx from 'classnames';
import { Field, Form } from 'react-jeff';
import {
  combineAddressArray,
  toggleDelivery,
  AddressService,
  NullableAddressLookup,
  useArrowDeliveryStore,
} from '@sky-tv-group/shared';
import { ValidatedAddressSuggest } from '../../validation/addressValidated';
import { Switch } from '../../switch';

interface Props {
  form: Form<string>;
  hidden: boolean;
  productName: string;
  useDifferentDeliveryAddressField: Field<boolean, string>;
  deliveryAddressField: Field<NullableAddressLookup, string>;
  defaultDeliveryAddressField: Field<string[], string>;
  addressService: AddressService;
}

const BroadbandProductDelivery = ({
  form,
  hidden,
  productName,
  addressService,
  useDifferentDeliveryAddressField,
  deliveryAddressField,
  defaultDeliveryAddressField,
}: Props) => {
  const classes = cx('w-full', { hidden });
  const arrowDeliveryDetails = useArrowDeliveryStore(s => s.details);
  return (
    <div className={classes}>
      <div className="flex items-center pb-4 w-full md:w-1/2">
        <label className="sky-h7-reg mr-4 text-midnight">Deliver the {productName} to a different address</label>
        <div>
          <Switch
            value={useDifferentDeliveryAddressField.value}
            onChange={e => toggleDelivery(e, useDifferentDeliveryAddressField, deliveryAddressField)}
          />
        </div>
      </div>
      <div className="">
        <div className="pb-10 md:pb-16">
          <label className="block sky-h7-reg text-midnight mb-2">Delivery address</label>
          <ValidatedAddressSuggest
            addressField={deliveryAddressField}
            formValidation={form}
            id="broadband-delivery-address"
            className="w-full rounded"
            placeholder={combineAddressArray(defaultDeliveryAddressField.value)}
            addressType="Postal"
            disabled={!useDifferentDeliveryAddressField.value}
            addressService={addressService}
            excludeExpression="^PO BOX"
          />
        </div>
      </div>
    </div>
  );
};

export { BroadbandProductDelivery };
