import { useProductStore, productStoreApi } from '../store/productStore';
import { productSkuIds } from '../config';
import { T_Product } from '../types';
import { KonakartService } from '../services/konakart';
import { useCustomerStore } from '../store/customerStore';
import { useUpdateCart } from './useUpdateCart';
/**
 * Handle One Time Charge (OTC) for boxes. This OTC fee is needed to be able to Complete a Work Order
 * Konakart config: Add OTC fees for the boxes. (725 for Sky Box, 736 for MySky and MySky+)
 * @param konakartService
 */
function useHandleOneTimeCharge(konakartService: KonakartService) {
  const { changeProductQuantity } = useProductStore(s => ({
    changeProductQuantity: s.changeProductQuantity,
  }));
  const { customerId } = useCustomerStore(s => ({ customerId: s.kk }));
  const { updateOrder } = useUpdateCart(konakartService);

  const otcSkyBox = useProductStore(s => s.products.find(p => p.sku === productSkuIds.skyBoxOTC.primary));
  const otcMySky = useProductStore(s => s.products.find(p => p.sku === productSkuIds.mySkyOTC.primary));
  const otcMySkyPlus = useProductStore(s => s.products.find(p => p.sku === productSkuIds.mySkyPlusOTC.primary));

  /**
   * Adds box's OTC according to the selected box.
   * @param box
   * @param update
   */
  const handleBoxOTC = async (box: T_Product, update: boolean = true, trackEvent?: boolean) => {
    if (otcSkyBox && otcMySky && otcMySkyPlus) {
      // Remove any otc in the cart
      if (otcSkyBox.quantityInCart > 0) {
        changeProductQuantity(otcSkyBox.id, 0, trackEvent);
      } else if (otcMySky.quantityInCart > 0) {
        changeProductQuantity(otcMySky.id, 0, trackEvent);
      } else if (otcMySkyPlus.quantityInCart > 0) {
        changeProductQuantity(otcMySkyPlus.id, 0, trackEvent);
      }
      // Add any otc according to box selection
      if (box.sku === productSkuIds.skyBox.primary) {
        changeProductQuantity(otcSkyBox.id, 1, trackEvent);
      } else if (box.sku === productSkuIds.mySky.primary) {
        changeProductQuantity(otcMySky.id, 1, trackEvent);
      } else if (box.sku === productSkuIds.mySkyPlus.primary) {
        changeProductQuantity(otcMySkyPlus.id, 1, trackEvent);
      }
    }
    if (update) {
      await updateOrder(customerId, productStoreApi.getState().getBasketItemsToAddToOrder());
    }
  };

  return {
    handleBoxOTC,
  };
}

export { useHandleOneTimeCharge };
