import { AxiosInstance } from 'axios';
import { NavigationLinks } from '../types';

export class NavigationService {
  private navAgent: AxiosInstance;
  constructor(navAgent: AxiosInstance) {
    this.navAgent = navAgent;
  }
  public getLinks = async (linksUrl: string) => {
    let { data } = await this.navAgent.get<NavigationLinks>(linksUrl, {});
    return data;
  };
}
