import React from 'react';
import { T_OrderProduct } from '@sky-tv-group/shared';
import { T_Coupon, BillingCampaign } from '@sky-tv-group/shared/src/types';
import { CheckCircle } from './RunningTotalIcons';

interface RunningTotalOfferProps {
  offer: T_OrderProduct | undefined;
  coupon: T_Coupon | undefined;
  offerText?: string;
  campaign?: BillingCampaign;
  captionless?: boolean;
  additionalClasses?: string;
  showNew?: boolean;
}
const RunningTotalOffer = ({
  offer,
  coupon,
  offerText,
  campaign,
  captionless,
  additionalClasses,
  showNew = false,
}: RunningTotalOfferProps) => {
  return (
    <p
      className={`pb-2 items-center justify-between max-w-80%  ${additionalClasses ? additionalClasses : 'text-navy'} 
        ${showNew ? 'sky-h6-reg' : 'sky-h7-reg'}`}
      data-testid={`running-total-offer-${coupon?.couponCode}`}>
      <span className="flex items-center">
        <span
          className="icon-wrapper mr-3"
          style={{
            minWidth: '15px',
            width: '15px',
            height: '15px',
            display: 'inline-block',
            cursor: 'pointer',
            color: '#9100D4',
          }}>
          <CheckCircle />
        </span>
        {campaign ? (
          <span>Offer {campaign.promotionCode} applied</span>
        ) : offer ? (
          offer.product.name
        ) : coupon ? (
          coupon.name
        ) : (
          ''
        )}
      </span>
      {!captionless && (
        <span
          className={`${showNew ? 'sky-h7-reg' : 'sky-sub'} inline-block pl-7`}
          dangerouslySetInnerHTML={{ __html: offer ? offer.promotionTitle : offerText ?? '' }}></span>
      )}
    </p>
  );
};

export { RunningTotalOffer };
