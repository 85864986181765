import create from 'zustand';
import { AddressPrequalification } from '../services/prequal';
import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';

import { YesNoValueType, SkyCustomerYesNoOption, T_Product, T_Customer } from '../types';

interface AddressLines {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
}

/* Broadband specific data. */
interface BroadbandStoreData {
  isLoadingPrequal: boolean;
  guestPrequalData: AddressPrequalification | undefined;
  isUpdatingPlan: boolean;
  persistedButtonStates: {
    vulnerableCustomerInfo: YesNoValueType;
    wifiBoosterQuestion1: YesNoValueType;
    wifiBoosterQuestion2: YesNoValueType;
    wifiBoosterQuestion3: YesNoValueType;
    wifiBoosterAlreadyKnow: YesNoValueType;
  };

  authenticatedPrequalData: AddressPrequalification | undefined;
  selectedCardIndex: number;
  useGuestPrequal: boolean;
  selectedInstallationAddress: string | undefined;
  selectedTuiAddress: string | undefined;
  selectedAddressId: string | undefined;
  authTuiAddress: string | undefined;
  selectedIsSkyStarterCustomer: boolean;
  broadbandProductSelected: T_Product | undefined;
  isBundle: boolean;
  customer: T_Customer | undefined;
  hasActiveOrPendingBroadbandPackage: boolean;

  selectedAddressLines: AddressLines | undefined;
  redirectFromCheckingPage: boolean;
}

interface NewAcquisitionOrderStore extends BroadbandStoreData {
  accountNumber: string;
  workOrderNumber: string;
  paymentURL: string;
  firstName: string;
  email: string;
  selectedInstallationAddress: string | undefined;
  selectedTuiAddress: string | undefined;
  selectedAddressId: string | undefined;
  selectedAddressLines: AddressLines | undefined;
  setBroadbandProductSelected: (value: T_Product) => void;
  setAccountNumber: (value: string) => void;
  setWorkOrderNumber: (value: string) => void;
  setPaymentURL: (value: string) => void;
  setFirstName: (value: string) => void;
  setEmail: (value: string) => void;
  setSelectedInstallationAddress: (value: string | undefined, selectedAddressLines: AddressLines) => void;
  setSelectedTuiAddress: (value: string | undefined) => void;
  setSelectedAddressId: (value: string | undefined) => void;
  setGuestPrequal: (prequal: AddressPrequalification) => void;
  setIsLoadingPrequal: (value: boolean) => void;
  setPersistedButtonStates: <K extends keyof BroadbandStoreData['persistedButtonStates']>(
    key: K,
    value: BroadbandStoreData['persistedButtonStates'][K]
  ) => void;
  setUpdatedValue: (value: any) => void;
  clearStore: () => void;
  setIsUpdatingPlan: (value: boolean) => void;
}

const sessionStorageStateName = 'New Acquisition Order Store';
const getSessionStorageState: NewAcquisitionOrderStore = JSON.parse(
  sessionStorage.getItem(sessionStorageStateName) ?? '{}'
);

// Converts prequal json data to AddressPrequalification objects
const transform = (storageState: NewAcquisitionOrderStore) => {
  Object.keys(storageState).forEach(key => {
    if (key === 'guestPrequalData') {
      storageState[key] = new AddressPrequalification(storageState[key]?.prequal);
    }
  });
  return storageState;
};

const persistedButtonsDefaultState: BroadbandStoreData['persistedButtonStates'] = {
  vulnerableCustomerInfo: SkyCustomerYesNoOption.NO,
  wifiBoosterQuestion1: SkyCustomerYesNoOption.NO,
  wifiBoosterQuestion2: SkyCustomerYesNoOption.NO,
  wifiBoosterQuestion3: SkyCustomerYesNoOption.NO,
  wifiBoosterAlreadyKnow: SkyCustomerYesNoOption.NO,
};

const [useNewAcquisitionOrderStore, newAcquisitionOrderStoreApi] = create<NewAcquisitionOrderStore>(
  ReduxDevTools(
    StatePersistence(
      (set, get) => ({
        accountNumber: '',
        workOrderNumber: '',
        paymentURL: '',
        firstName: '',
        email: '',
        selectedInstallationAddress: '',
        selectedTuiAddress: undefined,
        selectedAddressId: undefined,
        selectedAddressLines: undefined,
        isLoadingPrequal: false,
        isUpdatingPlan: false,
        guestPrequalData: undefined,

        authenticatedPrequalData: undefined,
        authTuiAddress: '',
        broadbandProductSelected: undefined,
        isBundle: false,
        customer: undefined,
        hasActiveOrPendingBroadbandPackage: false,
        selectedIsSkyStarterCustomer: false,
        redirectFromCheckingPage: false,
        persistedButtonStates: persistedButtonsDefaultState,
        selectedCardIndex: 1,
        useGuestPrequal: getSessionStorageState.useGuestPrequal ?? false,

        ...(transform(getSessionStorageState) as {}),
        setAccountNumber: (value: string) => {
          set({ accountNumber: value }, 'set Account number');
        },
        setBroadbandProductSelected: (value: T_Product) => {
          set({ broadbandProductSelected: value }, `Product selected ${value.sku}`);
        },
        setWorkOrderNumber: (value: string) => {
          set({ workOrderNumber: value }, 'set Work Order number');
        },
        setPaymentURL: (value: string) => {
          set({ paymentURL: value }, 'set paymenturl');
        },
        setFirstName: (value: string) => {
          set({ firstName: value }, 'set First name');
        },
        setEmail: (value: string) => {
          set({ email: value }, 'set email');
        },
        setSelectedInstallationAddress: (value: string | undefined, selectedAddressLines: AddressLines) => {
          set({ selectedInstallationAddress: value, selectedAddressLines });
        },
        setSelectedTuiAddress: (value: string | undefined) => {
          set({ selectedTuiAddress: value });
        },
        setSelectedAddressId: (value: string | undefined) => {
          set({ selectedAddressId: value });
        },
        setGuestPrequal: (prequal: AddressPrequalification) => {
          set({ guestPrequalData: prequal });
        },
        setIsLoadingPrequal: (isLoading: boolean) => {
          set({ isLoadingPrequal: isLoading });
        },
        setIsUpdatingPlan: (isUpdatingPlan: boolean) => {
          set({ isUpdatingPlan: isUpdatingPlan });
        },
        setUpdatedValue: (updatedValue: any) => {
          const currentValue = get();
          const value = transform(updatedValue) as {};
          set({ ...currentValue, ...value });
        },
        setPersistedButtonStates: <K extends keyof BroadbandStoreData['persistedButtonStates']>(
          key: K,
          value: BroadbandStoreData['persistedButtonStates'][K]
        ) => {
          set({
            persistedButtonStates: {
              ...get().persistedButtonStates,
              [key]: value,
            },
          });
        },
        clearStore: () => {
          set({
            selectedInstallationAddress: '',
            selectedTuiAddress: '',
            selectedAddressId: '',
            selectedAddressLines: undefined,
            guestPrequalData: undefined,
          });
        },
      }),
      sessionStorageStateName
    ),
    'Order Store'
  )
);

export { useNewAcquisitionOrderStore, newAcquisitionOrderStoreApi };
