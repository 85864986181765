import * as React from 'react';
import { Button } from '../../button';
import { T_Product } from '@sky-tv-group/shared/src/types';
import { useState } from 'react';

interface CardCTAButtonProps {
  addLink?: string | null;
  callToActionButtonAction?: (inCart: boolean, product: T_Product) => Promise<void>;
  detailsLink?: string | null;
  detailsAction?: () => void;
  associatedKKProduct?: T_Product;
}

const CardCTAButton: React.FC<CardCTAButtonProps> = ({
  addLink,
  callToActionButtonAction,
  detailsLink,
  detailsAction,
  associatedKKProduct,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inCart = !!associatedKKProduct && associatedKKProduct.quantityInCart > 0;

  const getColorMode = () => {
    if (inCart && !isLoading) {
      return 'pink';
    } else if (isDisabled) {
      return 'disabled';
    } else {
      return 'pure';
    }
  };

  const onCallToActionClick = async () => {
    if (callToActionButtonAction != null) {
      setIsDisabled(true);
      setIsLoading(true);
      if (callToActionButtonAction && associatedKKProduct) {
        await callToActionButtonAction(inCart, associatedKKProduct);
      }
      setIsDisabled(false);
      setIsLoading(false);
    } else if (addLink) {
      window.open(addLink, '_blank')?.focus();
    }
  };

  return (
    <div className="flex flex-row relative mt-sky-lg">
      <Button
        variant="secondary"
        colorMode="light"
        onClick={() => {
          if (detailsAction) {
            detailsAction();
          } else if (detailsLink) {
            window.open(detailsLink, '_blank')?.focus();
          }
        }}>
        Details
      </Button>

      <Button
        onClick={onCallToActionClick}
        className="flex-1"
        variant="primary"
        data-testid={'add-product-' + associatedKKProduct?.sku}
        icon={isLoading ? 'loading' : inCart ? 'check' : 'add'}
        colorMode={getColorMode()}>
        {inCart && !isLoading ? 'Added' : 'Add'}
      </Button>
    </div>
  );
};

export default CardCTAButton;
