import React, { FunctionComponent, useState } from 'react';
import {
  T_Product,
  useHandleRemove,
  useProductWithCouponRemoval,
  categoryIds,
  useToastContainer,
} from '@sky-tv-group/shared';
import { IIcedPackageCardProps } from '../package-card/IcedPackageCard';
import { Button } from '../button';
import { FancyPrice } from '../FancyPrice';
import { IPopUpContentConfig } from '@sky-tv-group/shared/src/types';

export interface IExploreBannerProps extends IIcedPackageCardProps {
  product: T_Product;
  price: number;
  hide: () => void;
  isStarter?: boolean;
  skyPriceChangePopUp?: IPopUpContentConfig[] | undefined;
  disabled?: boolean;
  selectedTuiAddress?: string;
}

export const ExploreBanner: FunctionComponent<IExploreBannerProps> = ({
  product,
  removable,
  toggleProduct,
  kkService,
  price,
  subscribed = false,
  isStarter = false,
  skyPriceChangePopUp,
  disabled = false,
  selectedTuiAddress,
}) => {
  const isInCart = product.quantityInCart > 0;
  removable = subscribed ? false : removable;
  let labelInCart = subscribed ? 'Subscribed' : removable ? 'Remove' : 'Included';
  if (labelInCart === 'Subscribed' && isStarter) {
    labelInCart = 'Included';
  }
  const preventRemove = !removable && product.quantityInCart > 0;
  const handleRemove = useHandleRemove(kkService);
  const isBroadband = product.categoryId === categoryIds.broadband;

  const [isDisabled, setIsDisabled] = useState(disabled);
  const { addToast } = useToastContainer();

  const { handleRemovalOfT_ProductWithToast } = useProductWithCouponRemoval(kkService);
  const handleToggle = async () => {
    setIsDisabled(true);

    if (isInCart) {
      await handleRemove(product);
    } else {
      await handleRemovalOfT_ProductWithToast(product, toggleProduct, true);
      if (skyPriceChangePopUp) {
        Object.entries(skyPriceChangePopUp)?.map(([key, popUp]) => {
          if (popUp.sku === product.sku) {
            addToast({
              title: popUp?.title,
              message: `${popUp?.bodyCopy} <a href="${popUp?.link}" target="_blank" class="text-blue-light underline">Find out more here</a>`,
              type: 'offer',
            });
          }
        });
      }
    }
    setIsDisabled(false);
  };

  /* Sonarcloud: Ternary operators should not be nested. */
  function getColorMode() {
    if (isInCart) {
      if (removable) {
        return 'pink';
      } else {
        return 'disabled';
      }
    } else {
      if (disabled) {
        return 'disabled';
      } else {
        return 'pure';
      }
    }
  }

  return (
    <div className="flex flex-row justify-end items-center ">
      {selectedTuiAddress ? (
        <Button
          className="h-auto w-20 md:w-28 md:h-12 mr-8"
          colorMode={getColorMode()}
          icon={isInCart ? (removable ? 'check' : undefined) : 'add'}
          variant="primary"
          data-testid={'add-product-' + product.sku}
          onClick={() => {
            handleToggle();
          }}
          disabled={isDisabled || preventRemove}>
          {product.quantityInCart > 0 ? labelInCart : 'Add'}
        </Button>
      ) : null}
      <FancyPrice
        price={price}
        numberStyles="sm:sky-h1-black sky-h1 font-bold w-full ml-auto"
        containerClasses="text-navy relative "
      />
      <div className="w-8"></div>
    </div>
  );
};
