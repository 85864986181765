import { T_Product } from '../../../types';
import { formatMoney } from '../../format';

/**
 * Removing productName will remove offer
 * @param productName
 */
const removeProductRemovesOffer = (productName: string) => {
  return `Removing ${productName} will also remove the offer. Are you sure you would like this?`;
};

/**
 * Removing product will remove Free Product
 * @param productName
 * @param freeProductName
 */
const removeProductRemovesFreeProduct = (productName: string, freeProductName: string) => {
  return `Removing ${productName}, will also remove ${freeProductName} on us. Are you sure you would like this?`;
};
/**
 * Removing the offer will remove all associated coupon products
 * @param couponProducts
 */
const removeOfferRemovesMultipleProducts = (couponProducts: Pick<T_Product, 'name'>[]) => {
  return `Removing the offer will also remove ${couponProducts
    .map(cp => cp.name.trim())
    .join(', ')}. Are you sure you would like this?`;
};

/**
 * Removing the Product will remove the Free Product too
 * @param productName
 * @param freeProductName
 */
const removeProductRemovesOfferAndFreeProduct = (productName: string, freeProductName: string) => {
  return `Removing ${productName} will also remove the offer. It will also remove ${freeProductName} on us. Are you sure you would like this?`;
};

/**
 * Removing the offer will remove all associated coupon products and the free product since its associated with the coupon
 * @param couponProducts
 * @param freeProductName
 */
const removeOfferRemovesMultipleProductsAndFreeProduct = (
  couponProducts: Pick<T_Product, 'name'>[],
  freeProductName: string
) => {
  return `Removing the offer will also remove ${couponProducts
    .map(cp => cp.name.trim())
    .join(', ')}. This will also remove ${freeProductName} on us. Are you sure you would like this?`;
};

/**
 * Removing product will remove free product and keep it in cart at original price
 * @param productName
 * @param freeProductName
 * @param freeProductPriceIncTax
 */
const removeProductRemovesOfferAndFreeProductAndChargesItAtDefault = (
  productName: string,
  freeProductName: string,
  freeProductPriceIncTax: number
) => {
  return `Removing ${productName} will also remove the offer. It will also remove ${freeProductName} on us.
    ${freeProductName} will be charged at ${formatMoney(freeProductPriceIncTax)}. Are you sure you would like this?`;
};

/**
 * Removing the offer removes multiple products and will remove free product, which will be charged at default price
 * @param couponProducts
 * @param freeProductName
 * @param freeProductPriceIncTax
 */
const removeOfferRemovesMultipleProductsAndFreeProductAndChargesItAtDefault = (
  couponProducts: Pick<T_Product, 'name'>[],
  freeProductName: string,
  freeProductPriceIncTax: number
) => {
  return `Removing the offer will also remove ${couponProducts
    .map(cp => cp.name.trim())
    .join(', ')}. This will also remove ${freeProductName} on us. ${freeProductName}
    will be charged at ${formatMoney(freeProductPriceIncTax)}. Are you sure you would like this?`;
};

/**
 * Removing product will remove free product, which will be charged at default price
 * @param productName
 * @param freeProductName
 * @param freeProductPriceIncTax
 */
const removeProductRemovesFreeProductAndChargesItAtDefault = (
  productName: string,
  freeProductName: string,
  freeProductPriceIncTax: number
) => {
  return `Removing ${productName}, will also remove ${freeProductName} on us. ${freeProductName} will be charged at ${formatMoney(
    freeProductPriceIncTax
  )}.
    Are you sure you would like this?`;
};

/**
 * Removing product will remove Free product and charge at coupon related pricing
 * @param productName
 * @param freeProductName
 */
const removeProductRemovesFreeProductAndChargesItAtPromotionalPricing = (
  productName: string,
  freeProductName: string
) => {
  return `Removing ${productName}, will also remove ${freeProductName} on us. ${freeProductName} will be charged at the promotional pricing as per the offer.
    Are you sure you would like this?`;
};

export {
  removeProductRemovesOffer,
  removeOfferRemovesMultipleProducts,
  removeProductRemovesFreeProduct,
  removeOfferRemovesMultipleProductsAndFreeProduct,
  removeProductRemovesFreeProductAndChargesItAtDefault,
  removeOfferRemovesMultipleProductsAndFreeProductAndChargesItAtDefault,
  removeProductRemovesOfferAndFreeProduct,
  removeProductRemovesOfferAndFreeProductAndChargesItAtDefault,
  removeProductRemovesFreeProductAndChargesItAtPromotionalPricing,
};
