import React from 'react';

const infoConfig: Array<{ title: string; body: string; icon: string }> = [
  {
    title: 'Hassle-free set up',
    body:
      'We’ll get you up and running quickly. Fancy a bit of DIY? You can self-install using our jargon free set-up guides. ',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/bb1.png',
  },
  {
    title: 'Super Set Up Technicians',
    body:
      'If DIY set-up’s not your thing you can choose to have one of our Super Set Up Technicians visit your home and do it for you.',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/bb2.png',
  },
  {
    title: 'Trusted service that rocks',
    body:
      'You know us and our Sky crew, so you can trust us to deliver fantastic service. Sky Broadband’s no exception. ',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/bb3.png',
  },
  {
    title: 'Use of latest WiFi6 tech',
    body:
      'Which means our Sky WiFi Routers are grunty enough to give you coverage from the lounge room to the throne room in an average sized kiwi home (less than 200m2).',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/bb4.png',
  },
  {
    title: 'Home phone plans available',
    body:
      'There’s even a very competitive Sky Home Phone calling plan plus you can bring your existing home phone number over to Sky.',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/bb5.png',
  },
  {
    title: 'Rolling monthly plan',
    body: `We reckon you're going to love, love, LOVE Sky Broadband and want to stay. That's why we offer rolling monthly broadband plans for Sky TV customers. `,
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/bb6.png',
  },
];

const BBMarketingInfo = () => {
  const numberOfColumns = 3;

  return (
    <div className="sky-bg-white">
      <div className={'pt-9 md:pt-11 pb-16 md:pb-24 flex flex-row justify-center'}>
        <div
          className={
            'flex flex-col md:flex-row md:justify-center md:flex-wrap w-full border border-gray-light border-1 border-t-0'
          }>
          {infoConfig.map(({ icon, title, body }, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-start md:w-1/3 p-8 border-t border-gray-light border-t-1 ${
                (index + 1) % numberOfColumns !== 0 ? 'md:border-r md:border-r-gray-light md:border-r-1' : undefined
              }`}>
              <img src={icon} className={'object-contain'} style={{ width: '89px', height: '89px' }} alt={title} />
              <h5 className={'mt-5 sky-h4-bold md:sky-h6-bold text-center'}>{title}</h5>
              <div className={'mt-5 text-center'}>{body}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { BBMarketingInfo };
