import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import cx from 'classnames';
import { Error } from './icon';
import { Offer } from './icon';
import { Success } from './icon';
import { Warning } from './icon';

import { ToastProps } from '@sky-tv-group/shared';
/**
 * A Toast card to show at the bottom of the screen
 */
const Toast = ({ type, title, message, action, actionText, onClose, closeText }: ToastProps) => {
  let [isDisabledAction, setIsDisableAction] = useState(false);
  let [isDisabledClose, setIsDisabledClose] = useState(false);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose?.(e);
  };

  const isErrorOrSuccess = type === 'error' || type === 'success';
  const toastRootClasses = cx(
    'flex flex-row flex-wrap items-center mb-1 min-w-full transition duration-200 ease-in-out md:min-w-auto md:w-full shadow',
    {
      'bg-error': type === 'error',
      'bg-success': type === 'success',
      'bg-warning': type === 'warning',
      'bg-offer': type === 'offer',
    }
  );
  const headingClasses = cx('sky-h2 ml-4 pb-6 text-navy', {
    'text-white': isErrorOrSuccess,
  });
  const handleAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    action?.(e);
    handleClose(e);
  };
  const contentContainerClasses = cx('bg-white ml-8 p-4 shadow w-full');
  const textClasses = cx('sky-h4 md:sky-h7 mr-5 text-gray-darkerest', {
    'pb-3': action != null || onClose != null,
  });
  const primaryButtonClasses = cx('sky-button', {
    'sky-button--primary-error': type === 'error',
    'sky-button--primary-success': type === 'success',
    'sky-button--primary-warning': type === 'warning',
    'sky-button--primary-offer': type === 'offer',
  });

  const secondaryButtonClasses = cx('sky-button', {
    'sky-button--secondary-error': type === 'error',
    'sky-button--secondary-success': type === 'success',
    'sky-button--secondary-warning': type === 'warning',
    'sky-button--secondary-offer': type === 'offer',
  });

  function ToastIconType() {
    switch (type) {
      case 'error':
        return <Error />;
      case 'offer':
        return <Offer />;
      case 'success':
        return <Success />;
      case 'warning':
        return <Warning />;
      default:
        return null;
    }
  }

  return (
    <div className={toastRootClasses}>
      <div className={contentContainerClasses}>
        <div className="flex flex-row mr-4">
          {ToastIconType()}
          <h4 className={headingClasses}>{title}</h4>
        </div>
        {/* Use Dompurify to sanitise possible HTML to avoid XSS security issues */}
        <p
          className={textClasses}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message, { ADD_ATTR: ['target'] }) }}
        />
        <div className="flex flex-row flex-no-wrap justify-end">
          {/* if an action was not supplied, render a close button  */}
          {action != null ? (
            <>
              <button
                className={secondaryButtonClasses}
                onClick={async e => {
                  await setIsDisabledClose(true);
                  handleClose(e);
                }}
                disabled={isDisabledClose}
                data-testid={closeText}>
                {closeText}
              </button>
              <button
                className={primaryButtonClasses}
                onClick={async e => {
                  await setIsDisableAction(true);
                  handleAction(e);
                }}
                disabled={isDisabledAction}
                data-testid={actionText}>
                {actionText}
              </button>
            </>
          ) : (
            <button
              className={primaryButtonClasses}
              onClick={async e => {
                await setIsDisabledClose(true);
                handleClose(e);
              }}
              disabled={isDisabledClose}
              data-testid={closeText ?? 'Close'}>
              {closeText ?? 'Close'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export { Toast };
