import { Service, ServiceOccurrence } from '../../../types';

/**
 * One Time Charges and Technician Fees must always be on the first occurrence
 * Need to verify if there is a Service initialised or add it if needed.
 * @param occurrence
 * @param boxUpgradableItems
 * @param cableServices
 * @param upgradableBoxDetails
 */
export const handleOneTimeChargeLogic = (
  occurrence: Pick<ServiceOccurrence, 'status' | 'occurrenceNumber'>[],
  oneTimeChargeItems: string[],
  cableServices: Service[]
): Service[] => {
  // If there is a default cable service occurrence hit the first occurrence to update with the oneTimeCharges
  if (cableServices[0] && cableServices[0].actions[0] && cableServices[0].actions[0].add) {
    cableServices[0].actions[0].add = [...cableServices[0].actions[0].add, ...oneTimeChargeItems];
    return cableServices;
  }

  // Nothing has been added to the cable service, so need to construct new one with one time charge items
  if (cableServices.length === 0 && oneTimeChargeItems.length > 0) {
    // Need to find the first valid occurrence as that is our default box
    const firstOccurrence = occurrence.find(occ => occ.status === 1);
    if (firstOccurrence) {
      // add the default occurrence and return that only
      cableServices.push({
        id: firstOccurrence.occurrenceNumber.toString(),
        actions: [
          {
            add: [...oneTimeChargeItems],
            remove: [],
          },
        ],
        details: [],
      });
      return cableServices;
    }
  }

  // handle all other cases
  return cableServices;
};
