import { categoryIds, productSkuIds } from '../../config';
import { useboxesStore } from '../../store/boxesStore';
import { productStoreApi } from '../../store/productStore';
import { BOXTYPE, T_Occurrence, T_OrderProduct, T_Product } from '../../types';

// TODO could used UUID
let uniqueId = () =>
  Date.now().toString(36) +
  Math.random()
    .toString(36)
    .substring(2);

function useAddUpdateBox() {
  const products = productStoreApi.getState().products;
  const addBox = useboxesStore(s => s.addBox);
  const removeBox = useboxesStore(s => s.removeBox);
  const getPrimaryBox = useboxesStore(s => s.getPrimaryBox);

  /**
   * Sky Box.
   */
  const skyBox = products.find(p => p.sku === productSkuIds.skyBoxCharge.primary);
  // VOD
  const noRecording = products.find(p => p.sku === productSkuIds.noRecording.primary);
  // My Sky
  const recording = products.find(p => p.sku === productSkuIds.arrowBoxRecording.primary);

  /**
   * Upfront recording.
   * Customers upgrading from a box which had recording paid for upfront - they are entitled to free recording when upgrading.
   */
  const upFrontRecording = products.find(p => p.sku === productSkuIds.upfrontRecording.primary);
  // Multiroom charge
  const multiroomFee = products.find(p => p.sku === productSkuIds.multiroom.primary);
  /**
   * New Sky Box WHT.
   */
  const newSkyBoxWhite = products.find(p => p.sku === productSkuIds.arrowBox.primary);
  /**
   * New Sky Box One Off Fee.
   */
  const newSkyBoxOneOffFee = products.find(p => p.sku === productSkuIds.arrowBoxOneOff.primary);
  /**
   * New Sky Box Upfront Fee.
   */
  const newSkyBoxUpfront = products.find(p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary);
  /**
   * New Sky Box Monthly.
   */
  const newSkyBoxMonthly = products.find(p => p.sku === productSkuIds.arrowMonthly.primary);
  /**
   * New Sky Box Tech Visit
   */
  const newSkyBoxTechVisit = products.find(x => x.sku === productSkuIds.ArrowTechVisit.primary);

  /**
   * Sky Pod/Sky Stream - available to customers migrating from VTV
   */
  const skyPod = products.find(p => p.sku === productSkuIds.skyPod.primary);

  const skyPodOneOffFee = products.find(p => p.sku === productSkuIds.skyPodOneOffFee.primary);

  /**Add Box to Store */
  const addNewBoxtoStore = (
    name: string,
    boxType: BOXTYPE,
    products: T_Product[],
    upgradeFrom?: T_Occurrence,
    primary?: boolean
  ) => {
    const tempOccurrenceId = uniqueId();
    addBox({
      id: tempOccurrenceId,
      name: name,
      boxType: boxType,
      serviceCode: products.find(p => p.categoryId === categoryIds.box)?.sku,
      products: products.map(p => {
        p.occurrenceNumber = tempOccurrenceId;
        return p;
      }),
      upgradeFrom: upgradeFrom,
      primary,
    });
    return tempOccurrenceId;
  };

  /**Update Box to Store */
  const updateBoxtoStore = (
    id: string,
    name: string,
    boxType: BOXTYPE,
    products: T_Product[],
    upgradeFrom?: T_Occurrence,
    primary?: boolean,
    occurence?: T_Occurrence
  ) => {
    removeBox(id);
    addBox({
      id,
      primary: !!primary,
      name: name,
      boxType: boxType,
      products: products.map(p => {
        p.occurrenceNumber = id;
        return p;
      }),
      upgradeFrom: upgradeFrom,
      occurence: occurence,
    });
  };

  const removeBoxFromStore = (id: string) => {
    removeBox(id);
  };

  /**
   * This method adds the primary box on acquisition journey when "/boxes" is not applicable.
   * Takes the box type from the order products and adds to the boxes store.
   *
   * @param orderProducts
   */
  const addPrimaryBoxFromOrder = (orderProducts: T_OrderProduct[]) => {
    let arrowBox = orderProducts.find(p => p.product.sku === productSkuIds.arrowBox.primary);
    let skyBox = orderProducts.find(p => p.product.sku === productSkuIds.skyBoxCharge.primary);
    let recording = orderProducts.find(p => p.product.sku === productSkuIds.arrowBoxRecording.primary);
    let noRecording = orderProducts.find(p => p.product.sku === productSkuIds.noRecording.primary);
    let boxOneOff643 = orderProducts.find(p => p.product.sku === productSkuIds.arrowBoxOneOff.primary);
    let boxOneOff830 = orderProducts.find(p => p.product.sku === productSkuIds.arrowUpfrontBoxFee.primary);
    let arrowMonthly = orderProducts.find(p => p.product.sku === productSkuIds.arrowMonthly.primary);
    let skyPod = orderProducts.find(p => p.product.sku === productSkuIds.skyPod.primary);
    let skyPodOneOffFee = orderProducts.find(p => p.product.sku === productSkuIds.skyPodOneOffFee.primary);

    let products: T_OrderProduct[] = [];
    let boxId: string | undefined = undefined;
    if (arrowBox) {
      products = [
        arrowBox,
        ...(boxOneOff643 && boxOneOff830 ? [boxOneOff643, boxOneOff830] : []),
        ...(arrowMonthly && !boxOneOff643 ? [arrowMonthly] : []),
        ...(recording ? [recording] : noRecording ? [noRecording] : []),
      ];
      boxId = addNewBoxtoStore(
        arrowBox.product.name,
        'NEW',
        products.map(p => p.product),
        undefined,
        true
      );
    } else if (skyBox) {
      products = [skyBox, ...(recording ? [recording] : noRecording ? [noRecording] : [])];
      boxId = addNewBoxtoStore(
        skyBox.product.name,
        'NEW',
        products.map(p => p.product),
        undefined,
        true
      );
    } else if (skyPod) {
      products = [skyPod, ...(skyPodOneOffFee ? [skyPodOneOffFee] : [])];
      boxId = addNewBoxtoStore(
        skyPod.product.name,
        'NEW',
        products.map(p => p.product),
        undefined,
        true
      );
    }
    // add the box id to the order products to identify which box this product belongs to
    if (boxId) products.forEach(op => (op.occurrenceNumber = boxId));
  };

  const addPrimaryBoxFromSKU = (skus: string[]) => {
    let primaryBox = getPrimaryBox();
    let boxProducts: T_Product[] = [];
    let boxId: string | undefined = undefined;

    if (skyBox && skus.includes(skyBox?.sku)) {
      boxProducts = [skyBox, ...(recording && skus.includes(recording.sku) ? [recording] : [])];
      if (!primaryBox) boxId = addNewBoxtoStore(boxProducts[0].name, 'NEW', boxProducts, undefined, true);
    } else if (newSkyBoxWhite && skus.includes(newSkyBoxWhite.sku)) {
      boxProducts = [
        newSkyBoxWhite,
        // one-off product
        ...(newSkyBoxOneOffFee && newSkyBoxUpfront && skus.includes(newSkyBoxOneOffFee.sku)
          ? [newSkyBoxOneOffFee, newSkyBoxUpfront]
          : []),
        // monthly product
        ...(newSkyBoxMonthly && skus.includes(newSkyBoxMonthly.sku) && !skus.includes(newSkyBoxOneOffFee?.sku ?? '')
          ? [newSkyBoxMonthly]
          : []),
        // pvr product
        ...(recording && skus.includes(recording.sku) ? [recording] : noRecording ? [noRecording] : []),
      ];
      if (!primaryBox) boxId = addNewBoxtoStore(boxProducts[0].name, 'NEW', boxProducts, undefined, true);
    } else if (skyPod && skyPodOneOffFee && skus.includes(skyPod.sku)) {
      boxProducts = [skyPod, skyPodOneOffFee];
      if (!primaryBox) boxId = addNewBoxtoStore(boxProducts[0].name, 'NEW', boxProducts, undefined, true);
    }

    // add the box id to the order products to identify which box this product belongs to
    // and set quantityInCart to 0
    if (boxId) {
      boxProducts.forEach(op => {
        if (op) {
          op.occurrenceNumber = boxId;
          op.quantityInCart = 0;
        }
      });
    }

    return boxProducts;
  };

  return {
    skyBox,
    noRecording,
    recording,
    upFrontRecording,
    multiroomFee,
    newSkyBoxWhite,
    newSkyBoxUpfront,
    newSkyBoxOneOffFee,
    newSkyBoxMonthly,
    newSkyBoxTechVisit,
    addNewBoxtoStore,
    removeBoxFromStore,
    updateBoxtoStore,
    addPrimaryBoxFromOrder,
    skyPod,
    skyPodOneOffFee,
    addPrimaryBoxFromSKU,
  };
}

export { useAddUpdateBox };
