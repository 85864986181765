import React, { useEffect, useRef, useState } from 'react';

import { next, back } from '../navigation';
import { Button, FormElement, HomePhone } from '@sky-tv-group/components';
import { CheckoutStates, useVoiceDetails, useNewAcquisitionOrderStore } from '@sky-tv-group/shared';

import { serviceProviderService, orderService, voiceService, myAccountSWR } from '../../../services';

const VoiceForm = () => {
  const { selectedTuiAddress } = useNewAcquisitionOrderStore();

  const {
    form,
    providers,
    homePhoneFields,
    customer,
    hasExistingNumber,
    hasSameProvider,
    keepSameNumber,
    otherPhoneProvider,
    requireAccountNumber,
    showHomePhoneSelector,
    notShowPhoneProviderMatchBroadbandDropDownField,
  } = useVoiceDetails(myAccountSWR, serviceProviderService, () => next(CheckoutStates.VOICE));

  let stickyRef = useRef<HTMLDivElement>(null) as any;

  function handleStickyBarScroll() {
    let footerElement = document.querySelector('#footerTermsAndCond') as any;
    if (stickyRef.current) {
      if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
        footerElement.style.marginBottom = stickyRef.current.offsetHeight + 'px';
      } else {
        footerElement.style.marginBottom = '0px';
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleStickyBarScroll);
    return () => {
      window.removeEventListener('scroll', handleStickyBarScroll);
    };
  }, []);
  const goNext = async () => {
    await form.submit();
    if (form.valid) {
      next(CheckoutStates.VOICE);
    }
  };
  return (
    <div className="container m-auto md:pb-10 text-navy" data-testid="voice-form">
      <FormElement {...form.props}>
        <HomePhone
          form={form}
          providers={providers}
          fields={homePhoneFields}
          customer={customer!}
          hasExistingNumber={hasExistingNumber}
          hasSameProvider={hasSameProvider}
          keepSameNumber={keepSameNumber}
          otherPhoneProvider={otherPhoneProvider}
          requireAccountNumber={requireAccountNumber}
          showHomePhoneSelector={showHomePhoneSelector}
          notShowPhoneProviderMatchBroadbandDropDownField={notShowPhoneProviderMatchBroadbandDropDownField}
          orderService={orderService}
          voiceService={voiceService}
          selectedTui={selectedTuiAddress}
          labelEnabled={true}
        />
      </FormElement>
      <div className=" rounded-lg border overflow-hidden text-white my-12">
        {/* TODO T&Cs here? */}
        <div
          className="block  fixed
               min-w-full bottom-0 lg:static z-50 md:static left-0"
          ref={stickyRef}>
          <div
            className={`flex justify-between md:p-12 lg:p-12  min-w-full bottom-0 z-50 lg:shadow-none md:shadow-none lg:rounded-none md:rounded-none sticky-custom bg-white left-0 p-5`}>
            <Button
              data-testid="back-to-build-my-plan-checkout-button"
              colorMode="light"
              variant="secondary"
              onClick={() => back(CheckoutStates.VOICE)}
              disabled={false}
              className="hidden lg:block md:block">
              Back
            </Button>
            <Button
              data-testid="next-to-confirm-button"
              variant="primary"
              onClick={goNext}
              className="min-w-full min-w-fit">
              Next: View Order Details
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { VoiceForm };
