import { TrackerService } from '../../../../../services/tracker';
import { AddressService } from '../../../../../services/address';
import { AddressPrequalification } from '../../../../../services/prequal';
import { IBroadbandDelivery } from '../../../../../store/broadbandDeliveryStore';

import {
  DeliveryAddress,
  DetailAddons,
  getDeliveryInformation,
  getDetailAddOns,
  getLocationId,
  getProduct,
  getProviderAccountId,
} from '../broadbandOrderDetails';
import { CustomerInfo } from '../../../helper';
import { BBPlanIndex } from '../../dataServiceBuilder';

export interface DeliveryDetails {
  addons: DetailAddons[];
  deliveryAddress: DeliveryAddress | {};
}

/**
 * Builds out a delivery detail object array which represents a payload object for broadband sent to ICOMS
 * A simplified version of the build details function, more specific to delivery information (used for hardware only ordering)
 * @param deliveryDetails - A secondary details object containing information specfic for delivery (e.g. address information)
 * @param customer - Customer object needed to get delivery information and site info
 * @param hasMesh - True if the user is ordering a mesh device (sky booster)
 * @param hasStaticIp - True if the user is ordering static ip
 * @param getPrequalData - A function that gets prequal data
 * @param trackingService - Tracking service used to get the users provider account id
 * @param addressService - Address service needed to build delivery information
 * @param dataProviderUserId - Internet service provider user id needed to build delivery information
 * @param productId - The product key that broadband is being changed to
 * @param isBoostUpgradeOnly - true if only change is to add a mesh device
 * @returns A delivery details array containing broadband order information needed to build the payload that is sent to ICOMS
 */
export const buildDeliveryDetails = async (
  deliveryDetails: IBroadbandDelivery,
  customer: CustomerInfo,
  hasMesh: boolean,
  hasStaticIp: boolean,
  getPrequalData: (addressId: string) => Promise<AddressPrequalification | undefined>,
  isVoiceProductAdded: boolean,
  trackingService: TrackerService,
  addressService: AddressService,
  bbPlanIndex: BBPlanIndex,
  isBoostUpgradeOnly: boolean,
  dataProviderUserId?: string,
  productId?: string,
  availableProductKey?: string
): Promise<DeliveryDetails[]> => {
  //console.log('buildDeliveryDetails', isBoostUpgradeOnly);
  // Modem only available for new broadband customers
  const hasModem = false;

  let prequalData = await getPrequalData(customer.tuiAddressCode!);
  let detailObject = {
    addonOnly: isBoostUpgradeOnly,
    hardwareOnly: isBoostUpgradeOnly,
    locationId: getLocationId(prequalData),
    broadbandProduct: getProduct(prequalData, bbPlanIndex, isVoiceProductAdded) as string | undefined,
    ...(dataProviderUserId !== '' ? { dataProviderUserId: dataProviderUserId } : null),
    ...(productId?.length ? { productId: productId } : null),
    ...(availableProductKey?.length ? { availableProductKey: availableProductKey } : null),
    providerAccountId: await getProviderAccountId(customer, trackingService),
    addons: await getDetailAddOns(hasModem, hasMesh, hasStaticIp, prequalData),
    deliveryAddress: await getDeliveryInformation(
      deliveryDetails,
      customer,
      deliveryDetails.email,
      deliveryDetails.mobileNumberAreaCode.concat(deliveryDetails.mobileNumberLineNumber),
      addressService
    ),
  };
  if (isBoostUpgradeOnly) {
    // @ts-ignore
    delete detailObject['availableProductKey'];
    // @ts-ignore
    delete detailObject['productId'];
    // @ts-ignore
    delete detailObject['broadbandProduct'];
  }

  return [detailObject];
};
