import { FIXED_ICOMS_LIMIT } from '../config';

interface AddressLines {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
}

const generateLineValues = (value: string): { line: string; newLinePrepend: string } => {
  const addressSplit = value.split(' ');
  let line = '';
  let newLinePrepend = '';

  for (let wordToAdd of addressSplit) {
    // + 1 for space
    if (line.length + wordToAdd.length + 1 < FIXED_ICOMS_LIMIT) {
      line = !line.length ? `${wordToAdd}` : `${line} ${wordToAdd}`;
    } else {
      // + 2 as need the space + the extra character to fit in the end
      if (line.length + 2 <= FIXED_ICOMS_LIMIT) {
        // can add to end - but must have space left
        let amountCanAppend = FIXED_ICOMS_LIMIT - (line.length + 1);
        line = `${line} ${wordToAdd.substring(0, amountCanAppend)}`;
        newLinePrepend = wordToAdd.substring(amountCanAppend);
      } else {
        newLinePrepend = newLinePrepend.length ? `${newLinePrepend} ${wordToAdd}` : `${wordToAdd}`;
      }
    }
  }
  return { line, newLinePrepend };
};

/**
 * Need to format the address as each line has 32 character icoms restriction
 */
const formatAddress = (street: string, suburb: string, city: string, postcode: string): AddressLines => {
  const addressArray = [street ?? '', suburb ?? '', city ?? '', postcode ?? ''];
  const valid = addressArray.every(e => e.length <= FIXED_ICOMS_LIMIT);
  if (valid) {
    return {
      line3: addressArray[2],
      line4: addressArray[3],
      line1: addressArray[0],
      line2: addressArray[1],
    };
  }

  // invalid case for icoms
  let array = ['', '', '', ''];
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].length > FIXED_ICOMS_LIMIT) {
      let { line, newLinePrepend } = generateLineValues(addressArray[i]);
      // set the line
      array[i] = line;
      if (!newLinePrepend.length) continue; //skip if nothing to add in new line
      if (i + 1 < array.length) {
        addressArray[i + 1] =
          addressArray[i + 1].length > 0 ? `${newLinePrepend} ${addressArray[i + 1]}` : `${newLinePrepend}`;
      } else {
        // ULTRA RARE / IMPOSSIBLE edge case. The last bit is just lost.
        // Really wouldn't expect this to happen
      }
    } else {
      // line meets requirement of 32 characters or less
      array[i] = addressArray[i];
    }
  }

  return {
    line1: array[0],
    line2: array[1],
    line3: array[2],
    line4: array[3],
  };
};

export { formatAddress };
