import { AxiosInstance } from 'axios';
import { T_DiagnosticsTest } from '../types';

export interface T_WifiSetting {
  ssid: string;
  password: string;
  sequenceNumber: number;
  enabled: boolean;
}

export enum WifiType {
  NETWORK_24_GHZ = 'network24Ghz',
  NETWORK_5_GHZ = 'network5Ghz',
}

export interface T_WifiSettings {
  bandsteering: boolean;
  [WifiType.NETWORK_24_GHZ]: T_WifiSetting[];
  [WifiType.NETWORK_5_GHZ]: T_WifiSetting[];
}

export class WifiService {
  constructor(private agent: AxiosInstance) {}
  public getWifiSetting = async (providerAccountId?: string) => {
    const { data } = await this.agent.get<T_WifiSettings>(`/wifi/wifiSettings`, {
      params: { providerAccountId },
    });
    return data;
  };
  public setWifiSetting = async (providerAccountId: string, body: T_WifiSettings) => {
    const { data } = await this.agent.put<T_WifiSettings>(`/wifi/wifiSettings`, body, {
      params: { providerAccountId },
    });
    return data;
  };
  public getDiagnosticsTest = async (providerAccountId: string): Promise<T_DiagnosticsTest> => {
    const { data } = await this.agent.get('/wifi/diagnostics/test', {
      params: {
        providerAccountId,
      },
    });
    return data;
  };
}
