import { SPLITIO_KEY } from '../config';
import { CouponTypeValue, T_Coupon } from '../types';
import { getTreatment } from './useSplitIO';

export let shouldShowCouponToast = async (coupon: T_Coupon) => {
  if (coupon.custom3?.toUpperCase() !== CouponTypeValue.Acquisition) {
    return true;
  }
  let { enabled: couponToastEnabled } = await getTreatment(SPLITIO_KEY.SKYWEB_COUPON_TOAST);
  return couponToastEnabled;
};
