import React from 'react';
import { Form } from 'react-jeff';

import { DateRange, ServiceProvider, SkyBroadbandFormFieldsProps } from '@sky-tv-group/shared';
import {
  BroadbandActivationDate,
  BroadbandAddress,
  BroadbandEmail,
  BroadbandInstallationDate,
  BroadbandProductDelivery,
  BroadbandProviderDetails,
  ChangingProvider,
  MeshNotification,
  PhoneNumber,
} from '@sky-tv-group/components';
import { addressService } from '../../services';

interface Props {
  form: Form<string>;
  fields: SkyBroadbandFormFieldsProps;
  isFibreSwap: boolean;
  isNotIntactOnt: boolean;
  isNotChorus: boolean;
  isNewFibre: boolean;
  isLfcDoesntTellUsIfConsentRequired: boolean;
  isSelfInstall: boolean;
  consentRequired: boolean;
  providers: ServiceProvider[];
  installDates: DateRange;
  activationDates: DateRange;
  handleCallback?: (data?: any) => void;
}

export const SkyBroadbandForm = ({
  form,
  fields,
  isFibreSwap,
  isNotIntactOnt,
  isNotChorus,
  isNewFibre,
  isLfcDoesntTellUsIfConsentRequired,
  consentRequired,
  isSelfInstall,
  providers,
  installDates,
  activationDates,
  handleCallback,
}: Props) => {
  return (
    <>
      <h4 className="bg-gray-light sky-h3 md:sky-h4 text-center h-24 flex justify-center items-center font-bold">
        Broadband Installation
      </h4>
      <div className="flex flex-col md:flex-row md:flex-wrap p-6 md:p-0 md:py-6">
        <div className="pb-4 w-full">
          <label className="block mb-2 sky-h4-bold">
            Please provide your up-to-date details so we can get things moving for you.
          </label>
        </div>
        <PhoneNumber
          message="Mobile number for broadband communication"
          form={form}
          showTooltip={false}
          mobileNumberAreaCodeField={fields.mobileNumberAreaCodeField}
          mobileNumberLineNumberField={fields.mobileNumberLineNumberField}
        />
        <ChangingProvider isFibreSwap={isFibreSwap} />
        <BroadbandEmail form={form} emailField={fields.emailField} />
        <MeshNotification hidden={isSelfInstall} />
        {
          <BroadbandProviderDetails
            form={form}
            hidden={!isNotIntactOnt}
            isFibreSwap={isFibreSwap}
            isNotChorus={isNotChorus}
            providers={providers}
            currentServiceProviderField={fields.currentServiceProviderField}
            providerAccountNumberField={fields.providerAccountNumberField}
            otherServiceProviderField={fields.otherServiceProviderField}
          />
        }
        <BroadbandInstallationDate
          form={form}
          hidden={!isNewFibre || consentRequired || isLfcDoesntTellUsIfConsentRequired}
          installationDateField={fields.installationDateField}
          installationTimeField={fields.installationTimeField}
          installDates={installDates}
          handleCallback={handleCallback}
        />
        <BroadbandActivationDate
          form={form}
          hidden={isNewFibre}
          activationDateField={fields.activationDateField}
          activationDates={activationDates}
        />
        <BroadbandAddress address={fields.installationAddressField.value} />
        <BroadbandProductDelivery
          form={form}
          hidden={!isSelfInstall}
          productName="Sky Router"
          addressService={addressService}
          useDifferentDeliveryAddressField={fields.useDifferentDeliveryAddressField}
          deliveryAddressField={fields.deliveryAddressField}
          defaultDeliveryAddressField={fields.defaultDeliveryAddressField}
        />
      </div>
    </>
  );
};
