import React from 'react';
import { Field } from 'react-jeff';
import { SPLITIO_KEY, useSplitIO } from '@sky-tv-group/shared';
import { ConditionsCheckbox } from './ConditionsCheckbox';
interface Props {
  marketingMessagesCheckbox: Field<boolean>;
  onChange: (value: boolean) => void;
}

const AccountDetailsCheckbox = ({ marketingMessagesCheckbox, onChange }: Props) => {
  const [enableMarketingMessage] = useSplitIO(SPLITIO_KEY.SKYWEB_ALLOW_MARKETING_MESSAGE);

  return (
    <div className="flex flex-col md:px-0 mb-4">
      {enableMarketingMessage && (
        <ConditionsCheckbox field={marketingMessagesCheckbox} onChange={onChange}>
          I consent to receive electronic messages (e.g. SMS and email) from Sky with unmissable highlights, what’s new
          and awesome as well as exclusive access to competitions, offers and Sky Rewards.
        </ConditionsCheckbox>
      )}
    </div>
  );
};

export { AccountDetailsCheckbox };
