import create from 'zustand';
import { NavigationLinks } from '@sky-tv-group/shared';
import { navService } from '../services/index';
import { NAVIGATION_URL } from '../config';

interface NavStore {
  navLinks: NavigationLinks;
  loadLinks: () => void;
}

export const [useNavStore, API] = create<NavStore>(set => ({
  navLinks: {
    logoUrl: '/',
    logoSrc: '/',
    TVGuideURL: '/',
    header: [],
    side: [],
    footer: [],
    social: [],
    corporate: [],
  },
  loadLinks: async () => {
    try {
      const navLnk = await navService.getLinks(NAVIGATION_URL);
      set({ navLinks: navLnk });
    } catch (err) {
      console.error(err);
    }
  },
}));
