import React from 'react';
import classnames from 'classnames';
export interface FirstMonthBannerConfig {
  componentHeader: string;
  offerHeader: string;
  offerDescription: string;
  ctaButtonText: string;
  ctaButtonLink: string;
  termsLabel: string;
  termsLink: string;
  icon: string;
  backGroundImageMobile: string;
  backGroundImage: string;
}

export interface FirstMonthOfferProps {
  className?: string;
  infoConfig?: FirstMonthBannerConfig;
  showComponentHeader?: boolean;
}

const FirstMonthOffer = React.memo(
  ({ className = '', infoConfig, showComponentHeader = true }: FirstMonthOfferProps) => {
    if (!infoConfig) return null;

    return (
      <div className={classnames('flex flex-col justify-center items-center pb-10 md:pb-13 m-auto', className)}>
        {infoConfig.componentHeader && showComponentHeader && (
          <h6 className="sky-h6 6 text-navy text-center 6 mb-2">{infoConfig.componentHeader}</h6>
        )}
        <div
          className="flex flex-row relative w-full text-navy bg-white border rounded-md"
          style={{ minHeight: '120px' }}>
          {/* Desktop */}
          <div className="h-full w-20 sm:w-40 flex items-start rounded-l-md absolute sky-blue-gradient">
            <img
              alt={'one month on us'}
              src={'https://static.sky.co.nz/sky/icons/1monthonus.svg'}
              className="w-100px h-100px absolute"
              style={{
                right: -28,
                top: 10,
              }}
            />
          </div>
          <div
            className="text-left flex flex-col sm:flex-row justify-start flex-grow pr-8 pt-4 pb-4 items-center container"
            style={{
              backgroundColor: '#00013A',
              color: 'white',
              borderRadius: '0.5rem',
            }}>
            <h3 className="sky-h6-black ml-40 sm:ml-60 flex flex-col sm:flex-row items-center w-full">
              <span
                className="sky-h6-reg"
                style={{
                  fontSize: '14px',
                }}>
                {infoConfig.offerHeader} {infoConfig.offerDescription}&nbsp;
                <a href={infoConfig.termsLink} target="_blank" rel="noreferrer noopener" className="">
                  {infoConfig.termsLabel}.&nbsp;
                </a>
              </span>
              <div
                className="ml-auto"
                style={{
                  textAlign: 'left',
                }}>
                <a
                  href={infoConfig.ctaButtonLink}
                  className="sky-button sky-button--primary-dark fr-banner-carousel-slide-primary mt-4 sm:mt-0"
                  style={{
                    display: 'block',
                    width: '138px',
                  }}>
                  {infoConfig.ctaButtonText}
                </a>
              </div>
            </h3>
          </div>
        </div>
      </div>
    );
  }
);

export { FirstMonthOffer };
