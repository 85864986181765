import { productStoreApi } from '../store/productStore';
import { T_Product, CouponTypeValue } from '../types';
import { KonakartService } from '../services/konakart';
import { useCustomerStore } from '../store/customerStore';
import { useOrderStore } from '../store/orderStore';
import { useCartContainer } from './useCartContainer';
import { productSkuIds } from '../config';
import { couponStoreApi } from '../store/couponStore';

function useBroadbandSwapProduct(kkService: KonakartService) {
  const {
    routerAdded,
    meshDevice,
    broadbandProductInCart,
    broadband,
    toggleProduct,
    applyProduct,
    broadbandServices,
    broadbandOneOffFees,
  } = useCartContainer(kkService, CouponTypeValue.Upgrade, false);
  const customerId = useCustomerStore(s => s.kk);
  const updateOrder = useOrderStore(s => s.updateOrder);
  const meshAdded = meshDevice ? meshDevice.quantityInCart > 0 : false;

  // broadband installation types
  const broadbandByod = broadbandServices.find(x => x.sku === productSkuIds.broadbandBringYourOwnDevice.primary);
  const broadbandSelfInstallation = broadbandServices.find(x => x.sku === productSkuIds.broadbandSelfInstall.primary);
  const selfInstallOTC = broadbandOneOffFees.find(p => p.sku === productSkuIds.broadbandSelfInstallOTC.primary);

  const currentBroadband = broadband.find(p => p.sku === broadbandProductInCart?.product.sku);
  const lightningFastWifi = broadband.find(p => p.sku === productSkuIds.broadbandLightningFastWiFi.primary);
  const lightningFastWifiBoost = broadband.find(p => p.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary);
  const wifi100 = broadband.find(p => p.sku === productSkuIds.broadbandWifi100.primary);
  const wifi100Boost = broadband.find(p => p.sku === productSkuIds.broadbandWifi100Boost.primary);
  const fibreStarter = broadband.find(p => p.sku === productSkuIds.broadbandEssnFibre.primary);
  const fibreStarterBoost = broadband.find(p => p.sku === productSkuIds.broadbandEssnFibBoost.primary);

  const swapBroadbandPackage = (currentProduct: T_Product, newProduct: T_Product) => {
    applyProduct(currentProduct.id, 0);
    applyProduct(newProduct.id, 1);
  };

  const swapToBoostPlan = (isBoost: boolean) => {
    if (isBoost && currentBroadband) {
      if (currentBroadband.sku === lightningFastWifi?.sku) {
        swapBroadbandPackage(currentBroadband, lightningFastWifiBoost!);
      } else if (currentBroadband.sku === wifi100?.sku) {
        swapBroadbandPackage(currentBroadband, wifi100Boost!);
      } else if (currentBroadband.sku === fibreStarter?.sku) {
        swapBroadbandPackage(currentBroadband, fibreStarterBoost!);
      }
    } else if (!isBoost && currentBroadband) {
      if (currentBroadband.sku === lightningFastWifiBoost?.sku) {
        swapBroadbandPackage(currentBroadband, lightningFastWifi!);
      } else if (currentBroadband.sku === wifi100Boost?.sku) {
        swapBroadbandPackage(currentBroadband, wifi100!);
      } else if (currentBroadband.sku === fibreStarterBoost?.sku) {
        swapBroadbandPackage(currentBroadband, fibreStarter!);
      }
    }
  };

  const swapRouterServices = (isByod: boolean) => {
    if (isByod) {
      applyProduct(broadbandByod!.id, 1);
      applyProduct(broadbandSelfInstallation!.id, 0);
      applyProduct(selfInstallOTC!.id, 0);
    } else {
      applyProduct(broadbandByod!.id, 0);
      applyProduct(broadbandSelfInstallation!.id, 1);
      applyProduct(selfInstallOTC!.id, 1);
    }
  };

  const toggleMeshWithProductSwap = async (mesh: T_Product, update: boolean = true) => {
    await toggleProduct(mesh, false, undefined, undefined, true);

    const products = productStoreApi.getState().products;
    const meshInCart = products.some(p => p.sku === meshDevice?.sku && p.quantityInCart > 0);

    if (meshInCart) {
      swapToBoostPlan(true);
    } else {
      swapToBoostPlan(false);
    }

    if (customerId && update) {
      await updateOrder(
        kkService,
        customerId,
        productStoreApi.getState().getBasketItemsToAddToOrder(),
        couponStoreApi.getState().coupons
      );
    }
  };

  const removeMesh = async (mesh: T_Product) => {
    await toggleProduct(mesh, false, undefined, undefined, true);
    swapToBoostPlan(false);

    if (customerId) {
      await updateOrder(
        kkService,
        customerId,
        productStoreApi.getState().getBasketItemsToAddToOrder(),
        couponStoreApi.getState().coupons
      );
    }
  };

  const toggleRouterWithInstallSwap = async (router: T_Product) => {
    if (router.quantityInCart > 0) {
      // router already in cart, now where toggling it off, swap self-install with byod
      swapRouterServices(true);
      if (meshDevice && meshDevice.quantityInCart > 0) {
        await toggleMeshWithProductSwap(meshDevice, false);
      }
    } else {
      swapRouterServices(false);
    }

    return toggleProduct(router);
  };

  return {
    toggleMeshWithProductSwap,
    toggleRouterWithInstallSwap,
    broadbandProductInCart,
    broadbandSelfInstallation,
    swapToBoostPlan,
    swapRouterServices,
    routerAdded,
    meshAdded,
    meshDevice,
    currentBroadband,
    removeMesh,
  };
}

export { useBroadbandSwapProduct };
