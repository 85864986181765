import cx from 'classnames';
import React from 'react';
import { Field, Form } from 'react-jeff';

import { TextArea, TextAreaProps, TextInput, TextProps } from '../input';

interface IValidationTextProps extends TextProps {
  fieldValidation: Field<string>;
  formValidation: Form<string>;
}

const ValidatedTextInput = ({
  onChange,
  className,
  fieldValidation,
  formValidation,
  ...props
}: IValidationTextProps) => {
  const errorPresent = fieldValidation.errors.length > 0;
  const inputClassName = cx(className, { 'sky-input--error': errorPresent });
  const errorClassName = cx(className, { 'text-error': errorPresent });

  return (
    <>
      <TextInput className={inputClassName} {...fieldValidation.props} {...props} onChange={onChange} />
      {errorPresent && <p className={errorClassName}>{fieldValidation.errors[0]}</p>}
    </>
  );
};

interface IValidationTextAreaProps extends TextAreaProps {
  fieldValidation: Field<string>;
  formValidation: Form<string>;
}
const ValidatedTextArea = ({
  onChange,
  className,
  fieldValidation,
  formValidation,
  ...props
}: IValidationTextAreaProps) => {
  const errorPresent = fieldValidation.errors.length > 0;
  const inputClassName = cx(className, { 'sky-input--error': errorPresent });
  const errorClassName = cx(className, { 'text-error': errorPresent });

  return (
    <>
      <TextArea className={inputClassName} {...fieldValidation.props} {...props} onChange={onChange} />
      {errorPresent && <p className={errorClassName}>{fieldValidation.errors[0]}</p>}
    </>
  );
};

export { ValidatedTextInput, ValidatedTextArea };
