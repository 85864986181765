import { useEffect, useState } from 'react';

export enum ScrollDirection {
  DOWN = 'down',
  UP = 'up',
}

type ScrollDirectionType = ScrollDirection | null;

export const useScrollDirection = (listeningStatus: boolean = true, scrollYOffet: number = 1): ScrollDirectionType => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirectionType>(null);
  useEffect(() => {
    if (!listeningStatus) return;
    let lastScrollY = window.pageYOffset;
    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? ScrollDirection.DOWN : ScrollDirection.UP;
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > scrollYOffet || scrollY - lastScrollY < -scrollYOffet)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection, listeningStatus]);
  return scrollDirection;
};
