import { useEffect } from 'react';
import { IceWebTitle } from '../types';

export const useDocumentTitle = (pageTitle?: string) => {
  useEffect(() => {
    if (pageTitle) document.title = pageTitle;
    return () => {
      // Change title to default IceWeb page title
      document.title = IceWebTitle;
    };
  }, [pageTitle]);
};
