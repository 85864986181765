import React, { useEffect, useReducer, useState } from 'react';
import { Unchecked, Checked, Button, Loader, ConditionsCheckbox } from '@sky-tv-group/components';
import { categoryIds, couponCodes, couponStoreApi, productSkuIds, productStoreApi, T_OrderRequest, useCustomerStore, useNewAcquisitionOrderStore, useOrderStore, useProductStore } from '@sky-tv-group/shared';
import { konakartService } from '../../services';
import { useHistory } from 'react-router-dom';
import { MoreChoicesForYouProduct } from './CardPlanSelector';
import { caret } from '@sky-tv-group/components';
import { segment } from '@sky-tv-group/shared';
import { SkyCustomerYesNoOption,YesNoValueType } from '../../types';

const Broadband = "Broadband";
const tvDevices = "TV Devices";
const tv = "TV";
const recording = "Recording";
const oneOffCharges = "One-off Charges";
interface Props {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isBBAdded: boolean;
    selectedBBProduct: any;
    planArr: MoreChoicesForYouProduct[];
    isBBInCart: any
}
interface State {
    isWifiRouter: boolean;
    isWifiBoost?: boolean;
    isSuperTech?: boolean;
    isStaticIP?: boolean;
    isHomeplan?: boolean;
    isUnlimitedNZCalls?: boolean;
    isUnlimitedAustralianCalls?: boolean;
    isTop10Destinamtion?: boolean;
    isTop20Destinamtion?: boolean;
    isValidateWifiBoost?:boolean;
}

// Define action types
type Action =
    | { type: 'toggleWifiRouter' }
    | { type: 'toggleWifiBoost' }
    | { type: 'toggleSuperTech' }
    | { type: 'toggleStaticIP' }
    | { type: 'toggleHomeplan' }
    | { type: 'toggleUnlimitedNZCalls' }
    | { type: 'toggleUnlimitedAustralianCalls' }
    | { type: 'toggleTop10Destinamtion' }
    | { type: 'toggleTop20Destinamtion' }
    | {type: 'toggleValidateIsWifiBoost'}


const Addons = ({ setIsOpen, isBBAdded, selectedBBProduct, planArr, isBBInCart }: Props) => {

    const initialState: State = {
        isWifiRouter: true,
        isWifiBoost: false,
        isSuperTech: false,
        isStaticIP: false,
        isHomeplan: false,
        isUnlimitedNZCalls: false,
        isUnlimitedAustralianCalls: false,
        isTop10Destinamtion: false,
        isTop20Destinamtion: false,
    };
    const kkProducts = productStoreApi.getState().products;
    const wifiRouter = kkProducts.find(p => p.sku === productSkuIds.skyRouter.primary);
    const wifiBooster = kkProducts.find(p => p.sku === productSkuIds.meshDevice.primary);
    const superTechnician = kkProducts.find(p => p.sku === productSkuIds.broadbandPerfectInstall.primary);
    const staticIP = kkProducts.find(p => p.sku === productSkuIds.broadbandStaticIP.primary);
    const homePhonePlan = kkProducts.find(p => p.sku === productSkuIds.voice.primary);
    const unlimitedPhone = kkProducts.find(p => p.sku === productSkuIds.voiceNationalCalls.primary);
    const unlimitedAustraliancalls = kkProducts.find(p => p.sku === productSkuIds.voiceAustraliaCalls.primary);
    const top10Destination = kkProducts.find(p => p.sku === productSkuIds.voiceTop10Calls.primary);
    const top20Destination = kkProducts.find(p => p.sku === productSkuIds.voiceTop20Calls.primary);
    const fibreStarterBoost = kkProducts.find(p => p.sku === productSkuIds.broadbandEssnFibBoost.primary);
    const fibreEverydayBoost = kkProducts.find(p => p.sku === productSkuIds.broadbandWifi100Boost.primary);
    const fibreProBoost = kkProducts.find(p => p.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary);
    const bbBoostPlans = [fibreStarterBoost, fibreEverydayBoost, fibreProBoost];
    const broadbandSelfInstall = kkProducts.find(p => p.sku === productSkuIds.broadbandSelfInstall.primary);
    const broadbandSelfInstallOTC = kkProducts.find(p=> p.sku === productSkuIds.broadbandSelfInstallOTC.primary);
    const bbSelfInstallOTC =[broadbandSelfInstall,broadbandSelfInstallOTC];
    const byod = kkProducts.find(p=> p.sku === productSkuIds.broadbandBringYourOwnDevice.primary);


    const history = useHistory();

    const { changeProductQuantity } = useProductStore(s => ({
        changeProductQuantity: s.changeProductQuantity,
    }));




    let BBAddOnPayload: T_OrderRequest[] | undefined = [];

    const selectedAddOns = {
        productId: 0,
        quantity: 1,
        currentlySubscribed: false,
        downgrade: false,
        finalPriceIncTax: 0,
        inPromotion: false,
    }
    const { kk: customerId } = useCustomerStore();
    const { updateOrder, order } = useOrderStore();
    const [isloading, setIsLoading] = useState(false);
    const [isRemoveLoading, setIsRemoveLoading] = useState(false);
    const [isDiscard, setIsDiscard] = useState(false);

    // cutom 9 field for booster bb plans sku
    const isCustomize = isBBInCart?.product?.custom9 ? isBBInCart?.product?.custom9 === selectedBBProduct?.kkProduct?.sku : isBBInCart?.product?.sku === selectedBBProduct?.kkProduct?.sku

    const reducer = (state: State, action: Action): State => {

        switch (action.type) {
            case 'toggleWifiRouter':
                const newIsWifiRouter = !state.isWifiRouter;
                const newIsWifiBoost = state.isWifiBoost;

                return {
                    ...state,
                    isWifiRouter: newIsWifiRouter,
                    isWifiBoost: newIsWifiRouter ? newIsWifiBoost : false, // Reset boost if router is toggled off
                    isValidateWifiBoost: newIsWifiBoost === false ? false :  newIsWifiRouter ? !state.isValidateWifiBoost : state.isValidateWifiBoost,
                };
            case 'toggleWifiBoost':
                const newBoostState = !state.isWifiBoost;

                return {
                    ...state,
                    isWifiBoost: newBoostState,
                    isValidateWifiBoost: state.isWifiRouter && newBoostState  ? !state.isValidateWifiBoost : false,
                };
            case 'toggleSuperTech':
                return { ...state, isSuperTech: !state.isSuperTech };
            case 'toggleStaticIP':
                return { ...state, isStaticIP: !state.isStaticIP };
            case 'toggleHomeplan':
                return { ...state, isHomeplan: !state.isHomeplan };
            case 'toggleUnlimitedNZCalls':
                return { ...state, isUnlimitedNZCalls: !state.isUnlimitedNZCalls };
            case 'toggleUnlimitedAustralianCalls':
                return { ...state, isUnlimitedAustralianCalls: !state.isUnlimitedAustralianCalls };
            case 'toggleTop10Destinamtion':
                return { ...state, isTop10Destinamtion: !state.isTop10Destinamtion };
            case 'toggleTop20Destinamtion':
                return { ...state, isTop20Destinamtion: !state.isTop20Destinamtion };
            default:
                return state;
        }
    };

    const { customerOption, setCustomerOption } = useNewAcquisitionOrderStore(s => ({
        setCustomerOption: (value: YesNoValueType) => s.setPersistedButtonStates('vulnerableCustomerInfo', value),
        customerOption: s.persistedButtonStates.vulnerableCustomerInfo,
    }));

    const [state, dispatch] = useReducer(reducer, initialState);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [isWifiTruncate, setIsWifiTruncate] = useState(true);
    const [isSuperTechTruncate, setIsSuperTechTruncate] = useState(true);
    const [isStaticIPTruncate, setIsStaticIPTruncate] = useState(true);

    const truncateRouterWifiText = "Our Sky WiFi Routers use the latest tech (that’s WiFi 6 if you like letters and numbers) and give you coverage.."
    const truncateSuperTech = "Book a super set up technician to get you up and running smoothly in your home. They’ll get all of your home devices connecte..."
    const truncateStaticIP = "You can get a static IP to keep your connection IP address the same every time you connect to the internet. Some households nee..."


    useEffect(() => {
        const orderProducts = order?.orderProducts;
        const homeplan = orderProducts?.some(p => p.product.sku === productSkuIds.voice.primary);
        // if (isCustomize) {
            // orderProducts?.some(p => p.product.sku === productSkuIds.skyRouter.primary) && dispatch({ type: "toggleWifiRouter" });
            orderProducts?.some(p => p.product.sku === productSkuIds.meshDevice.primary) && dispatch({ type: "toggleWifiBoost" });
            orderProducts?.some(p => p.product.sku === productSkuIds.broadbandPerfectInstall.primary) && dispatch({ type: "toggleSuperTech" });
            orderProducts?.some(p => p.product.sku === productSkuIds.broadbandStaticIP.primary) && dispatch({ type: "toggleStaticIP" });;
            homeplan && dispatch({ type: "toggleHomeplan" });
            orderProducts?.some(p => p.product.sku === productSkuIds.voiceNationalCalls.primary) && dispatch({ type: "toggleUnlimitedNZCalls" });
            orderProducts?.some(p => p.product.sku === productSkuIds.voiceAustraliaCalls.primary) && dispatch({ type: "toggleUnlimitedAustralianCalls" });
            orderProducts?.some(p => p.product.sku === productSkuIds.voiceTop10Calls.primary) && dispatch({ type: "toggleTop10Destinamtion" });
            orderProducts?.some(p => p.product.sku === productSkuIds.voiceTop20Calls.primary) && dispatch({ type: "toggleTop20Destinamtion" });
            homeplan && customerOption === SkyCustomerYesNoOption.YES && setChecked(true);
        // }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);

    const bbpayLoad = {
        productId: selectedBBProduct?.kkProduct?.id,
        quantity: 1,
        finalPriceIncTax: selectedBBProduct?.kkProduct?.price0,
        downgrade: false,
        currentlySubscribed: false,
        inPromotion: false,
    }



    let isProdinCart = productStoreApi.getState().getBasketItemsToAddToOrder();
    const bbOrderProduts = order?.orderProducts?.filter(p => p.categoryId === categoryIds?.broadband || p.categoryId === categoryIds?.broadbandDevices || p.categoryId === categoryIds?.broadbandTechnicianFee || p.categoryId === categoryIds.voice || p.categoryId === categoryIds.voiceAddons || p.categoryId === categoryIds.voiceCrossCountry  || p.categoryId === categoryIds.broadbandOneOffFee || p.categoryId === categoryIds.broadbandServices);
    const addOnPayload = (addonParams: any, isAddon: boolean) => {
        const addonInCart = isProdinCart?.findIndex(p => p.productId === addonParams.id);
        const addOnCart = isProdinCart?.find(p => p.productId === addonParams.id);
        const addOnProduct = BBAddOnPayload?.findIndex(p => p.productId === addonParams?.id)
        if (isAddon) {
            if (addonInCart !== -1) {
                changeProductQuantity(addOnCart?.productId!, -1, false);
                isProdinCart.splice(addonInCart!, 1)
            }
            if (addOnProduct === -1) BBAddOnPayload?.push({ ...selectedAddOns, productId: addonParams?.id!, finalPriceIncTax: addonParams?.price0! })
        }
        else {
            if (addonInCart !== -1) {
                changeProductQuantity(addOnCart?.productId!, -1, false);
                isProdinCart.splice(addonInCart!, 1)
            }
            if (addOnProduct !== -1) BBAddOnPayload?.splice(addOnProduct!, 1);
        }
    }

    const goNext = async () => {
        const isBBAvailable = BBAddOnPayload?.some(p => p.productId === bbpayLoad.productId);

        const bbProdIds = planArr?.filter((p: any) => isProdinCart.some(id => id.productId === p.kkProduct.id));
        const bbBoostPlansId = bbBoostPlans?.filter((p: any) => isProdinCart.some(id => id?.productId === p?.id));


        // switching BB plans removing existing bb in cart logic
        if (bbProdIds && bbProdIds.length > 0) {
            bbProdIds?.forEach((bbProduct: any) => {
                const BBInCart = isProdinCart?.find(p => p.productId === bbProduct?.kkProduct?.id)
                const indexBBInCart = isProdinCart?.findIndex(p => p.productId === bbProduct?.kkProduct?.id)
                if (indexBBInCart !== -1) {
                    changeProductQuantity(BBInCart?.productId!, -1, false);
                    isProdinCart?.splice(indexBBInCart!, 1);
                }
            });
        }

        //switching BB boost plans removing existing bb in cart logic
        if (bbBoostPlansId && bbBoostPlansId.length > 0) {
            bbBoostPlansId?.forEach((bbProduct: any) => {
                const BBInCart = isProdinCart?.find(p => p.productId === bbProduct?.id)
                const indexBBInCart = isProdinCart?.findIndex(p => p.productId === bbProduct?.id)
                if (indexBBInCart !== -1) {
                    changeProductQuantity(BBInCart?.productId!, -1, false);
                    isProdinCart?.splice(indexBBInCart!, 1);
                }
            });

        }
        const isBBExist = isProdinCart.some(p => p.productId === bbpayLoad.productId);

        if (!isBBAvailable && !isBBExist && !state.isWifiBoost) {
            BBAddOnPayload?.push(bbpayLoad);
        } else {
            const bbBoostPayload = bbBoostPlans?.find(p => p?.custom9 === selectedBBProduct?.kkProduct?.sku);
            if (bbBoostPayload) BBAddOnPayload?.push({ ...bbpayLoad, productId: bbBoostPayload.id, finalPriceIncTax: bbBoostPayload.price0 })
        }

        addOnPayload(wifiRouter, state.isWifiRouter);
        addOnPayload(wifiBooster, state?.isWifiBoost!);
        addOnPayload(superTechnician, state?.isSuperTech!);
        addOnPayload(staticIP, state?.isStaticIP!);
        addOnPayload(homePhonePlan, state?.isHomeplan!);
        addOnPayload(unlimitedPhone, state?.isUnlimitedNZCalls!);
        addOnPayload(unlimitedAustraliancalls, state?.isUnlimitedAustralianCalls!);
        addOnPayload(top10Destination, state?.isTop10Destinamtion!);
        addOnPayload(top20Destination, state?.isTop20Destinamtion!);

        if(state.isWifiRouter === false){
            addOnPayload(byod, false);
            BBAddOnPayload?.push({...selectedAddOns, productId:byod?.id!, finalPriceIncTax:byod?.price0!})
        }else{
            addOnPayload(byod, false);
        }

        //self intall and otc for bb need to be added
        if(bbSelfInstallOTC && bbSelfInstallOTC.length>0){
            bbSelfInstallOTC?.forEach((otcProduct:any) =>{
                const SelforOTcBBInCart = isProdinCart?.find(p => p.productId === otcProduct?.id!);
                const indexSelforOTcBBInCart = isProdinCart?.findIndex(p => p.productId === otcProduct?.id!);
                if(indexSelforOTcBBInCart !== -1){
                    changeProductQuantity(SelforOTcBBInCart?.productId!, -1, false);
                    isProdinCart?.splice(indexSelforOTcBBInCart!, 1);
                }

                if(state.isWifiRouter){
                        BBAddOnPayload?.push({...selectedAddOns, productId:otcProduct?.id!, finalPriceIncTax:otcProduct?.price0!});
                }

            })

        }

        const cartProducts = BBAddOnPayload && BBAddOnPayload?.length > 0 ? isProdinCart.concat(BBAddOnPayload) : isProdinCart;

        try {
            document.body.style.pointerEvents="none"
            const coupon = await konakartService.getCoupon(couponCodes.bundlecoupon_BB);
            if (coupon) couponStoreApi.getState().applyCouponToStore(coupon);
            setIsLoading(true);
            await updateOrder(
                konakartService,
                customerId,
                cartProducts,
                couponStoreApi.getState().coupons
            );
            const products = productStoreApi.getState().products;
            const bbProducts = products.map(prod=>{
                  const isBBProd = cartProducts.find(p => p.productId === prod.id && (prod.categoryId === categoryIds?.broadband || prod.categoryId === categoryIds?.broadbandDevices || prod.categoryId === categoryIds?.broadbandTechnicianFee || prod.categoryId === categoryIds.voice || prod.categoryId === categoryIds.voiceAddons || prod.categoryId === categoryIds.voiceCrossCountry  || prod.categoryId === categoryIds.broadbandOneOffFee || prod.categoryId === categoryIds.package || prod.categoryId === categoryIds.packageUpgrade || prod.categoryId === categoryIds?.oneOffFee || prod.categoryId === categoryIds?.box));
                  if(isBBProd){
                    return prod;
                  }
            });
            const prods = bbProducts.filter(p => p !== undefined);
            if(prods){
                const addTocartProducts =  prods?.map(e=>{
                     if(e.categoryId === categoryIds?.broadband){
                         return {category:Broadband, name: e.name, price: e.priceIncTax};
                     }else if(e.categoryId === categoryIds?.broadbandDevices){
                         return {category:Broadband, name: e.name, price: e.priceIncTax};
                     }else if(e.categoryId === categoryIds?.broadbandTechnicianFee){
                         return {category:oneOffCharges, name: e.name, price: e.priceIncTax};
                     }else if(e.categoryId === categoryIds?.voiceAddons){
                         return {category:Broadband, name: e.name, price: e.priceIncTax};
                     }else if(e.categoryId === categoryIds?.voice){
                         return {category:Broadband, name: e.name, price: e.priceIncTax};
                     }else if(e.categoryId === categoryIds?.voiceCrossCountry){
                         return {category:Broadband, name: e.name, price: e.price0};
                     }else if(e.categoryId === categoryIds?.oneOffFee){
                         return {category:oneOffCharges, name: e.name, price: e.priceIncTax};
                     }else if(e.categoryId === categoryIds?.packageUpgrade){
                         return {category:tv, name: e.name, price: e.priceIncTax};
                     }else if(e.categoryId === categoryIds?.package){
                         return {category:tv, name: e.name, price: e.priceIncTax};
                     }
                  /*   else if(e.categoryId === categoryIds?.recording){
                            return {category:recording, name: (e.name === "Video On Demand"?"Multiroom Charge":e.name), price: (e.name === "Video On Demand" ? 25 : e.priceIncTax)};
                    }*/else if(e.categoryId === categoryIds?.box){
                         return {category:tvDevices, name: e.name, price: e.priceIncTax};
                     }
                 });
                 const finalCartItems=addTocartProducts.filter(Boolean);
                 const isUpgrade=true;
                 segment.BBCheckout(finalCartItems,isUpgrade);
                }
            history.push("/build-my-plan/checkout");
        } catch (error) {
            console.log(error);
        } finally {
            document.body.style.pointerEvents="all"
            setIsLoading(false);
            setIsOpen(false);
        }

    };

    const removeBroadbandPlan = async () => {

        if (bbOrderProduts && bbOrderProduts?.length > 0) {
            bbOrderProduts?.forEach((bbProduct: any) => {
                const BBInCart = isProdinCart?.find(p => p.productId === bbProduct?.productId)
                const indexBBInCart = isProdinCart?.findIndex(p => p.productId === bbProduct?.productId)
                if (indexBBInCart !== -1) {
                    changeProductQuantity(BBInCart?.productId!, -1, false);
                    isProdinCart?.splice(indexBBInCart!, 1);
                }
            });
            const removeBBProduct = order?.orderProducts?.find(p => p.categoryId === categoryIds?.broadband);
            const isUpgrade = true;
            const isBBAddedForGA = false;
            segment?.bbProductAddOrRemove(removeBBProduct?.product,isUpgrade,1,removeBBProduct?.price,isBBAddedForGA);
        }

        try {
            document.body.style.pointerEvents="none"
            couponStoreApi.getState().clearCouponFromCode(couponCodes.bundlecoupon_BB);
            setIsRemoveLoading(true);
            await updateOrder(
                konakartService,
                customerId,
                isProdinCart,
                couponStoreApi.getState().coupons
            );

        } catch (error) {
            console.log(error);
        } finally {
            document.body.style.pointerEvents="all"
            setIsRemoveLoading(false);
            setIsOpen(false);
        }
    }

    const onChangeDispatch = (type: State | any) => {
        dispatch({ type: type });
        if (isCustomize) {
            setIsDiscard(true);
        }
    }

    const cancel = () => {
        setIsOpen(false)
    };

    const [showWholeMessage, setShowWholeMessage] = useState(false);
    const toggleShowWholeMessage = () => {
        setShowWholeMessage(!showWholeMessage);
    };
    const messagePreview = 'Fibre services including home phones do not work in a power cut.  ';
    const restOfTheMessage =
        'During a power cut, your home phone and devices like cordless phones, medical alarms, etc may not work even with a battery and you will not be able to make calls including to 111 emergency services. You will need another way to make calls and contact 111 emergency services such as a charged mobile device or power back-up device.';

    const [checked, setChecked] = useState(false);
    const toggleChecked = () => {
        setChecked(!checked);
        setCustomerOption(!checked?SkyCustomerYesNoOption.YES:SkyCustomerYesNoOption.NO);
    };

    return (
        <div className='p-4'>

            <div className="md:container mx-auto pt-4">
                <div className="sky-text-midnight mx-3 md:mx-0" onClick={toggleShowWholeMessage}>
                    <div className="cursor-pointer rounded-lg flex w-full p-4 mb-4">
                        <div className="w-11/12 p-3 flex items-center text-15px">
                            <div>
                                <span className="sky-text-red font-bold">IMPORTANT: </span>
                                <span className='font-bold'>{ messagePreview} </span>
                                <div className=''>{showWholeMessage && restOfTheMessage}</div>
                            </div>
                        </div>
                        <div className="w-1/12 py-3 md:p-3 text-15px">
                            <span className={`w-8 h-8 flex justify-center items-center ml-12 ${showWholeMessage ? 'transform -rotate-180 -translate-y-1/2' : ''}`}>
                                {caret}
                            </span>
                        </div>
                    </div></div></div>


            <hr className='mt-6 mb-6' />
            <div className="sky-h5-black md:sky-h5-black mb-6"> Fibre Add Ons:</div>

            <div className="md:flex flex-col items-start gap-2  flex-grow">
                <h2 className="sky-h5-black md:sky-h5-black ">Sky WiFi 6 Router</h2>
                <div className="flex">
                    {isMobile && isWifiTruncate
                        ?
                        <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">{truncateRouterWifiText} <span className='underline text-blue-pure font-bold' onClick={() => setIsWifiTruncate(!isWifiTruncate)}>show more</span></p>
                        :
                        <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">Our Sky WiFi Routers use the latest tech (that’s WiFi 6 if you like letters and numbers) and give you coverage from the lounge room to the throne room in an average sized kiwi home (less than 200m2). We recommend you use our Sky WiFi Routers as they are 30% gruntier than routers that use WiFi 1 to 5!</p>
                    }
                </div>
                <div className="flex flex-row items-start gap-8 pt-4 min-w-full">
                <div className={` flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative  ${state.isValidateWifiBoost && !state.isWifiRouter? 'border-red': 'border-gray-dark hover:border-gray-copy' }`}>
                        <button className="" style={{ marginTop: "10px", marginRight: "10px" }} onClick={() =>{
                            onChangeDispatch('toggleWifiRouter')
                        }}>
                            {state.isWifiRouter ? <Checked /> : <Unchecked />}
                        </button>
                        <div className="flex flex-col px-4 pt-3 items-start gap-2">
                            <p>Use Sky WiFi 6 Router</p>
                            <div className="text-black "><small className="sky-sub align-top font-bold" style={{ fontSize: "10px" }}>$</small><span className="sky-h5-bold inline-block text-5xl font-black relative " style={{ top: "-0.5rem" }}>{wifiRouter?.price0}</span><small className="align-top sky-sub">.00</small><small className="relative  sky-sub" style={{ left: "-1.25rem", top: "-0.5rem" }}>/mth</small></div>
                        </div>
                    </div>
                </div>
                {state.isWifiRouter ? <div className='md:w-full underline text-center font-bold text-base cursor-pointer' style={{ color: "#0057FF" }} onClick={() => onChangeDispatch('toggleWifiRouter')}>Remove</div> : ""}
            </div>
            <hr className='mt-6 mb-6' />
            <div className="md:flex flex-col items-start gap-2  flex-grow">
                <h2 className="sky-h5-black md:sky-h5-black ">Broadband Booster Plan</h2>
                {!state.isWifiBoost && !state.isValidateWifiBoost && <div className="flex">
                    <p className="sky-h7-reg md:sky-h7-reg py-2 sky-bg-gray-light px-10 rounded-lg text-sm">
                        <div><div className='flex items-center'   >
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9994 29.7143C23.5736 29.7143 29.7137 23.5742 29.7137 16.0001C29.7137 8.42586 23.5736 2.28577 15.9994 2.28577C8.42525 2.28577 2.28516 8.42586 2.28516 16.0001C2.28516 23.5742 8.42525 29.7143 15.9994 29.7143ZM15.9994 10.2858C16.6306 10.2858 17.1423 9.77409 17.1423 9.14291C17.1423 8.51173 16.6306 8.00005 15.9994 8.00005C15.3683 8.00005 14.8566 8.51173 14.8566 9.14291C14.8566 9.77409 15.3683 10.2858 15.9994 10.2858ZM15.9994 12.5715C15.3683 12.5715 14.8566 13.0832 14.8566 13.7143V22.8572C14.8566 23.4884 15.3683 24.0001 15.9994 24.0001C16.6306 24.0001 17.1423 23.4884 17.1423 22.8572V13.7143C17.1423 13.0832 16.6306 12.5715 15.9994 12.5715Z" fill="#00013A" />
                            </svg>
                            <p className="ml-4 py-4">Do you need a booster?</p> </div><p className="sky-h6-reg">Live in a palace &amp; know your WiFi needs a boost, or unsure and your home meets any of the following conditions, then you may need an extra boost for just $10/mth.</p>
                            <div className="flex gap-7 py-4  flex-col sky-h6-reg md:flex-col"><div className="flex md:flex-row items-center">
                                <img src="/images/2ormore.png" alt="2ormore" />
                                <span style={{ margin: "0 0 0 8px" }}> 2 or more levels</span></div><div className="flex md:flex-row items-center">
                                    <img src="/images/4bedrooms.png" alt="4bedrooms" /><span style={{ margin: " 0.5rem 0px 0px 10px" }}>4+ bedrooms</span></div>
                                <div className="flex md:flex-row items-center">
                                    <img src="/images/loungerooms.png" alt="loungerooms" /><span style={{ margin: "0.5rem 0px 0px 10px" }}>2 or more lounge rooms</span>
                                </div>
                            </div>


                        </div>
                    </p>
                </div>}
                {state.isWifiRouter && state.isWifiBoost &&  <div className="mt-4 rounded-md border-l-6 border-blue-light bg-blue-cool inline-block px-40px py-4 sky-h6-reg">
                    One booster should do the trick, but if you find you need more coverage give us a call on <span><a href="tel:09 525 5555" className='font-bold underline'>09 525 5555</a></span>
                </div>}
                {state.isValidateWifiBoost && !state.isWifiBoost && <div className="mt-2 text-red text-base">
                    #You must select wifi 6 router to add a booster
                </div>}
                <div className="flex flex-row items-start gap-8 pt-1 min-w-full">
                    <div className={` flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative  ${state.isValidateWifiBoost && !state.isWifiBoost? 'border-red': 'border-gray-dark hover:border-gray-copy' }`}>
                        <button className="" style={{ marginTop: "10px", marginRight: "10px" }}
                         disabled={state.isValidateWifiBoost && !state.isWifiBoost || !state.isWifiRouter}
                         onClick={() => {
                            onChangeDispatch('toggleWifiBoost')
                            }}>
                            {state.isWifiBoost ? <Checked /> : <Unchecked />}
                        </button>
                        <div className="flex flex-col px-4 pt-3 items-start gap-2">
                            <p >Add a Booster</p>
                            <div className="text-black "><small className="sky-sub align-top font-bold" style={{ fontSize: "10px" }}>$</small><span className="sky-h5-bold inline-block text-5xl font-black relative " style={{ top: "-0.5rem" }}>{wifiBooster?.price0}</span><small className="align-top sky-sub">.00</small><small className="relative  sky-sub" style={{ left: "-1.25rem", top: "-0.5rem" }}>/mth</small></div>
                        </div>
                    </div>
                </div>
                {state.isWifiBoost ? <div className='md:w-full underline text-center font-bold text-base cursor-pointer' style={{ color: "#0057FF" }} onClick={() => onChangeDispatch('toggleWifiBoost')}>Remove</div> : ""}
            </div>
            <hr className='mt-6 mb-6' />
            <div className="md:flex flex-col items-start gap-2  flex-grow">
                <h2 className="sky-h5-black md:sky-h5-black ">Super Set Up Technician</h2>
                <div className="flex">
                    {isMobile && isSuperTechTruncate
                        ?
                        <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">{truncateSuperTech} <span className='underline text-blue-pure font-bold' onClick={() => setIsSuperTechTruncate(!isSuperTechTruncate)}>show more</span></p>
                        :
                        <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">Book a super set up technician to get you up and running smoothly in your home. They’ll get all of your home devices connected to the network and even personalise your Sky Broadband network name and password to one you'll remember! You won't need to lift a finger! They're uber busy, so if you need to reschedule or cancel a visit within 24 hours of your appointment time, or miss it altogether, there'll be a $150 charge. </p>
                    }
                </div>
                <div className="flex flex-row items-start gap-8 pt-4 min-w-full">
                    <div className=" flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative hover:border-gray-copy border-gray-dark">
                        <button className="" style={{ marginTop: "10px", marginRight: "10px" }} onClick={() => onChangeDispatch('toggleSuperTech')}>
                            {state.isSuperTech ? <Checked /> : <Unchecked />}
                        </button>
                        <div className="flex flex-col px-4 pt-3 items-start gap-2">
                            <p >I want a Super Set Up Technician</p>
                            <div className="text-black "><small className="sky-sub align-top font-bold" style={{ fontSize: "10px" }}>$</small><span className="sky-h5-bold inline-block text-5xl font-black relative " style={{ top: "-0.5rem" }}>{superTechnician?.price0}</span><small className="align-top sky-sub">.00</small><small className="relative  sky-sub" style={{ left: "-1.25rem", top: "-0.5rem" }}>/one-off charge</small></div>
                        </div>
                    </div>
                </div>
                {state.isSuperTech ? <div className='md:w-full underline text-center font-bold text-base cursor-pointer' style={{ color: "#0057FF" }} onClick={() => onChangeDispatch('toggleSuperTech')}>Remove</div> : ""}
            </div>
            <hr className='mt-6 mb-6' />
            <div className="md:flex flex-col items-start gap-2  flex-grow">
                <h2 className="sky-h5-black md:sky-h5-black ">Static IP</h2>
                <div className="flex">
                    {isMobile && isStaticIPTruncate
                        ?
                        <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">{truncateStaticIP} <span className='underline text-blue-pure font-bold' onClick={() => setIsStaticIPTruncate(!isStaticIPTruncate)}>show more</span></p>

                        :
                        <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">You can get a static IP to keep your connection IP address the same every time you connect to the internet. Some households need this for things like hosting online games or accessing home devices remotely. </p>

                    }
                </div>
                <div className="flex flex-row items-start gap-8 pt-4 min-w-full">
                    <div className=" flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative hover:border-gray-copy border-gray-dark">
                        <button className="" style={{ marginTop: "10px", marginRight: "10px" }} onClick={() => onChangeDispatch('toggleStaticIP')}>
                            {state.isStaticIP ? <Checked /> : <Unchecked />}
                        </button>
                        <div className="flex flex-col px-4 pt-3 items-start gap-2">
                            <p >Add a Static IP</p>
                            <div className="text-black "><small className="sky-sub align-top font-bold" style={{ fontSize: "10px" }}>$</small><span className="sky-h5-bold inline-block text-5xl font-black relative " style={{ top: "-0.5rem" }}>{staticIP?.price0}</span><small className="align-top sky-sub">.00</small><small className="relative  sky-sub" style={{ left: "-1.25rem", top: "-0.5rem" }}>/mth</small></div>
                        </div>
                    </div>
                </div>
                {state.isStaticIP ? <div className='md:w-full underline text-center font-bold text-base cursor-pointer' style={{ color: "#0057FF" }} onClick={() => onChangeDispatch('toggleStaticIP')}>Remove</div> : ""}
            </div>
            <hr className='mt-6 mb-6' />
            <div className="md:flex flex-col items-start gap-2  flex-grow">
                <h2 className="sky-h5-black md:sky-h5-black ">Home Phone Plan</h2>
                <div className="flex">
                    <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">Enjoy unlimited local home phone calls, voicemail, call waiting and caller ID features with a Sky Home Phone plan. You can even bring your existing number over too if you like.</p>
                </div>
                <div className="flex flex-row items-start gap-8 pt-4 min-w-full">
                    <div className=" flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative hover:border-gray-copy border-gray-dark">
                        <button className="" style={{ marginTop: "10px", marginRight: "10px" }} onClick={() => onChangeDispatch('toggleHomeplan')}>
                            {state.isHomeplan ? <Checked /> : <Unchecked />}
                        </button>
                        <div className="flex flex-col px-4 pt-3 items-start gap-2">
                            <p >Add a Home Phone Plan</p>
                            <div className="text-black "><small className="sky-sub align-top font-bold" style={{ fontSize: "10px" }}>$</small><span className="sky-h5-bold inline-block text-5xl font-black relative " style={{ top: "-0.5rem" }}>{homePhonePlan?.price0}</span><small className="align-top sky-sub">.00</small><small className="relative  sky-sub" style={{ left: "-1.25rem", top: "-0.5rem" }}>/mth</small></div>
                        </div>
                    </div>
                </div>
                {state.isHomeplan ? <div className='md:w-full underline text-center font-bold text-base cursor-pointer' style={{ color: "#0057FF" }} onClick={() => onChangeDispatch('toggleHomeplan')}>Remove</div> : ""}
            </div>
            <hr className='mt-6 mb-6' />
            {state.isHomeplan && <div id='homeChildCompo' className='mb-6'>
                <div className="sky-bg-gray-lightest p-6 my-6"><span className="sky-h7-reg sky-text-red text-bold">Vulnerable Customers: </span><span className="sky-h7-reg">Are you, or someone in your household, a 111 Vulnerable Consumer? If you are it’s important to understand that fibre services, including your Sky Home Phone, will not work in a power cut. So, you’ll need an alternative way to contact 111 emergency services. This could be having a charged mobile phone or using a power back-up device. Under the Commerce Commission’s 111 Contact Code, it’s our responsibility to provide extra support to any people who qualify, whether it be for health, disability or safety reasons. We’re here to help, so whatever your situation, we’ll happily provide you with our Sky Home Phone service and if there happens to be a power cut, we’ll offer additional support to help you access 111 emergency services.</span></div>

                <div className="flex">
                    <ConditionsCheckbox onChange={toggleChecked} isChecked={checked}>
                        <b>I am, or someone in my household, is a 111 Vulnerable Consumer</b>
                    </ConditionsCheckbox>
                </div>

                {checked && <div className="sky-bg-gray-lightest p-6 sky-h7-reg">To apply for additional support as a 111 Vulnerable Consumer you can download and complete a Vulnerable Consumer form after you’ve completed your order. We’ve got some handy information about it all right <a href="https://help.sky.co.nz/s/article/Vulnerable-consumers" target="_blank" rel="noreferrer noopener" className="text-blue-light underline"> here</a>. <a href="https://static.sky.co.nz/support/docs/Sky%20Broadband%20VC%20form.pdf" target="_blank" rel="noreferrer noopener" className="text-blue-light underline">Get form here &gt;</a></div>}

                <hr className='mt-6 mb-6' />
                <div className="md:flex flex-col items-start gap-2  flex-grow">
                    <h2 className="sky-h5-black md:sky-h5-black ">Unlimited mobile & landline calling</h2>
                    <div className="flex flex-col items-start gap-8 pt-4 min-w-full">
                        <div className=" flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative hover:border-gray-copy border-gray-dark">
                            <button className="" style={{ marginTop: "10px", marginRight: "10px" }} onClick={() => onChangeDispatch('toggleUnlimitedNZCalls')}>
                                {state.isUnlimitedNZCalls ? <Checked /> : <Unchecked />}
                            </button>
                            <div className="flex flex-col px-4 pt-3 items-start gap-2">
                                <p >Unlimited calls to New Zealand mobiles and landlines nationwide.</p>
                                <div className="text-black "><small className="sky-sub align-top font-bold" style={{ fontSize: "10px" }}>$</small><span className="sky-h5-bold inline-block text-5xl font-black relative " style={{ top: "-0.5rem" }}>{unlimitedPhone?.price0}</span><small className="align-top sky-sub">.00</small><small className="relative  sky-sub" style={{ left: "-1.25rem", top: "-0.5rem" }}>/mth</small></div>
                            </div>

                        </div>
                        {state.isUnlimitedNZCalls ? <div className='md:w-full underline text-center font-bold text-base cursor-pointer' style={{ color: "#0057FF" }} onClick={() => onChangeDispatch('toggleUnlimitedNZCalls')}>Remove</div> : ""}
                        <div className=" flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative hover:border-gray-copy border-gray-dark">
                            <button className="" style={{ marginTop: "10px", marginRight: "10px" }} onClick={() => onChangeDispatch('toggleUnlimitedAustralianCalls')}>
                                {state.isUnlimitedAustralianCalls ? <Checked /> : <Unchecked />}
                            </button>
                            <div className="flex flex-col px-4 pt-3 items-start gap-2">
                                <p >Unlimited calls to Australian mobiles and landlines.</p>
                                <div className="text-black "><small className="sky-sub align-top font-bold" style={{ fontSize: "10px" }}>$</small><span className="sky-h5-bold inline-block text-5xl font-black relative " style={{ top: "-0.5rem" }}>{unlimitedAustraliancalls?.price0}</span><small className="align-top sky-sub">.00</small><small className="relative  sky-sub" style={{ left: "-1.25rem", top: "-0.5rem" }}>/mth</small></div>
                            </div>
                        </div>
                        {state.isUnlimitedAustralianCalls ? <div className='md:w-full underline text-center font-bold text-base cursor-pointer' style={{ color: "#0057FF" }} onClick={() => onChangeDispatch('toggleUnlimitedAustralianCalls')}>Remove</div> : ""}
                    </div>
                </div>

                <hr className='mt-6 mb-6' />
                <div className="md:flex flex-col items-start gap-2  flex-grow">
                    <h2 className="sky-h5-black md:sky-h5-black ">International calling options</h2>
                    <div className="flex">
                        <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">You can choose to select unlimited calls to mobiles and landlines in either the top 10 or top 20 international calling destinations (beyond Australia).</p>
                    </div>
                    <div className="flex flex-col items-start gap-8 pt-4 min-w-full">
                        <div className=" flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative hover:border-gray-copy border-gray-dark">
                            <button className="" style={{ marginTop: "10px", marginRight: "10px" }}
                               onClick={() => {
                                onChangeDispatch('toggleTop10Destinamtion')
                                if(state.isTop20Destinamtion) onChangeDispatch('toggleTop20Destinamtion')
                                }}
                            >
                                {state.isTop10Destinamtion ? <Checked /> : <Unchecked />}
                            </button>
                            <div className="flex flex-col px-4 pt-3 items-start gap-2">
                                <p >Top 10 Destinations <br />
                                    Unlimited calls to mobiles and landlines in these destinations: Canada, China, Hong Kong, India, Ireland (Republic), Singapore, South Africa, South Korea, UK, USA</p>
                                <div className="text-black "><small className="sky-sub align-top font-bold" style={{ fontSize: "10px" }}>$</small><span className="sky-h5-bold inline-block text-5xl font-black relative " style={{ top: "-0.5rem" }}>{top10Destination?.price0}</span><small className="align-top sky-sub">.00</small><small className="relative  sky-sub" style={{ left: "-1.25rem", top: "-0.5rem" }}>/mth</small></div>
                            </div>

                        </div>
                        {state.isTop10Destinamtion ? <div className='md:w-full underline text-center font-bold text-base cursor-pointer' style={{ color: "#0057FF" }} onClick={() => onChangeDispatch('toggleTop10Destinamtion')}>Remove</div> : ""}
                        <div className=" flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative hover:border-gray-copy border-gray-dark">
                            <button className="" style={{ marginTop: "10px", marginRight: "10px" }}
                                onClick={() => {
                                onChangeDispatch('toggleTop20Destinamtion')
                                if(state.isTop10Destinamtion)  onChangeDispatch('toggleTop10Destinamtion')

                            }}>
                                {state.isTop20Destinamtion ? <Checked /> : <Unchecked />}
                            </button>
                            <div className="flex flex-col px-4 pt-3 items-start gap-2">
                                <p >Top 20 Destinations <br />
                                    Unlimited calls to mobiles and landlines in these destinations: Canada, China, Hong Kong, India, Ireland (Republic), Singapore, South Africa, South Korea, UK, USA</p>
                                <div className="text-black "><small className="sky-sub align-top font-bold" style={{ fontSize: "10px" }}>$</small><span className="sky-h5-bold inline-block text-5xl font-black relative " style={{ top: "-0.5rem" }}>{top20Destination?.price0}</span><small className="align-top sky-sub">.00</small><small className="relative  sky-sub" style={{ left: "-1.25rem", top: "-0.5rem" }}>/mth</small></div>
                            </div>

                        </div>
                        {state.isTop20Destinamtion ? <div className='md:w-full underline text-center font-bold text-base cursor-pointer' style={{ color: "#0057FF" }} onClick={() => onChangeDispatch('toggleTop20Destinamtion')}>Remove</div> : ""}
                    </div>
                </div>
            </div>}


            <div style={isMobile ? { borderTop: '1px solid', padding: "16px 10px 0 10px", borderRadius: '20px' } : { borderTop: '', padding: "", borderRadius: '' }}>
                <div
                    className={`flex mb-1 flex-col lg:flex-row md:flex-row justify-between   lg:min-w-full bottom-0 z-50 lg:shadow-none md:shadow-none lg:rounded-none md:rounded-none sticky-custom bg-white left-0`}>
                    {isCustomize
                        ?
                        <>
                            <div className="mb-4 w-full lg:w-1/4 md:w-1/4
                         px-4 flex justify-center lg:block md:block order-last lg:order-first md:order-first">
                                <Button
                                    data-testid="remove-bb-btn"
                                    colorMode="pure"
                                    variant="secondary"
                                    onClick={removeBroadbandPlan}
                                    disabled={isRemoveLoading || isloading ? true : false} className={`w-full hidden lg:blocl md:block  mx-0 ${isRemoveLoading ? "py-5 pt-6" : ''}`}
                                >
                                    {isRemoveLoading ? <div className="relative"><Loader></Loader></div> : "Remove Broadband Plan"}
                                </Button>
                                <div
                                    data-testid="remove-bb-btn-mobile"
                                    onClick={removeBroadbandPlan}
                                    className="block lg:hidden md:hidden text-blue-pure text-lg font-bold underline">
                                    Remove Broadband Plan
                                </div>
                            </div>
                            {isDiscard
                                ?
                                <>
                                    <div className="mb-4 w-full lg:w-1/2 flex lg:flex-row flex-col">
                                        <div className='px-2 mb-4 w-full flex justify-center lg:block md:block order-last lg:order-first md:order-first'>
                                            <Button
                                                data-testid="discard-btn"
                                                colorMode="pure"
                                                variant="secondary"
                                                onClick={cancel}
                                                disabled={isloading || isRemoveLoading ? true : false} className={`w-full mx-0  w-10/12`}
                                            >
                                                Discard Changes
                                            </Button>

                                        </div>
                                        <div className='px-2 w-full mb-4'>
                                            <Button data-testid="confirm-btn"
                                                colorMode="pure"
                                                variant="primary"
                                                disabled={isloading || isRemoveLoading ? true : false} className={`sky-button--primary-pure w-full  mx-0 ${isloading ? "py-5 pt-6" : ''}`}
                                                onClick={goNext}
                                            >
                                                {isloading ? <div className="relative"><Loader></Loader></div> : "Confirm Changes"}
                                            </Button>
                                        </div>

                                    </div>
                                </>

                                :
                                <div className="mb-4 w-full px-4 md:w-1/4">
                                    <Button data-testid="close-btn"
                                        colorMode="pure"
                                        variant="primary"
                                        disabled={isloading || isRemoveLoading ? true : false} className={`sky-button--primary-pure w-full  mx-0`}
                                        onClick={cancel}
                                    >
                                        Close
                                    </Button>
                                </div>
                            }
                        </>
                        :
                        <>
                            <div className='md:w-1/4 lg:w-1/4 w-full order-last md:order-first lg:order-first flex justify-center lg:block md:block'>
                                <Button
                                    data-testid="cancel-btn"
                                    colorMode="light"
                                    variant="secondary"
                                    onClick={cancel}
                                    disabled={isloading ? true : false}
                                    className="hidden lg:block md:block  w-8/12">
                                    Cancel
                                </Button>
                                <div
                                    data-testid="cancel-btn-mobile"
                                    onClick={cancel}
                                    className="block lg:hidden md:hidden text-blue-pure text-lg font-bold underline">
                                    Cancel
                                </div>

                            </div>
                            <div className="mb-4 w-full px-4 md:w-1/4 flex justify-center lg:block md:block">
                                <Button data-testid="next-review-btn"
                                    colorMode="pure"
                                    variant="primary"
                                    disabled={isloading ? true : false} className={`sky-button--primary-pure w-full  mx-0 ${isloading ? "py-5 pt-6" : ''}`}
                                    onClick={goNext}>
                                    {isloading ? <div className="relative"><Loader></Loader></div> : "Next: Review Your Package"}
                                </Button>
                            </div>
                        </>

                    }

                </div>
            </div>
        </div>

    )
}


export { Addons };