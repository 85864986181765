import { useMemo, useCallback } from 'react';
import _sumBy from 'lodash.sumby';
import { KonakartService } from '../services/konakart';
import { useToastContainer } from './useToastContainer';
import { useToggleProduct } from './useToggleProduct';
import { useCouponCode } from './useCouponCode';
import { useProductStore, productStoreApi } from '../store/productStore';
import { productSkuIds, categoryIds, messages, broadbandCategories, voiceCategories, couponCodes } from '../config';
import { T_Product, CouponTypeValue, CouponType, T_Coupon, T_Occurrence} from '../types';
import { useCustomerStore } from '../store/customerStore';
import { useCouponStore, couponStoreApi } from '../store/couponStore';
import { useOrderStore } from '../store/orderStore';
import { useNoBox } from './useNoBox';
import { useHandleOneTimeCharge } from './useHandleOneTimeCharge';
import { freeProductStoreApi } from '../store/freeProductStore';
import { useRemoveTextFormatter } from './useRemoveTextFormatter/useRemoveTextFormatter';
import { useFreeProduct } from './useFreeProduct';
import { useUpdateCart } from './useUpdateCart';
import { shouldShowCouponToast } from './couponToastHelper';
import { getBoxQuantityAndOccurrences } from '../swr/helper';
import { formatProduct } from './product';
import { isNonNil } from './filter';
import { BBList, boxesStoreApi, useEligibilityOffers } from '../entry';
import { Coupon, EligibilityOffer } from '@sky-tv-group/graphql';
import { useOfferAllocation } from './eligibilityOffers/useOfferAllocation';

const {
  skyBox: { primary: skyBox },
  mySky: { primary: mySky },
  mySkyPlus: { primary: mySkyPlus },
  soho: { primary: soho },
} = productSkuIds;
const boxSkus: string[] = [skyBox, mySky, mySkyPlus];
/**
 * Hook for managing interactions with the cart in terms of adding products and removing them
 * Uses the toast container to display messages when a transaction occurs
 * cartType is ACQUISITION if it's coming from cart, UPGRADE if it's coming from MyAccount.
 */
function useCartContainer(konakartService: KonakartService, cartType: CouponType, checkFreeProduct: boolean = true) {
  const { customerId, isStaffAccount } = useCustomerStore(s => ({
    customerId: s.kk,
    isStaffAccount: s.isStaffAccount,
  }));
  const {
    getOfferToast,
    addAwaitableToast,
    addToast,
    getDependencyToastId,
    hasDependencyToast,
    addToDependencyToasts,
    removeFromDependencyToasts,
    removeToast,
  } = useToastContainer();
  const toggleProductInStore = useToggleProduct();
  const { removalText } = useRemoveTextFormatter();
  const {
    applyCouponCodeToCart,
    getCouponFromCouponCode,
    applyCouponCodeToState,
    removeCouponFromCart,
  } = useCouponCode(konakartService);
  const {
    coupons,
    removeAllCoupons,
    getCouponForProduct,
    hasSameCouponTypeAndCategory,
    clearCouponFromCode,
  } = useCouponStore(s => ({
    coupons: s.coupons,
    removeAllCoupons: s.removeAllCoupons,
    getCouponForProduct: s.getCouponForProduct,
    hasSameCouponTypeAndCategory: s.hasSameCouponTypeAndCategory,
    clearCouponFromCode: s.clearCouponFromCode,
  }));
  const {
    removeFreeProductWithToast,
    freeProductRemovalToast,
    checkIfFreeProductWillBeFullyPaid,
    freeProductAdditionToast,
  } = useFreeProduct(konakartService);
  const { updateOrder } = useUpdateCart(konakartService, checkFreeProduct);
  const { order, clearOrder } = useOrderStore(s => ({
    order: s.order,
    clearOrder: s.clearOrder,
  }));
  const { products, clearProducts, initialized, productStoreUpdated, updateProductDowngrade } = useProductStore(s => ({
    products: s.products.sort(
      ({ rating: previousRating }, { rating: currentRating }) => currentRating - previousRating
    ),
    clearProducts: s.clearProducts,
    initialized: s.initializedProductStore,
    productStoreUpdated: s.updatedProductStore,
    updateProductDowngrade: s.updateProductDowngrade,
  }));
  const { changeProductQuantity } = useProductStore(s => ({
    changeProductQuantity: s.changeProductQuantity,
  }));
  const { handleBoxOTC } = useHandleOneTimeCharge(konakartService);
  const starter = useProductStore(s => {
    return s.products.find(p => p.sku === productSkuIds.starter.primary);
  });

  const access = useProductStore(s => s.products.find(p => p.sku === productSkuIds.access.primary));
  const hasActiveStarter = !!starter && starter.quantityBought > 0;

  const entertainment = useProductStore(s => s.products.find(p => p.sku === productSkuIds.entertainment.primary));

  const routerDevice = useProductStore(s => s.products.find(p => p.sku === productSkuIds.skyRouter.primary));
  const meshDevice = useProductStore(s => s.products.find(p => p.sku === productSkuIds.meshDevice.primary));
  const selfInstall = useProductStore(s => s.products.find(p => p.sku === productSkuIds.broadbandSelfInstall.primary));
  const skyBox = useProductStore(s =>
    s.products.find(p => p.sku === productSkuIds.skyBox.primary && p.custom3 === productSkuIds.skyBox.customAttribute)
  )!;
  const skyBoxCharge = useProductStore(s => s.products.find(p => p.sku === productSkuIds.skyBoxCharge.primary))!;
  const validBoxIds = products.filter(p => boxSkus.includes(p.sku)).map(p => p.id);
  const packages = products.filter(p => p.categoryId === categoryIds.package);
  const packageUpgrades = products.filter(p => p.categoryId === categoryIds.packageUpgrade);
  const specials = products.filter(p => p.categoryId === categoryIds.special);
  const hindi = products.filter(p => p.categoryId === categoryIds.hindiChannels);
  const boxes = products.filter(p => p.categoryId === categoryIds.box);

  const recording = products.find(p => p.sku === productSkuIds.arrowBoxRecording.primary)!;
  const boxMonthly = products.find(p => p.sku === productSkuIds.arrowMonthly.primary)!;
  const boxOneOff643 = products.find(p => p.sku === productSkuIds.arrowBoxOneOff.primary)!;
  const boxOneOff830 = products.find(p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary)!;
  const multiroomFee = products.find(p => p.sku === productSkuIds.multiroom.primary)!;
  const noRecording = products.find(p => p.sku === productSkuIds.noRecording.primary)!;
  const skyPodOneOffFee = products.find(p => p.sku === productSkuIds.skyPodOneOffFee.primary)!;
  const boxOptions = {
    recording,
    boxMonthly,
    boxOneOff643,
    boxOneOff830,
    multiroomFee,
    noRecording,
    skyPodOneOffFee,
  };
  const arrowBox = useProductStore(s => s.products.find(p => p.sku === productSkuIds.arrowBox.primary))!;
  const skyPod = useProductStore(s => s.products.find(p => p.sku === productSkuIds.skyPod.primary))!;
  /**
   * The boxes that we're selling from the cart.
   */
  const acquisitionBoxes = boxes.filter(b => b.custom4?.toUpperCase() === CouponTypeValue.Acquisition);
  const multirooms = products.filter(p => p.categoryId === categoryIds.multiroom);

  // option for adding MR
  const acquisitionMultirooms = [arrowBox, skyBoxCharge].filter(isNonNil);

  const { arrowLoyaltyOffers, anyArrowLoyaltyOffers, mySkyOffers, anyMySkyAlreadyInTheOrder } = useEligibilityOffers();

  const { pickBestOffer } = useOfferAllocation(konakartService);

  //const has01277 = couponStoreApi.getState().coupons.some(c => c.couponCode === couponCodes.oneMonthOnUsWithSport);

  const getArrowBoxMR = (oneoff: boolean, recording: boolean, eligibleForFreeRecording: boolean) => {
    if (eligibleForFreeRecording) {
      recording = false;
    }

    let box = multirooms.filter(mr => mr.sku.includes(productSkuIds.arrowBox.primary));
    box = box.filter(mr => {
      return mr.sku.includes(oneoff ? productSkuIds.arrowBoxOneOff.primary : productSkuIds.arrowMonthly.primary);
    });

    box = box.filter(mr => {
      let isRecording = mr.sku.includes(productSkuIds.arrowBoxRecording.primary);
      return recording ? isRecording : !isRecording;
    });

    box = box.filter(upBox => {
      let hasFreeRecording = upBox.sku.includes(productSkuIds.upfrontRecording.primary);
      return eligibleForFreeRecording ? hasFreeRecording : !hasFreeRecording;
    });
    return box[0];
  };

  const getProductAndAddons = (product: T_Product, oneoff: boolean, recording: boolean, primary: boolean = false) => {
    let res = [product];
    if (!primary) res.push(multiroomFee);
    if (recording) {
      res.push(boxOptions.recording);
    } else {
      res.push(boxOptions.noRecording);
    }
    if (product.id === arrowBox.id) {
      if (oneoff) {
        //res.push(boxOptions.boxOneOff643);
        res.push(boxOptions.boxOneOff830);
      } else {
        res.push(boxOptions.boxMonthly);
      }
    }
    if (product.id === skyPod.id) {
      res.push(boxOptions.skyPodOneOffFee);
    }
    return res.filter(isNonNil).map(formatProduct);
  };

  const multiroomsNumber = _sumBy(multirooms, p => p.quantityInCart ?? 0);
  const monthlyFees = (order?.orderProducts ?? [])
    .filter(
      r =>
        r.product.categoryId !== categoryIds.oneOffFee &&
        r.product.categoryId !== categoryIds.broadbandOneOffFee &&
        r.product.categoryId !== categoryIds.technicianFee &&
        r.product.categoryId !== categoryIds.broadbandTechnicianFee
    )
    .map(r => {
      // switch to bb discounted price if account has active starter
      if (hasActiveStarter && BBList.includes(r.product.sku) && r.price > r.product.price1) {
        r.price = r.product.price1;
      }
      return r;
    });
  const oneOffFees = (order?.orderProducts ?? []).filter(
    r => r.product.categoryId === categoryIds.oneOffFee || r.product.categoryId === categoryIds.broadbandOneOffFee
  );
  const technicianFee = (order?.orderProducts ?? []).filter(r => r.product.categoryId === categoryIds.technicianFee);
  const broadbandTechnicianFee = (order?.orderProducts ?? []).filter(
    r => r.product.categoryId === categoryIds.broadbandTechnicianFee
  );
  const allTechnicianFees = technicianFee.concat(broadbandTechnicianFee);
  const orderTotals = [
    order?.orderTotals.find(t => t.className === 'ot_subtotal'),
    order?.orderTotals.find(t => t.className === 'ot_total'),
    order?.orderTotals.find(t => t.className === 'ot_total_no_otc'),
    order?.orderTotals.find(t => t.className === 'ot_technician_fee'),
  ];
  /**
   * Broadband products.
   */
  const broadband = products.filter(p => p.categoryId === categoryIds.broadband);
  const broadbandServices = products.filter(p => p.categoryId === categoryIds.broadbandServices);
  const broadbandDevices = products.filter(p => p.categoryId === categoryIds.broadbandDevices);
  const broadbandOneOffFees = products.filter(p => p.categoryId === categoryIds.broadbandOneOffFee);
  const broadbandDiscounts = products.filter(p => p.categoryId === categoryIds.discounts);
  const isBroadcastTier = order?.orderProducts?.some(
    p => p.categoryId === categoryIds.digitalDecoder && p.quantity > 0
  );

  /**
   * Whether the order has at least one sky box added
   */
  const hasBox = useMemo(
    () => (order?.orderProducts ?? []).find(r => r.product.categoryId === categoryIds.box) != null,
    [order]
  );

  /**
   * Return the added Broadband product
   */
  const broadbandProductInCart = order?.orderProducts?.find(
    orderProd => orderProd.categoryId === categoryIds.broadband
  );

  /**
   * Add Sky Box to cart
   */
  const addSkyBox = async (update: boolean = true, handleDependencyCheck: boolean = true, trackEvent?: boolean) => {
    // box not found or added already
    if (!skyBox || hasBox) {
      return;
    }
    await toggleBoxes(skyBox, update, handleDependencyCheck, trackEvent);
  };

  const { noBoxAction } = useNoBox(validBoxIds, addSkyBox);

  const starterAdded = starter && starter.quantityInCart > 0;
  const accessAdded = access && access.quantityInCart > 0;

  /**
   * Returns true if starter or sky access is in cart, can only be used if user is authenticated
   */
  const allowCheckout = starterAdded || accessAdded;

  /**
   * Mesh name
   */
  const routerName = routerDevice?.name ? routerDevice.name : 'Sky WiFi Router';

  /**
   * Returns true if mesh device is in cart
   */
  const meshAdded = meshDevice ? meshDevice.quantityInCart > 0 : false;

  /**
   * Returns true if self install is in cart
   */
  const selfInstallAdded = selfInstall ? selfInstall.quantityInCart > 0 : false;

  /**
   * Returns true if router device is in cart
   */
  const routerAdded = routerDevice ? routerDevice.quantityInCart > 0 : false;

  /**
   * Mesh name
   */
  const meshName = meshDevice?.name ? meshDevice.name : 'Sky WiFi Booster';

  /**
   * Cart initialized
   */
  const cartInitialized = initialized;

  /**
   * Return the Main box in the cart
   */
  const boxInCart = products.find(p => p.categoryId === categoryIds.box && p.quantityInCart > 0);

  // Returns true if Starter is the only package/channel in the cart
  const isStarterTheOnlyPackageInCart =
    products.filter(
      p =>
        (p.categoryId === categoryIds.package && p.quantityInCart > 0) ||
        (p.categoryId === categoryIds.packageUpgrade && p.quantityInCart > 0) ||
        (p.categoryId === categoryIds.special && p.quantityInCart > 0) ||
        (p.categoryId === categoryIds.hindiChannels && p.quantityInCart > 0)
    ).length === 1;

  // Returns all boxes and multiroom boxes in cart
  const boxesAndMultiroomInCart = products.filter(
    p =>
      (p.categoryId === categoryIds.box && p.quantityInCart > 0) ||
      (p.categoryId === categoryIds.multiroom && p.quantityInCart > 0)
  );

  // Returns true is Sky Box is the only box in the cart
  const isSkyBoxTheOnlyBoxInCart =
    boxesAndMultiroomInCart.length === 1 &&
    boxesAndMultiroomInCart[0].sku === productSkuIds.skyBox.primary &&
    boxesAndMultiroomInCart[0].custom3 === productSkuIds.skyBox.customAttribute;
  // Returns true if Starter and Sky Box are the only products in the cart
  const onlyBaseProductsAdded = isStarterTheOnlyPackageInCart && isSkyBoxTheOnlyBoxInCart;

  // Changes product quantity in store
  const applyProduct = useCallback(
    (
      productId: number,
      quantity: number,
      trackEvent?: boolean,
      boxId?: string,
      overrides?: Pick<T_Product, 'inPromotion' | 'priceIncTax'>
    ) => {
      toggleProductInStore(productId, quantity, trackEvent, boxId, overrides);

      // remove any dep toasts if need be
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, toastId] = getDependencyToastId(productId);
      removeFromDependencyToasts(productId);
      removeToast(toastId);
    },
    [removeFromDependencyToasts, removeToast, toggleProductInStore, getDependencyToastId]
  );

  const swapMultipleProducts = async (productToRemove: T_Product[], productToAdd: T_Product[], trackEvent = true) => {
    for (let i = 0; i < productToRemove.length; i++) {
      const product = productToRemove[i];
      let productsInStore = products.filter(p => p.id === product.id && p.quantityInCart)?.length ?? 0;
      let newQuantity = Math.max(0, productsInStore - 1);

      await toggleProduct(
        product,
        productToAdd.length === 0 && i === productToRemove.length - 1,
        true,
        '',
        false,
        newQuantity,
        trackEvent
      );
    }

    for (let i = 0; i < productToAdd.length; i++) {
      const product = productToAdd[i];
      let productsInStore = products.filter(p => p.id === product.id && p.quantityInCart)?.length ?? 0;
      let newQuantity = Math.max(0, productsInStore + 1);

      await toggleProduct(product, i === productToAdd.length - 1, true, '', false, newQuantity, trackEvent);
    }

    // If user remove any products with coupons while editing upgrade
    cleanCouponsAfterBoxRemoval();
  };

  const changeProductsByQuantity = async (
    productsToUpdate: T_Product[],
    byQuantity = 1,
    needUpdate = true,
    trackEvent = true
  ) => {
    for (let i = 0; i < productsToUpdate.length; i++) {
      const product = productsToUpdate[i];
      let productsInStore = products.filter(p => p.id === product.id && p.quantityInCart)?.length ?? 0;
      let newQuantity = Math.max(0, productsInStore + byQuantity);

      await toggleProduct(
        product,
        needUpdate && i === productsToUpdate.length - 1,
        true,
        '',
        false,
        newQuantity,
        trackEvent
      );
    }

    if (productsToUpdate.length === 0 && needUpdate) {
      await updateOrder(customerId, productStoreApi.getState().getBasketItemsToAddToOrder());
    }

    // If user remove any products with coupons while upgrading
    cleanCouponsAfterBoxRemoval();
  };

  const addMultipleProductsToCart = async (
    productsToUpdate: T_Product[],
    byQuantity = 1,
    needUpdate = true,
    trackEvent = true
  ) => {
    for (let i = 0; i < productsToUpdate.length; i++) {
      const product = productsToUpdate[i];
      const updatedQuantity = productsToUpdate.filter(obj => obj.sku === product.sku).length;

      let productsInStore = products.filter(p => p.id === product.id && p.quantityInCart)?.length ?? 0;
      let newQuantity = Math.max(0, productsInStore + updatedQuantity);
      changeProductQuantity(product.id, newQuantity, trackEvent, product.occurrenceNumber, {
        inPromotion: product.inPromotion,
        priceIncTax: product.priceIncTax,
      });
      /*if (needUpdate && i === productsToUpdate.length - 1) {
        await updateOrder(customerId, productStoreApi.getState().getBasketItemsToAddToOrder());
      }*/
    }

    if (productsToUpdate.length === 0 && needUpdate) {
      await updateOrder(customerId, productStoreApi.getState().getBasketItemsToAddToOrder());
    }
  };
  // This method is used to remove applied coupons from coupon store after user remove a box.
  // ToDo - Once the back end provide product sku with eligibility offer we can use that instead of
  // hard coded arrowUpfrontBoxFee
  const cleanCouponsAfterBoxRemoval = () => {
    if (!anyArrowLoyaltyOffers && !mySkyOffers) return;

    const anyBoxesWithoneOffFee = boxesStoreApi
      .getState()
      .boxes.filter(
        b =>
          b.boxType !== 'SUBSCRIBED' &&
          b.boxType !== 'PENDING' &&
          b.products.find(bp => bp.sku === productSkuIds.arrowUpfrontBoxFee.primary)
      );

    if (!anyBoxesWithoneOffFee || anyBoxesWithoneOffFee.length === 0) {
      arrowLoyaltyOffers?.forEach(offer => {
        clearCouponFromCode(offer.campaign);
      });
    }

    /* Remove MySky offer as well. */
    if (mySkyOffers && anyMySkyAlreadyInTheOrder) {
      mySkyOffers?.forEach(offer => {
        clearCouponFromCode(offer.campaign);
      });
    }
  };

  // Instead of adding the product straight, check first if the product has an offer assigned to it.
  const offerCheck = async (
    product: T_Product,
    quantity: number,
    update: boolean = true,
    removeAccessories: boolean = true,
    broadbandJourney?: string,
    trackEvent?: boolean
  ) => {
    //First Apply any EE offers
    if (quantity > 0) {
      const eeOffer = pickBestOffer(product);

      if (eeOffer) {
        await applyCouponCodeToState(eeOffer.promotion.coupon, cartType);
        return await toggleSingleProductWithUpdate(
          product,
          quantity,
          eeOffer.promotion.coupon,
          update,
          removeAccessories,
          broadbandJourney,
          trackEvent
        );
      }
    }

    //If there are no EE offers check for offers in KK from product.custom2
    let offerCode = product.custom2;

    if (!offerCode || product.quantityBought > 0) {
      // just apply the product
      return await toggleSingleProductWithUpdate(
        product,
        quantity,
        getCouponForProduct(product),
        update,
        removeAccessories,
        broadbandJourney,
        trackEvent
      );
    }

    // otherwise there is an offer in the custom2 field
    const offer = await getRespectiveCouponForCartType(offerCode, cartType);
    // if there is no offer and from the back end and the quantity of products is 0
    if (!offer || !quantity || offer.custom3?.toUpperCase() !== cartType) {
      // just apply the product
      return await toggleSingleProductWithUpdate(
        product,
        quantity,
        getCouponForProduct(product),
        update,
        removeAccessories,
        broadbandJourney,
        trackEvent
      );
    }

    // if a coupon is an ACQUISITION coupon, check:
    //  - if we have an existing acquisition coupon with same type or
    //  - if there is an offerToast already
    // (ACQUISITION MULTIPLE OFFERS RESTRICTION: if there's an offer toast active, dont show any other offer toast at the same time.)
    if (offer.custom3?.toUpperCase() === CouponTypeValue.Acquisition) {
      if (!hasSameCouponTypeAndCategory(offer) || getOfferToast()) {
        await applyCouponCodeToState(offer, cartType);
        return await toggleSingleProductWithUpdate(
          product,
          quantity,
          undefined,
          update,
          removeAccessories,
          broadbandJourney,
          trackEvent
        );
      }
    }

    if (hasDependencyToast(product.id)) {
      return;
    }

    const applyCoupon = async () => {
      try {
        // everything inside this try is done to the store but not to konakart
        if (update) {
          await applyCouponCodeToState(offer, cartType);
        }

        const idsToAdd = offer.custom1.split(',').map(x => Number(x));
        const products = productStoreApi.getState().products;
        // do not add the product that is toggled. Do that in the onClose of the toast.
        // this is due to it will be called if offer accepted or not.
        const couponProducts = products.filter(cp => idsToAdd.includes(cp.id) && product.id !== cp.id);
        couponProducts.forEach(couponProduct => {
          if (couponProduct.quantityInCart === 0) {
            applyProduct(couponProduct.id, quantity, trackEvent);
          }
        });
      } catch (e) {
        // show a toast for failure
        showCouponErrorToast(product.custom2);
      }
    };

    if (!(await shouldShowCouponToast(offer))) {
      await applyCoupon();
      await toggleSingleProductWithUpdate(
        product,
        quantity,
        getCouponForProduct(product),
        update,
        removeAccessories,
        broadbandJourney,
        trackEvent
      );
      return;
    }

    // pop the offer toast
    const { toastId: newOffer, promise: toastPromise } = addAwaitableToast({
      title: offer.name,
      type: 'offer',
      message: offer.description,
      action: async () => {
        applyCoupon();
      },
      actionText: 'Apply Offer',
      onClose: async () => {
        // this is responsible for the doing the actual update on the order.
        await toggleSingleProductWithUpdate(
          product,
          quantity,
          getCouponForProduct(product),
          update,
          removeAccessories,
          broadbandJourney,
          trackEvent
        );
        removeFromDependencyToasts(product.id);
      },
      closeText: 'No thanks',
    });

    // store that toast id for later
    addToDependencyToasts(product.id, newOffer);
    return toastPromise;
  };

  /**
   * Packages can have coupon for ACQUISITION/UPGRADE and we need to filter respective one
   * @param couponCodes
   * @param type
   */
  const getRespectiveCouponForCartType = async (
    couponCodes: string,
    type: CouponType
  ): Promise<T_Coupon | undefined> => {
    const couponCodeArray = couponCodes.split(',');
    for (let couponValue of couponCodeArray) {
      let couponObject = await getCouponFromCouponCode(couponValue);
      if (couponObject && couponObject.custom3?.toUpperCase() === type) {
        // get back the first coupon that matches the type
        return couponObject;
      }
    }
    // found nothing
    return undefined;
  };

  /**
   * Returns true if dependency was met,
   *
   * 8/26/2021 - Change the logic of dependency resolution from being an "all dependencies is met (and)"
   * to at least one dependency is met (or). This change will resolve an issue where a product
   * depends on either Sky Starter or Sky Access to be in cart.
   */
  const wasDependencyMet = (product: T_Product, showToast = false): boolean => {
    // special case for home phone / addons && booster, check for other data package in cart
    // and not just 101BB, if there isn't any it defaults to asking for 101BB to be added
    if (
      product.categoryId === categoryIds.voice ||
      (product.categoryId === categoryIds.broadbandDevices && product.sku !== productSkuIds.meshDevice.primary) ||
      product.categoryId === categoryIds.voiceAddons ||
      product.categoryId === categoryIds.voiceCrossCountry ||
      product.categoryId === categoryIds.broadbandTechnicianFee
    ) {
      const broadbandPackageInCart = productStoreApi
        .getState()
        .products.some(p => p.categoryId === categoryIds.broadband && p.quantityInCart > 0);
      return broadbandPackageInCart;
    }

    if ((product.depends?.length ?? 0) < 1) return true;

    // Cart type
    const isUpgrade = cartType === CouponTypeValue.Upgrade;
    // Make sure product dependencies aren't itself
    const realDependencies = product.depends?.filter(d => d !== product.id);
    let depWasntMet = false;
    realDependencies?.every((realDepId, idx) => {
      const dependencyInCart = productStoreApi
        .getState()
        .products.some(p => p.id === realDepId && p.quantityInCart > 0);

      if (dependencyInCart) return false; // return false to stop looping when at least one dependency is in cart
      // Continue searching for dependencies in cart
      if (isUpgrade && realDependencies.length - 1 !== idx) return true;

      // If the product isn't in the cart
      depWasntMet = true;
      const dependency = products.find(dependentProduct => dependentProduct.id === realDepId);
      if (!dependency || hasDependencyToast(realDepId)) {
        return;
      }
      // If this is acquisition cart, don't look for Sky Access as dependency
      if (!isUpgrade && dependency.sku === productSkuIds.access.primary) {
        return true;
      }

      // have to add deps automatically to support SKUs in url.
      let addDeps = async () => {
        await offerCheck(dependency, 1, false);
        const quantity = product.quantityInCart > 0 ? 0 : 1;
        // If the dependency is Starter and not a box thats being checked for dependency, add Sky Box silently
        await addBoxLogicSilently(dependency, product, quantity);
        // Final updateOrder call to update cart with main product that was toggled
        await offerCheck(product, quantity);
      };

      if (showToast) {
        // Throw a warning toast
        const warningToastId = addToast({
          title: 'Alert',
          type: 'warning',
          message: messages.dependencyWarning(product.name, dependency.name),
          action: addDeps,
          actionText: `Add ${dependency.name}`,
          closeText: messages.noThanks,
          onClose: () => {
            removeFromDependencyToasts(realDepId);
          },
        });

        addToDependencyToasts(realDepId, warningToastId);
      } else {
        addDeps();
      }
    });

    return !depWasntMet;
  };

  /**
   * Returns false if there is a dependent product is still in cart
   * Prompts toast if it wants to remove the dependent product in cart
   * @param product
   */
  const dependentProductInCart = (product: T_Product) => {
    const products = productStoreApi.getState().products;
    // check local state
    const productsInCart = products.filter(p => p.quantityInCart > 0);
    // If product is depended on some product in cart, and its a package upgrade
    const dependents = productsInCart.filter(p => {
      return !!p.depends?.find(n => n === product.id) && p.categoryId === categoryIds.packageUpgrade;
    });

    if (dependents.length < 1) {
      return false;
    }

    let dependedOn = true;

    // TODO: show toast if he wants to remove sky starter from cart
    // If yes, remove all dependents in cart and return true
    // Else if he doesn't wants dependents remove then return false so sky starter won't be removed
    addToast({
      title: 'Alert',
      type: 'warning',
      message: 'Before removing Sky Starter, remove other packages first.',
    });

    return dependedOn;
  };

  /**
   * Does a check to see if we need to add sky box silently OR if adding a box that needs to handle OTC
   */
  const addBoxLogicSilently = async (dependency: T_Product, product: T_Product, quantity: number) => {
    // Check first if we are adding starter as a dependency as box will be added based on starter added.
    if (dependency?.id === starter?.id) {
      // Check if the product being added is a box. If its a box, we don't need to add sky box default.
      if (product.categoryId === categoryIds.box) {
        // must now check for OTC - joining fee for box - does the update for this transaction
        await handleBoxOTC(product, false);
      } else {
        // if not a box being added - we can add skybox silently and do updateOrder call inside
        await addSkyBox(false, false);
      }
    }
  };

  /**
   * Adds or Removes the product from the cart depending on if it exists or not.
   * Boxes should not call this, method. Instead use toggleBoxes
   * @param product - product being toggled
   * @param update - default true (if want to update Konakart at the end)
   * @param broadbandJourney - whether fibre swap, intact ONT or new fibre journey.
   */
  const toggleProduct = async (
    product: T_Product,
    update: boolean = true,
    removeAccessories: boolean = true,
    broadbandJourney?: string,
    showToast = false,
    quantity?: number,
    trackEvent?: boolean
  ) => {
    // If product is going to be added, check for dependency
    if (product.quantityBought < 1 && product.quantityInCart < 1) {
      const isDependencyMet = wasDependencyMet(product, showToast);
      if (!isDependencyMet) return;
    }
    const toggleQty = quantity ?? (product.quantityInCart > 0 && !product.downgrade ? 0 : 1);
    await offerCheck(product, toggleQty, update, removeAccessories, broadbandJourney, trackEvent);
  };

  /**
   * Toggles a box to the cart and removes other boxes if it is being added.
   * Only one box type can be in cart
   * (Acquisition flow only method) - will not have any existing campaigns as no account created
   * @param box
   * @param update - for the final handleBoxTOC to update or not
   * @param handleDependencyCheck
   */
  const toggleBoxes = async (
    box: T_Product,
    update: boolean = true,
    handleDependencyCheck: boolean = true,
    trackEvent?: boolean
  ) => {
    if (handleDependencyCheck) {
      const isDependencyCheckPassed = wasDependencyMet(box);
      if (!isDependencyCheckPassed) return;
    }
    const quantity = box.quantityInCart > 0 ? 0 : 1;
    // remove all other boxes added to cart and if other is added toggle toast to remove
    if (quantity > 0) {
      // only bother removing other boxes if we are adding to cart
      // we only expect one box to have quantity greater than 0
      let currentBox = boxes.find(productBox => productBox.id !== box.id && productBox.quantityInCart > 0);
      if (currentBox) {
        await checkBoxAssociatedWithCoupon(box, currentBox);
      } else {
        await offerCheck(box, quantity, false, true, undefined, trackEvent); // handleOTC does final update call
      }
    } else {
      await offerCheck(box, quantity, false, true, undefined, trackEvent); // handleOTC does final update call
    }

    // otc should not be charged for broadcast tier customers
    if (!isBroadcastTier) {
      // this does the update to the Order if any box is added (other than a box that triggers starter logic)
      handleBoxOTC(box, update, trackEvent);
    }
  };

  /**
   * Check before removing a box due to business rule, whether the box about to be removed
   * is associated with a coupon.
   * NO UPDATE REQUIRED - we do the update on the BoxOTC
   * @param boxToAdd - the box that they want to add
   * @param boxToRemove with a greater quantity than 0
   */
  const checkBoxAssociatedWithCoupon = async (boxToAdd: T_Product, boxToRemove: T_Product) => {
    if (coupons.length !== 0) {
      const couponProductToRemove = getCouponForProduct(boxToRemove);
      // if the coupon includes the product - which is the box
      if (couponProductToRemove) {
        if (hasDependencyToast(boxToRemove.id)) return;

        const { toastId, promise } = addAwaitableToast({
          title: messages.areYouSure,
          type: 'warning',
          message: `Adding ${boxToAdd.name} will remove ${boxToRemove.name} and will also remove the offer associated, are you sure you would like this?`,
          action: () => {
            // Remove the coupon
            removeCouponFromCart(couponProductToRemove, false, cartType);
            // Set the quantity of the new box to 0
            applyProduct(boxToRemove.id, 0);
          },
          actionText: messages.removeCoupon,
          closeText: messages.noThanks,
          onClose: async () => {
            // If coupon is removed it means that we can add the new one
            // Do not use the coupon from the hook, as due to short time when we apply undefined, it might not update the hook value yet
            if (getCouponForProduct(boxToRemove) === undefined) {
              await offerCheck(boxToAdd, 1, false);
            }
            removeFromDependencyToasts(boxToRemove.id);
          },
        });

        addToDependencyToasts(boxToRemove.id, toastId);
        return promise;
      }
    }
    applyProduct(boxToRemove.id, 0);
    await offerCheck(boxToAdd, 1, false);
  };

  /**
   * Awaitable starter dependency toast check.
   */
  const addStarterToast = async (product: T_Product) => {
    let wasStarterAdded = false;
    const hasStarterDependency = product.depends?.filter(d => d !== product.id && d === starter?.id);

    // if adding a starter or starter already in cart, skip toast
    if (product.id === starter?.id || starterAdded) {
      return !wasStarterAdded;
    }

    // broadband doesn't have starter dependency, skip toast
    if (hasStarterDependency?.length === 0) {
      return wasStarterAdded;
    }

    // Throw a warning toast
    const { toastId: newOffer, promise: toastPromise } = addAwaitableToast({
      title: 'Alert',
      type: 'warning',
      message: messages.dependencyWarning(product.name, starter!.name),
      action: async () => {
        // Add dependent product - don't need to update here as will be adding the main product
        await offerCheck(starter!, 1, false);
        wasStarterAdded = true;
      },
      actionText: `Add ${starter?.name}`,
      closeText: messages.noThanks,
      onClose: async () => {
        removeFromDependencyToasts(starter!.id);
      },
    });

    addToDependencyToasts(starter!.id, newOffer);
    await toastPromise; // wait for it!
    return wasStarterAdded;
  };

  /**
   * Toggle product for digital rental customers
   * Adds the multiroom boxes to order if a customer has multiroom digital rental boxes
   * @param product
   * @param occurrences
   */
  const digitalRentalToggleProduct = async (product: T_Product, occurrences: T_Occurrence[], update?: boolean) => {
    const quantity = product.quantityInCart > 0 ? 0 : 1;
    const wasStartedAdded = await addStarterToast(product);

    if (!wasStartedAdded) {
      return;
    }

    // trying to remove a product but dependency is still in cart
    // show warning toast and do nothing
    if (dependentProductInCart(product) && quantity === 0) {
      return;
    }

    if (quantity > 0 && wasStartedAdded) {
      upgradeDigitalRentalBoxes(occurrences);
    }

    if (starter?.id === product.id) {
      if (quantity === 0) {
        // if starter is to be removed, remove all upgrade boxes
        undoBoxesUpgrade(occurrences);
      }
    }

    await offerCheck(product, quantity, update);
  };

  /**
   * Removes all added box and multiroom products in cart
   * @param occurrences
   */
  const undoBoxesUpgrade = (occurrences: T_Occurrence[]) => {
    // Remove box products from order
    const orderBoxes = order?.orderProducts.filter(product => {
      return product.categoryId === categoryIds.box || product.categoryId === categoryIds.multiroom;
    });

    for (let box of orderBoxes ?? []) {
      applyProduct(box.productId, 0);
    }

    // Toggle digital decoder products exclusion from totals
    for (let product of products) {
      if (product.categoryId === categoryIds.digitalDecoder) {
        const matched = getBoxQuantityAndOccurrences(occurrences, product);
        if (matched.quantity > 0) {
          // include price from ot_total
          updateProductDowngrade(product.id, false);
        }
      }
    }
  };

  /**
   * Removes all added boxes and packages from cart
   * Also clears any coupons that might add back packages and boxes to order.
   *
   * @param occurences
   */
  const removeAllStarterDependentProducts = () => {
    const nonDTHCategories = [...broadbandCategories, ...voiceCategories];

    // Get all starter related products from cart
    const products = order?.orderProducts.filter(product => {
      return !nonDTHCategories.includes(product.categoryId);
    });

    for (let prod of products ?? []) {
      applyProduct(prod.productId, 0);
    }

    couponStoreApi.getState().removeAllCoupons();
  };

  /**
   * Upgrades all digital rental box product to it's non-digital rental version
   * @param occurrences
   */
  const upgradeDigitalRentalBoxes = (occurrences: T_Occurrence[]) => {
    const [firstBox, ...multiBoxes] = occurrences;

    // find the appropriate box to add to cart
    // eg Sky Box, My Sky or My Sky+
    const firstBoxProduct = boxes.filter(box => {
      const skus = box.sku.split(',');
      return firstBox.entitlements.filter(e => skus.includes(e.code)).length === skus.length;
    });

    // then find the multiroom products to add to cart
    const multiBoxProducts = multirooms.filter(box => {
      const skus = box.sku.split(',');
      const matchedBox = multiBoxes.filter(box => {
        // box.entitlements should include all product sku (excluding multiroom sku)
        return skus.every(sku => {
          return sku === productSkuIds.multiroom.primary || box.entitlements.map(e => e.code).includes(sku);
        });
      });

      return matchedBox.length > 0;
    });

    for (let box of [...firstBoxProduct, ...multiBoxProducts]) {
      applyProduct(box.id, 1);
    }

    // Toggle digital decoder products exclusion from totals
    for (let product of products) {
      if (product.categoryId === categoryIds.digitalDecoder) {
        const matched = getBoxQuantityAndOccurrences(occurrences, product);
        if (matched.quantity > 0) {
          // exclude price from ot_total
          updateProductDowngrade(product.id, true);
        }
      }
    }
  };

  const addMultiRoom = (product: T_Product, diff: number) => async () => {
    const runOfferCheck = async () => {
      const q = Math.max(0, (product.quantityInCart ?? 0) + diff);
      await offerCheck(product, q);
    };
    // Check if starter added
    if (!starterAdded) {
      noStarterAction(product, diff);
      return;
    }
    // If there isn't a SkyBox
    if (!hasBox) {
      // Throw a toast telling the user they need a primary decoder
      noBoxAction(messages.addASkyBox, runOfferCheck)();
      return;
    }
    // Else just run the offer check
    return runOfferCheck();
  };

  /**
   * Multiroom specific logic on handling starter + adding multiroom product with diff quantity
   * @param multiroomProduct the multiroom product that has been toggled
   * @param diff difference to add it
   */
  const noStarterAction = (multiroomProduct: T_Product, diff: number) => {
    if (starter && !hasDependencyToast(starter.id)) {
      const warningToastId = addToast({
        title: 'Alert',
        type: 'warning',
        message: messages.dependencyWarning(multiroomProduct.name, starter.name),
        action: async () => {
          // Add starter and handle SkyBox silently - no order updates to be called here (only on final adding of multiroom product)
          await offerCheck(starter, 1, false);
          await addSkyBox(false, false);

          const q = Math.max(0, (multiroomProduct.quantityInCart ?? 0) + diff);
          await offerCheck(multiroomProduct, q);
        },
        actionText: `Add ${starter.name}`,
        closeText: messages.noThanks,
        onClose: () => {
          removeFromDependencyToasts(starter.id);
        },
      });
      addToDependencyToasts(starter.id, warningToastId);
    }
  };

  /**
   * Add Sky starter to cart.
   * Use this instead of toggle product for adding starter as this does the sky box default logic correctly, as toggle wont trigger dependencyCheck
   * as starter is dependent on starter.
   */
  const buyStarter = async (trackEvent: boolean = false, buyBox: boolean = true, update: boolean = true) => {
    // starter not found or added already
    if (!starter || starterAdded) {
      return;
    }
    // Add Box with Sky Starter by default
    if (buyBox) {
      await addSkyBox(false, false, trackEvent);
    }

    /* Pass trackEvent so we don't track Starter in get.sky. */
    await toggleSingleProductWithUpdate(starter, 1, getCouponForProduct(starter), update, true, undefined, trackEvent);
  };

  /**
   * Empty cart
   * 1. Clear coupon
   * 2. Reset products
   * 3. Reset Order
   */
  const emptyCart = async () => {
    removeAllCoupons();
    clearProducts(konakartService);
    await clearOrder(konakartService, customerId);
  };

  /**
   * Attempts a downgrade if product is currently subscribed else toggle the quantity from cart
   *
   * @param product
   * @param quantity
   * @param broadbandJourney
   * @param trackEvent
   */
  const toggleOrDowngradeProduct = (product: T_Product, quantity: number, trackEvent?: boolean) => {
    const productFromStore = products.find(p => p.id === product.id && p.occurrenceNumber === product.occurrenceNumber);
    if (productFromStore?.quantityBought) {
      // Product is currently subscribed and being removed, just toggle product downgrade flag
      updateProductDowngrade(product.id, !productFromStore.downgrade, product.occurrenceNumber);
    } else {
      // Continue as normal adding/removing product in cart
      applyProduct(product.id, quantity, trackEvent, product.occurrenceNumber, {
        inPromotion: product.inPromotion,
        priceIncTax: product.priceIncTax,
      });
    }
  };

  /**
   * Handle scenario where we might have free products and removal of a product
   * will need to trigger a warning as Free Product will be gone too.
   * @param productId
   * @param quantity
   */
  const handleRemovalOfProductForFreeProducts = async (
    messageToRemoveFreeProduct: string,
    product: T_Product,
    quantity: number,
    update: boolean,
    removeAccessories: boolean
  ) => {
    if (messageToRemoveFreeProduct) {
      const action = () => {
        if (quantity === 0 && removeAccessories) removeProductAccessories(product.accessories);
        toggleOrDowngradeProduct(product, quantity);

        const { freeProduct } = freeProductStoreApi.getState();

        if (freeProduct) {
          if (!freeProduct.currentlySubscribed) {
            // If SoHo is part of the package and wasn't manually selected by the user,
            // remove it from the cart as well. Otherwise, just remove the free deal and
            // that comes with the SKY sports/movies deal
            if (freeProduct.product.sku !== soho || freeProduct.categoryName !== 'specialist-channel') {
              applyProduct(freeProduct.productId, 0);
            }
          } else {
            updateProductDowngrade(freeProduct.productId, !product.downgrade);
          }

          freeProductStoreApi.getState().clearFreeProduct();
        }
      };

      const onClose = async () => {
        removeFromDependencyToasts(product.id);
        if (update) {
          return await updateOrder(customerId, productStoreApi.getState().getBasketItemsToAddToOrder());
        }
      };

      // call to action the warning removal toast for free product
      await freeProductRemovalToast(product.id, messageToRemoveFreeProduct, onClose, action);
    }
  };

  /**
   * Toast to show when a currently subscribed free product becomes a paid one
   *
   * @param freeProduct
   * @param update
   */
  const handleAddingFreeProductWithoutPromo = async (freeProduct: T_Product, update: boolean) => {
    const action = () => {
      const orderProduct = order?.orderProducts?.find(op => op.product.id === freeProduct.id);
      if (orderProduct && orderProduct.currentlySubscribed) {
        freeProductStoreApi.getState().setFromFreeProduct(orderProduct);
        updateProductDowngrade(orderProduct.productId, !orderProduct.downgrade);
      }
    };

    const close = async () => {
      removeFromDependencyToasts(freeProduct.id);
      if (update) {
        await updateOrder(customerId, productStoreApi.getState().getBasketItemsToAddToOrder());
      }
    };

    const message = messages.freeProductPayInFull(freeProduct.name, freeProduct.price0);
    await freeProductAdditionToast(freeProduct.id, message, close, action);
  };

  // Removes product accessories
  const removeProductAccessories = (accessories: number[], broadbandJourney?: string, trackEvent?: boolean) => {
    let removals = [] as number[];

    let remover = (accessories: number[]) => {
      if (accessories && accessories.length > 0) {
        const accessoryProducts = productStoreApi
          .getState()
          .products.filter(x => accessories.includes(x.id) && x.quantityInCart > 0 && !removals.includes(x.id));
        for (const acce of accessoryProducts) {
          removals.push(acce.id);
          remover(acce.accessories);
          removeFromDependencyToasts(acce.id);
          applyProduct(acce.id, 0, trackEvent);
        }
      }
    };
    remover(accessories);
  };

  /**
   * Adds/removes a single product to cart and handles free product
   *
   * @param productId
   * @param quantity
   * @param couponObject
   * @param update
   */
  const toggleSingleProductWithUpdate = async (
    product: T_Product,
    quantity: number,
    couponObject?: T_Coupon,
    update: boolean = true,
    removeAccessories = true,
    broadbandJourney?: string,
    trackEvent?: boolean
  ) => {
    // Need to see if we are removing something that will impact free product
    // Also remove accessories (dependent products) silently from cart

    if (quantity === 0) {
      const isFreeProductRemovedProduct = removeFreeProductWithToast(product.id);
      const productToBeRemoved = productStoreApi.getState().products.find(x => x.id === product.id);

      // check if free product has relation to removed product
      if (isFreeProductRemovedProduct && productToBeRemoved) {
        // Handles the removal of the product and any actions around free product
        // False for offers as this current product has no offer for it
        const message = removalText(false, productToBeRemoved.name, undefined, couponObject);
        await handleRemovalOfProductForFreeProducts(message, product, quantity, update, removeAccessories);

        return;
      }

      const fromFreeProduct = freeProductStoreApi.getState().fromFreeProduct;
      if (fromFreeProduct) {
        freeProductStoreApi.getState().clearFromFreeProduct();
      }
    } else {
      const freeProductPayInFull = checkIfFreeProductWillBeFullyPaid(product);
      if (freeProductPayInFull) {
        await handleAddingFreeProductWithoutPromo(product, update);
        return;
      }
    }

    if (quantity === 0 && removeAccessories) {
      removeProductAccessories(product.accessories, '', trackEvent);
    }

    toggleOrDowngradeProduct(product, quantity, trackEvent);

    if (update) {
      await updateOrder(customerId, productStoreApi.getState().getBasketItemsToAddToOrder(isStaffAccount));
    }
  };

  const getProductTypeByCoupon = (coupon: T_Coupon): 'BOX' | 'BROADBAND' | 'OTHER' => {
    try {
      let product = productStoreApi.getState().products?.find(p => p.id === Number(coupon.custom1));

      if (product) {
        switch (product.sku) {
          case productSkuIds.arrowUpfrontBoxFee.primary:
          case productSkuIds.arrowBoxOneOff.primary:
          case productSkuIds.arrowBoxRecording.primary:
            return 'BOX';
          case productSkuIds.broadbandLightningFastWiFi.primary:
          case productSkuIds.broadbandLightningFastWiFiBoost.primary:
          case productSkuIds.broadbandWifi100.primary:
          case productSkuIds.broadbandWifi100Boost.primary:
            return 'BROADBAND';
        }
      }

      return 'OTHER';
    } catch (error) {
      return 'OTHER';
    }
  };

  const showCouponErrorToast = (couponName: string) => {
    addToast({
      title: 'Alert',
      type: 'error',
      message: `Offer code: ${couponName} is invalid`,
      time: 5,
    });
  };

  const hasCoupons = coupons.length !== 0;
  // picks the longest coupon durations
  const couponDuration = hasCoupons
    ? coupons.sort((a, b) => Number(b.custom4) - Number(a.custom4))[0].custom4
    : undefined;

  return {
    applyProduct,
    toggleProduct,
    toggleSingleProductWithUpdate,
    digitalRentalToggleProduct,
    emptyCart,
    buyStarter,
    addSkyBox,
    addMultiRoom,
    toggleBoxes,
    removeAllStarterDependentProducts,
    hasActiveStarter,
    starterAdded,
    accessAdded,
    allowCheckout,
    starter,
    entertainment,
    skyBox,
    boxInCart,
    routerDevice,
    routerName,
    meshDevice,
    meshAdded,
    routerAdded,
    meshName,
    oneOffFees,
    monthlyFees,
    multiroomsNumber,
    multirooms,
    acquisitionMultirooms,
    packages,
    packageUpgrades,
    specials,
    hindi,
    boxes,
    acquisitionBoxes,
    hasBox,
    coupons,
    hasCoupons,
    couponDuration,
    applyCouponCodeToCart,
    applyCouponCodeToState,
    orderTotals,
    cartInitialized,
    productStoreUpdated,
    order,
    validBoxIds,
    broadband,
    broadbandServices,
    broadbandDevices,
    broadbandOneOffFees,
    broadbandDiscounts,
    broadbandProductInCart,
    selfInstallAdded,
    technicianFee,
    broadbandTechnicianFee,
    allTechnicianFees,
    onlyBaseProductsAdded,
    getArrowBoxMR,
    swapMultipleProducts,
    changeProductsByQuantity,
    addMultipleProductsToCart,
    boxOptions,
    getProductAndAddons,
    getProductTypeByCoupon,
  };
}

export { useCartContainer };
