import { T_Product, T_Coupon } from '../..';
import { bothFreeProductOfferRemoval } from './scenarios/bothFreeProductOfferRemoval';
import { onlyFreeProductRemoval } from './scenarios/onlyFreeProductRemoval';
import { onlyOfferRemoval } from './scenarios/onlyOfferRemoval';

/**
 * Builder
 * @param isOfferAssociated
 * @param isInitiallyAdded
 * @param productName
 * @param couponProducts
 * @param couponObject
 * @param isFreeRelated
 * @param freeProduct
 */
const buildRemovalText = (
  isOfferAssociated: boolean,
  isInitiallyAdded: boolean,
  productName?: string,
  couponProducts?: Pick<T_Product, 'name' | 'priceIncTax' | 'id'>[],
  couponObject?: Pick<T_Coupon, 'custom1'>,
  isFreeRelated?: boolean,
  freeProduct?: Pick<T_Product, 'id' | 'name' | 'priceIncTax'>
) => {
  // bad values - return nothing
  if (!isOfferAssociated && !freeProduct) return '';

  // offer removing only
  const isOfferOnlyRemoval = isOfferAssociated && (!freeProduct || !isFreeRelated);
  // free product removing only
  const isFreeProductOnlyRemoval = !isOfferAssociated && freeProduct && productName;
  // hybrid: Coupon and Free product are associated
  const isOfferAndFreeProductRemoval = isOfferAssociated && freeProduct && isFreeRelated;

  // Remove a Product / Offer that is not associated with Free Product
  if (isOfferOnlyRemoval) {
    // remove offer, nothing to do with free product
    return onlyOfferRemoval(productName, couponProducts);
  }

  // Remove a Product that will remove Free Product
  if (isFreeProductOnlyRemoval) {
    return onlyFreeProductRemoval(productName!, freeProduct!, isInitiallyAdded, couponObject);
  }

  // Removes both offer and free product
  if (isOfferAndFreeProductRemoval) {
    return bothFreeProductOfferRemoval(isInitiallyAdded, productName, couponProducts, freeProduct);
  }

  // edge case - shouldn't be hit
  return '';
};

export { buildRemovalText };
