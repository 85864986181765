import create from 'zustand';
import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';
import { Prequal } from '../services/prequal';
import { NullableAddressLookup } from '../types';
import { sessionStorage } from '../helpers/sessionStorage';

const sessionStorageStateName = 'Broadband Delivery Store';
const getSessionStorageState = (): Partial<BroadbandDeliveryStore> => {
  const state = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}');
  return state;
};

export interface IBroadbandDelivery {
  mobileNumberAreaCode: string;
  mobileNumberLineNumber: string;
  email: string;
  installationAddress: string;
  useDifferentDeliveryAddress?: boolean;
  defaultDeliveryAddress: string[];
  deliveryAddress?: NullableAddressLookup;
}

interface PrequalData {
  addressId: string;
  data: Prequal | undefined;
}

interface BroadbandDeliveryStore {
  details?: IBroadbandDelivery;
  isValid: boolean;
  prequalData?: PrequalData;
  broadbandJourney?: string;
  setBroadbandDeliveryDetails: (value: IBroadbandDelivery) => void;
  setPrequalData: (value: PrequalData) => void;
  setIsValid: (value: boolean) => void;
  clearBroadbandDeliveryStore: () => void;
}

let [useBroadbandDeliveryStore, broadbandDeliveryStoreAPI] = create<BroadbandDeliveryStore>(
  ReduxDevTools(
    StatePersistence(set => {
      return {
        details: undefined,
        isValid: false,
        prequalData: undefined,
        broadbandJourney: '',
        ...getSessionStorageState(),
        setBroadbandDeliveryDetails: (value: IBroadbandDelivery) => {
          set({ details: value }, 'setBroadbandDeliveryDetails');
        },
        setPrequalData: (value: PrequalData) => {
          set({ prequalData: value }, 'setPrequalData');
        },
        setIsValid: (value: boolean) => {
          set({ isValid: value }, 'setIsValid');
        },
        clearBroadbandDeliveryStore: () => {
          set({ details: undefined, isValid: false, prequalData: undefined }, 'clearBroadbandDeliveryStore');
          sessionStorage.removeItem(sessionStorageStateName);
        },
      };
    }, sessionStorageStateName),
    'Broadband Delivery Store'
  )
);

export { useBroadbandDeliveryStore, broadbandDeliveryStoreAPI };
