import { couponCodes, messages, productSkuIds } from '../config';
import { KonakartService } from '../services/konakart';
import { couponStoreApi } from '../store/couponStore';
import { freeProductStoreApi, useFreeProductStore } from '../store/freeProductStore';
import { orderStoreApi } from '../store/orderStore';
import { useProductStore } from '../store/productStore';
import { T_OrderProduct, T_Product } from '../types';
import { useToastContainer } from './useToastContainer';

function useFreeProduct(kkService: KonakartService) {
  const { addSuccessToast, hasDependencyToast, addToDependencyToasts, addAwaitableToast } = useToastContainer();

  const { setFreeProduct, clearFreeProduct, setFromFreeProduct } = useFreeProductStore(s => ({
    setFreeProduct: s.setFreeProduct,
    clearFreeProduct: s.clearFreeProduct,
    setFromFreeProduct: s.setFromFreeProduct,
  }));

  const { products, changeProductQuantity } = useProductStore(s => ({
    products: s.products,
    changeProductQuantity: s.changeProductQuantity,
  }));

  /**
   * Checks if a free product will be paid in full
   * eg. Existing Soho product added to cart but one of the dependency is going to be removed
   */
  const checkIfExistingFreeProductIsToBePaid = () => {
    const orderProducts = orderStoreApi.getState().order?.orderProducts ?? [];
    for (const orderProduct of orderProducts) {
      const custom5Dependency = orderProduct.product.custom5?.split(',');
      if (custom5Dependency && custom5Dependency.length > 0) {
        const payFull = checkIfFreeProductWillBeFullyPaid(orderProduct.product);
        if (payFull && !orderProduct.downgrade) setFromFreeProduct(orderProduct);
      }
    }
  };

  /**
   * Checks if a paid product will be made free
   * eg. Existing Soho product added to cart containing all dependencies and is a subscribed product
   */
  const checkIfExistingProductCanBeMadeFree = (freeProductId: number | undefined) => {
    const orderProducts = orderStoreApi.getState().order?.orderProducts ?? [];
    const freeProduct = orderProducts.find(x => x.productId === freeProductId);
    const custom5Dependency = freeProduct?.product?.custom5?.split(',');
    // eg. 67:70,66:70
    // If customer will pay free product in full amount
    if (!custom5Dependency || custom5Dependency.length < 1) return false;

    for (const dependencySet of custom5Dependency) {
      const dependencySetIds = dependencySet.split(':').map(x => parseInt(x));
      // eg. 67:70
      let depsFound = 0;

      for (const dependencyId of dependencySetIds) {
        const dependencyFound = orderProducts.find(x => x.productId === dependencyId);
        if (dependencyFound) depsFound++;
      }

      if (depsFound === dependencySetIds.length) return true;
    }
    return false;
  };

  /**
   * To be called at the start of app load to see if we currently have free product already to set it to storage
   * This ensures us to know that when its added next time we can always show a toast.
   */
  const toggleCheckFreeProductOnLoad = () => {
    // check to see if we have free product in order
    const ot_FreeProduct = orderStoreApi.getState().order?.orderTotals.find(t => t.className === 'ot_free_product');
    if (ot_FreeProduct && ot_FreeProduct.freeProductId) {
      // grab corresponding free product
      const freeOrderProduct = orderStoreApi
        .getState()
        .order?.orderProducts.find(x => x.productId === ot_FreeProduct.freeProductId);

      // ensure that free product exists, and no free product in our store
      if (freeOrderProduct) {
        setFreeProduct(freeOrderProduct);
      }
    } else {
      checkIfExistingFreeProductIsToBePaid();
      clearFreeProduct();
    }
  };

  /**
   * Handle this check every time we do an update cart to ensure we have the free product checked and updated
   * @param orderProduct
   */
  const toggleCheckFreeProduct = () => {
    // get latest free product
    const freeProduct = freeProductStoreApi.getState().freeProduct;
    // check to see if we have free product in order
    const ot_FreeProduct = orderStoreApi.getState().order?.orderTotals.find(t => t.className === 'ot_free_product');

    if (ot_FreeProduct && ot_FreeProduct.freeProductId) {
      // grab corresponding free product
      const freeOrderProduct = orderStoreApi
        .getState()
        .order?.orderProducts.find(x => x.productId === ot_FreeProduct.freeProductId);

      // ensure that free product exists, and no free product in our store
      if (freeOrderProduct && !freeProduct) {
        const product = products.find(x => freeOrderProduct.productId === x.id);
        const initiallyAdded = product && product.quantityInCart > 0 ? true : false;
        const retainSohoInCart = initiallyAdded && !freeOrderProduct.currentlySubscribed;

        if (!freeOrderProduct.currentlySubscribed) {
          // If we find free product here,
          // it means that it was not added at load. So its new, so show toast.
          // need to wait for the coupon store to be updated before checking if the coupon is in the store
          // create a delay to wait for the store to be updated
          setTimeout(() => {
            const has01277 = couponStoreApi
              .getState()
              .coupons.some(c => c.couponCode === couponCodes.oneMonthOnUsWithSport);
            /** Special title for SoHo toast. */
            if (!has01277) {
              let title = freeOrderProduct.product.sku === productSkuIds.soho.primary ? 'You’ve got SoHo!' : 'Success';
              addSuccessToast({ title: title, message: freeOrderProduct.promotionTitle });
            }
          }, 1000);
        }
        setFreeProduct(freeOrderProduct, retainSohoInCart);

        if (!retainSohoInCart) {
          changeProductQuantity(freeOrderProduct.productId, 1);
        }
      }
    } else {
      // case when coupon applied on said free product
      // if so - clear the free product as its no longer that
      if (freeProduct && !freeProduct.currentlySubscribed) {
        // if its not initially added and free product exist - we remove it from cart
        changeProductQuantity(freeProduct.productId, 0);
      }
      // clear cart
      clearFreeProduct();
      // only when another update is done and we add back the free product
    }
  };

  /**
   * Returns true if product is being removed and it will also remove free product
   */
  const removeFreeProductWithToast = (productId: number): boolean => {
    // true if we remove the product and it will remove free product
    const freeProductLatest = freeProductStoreApi.getState().freeProduct;
    if (freeProductLatest && checkIfProductDependentForFreeProduct(freeProductLatest, productId)) {
      return true;
    }
    return false;
    // don't care about others, do as please with the product
  };

  /**
   * The removal toast
   * @param productId
   * @param message
   * @param onClose
   * @param action
   */
  const freeProductRemovalToast = (
    productId: number,
    message: string,
    onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any>,
    action: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
  ) => {
    if (hasDependencyToast(productId)) return;
    const { toastId, promise } = addAwaitableToast({
      title: messages.areYouSure,
      type: 'warning',
      message: message,
      action: action,
      actionText: messages.removeProduct,
      closeText: messages.noThanks,
      onClose: onClose,
    });
    addToDependencyToasts(productId, toastId);
    return promise;
  };

  /**
   * Product being the one we are thinking of removing
   * e.g. 66:70,67:70
   * @param product
   */
  const checkIfProductDependentForFreeProduct = (
    freeOrderProduct: T_OrderProduct,
    removedProductId: number
  ): boolean => {
    // split to check across all possible combinations to trigger free product
    const freeProductDependencyArray = freeOrderProduct.product.custom5.split(',');
    let combinationWithRemovedProductFound = false;
    let otherCombinationFound = false;
    freeProductDependencyArray.forEach(dep => {
      // get product ids of combination
      const productIdCombinationArray = dep.split(':').map(m => parseInt(m));
      const searchDepends = products.filter(p => productIdCombinationArray.includes(p.id) && p.quantityInCart > 0);
      // check if the combination matches
      if (searchDepends.length === productIdCombinationArray.length) {
        // case when we have combination that is truthy and does not include the product being removed. So removing will still result in free product for customer.
        if (!productIdCombinationArray.includes(removedProductId)) {
          // combination doesn't match removed product id
          // we found another combination that matches so
          otherCombinationFound = true;
          return;
        } else {
          // Combination includes the product that is being removed, so could remove freeProduct if no other combination found
          // We cannot break out of the loop here as have to verify if other combination allows free product still to be in cart or not
          combinationWithRemovedProductFound = true;
        }
      }
    });
    // if otherCombinationFound - true: means that free product is not dependent on the removed product
    // if combinationWithRemovedProductFound - true: means that free product is dependent if otherCombinationFound is false
    return combinationWithRemovedProductFound && !otherCombinationFound ? true : false;
  };

  /**
   * Adding the free product will be paid in toast
   *
   * @param productId
   * @param message
   * @param onClose
   * @param action
   */
  const freeProductAdditionToast = (
    productId: number,
    message: string,
    onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any>,
    action: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
  ) => {
    if (hasDependencyToast(productId)) return;
    const { toastId, promise } = addAwaitableToast({
      title: messages.areYouSure,
      type: 'warning',
      message: message,
      action: action,
      actionText: messages.addProduct,
      closeText: messages.noThanks,
      onClose: onClose,
    });
    addToDependencyToasts(productId, toastId);
    return promise;
  };

  /**
   * This method checks if the free product is going to be paid in full and not free anymore.
   * eg. A free product is currently subscribed but its parent product (eg. Sky Movies or Sky Entertainment)
   * is downgraded.
   */
  const checkIfFreeProductWillBeFullyPaid = (freeProduct: T_Product) => {
    const orderProducts = orderStoreApi.getState().order?.orderProducts ?? [];
    const freeProductDependencySet = freeProduct.custom5?.split(','); // eg. 67:70,66:70
    let payFull = false; // If customer will pay free product in full amount

    // not a free product since no dependency is available or is empty
    if (!freeProductDependencySet || freeProductDependencySet.length < 1) return false;

    for (const dependencySet of freeProductDependencySet) {
      const dependencies = dependencySet.split(':').map(id => parseInt(id)); // eg. 67:70
      let depsFound = 0;
      let depsDowngraded = false;

      for (const orderProduct of orderProducts) {
        if (dependencies.includes(orderProduct.productId)) {
          depsFound += 1;
          if (orderProduct.downgrade) {
            depsDowngraded = true;
          }
        }
      }

      // All dependencies are found in this set and at least one is downgraded
      // Free product is going to be paid in full
      if (depsFound === dependencies.length && depsDowngraded) {
        payFull = true;
        return payFull;
      }
    }

    return payFull;
  };

  /** Returns whether SoHo is removable or not. */
  const isSoHoRemovable = (
    notCurrentlySubscribedFreeProducts: T_OrderProduct[] | undefined,
    freeProduct: T_OrderProduct
  ) => {
    return notCurrentlySubscribedFreeProducts &&
      notCurrentlySubscribedFreeProducts.length > 0 &&
      freeProduct?.product?.sku === productSkuIds.soho.primary
      ? false
      : true;
  };

  return {
    toggleCheckFreeProduct,
    freeProductRemovalToast,
    freeProductAdditionToast,
    removeFreeProductWithToast,
    toggleCheckFreeProductOnLoad,
    clearFreeProduct,
    checkIfFreeProductWillBeFullyPaid,
    isSoHoRemovable,
    checkIfExistingProductCanBeMadeFree,
  };
}

export { useFreeProduct };
