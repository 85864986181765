import React from 'react';

/**
 * A form control
 */

interface FormProps {
  onSubmit: Function;
}

const FormElement = ({ onSubmit, ...props }: FormProps & Omit<React.HTMLProps<HTMLFormElement>, 'onSubmit'>) => {
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        onSubmit();
      }}
      {...props}
    />
  );
};

export { FormElement };
