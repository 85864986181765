import { KonakartService } from '../services/konakart';
import { useToastContainer } from './useToastContainer';
import { productStoreApi, useProductStore } from '../store/productStore';
import { CouponType, T_Coupon } from '../types';
import { useCustomerStore } from '../store/customerStore';
import { useCouponStore } from '../store/couponStore';
import { orderStoreApi } from '../store/orderStore';
import { usePrimaryBoxRequirementCheck } from './usePrimaryBoxRequirementCheck';
import { useUpdateCart } from './useUpdateCart';
import { productSkuIds } from '../config';
import { shouldShowCouponToast } from './couponToastHelper';

/**
 * Hook for CouponCode logic.
 *
 * Includes popping toasts and validation.
 */
function useCouponCode(kkService: KonakartService) {
  const { addToast, addSuccessToast } = useToastContainer();
  const { customerId } = useCustomerStore(s => ({ customerId: s.kk }));
  const { updateFromOrderProducts } = useProductStore(s => ({ updateFromOrderProducts: s.updateFromOrderProducts }));
  const { requirePrimaryBox } = usePrimaryBoxRequirementCheck(kkService);
  const { applyCouponToStore, clearCoupon, getCouponFromCode } = useCouponStore(s => ({
    applyCouponToStore: s.applyCouponToStore,
    clearCoupon: s.clearCoupon,
    getCouponFromCode: s.getCouponFromCode,
  }));
  const { updateOrder } = useUpdateCart(kkService);

  const getCouponFromCouponCode = async (couponCodeToCheck: string) => {
    return await kkService.getCoupon(couponCodeToCheck);
  };

  const isCouponCodeValid = async (couponCode: string) => {
    return await kkService.checkCouponCode(couponCode);
  };

  const productAlreadyInPromotToast = (productName: string, term: string) => {
    addToast({
      title: 'Alert',
      type: 'error',
      message: `Can't apply coupon. ${productName} is already ${term}.`,
      time: 5,
    });
  };

  const invalidToast = (couponCode: string) => {
    addToast({
      title: 'Alert',
      type: 'error',
      message: `Offer code: ${couponCode} is invalid`,
      time: 5,
    });
  };

  const successToast = (couponCode?: string) => {
    addSuccessToast({
      message: couponCode != null ? `Offer code: ${couponCode} applied` : `Offer removed`,
    });
  };

  const invalidCoupon = (couponCode?: string) => {
    if (couponCode) invalidToast(couponCode);
    return false;
  };

  const alreadyDiscounted = (productName: string, term: string) => {
    productAlreadyInPromotToast(productName, term);
    return false;
  };

  /**
   * Applies coupon if couponProducts is not yet bought.
   *
   * If update is true, updates KK with coupon.
   * This will add the product to order. You only need to set this to false in "applyCouponCodeToState" method
   *
   * @param couponObject
   * @param update
   */
  const applyCoupon = async (
    couponObject?: T_Coupon,
    update: boolean = true,
    type?: CouponType,
    showToast: boolean = true
  ) => {
    // find the product in cart and stop applying the coupon if already been bought
    const couponProducts = couponObject?.custom1.split(',');
    const productsToAdd = productStoreApi.getState().products.filter(p => couponProducts?.includes(p.id.toString()));
    const productBought = productsToAdd.filter(p => p.quantityBought !== 0);
    // allow for acquisition coupons where starter dependency has already in cart
    const productInCart = productsToAdd.filter(p => p.quantityInCart !== 0 && p.sku !== productSkuIds.starter.primary);

    if (couponObject) {
      const isCouponAlreadyApplied = getCouponFromCode(couponObject?.couponCode) ?? false;

      if (isCouponAlreadyApplied && productBought.length === couponProducts?.length) {
        clearCoupon(couponObject);
        return alreadyDiscounted(productBought[0].name, 'bought');
      } else if (isCouponAlreadyApplied && productInCart.length === couponProducts?.length) {
        return alreadyDiscounted(productInCart[0].name, 'discounted');
      } else {
        requirePrimaryBox(couponObject, false);
        applyCouponToStore(couponObject);
      }
    }

    if (update) {
      // Call konakart service with the coupon. KK service will add the product if it's not yet in cart
      await updateOrder(customerId, productStoreApi.getState().getBasketItemsToAddToOrder());
      // after the order we then need to update the products with the correct quantity
      const orderProducts = orderStoreApi.getState().order?.orderProducts;

      if (orderProducts) {
        updateFromOrderProducts(orderProducts);
      }
    }

    if (couponObject) {
      if (!(await shouldShowCouponToast(couponObject))) {
        // successToast(couponObject.couponCode);
      } else if (showToast) {
        successToast(couponObject.couponCode);
      }
    }

    return !!couponObject;
  };

  /**
   * Applies a coupon code to cart.
   *
   * Handles multiple coupon codes.
   *
   * @param couponCode coupon code to apply
   * @param type Coupon type either ACQUISITION or UPGRADE
   */
  const applyCouponCodeToCart = async (couponCode: string, type?: CouponType): Promise<boolean> => {
    try {
      // If there's a coupon code. But it can be acqusition/upgrade need to find matching page
      const couponCodeArray = couponCode.split(',');
      let couponArray: T_Coupon[] = [];

      for (let couponValue of couponCodeArray) {
        let couponObject = await getCouponFromCouponCode(couponValue);

        if (couponObject) {
          couponArray.push(couponObject);
        }
      }

      for (let couponValue of couponArray) {
        // Check coupon first before applying.
        if (couponValue && couponValue.custom3?.toUpperCase() === type) {
          // first one we find matching the type return
          return applyCoupon(couponValue, true, type);
        }
      }

      return invalidCoupon();
    } catch (e) {
      return invalidCoupon();
    }
  };

  /**
   * Applies a coupon code to store - not update the order!!
   * This entire process is synchronous
   * This is to be used in the toast action method, and onclose do the update to konakart
   *
   * Only works with single coupon
   *
   * @param couponObject coupon code to apply
   * @param type Coupon type either ACQUISITION or UPGRADE
   */
  const applyCouponCodeToState = (couponObject: T_Coupon, type?: CouponType) => {
    try {
      // Check coupon first before applying.
      if (couponObject && couponObject.custom3?.toUpperCase() === type) {
        return applyCoupon(couponObject, false /* do not update */, type, false);
      }
      return invalidCoupon();
    } catch (e) {
      return invalidCoupon();
    }
  };

  /**
   * Removes coupon from cart or state
   *
   * @param couponObject The coupon object
   * @param update Update KK and order products
   * @param type Coupon type either ACQUISITION or UPGRADE
   */

  const removeCouponFromCart = async (couponObject: T_Coupon, update?: boolean, type?: CouponType) => {
    // if type is present, check for coupon type before removing
    if (couponObject && (type ? couponObject.custom3?.toUpperCase() === type : true)) {
      clearCoupon(couponObject);

      if (update) {
        // Call konakart service with the coupon. KK service will add the product if it's not yet in cart
        await updateOrder(customerId, productStoreApi.getState().getBasketItemsToAddToOrder());
        // after the order we then need to update the products with the correct quantity
        const orderProducts = orderStoreApi.getState().order?.orderProducts;

        if (orderProducts) {
          updateFromOrderProducts(orderProducts);
        }
      }
    }
  };

  return {
    getCouponFromCouponCode,
    isCouponCodeValid,
    applyCouponCodeToCart,
    applyCouponCodeToState,
    removeCouponFromCart,
  };
}

export { useCouponCode };
