import { compareDesc, parseISO, max } from 'date-fns';
import { useMemo } from 'react';
import { categoryIds, productSkuIds, broadbandCategories, voiceCategories, PACKAGE_TYPE } from '../config';
import { useOrderStore } from '../store/orderStore';
import { useProductStore } from '../store/productStore';
import { checkProductIsBroadbandMeshPackage, getActiveCampaigns } from '../swr/helper';
import { MyAccountSWR } from '../swr/myaccount';
import { MyAccountV2SWR } from '../swr/myaccountV2';
import { T_OrderProduct, T_Product, BroadbandDeliveryType, CouponTypeValue, T_Order } from '../types';
import sumBy from 'lodash/sumBy';
import { useCouponStore } from '../store/couponStore';
import { addMonths, format } from 'date-fns';
import cloneDeep from 'lodash.clonedeep';
import { useAnalytics } from './useAnalytics';
import { useboxesStore } from '../store/boxesStore';

export function useManagePackage(myAccountSWR: MyAccountSWR) {
  const { order, initializedOrderStore } = useOrderStore();
  const { products, initializedProductStore } = useProductStore();
  const { coupons } = useCouponStore(s => ({
    coupons: s.coupons,
  }));
  const boxesFromStore = useboxesStore(s => s.boxes);

  // boolean to make sure order store and product store is fully loaded
  const useManagePackageInitialized = initializedOrderStore && initializedProductStore;
  const { checkoutStarted } = useAnalytics();

  // as discussed, should only have one active campaign in query2
  // campaignCode is not a KK couponCode.
  const { data: billing } = myAccountSWR.useBilling();

  const available = useMemo(() => {
    return products
      .filter(
        p =>
          p.categoryId !== categoryIds.oneOffFee &&
          p.categoryId !== categoryIds.broadbandOneOffFee &&
          p.categoryId !== categoryIds.technicianFee &&
          p.categoryId !== categoryIds.broadbandTechnicianFee
      )
      .sort((a, b) => {
        return (b.rating ?? 0) - (a.rating ?? 0);
      });
  }, [products]);

  const setBroadbandDiscount = (p: T_Product) => {
    if (p.categoryId === categoryIds.broadband && hasActiveStarter && p.price1 && p.priceIncTax > p.price1) {
      p.priceIncTax = p.price1;
    }
    return p;
  };

  const hasActiveStarter = products.find(p => p.sku === productSkuIds.starter.primary && p.quantityBought > 0);
  // Access account.
  const hasActiveAccess = products.find(p => p.sku === productSkuIds.access.primary && p.quantityBought > 0);
  // Active broadband
  const hasBroadband = products.some(p => p.categoryId === categoryIds.broadband && p.quantityBought > 0);

  // entertainment products.eg. Sky Ent Light/Premium/Plantinum
  const entertainmentProducts = useMemo(() => {
    return available.filter(p => p.categoryId === categoryIds.entertainment);
  }, [available]);

  // products that are in current subscription
  const currentlySubscribedProducts = useMemo(() => {
    return available.filter(p => p.quantityBought > 0).map(setBroadbandDiscount);
    // eslint-disable-next-line
  }, [available]);

  // products that are in current subscription
  const currentlySubscribedMonthlyProducts = useMemo(() => {
    return available
      .filter(p => p.quantityBought > 0)
      .filter(
        r =>
          r.categoryId !== categoryIds.oneOffFee &&
          r.categoryId !== categoryIds.broadbandOneOffFee &&
          r.categoryId !== categoryIds.technicianFee &&
          r.categoryId !== categoryIds.broadbandTechnicianFee
      )
      .map(setBroadbandDiscount);
    // eslint-disable-next-line
  }, [available]);

  // products not in the current subscription
  const notCurrentlySubscribedProducts = useMemo(() => {
    return available.filter(p => p.quantityBought <= 0);
  }, [available]);

  // TV products that are in current subscription
  const currentlySubscribedTVProducts = useMemo(() => {
    return available.filter(
      p =>
        p.quantityBought > 0 &&
        !broadbandCategories.includes(p.categoryId) &&
        !voiceCategories.includes(p.categoryId) &&
        p.categoryId !== categoryIds.additionalCharges
    );
  }, [available]);

  const currentlySubscribedBoxProducts = useMemo(() => {
    return available.filter(p => p.quantityBought > 0 && p.categoryId === categoryIds.box);
  }, [available]);
  const currentlySubscribedMySkyPlusBoxProducts = useMemo(() => {
    return available.filter(
      p => p.quantityBought > 0 && p.categoryId === categoryIds.box && p.sku === productSkuIds.mySkyPlus.primary
    );
  }, [available]);
  const currentlySubscribedMultiroomProducts = useMemo(() => {
    return available.filter(p => p.quantityBought > 0 && p.categoryId === categoryIds.multiroom);
  }, [available]);

  // Voice products that are in current subscription
  const currentlySubscribedVoiceProducts = useMemo(() => {
    return available.filter(p => p.quantityBought > 0 && voiceCategories.includes(p.categoryId));
  }, [available]);

  // Broadband products that are in current subscription
  const currentlySubscribedBroadbandProducts = useMemo(() => {
    return available
      .filter(p => p.quantityBought > 0 && broadbandCategories.includes(p.categoryId))
      .map(setBroadbandDiscount);
    // eslint-disable-next-line
  }, [available]);

  const broadbandProducts = useMemo(() => {
    return available.filter(p => p.categoryId === categoryIds.broadband);
  }, [available]);

  /**
   * Manage packages purchased products in cart
   */
  // Getting products for cart
  const monthlyFees = (order?.orderProducts ?? []).filter(
    r =>
      r.product.categoryId !== categoryIds.oneOffFee &&
      r.product.categoryId !== categoryIds.broadbandOneOffFee &&
      r.product.categoryId !== categoryIds.technicianFee &&
      r.product.categoryId !== categoryIds.broadbandTechnicianFee
  );
  const oneOffFees = (order?.orderProducts ?? []).filter(
    r => r.product.categoryId === categoryIds.oneOffFee || r.product.categoryId === categoryIds.broadbandOneOffFee
  );
  // Getting equivalent order products
  const orderProductsBoughtInCart = [...monthlyFees, ...oneOffFees].filter(productInCart =>
    currentlySubscribedProducts.find(existingProductInCart => existingProductInCart.id === productInCart.productId)
  );

  const productsUserIsTryingToUpgrade = monthlyFees.filter(
    mf => notCurrentlySubscribedProducts.find(ncsp => ncsp.id === mf.productId) != null
  );

  // Check to see if multiroom upgrade is in cart
  const multirooms = products.filter(p => p.categoryId === categoryIds.multiroom);
  const acquisitionMultirooms = multirooms.filter(b => b.custom4?.toUpperCase() === CouponTypeValue.Acquisition);
  const acquisitionMultiroomCategoryIds = [categoryIds.multiroom];
  const acquisitionMultiroomProducts = order?.orderProducts?.filter(
    p =>
      acquisitionMultiroomCategoryIds.find(pc => pc === p.categoryId) &&
      p.product &&
      p.product.custom4?.toUpperCase() === CouponTypeValue.Acquisition
  );
  const multiroomInCart = acquisitionMultiroomProducts
    ?.map(p => {
      let _p = { ...p };
      _p.quantity = p.quantity - (acquisitionMultirooms.find(a => a.id === p.productId)?.quantityBought ?? 0);
      return _p;
    })
    .find(p => p.quantity > 0);

  // const orderProductsAddedToCart = [...monthlyFees, ...oneOffFees].filter(productInCart =>
  //   notCurrentlySubscribedProducts.find(newProductInCart => newProductInCart.id === productInCart.productId)
  // );

  const GetOrderProductsAddedToCart = () => {
    let _orderProductsAddedToCart = [...monthlyFees, ...oneOffFees].filter(
      productInCart =>
        productInCart.categoryId !== categoryIds.multiroom &&
        notCurrentlySubscribedProducts.find(newProductInCart => newProductInCart.id === productInCart.productId)
    );

    //Handle multiroom products seperately
    const multiroomProducts = order?.orderProducts?.filter(p => p.categoryId === categoryIds.multiroom);

    if (multiroomProducts) {
      multiroomProducts.forEach(p => {
        //Get existing product
        let existingProduct = acquisitionMultirooms.find(ep => ep.id === p.productId);

        //Set quantity of new product
        if (existingProduct) {
          let difference = existingProduct.quantityInCart - existingProduct.quantityBought;

          //Add (n = difference) of products
          for (let i = 0; i < difference; i++) {
            _orderProductsAddedToCart = [..._orderProductsAddedToCart, p];
          }
        } else {
          _orderProductsAddedToCart = [..._orderProductsAddedToCart, p];
        }
      });
    }

    return _orderProductsAddedToCart;
  };

  const orderProductsAddedToCart = GetOrderProductsAddedToCart();

  /** This is actually working but was missing special category. */
  const dthProductsAddedToCart = [...monthlyFees, ...oneOffFees].filter(productInCart =>
    notCurrentlySubscribedProducts.find(
      newProductInCart =>
        newProductInCart.id === productInCart.productId &&
        (newProductInCart.categoryId === categoryIds.box ||
          newProductInCart.categoryId === categoryIds.packageUpgrade ||
          newProductInCart.categoryId === categoryIds.multiroom ||
          newProductInCart.categoryId === categoryIds.hindiChannel ||
          newProductInCart.categoryId === categoryIds.special)
    )
  );

  //Broadband, voice and Broadband devices
  const broadbandBBdevicesAndVoiceProducts = [...monthlyFees, ...oneOffFees].filter(productInCart =>
    notCurrentlySubscribedProducts.find(
      newProductInCart =>
        newProductInCart.id === productInCart.productId &&
        (newProductInCart.categoryId === categoryIds.broadband ||
          newProductInCart.categoryId === categoryIds.voice ||
          newProductInCart.categoryId === categoryIds.voiceAddons ||
          newProductInCart.categoryId === categoryIds.voiceCrossCountry ||
          newProductInCart.categoryId === categoryIds.broadbandDevices)
    )
  );

  // Will contain the three broadband installation types
  const BroadbandInstallationTypes = products.filter(x => x.categoryId === categoryIds.broadbandServices);
  const BroadbandInstallationOTCTypes = products.filter(x => x.categoryId === categoryIds.broadbandOneOffFee);
  const BroadbandInstallationDevices = products.filter(x => x.categoryId === categoryIds.broadbandDevices);
  const BroadbandTechnicianFees = products.filter(x => x.categoryId === categoryIds.broadbandTechnicianFee);

  // The two broadband installation types
  const broadbandByod = BroadbandInstallationTypes.find(
    x => x.sku === productSkuIds.broadbandBringYourOwnDevice.primary
  );
  const broadbandSelfInstallation = BroadbandInstallationTypes.find(
    x => x.sku === productSkuIds.broadbandSelfInstall.primary
  );

  // Sky Router that is added with Perfect Installation and Self Install
  const skyRouter = BroadbandInstallationDevices.find(x => x.sku === productSkuIds.skyRouter.primary);

  // Mesh Device (single) that is only availble for non BYOD
  const meshDevice = BroadbandInstallationDevices.find(x => x.sku === productSkuIds.meshDevice.primary);

  // Static Ip for broadband
  const staticIp = BroadbandInstallationDevices.find(x => x.sku === productSkuIds.broadbandStaticIP.primary);

  // Perfect Install for broadband
  const perfectInstall = BroadbandTechnicianFees.find(x => x.sku === productSkuIds.broadbandPerfectInstall.primary);

  // OTC charges for broadband
  const broadbandSelfInstallationOTC = BroadbandInstallationOTCTypes.find(
    x => x.sku === productSkuIds.broadbandSelfInstallOTC.primary
  );

  // Currently there should only ever be one
  const currentBroadbandInstallationTypes = [...monthlyFees, ...oneOffFees].filter(productInCart =>
    BroadbandInstallationTypes.find(newProductInCart => newProductInCart.id === productInCart.productId)
  );

  // Only a single installation type should be possible so will only ever be one.
  const currentInstallationType = currentBroadbandInstallationTypes[0];

  // Check to see if broadband is in cart
  const broadbandInCart: T_OrderProduct | undefined = orderProductsAddedToCart.find(
    x => x.product.categoryId === categoryIds.broadband
  );

  //search through items added to cart and find the upgraded box
  const upgradableBoxInCart = productsUserIsTryingToUpgrade.find(p => p.categoryId === categoryIds.upgradableBoxes);

  const arrowUpgradableBoxInCart = productsUserIsTryingToUpgrade.find(
    p =>
      p.categoryId === categoryIds.upgradableBoxes &&
      p.product &&
      p.product.sku.includes(productSkuIds.arrowBox.primary)
  );

  //TODO - remove this when we have arrow products with/without recording in KK
  const arrowRecording = products.find(p => p.categoryId === categoryIds.recording);

  //New Sky Box products

  const arrowTechVisit = products.find(x => x.sku === productSkuIds.ArrowTechVisit.primary);

  const arrowUpfrontPayment = products.find(p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary);

  const arrowMonthlyPayment = products.find(p => p.sku === productSkuIds.arrowMonthly.primary);

  //is there an arrow box in Cart
  const newOrUpgradedBoxesFromStore = boxesFromStore.filter(b => b.boxType === 'NEW' || b.boxType === 'UPGRADE');
  const newBoxesFromStore = boxesFromStore.filter(b => b.boxType === 'NEW').length > 0;

  const arrowInCart =
    newOrUpgradedBoxesFromStore.filter(box =>
      box.products.find(
        product => product.sku === productSkuIds.arrowBox.primary || product.sku === productSkuIds.arrowBoxBlack.primary
      )
    ).length > 0;

  //has arrow tech visit been added to cart
  const arrowTechVisitInCart = (order?.orderProducts ?? []).find(
    x => x.product && x.categoryId === categoryIds.oneOffFee && x.product.sku === productSkuIds.ArrowTechVisit.primary
  );

  const arrowRecordingInCart = productsUserIsTryingToUpgrade.find(
    product => product.categoryId === categoryIds.recording && product.product.sku !== productSkuIds.noRecording.primary
  );

  const broadbandInFECart = products
    .filter(p => p.categoryId === categoryIds.broadband)
    .some(p => p.quantityInCart > 0 && p.quantityBought === 0);
  const voiceInCart = orderProductsAddedToCart.find(x => x.product.categoryId === categoryIds.voice);
  //  in cart on frontend, might not be sync with backend yet.
  const voiceInFECart = products
    .filter(p => p.categoryId === categoryIds.voice)
    .some(p => p.quantityInCart > 0 && p.quantityBought === 0);
  const staticIpInCart = orderProductsAddedToCart.find(x => x.product.sku === productSkuIds.broadbandStaticIP.primary);
  const staticIpInFECart = products
    .filter(p => p.sku === productSkuIds.broadbandStaticIP.primary)
    .some(p => p.quantityInCart > 0 && p.quantityBought === 0);

  const byodInCart = orderProductsAddedToCart.find(
    x => x.product.sku === productSkuIds.broadbandBringYourOwnDevice.primary
  );
  const meshInCart = orderProductsAddedToCart.find(x => x.product.sku === productSkuIds.meshDevice.primary);

  // Get currently subscribed broadbandPackage, can only ever be one
  const currentlySubscribedBroadbandPackage = currentlySubscribedProducts.find(
    p => p.categoryId === categoryIds.broadband
  );

  // Campaigns
  const existingCampaigns = getActiveCampaigns(billing?.campaigns ?? []);
  const hasExistingCampaign = existingCampaigns ? existingCampaigns.length > 0 : false;

  // Gets the farthest campaign end date in active campaigns
  const lastCampaignEndDate = hasExistingCampaign
    ? existingCampaigns?.sort((c1, c2) => {
        const dateLeft = c1.endDate ? parseISO(c1.endDate) : new Date();
        const dateRight = c2.endDate ? parseISO(c2.endDate) : new Date();
        return compareDesc(dateLeft, dateRight);
      })[0]?.endDate
    : undefined;

  // Looks through coupons and Campaigns andfind  the one with the farthest end date
  const couponDuration =
    coupons.length > 0 ? coupons.sort((a, b) => Number(b.custom4) - Number(a.custom4))[0].custom4 : undefined;

  const lastCouponEndDate = addMonths(new Date(), couponDuration ? +couponDuration : 0);
  let allActiveCouponsEndDate = '';

  try {
    allActiveCouponsEndDate = lastCampaignEndDate
      ? format(max([parseISO(lastCampaignEndDate), lastCouponEndDate]), 'do MMMM yyyy')
      : couponDuration
      ? format(lastCouponEndDate, 'do MMMM yyyy')
      : '';
  } catch (err) {}

  // if the customer as a Rental box (broadcast Tier) in the current Subcription
  const { isBroadcastTier, accountIssue, broadbandOnlyAccount } = myAccountSWR.usePackages();

  const getBroadbandRequiresDelivery = () => {
    let result = BroadbandDeliveryType.NONE;

    // Broadband is in cart, whether we allow navigation to installations page needs to be considered
    if (broadbandInCart) {
      // If no currently subscribed broadband packages, allow navigation as all new customers need to fill in their details
      if (!currentlySubscribedBroadbandPackage) {
        return BroadbandDeliveryType.ADDING;
      } else {
        const cartPackageHasHardware = checkProductIsBroadbandMeshPackage(broadbandInCart.product.sku);
        const currentlySubscribedBroadbandPackageHasHardware = checkProductIsBroadbandMeshPackage(
          currentlySubscribedBroadbandPackage.sku
        );

        // Two cases
        // User is upgrading from non mesh to mesh, then address needed for delivery
        // User is downgrading from mesh to non mesh, then address needed to deliver package that customer will use to return device
        if (cartPackageHasHardware !== currentlySubscribedBroadbandPackageHasHardware) {
          return cartPackageHasHardware
            ? BroadbandDeliveryType.CHANGE_REQUIRES_DELIVERY
            : BroadbandDeliveryType.CHANGE_REQUIRES_RETURN;
        }
      }
    }
    return result;
  };

  // Updated BB Plan
  const currentlySelectedBroadbandPlanForUpdate = notCurrentlySubscribedProducts
    .filter(p => p !== undefined)
    .filter(ncsp => ncsp.categoryId === categoryIds.broadband)
    .find(p => p.quantityInCart >= 1);

  const newCoupons = coupons?.filter(
    coupon => !currentlySubscribedProducts.find(csp => csp.id === Number(coupon?.custom1))
  );

  const analyticsCheckoutStarted = () => {
    //create new order object with only newly added products
    let tempOrder: T_Order | undefined = cloneDeep(order);
    if (tempOrder) {
      tempOrder.orderProducts = orderProductsAddedToCart;

      let totalIndex = tempOrder.orderTotals.findIndex(ot => ot.className === 'ot_total');
      let total = tempOrder.orderTotals.find(ot => ot.className === 'ot_total');
      if (total) {
        let totalValue = 0;
        tempOrder.orderProducts.forEach(op => (totalValue += op.price));
        total.value = totalValue;
        tempOrder.orderTotals[totalIndex] = total;
      }

      let packageType =
        dthProductsAddedToCart &&
        dthProductsAddedToCart.length > 0 &&
        broadbandBBdevicesAndVoiceProducts &&
        broadbandBBdevicesAndVoiceProducts.length > 0
          ? PACKAGE_TYPE.DTH_BROADBAND
          : broadbandBBdevicesAndVoiceProducts && broadbandBBdevicesAndVoiceProducts.length > 0
          ? PACKAGE_TYPE.BROADBAND
          : PACKAGE_TYPE.DTH;
      checkoutStarted(tempOrder, true, newCoupons[0], '', false, packageType);
    }
  };

  const currentMonthlyCost = billing?.monthlyCharge ? parseFloat(billing?.monthlyCharge) : 0;

  return {
    currentlySubscribedProducts,
    currentlySubscribedTVProducts,
    currentlySubscribedBoxProducts,
    currentlySubscribedMultiroomProducts,
    currentlySubscribedMySkyPlusBoxProducts,
    currentlySubscribedBroadbandProducts,
    currentlySubscribedVoiceProducts,
    notCurrentlySubscribedProducts,
    orderProductsBoughtInCart,
    orderProductsAddedToCart,
    dthProductsAddedToCart,
    broadbandBBdevicesAndVoiceProducts,
    broadbandProducts,
    broadbandInCart,
    multiroomInCart,
    broadbandInFECart,
    existingCampaigns,
    hasExistingCampaign,
    lastCampaignEndDate,
    BroadbandInstallationTypes,
    currentInstallationType,
    skyRouter,
    meshDevice,
    staticIp,
    perfectInstall,
    broadbandByod,
    broadbandSelfInstallation,
    broadbandSelfInstallationOTC,
    productsUserIsTryingToUpgrade,
    isBroadcastTier,
    voiceInCart,
    voiceInFECart,
    staticIpInCart,
    staticIpInFECart,
    byodInCart,
    meshInCart,
    currentlySubscribedBroadbandPackage,
    accountIssue,
    useManagePackageInitialized,
    broadbandOnlyAccount,
    hasActiveAccess,
    entertainmentProducts,
    getBroadbandRequiresDelivery,
    currentMonthlyCost,
    currentlySelectedBroadbandPlanForUpdate,
    allActiveCouponsEndDate,
    arrowRecording,
    upgradableBoxInCart,
    arrowRecordingInCart,
    arrowInCart,
    arrowTechVisitInCart,
    analyticsCheckoutStarted,
    arrowTechVisit,
    arrowUpgradableBoxInCart,
    arrowUpfrontPayment,
    arrowMonthlyPayment,
    newBoxesFromStore,
    hasBroadband,
  };
}
