import React from 'react';

let icon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 356 356">
    <path
      fill="#00B1EB"
      d="M0 246.975V21.694a7.363 7.363 0 017.231-7.231H348.77a7.363 7.363 0 017.231 7.23v225.282H0z"></path>
    <path
      fill="#00013A"
      d="M356 246.975V275.9a7.362 7.362 0 01-7.231 7.231H7.231A7.363 7.363 0 010 275.9v-28.925h356zM254.206 334.306H101.794v7.231h152.412v-7.231z"></path>
    <path fill="#00013A" fillOpacity="0.87" d="M228.619 334.306H127.381l7.232-51.175h86.775l7.231 51.175z"></path>
    <path fill="#fff" d="M341.537 28.925H14.462v203.588h327.075V28.925z"></path>
    <path
      fill="#00013A"
      fillOpacity="0.58"
      d="M221.388 283.131h-86.775l-4.039 28.564 92.115-19.369-1.301-9.195z"></path>
    <path
      fill="#C7D5DD"
      d="M307.272 168.811H49.562v5.562h257.71v-5.562zM260.386 192.974H96.448v5.563h163.938v-5.563z"></path>
    <path
      fill="#00B1EB"
      d="M207.071 66h-2.921c-2.741.4-6.726 1.655-10.064 3.245-2.774 1.32-5.931 4.171-8.123 4.55-1.595.273-3.311-.543-4.868-.653-19.929-1.425-32.547 12.58-34.416 32.307 6.851-8.09 16.372-21.13 27.274-25.165-15.72 11.653-29.977 30.953-30.843 47.731v2.921c.916 5.367 3.256 9.291 8.765 10.064h2.598c7.909-1.069 13.38-4.593 18.834-8.112 20.209 2.899 33.183-6.512 37.99-21.114h-21.432c-2.247 10.973-22.467 8.167-19.803-4.221h41.559c1.079-19.918-8.738-28.924-23.049-33.44 4.456-2.687 17.792-10.486 23.049-3.898 2.313 2.905.888 9.006.323 12.338.998-2.039 1.304-4.763 1.946-7.142V72.49c-.674-3.771-3.07-5.805-6.819-6.49zm-35.398 66.389c-4.072 2.708-17.534 9.269-22.078 2.921-3.207-4.472.63-11.357 2.274-15.254 4.725 5.986 10.892 10.535 19.804 12.333zm18.762-36.067l-20.127.011c-.778-12.431 21.454-13.336 20.127-.01z"></path>
  </svg>
);

let isIE = /Trident|MSIE/.test(window.navigator.userAgent);

let IEBanner: React.FC = ({ children }) => {
  return (
    <>
      {isIE ? (
        <div className="flex items-center justify-center flex-col p-12 pb-24">
          <div className="p-12 w-96 md:w-128">{icon}</div>
          <p className="sky-h3 font-normal text-navy leading-9">
            <strong>Sorry! Internet Explorer </strong>
            is no longer supported.
            <br />
            For best experience try using <strong> Chrome, Edge, Firefox.</strong>
          </p>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export { IEBanner };
