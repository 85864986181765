import {
  categoryIds,
  CouponType,
  getTermsAndConditions,
  KonakartService,
  MyAccountSWR,
  T_Coupon,
  T_OrderProduct,
  useCoupon,
  useFreeProductStore,
  useProductWithCouponRemoval,
  useRemoveProduct,
  useUpgradableBoxes,
} from '@sky-tv-group/shared';
import React from 'react';
import { RowItem } from './RowItem';
import cx from 'classnames';
import { getEarlyTerminationTCs } from './helpers';

export interface IAddedProductsProps {
  kkService: KonakartService;
  cartType: CouponType;
  myAccountSWR?: MyAccountSWR;
  addedCableProducts: T_OrderProduct[];
  addedBroadbandAndPhoneProducts: T_OrderProduct[];
  coupons?: T_Coupon[];
  isBroadcastTier?: boolean;
}

export const AddedProducts = ({
  kkService,
  cartType,
  myAccountSWR,
  addedCableProducts,
  addedBroadbandAndPhoneProducts,
  coupons,
  isBroadcastTier = false,
}: IAddedProductsProps) => {
  const { data: customer } = myAccountSWR ? myAccountSWR.useCustomer() : { data: undefined };
  const { hasDynamicCoupon, isProductIncludedInDynamicCoupon } = useCoupon();
  const { canRemoveProductAtReviewPage, toggleCartProduct } = useRemoveProduct(kkService, cartType);
  const { handleRemovalOfT_OrderProductWithToast } = useProductWithCouponRemoval(kkService);
  const useUpgrBoxes = myAccountSWR ? useUpgradableBoxes(kkService, myAccountSWR) : undefined;
  const freeProduct = useFreeProductStore(s => s.freeProduct);
  const showFreeAsZero = freeProduct && freeProduct?.price !== 0;

  // Filter cable products with existing offers
  const dynamicCouponApplied = coupons && hasDynamicCoupon(coupons);
  const couponRelated = coupons && coupons.flatMap(coupon => coupon.custom1.split(','));
  const productsIdsWithOffers = dynamicCouponApplied
    ? addedCableProducts.filter(p => isProductIncludedInDynamicCoupon(p)).map(p => p.productId)
    : addedCableProducts
        .filter(p => couponRelated?.includes(`${p.productId}`) && p.categoryId !== categoryIds.hindiChannels)
        .map(p => p.productId);

  const getProductNameWithQuantity = (product: T_OrderProduct): string => {
    return product.quantity > 1 ? `${product.product.name} (x${product.quantity})` : product.product.name;
  };

  const getProductPriceWithQuantity = (product: T_OrderProduct): number => {
    return Math.max(product.price * product.quantity, product.price);
  };

  const skyBroadbandAndPhoneProductsClassnames = cx('sky-h4-bold md:sky-h4-bold sky-text-midnight', {
    'pt-4': addedCableProducts.length > 0,
  });

  return (
    <>
      <div className="sky-bg-gray-lighter border p-4 mb-4">
        {addedCableProducts.length > 0 && (
          <>
            <h4 className="sky-h4-bold md:sky-h4-bold sky-text-midnight pb-4">Sky TV</h4>
            {addedCableProducts.map(cable => {
              // multiroom product upgrades from digital rental cannot be removed (for now)
              const isNotMultiroomRentalUpgrade = !(cable.categoryId === categoryIds.multiroom && isBroadcastTier);
              const tcs = getEarlyTerminationTCs(cable, coupons ?? []);
              const isFreeProduct = freeProduct?.productId === cable.productId && showFreeAsZero;

              return (
                <RowItem
                  displayPrice={isFreeProduct ? 0 : getProductPriceWithQuantity(cable)}
                  productName={getProductNameWithQuantity(cable)}
                  key={cable.productId}
                  offer={productsIdsWithOffers.includes(cable.productId) || !!cable.promotionTitle}
                  originalPrice={cable.product.priceIncTax * cable.quantity}
                  onRemove={
                    canRemoveProductAtReviewPage(cable.product, addedCableProducts) && isNotMultiroomRentalUpgrade
                      ? async () => {
                          useUpgrBoxes && useUpgrBoxes.handleUpgradeBoxOTC(cable.product, cable.quantity);
                          await handleRemovalOfT_OrderProductWithToast(
                            { name: cable.product.name, id: cable.productId },
                            toggleCartProduct,
                            true
                          );
                        }
                      : undefined
                  }
                  categoryId={cable.categoryId}
                  offerName={cable.promotionTitle}
                  serviceCode={cable.product.sku}
                  hardcodedTsAndCs={tcs}
                />
              );
            })}
          </>
        )}
        {addedBroadbandAndPhoneProducts.length > 0 && (
          <>
            <h4 className={skyBroadbandAndPhoneProductsClassnames}>Sky Broadband and Home Phone</h4>
            {addedBroadbandAndPhoneProducts
              .filter(p => p.categoryId !== categoryIds.broadbandServices)
              .map(p => {
                // data and voice
                const tcs = getTermsAndConditions(customer?.privacyList ?? [], p.product.sku, p.product.categoryId);

                return (
                  <RowItem
                    displayPrice={getProductPriceWithQuantity(p)}
                    productName={getProductNameWithQuantity(p)}
                    key={p.productId}
                    offer={couponRelated?.includes(`${p.productId}`) || !!p.promotionTitle}
                    onRemove={
                      canRemoveProductAtReviewPage(p.product, addedBroadbandAndPhoneProducts)
                        ? async () => {
                            await handleRemovalOfT_OrderProductWithToast(
                              { name: p.product.name, id: p.productId },
                              toggleCartProduct,
                              true
                            );
                          }
                        : undefined
                    }
                    originalPrice={p.product.priceIncTax}
                    hardcodedTsAndCs={tcs?.termsAndConds}
                    serviceCode={p.product.sku}
                  />
                );
              })}
          </>
        )}
      </div>
    </>
  );
};
