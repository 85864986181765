import { useTreatments, SplitSdk } from '@splitsoftware/splitio-react';
import { SPLIT_AUTHORIZATION_KEY, SPLIT_KEY } from '../config';

export const useSplitIO = (key: string) => {
  // Custom Attributes
  const attributes = {
    system_time: new Date().getTime(),
  };

  let t = useTreatments([key], attributes)[key];
  let enabled = t.treatment === 'on';
  return [enabled, t.config];
};

const factory = SplitSdk({
  core: {
    authorizationKey: SPLIT_AUTHORIZATION_KEY,
    key: SPLIT_KEY,
  },
});

const client = factory.client();

export let getTreatment = async (treatment: string) => {
  if (process.env.NODE_ENV === 'test') {
    return {
      enabled: false,
      config: {},
    };
  }
  await client.ready();
  let r = client.getTreatmentWithConfig(treatment);
  let t = {
    enabled: r.treatment === 'on',
    config: JSON.parse(r.config ?? '{}'),
  };

  return t;
};
