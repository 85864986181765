import { T_Occurrence, T_Product, BoxUpgradeStatus, T_Billing, ServiceStatus, Box } from '../../types';
import { productSkuIds } from '../../config';
import { productStoreApi } from '../../store/productStore';
import { boxesStoreApi } from '../../store/boxesStore';
import { Product } from 'puppeteer';

function useIdentifyBox() {
  /* Use api in hooks instead of store to avoid re-renders. */
  const products = productStoreApi.getState().products;
  const upgradingBoxFromOccurenceNo = boxesStoreApi.getState().getUpgradingBoxFromOccurenceNo;

  /**
   * Sky Box.
   */
  const skyBox = products.filter(p => p.sku === productSkuIds.skyBoxCharge.primary)[0];

  /**
   * New Sky Box WHT.
   */
  const newSkyBoxWhite = products.filter(p => p.sku === productSkuIds.arrowBox.primary)[0];

  /**
   * New Sky Box BLK.
   */
  const newSkyBoxBlack = products.filter(p => p.sku === productSkuIds.arrowBoxBlack.primary)[0];

  const legacyBox = products.filter(p => p.sku === productSkuIds.paceBox500GB.primary)[0];

  /**
   * Sky Pod
   */
  const skyPod = products.filter(p => p.sku === productSkuIds.skyPod.primary)[0];

  /* Returns one of the 4 new box definitions.
  1.  Sky Box.
  2.  LEGACY Box.
  3.  New Sky Box WHT.
  4.  New Sky Box BLK.
  */
  const getBox = (box: T_Occurrence | undefined) => {
    let returnBox: T_Product | undefined = undefined;

    let entCodes = box?.entitlements.map(ent => ent.code);

    if (entCodes?.includes(productSkuIds.arrowBoxBlack.primary)) {
      returnBox = newSkyBoxBlack;
    } else if (entCodes?.includes(productSkuIds.arrowBox.primary)) {
      returnBox = newSkyBoxWhite;
    } else if (entCodes?.includes(productSkuIds.skyBoxCharge.primary)) {
      returnBox = skyBox;
    } else if (entCodes?.includes(productSkuIds.skyPod.primary)) {
      returnBox = skyPod;
    } else {
      const legacyBoxCode = entCodes?.find(
        ec => productSkuIds.legacyBoxCharge.primary.split(',').find(lc => lc === ec) !== undefined
      );
      returnBox = products.find(p => p.sku === legacyBoxCode)!;
    }

    return returnBox;
  };

  /*
    Returns subscribed box from...
    export type BOXTYPE = 'SUBSCRIBED' | 'NEW' | 'UPGRADE';
    ...in types.ts.
  */
  const getSubscribedBox = (box: T_Occurrence, billing: T_Billing, opts?: { primary?: boolean; pending?: boolean }) => {
    if (!opts?.pending) {
      // !pending means its not adding new box
      // service code sanitizing https://skynz.atlassian.net/browse/WEB-1800
      let sd = billing.services.CableServiceOccurrence.find(
        cs => cs.occurrenceNumber.toString() === box.occurrenceNumber
      );

      box.entitlements = box.entitlements.filter(et =>
        sd?.serviceDetails
          .filter(sd => sd.serviceStatus !== ServiceStatus.PendingInstall)
          .map(sd => sd.serviceCode)
          .includes(et.code)
      );
    }
    // service code sanitizing end

    return {
      id: box?.occurrenceNumber,
      occurence: box,
      boxType: opts?.pending ? 'PENDING' : 'SUBSCRIBED',
      name: getBox(box)?.name,
      products: products.filter(
        p => p.occurrenceNumber === box.occurrenceNumber && box.entitlements.map(et => et.code).includes(p.sku)
      ),
      primary: !!opts?.primary,
    } as Box;
  };

  const getBoxStatus = (box: Box, billing?: T_Billing, isInCart?: boolean): BoxUpgradeStatus | null => {
    let occurenceNumber = box?.id ? box?.id : null;
    if (isInCart) return BoxUpgradeStatus.IN_CART;

    const item = billing?.services.CableServiceOccurrence.find(
      service => `${service.occurrenceNumber}` === occurenceNumber
    );

    if (
      item?.status === ServiceStatus.DataPendingOrder ||
      item?.status === ServiceStatus.PendingDisconnect ||
      item?.status === ServiceStatus.PendingInstall
    ) {
      if (box.boxType === 'SUBSCRIBED') {
        return BoxUpgradeStatus.PROCESSINGUPGRADING;
      }

      return BoxUpgradeStatus.PROCESSING;
    }

    if (occurenceNumber && upgradingBoxFromOccurenceNo(occurenceNumber)) {
      return BoxUpgradeStatus.UPGRADING;
    }

    return null;
  };

  let getBoxPackage = (box: T_Occurrence, categoryId: number, packages: T_Product[] | undefined) => {
    return packages
      ?.filter(pack => pack.categoryId === categoryId)
      .find(pack => {
        let skus = pack.sku.split(',');
        let entCodes = box.entitlements.map(ent => ent.code);
        // if device has 625, replace it with 624 to make this algo work
        if (entCodes.includes(productSkuIds.arrowBoxBlack.primary)) {
          entCodes = entCodes.filter(code => code !== productSkuIds.arrowBoxBlack.primary);
          entCodes = entCodes.concat(productSkuIds.arrowBox.primary);
        }
        return entCodes.filter(code => skus.includes(code)).length === skus.length;
      });
  };

  /* Pass the Box or SKU for string. */
  const isArrowBox = (box: Box | string) => {
    let isArrow = (sku: string) => {
      return (
        sku === productSkuIds.arrowBox.primary ||
        sku === productSkuIds.arrowBoxBlack.primary ||
        sku === productSkuIds.arrowBoxOneOff.primary
      );
    };

    if (typeof box === 'string') {
      return isArrow(box);
    } else {
      return box?.products?.some(p => isArrow(p.sku));
    }
  };

  /* Pass the Box or SKU for string. */
  const isSkyPod = (box: Box | string) => {
    let isPod = (sku: string) => {
      return sku === productSkuIds.skyPod.primary;
    };

    if (typeof box === 'string') {
      return isPod(box);
    } else {
      return box?.products?.some(p => isPod(p.sku));
    }
  };

  const formatBoxName = (product: T_Product | undefined, recording: T_Product | undefined): string => {
    if (!product) return '';
    let productName =
      product.sku !== productSkuIds.arrowBox.primary &&
      product.sku !== productSkuIds.arrowBoxBlack.primary &&
      product.sku !== productSkuIds.arrowBoxOneOff.primary &&
      product.sku !== productSkuIds.skyPod.primary
        ? 'Sky Box'
        : product.name;

    return productName;
  };

  const isBoxEligibleForCSG = (billing: T_Billing, device: T_Occurrence, product: T_Product): boolean => {
    const activeCableServiceDetails = billing?.services.CableServiceOccurrence?.filter(
      s => s.status === ServiceStatus.Active
    ).flatMap(x => x.serviceDetails);

    let arrowBoxesFromBilling = activeCableServiceDetails?.filter(product => {
      return (
        product.serviceCode === productSkuIds.arrowBox.primary ||
        product.serviceCode === productSkuIds.arrowBoxBlack.primary
      );
    });

    //look for arrow box with the earliest installation date
    let firstArrowBox = arrowBoxesFromBilling?.sort((a, b) => Date.parse(a.installDate) - Date.parse(b.installDate))[0];

    //Find associated cable occurence so we can get the occurence number
    let cableServiceOccurencesWithArrow = billing?.services.CableServiceOccurrence.filter(occ => {
      return occ.serviceDetails.some(
        service =>
          //look for arrow boxes with the same installation date as the first added box (meaning it was ordered in the same transaction)
          (service.serviceCode === productSkuIds.arrowBox.primary ||
            service.serviceCode === productSkuIds.arrowBoxBlack.primary) &&
          service.installDate === firstArrowBox?.installDate
      );
    });

    //Does the box in this component have the same installation date as the first arrow box and same occurence number
    let thisDeviceIsFirstAddedArrowBox =
      isArrowBox(product.sku) &&
      cableServiceOccurencesWithArrow?.find(occ => occ.occurrenceNumber.toString() === device?.occurrenceNumber);

    //Check if the box was installed within the last 30 days
    let isEligible = false;
    if (
      firstArrowBox &&
      (Date.now() - Date.parse(firstArrowBox.installDate)) / 1000 / 60 / 60 / 24 < 30 &&
      thisDeviceIsFirstAddedArrowBox
    ) {
      isEligible = true;
    }

    return isEligible;
  };

  const doesBoxHaveFreeMySky = (billing: T_Billing, box: Box): boolean => {
    const activeCableServiceDetails = billing?.services.CableServiceOccurrence?.filter(
      s => s.status === ServiceStatus.Active
    ).flatMap(x => x.serviceDetails);

    // don't continue if billing does not have sky access product
    // only access accounts can have a box with free my sky
    if (!activeCableServiceDetails.some(s => s.serviceCode === productSkuIds.access.primary)) {
      return false;
    }

    const frontBookRecording = billing?.services.CableServiceOccurrence?.filter(s => s.status === ServiceStatus.Active)
      .filter(a => a.occurrenceNumber.toString() === box.occurence?.occurrenceNumber)
      .flatMap(x => x.serviceDetails)
      .some(s => s.serviceCode === productSkuIds.frontBookMySky.primary);

    if (frontBookRecording) {
      return true;
    } else {
      return false;
    }
  };

  const hasAnyOneOffArrow = (products: any[]): boolean => {
    const arrowBoxes = products.filter(
      p =>
        p.serviceStatus === ServiceStatus.Active &&
        (p.sku === productSkuIds.arrowBox.primary || p.sku === productSkuIds.arrowBoxBlack.primary)
    );
    const arrowMonthly = products.filter(
      p => p.serviceStatus === ServiceStatus.Active && p.sku === productSkuIds.arrowMonthly.primary
    );

    return arrowBoxes && arrowBoxes.length !== arrowMonthly?.length;
  };

  return {
    legacyBox,
    getBox,
    getBoxStatus,
    getSubscribedBox,
    getBoxPackage,
    isArrowBox,
    isSkyPod,
    formatBoxName,
    isBoxEligibleForCSG,
    doesBoxHaveFreeMySky,
    hasAnyOneOffArrow,
  };
}

export { useIdentifyBox };
