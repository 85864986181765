import React from 'react';
import { Field, Form } from 'react-jeff';
import cx from 'classnames';
import { caret } from '../icon';

interface SelectProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, 'onChange'> {
  onChange: (value: any) => void;
}

interface IValidationSelectProps extends SelectProps {
  fieldValidation: Field<any, string>;
  formValidation: Form<string>;
  greyTextWhenEmpty?: boolean;
}

/**
 * A select control with validation
 */
const SelectElement = ({
  onChange,
  children,
  className,
  fieldValidation,
  formValidation,
  greyTextWhenEmpty,
  ...props
}: IValidationSelectProps) => {
  const errorPresent = fieldValidation.errors.length > 0;
  const errorClassName = cx(className, { 'text-error': errorPresent });
  const selectClassName = cx('sky-input sky-input--text sky-h7-reg appearance-none rounded', className, {
    'sky-input--error': errorPresent,
    'text-gray-border': fieldValidation.value === '' && greyTextWhenEmpty === true,
  });

  return (
    <>
      <div className="relative">
        <select
          className={selectClassName}
          onChange={event => onChange(event.currentTarget.value)}
          {...props}
          required={fieldValidation.required}>
          {children}
        </select>
        <div className="w-6 mt-1 h-12 py-2 mr-1 absolute text-gray-darker top-0 right-0 pointer-events-none">
          {caret}
        </div>
      </div>
      {errorPresent && <p className={errorClassName}>{fieldValidation.errors[0]}</p>}
    </>
  );
};

export { SelectElement };
