import { DeliveryAddress, Service, ServiceCodesModify } from '../../../types';
import _uniq from 'lodash.uniq';
import { productSkuIds } from '../../../config';

/**
 * Upgrading box logic - adding the upgradable boxes for the default box
 * @param occurrence
 * @param boxUpgradableItems
 * @param cableServices
 * @param upgradableBoxDetails
 */
export const handleUpgradableBoxLogic = async (
  boxUpgradableItems: ServiceCodesModify,
  cableServices: Service[],
  occurenceNumber: string | undefined,
  deliveryDetails?: DeliveryAddress,
  addDeliveryAddressToPayload?: boolean
): Promise<Service[]> => {
  /* Do something if there's something to upgrade. */
  if (boxUpgradableItems && boxUpgradableItems.addedItem && boxUpgradableItems.addedItem.length > 0) {
    let cableService: Service = {
      id: occurenceNumber,
      actions: [
        {
          add: _uniq([...boxUpgradableItems.addedItem]).sort(),
          remove: [...boxUpgradableItems.removedItem],
        },
      ],
      details: [],
    };

    if (
      (boxUpgradableItems.addedItem.includes(productSkuIds.arrowBox.primary) ||
      boxUpgradableItems.addedItem.includes(productSkuIds.arrowBoxBlack.primary) ||
      boxUpgradableItems.addedItem.includes(productSkuIds.skyPod.primary)) &&
        addDeliveryAddressToPayload &&
        deliveryDetails
    ) {
      cableService.deliveryDetails = deliveryDetails;
    }

    /* This is for upgrade to New Sky Box only. */
    if (boxUpgradableItems.customerAction === 'swap') {
      cableService.multiRoom = {
        action: boxUpgradableItems.customerAction,
      };
    }
    // If there is a default cable service occurrence hit the first occurrence to update with the upgradable box
    if (
      cableServices[0] &&
      cableServices[0].actions[0] &&
      cableServices[0].actions[0].add &&
      cableServices[0].actions[0].remove
    ) {
      /* Check if occurrence is already in cable services and update accordingly. */
      const index = cableServices.findIndex(c => c.id === occurenceNumber);
      if (index !== -1) {
        let serviceObj:Service | null = null;
        /* Make a copy of the cableservice for primary device to spilt the order payload. */
        if (cableServices.length > 0) {
          serviceObj = JSON.parse(JSON.stringify(cableServices[0]));
        }

        /* This is for upgrade to New Sky Box only. */
        if (boxUpgradableItems.customerAction === 'swap') {
          cableServices[index].multiRoom = {
            action: boxUpgradableItems.customerAction,
          };
        }
        //for skybox to skypod upgrade issue
        if (
          (boxUpgradableItems.addedItem.includes(productSkuIds.arrowBox.primary) ||
            boxUpgradableItems.addedItem.includes(productSkuIds.arrowBoxBlack.primary) ||
            boxUpgradableItems.addedItem.includes(productSkuIds.skyPod.primary)) &&
          addDeliveryAddressToPayload &&
          deliveryDetails
        ) {
          cableServices[index].deliveryDetails = deliveryDetails;
        }

        cableServices[index].actions[0].add = _uniq([
          ...cableServices[0].actions[0].add,
          ...boxUpgradableItems.addedItem,
        ]).sort();
        cableServices[index].actions[0].remove = [
          ...cableServices[0].actions[0].remove,
          ...boxUpgradableItems.removedItem,
        ];
        
        /* If the cableservice obj for primary device is altered, its unaltered copy is added to the services to spilt the order payload. */
        if (serviceObj 
          && (cableServices[0].multiRoom
          || cableServices[0].deliveryDetails
          || cableServices[0].actions[0].add.length !== serviceObj.actions[0].add?.length
          || cableServices[0].actions[0].remove.length !== serviceObj.actions[0].remove?.length)
        ) {
          cableServices.unshift(serviceObj);
        }
        return cableServices;
      }
    }

    /* Otherwise, create the cable service and use occurrence number as id. */
    cableServices.push(cableService);
  }
  return cableServices;
};
