import { useEffect, useState } from 'react';
import { T_Customer, T_Profile } from '../types';

export const useBroadbandStatus = (
  customer: T_Customer | undefined,
  profile: T_Profile | undefined,
  hasBroadbandPackage: boolean,
  isCustomerQualifiedForBroadband: boolean
) => {
  const [isShowBroadband, setIsShowBroadand] = useState(false);
  const [broadbandLoader, setBroadbandLoader] = useState(false);
  const [broadbandExistsForUser, setBroadbandExistsForUser] = useState(false);

  useEffect(() => {
    const displayBroadbandBanner = async () => {
      if (customer && profile) {
        try {
          setBroadbandLoader(true);
          setBroadbandExistsForUser(hasBroadbandPackage);
          setIsShowBroadand(!hasBroadbandPackage && isCustomerQualifiedForBroadband);
        } finally {
          setBroadbandLoader(false);
        }
      }
    };
    displayBroadbandBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, profile, isCustomerQualifiedForBroadband]);

  return {
    broadbandLoader,
    broadbandExistsForUser,
    isShowBroadband,
  };
};
