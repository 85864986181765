import React from 'react';
import { useBroadbandStore } from '@sky-tv-group/shared';
import { CheckboxInput } from '../../input';

interface Props {
  isFibreSwap: boolean;
  hasBroadBand?: boolean;
}

const ChangingProvider = ({ isFibreSwap, hasBroadBand = false }: Props) => {
  const { setIsChangingProvider, isChangingProvider } = useBroadbandStore();
  const isFibreSwapFromExternal = isFibreSwap && !hasBroadBand;

  if (!isFibreSwapFromExternal) return null;

  return (
    <div className="pb-4 w-full">
      <div className="p-8 bg-gray-light">
        <label className="block mb-3 sky-h7-black text-midnight">{'Changing provider'}</label>
        <p className="text-13px">
          {
            'We make it really easy to switch your Broadband to Sky by taking care of the service transfer from your current provider, and you can track the progress of your order on our handy order tracker in My Account. However, there may be an overlap in billing with your current provider so we ask that you check with them to see if they have a *notice period *for the cancellation of services.'
          }
        </p>
        {isFibreSwapFromExternal && (
          <div className="mt-3 flex">
            <div className="w-7">
              <CheckboxInput onChange={setIsChangingProvider} checked={isChangingProvider} />
            </div>
            <p className="text-13px flex items-center ml-3">
              {'Yes I acknowledge the potential overlap of services & am advised to check with my current provider.'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export { ChangingProvider };
