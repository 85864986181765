import { T_Product } from '@sky-tv-group/shared';
import React from 'react';
import { WhatsHotCard } from './WhatsHotCard';

interface IWhatsHotProps {
  product: T_Product;
}

export const WhatsHot = ({ product }: IWhatsHotProps) => {
  //placeholder until the data for the cards is available, then will assign a different color to each card.

  function assignBackgroundGradient(index: number) {
    let backgroundColorGradient: string;
    switch (index) {
      case 1:
        backgroundColorGradient = 'sky-tempting-azure-gradient';
        break;
      case 2:
        backgroundColorGradient = 'sky-peach-gradient';
        break;
      case 3:
        backgroundColorGradient = 'sky-blue-gradient';
        break;
      case 4:
        backgroundColorGradient = 'sky-young-passion-gradient';
        break;
      case 5:
        backgroundColorGradient = 'sky-tempting-azure-gradient';
        break;
      default:
        backgroundColorGradient = 'sky-blue-gradient';
        break;
    }
    return backgroundColorGradient;
  }
  return (
    <div className="flex flex-col flex-no-wrap my-0 mx-auto sm:flex-wrap sm:flex-row sm:overflow-hidden">
      {product.popupCards?.map((item, i) => (
        <WhatsHotCard key={i} popUpCard={item} backgroundColorGradient={assignBackgroundGradient(i)} />
      ))}
    </div>
  );
};
