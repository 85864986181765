import { boxCampaigns, productSkuIds } from '../../config';
import {
  Campaign,
  Privacy,
  Services,
  ServiceStatus,
  T_Coupon,
  T_Customer,
  T_Product,
  DeliveryAddress,
} from '../../types';
import { IArrowDelivery } from '../../store/arrowDeliveryStore';
import { AddressService } from '../../services/address';
import { isNonNil } from '../filter';
import { BBPlanIndex } from './dataServices/dataServiceBuilder';

export type ProductData = Pick<T_Product, 'sku' | 'custom3' | 'categoryId' | 'custom7' | 'model' | 'quantityInCart'>;
export type BillingOccurrences = Pick<
  Services,
  'CableServiceOccurrence' | 'DataServiceOccurrence' | 'TelephoneServiceOccurrence' | 'OTTServiceOccurrence'
>;

const broadbandPlans: string[] = [
  productSkuIds.broadbandLightningFastWiFi.primary,
  productSkuIds.broadbandLightningFastWiFiBoost.primary,
  productSkuIds.broadbandWifi100.primary,
  productSkuIds.broadbandWifi100Boost.primary,
];

export interface CustomerInfo {
  tuiAddressCode: string;
  firstName: string;
  lastName: string;
  billingFirstname?: string;
  billingLastName?: string;
  serviceContactEmail: string;
  houseNumber?: string;
  accountNumber?: string;
  privacyList?: Privacy[];
}

// Return array of ICOMS codes of the passed order products.
export const getAddedProducts = (orderProducts: ProductData[]): string[] => {
  // products added
  let addedItems: string[] = [];
  // kk products that wil have the correct sku ids
  orderProducts.forEach(p => {
    let tempSplitArray = p.sku.split(',').map(x => x.trim());
    let tempCustomServiceCodesSplit: string[] = [];
    if (p.custom3) {
      tempCustomServiceCodesSplit = p.custom3.split(',').map(x => x.trim());
    }
    addedItems = [...addedItems, ...tempSplitArray, ...tempCustomServiceCodesSplit];
  });

  return addedItems;
};

// Return array of ICOMS codes of the passed multiroom order products.
export const getAddedMultiroomProducts = (orderProducts: T_Product[]): string[] => {
  // products added
  let addedItems: string[] = [];
  // kk products that wil have the correct sku ids
  orderProducts.forEach(p => {
    if (p.sku) {
      addedItems.push(p.sku);
    }
  });

  return addedItems;
};

export const getRemovedBroadbandPackage = (
  currentlySubscribedBroadbandPackage: ProductData | undefined,
  newBroadbandPackageSku: string | undefined
): string[] => {
  // products added
  let removedItems: string[] = [];

  // If a broadband package is being added and there is an existing package, remove the old package
  if (newBroadbandPackageSku && currentlySubscribedBroadbandPackage) {
    removedItems = [currentlySubscribedBroadbandPackage.sku];
  }

  return removedItems;
};

export const getCouponCampaign = (
  coupons: Pick<T_Coupon, 'description' | 'couponCode' | 'custom5'>[] | undefined,
  filterBy: (coupon: Pick<T_Coupon, 'custom5'>) => boolean = _ => true
): Campaign[] => {
  let campaigns = coupons?.filter(filterBy).map(coupon => ({
    id: coupon?.couponCode,
    description: coupon?.description,
    boxCampaign: boxCampaigns.includes(coupon?.couponCode),
  }));

  return campaigns ?? [];
};

export function isOneGigBroadbandPlan(
  hasActiveBroadbandPackage: boolean | undefined,
  billingServices: BillingOccurrences | undefined,
  orderPackages: ProductData[]
) {
  let currentBroadbandSubscription: string | undefined = undefined;

  if (hasActiveBroadbandPackage) {
    currentBroadbandSubscription = billingServices?.DataServiceOccurrence.flatMap(occ => occ.serviceDetails)?.find(
      sd => sd.serviceStatus === ServiceStatus.Active && sd.quantity > 0 && broadbandPlans.includes(sd.serviceCode)
    )?.serviceCode;
  }

  let newBroadbandPackage = orderPackages.find(op => broadbandPlans.includes(op.sku))?.sku;
  const packageToCheck = newBroadbandPackage ?? currentBroadbandSubscription;

  let bbPlanIndex: BBPlanIndex = 0;
  if (
    packageToCheck === productSkuIds.broadbandLightningFastWiFi.primary ||
    packageToCheck === productSkuIds.broadbandLightningFastWiFiBoost.primary
  ) {
    bbPlanIndex = 0;
  } else if (
    packageToCheck === productSkuIds.broadbandWifi100.primary ||
    packageToCheck === productSkuIds.broadbandWifi100Boost.primary
  ) {
    bbPlanIndex = 1;
  } else if (
    packageToCheck === productSkuIds.broadbandEssnFibre.primary ||
    packageToCheck === productSkuIds.broadbandEssnFibBoost.primary
  ) {
    bbPlanIndex = 2;
  }

  return bbPlanIndex;
}

/**
 * Builds an object of customer information (CustomerInfo) from customer (T_Customer) data
 * for upgrade order payloads (authenticated user).
 *
 * Note: Extend return CustomerInfo object if required.
 *
 * @param customer T_Customer
 * @returns CustomerInfo
 */
export const buildCustomerInfo = (customer: T_Customer): CustomerInfo => {
  const billingDetails = customer.Parties.find(p => p.types['BT:Billing'] !== undefined)?.types['BT:Billing']
    ?.personalDetails;
  const personalDetails = customer.Parties.find(p => p.types['MP:Primary'] !== undefined)?.types['MP:Primary']
    ?.personalDetails;

  return {
    serviceContactEmail: customer.serviceContactEmail,
    firstName: personalDetails?.firstName || '',
    lastName: personalDetails?.lastName || '',
    billingFirstname: billingDetails?.firstName,
    billingLastName: billingDetails?.lastName,
    houseNumber: customer.houseNumber,
    accountNumber: customer.accountNumber,
    tuiAddressCode: customer.tuiAddressCode,
    privacyList: customer.privacyList,
  };
};

export const getArrowDeliveryInformation = async (
  deliveryDetails: IArrowDelivery,
  customer: CustomerInfo,
  email: string,
  contactNumber: string,
  addressService: AddressService
): Promise<DeliveryAddress> => {
  let tui = customer.tuiAddressCode!;
  let deliveryAddress = !deliveryDetails.useDifferentDeliveryAddress
    ? deliveryDetails.defaultDeliveryAddress
    : undefined;

  // If not using default, use the user selected address
  if (!deliveryAddress) {
    // If not using the default, use whatever address the user selected. Update the tui to match
    // TODO: cache results of getAddressDetails to avoid repeating API calls when multiple boxes is
    // being upgraded
    const addressDetails = await addressService.getAddressDetail(deliveryDetails.deliveryAddress!.id);

    deliveryAddress = [
      addressDetails.formatted_delivery_address.line1,
      addressDetails.formatted_delivery_address.line2,
      addressDetails.formatted_delivery_address.line3,
      addressDetails.formatted_delivery_address.line4,
    ];
  }

  // Only use billing names - old code does a substitution with MP:Primary.
  let contactName = [customer.firstName, customer.lastName].filter(isNonNil).join(' ');

  if (!contactName.length) {
    contactName = [customer.billingFirstname, customer.billingLastName].filter(isNonNil).join(' ');
  }

  return {
    line1: deliveryAddress[0],
    line2: deliveryAddress[1],
    line3: deliveryAddress[2],
    line4: deliveryAddress[3],
    tui: tui,
    deliveryInfo: {
      contactName: contactName,
      contactNumber: contactNumber,
      contactEmail: email,
      notes: '',
    },
  };
};
