import React, { useState } from 'react';
import { productSkuIds, T_Product, KonakartService, useLockBodyScrollOnValue } from '@sky-tv-group/shared';
import { Button } from '../button';
import { Aspect } from '../aspect';
import { FancyPrice } from '../FancyPrice';
import { useModal } from '../ExploreModal/useModal';
import ExploreModal from '../ExploreModal/ExploreModal';
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../../config';
import { IPopUpContentConfig } from '@sky-tv-group/shared/src/types';

export interface IICEPackageCardSingleProps {
  kkService: KonakartService;
  product: T_Product;
  imageBackgroundClassName?: string;
  imageClassName?: string;
  toggleProduct: (product: T_Product) => Promise<void>;
  removable?: boolean;
  showVideo?: boolean;
  subscribed?: boolean;
  skyPriceChangePopUp?: IPopUpContentConfig[] | undefined;
  disabled?: boolean;
  handleToggle?: (inCart: boolean, product: T_Product) => Promise<void>;
  hideContract?: boolean;
}

const ICEPackageCardSingle: React.FC<IICEPackageCardSingleProps> = ({
  kkService,
  product,
  toggleProduct,
  removable = true,
  showVideo = false,
  subscribed = false,
  skyPriceChangePopUp,
  disabled = false,
  handleToggle,
  hideContract = false,
}) => {
  const isInCart = product.quantityInCart > 0;
  const labelInCart = removable ? 'Added' : subscribed ? 'Subscribed' : 'Included';
  const preventRemove = !removable && isInCart;
  const isPackage = (product.categoryName ?? '').includes('package');
  const skystarteridDesktop = product.sku === productSkuIds.starter.primary ? product.sku : '';

  const [isDisabled, setIsDisabled] = useState(disabled);
  const [isLoading, setIsLoading] = useState(false);

  const onProductToggle = async () => {
    setIsDisabled(true);
    setIsLoading(true);
    handleToggle && (await handleToggle(isInCart, product));
    setIsDisabled(false);
    setIsLoading(false);
  };

  const { showModal, toggleModal } = useModal();
  useLockBodyScrollOnValue(showModal);

  return (
    <>
      <div
        className="card border-2 border-gray-dark flex flex-1 flex-row justify-start overflow-hidden rounded-lg  text-navy"
        style={{ backgroundColor: '#FBFBFB' }}
        data-testid={`ice-package-card-single-${product.id}`}>
        <div>
          {/* There is no other option but to make this horizontal card 4 : 3 to keep
          consistency around the site even though video player and videos are always 16 : 9 */}
          {!skystarteridDesktop ? (
            <div className="w-full h-full">
              {!isPackage ||
                (showVideo && product?.horizontalVideoUrl && (
                  <iframe
                    title={product?.name}
                    src={HORIZONTAL_VIDEO_PLAYER_URL + product?.horizontalVideoUrl}
                    allow="encrypted-media"
                    className="w-full h-full"></iframe>
                ))}
              {!isPackage && !showVideo && <img src={product.horizontalImageUrl} alt={product.name}></img>}
            </div>
          ) : (
            <Aspect ratio={3 / 4}>
              {showVideo && product?.horizontalVideoUrl && (
                <iframe
                  title={product?.name}
                  src={HORIZONTAL_VIDEO_PLAYER_URL + product?.horizontalVideoUrl}
                  allow="encrypted-media"
                  className="w-full h-full"></iframe>
              )}
              {!showVideo && <img src={product.horizontalImageUrl} alt={product.name}></img>}
            </Aspect>
          )}
        </div>
        {!skystarteridDesktop ? (
          <div className="flex-1 p-4 flex flex-col items-start justify-between">
            {/* Logo image URL. */}
            <div className="flex flex-col w-4/5 items-start justify-between">
              {product.logoImageUrl && (
                <img src={product.logoImageUrl} className="h-10 max-w-full " alt={product.name} />
              )}
              {!product.logoImageUrl && <div className="md:sky-h4-black sky-h5-black">{product.name}</div>}

              <div className="sm:sky-h7-reg text-16px pt-5" style={{ fontSize: '14px' }}>
                {product.description}
              </div>

              {/* Channel rail for a particular package. */}
              <div className={'flex w-full gap-2 items-left justify-left'}>
                {product?.iconImageUrl?.map(
                  (d, index) =>
                    index < 5 && (
                      <div key={index} className="flex-col w-12 h-10">
                        <img className="w-full h-full object-contain" src={d} alt={'Channel logo'} />
                      </div>
                    )
                )}
              </div>
            </div>
            <div className="flex justify-end">
              <div className="flex flex-col pt-2">
                <>
                  {/* Contract. */}
                  {hideContract || (
                    <div>
                      {product.contractDescription && <p className="sm:sky-sub">{product.contractDescription}</p>}
                    </div>
                  )}
                  <div className="flex justify-start pl-3 pt-2">
                    <FancyPrice
                      price={product.price0}
                      containerClasses="text-navy relative"
                      numberStyles={'md:sky-h2-black sky-h1 font-bold w-full'}
                    />
                  </div>
                </>
                <div className="flex flex-row pt-2">
                  {/* Explore button. */}
                  {product?.showExplorePopup && (
                    <Button onClick={toggleModal} className="w-full ml-0" variant="secondary" colorMode="pure">
                      {product.linkLabel}
                    </Button>
                  )}

                  <Button
                    className="ml-3 mr-8 w-full"
                    colorMode={isInCart ? (removable ? 'pink' : 'disabled') : isDisabled ? 'disabled' : 'pure'}
                    icon={isLoading ? 'loading' : isInCart ? (removable ? 'check' : undefined) : 'add'}
                    variant="primary"
                    data-testid={'add-product-' + product.sku}
                    onClick={onProductToggle}
                    disabled={isDisabled || preventRemove}>
                    {product.quantityInCart > 0 ? labelInCart : 'Add'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-1 p-4 flex items-start justify-between">
            {/* Logo image URL. */}
            <div className="flex flex-col w-4/5 items-start justify-between">
              {product.logoImageUrl && (
                <img src={product.logoImageUrl} className="h-10 max-w-full " alt={product.name} />
              )}
              {!product.logoImageUrl && <div className="md:sky-h4-black sky-h5-black">{product.name}</div>}

              {skystarteridDesktop ? (
                <div className="sm:sky-h7-reg text-16px pt-2" style={{ fontSize: '14px' }}>
                  {product.description}
                </div>
              ) : (
                <div className="sm:sky-h7-reg text-16px pt-5">{product.description}</div>
              )}

              {/* Channel rail for a particular package. */}
              {skystarteridDesktop ? (
                <div className={'flex w-full gap-2 pt-5 items-left justify-left justify-between'}>
                  <div className="flex gap-2">
                    {product?.iconImageUrl?.map(
                      (d, index) =>
                        index < 5 && (
                          <div key={index} className="flex-col w-12 h-12">
                            <img className="w-full h-full object-contain" src={d} alt={'Channel logo'} />
                          </div>
                        )
                    )}
                    <label className="textbox pt-4 pb-2 pl-2">& more</label>
                  </div>
                  <div>
                    {product?.showExplorePopup && (
                      <Button onClick={toggleModal} className="item-end" variant="secondary" colorMode="pure">
                        {product.linkLabel}
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <div className={'flex w-full gap-2 items-left justify-left'}>
                  {product?.iconImageUrl?.map(
                    (d, index) =>
                      index < 5 && (
                        <div key={index} className="flex-col w-12 h-10">
                          <img className="w-full h-full object-contain" src={d} alt={'Channel logo'} />
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
            <div className="flex justify-end" style={{ height: '130px' }}>
              <div className="flex flex-col justify-end items-end mb-5px pt-5">
                {!skystarteridDesktop ? (
                  <>
                    {/* Contract. */}
                    {hideContract || (
                      <div>
                        {product.contractDescription && <p className="sm:sky-sub">{product.contractDescription}</p>}
                      </div>
                    )}
                    <div className="flex justify-start pl-3">
                      <FancyPrice
                        price={product.price0}
                        containerClasses="text-navy relative"
                        numberStyles={'md:sky-h2-black sky-h1 font-bold w-full'}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col mr-10">
                      <FancyPrice
                        price={product.price0}
                        containerClasses="text-navy relative"
                        numberStyles={'md:sky-h2-black sky-h1 font-bold w-full'}
                      />
                    </div>
                    {hideContract || (
                      <div>
                        {product.contractDescription && <p className="sm:sky-sub">{product.contractDescription}</p>}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {!skystarteridDesktop && (
              <Button
                className="ml-3 mr-8 w-full"
                colorMode={isInCart ? (removable ? 'pink' : 'disabled') : isDisabled ? 'disabled' : 'pure'}
                icon={isLoading ? 'loading' : isInCart ? (removable ? 'check' : undefined) : 'add'}
                variant="primary"
                data-testid={'add-product-' + product.sku}
                onClick={onProductToggle}
                disabled={isDisabled || preventRemove}>
                {product.quantityInCart > 0 ? labelInCart : 'Add'}
              </Button>
            )}
          </div>
        )}
      </div>
      <ExploreModal
        isShown={showModal}
        kkService={kkService}
        toggleProduct={toggleProduct}
        hide={toggleModal}
        product={product}
        subscribed={subscribed}
        skyPriceChangePopUp={skyPriceChangePopUp}
        disabled={disabled}
      />
    </>
  );
};

export { ICEPackageCardSingle };
