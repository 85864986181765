import { useToastContainer } from './useToastContainer';

function useNoBox(validBoxIds: number[], addSkyBox: () => Promise<void>) {
  const { addToast, addToDependencyToasts, removeFromDependencyToasts, hasDependencyToast } = useToastContainer();

  const addBoxWarningToastAction = (addAction?: () => Promise<void>) => async () => {
    await addSkyBox();
    await addAction?.();
    removeFromDependencyToasts(validBoxIds);
  };

  const addBoxWarningToastClose = () => {
    removeFromDependencyToasts(validBoxIds);
  };

  const noBoxAction = (message: string, addAction?: () => Promise<void>) => () => {
    if (hasDependencyToast(validBoxIds)) {
      return;
    }
    // throw a warning toast
    const warningToastId = addToast({
      title: 'Missing Sky Box',
      type: 'warning',
      message,
      action: addBoxWarningToastAction(addAction),
      actionText: `Add Sky Box`,
      closeText: 'No thanks',
      onClose: addBoxWarningToastClose,
    });
    addToDependencyToasts(validBoxIds, warningToastId);
  };

  return {
    noBoxAction,
  };
}

export { useNoBox };
