export const HORIZONTAL_VIDEO_PLAYER_URL =
  process.env.HORIZONTAL_VIDEO_PLAYER_URL ??
  'https://players.brightcove.net/6122285394001/Uk656dFgP_default/index.html?videoId=';
export const VERTICAL_VIDEO_PLAYER_URL =
  process.env.VERTICAL_VIDEO_PLAYER_URL ??
  'https://players.brightcove.net/6122285394001/BGHQ9V4jg8_default/index.html?videoId=';

export const SKY_BROADBAND_T_AND_C_URL = process.env.REACT_APP_SKY_BROADBAND_T_AND_C_URL!;
export const TERMS_AND_CONDITIONS_DOMESTIC = process.env.REACT_APP_TERMS_AND_CONDITIONS_DOMESTIC!;
export const SKY_TV_T_AND_C_URL = process.env.REACT_APP_SKY_TV_T_AND_C_URL!;
