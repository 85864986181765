import { useField } from 'react-jeff';
import { validateContactName, validateNotes } from './validations';

function useSiteAccessInformationFields() {
  // Site access fields.
  let contactNameField = useField({
    defaultValue: '',
    validations: [validateContactName],
  });

  let notesField = useField({
    defaultValue: '',
    validations: [validateNotes],
  });

  return {
    contactNameField,
    notesField,
  };
}

export { useSiteAccessInformationFields };
