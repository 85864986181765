import React from 'react';
import { NavLinks } from '@sky-tv-group/components';
import { skyAppsRoutes } from '../../routes';
import { SkyAppsPathName } from '../../types';

interface Props {
  selectedLink: SkyAppsPathName;
}

export const SkyAppMenu = ({ selectedLink }: Props) => {
  const menus = skyAppsRoutes?.map(({ path, title }) => ({ slug: path?.replace('/', ''), text: title }));
  const selectedMenu = selectedLink?.replace('/', '');
  return (
    <div className="flex lg:justify-center items-center gap-4 p-4 overflow-x-auto bg-white sticky top-4 z-10 mx-auto no-scrollbar scroll-smooth">
      <NavLinks links={menus} selectedLink={selectedMenu} />
    </div>
  );
};
