import { categoryIds, productSkuIds } from '../config';
import { useCouponStore } from '../store/couponStore';
import { CouponTypeValue, T_Coupon, BillingCampaign } from '../types';
import { useProductInCart } from './useProductInCart';
import { KonakartService } from '../services/konakart';
import { useCouponCode } from './useCouponCode';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useCartContainer } from './useCartContainer';
import { priceHoldCampaignCodes } from '../config';
import { bbNamesNew, BBList } from './useBBname';
import { useFreeProduct } from './useFreeProduct';
import { MyAccountSWR } from '../entry';

function useRunningTotal(
  kkService: KonakartService,
  existingCampaigns?: BillingCampaign[],
  myAccountSWR?: MyAccountSWR
) {
  const { productsInCart, orderTotals } = useProductInCart();
  const { checkIfExistingProductCanBeMadeFree } = useFreeProduct(kkService);
  const hasPriceHoldCampaign = existingCampaigns?.some(item => priceHoldCampaignCodes.includes(item.promotionCode));
  const { data: billing } = myAccountSWR?.useBilling() ?? { data: undefined };

  if (hasPriceHoldCampaign) {
    const broadbandPlans = Object.values(bbNamesNew);
    productsInCart?.forEach(op => {
      if (BBList.includes(op?.product?.sku)) {
        op.product.price0 = broadbandPlans.find(item => op?.product?.sku === item.primary)?.price0 ?? op.product.price0;
        op.product.price1 = broadbandPlans.find(item => op?.product?.sku === item.primary)?.price1 ?? op.product.price1;
        op.product.priceIncTax =
          broadbandPlans.find(item => op?.product?.sku === item.primary)?.price0 ?? op.product.priceIncTax;
      }
    });
  }

  const { multirooms, boxes } = useCartContainer(kkService, 'UPGRADE');

  const { coupons } = useCouponStore();
  const [existingOffers, setExistingOffers] = useState<T_Coupon[]>([]);

  const { getCouponFromCouponCode } = useCouponCode(kkService);

  useEffect(() => {
    if (kkService) {
      const getCouponForCampaignCode = async (existingCampaigns: BillingCampaign[]) => {
        const existingCoupons: (T_Coupon | undefined)[] = await Promise.all(
          existingCampaigns.map(campaign => getCouponFromCouponCode(campaign.promotionCode))
        );
        setExistingOffers(existingCoupons.filter(c => !!c) as T_Coupon[]);
      };
      if (existingCampaigns) {
        getCouponForCampaignCode(existingCampaigns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(existingCampaigns)]);

  // Coupons from existing subscription and new coupons
  const allActiveCoupons = [...existingOffers, ...coupons];

  //Get campaign end date from a coupon code.
  const findCampainEndDateForCoupon = useCallback(
    (couponCode: string) => {
      let matchingCampaign = existingCampaigns?.find(campaign => campaign.promotionCode === couponCode);
      if (matchingCampaign) {
        return matchingCampaign.endDate;
      } else {
        return undefined;
      }
    },
    [existingCampaigns]
  );

  const starter = useMemo(() => {
    return productsInCart?.find(p => p.categoryId === categoryIds.package);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInCart, categoryIds]);

  //Tv Packages
  const tvPackageCategoryIds = [categoryIds.packageUpgrade];
  const tvPackageProducts = useMemo(() => {
    return productsInCart?.filter(p => tvPackageCategoryIds.find(pc => pc === p.categoryId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInCart, tvPackageCategoryIds]);

  //TV Packages Including skyStarter
  const tvPackageCategoryIdsWithStarter = [categoryIds.packageUpgrade, categoryIds.package];

  const tvPackageProductsIncSkyStarter = useMemo(() => {
    return productsInCart?.filter(p => tvPackageCategoryIdsWithStarter.find(pc => pc === p.categoryId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInCart, productsInCart]);

  //Add ons
  const addOnsCategoryIds = [categoryIds.special, categoryIds.hindiChannels];

  const addOnProducts = useMemo(() => {
    return productsInCart?.filter(p => addOnsCategoryIds.find(pc => pc === p.categoryId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInCart, addOnsCategoryIds]);

  //Acquistion Sky box
  const acquisitionSkyBoxCategoryIds = [categoryIds.box];

  const acquisitionSkyBoxProducts = useMemo(() => {
    return productsInCart?.filter(
      p =>
        acquisitionSkyBoxCategoryIds.find(pc => pc === p.categoryId) &&
        p.product &&
        p.product.custom4?.toUpperCase() === CouponTypeValue.Acquisition
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInCart, acquisitionSkyBoxCategoryIds, CouponTypeValue]);

  const skyBoxProducts = productsInCart?.filter(
    p => acquisitionSkyBoxCategoryIds.find(pc => pc === p.categoryId) && p.product
  );

  //Multiroom
  const acquisitionMultiroomCategoryIds = [categoryIds.multiroom];
  const acquisitionMultiroomProducts = productsInCart?.filter(
    p =>
      acquisitionMultiroomCategoryIds.find(pc => pc === p.categoryId) &&
      p.product &&
      p.product.custom4?.toUpperCase() === CouponTypeValue.Acquisition
  );

  const multiroomProducts = productsInCart?.filter(
    p => acquisitionMultiroomCategoryIds.find(pc => pc === p.categoryId) && p.product
  );

  const boughtMultiroomProducts = multiroomProducts
    ?.map(p => {
      let _p = { ...p };
      _p.quantity = multirooms.find(a => a.id === p.productId)?.quantityBought ?? 0;
      return _p;
    })
    .filter(p => p.quantity > 0);

  const newlyAddedMultiroomProducts = skyBoxProducts
    ?.map(p => {
      let _p = { ...p };
      _p.quantity = p.quantity - (boxes.find(a => a.id === p.productId)?.quantityBought ?? 0);
      return _p;
    })
    .filter(p => p.quantity > 0);

  //Multiroom & Sky box
  const multiroomAndSkyBoxCategoryIds = [categoryIds.multiroom, categoryIds.box];
  const multiroomAndSkyBoxProducts = productsInCart?.filter(
    p => multiroomAndSkyBoxCategoryIds.find(pc => pc === p.categoryId) && p.product
  );

  //Sky box recording
  const recordingProducts = productsInCart?.filter(product => product.categoryId === categoryIds.recording);

  //Broadband
  const broadbandCategoryIds = [categoryIds.broadband];
  const broadbandProducts = productsInCart?.filter(p => broadbandCategoryIds.find(pc => pc === p.categoryId));

  //Voice
  const voiceCategoryIds = [categoryIds.voice];
  const voiceProducts = productsInCart?.filter(p => voiceCategoryIds.find(pc => pc === p.categoryId));

  //Voice addons
  const voiceAddonsCategoryIds = [categoryIds.voiceAddons, categoryIds.voiceCrossCountry];
  const voiceAddonsProducts = productsInCart?.filter(p => voiceAddonsCategoryIds.find(pc => pc === p.categoryId));

  //Broadband, voice and Broadband devices
  const broadbandBBDevicesAndVoiceCategoryIds = [
    categoryIds.broadband,
    categoryIds.voice,
    categoryIds.voiceAddons,
    categoryIds.voiceCrossCountry,
    categoryIds.broadbandDevices,
  ];
  const broadbandBBdevicesAndVoiceProducts = productsInCart?.filter(p =>
    broadbandBBDevicesAndVoiceCategoryIds.find(pc => pc === p.categoryId)
  );

  // Free product, assumes there's only one free product (Soho)
  const freeProductId = orderTotals?.find(ot => ot.className === 'ot_free_product')?.freeProductId;
  const freeProducts = productsInCart?.filter(p => p.productId === freeProductId);
  const currentlySubscribedFreeProducts = productsInCart?.filter(
    p => p.productId === freeProductId && p.currentlySubscribed === true
  );
  const currentFreeProductIsASubscribedProduct = checkIfExistingProductCanBeMadeFree(freeProductId);
  const notCurrentlySubscribedFreeProducts = productsInCart?.filter(
    p => p.productId === freeProductId && p.currentlySubscribed === false
  );
  // Hindi Channels
  const productsHindiChannels = productsInCart?.filter(p => p.categoryId === categoryIds.hindiChannels);
  const paperBillingProduct = productsInCart?.find(p => p.product.sku === productSkuIds.paperBilling.primary);

  const isOneOffProduct = (coupon: T_Coupon) => {
    try {
      let product = productsInCart?.find(p => p.productId === Number(coupon.custom1));
      return product?.product.sku === productSkuIds.arrowUpfrontBoxFee.primary;
    } catch (error) {
      return false;
    }
  };

  const sohoExistsFromPhantomCode = () => {
    const CableServiceOccurrence = billing?.services?.CableServiceOccurrence;
    return CableServiceOccurrence?.[0].serviceDetails.find(s => s.serviceCode === '260P') ? true : false;
  };

  return {
    productsInCart,
    starter,
    tvPackageProducts,
    addOnProducts,
    acquisitionSkyBoxProducts,
    acquisitionMultiroomProducts,
    skyBoxProducts,
    multiroomProducts,
    broadbandProducts,
    voiceProducts,
    voiceAddonsProducts,
    coupons,
    freeProducts,
    productsHindiChannels,
    tvPackageProductsIncSkyStarter,
    multiroomAndSkyBoxProducts,
    broadbandBBdevicesAndVoiceProducts,
    currentlySubscribedFreeProducts,
    currentFreeProductIsASubscribedProduct,
    notCurrentlySubscribedFreeProducts,
    existingOffers,
    allActiveCoupons,
    paperBillingProduct,
    findCampainEndDateForCoupon,
    boughtMultiroomProducts,
    newlyAddedMultiroomProducts,
    recordingProducts,
    isOneOffProduct,
    sohoExistsFromPhantomCode,
  };
}

export { useRunningTotal };
