import { AxiosInstance } from 'axios';

export class ChannelJSONConfigService {
  private agent: AxiosInstance;
  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }
  public getChannelConfig = async () => {
    let { data } = await this.agent.get('');
    return data;
  };
}
