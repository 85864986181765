import React, { useEffect, useState } from 'react';
import { Button } from './button';

interface Props {
  imageUrl: string;
  header: string;
  description: string;
  btnText?: string;
  countdown: number;
  btnClick?: () => void;
}

let stepCount = 0;

const ConfirmationStep = ({ imageUrl, header, description, btnText, countdown = 0, btnClick }: Props) => {
  const [stepNum, setStepNum] = useState(stepCount);

  let headerMobileView = (header.includes('Track'))?'Activate your TV device':header;
  useEffect(() => {
    stepCount += 1;
    setStepNum(stepCount);
    return () => {
      stepCount -= 1;
    };
  }, []);

  return (
    <div>
      <div className="flex flex-col md:flex-row gap-6 md:gap-10">
        <div className="mt-5 flex justify-center step-image md:w-2/5">
          <img className="object-contain"
            src={imageUrl}
            alt=""
          />
        </div>
        <div className="step-content mt-5 text-center md:text-left md:w-3/5">
          <h1 className="font-skyBlack step-content-header mb-6">
            <span className="lg-view">
            {stepNum}. {header}
            </span>
            <span className="sm-view">
            {stepNum}. {headerMobileView}
            </span>
          </h1>
          <div
            className="text-base leading-5 mb-6  "
            style={{ whiteSpace: 'pre-line', fontWeight: 450 }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {btnText && btnClick && (
            <>
              <Button
                variant="primary"
                className={'ml-0'}
                colorMode={countdown > 0 ? 'disabled' : 'pure'}
                onClick={() => btnClick()}
                disabled={countdown > 0}>
                {btnText}
              </Button>
              {countdown > 0 && (
                <p className="text-error">You can resend the verification email in {countdown} seconds.</p>
              )}
            </>
          )}
        </div>
      </div>
      <div className="border-line">
      </div>
    </div>
  );
};

export { ConfirmationStep };
