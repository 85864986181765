import {
  Type,
  Services,
  ServiceDetail,
  ServiceOccurrence,
  BillingFrequency,
  BillingCampaign,
  T_Billing,
} from '../../types';

const serviceStub: Services = {
  CableServiceOccurrence: [],
  DataServiceOccurrence: [],
  TelephoneServiceOccurrence: [],
  OTTServiceOccurrence: [],
};

const withStub = {
  accountNumber: '',
  statementCode: '',
};

const rateStub = {
  type: Type.Nzd,
  amount: '0',
  afterTax: '0',
};

const serviceDetailStub: ServiceDetail = {
  serviceCode: '',
  rate: rateStub,
  alaCarteRate: rateStub,
  quantity: 1,
  occurrenceDefinition: 0,
  serviceStatus: 0,
  installDate: '',
  startBillingDate: '',
  billThroughDate: '',
  lastChangeDate: '',
  billingFrequency: BillingFrequency.Monthly,
  externalProductId: 0,
  billingDescription: '',
  invoiceWith: withStub,
};

const serviceOccurrenceStub: ServiceOccurrence = {
  occurrenceNumber: 0,
  status: 1,
  occurrenceType: '',
  serviceDetails: [serviceDetailStub],
  installDate: '',
  billWith: withStub,
  billUsageWith: withStub,
};

const ledgerStub = {
  delinquentAmount1To30: '',
  delinquentAmount31To60: '',
  delinquentAmount61To90: '',
  delinquentAmount91To120: '',
  delinquentAmount121To150: '',
  delinquentAmountPlus: '',
  pendingPayment: '',
  unappliedPayment: '',
  unappliedAdjustment: '',
  writeOffAmount: '',
  totalArrears: '',
  inArrears: false,
};

const campaignStub: BillingCampaign = {
  beginDate: '',
  endDate: '',
  active: true,
  groupCode: '',
  promotionCode: '',
  promotionDescription: '',
  containedServices: serviceStub,
};

const billingStub: T_Billing = {
  accountNumber: '',
  houseNumber: '',
  lastStatementDate: '',
  statementDueDate: '',
  lastPaymentAmount: '',
  lastStatementBalance: null,
  currentTotalAmountDueCents: '',
  monthlyCharge: '',
  paperLessBilling: false,
  statementServiceCategories: [],
  ledger: ledgerStub,
  services: serviceStub,
  campaigns: [],
  channelLineupDefault: '',
  primaryAddress: false,
  pendingOrderCount: 1,
  activationDate: '',
  status: 1,
};

export {
  serviceDetailStub,
  serviceStub,
  rateStub,
  billingStub,
  serviceOccurrenceStub,
  campaignStub,
  ledgerStub,
  withStub,
};
