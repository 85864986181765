import React, { useEffect } from 'react';

import { Auth0ClientOptions } from '@auth0/auth0-spa-js';

import { useAuth0 } from './authStore';

export const Auth0Provider: React.FC<{
  config: Auth0ClientOptions;
  onRedirectCallback: (appState: any) => void;
}> = ({ children, config, onRedirectCallback }) => {
  let load = useAuth0(s => s.load);

  useEffect(() => {
    load(config, onRedirectCallback);
  }, [load, config, onRedirectCallback]);

  return <>{children}</>;
};
