import React from 'react';
import { Field, Form } from 'react-jeff';
import { ValidatedTextInput } from '../../validation/inputValidated';

interface Props {
  form: Form<string>;
  emailField: Field<string, string>;
}

const BroadbandEmail = ({ form, emailField }: Props) => {
  return (
    <div className="py-4 w-full md:w-1/2">
      <label className="block sky-h7-reg mb-2 text-midnight">Email</label>
      <ValidatedTextInput
        className="sky-input--string sky-h7-reg w-full mt-1"
        type="string"
        fieldValidation={emailField}
        formValidation={form}
        id="broadband-email"
        placeholder="John.smith@mail.com"
        onChange={emailField.props.onChange}
      />
    </div>
  );
};

export { BroadbandEmail };
