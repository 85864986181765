import React, { useState } from 'react';
import { BottomSheet, ClipBoard, RunningTotalInner } from '@sky-tv-group/components';
import { konakartService, myAccountSWR } from '../services';
import { CouponTypeValue, useRunningTotal, useProductInCart, useboxesStore, BoxTypes } from '@sky-tv-group/shared';

export const CartCount = ({
  onCartClick,
  btnLabel,
  className,
}: {
  onCartClick: () => void;
  btnLabel: string;
  className?: string;
}) => {
  const {
    starter,
    tvPackageProducts,
    addOnProducts,
    broadbandProducts,
    voiceProducts,
    voiceAddonsProducts,
  } = useRunningTotal(konakartService);
  const boxesFromStore = useboxesStore(s => s.boxes?.filter(b => b?.boxType === BoxTypes.NEW));
  const { broadbandDevices } = useProductInCart();
  const totalCartItems =
    (starter ? 1 : 0) +
    (tvPackageProducts?.length ?? 0) +
    (addOnProducts?.length ?? 0) +
    (broadbandDevices?.length ?? 0) +
    (broadbandProducts?.length ?? 0) +
    (voiceProducts?.length ?? 0) +
    (voiceAddonsProducts?.length ?? 0) +
    (boxesFromStore?.length ?? 0);
  return (
    <button onClick={onCartClick} className={className}>
      <div className="flex flex-col justify-center items-center">
        <div className="text-navy">
          <ClipBoard />
          <span
            style={{ padding: '1px 8px 0 8px', fontWeight: 700 }}
            className="absolute text-center text-white top-1 bg-blue-pure sky-text-xs rounded-full">
            {totalCartItems}
          </span>
        </div>
        <div className="sky-text-xxs">{btnLabel}</div>
      </div>
    </button>
  );
};

export const CartSummary = () => {
  const [showBottomSheet, setBottomSheet] = useState<boolean>(false);
  return (
    <>
      <CartCount onCartClick={() => setBottomSheet(true)} btnLabel="Summary" />
      {showBottomSheet && (
        <BottomSheet isOpen={showBottomSheet} onRequestClose={() => setBottomSheet(false)}>
          <h2 className="text-navy text-center mb-4">
            <span className="sky-h3-reg">Order</span>
            <span className="sky-h3-black ml-2">Summary:</span>
          </h2>
          <RunningTotalInner
            cartType={CouponTypeValue.Acquisition}
            show={true}
            konakartService={konakartService}
            myAccountSWR={myAccountSWR}
            showNew={true}
          />
        </BottomSheet>
      )}
    </>
  );
};
