import React, { useEffect, useState } from 'react';
import {
  CheckoutStates,
  InstallationType,
  useInstallationForm,
  useProductInCart,
  useBroadbandStore,
  useOrderStore,
} from '@sky-tv-group/shared';
import { FormElement, SiteAccessInformation, Login, Button } from '@sky-tv-group/components';

import { next, back } from '../navigation';
import { configService, prequalService, serviceProviderService } from '../../../services';
import { useCheckoutManager } from '../../../hooks/useCheckoutManager';
import { SkyBroadbandForm } from '../../../components/checkout';
import { ACCOUNT_URL } from '../../../config';
import { useAcquisitionJourneyState } from '../../../provider/AcquisitionJourney';
import { useRef } from 'react';
import { categoryIds } from '@sky-tv-group/shared';
import { BackButton } from '../../../components/back-button';
import history from '../../../history';
import { BreadcrumbContainer } from '../../../components/BreadcrumbContainer';

const BBForm = () => {
  const { selfInstallInCart } = useProductInCart();
  const { customerDetails } = useCheckoutManager();
  const { progressBar } = useAcquisitionJourneyState();
  const { isChangingProvider } = useBroadbandStore();

  const installationType = selfInstallInCart ? InstallationType.SelfInstall : InstallationType.BYOD;

  const {
    form,
    fields,
    prequalData,
    providers,
    installDates,
    activationDates,
    isShowSiteAccessInfo,
    siteAccessInformationFields,
  } = useInstallationForm(configService, prequalService, serviceProviderService, installationType, customerDetails);

  const { order } = useOrderStore();
  const voiceInCart = order?.orderProducts?.some(p => p.product.categoryId === categoryIds.voice) ?? false;

  let stickyRef = useRef<HTMLDivElement>(null) as any;

  function handleStickyBarScroll() {
    let footerElement = document.querySelector('#footerTermsAndCond') as any;
    if (stickyRef.current) {
      if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
        footerElement.style.marginBottom = stickyRef.current.offsetHeight + 'px';
      } else {
        footerElement.style.marginBottom = '0px';
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleStickyBarScroll);
    return () => {
      window.removeEventListener('scroll', handleStickyBarScroll);
    };
  }, []);
  const goNext = async () => {
    await form.submit();
    if (form.valid) {
      next(CheckoutStates.BROADBAND);
    }
  };

  const goBack = () => {
    back(CheckoutStates.BROADBAND);
  };

  const serviceProviderNotSelected = fields?.currentServiceProviderField?.value.length === 0;
  const isFibreSwap = prequalData?.isFibreSwapJourney() ?? false;
  const inValidBanner = isFibreSwap && !isChangingProvider;
  const disableNextButton = isFibreSwap ? !form.valid || inValidBanner || serviceProviderNotSelected : false;
  const [canweserve, setcanweserve] = useState(false);
  const handleCallback = (data: []) => {
    if (data.length > 0) {
      setcanweserve(false);
    } else {
      setcanweserve(true);
    }
  };

  return (
    <div>
      <BreadcrumbContainer progressBarStatus={false} />
      <div className="container m-auto md:pb-10 lg:mt-16 lg:px-6 mt-4 text-navy" data-testid="bb-form">
        <div className="flex flex-col justify-center items-center py-4 md:w-100 lg:hidden">
          <BackButton
            buttontext="Go back to Sky TV Installation"
            onClick={() => history.push('/build-my-plan/checkout/dth-form')}
          />
        </div>
        <div>
          <FormElement {...form.props}>
            <SkyBroadbandForm
              form={form}
              fields={fields}
              isFibreSwap={isFibreSwap}
              isNotIntactOnt={!prequalData?.isIntactONTJourney()}
              isNotChorus={!prequalData?.isChorus()}
              isNewFibre={prequalData?.isNewFibreJourney() ?? false}
              isSelfInstall={selfInstallInCart}
              consentRequired={prequalData?.consentRequired() ?? false}
              isLfcDoesntTellUsIfConsentRequired={prequalData?.isLfcDoesntTellUsIfConsentRequired() ?? false}
              providers={providers}
              installDates={installDates}
              activationDates={activationDates}
              handleCallback={handleCallback}
            />
            {isShowSiteAccessInfo && <SiteAccessInformation form={form} fields={siteAccessInformationFields} />}
            <div className=" rounded-lg border overflow-hidden text-white my-12 ">
              {/* TODO T&Cs here? */}
              <div
                className="block fixed
                 min-w-full bottom-0 lg:static z-50 md:static left-0"
                ref={stickyRef}>
                <div
                  className={`flex justify-between md:p-12 lg:p-12 min-w-full bottom-0 z-50 lg:shadow-none md:shadow-none lg:rounded-none md:rounded-none sticky-custom bg-white left-0 p-5`}>
                  <Button
                    data-testid="back-to-build-my-plan-checkout-button"
                    colorMode="light"
                    variant="secondary"
                    onClick={goBack}
                    disabled={false}
                    className="hidden lg:block md:block">
                    Back
                  </Button>
                  <Button
                    data-testid="next-to-confirm-button"
                    variant="primary"
                    onClick={goNext}
                    disabled={disableNextButton}
                    colorMode={disableNextButton ? 'disabled' : 'light'}
                    className="min-w-full min-w-fit">
                    {voiceInCart ? 'Next: Home Phone' : 'Next: View Order Details'}
                  </Button>
                </div>
              </div>
            </div>
          </FormElement>
        </div>
      </div>
    </div>
  );
};

export { BBForm };
