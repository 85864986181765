import { BroadbandReady, useJSONConfig, useNewAcquisitionOrderStore } from '@sky-tv-group/shared';

import { configService } from '../services';
// import { useChoosePlan } from './useChoosePlan';
// import { myAccountSWR } from '../swr';
// import { AppRoutes } from '../constants';

function useCheckYourAddress() {
  const { selectedTuiAddress, guestPrequalData } = useNewAcquisitionOrderStore(s => ({
    selectedTuiAddress: s.selectedTuiAddress,
    guestPrequalData: s.guestPrequalData,
  }));
  // const { getSkyBroadbandAction, selectProduct, broadbandProductInCart } = useChoosePlan();

  // const { isCustomerNeedsAccountUpdate } = myAccountSWR.usePackages();

  // TODO figure out why can't use one from prequal.ts.
  let getBroadbandReadyStatus = () => {
    let summary = guestPrequalData?.prequal.fibreSummary;
    if (summary?.fibreAvailable && (summary.fibreEta === null || summary.fibreEta === undefined)) {
      return {
        broadbandReadyStatus: BroadbandReady.FibreOK,
        broadbandReadyDate: undefined,
      };
    }
    if (summary?.fibreEta !== null && summary?.fibreEta !== undefined) {
      return {
        broadbandReadyStatus: BroadbandReady.FibreFuture,
        broadbandReadyDate: summary.fibreEta,
      };
    }
    if (!summary?.fibreAvailable) {
      return {
        broadbandReadyStatus: BroadbandReady.FibreNever,
        broadbandReadyDate: undefined,
      };
    }
    return {
      broadbandReadyStatus: BroadbandReady.Unknown,
      broadbandReadyDate: undefined,
    };
  };

  let isFibreOk = () => {
    let br = getBroadbandReadyStatus();
    return br?.broadbandReadyStatus === BroadbandReady.FibreOK;
  };

  let isFibreFuture = () => {
    let br = getBroadbandReadyStatus();
    return br?.broadbandReadyStatus === BroadbandReady.FibreFuture;
  };

  let isFibreNeverOrUnknown = () => {
    let br = getBroadbandReadyStatus();
    return (
      (br?.broadbandReadyStatus === BroadbandReady.FibreNever || br?.broadbandReadyStatus === BroadbandReady.Unknown) &&
      selectedTuiAddress !== '' &&
      selectedTuiAddress !== undefined
    );
  };

  let fibreETA = () => {
    let br = getBroadbandReadyStatus();
    return br?.broadbandReadyDate;
  };

  // let authTuiDiffersFromSelected = () => {
  //   if (!authTuiAddress || !selectedTuiAddress || !isAuthenticated) {
  //     return false;
  //   }
  //   return selectedTuiAddress.toString() !== authTuiAddress?.toString();
  // };

  let getTitle = () => {
    // if (authTuiDiffersFromSelected() && redirectFromCheckingPage) {
    //   return 'Whoops! We notice the address entered is different to what we have for you. To change your address contact our friendly Sky Crew on 0800 800 759.';
    // }

    // if (isCustomerNeedsAccountUpdate) {
    //   return "Looks like your account needs updating. Give our friendly Sky crew a call and they'll sort you out pronto.";
    // }

    // if (hasActiveOrPendingBroadbandPackage && !authTuiDiffersFromSelected()) {
    //   return 'Lucky you! You already have Sky Broadband at this address';
    // }

    return isFibreOk()
      ? "Perfect!  Fibre's available at this address."
      : isFibreFuture()
      ? 'Fibre not available yet'
      : isFibreNeverOrUnknown()
      ? 'Sorry! No Fibre available at this address '
      : 'Check your address here';
  };

  const config = useJSONConfig(configService);
  const wifiGuyImg: { available: string; comingSoon: string; neutral: string; unavailable: string } =
    config?.broadband?.acquisition?.wifiGuy?.checkAddress ?? undefined;

  let availableImg = wifiGuyImg?.available;
  let comingSoonImg = wifiGuyImg?.comingSoon;
  let neutralImg = wifiGuyImg?.neutral;
  let unavailableimg = wifiGuyImg?.unavailable;

  let getWifiGuy = () => {
    return isFibreOk()
      ? availableImg
      : isFibreFuture()
      ? comingSoonImg
      : isFibreNeverOrUnknown()
      ? unavailableimg
      : neutralImg;
  };

  let getButtonLabel = () => {
    // if (isCustomerNeedsAccountUpdate || authTuiDiffersFromSelected()) {
    //   return telPhoneNumber;
    // }

    // if (hasActiveOrPendingBroadbandPackage && !authTuiDiffersFromSelected()) {
    //   return 'Go to My Account';
    // }

    if (isFibreOk()) {
      // if (authTuiDiffersFromSelected()) {
      //   return telPhoneNumber;
      // }
      return 'Get Sky Broadband Now';
    } else {
      return "Let me know when it's available";
    }
  };

  let executeHomeButtonAction = async () => {
    executeButtonAction(true);
  };

  let executeOtherButtonAction = async () => {
    executeButtonAction(false);
  };

  let executeButtonAction = async (isHomePage: boolean | false) => {
    // if (isCustomerNeedsAccountUpdate) {
    //   window.open('tel:' + telPhoneNumber, '_self');
    //   return;
    // }
    // if (hasActiveOrPendingBroadbandPackage && !authTuiDiffersFromSelected()) {
    //   window.location.assign(ACCOUNT_URL);
    // } else if (isFibreOk()) {
    //   if (authTuiDiffersFromSelected()) {
    //     window.open('tel:' + telPhoneNumber, '_self');
    //     return;
    //   } else {
    //     if (isHomePage) {
    //       focusOnPackageSelector();
    //     }
    //     // else {
    //     //   if (broadbandProductInCart) {
    //     //     selectProduct(broadbandProductSelected);
    //     //   } else {
    //     //     getSkyBroadbandAction(broadbandProductSelected);
    //     //   }
    //     //   history.push(AppRoutes.Broadband);
    //     //   getSkyBroadbandAction();
    //     // }
    //   }
    // } else {
    //   window.open(BB_REGISTER_URL);
    // }
  };

  // let isInHomePage = () => {
  //   return history.location.pathname === AppRoutes.Home;
  // };

  return {
    isFibreOk,
    isFibreFuture,
    isFibreNeverOrUnknown,
    fibreETA,
    getTitle,
    getButtonLabel,
    executeHomeButtonAction,
    executeOtherButtonAction,
    // isInHomePage,
    // authTuiDiffersFromSelected,
    getWifiGuy,
    // hasActiveOrPendingBroadbandPackage,
  };
}
export { useCheckYourAddress };
