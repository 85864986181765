import { useCallback } from 'react';
import { AddressPrequalification, PrequalService } from '../services/prequal';
import { useBroadbandDeliveryStore } from '../store/broadbandDeliveryStore';
import { useBroadbandStore } from '../store/broadbandStore';

export let useBroadband = (prequalService: PrequalService) => {
  let [
    details,
    isValid,
    broadbandJourney,
    setBroadbandDetails,
    setIsValid,
    setBroadbandJourney,
    clearBroadbandStore,
  ] = useBroadbandStore(s => [
    s.details,
    s.isValid,
    s.broadbandJourney,
    s.setBroadbandDetails,
    s.setIsValid,
    s.setBroadbandJourney,
    s.clearBroadbandStore,
  ]);

  let [
    deliveryDetails,
    deliveryIsValid,
    prequalData,
    setBroadbandDeliveryDetails,
    setPrequalData,
    deliverySetIsValid,
    clearBroadbandDeliveryStore,
  ] = useBroadbandDeliveryStore(s => [
    s.details,
    s.isValid,
    s.prequalData,
    s.setBroadbandDeliveryDetails,
    s.setPrequalData,
    s.setIsValid,
    s.clearBroadbandDeliveryStore,
  ]);

  const getPrequalData = useCallback(
    async (addressId: string) => {
      // Fail gracefully if TUI was not returned.
      if (addressId === '0') return undefined;

      if (prequalData && !!prequalData.data) {
        return new AddressPrequalification(prequalData!.data);
      }

      const apiResponse = await prequalService.getPrequalDetails(addressId);
      const prequal = apiResponse.getPrequal();
      if (apiResponse) {
        const loadedPrequal = { addressId: addressId, data: prequal };
        setPrequalData(loadedPrequal);

        return apiResponse;
      }
      return undefined;
    },
    [setPrequalData, prequalData, prequalService]
  );

  const clearBroadbandStores = () => {
    clearBroadbandStore();
    clearBroadbandDeliveryStore();
  };

  return {
    details,
    deliveryDetails,
    isValid,
    deliveryIsValid,
    broadbandJourney,
    getPrequalData,
    setBroadbandDetails,
    setBroadbandDeliveryDetails,
    setIsValid,
    deliverySetIsValid,
    setBroadbandJourney,
    clearBroadbandStores,
  };
};
