import React from 'react';
import { Form } from 'react-jeff';
import { SiteAccessInformationFormFieldProps } from '@sky-tv-group/shared';
import { ValidatedTextArea, ValidatedTextInput } from '../../validation/inputValidated';

interface SiteAccessInformationProps {
  form: Form<string>;
  fields: SiteAccessInformationFormFieldProps;
}

const SiteAccessInformation: React.FunctionComponent<SiteAccessInformationProps> = ({ form, fields }) => {
  return (
    <>
      <div className="pt-8">
        <h4 className="bg-gray-light sky-h3 md:sky-h4 text-center h-24 flex justify-center items-center font-bold">
          Property access instructions
        </h4>
      </div>
      <div className="flex flex-col md:flex-row md:flex-wrap p-6 md:p-0 md:py-6">
        <div className="pb-4 w-full">
          <label className="block sky-label md:sky-label mb-2">
            Someone will need to be home, be a decision maker and at least 18 years of age, just in case the technicians
            need property decisions made. If there’s anything special you need to share around gaining access and
            obtaining permission for installation, give us the heads up on that too. Please include the property manager
            or property owner contact details if that’s a factor.
          </label>
        </div>
        <div className="pb-4 w-full">
          <label className="block sky-label md:sky-label mb-2">Contact Name</label>
          <ValidatedTextInput
            className="sky-input--string sky-h5 md:sky-h7 w-full mt-1"
            type="string"
            fieldValidation={fields.contactNameField}
            formValidation={form}
            id="broadband-contact-name"
            placeholder="Name"
            onChange={fields.contactNameField.props.onChange}
            maxLength={80}
          />
        </div>
        <div className="pb-4 w-full">
          <label className="block sky-label md:sky-label mb-2">Special instructions</label>
          <ValidatedTextArea
            className="sky-input--string sky-h5 md:sky-h7 w-full mt-1"
            type="string"
            fieldValidation={fields.notesField}
            formValidation={form}
            id="broadband-notes"
            placeholder="Additional Info for the Technician e.g. important site access info, location for connection..."
            onChange={fields.notesField.props.onChange}
            maxLength={130}
          />
        </div>
      </div>
    </>
  );
};

export { SiteAccessInformation };
