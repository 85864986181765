import { AxiosInstance } from 'axios';

import { T_Notification } from '../types';

export class NotificationService {
  private notifAgent: AxiosInstance;
  constructor(agent: AxiosInstance) {
    this.notifAgent = agent;
  }
  public getNotifications = async () => {
    try {
      const { data } = await this.notifAgent.get<T_Notification[]>(`/sn/notifications`);
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}
