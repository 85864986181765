import React, { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import { closex } from '../icon';

import { useHash } from '../ExploreModal/useModal';
import { TopBar } from './TopBar';
import { SKY_REWARD_TEMPLATE_TYPE, useCoupon, useEligibilityOffers } from '@sky-tv-group/shared';

export interface AnimatedModalProps {
  isShown: boolean;
  toggleModal: () => void;
  children: JSX.Element;
  topbarContent?: JSX.Element;
  shouldUseHash?: boolean;
  withPadding?: boolean;
  fullScreen?: boolean;
  theme?: 'WHITE' | 'BLACK';
  isBoxModal?: boolean;
}

export const AnimatedModal: FunctionComponent<AnimatedModalProps> = ({
  isShown,
  toggleModal,
  children,
  topbarContent,
  shouldUseHash,
  withPadding,
  fullScreen,
  theme,
  isBoxModal = false,
}) => {
  //check for rewards offer for user then add an inline style to the button
  const currentUserArrowLoyaltyOfferDetails = useEligibilityOffers().currentUserArrowLoyaltyOfferDetails();
  const rewardTemplateType = useCoupon().couponRewardTemplateType(
    currentUserArrowLoyaltyOfferDetails.coupon?.couponCode
  );

  let rewardsGradientStyle = {};
  rewardTemplateType === SKY_REWARD_TEMPLATE_TYPE.REWARD ||
  rewardTemplateType === SKY_REWARD_TEMPLATE_TYPE.NON_REWARD ||
  rewardTemplateType === SKY_REWARD_TEMPLATE_TYPE.ROI
    ? (rewardsGradientStyle = {
        background: 'linear-gradient(rgb(59, 75, 161), rgb(129, 45, 205))',
      })
    : null;

  if (shouldUseHash) useHash(toggleModal, isShown);

  if (!isShown) return null;

  return createPortal(
    <div className={`upgrade-modal-overlay ${isShown ? 'fixed' : 'hidden'}`}>
      <div
        className={`upgrade-modal-wrapper ${theme === 'BLACK' ? 'bg-black' : 'bg-white'} ${
          fullScreen ? 'full-screen' : undefined
        } fade-in`}>
        {topbarContent && (
          <TopBar theme={theme} hide={toggleModal}>
            {topbarContent}
          </TopBar>
        )}
        {!topbarContent && !isBoxModal && (
          <button
            style={rewardsGradientStyle}
            className="fixed text-3xl z-10 right-0 top-0 mt-5 mr-5 sm:mt-24 sm:mr-24 rounded-full w-12 h-12 text-white bg-blue-pure transform hover:bg-navy hover:rotate-180 transition duration-500 ease-in-out"
            onClick={toggleModal}>
            {closex}
          </button>
        )}
        {!topbarContent && isBoxModal && ''}
        <div className={`modal-inner ${withPadding ? 'py-32 px-20' : undefined}`}>{children}</div>
      </div>
    </div>,
    document.body
  );
};

export default AnimatedModal;
