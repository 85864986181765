import { EligibilityOffer } from '@sky-tv-group/graphql';
import {
  CouponTypes,
  CouponTypeValue,
  KonakartService,
  ServiceStatus,
  T_Coupon,
  T_Product,
  useCouponCode,
  useCouponStore,
  useProductStore,
} from '../../entry';
import { useEligibilityOffers } from './useEligibilityOffers';

function useOfferAllocation(konakartService: KonakartService) {
  const { eligibilityOffers } = useEligibilityOffers();
  const products = useProductStore().products;
  const { getCouponForProduct, clearCouponFromCode, applyCouponToStore } = useCouponStore();
  const { getCouponFromCouponCode, applyCouponCodeToState } = useCouponCode(konakartService);

  const pickBestOffer = (product: T_Product, productIdToDelete: number = -1): EligibilityOffer | undefined => {
    //Pick Offers for the product
    const offersForProduct = eligibilityOffers?.filter(eo => eo.promotion.coupon.custom1.indexOf(product.id) > -1);

    //Already added coupons for any associate product(s)
    const otherProductOffers: T_Coupon[] = [];

    //Filter multi product offers where other product is not subscribed or added to the cart
    const offersEligibleForProduct: EligibilityOffer[] | undefined = [];

    //Subscribed or added products
    //Filter the product to be deleted
    const subscribedOrAddedProducts = products.filter(
      p => (p.quantityBought > 0 || p.quantityInCart > 0) && (productIdToDelete < 0 || p.id !== productIdToDelete)
    );

    const currentProductAlreadySubscribedOrAdded = subscribedOrAddedProducts.find(p => p.id === product.id);
    const currentProductIfSubscribedBoughtTotal = currentProductAlreadySubscribedOrAdded?.quantityBought ?? 0;
    const currentProductIfAddedTotal = currentProductAlreadySubscribedOrAdded?.quantityInCart ?? 0;

    offersForProduct?.map(op => {
      //Check for maximum offer usage, if exceeds skip
      if (
        op.promotion.maxUse &&
        op.promotion.maxUse <= (currentProductIfAddedTotal || currentProductIfSubscribedBoughtTotal)
      ) {
        return false; //skip
      }

      //solo product offers
      if (op.promotion.coupon.custom1 == product.id) {
        offersEligibleForProduct?.push(op);
        return false; //skip
      }

      //check whether other product(s) are susbcribed or added to the cart
      const productIds = op.promotion.coupon.custom1.split(',');

      const otherProductIds = productIds.filter((p: string) => p !== product.id.toString());

      let otherProductSubscribedOrAddedCount = 0;
      subscribedOrAddedProducts.map(p => {
        if (otherProductIds.find((op: string) => op === p.id.toString())) {
          otherProductSubscribedOrAddedCount++;

          //Check whether other product has an offer
          const otherProductOffer = getCouponForProduct(p);

          //Update other product Offers list
          if (otherProductOffer) {
            otherProductOffers.push(otherProductOffer);
          }
        }
      });

      if (otherProductSubscribedOrAddedCount === otherProductIds.length) {
        offersEligibleForProduct?.push(op);
        return false; //skip
      }
    });

    //If no offers return
    if (!offersEligibleForProduct || offersEligibleForProduct.length === 0) return undefined;

    //Get the best offer
    const bestOffer = offersEligibleForProduct.sort((a, b) => a.priority - b.priority)[0];

    //Remove other product Offers assuming this is the best offer and to make sure no duplicate offers
    if (otherProductOffers) {
      otherProductOffers.map(o => {
        clearCouponFromCode(o.couponCode);
      });
    }

    return bestOffer;
  };

  const addOtherProductsOffersAfterProductIsRemoved = async (productId: number, coupon: T_Coupon): Promise<boolean> => {
    return new Promise(resolve => {
      //If there are no other product(s) return
      if (coupon.custom1 === productId.toString()) resolve(false);

      //Get the cart type from the removed coupon
      const cartType =
        coupon.custom3 === CouponTypeValue.Upgrade ? CouponTypeValue.Upgrade : CouponTypeValue.Acquisition;
      const productIds = coupon.custom1.split(',');
      const otherProductIds = productIds.filter(p => p !== productId.toString());

      //Go through other products (which are not already subscribed) to apply the best offer for them
      otherProductIds.map(async id => {
        const product = products.find(p => p.quantityBought <= 0 && p.id === Number(id));

        if (product) {
          const offer = pickBestOffer(product, productId);

          //Apply coupon to the store if there is EE offer
          if (offer) {
            resolve(await applyCouponCodeToState(offer.promotion.coupon, cartType));
          }

          //Check for offers from KK product custom2
          if (product.custom2) {
            const coupon = await getCouponFromCouponCode(product.custom2);
            if (coupon) {
              resolve(await applyCouponCodeToState(coupon, cartType));
            }
          }
        }
      });
    });
  };

  return {
    pickBestOffer,
    addOtherProductsOffersAfterProductIsRemoved,
  };
}

export { useOfferAllocation };
