import { AxiosInstance } from 'axios';
import { T_Phone_Options_Payload } from '../types';

export class VoiceService {
  private voiceAgent: AxiosInstance;
  constructor(voiceAgent: AxiosInstance) {
    this.voiceAgent = voiceAgent;
  }
  public getPhoneNumberOptions = async (payload: T_Phone_Options_Payload) =>
    (await this.voiceAgent.post<string[]>('/voice/phoneNumberOptions', payload)).data;
}
