import { KonakartService } from '../services/konakart';
import { useProductStore, productStoreApi } from '../store/productStore';
import { T_Coupon, T_Product } from '../types';
import { categoryIds, productSkuIds } from '../config';
import { useHandleOneTimeCharge } from './useHandleOneTimeCharge';

function usePrimaryBoxRequirementCheck(konakartService: KonakartService) {
  const { products, changeProductQuantity } = useProductStore(s => ({
    changeProductQuantity: s.changeProductQuantity,
    products: s.products,
  }));
  const { handleBoxOTC } = useHandleOneTimeCharge(konakartService);
  const boxes = products.filter(p => p.categoryId === categoryIds.box);

  const clearBox = (product: T_Product) => {
    changeProductQuantity(product.id, 0);
  };

  const boxesFromIds = (arrayOfIds: number[]): T_Product | undefined => {
    return boxes.find(b => arrayOfIds.includes(b.id));
  };

  /**
   * Handles adding a primary box to a coupon product
   *
   * @param coupon coupon object
   * @param update if we need to update konakart service
   */

  const requirePrimaryBox = (coupon: T_Coupon, update: boolean = true) => {
    const couponProducts = coupon.custom1.split(',').map(x => Number(x));
    const box = boxesFromIds(couponProducts);
    const currentBox = productStoreApi
      .getState()
      .products.find(b => b.quantityInCart > 0 && b.categoryId === categoryIds.box);

    // If there's a box in the query/coupon add the OTC
    if (box) {
      handleBoxOTC(box, update);
    }
    if (box && currentBox && box.id !== currentBox.id) {
      clearBox(currentBox);
    } else if (!box && !currentBox) {
      // If there's no box in the coupon and no box in the cart, add a default Sky Box
      const skyBox = products.find(
        p => p.sku === productSkuIds.skyBox.primary && p.custom3 === productSkuIds.skyBox.customAttribute
      );
      if (skyBox && skyBox.quantityInCart === 0 && skyBox.quantityBought === 0) {
        // if not added - add by default
        changeProductQuantity(skyBox.id, 1);
        handleBoxOTC(skyBox, update);
      }
    }
  };

  return {
    requirePrimaryBox,
  };
}
export { usePrimaryBoxRequirementCheck };
