import create from 'zustand';

import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { ACCOUNT_URL, AUTH0_LOGOUT_URL } from '../../config';
import { myAccountService } from '../../services';
import { segment } from '@sky-tv-group/shared';

interface Store {
  isAuthenticated: boolean;
  loading: boolean;
  user?: any;
  client?: Auth0Client;
  load: (config: Auth0ClientOptions, onRedirectCallback: (appState: any) => void) => void;
  logout: () => void;
  login: () => void;
}
//github.com/auth0/auth0-spa-js/blob/06026747255ef7798cc96820904f855fc993914c/src/index.ts
async function createAuth0Client(options: Auth0ClientOptions) {
  const auth0 = new Auth0Client(options);
  try {
    await auth0.getTokenSilently(options);
  } catch (error) {}
  return auth0;
}

export const [useAuth0, API] = create<Store>((set, get) => ({
  isAuthenticated: false,
  loading: true,
  load: async (config, onRedirectCallback) => {
    try {
      const client = await createAuth0Client(config);
      set({ client });
      let { search } = window.location;
      let fromAuth0LoginPage = false;
      if (search.includes('error=unauthorized')) {
        get().logout();
        return;
      }
      if (search.includes('code=') && search.includes('state=')) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
        fromAuth0LoginPage = true;
      }
      const isAuthenticated = await client.isAuthenticated();
      set({ isAuthenticated });
      if (isAuthenticated) {
        const user = await client.getUser({
          scope: config.scope!,
          audience: config.audience!,
        });
        set({ user });
        if (fromAuth0LoginPage) {
          const profile = await myAccountService.getProfile();
          segment.identify(user.sub, profile.email, profile.accountNumber, profile.profileId);

          const customer = await myAccountService.getCustomer();
          segment.signedIn(customer.accountNumber);
        }
      }
      set({ loading: false });
    } catch (err) {
      console.error(err);
      window.location.assign('/');
    }
  },
  logout: () => {
    get().client?.logout({ returnTo: AUTH0_LOGOUT_URL });
  },
  login: () => {
    get().client?.loginWithRedirect({ redirect_uri: ACCOUNT_URL });
  },
}));

let getUser = () => API.getState().user;
let getClient = () => API.getState().client;
export { getUser, getClient };
