import { StateCreatorWithNamedSet, SetStateWithName, GetState, StoreApiWithDevTools, PartialState } from 'zustand';
import { sessionStorage } from '../helpers/sessionStorage';
import { localStorage } from '../helpers/localStorage';

/**
 * A middleware for Zustand that adds state to session storage
 * To use this in an `@sky` package, you need to copy the zustand.d.ts file to the root of the package.
 *
 * @example - with combination of storeLogger
 * interface StoreShape {
 *     stateItems: string[];
 *     pushItem: (item: string) => void;
 *     popItem: () => string;
 * }
 *
 *const sessionStorageStateName = 'sessionStorageKey';
 *const getSessionStorageState = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}');
 *
 *const [useItemStore, itemStoreApi] = create<StoreShape>(
 *    // Appply the middleware
 *    ReduxDevTools(
 *      StatePersistence(
 *        ((set, get) => ({
 *          stateItems: [],
 *          ...getSessionStorageState, // gets from session storage
 *          pushItem: (item: string) => {
 *            const currentStateItems = get().stateItems;
 *            // provide a name for the set action that the Redux devtools can pickup
 *            set({ stateItems: [item, ...currentStateItems]}, 'pushItem');
 *          },
 *          popItem: () => {
 *            const [firstItem, ...rest] = get().stateItems;
 *            // optionally dont provide a name which will log the action with a warning.
 *            set({ stateItems: rest });
 *            return firstItem;
 *          },
 *      }),
 *      // name of the session storage
 *      'checkoutState'
 *      ),
 *    //
 *    'checkoutStore')
 * );
 *
 * @param createState The function normally provided to the create method
 * @param storageName The name of Session Storage key
 */
const StatePersistence = <TState>(createState: StateCreatorWithNamedSet<TState>, storageName: string) => (
  set: SetStateWithName<TState>,
  get: GetState<TState>,
  api: StoreApiWithDevTools<TState>
): TState => {
  const sessionSet = (state: PartialState<TState>, name?: string) => {
    set(state, name);
    sessionStorage.setItem(storageName, JSON.stringify(get()));
  };
  const initialState = createState(sessionSet, get, api);
  return initialState;
};

const LocalStatePersistence = <TState>(createState: StateCreatorWithNamedSet<TState>, storageName: string) => (
  set: SetStateWithName<TState>,
  get: GetState<TState>,
  api: StoreApiWithDevTools<TState>
): TState => {
  const sessionSet = (state: PartialState<TState>, name?: string) => {
    set(state, name);
    localStorage.setItem(storageName, JSON.stringify(get()));
  };
  const initialState = createState(sessionSet, get, api);
  return initialState;
};

export { StatePersistence, LocalStatePersistence };
