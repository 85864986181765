import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

export interface TermsAndConditionMenuProps {
  menus: MenuItemsType[];
  selectedMenu: string;
  splitIndex?: number;
}

export type MenuItemsType = {
  slug: string;
  text: string;
};

export const NavLinks = ({ links, selectedLink }: { links: MenuItemsType[]; selectedLink: string }) => (
  <>
    {(links ?? []).map(({ slug, text }) => (
      <Link
        id={`nav-link-${slug}`}
        key={`nav-link-${slug}`}
        className={`whitespace-no-wrap text-lg font-bold ${
          selectedLink === slug
            ? 'sky-text-daylight border-sky-secondaryBlue border-b-2 border-solid'
            : 'sky-text-midnight hover:sky-text-daylight'
        } `}
        to={`/${slug}`}>
        {text}
      </Link>
    ))}
  </>
);

export const TermsAndConditionMenu = ({ menus, selectedMenu, splitIndex = 6 }: TermsAndConditionMenuProps) => {
  const [firstRow, secondRow] = useMemo(() => [(menus ?? []).slice(0, splitIndex), (menus ?? []).slice(splitIndex)], [
    menus,
    splitIndex,
  ]);
  useEffect(() => {
    const element: HTMLAnchorElement | null = document.getElementById(`nav-link-${selectedMenu}`) as HTMLAnchorElement;
    if (element) element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }, [selectedMenu]);
  return (
    <>
      <div className="flex lg:justify-center items-center gap-4 p-4 overflow-x-auto bg-white mx-auto no-scrollbar scroll-smooth">
        <NavLinks selectedLink={selectedMenu} links={firstRow} />
      </div>
      <div className="flex lg:justify-center items-center gap-4 p-4 overflow-x-auto bg-white sticky top-4 z-10 mx-auto no-scrollbar scroll-smooth">
        <NavLinks selectedLink={selectedMenu} links={secondRow} />
      </div>
    </>
  );
};
