import { categoryIds, ELIGIBILITY_ACQUISITION_TYPE, productSkuIds, SPLITIO_KEY } from '../config';
import { useOrderStore } from '../store/orderStore';
import { useEligibilityOffers } from './eligibilityOffers/useEligibilityOffers';
import { useSplitIO } from './useSplitIO';

function useProductInCart() {
  const { order } = useOrderStore();
  const [dartPreorderEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_VTV_DART_PREORDER);
  const { acquisitionType } = useEligibilityOffers();
  const isVTVStandalone = acquisitionType && acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone;
  const broadbandProductInCart = order?.orderProducts?.some(p => p.categoryId === categoryIds.broadband) ?? false;
  const meshInCart = order?.orderProducts?.some(p => p.product.sku === productSkuIds.meshDevice.primary) ?? false;
  const voiceInCart = order?.orderProducts?.some(p => p.product.categoryId === categoryIds.voice) ?? false;
  const selfInstallInCart =
    order?.orderProducts.some(p => p.product.sku === productSkuIds.broadbandSelfInstall.primary) ?? false;
  const starterInCart = order?.orderProducts.some(p => p.product.sku === productSkuIds.starter.primary) ?? false;
  const starterAndSkyBoxOnlyInCart =
    (order?.orderProducts.some(p => p.product.sku === productSkuIds.starter.primary) &&
      order?.orderProducts.some(p => p.product.sku === productSkuIds.skyBox.primary) &&
      order?.orderProducts.length === 3) ??
    false;

  const boxes = order?.orderProducts.filter(op => op.product.categoryId === categoryIds.box) ?? [];
  const newBoxes = boxes.filter(box => !box.currentlySubscribed);
  const requireDeliveryOnly =
    (newBoxes?.length > 0 && newBoxes.every(op => op.product.sku === productSkuIds.skyPod.primary)) ||
    (isVTVStandalone && dartPreorderEnabled);

  const tvPackagesProducts = order?.orderProducts.filter(
    p => p.categoryId === categoryIds.package || p.categoryId === categoryIds.packageUpgrade
  );
  const addonProducts = order?.orderProducts.filter(
    p => p.categoryId === categoryIds.hindiChannels || p.categoryId === categoryIds.special
  );
  const boxProducts = order?.orderProducts.filter(
    p => p.categoryId === categoryIds.box || p.categoryId === categoryIds.multiroom
  );

  /** Any new DTH product in cart. */
  const isDTHInCart =
    (tvPackagesProducts && tvPackagesProducts.length > 0) ||
    (addonProducts && addonProducts.length > 0) ||
    (boxProducts && boxProducts.length > 0);

  const isArrowInCart =
    boxProducts &&
    boxProducts.length > 0 &&
    boxProducts.some(
      p => p.product.sku === productSkuIds.arrowBox.primary || p.product.sku === productSkuIds.arrowBoxBlack.primary
    );

  const oneOffProducts = order?.orderProducts.filter(
    p =>
      p.categoryId === categoryIds.oneOffFee ||
      p.categoryId === categoryIds.broadbandOneOffFee ||
      p.categoryId === categoryIds.broadbandTechnicianFee
  );

  const oneOffProductsWithoutBBOneOffFee = order?.orderProducts.filter(
    p =>
      (p.categoryId === categoryIds.oneOffFee || p.categoryId === categoryIds.broadbandTechnicianFee) && p.price !== 0
  );

  const technicianFee = (order?.orderProducts ?? []).filter(r => r.product.categoryId === categoryIds.technicianFee);
  const broadbandTechnicianFee = (order?.orderProducts ?? []).filter(
    r => r.product.categoryId === categoryIds.broadbandTechnicianFee
  );

  const broadbandDevices = order?.orderProducts.filter(p => p.categoryId === categoryIds.broadbandDevices);

  const broadbandPhoneProducts = order?.orderProducts.filter(
    p =>
      p.categoryId === categoryIds.broadband ||
      p.categoryId === categoryIds.broadbandDevices ||
      p.categoryId === categoryIds.voice ||
      p.categoryId === categoryIds.voiceAddons ||
      p.categoryId === categoryIds.voiceCrossCountry
  );

  return {
    broadbandProductInCart,
    meshInCart,
    selfInstallInCart,
    voiceInCart,
    starterInCart,
    productsInCart: order?.orderProducts,
    orderTotals: order?.orderTotals,
    starterAndSkyBoxOnlyInCart,
    isDTHInCart,
    isArrowInCart,
    tvPackagesProducts,
    addonProducts,
    boxProducts,
    oneOffProducts,
    technicianFee,
    broadbandTechnicianFee,
    broadbandDevices,
    broadbandPhoneProducts,
    oneOffProductsWithoutBBOneOffFee,
    requireDeliveryOnly,
  };
}

export { useProductInCart };
