export let getStyledText = (text?: string, numberClass?: string) => {
  const regexNumber = /\b\d+\b/g;
  const regexEmail = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z|a-z]{2,}\b/g;
  let StyledText = text;
  if (numberClass) {
    StyledText = text?.replace(regexNumber, `<span class="${numberClass}">$&</span`);
  }
  StyledText = StyledText?.replace(regexEmail, '<strong>$&</strong>');
  return StyledText;
};
