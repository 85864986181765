import {
  useProductInCart,
  ELIGIBILITY_ACQUISITION_TYPE,
  useEligibilityOffers,
  useSplitIO,
  SPLITIO_KEY,
} from '@sky-tv-group/shared';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { konakartService } from '../../services';
import { routes } from './routes';
import { RunningTotal } from '../../components/running-total/RunningTotal';

export let BuildMyPlan = () => {
  const [showMobileRuningTotal] = useSplitIO(SPLITIO_KEY.SKYWEB_MOBILE_RUNNINGTOTAL);
  let { path: parentPath } = useRouteMatch();
  let location = useLocation();
  let { productsInCart } = useProductInCart();
  const { acquisitionType } = useEligibilityOffers();

  useEffect(() => {
    let nav = window.document.getElementById('header-nav');
    if (nav) {
      nav.style.visibility = 'hidden';
    }
    return () => {
      if (nav) {
        nav.style.visibility = 'visible';
      }
    };
  });

  const _routes = routes.filter(r =>
    acquisitionType && acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone ? r.path !== '/broadband' : true
  );

  let showRunningTotal =
    !location.pathname.includes(_routes[_routes.length - 5]?.path) && productsInCart && productsInCart.length > 0;

  let widthControl = showRunningTotal ? 'w-full lg:w-4/5' : 'w-full my-4';

  return (
    <div className="container-fluid mx-auto">
      <div className="flex flex-col lg:flex-row">
        <div className={widthControl}>
          <Switch>
            {_routes.map(({ path, exact, component }) => (
              <Route key={path} exact={exact} path={parentPath + path} component={component} />
            ))}
            <Redirect to={parentPath + _routes[0].path + location.search} from={parentPath} />
          </Switch>
        </div>
        {showRunningTotal && (
          <div
            className={`${
              showMobileRuningTotal ? 'w-full' : 'hidden md:block'
            } lg:w-1/5 my-0 lg:sticky lg:stick-to-top lg:right-0 top-0`}>
            <RunningTotal show={showRunningTotal} konakartService={konakartService} />
          </div>
        )}
      </div>
    </div>
  );
};
