import { AxiosInstance } from 'axios';

export class ServiceProviderService {
  private serviceProviderAgent: AxiosInstance;
  constructor(serviceProviderAgent: AxiosInstance) {
    this.serviceProviderAgent = serviceProviderAgent;
  }

  public getProviders = async () => {
    const data = (await this.serviceProviderAgent.get<ServiceProvider[]>('/service-providers')).data;
    return data;
  };
  public getVoiceProviders = async () => {
    const data = (await this.serviceProviderAgent.get<ServiceProvider[]>('/service-providers/voice')).data;
    return data;
  };
}

export interface ServiceProvider {
  code: string;
  name: string;
}
