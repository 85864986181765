import React from 'react';
interface Props {
  errorMsg: { header: string; paragraph: string; para2: string }[];
  linkText?: string;
  linkURL?: string;
}

const ErrorScreen = ({ errorMsg, linkText, linkURL }: Props) => {
  const helpText = linkText ? errorMsg[0].para2.split(linkText) : [errorMsg[0].para2];

  return (
    <div style={{ maxWidth: 840, marginLeft: 'auto', marginRight: 'auto' }} className="mt-20 mb-40 text-center">
      <div className=" error-header mb-6 ">{errorMsg[0]?.header}</div>
      <div className="leading-6  text-xl">{errorMsg[0]?.paragraph}</div>
      <br />
      <div className="leading-6  text-xl font-bold">
        {helpText.map((item: any, index: any) => (
          <div key={index}>
            {item}
            {index !== helpText.length - 1 && (
              <a href={linkURL} style={{ textDecoration: 'underline', color: '#0057FF' }}>
                {linkText}
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export { ErrorScreen };
