import React from 'react';

interface LogoProps {
  color: string;
  height?: number;
}

export const Logo = ({ color, height }: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 200 117.8"
    version="1.1"
    viewBox="0 0 200 117.8"
    xmlSpace="preserve"
    height={height}>
    <path
      fill={color}
      d="M61.3 78.3c-2 9.2-7.7 17.6-24 17.8H1.7c-1.1 0-1.9-1-1.7-2.1l2.6-11.8c.2-.8.9-1.4 1.7-1.4h33c5.3 0 6.8-1.2 7.3-3.1.4-1.7-.2-3.3-4.4-5l-17.1-7c-6.5-2.5-15.4-7.7-12.9-19 2-8.9 8.3-17.3 24.1-17.4h33.2c1.1 0 1.9 1 1.7 2.1l-2.6 11.8c-.2.8-.9 1.4-1.7 1.4H32.8c-4.3 0-5.5 1.4-5.7 2.9-.3 1.6 1.5 2.9 6 4.9l15.6 6.5c7.9 3.3 15.1 7.8 12.6 19.4zM99.9 0H86.5c-.8 0-1.5.6-1.7 1.4L64.6 94c-.2 1.1.6 2.1 1.7 2.1h13.4c.8 0 1.5-.6 1.7-1.4L101.6 2c.2-1-.6-2-1.7-2zm98.4 29.4h-15.2c-.5 0-1 .2-1.3.6l-24.5 27.7-16.4-27.4c-.3-.5-.9-.8-1.5-.8h-21.1c-.5 0-1 .2-1.3.6L88.5 62.2l19.8 33.1c.3.5.9.8 1.5.8h15.8c1.3 0 2.2-1.5 1.5-2.6l-18.7-31.3L127.9 40l19.5 32.7-9.4 43.1c-.2 1.1.6 2.1 1.7 2.1h12.9c.8 0 1.5-.6 1.7-1.4l9.6-43.9 35.7-40.4c.9-1.1.2-2.8-1.3-2.8z"
      className="st0"></path>
  </svg>
);
