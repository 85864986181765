import create from 'zustand';
import { sessionStorage } from '../helpers/sessionStorage';
import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';

const sessionStorageStateName = 'URL Params Store';
const getSessionStorageState = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}');

interface urlParamsStore {
  selectedSrc?: string;
  setSelectedSrc: (Value: string) => void;
  clearStore: () => void;
}

const [useUrlParamsStore, urlParamsStoreApi] = create<urlParamsStore>(
  ReduxDevTools(
    StatePersistence(
      set => ({
        selectedSrc: undefined,
        ...getSessionStorageState,
        setSelectedSrc: (value: string | undefined) => {
          set({ selectedSrc: value }, 'set selectedSrc');
        },
        clearStore: () => {
          set(
            {
              selectedSrc: undefined,
            },
            'clear selectedSrc'
          );
        },
      }),
      sessionStorageStateName
    ),
    'URL Params Store'
  )
);

export { useUrlParamsStore, urlParamsStoreApi };
