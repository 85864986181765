import React from 'react';
import { format } from 'date-fns';
import { AccountDetails, CheckoutStates } from '@sky-tv-group/shared';
import { SectionHeader } from '../../header/SectionHeader';
import { SquareEditIcon } from '../../icon';
import { FormWrapper } from '../../..';

interface Props {
  accountDetails: AccountDetails;
  edit: (state: string) => void;
  showSectionHeader?: boolean;
}
const AccountDetailsReview = ({ accountDetails, edit, showSectionHeader = true }: Props) => {
  const getBillingAddress = (): string => {
    if (accountDetails.propertyDetail.paperlessBilling) {
      return accountDetails.propertyDetail.serviceContactEmail.length
        ? accountDetails.propertyDetail.serviceContactEmail
        : accountDetails.yourDetail.email;
    }
    if (!accountDetails.propertyDetail.billingAddressSameAsInstallationAddress) {
      return accountDetails.propertyDetail.billingAddress?.label ?? '';
    }
    return accountDetails.propertyDetail.address?.label ?? '';
  };

  return (
    <>
      {showSectionHeader && <SectionHeader titleText="Account Details" />}
      <FormWrapper>
        <div className="w-full my-4">
          <div className="flex-col mx-4 md:mx-0">
            <div className="flex w-full items-center mb-6">
              <h4 className="flex-1 sky-h6-black md:sky-h7-black">Your Details</h4>
              <p className="text-blue-pure cursor-pointer flex flex-row" onClick={() => edit(CheckoutStates.DETAILS)}>
                Edit
                <span className="ml-2">
                  <SquareEditIcon />
                </span>
              </p>
            </div>
            <div className="flex w-full mb-4">
              <p className="sky-h6-bold">{`${accountDetails.yourDetail.firstName} ${accountDetails.yourDetail.familyName}`}</p>
            </div>
            <div className="flex w-full mb-4">
              <div className="flex-col w-1/2 md:w-1/4">
                <p className="sky-h7-reg text-gray-darker mb-1">Date of birth</p>
                <p className="sky-h6-bold">
                  {format(new Date(accountDetails.yourDetail.dateOfBirth ?? ''), 'd MMM YYY')}
                </p>
              </div>
              <div className="flex-col flex-1">
                <p className="sky-h7-reg text-gray-darker mb-1">Email</p>
                <p className="sky-h6-bold">{accountDetails.yourDetail.email}</p>
              </div>
            </div>
            <div className="flex w-full mb-4">
              <div className="w-1/2 md:w-1/4">
                <p className="sky-h7-reg text-gray-darker mb-2">Phone</p>
                <p className="sky-h6-bold">{`${accountDetails.yourDetail.phoneAreaCode} ${accountDetails.yourDetail.phoneTelephoneNumber}`}</p>
              </div>
              <div className="flex-1">
                <p className="sky-h7-reg text-gray-darker mb-2">Bills will be sent to</p>
                <p className="sky-h6-bold">{getBillingAddress()}</p>
              </div>
            </div>
          </div>
        </div>
      </FormWrapper>
    </>
  );
};

export { AccountDetailsReview };
