import { T_Product, useTotalResponse } from '../types';
import { useManagePackage } from './useManagePackage';
import { productSkuIds } from '../config';
import { useMemo } from 'react';
import { useOrderStore } from '../store/orderStore';
import { MyAccountSWR } from '../swr/myaccount';

export let useTotals = (myAccountSWR: MyAccountSWR): useTotalResponse => {
  const { data: billing } = myAccountSWR.useBilling();
  const { order } = useOrderStore(s => ({
    order: s.order,
  }));

  const { broadbandInCart, currentlySubscribedProducts, currentlySubscribedBroadbandPackage } = useManagePackage(
    myAccountSWR
  );

  let removedBroadbandProducts: T_Product[] = [];
  if (currentlySubscribedBroadbandPackage && broadbandInCart) {
    removedBroadbandProducts = [currentlySubscribedBroadbandPackage];
    const meshProduct = currentlySubscribedProducts.find(p => p.sku === productSkuIds.meshDevice.primary);
    if (
      meshProduct &&
      (broadbandInCart?.product.sku === productSkuIds.broadbandLightningFastWiFi.primary ||
        broadbandInCart?.product.sku === productSkuIds.broadbandWifi100.primary)
    ) {
      removedBroadbandProducts.push(meshProduct);
    }
  }

  let removedBroadbandProductsPrice = removedBroadbandProducts.reduce((p, c) => {
    return p + c.priceIncTax;
  }, 0);

  const orderTotals = useMemo(() => {
    return [
      order?.orderTotals.find(t => t.className === 'ot_subtotal'),
      order?.orderTotals.find(t => t.className === 'ot_total'),
      order?.orderTotals.find(t => t.className === 'ot_total_no_otc'),
      order?.orderTotals.find(t => t.className === 'ot_technician_fee'),
    ] as const;
  }, [order]);

  // current user monthly charge taken from billing info
  const monthlyCharge = parseFloat(billing?.monthlyCharge ?? '0');
  // regular one off fee + Arrow oneoff fee
  // ot_total - ot_total_no_otc + ot_technician_fee (a bug in kk service, has to do this way )+ arrow one off fee
  const oneOff = orderTotals[1]?.value! - orderTotals[2]?.value! + (orderTotals[3]?.value ?? 0);

  const totals = {
    monthlyPriceWithoutOffer: orderTotals[0]?.value! - removedBroadbandProductsPrice,
    //We need to adjust Monthly Price with offers as we do not support multiple products with different campaigns.
    //Once we suppoer above we can remove this adjustment
    monthlyPriceWithOffer: orderTotals[2]?.value! - removedBroadbandProductsPrice,
    // ot_total_otc + ot_total_no_otc === ot_total
    priceYouPayToday: orderTotals[1]?.value! - removedBroadbandProductsPrice,
    oneOff,
    monthlyCharge,
  };

  return totals;
};
