import { useCallback } from 'react';
import { useProductStore } from '../store/productStore';
import { T_Product } from '../types';

/**
 * Hook for toggling product logic.
 *
 * Includes popping toasts for adding and removal.
 */
function useToggleProduct() {
  const { changeProductQuantity } = useProductStore(s => ({ changeProductQuantity: s.changeProductQuantity }));

  /**
   * Toggles product - does update the store, but not konakart
   */
  const toggleProduct = useCallback(
    (
      productId: number,
      quantity: number,
      trackEvent?: boolean,
      boxId?: string,
      overrides?: Pick<T_Product, 'inPromotion' | 'priceIncTax'>
    ) => {
      changeProductQuantity(productId, quantity, trackEvent, boxId, overrides);
    },
    [changeProductQuantity]
  );

  return toggleProduct;
}

export { useToggleProduct };
