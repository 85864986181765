import { messages } from '../config';
import { KonakartService } from '../services/konakart';
import { useCouponStore } from '../store/couponStore';
import { useFreeProductStore } from '../store/freeProductStore';
import { useProductStore } from '../store/productStore';
import { T_Product } from '../types';
import { useRemoveTextFormatter } from './useRemoveTextFormatter/useRemoveTextFormatter';
import { useCouponCode } from './useCouponCode';
import { useFreeProduct } from './useFreeProduct';
import { useToastContainer } from './useToastContainer';
import { shouldShowCouponToast } from './couponToastHelper';
import { useOfferAllocation } from './eligibilityOffers/useOfferAllocation';

function useProductWithCouponRemoval(konakartService: KonakartService) {
  const {
    hasDependencyToast,
    addToDependencyToasts,
    removeFromDependencyToasts,
    addAwaitableToast,
  } = useToastContainer();
  const { coupons, getCouponForProduct } = useCouponStore(s => ({
    coupons: s.coupons,
    getCouponForProduct: s.getCouponForProduct,
  }));
  const { removeCouponFromCart } = useCouponCode(konakartService);
  const { freeProduct, initiallyAdded, clearFreeProduct } = useFreeProductStore(s => ({
    freeProduct: s.freeProduct,
    initiallyAdded: s.initiallyAdded,
    clearFreeProduct: s.clearFreeProduct,
  }));
  const { removalText } = useRemoveTextFormatter();
  const { removeFreeProductWithToast } = useFreeProduct(konakartService);
  const changeProductQuantity = useProductStore(s => s.changeProductQuantity);
  const { addOtherProductsOffersAfterProductIsRemoved } = useOfferAllocation(konakartService);

  /**
   * Meant for regular Products
   * For the existing cart to toggle products - can add / remove products with this.
   * When you know the product is from the product Store.
   * Cannot be the OrderProduct.product object
   * @param product
   * @param toggleProduct
   * @param update
   * @param removeAccessories - If set to true, any connected addons to the product will be removed upon update
   */
  const handleRemovalOfT_ProductWithToast = async (
    product: T_Product,
    toggleProduct: (
      productId: T_Product,
      update?: boolean | undefined,
      removeAccessories?: boolean | undefined
    ) => Promise<void>,
    update: boolean = true,
    removeAccessories: boolean = true
  ) => {
    // if no coupon then toggle the product
    // this is to prevent case where no coupon in Konakart && this is nonexistent so don't have matching empty
    if (coupons.length === 0) {
      await toggleProduct(product, update, removeAccessories);
      return;
    }
    // Check to see if the product and coupon are associated.
    const productCoupon = getCouponForProduct(product);

    // if there are coupon
    if (productCoupon) {
      // pop a toast about removing the coupon too
      if (hasDependencyToast(product.id)) return;

      const isFreeProductRemovedProduct = handleFreeProduct(product.id);
      const message = removalText(true, product.name, undefined, productCoupon, isFreeProductRemovedProduct);

      const removeCoupon = async () => {
        removeCouponFromCart(productCoupon);
        handleRemovalOfFreeProduct(isFreeProductRemovedProduct);
        await toggleProduct(product, update, removeAccessories);
      };

      if (!(await shouldShowCouponToast(productCoupon))) {
        removeCoupon();
        return;
      }
      const { toastId, promise } = addAwaitableToast({
        title: messages.areYouSure,
        type: 'warning',
        message: message,
        action: async () => {
          await removeCoupon();
        },
        actionText: messages.removeCoupon,
        closeText: messages.noThanks,
        onClose: async () => {
          await removeFromDependencyToasts(product.id);
        },
      });
      addToDependencyToasts(product.id, toastId);
      return promise;
    }

    // else just toggle it out of the cart
    await toggleProduct(product, update, removeAccessories);
  };

  /**
   * Meant for OrderProducts.Products
   * Handles case only when removing the product that may have association with a coupon object.
   * This is for OrderProducts as toggleCartProduct retrieves a product from our store which will contain the depends field required by dependencyCheck
   * @param productId
   * @param toggleCartProduct
   * @param update
   * @param existingCampaign
   */
  const handleRemovalOfT_OrderProductWithToast = async (
    product: Pick<T_Product, 'id' | 'name'>,
    toggleCartProduct: (productId: number, update?: boolean | undefined) => Promise<void>,
    update: boolean = true
  ) => {
    // if no coupon then toggle product as normal
    if (coupons.length === 0) {
      await toggleCartProduct(product.id, update);
      return;
    }
    // Check to see if the product and coupon are associated.
    const productCoupon = getCouponForProduct(product);

    // if the product has an offer effecting it
    if (productCoupon) {
      if (hasDependencyToast(product.id)) return;
      // pop a toast about removing the coupon too

      const isFreeProductRemovedProduct = handleFreeProduct(product.id);
      const message = removalText(true, product.name, undefined, productCoupon, isFreeProductRemovedProduct);

      let { toastId, promise } = addAwaitableToast({
        title: messages.areYouSure,
        type: 'warning',
        message: message,
        action: async () => {
          // remove the coupon in the coupon store
          removeCouponFromCart(productCoupon);
          // Check if the free product is related to the coupon
          handleRemovalOfFreeProduct(isFreeProductRemovedProduct);
          //If any associated products for the removed coupon add any offers available
          await addOtherProductsOffersAfterProductIsRemoved(product.id, productCoupon);
          // after that then do a single update order of removal of product and coupon
          await toggleCartProduct(product.id, update);
        },
        onClose: async () => {
          await removeFromDependencyToasts(product.id);
        },
        actionText: messages.removeCoupon,
        closeText: messages.noThanks,
      });
      addToDependencyToasts(product.id, toastId);
      return promise;
    }
    // else just toggle it out of the cart
    await toggleCartProduct(product.id, update);
  };

  const handleFreeProduct = (productId: number): boolean => {
    return removeFreeProductWithToast(productId);
  };

  const handleRemovalOfFreeProduct = (messageFreeProductCheck: boolean) => {
    // Check if the free product is related to the coupon
    if (messageFreeProductCheck) {
      if (freeProduct) {
        clearFreeProduct();
        if (!initiallyAdded) {
          // if not initially added we can remove it
          changeProductQuantity(freeProduct.productId, 0);
        }
      }
    }
  };

  return {
    handleRemovalOfT_ProductWithToast,
    handleRemovalOfT_OrderProductWithToast,
  };
}

export { useProductWithCouponRemoval };
