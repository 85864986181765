import React from 'react';
import { formatMoney, useOrderStore } from '@sky-tv-group/shared';
import { useTotalResponse } from '@sky-tv-group/shared/src/types';

interface Props {
  totals: useTotalResponse;
  handleNext: () => void;
  handleBack: () => void;
}

export const StickyControlPanel = ({ totals, handleNext, handleBack }: Props) => {
  const { isOrderPending } = useOrderStore();
  return (
    <div className=" border lg:sticky hidden lg:block " style={{ top: '60px' }}>
      <h4
        className="text-left bg-white px-4 py-10 hidden lg:flex items-center justify-between sky-text-blue"
        style={{ color: '#0057ff' }}>
        <span className="sky-h4-black">Your Package</span>
        <span className="sky-h4-bold">{formatMoney(totals.monthlyPriceWithoutOffer ?? 0)}/mth</span>
      </h4>

      <div className="flex justify-between bg-gray-medium p-4 text-md border-t border-b border-gray font-skyBold">
        <div className=" text-black">Your First Bill</div>
        <div>
          <strong>{formatMoney(totals.priceYouPayToday)}</strong>
        </div>
      </div>
      <div className="flex flex-row shadow-md lg:flex-row 2xl:flex-row justify-between sky-bg-gray-light gap-3 p-4">
        <button
          data-testid="next-to-checkout-button"
          className="lg:w-full sky-button sky-button--primary-light mx-0 px-6"
          style={{
            minWidth: 'unset',
            backgroundColor: isOrderPending ? '#cccc' : '#0057FF',
            borderColor: isOrderPending ? '#cccc' : '#0057FF',
          }}
          onClick={handleNext}
          disabled={isOrderPending}>
          Next: About You
        </button>
      </div>
    </div>
  );
};
