/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Field, useField } from 'react-jeff';
import { isValid, subYears } from 'date-fns';

import { AddressService, capitalize, useAccountDetails } from '@sky-tv-group/shared';
import { ValidatedTextInput } from '../validation/inputValidated';
import { ValidatedPhoneNumber } from '../validation/phonenumberValidated';
import { ValidationDatePicker } from '../validation/dateValidated';
import { ConditionsCheckbox } from './review/ConditionsCheckbox';
import { ArrowDown, ArrowUp, CheckIcon } from '../icon';
import { Button } from '../button';
export interface IYourDetailsProps {
  titleText: string;
  formValidation: Form<string>;
  firstNameField: Field<string, string>;
  familyNameField: Field<string, string>;
  emailField: Field<string, string>;
  phoneAreaCodeField: Field<string, string>;
  phoneTelephoneNumberField: Field<string, string>;
  dateOfBirthField: Field<Date | null, string>;
  addressService: AddressService;
  emailConsentField?: boolean;
  handleApicall?: (value: boolean) => void;
  isAbandon?: boolean;
  showDetailsCheck?: boolean;
}

const YourDetails: React.FunctionComponent<IYourDetailsProps> = ({
  titleText,
  formValidation,
  firstNameField,
  familyNameField,
  emailField,
  phoneAreaCodeField,
  phoneTelephoneNumberField,
  dateOfBirthField,
  addressService,
  emailConsentField = false,
  handleApicall,
  isAbandon,

  showDetailsCheck,

}) => {
  const {
    yourDetail,
    updateFirstName,
    updateDateOfBirth,
    updateEmail,
    updateFamilyName,
    updateAreaCode,
    updateTelephoneNumber,
    updateEmailConsent,
  } = useAccountDetails(addressService);

  const emailConsentValue = useField({
    defaultValue: yourDetail?.emailConsent === undefined ? false : yourDetail?.emailConsent,
    required: false,
  });
  // update fields to session storage state when changed
  useEffect(() => {
    updateFirstName(firstNameField.value);
  }, [firstNameField.value]);

  useEffect(() => {
    updateFamilyName(familyNameField.value);
  }, [familyNameField.value]);

  useEffect(() => {
    updateEmail(emailField.value);
  }, [emailField.value]);

  useEffect(() => {
    updateAreaCode(phoneAreaCodeField.value);
  }, [phoneAreaCodeField.value]);

  useEffect(() => {
    updateTelephoneNumber(phoneTelephoneNumberField.value);
  }, [phoneTelephoneNumberField.value]);

  useEffect(() => {
    updateDateOfBirth(dateOfBirthField.value);
  }, [dateOfBirthField.value]);

  // initialize values from session storage
  useEffect(() => {
    if (yourDetail.firstName) {
      firstNameField.setValue(yourDetail.firstName);
    }
    if (yourDetail.familyName) {
      familyNameField.setValue(yourDetail.familyName);
    }
    if (yourDetail.email) {
      emailField.setValue(yourDetail.email);
    }
    if (yourDetail.phoneAreaCode) {
      phoneAreaCodeField.setValue(yourDetail.phoneAreaCode);
    }
    if (yourDetail.phoneTelephoneNumber) {
      phoneTelephoneNumberField.setValue(yourDetail.phoneTelephoneNumber);
    }
    if (isValid(yourDetail.dateOfBirth)) {
      dateOfBirthField.setValue(yourDetail.dateOfBirth);
    }
  }, []);
  const handleMarketingMaterials = (value: boolean) => {
    emailConsentValue.setValue(value);
    updateEmailConsent(value);
    if (handleApicall) {
      handleApicall(value);
    }
  };

  return (
    <>
      {isAbandon ? (
        <div className="border-gray-300  ">
          <h2 className="px-8 md:px-0 py-3 border-gray-300  text-navy pb-6 ">
            <span className="sky-h3-reg md:sky-h4-reg">About</span>
            <span className="sky-h3-black md:sky-h4-black ml-2">You:</span>
          </h2>
          <div className='border-2 p-4 mb-4 rounded-lg'>
          <div className="flex flex-col md:flex-row md:flex-wrap p-6 md:p-0 md:py-6 border-gray-300 border-y">
            <div className="flex flex-col mb-3 w-full "  >
              <div className="flex flex-row  md:w-full justify-between sky-h4-black md:sky-h6-black">
                <div>Your Details</div>
              </div>
            </div>
            { (
              <>
                <div className="pb-4 md:w-1/2 md:pr-2">
                  <label className="block sky-label sky-text-midnight md:sky-label mb-2">First name</label>
                  <ValidatedTextInput
                    fieldValidation={firstNameField}
                    formValidation={formValidation}
                    id="account-details-your-details-first-name"
                    className="w-full"
                    type="text"
                    onChange={str => {
                      firstNameField.props.onChange(capitalize(str));
                    }}
                    placeholder="John"
                  />
                </div>
                <div className="pb-4 md:w-1/2 md:pl-2">
                  <label className="block sky-label sky-text-midnight md:sky-label mb-2">Last name</label>
                  <ValidatedTextInput
                    fieldValidation={familyNameField}
                    formValidation={formValidation}
                    id="account-details-your-details-family-name"
                    className="w-full"
                    type="text"
                    onChange={str => {
                      familyNameField.props.onChange(capitalize(str));
                    }}
                    placeholder="Smith"
                  />
                </div>
                <div className="flex flex-col md:flex-row md:w-full mb-4 md:mb-0">
                  <div className="pb-4 pr-0 md:pr-2 w-full md:w-1/2">
                    <label className="block sky-label sky-text-midnight md:sky-label mb-2">Email</label>
                    <ValidatedTextInput
                      fieldValidation={emailField}
                      formValidation={formValidation}
                      id="account-details-your-details-email"
                      className="w-full"
                      type="email"
                      onChange={emailField.props.onChange}
                      placeholder="john.smith@mail.com"
                    />
                  </div>
                  <div className="p-0 md:pl-2 w-full md:w-1/2">
                    <label className="block sky-label sky-text-midnight md:sky-label mb-2">Phone number</label>
                    <ValidatedPhoneNumber
                      areaCodeField={phoneAreaCodeField}
                      lineNumberField={phoneTelephoneNumberField}
                      onAreaCodeFieldChange={phoneAreaCodeField.props.onChange}
                      onLineNumberFieldChange={phoneTelephoneNumberField.props.onChange}
                      id="account-details-your-details"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row md:w-full">
                  <div className="pb-4 pr-0 md:pr-2 w-full md:w-1/2">
                    <div className="flex  flex-col md:flex-row ">
                      <label className="block sky-label sky-text-midnight md:sky-label mb-2">Date of birth</label>
                      <span className="mt-0.5 md:ml-2  sky-text-midnight text-lg md:text-sm">
                        You must be at least 18 years old to subscribe.{' '}
                      </span>
                    </div>
                    <ValidationDatePicker
                      fieldValidation={dateOfBirthField}
                      selected={yourDetail.dateOfBirth ?? undefined}
                      placeholderText="dd/mm/yyyy"
                      id="account-details-your-details-date-of-birth"
                      formValidation={formValidation}
                      className="w-full"
                      minDate={subYears(new Date(), 120)}
                      maxDate={subYears(new Date(), 18)}
                      showMonthDropDown={true}
                      showYearDropdown={true}
                    />
                  </div>
                </div>
                {emailConsentField && (
                  <div>
                   <ConditionsCheckbox field={emailConsentValue} onChange={handleMarketingMaterials} isAbandoned={true}>
                      I consent to receive electronic messages (e.g. SMS and email) from Sky with unmissable highlights,
                      what’s new and awesome as well as exclusive access to competitions, offers and Sky Rewards
                      (optional)
                    </ConditionsCheckbox>
                  </div>
                )}
                <div className="sky-h6-reg">
                  <span>
                    Any personal information submitted will be held in accordance with Sky’s
                    <a href="https://www.sky.co.nz/privacy-policy" className="text-blue-pure underline">
                      {' '}
                      privacy policy.{' '}
                    </a>
                  </span>
                </div>

                {/* button */}
                {/* <div className="flex justify-center md:justify-start my-2 mt-4 min-w-full ">
                  <Button colorMode="pure" className ='min-w-full min-w-fit px-4' variant="secondary" disabled={false} onClick={handlePropertyDetails}>
                    {`Next: Enter Property Details`}
                  </Button>
                </div> */}
              </>
            )}
          </div>
          </div>
        </div>
      ) : (
        <>
          {titleText && (
            <h4 className="bg-gray-light sky-h3 md:sky-h4 text-center h-24 p-4 flex justify-center items-center font-bold">
              {titleText}
            </h4>
          )}
          <div className="flex flex-col md:flex-row md:flex-wrap p-6 md:p-0 md:py-6">
            <div className="pb-4 md:w-1/2 md:pr-2">
              <label className="block sky-label md:sky-label mb-2">First name</label>
              <ValidatedTextInput
                fieldValidation={firstNameField}
                formValidation={formValidation}
                id="account-details-your-details-first-name"
                className="w-full"
                type="text"
                onChange={str => {
                  firstNameField.props.onChange(capitalize(str));
                }}
                placeholder="John"
              />
            </div>
            <div className="pb-4 md:w-1/2 md:pl-2">
              <label className="block sky-label md:sky-label mb-2">Family name</label>
              <ValidatedTextInput
                fieldValidation={familyNameField}
                formValidation={formValidation}
                id="account-details-your-details-family-name"
                className="w-full"
                type="text"
                onChange={str => {
                  familyNameField.props.onChange(capitalize(str));
                }}
                placeholder="Smith"
              />
            </div>
            <div className="pb-4 w-full">
              <label className="block sky-label md:sky-label mb-2">Email</label>
              <ValidatedTextInput
                fieldValidation={emailField}
                formValidation={formValidation}
                id="account-details-your-details-email"
                className="w-full"
                type="email"
                onChange={emailField.props.onChange}
                placeholder="john.smith@mail.com"
              />
            </div>
            <div className="flex flex-col md:flex-row md:w-full">
              <div className="pb-4 pr-0 md:pr-2 w-full md:w-1/2">
                <label className="block sky-label md:sky-label mb-2">Phone number</label>
                <ValidatedPhoneNumber
                  areaCodeField={phoneAreaCodeField}
                  lineNumberField={phoneTelephoneNumberField}
                  onAreaCodeFieldChange={phoneAreaCodeField.props.onChange}
                  onLineNumberFieldChange={phoneTelephoneNumberField.props.onChange}
                  id="account-details-your-details"
                />
              </div>
              <div className="p-0 md:pl-2 w-full md:w-1/2">
                <label className="block sky-label md:sky-label mb-2">Date of birth</label>
                <ValidationDatePicker
                  fieldValidation={dateOfBirthField}
                  placeholderText="dd/mm/yyyy"
                  id="account-details-your-details-date-of-birth"
                  formValidation={formValidation}
                  className="w-full"
                  minDate={subYears(new Date(), 120)}
                  maxDate={subYears(new Date(), 18)}
                  showMonthDropDown={true}
                  showYearDropdown={true}
                />
                <p className="sky-sub md:sky-sub pt-2">You must be at least 18 years old to subscribe.</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { YourDetails };
