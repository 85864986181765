import create from 'zustand';
import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';
import { OrderNumber } from '../types';
import { sessionStorage } from '../helpers/sessionStorage';
interface NewUpgradeOrderStore {
  workOrderNumbers: OrderNumber[];
  firstName: string;
  isShowConfirmationPage: boolean;
  hasBroadband: boolean;
  hasDevice: boolean;
  setWorkOrderNumbers: (value: OrderNumber[]) => void;
  setFirstName: (value: string) => void;
  setIsShowConfirmationPage: (value: boolean) => void;
  setHasBroadband: (value: boolean) => void;
  setHasDevice: (value: boolean) => void;
  clear: () => void;
}

const sessionStorageStateName = 'New Upgrade Order Store';
const getSessionStorageState = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}');

const [useNewUpgradeOrderStore, newUpgradeOrderStoreApi] = create<NewUpgradeOrderStore>(
  ReduxDevTools(
    StatePersistence(
      set => ({
        workOrderNumber: '',
        firstName: '',
        isShowConfirmationPage: '',
        hasBroadband: false,
        hasDevice: false,
        ...getSessionStorageState,
        setWorkOrderNumbers: (value: OrderNumber[]) => {
          set({ workOrderNumbers: value }, 'set Work Order number');
        },
        setFirstName: (value: string) => {
          set({ firstName: value }, 'set First name');
        },
        setIsShowConfirmationPage: (value: boolean) => {
          set({ isShowConfirmationPage: value }, 'set Show confirmation page');
        },
        setHasBroadband: (value: boolean) => {
          set({ hasBroadband: value }, 'set Has broadband');
        },
        setHasDevice: (value: boolean) => {
          set({ hasDevice: value }, 'set Has Device');
        },
        clear: () => {
          set(
            {
              workOrderNumbers: [],
              firstName: '',
              isShowConfirmationPage: false,
              hasBroadband: false,
              hasDevice: false,
            },
            'clear data'
          );
          sessionStorage.removeItem(sessionStorageStateName);
        },
      }),
      sessionStorageStateName
    ),
    'Order Store'
  )
);
export { useNewUpgradeOrderStore, newUpgradeOrderStoreApi };
