import { useEffect, useState } from 'react';
import { addBusinessDays, addMonths, eachWeekendOfInterval, isWeekend, parseISO } from 'date-fns';

import { categoryIds } from '../config';

import { JSONConfigService } from '../services/jsonConfig';
import { AddressPrequalification } from '../services/prequal';
import { DateRange, InstallationType } from '../types';
import { orderStoreApi } from '../store/orderStore';

const today = new Date();
today.setHours(0, 0, 0, 0);

// Returns a date that is not excluded based on config and not a weekend
function nextAvailableDate(exclude: number[], current: Date): Date {
  if (exclude.includes(current.getTime()) || isWeekend(current)) {
    return nextAvailableDate(exclude, addBusinessDays(current, 1));
  }
  return current;
}

// Returns a date based on prequal and installation type
function getActivationDate(
  prequal: AddressPrequalification,
  installationType: InstallationType,
  broadbandBYODDefaultLeadDays: number,
  broadbandDIYActivationLeadDays: number,
  broadbandVoiceDefaultLeadDays: number
) {
  const orderProducts = orderStoreApi.getState().order?.orderProducts ?? [];
  const hasVoice = orderProducts.find(p => p.categoryId === categoryIds.voice) ?? false;

  if (prequal?.isFibreSwapJourney() || prequal?.isIntactONTJourney()) {
    if (installationType === InstallationType.BYOD) {
      /* If fibre swap or intact ONT journey, and BYOD and voice. */
      if (hasVoice) {
        return addBusinessDays(today, broadbandVoiceDefaultLeadDays);
      } else {
        return addBusinessDays(today, broadbandBYODDefaultLeadDays);
      }
    } else {
      return addBusinessDays(today, broadbandDIYActivationLeadDays);
    }
  } else {
    return addBusinessDays(today, broadbandVoiceDefaultLeadDays);
  }
}

// Get earliest installation date that is not a holiday or weekend or excluded dates from config,
// used for new fiber journeys.
function getEarliestInstallationDate(excludedDates: Date[], broadbandNewInstallationLeadDays: number) {
  const exclude = excludedDates.map(date => date.getTime());
  return nextAvailableDate(exclude, addBusinessDays(today, broadbandNewInstallationLeadDays));
}

// Get earliest activation date, used for fibre swap journeys
function getEarliestActivationDate(
  excludedDates: Date[],
  installationType: InstallationType,
  prequal: AddressPrequalification,
  broadbandBYODDefaultLeadDays: number,
  broadbandDIYActivationLeadDays: number,
  broadbandVoiceDefaultLeadDays: number
) {
  const excludedDateTime = excludedDates.map(date => date.getTime());

  const earliestActivationDate = getActivationDate(
    prequal,
    installationType,
    broadbandBYODDefaultLeadDays,
    broadbandDIYActivationLeadDays,
    broadbandVoiceDefaultLeadDays
  );

  return nextAvailableDate(excludedDateTime, earliestActivationDate);
}

function useBroadbandInstallationDate(
  configService: JSONConfigService,
  prequal: AddressPrequalification | null,
  installationType: InstallationType,
  selectedDate?: Date | null
) {
  const [excludeDates, setExcludeDates] = useState<Date[]>([]);

  /**
   * Activation/installatin dates
   */
  const [earliestActivationDate, setEarliestActivationDate] = useState<Date>(new Date());
  const [earliestInstallationDate, setEarliestInstallationDate] = useState<Date>(new Date());
  const [selectedActivationDate, setSelectedActivationDate] = useState<Date | null>(selectedDate ? selectedDate : null);

  const loadTimesFromJSONConfig = async (setExcludedDatesState: boolean) => {
    const config = await configService.getConfig();
    let excludedDates = ((config?.broadband?.excludeInstallationDates ?? []) as string[]).map(str => parseISO(str));

    // If we aren't setting the excludeDates value, we wan't to be using
    // that value instead of the new dates we get from configService
    if (!setExcludedDatesState) {
      excludedDates = excludeDates;
    }

    // Check to see if we want to set the state for excludeDates
    if (setExcludedDatesState) {
      setExcludeDates(excludedDates);
    }

    /* Set broadband lead times from JSON config. */
    if (config?.broadband?.leadTimes && prequal) {
      const _earliestActivationDate = getEarliestActivationDate(
        excludedDates,
        installationType,
        prequal,
        config?.broadband?.leadTimes?.broadbandBYODDefaultLeadDays,
        config?.broadband?.leadTimes?.broadbandDIYActivationLeadDays,
        config?.broadband?.leadTimes?.broadbandVoiceDefaultLeadDays
      );
      setEarliestActivationDate(_earliestActivationDate);

      if (!selectedDate) {
        setSelectedActivationDate(_earliestActivationDate);
      }

      setEarliestInstallationDate(
        getEarliestInstallationDate(excludedDates, config?.broadband?.leadTimes?.broadbandNewInstallationLeadDays)
      );
    }
  };

  useEffect(() => {
    if (prequal) {
      loadTimesFromJSONConfig(true);
    }
  }, [prequal]);

  useEffect(() => {
    if (prequal) {
      loadTimesFromJSONConfig(false);
    }
  }, [excludeDates]);

  const latestInstallationDate: Date = addMonths(earliestInstallationDate, 2);
  const installationWeekdaysOnly: Date[] = eachWeekendOfInterval({
    start: earliestInstallationDate,
    end: latestInstallationDate,
  });
  const excludeInstallationDates = installationWeekdaysOnly.concat(excludeDates);
  const installDates: DateRange = {
    exclude: excludeInstallationDates,
    earliest: earliestInstallationDate,
    latest: latestInstallationDate,
  };

  const latestActivationDate: Date = addMonths(earliestActivationDate, 2);
  const activationWeekdaysOnly: Date[] = eachWeekendOfInterval({
    start: earliestActivationDate,
    end: latestActivationDate,
  });
  const excludeActivationDates = activationWeekdaysOnly.concat(excludeDates);
  const activationDates: DateRange = {
    exclude: excludeActivationDates,
    earliest: earliestActivationDate,
    latest: latestActivationDate,
  };

  return {
    earliestInstallationDate,
    earliestActivationDate,
    excludeDates,
    installDates,
    activationDates,
    selectedActivationDate,
  };
}

export { useBroadbandInstallationDate, getEarliestInstallationDate, getEarliestActivationDate };
