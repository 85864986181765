import React from 'react';
import { Button } from '../button';
import { useModal } from '../ExploreModal/useModal';
import { MultiroomModal, MultiroomModalProps } from './MultiroomModal';
import { useCoupon, useEligibilityOffers } from '@sky-tv-group/shared';

interface EditMultiroomButtonProps {
  modalProps: Omit<MultiroomModalProps, 'header' | 'toggleModal'>;
  className?: string;
}

export const EditMultiRoomButton = ({ modalProps, className = 'min-w-40' }: EditMultiroomButtonProps) => {
  const { showModal, toggleModal } = useModal();
  const { couponRewardTemplateType } = useCoupon();
  const currentUserArrowLoyaltyOfferDetails = useEligibilityOffers().currentUserArrowLoyaltyOfferDetails();
  return (
    <>
      <Button
        variant="secondary"
        colorMode="pure"
        className={className}
        templateType={couponRewardTemplateType(currentUserArrowLoyaltyOfferDetails.coupon?.couponCode)}
        onClick={toggleModal}>
        Edit
      </Button>
      {showModal && (
        <MultiroomModal
          header={modalProps.isPrimary ? 'Edit Primary Device' : 'Edit Multiroom Device'}
          toggleModal={toggleModal}
          {...modalProps}
        />
      )}
    </>
  );
};
