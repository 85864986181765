import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface GetSkyOfferBannerProps {
    bannerData: any
}

const GetSkyOfferBanner: React.FC<GetSkyOfferBannerProps> = ({ bannerData }) => {

    const componentData = bannerData?.data?.ComponentCardSection[0]?.componentCards[0];

    if (componentData) {
        return (
            <div className="flex md:flex-row flex-col getsky-banner relative" >
                { componentData.cardImageUrl?.url 
                && (<img className="relative" src={componentData.cardImageUrl?.url} />)}
                <div style={{ background: 'linear-gradient(9deg, #01003D, #163EA7, #0C97E4 )' }}
                    className="getsky-banner-content relative flex-col justify-start items-start inline-flex ">
                    { componentData.heading && (<div style={{ color: 'rgb(14 165 233)' }}
                        className="self-stretch font-black font-skyBlack getsky-banner-title">{componentData.heading}</div>)}
                    <div className="justify-start items-baseline inline-flex">
                        { componentData.cardTitle && (<div style={{ fontSize: '48px', lineHeight: '58px' }} className="text-white font-black font-skyBlack">{componentData.cardTitle}</div>)}
                        { componentData.cardSubTitle && (<div style={{ fontSize: '24px', lineHeight: '28px' }} className="text-white font-black">{componentData.cardSubTitle}</div>)}
                    </div>
                    { componentData.cardDescription && (<div className="text-white text-xl leading-normal"> {componentData.cardDescription}  </div>)}
                    {documentToReactComponents(componentData.cardDetailedDescription)}
                    <div className="right-1 top-1 absolute flex-col justify-start items-start inline-flex">
                        <div
                            className="z-10 overflow-hidden "
                            style={{
                                borderRadius: '4px',
                                transform: 'skew(-15deg)',
                                transformOrigin: '0 0',
                                marginTop: `0px`,
                                marginLeft: `0px`,
                            }}>
                            <div
                                className="font-skyBook text-white whitespace-pre-line text-lg md:text-sm font-bold"
                                style={{
                                    background: 'rgb(236 72 153)',
                                    width: '120px',
                                    lineHeight: '12px',
                                    padding: '8px',
                                    wordBreak: 'keep-all',
                                    whiteSpace: 'nowrap',
                                    borderRadius: '4px',
                                    transform: 'skew(15deg)',
                                    transformOrigin: '0 0',
                                    perspective: '1px',
                                }}>
                                {componentData.linkText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    } else {
        return null;
    }
}

export { GetSkyOfferBanner };