import React from 'react';
import { ArrowRightIcon } from '@sky-tv-group/components';

interface Props {
  className?: any;
  onClick?: (e: any) => any;
  buttontext?: string;
}

export const BackButton: React.FC<Props> = ({ className, onClick, buttontext }: Props) => {
  return (
    <button className="flex flex-row" onClick={onClick}>
      <div className="mr-1">
        <ArrowRightIcon />
      </div>
      <div className="text-sky-blue sky-h6-reg font-medium">{buttontext}</div>
    </button>
  );
};
