import create from 'zustand';

import { ReduxDevTools } from '../helpers/storeLogger';
import { ToastProps } from '../types';

export type StoreToastProps = ToastProps & { time?: number };
export type DepToastKey = string;
export type DepToastId = string;
export type OfferToastKey = string;

// the store for managing Toast notifications
interface ToastStore {
  toasts: Record<string, StoreToastProps>;
  depToasts: Record<DepToastKey, DepToastId>;
  addDepToast: (productIds: DepToastKey, toastId: DepToastId) => void;
  removeDepToast: (productIds: DepToastKey) => void;
  getDepToast: (productIds: number | number[]) => [DepToastKey, DepToastId];
  getOfferToast: () => [OfferToastKey, StoreToastProps] | undefined;
  addToast: (toastId: string, toastProps: StoreToastProps) => void;
  removeToast: (toastId: string) => void;
  removeAllToasts: () => void;
}

const [useToastStore, toastStoreApi] = create<ToastStore>(
  ReduxDevTools(
    (set, get) => ({
      toasts: {},
      depToasts: {},
      addDepToast: (productIds, toastId) => {
        set(
          // grab only depToasts from state
          ({ depToasts }) => ({
            // add a new toast reference into depToasts
            depToasts: { ...depToasts, [productIds]: toastId },
          }),
          'addDepToast'
        );
      },
      removeDepToast: productIds => {
        set(
          // grab only depToasts from state
          ({ depToasts }) => {
            // destructure depToasts into the toast at productId and the rest
            const { [productIds]: toastToRemove, ...rest } = depToasts;
            // just return the rest discarding the one at productId
            return { depToasts: rest };
          },
          'removeDepToast'
        );
      },
      getDepToast: productIds => {
        const { depToasts } = get();
        const productIdString = productIds instanceof Array ? productIds.join() : productIds.toString();
        // find if any of the dep toast keys include the productIds passed in
        const depToastKey = Object.keys(depToasts).find(k => k.includes(productIdString));
        // if not return without doing anything
        if (!depToastKey) {
          return ['', ''];
        }
        return [depToastKey, depToasts[depToastKey]];
      },
      getOfferToast: () => {
        const { toasts } = get();
        const offerToastKey = Object.keys(toasts).find(k => toasts[k].type === 'offer');
        if (!offerToastKey) {
          return undefined;
        }
        return [offerToastKey, toasts[offerToastKey]];
      },
      addToast: (toastId, toastProps) => {
        set(
          // grab only toasts from state
          ({ toasts }) => ({
            // add the new toast reference into the toasts list
            toasts: { ...toasts, [toastId]: toastProps },
          }),
          'addToast'
        );

        const time = toastProps.time ?? 0;
        let timeoutID;
        // if the new toast has a timeout associated with it
        if (time > 0) {
          // add a timeout for it
          timeoutID = setTimeout(() => {
            get().removeToast(toastId);
          }, time * 1000);
        }
      },
      removeToast: toastId => {
        set(
          // grab only toasts from the state
          ({ toasts }) => {
            // destructure toasts into the toast at toastId and rest
            const { [toastId]: toastToRemove, ...rest } = toasts;
            // just return the rest discarding the one at toastId
            return { toasts: rest };
          },
          'removeToast'
        );
      },
      removeAllToasts: () => {
        set(
          {
            toasts: {},
            depToasts: {},
          },
          'removeAllToasts'
        );
      },
    }),
    'Toast Store'
  )
);

export { useToastStore, toastStoreApi };
