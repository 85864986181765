import { Field } from 'react-jeff';
import { parseISO } from 'date-fns';
import { hardcodedTermsAndConditions } from '../config';
import { AddressPrequalification, PrequalService } from '../services/prequal';
import { FibreCompanies, Privacy, T_Customer, NullableAddressLookup } from '../types';
import { getTuiAddressCode } from './customer';

const NonContractPrivacyCode = 'ATO';

export function getEffectivePrivacyList(privacyList: Privacy[]) {
  if (privacyList && privacyList.length > 0) {
    privacyList = privacyList.filter(p => {
      let privacyCodeExpiration = p.privacyCodeExpiration ? parseISO(p.privacyCodeExpiration) : '';
      let privacyCodeEffective = p.privacyCodeEffective ? parseISO(p.privacyCodeEffective) : '';
      let isCancelled = p.cancelationFlag === 'Y' ? true : false;
      var now = new Date();
      let isPrivacyCodeExpired = privacyCodeExpiration && privacyCodeExpiration <= now ? true : false;
      let isPrivacyCodeStarted = privacyCodeEffective && privacyCodeEffective >= now ? false : true;
      // If privacy code is not yet expired and it is not cancelled and it has already started, it's effective.
      return !isPrivacyCodeExpired && !isCancelled && isPrivacyCodeStarted;
    });
  }
  return privacyList;
}

export function isPrivacyCodeValid(privacyList: Privacy[], codeToCheck: string) {
  const effectiveCodes = getEffectivePrivacyList(privacyList).map((pc: Privacy) => pc.privacyCode);
  return effectiveCodes.includes(codeToCheck);
}

export function isCustomerNonContract(privacyList: Privacy[]) {
  return isPrivacyCodeValid(privacyList, NonContractPrivacyCode);
}

export function isCustomerPermittedForBroadband(customer: T_Customer, BROADBAND_PRIVACY_CODES: string): boolean {
  let broadbandPrivacyCodes: string[] = BROADBAND_PRIVACY_CODES?.split(',').map(code => code.trim());
  let privacyList = customer?.privacyList ? customer?.privacyList : [];
  let effectivePrivacyList = getEffectivePrivacyList(privacyList);
  let isPermitted = false;
  if (broadbandPrivacyCodes) {
    effectivePrivacyList.forEach(p => {
      if (broadbandPrivacyCodes.includes(p.privacyCode)) {
        //if the customer privacy code matches any accepted codes for broadband show it for them
        isPermitted = true;
      }
    });
  }
  return isPermitted;
}

export const isFibreAvailable = async (
  tuiAddressCode: string,
  getPrequalDataFunction: (addressId: string) => Promise<AddressPrequalification | undefined>,
  prequalService: PrequalService
) => {
  const lookupFunction = getPrequalDataFunction ?? prequalService.getPrequalDetails;
  try {
    if (tuiAddressCode?.length > 0 && tuiAddressCode !== '0') {
      const prequal = await lookupFunction(tuiAddressCode);
      return prequal?.isFibreAvailable() ?? false;
    }
  } catch {}
  return false;
};

export async function isCustomerValidForBroadband(
  customer: T_Customer,
  prequalData: AddressPrequalification,
  BROADBAND_PRIVACY_CODES: string
) {
  if (isCustomerPermittedForBroadband(customer, BROADBAND_PRIVACY_CODES)) {
    return (
      (prequalData?.isIntactONTJourney() || prequalData?.isFibreSwapJourney() || prequalData?.isNewFibreJourney()) ??
      false
    );
  }
  return false;
}

export async function isCustomerQualifiedForBroadband(
  customer: T_Customer | undefined,
  prequalData: AddressPrequalification,
  BROADBAND_PRIVACY_CODES: string
) {
  if (!customer || (customer && customer.tuiAddressCode.length < 2)) {
    return false;
  }
  return (
    (await isCustomerValidForBroadband(customer, prequalData, BROADBAND_PRIVACY_CODES)) &&
    prequalData.isFibreAvailable()
  );
}

export async function isIntactONTJourneyNotChorus(
  customer: T_Customer,
  getPrequalData: (addressId: string) => Promise<AddressPrequalification | undefined>
) {
  let prequalData = await getPrequalData(getTuiAddressCode(customer));
  return (prequalData?.isIntactONTJourney() && prequalData?.getLFC() !== FibreCompanies.Chorus) ?? false;
}

export async function getBroadbandFunnelType(
  customer: T_Customer,
  getPrequalData: (addressId: string) => Promise<AddressPrequalification | undefined>
) {
  let prequalData = await getPrequalData(getTuiAddressCode(customer));
  return prequalData?.getBroadbandFunnelType();
}

export const getTermsAndConditions = (privacyList: Privacy[], sku: string, categoryId: number) => {
  const effectiveCodes = getEffectivePrivacyList(privacyList).map((pc: Privacy) => pc.privacyCode);
  const terms = hardcodedTermsAndConditions.filter(htcs => htcs.category === categoryId || htcs.sku === sku);

  if (terms && terms.length === 1) {
    return terms[0];
  }

  //find the correct t&cs to show - if one exists that uses a prviacy code use that else fallback to one that isn't
  let defaultResult;
  for (const item of terms) {
    if (!item.appliesTo) {
      defaultResult = item;
    }
    const termForCode = item.appliesTo?.filter((val: any) => effectiveCodes.includes(val)) ?? [];
    if (termForCode.length > 0) {
      return item;
    }
  }

  return defaultResult;
};

export const toggleDelivery = (
  isOn: boolean,
  toggle: Field<boolean, string>,
  deliveryAddressField: Field<NullableAddressLookup, string>
) => {
  toggle.props.onChange(isOn);

  // Reset Address Field if Toggle is off (aka normal customer address used)
  if (!isOn) {
    deliveryAddressField.value = {
      id: '',
      label: '',
    };
    deliveryAddressField.reset();
  } else {
    deliveryAddressField.validate();
    toggle.value = true;
  }
};
