import { useEffect, useState } from 'react';
import {
  useOrderStore,
  categoryIds,
  CustomerInstallationDetails,
  useAccountDetailsStore,
  useNewAcquisitionOrderStore,
} from '@sky-tv-group/shared';

/**
 * Hook for managing broadband and DTH acquisition checkout.
 *
 * Please don't use useCartContainer or useManagePackage hooks here.
 * Instead use the orderStore to check if products is in cart (bb, voice, tv, etc...).
 * If you need to check for customer billing info use myAccountSWR.usePackages directly.
 *
 * @returns
 */
function useCheckoutManager() {
  // const { isAuthenticated } = useAuth0();
  const { order, initializedOrderStore } = useOrderStore();
  const { accountDetails } = useAccountDetailsStore();
  const { selectedAddressLines, selectedTuiAddress, selectedInstallationAddress } = useNewAcquisitionOrderStore();
  const [customerDetails, setCustomerDetails] = useState<CustomerInstallationDetails>();

  const products = order?.orderProducts;
  const hasBroadband = products?.find(p => p.categoryId === categoryIds.broadband) !== undefined;
  // const hasVoice = products?.find(p => p.categoryId === categoryIds.voice) !== undefined;
  const hasPackage = products?.find(p => p.categoryId === categoryIds.package) !== undefined;
  // const showCheckout = !isAuthenticated && (!initializedOrderStore || hasBroadband || hasPackage);

  // const allowedRoutes = Object.keys(checkoutMachine.states).filter(state => {
  //   if (state === CheckoutStates.BROADBAND && !hasBroadband) return false;
  //   if (state === CheckoutStates.VOICE && !hasVoice) return false;
  //   if (state === CheckoutStates.TV && !hasPackage) return false;
  //   return true;
  // });

  useEffect(() => {
    if (selectedAddressLines && selectedTuiAddress && accountDetails && selectedInstallationAddress) {
      const addressLines = [
        selectedAddressLines.line1,
        selectedAddressLines.line2,
        selectedAddressLines.line3,
        selectedAddressLines.line4,
      ];

      setCustomerDetails({
        contactName: `${accountDetails?.yourDetail?.firstName} ${accountDetails?.yourDetail?.familyName}`,
        contactEmail: accountDetails?.yourDetail?.email,
        tuiAddressCode: selectedTuiAddress?.toString(),
        addressLines: addressLines,
        formattedAddress: selectedInstallationAddress ?? '',
        phoneAreaCode: accountDetails?.yourDetail?.phoneAreaCode,
        phoneTelephoneNumber: accountDetails?.yourDetail?.phoneTelephoneNumber,
      });
    }
  }, [selectedAddressLines, selectedTuiAddress, selectedInstallationAddress, accountDetails]);

  return {
    // allowedRoutes,
    // showCheckout,
    initializedOrderStore,
    customerDetails,
    hasBroadband,
    hasPackage,
  };
}

export { useCheckoutManager };
