import { AxiosInstance } from 'axios';
import { ApolloClient, NormalizedCacheObject, ApolloQueryResult, ApolloError } from '@apollo/client';
import {
  GetMoreChoicesForYouDocument,
  GetMoreChoicesForYouQuery,
  GetUserInformationDocument,
  GetUserInformationQuery,
  GetProductCardsQuery,
  GetProductCardsDocument,
  ProductCardFragment,
  GetEligibilityOffersQuery,
  GetEligibilityOffersDocument,
  GetMoreChoicesForYouQueryVariables,
  GetOrderConfirmationQuery,
  GetOrderConfirmationDocument,
  GetOrderConfirmationQueryVariables,
  GetTermsConditionsQueryVariables,
  GetTermsConditionsQuery,
  GetMenuSectionListQueryVariables,
  GetMenuSectionListQuery,
  GetMenuSectionListDocument,
  GetTermsConditionsDocument,
  GetCardContainerQueryVariables,
  GetCardContainerQuery,
  GetCardContainerDocument,
  GetComposePageQueryVariables,
  GetComposePageQuery,
  GetComposePageDocument,
  GetMenuListItemDocument,
  GetMenuListItemQueryVariables,
  GetMenuListItemQuery,
  ComponentCardSectionQueryVariables,
  ComponentCardSectionQuery,
  ComponentCardSectionDocument,
} from '@sky-tv-group/graphql';

export class MyAccountV2Service {
  constructor(private agent: AxiosInstance, private gqlAgent: ApolloClient<NormalizedCacheObject>) {
    this.agent = agent;
    this.gqlAgent = gqlAgent;
  }

  public getMoreChoicesForYou = async (
    variables: GetMoreChoicesForYouQueryVariables
  ): Promise<{
    data: GetMoreChoicesForYouQuery;
    error?: ApolloError;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetMoreChoicesForYouQuery> = await this.gqlAgent.query({
      query: GetMoreChoicesForYouDocument,
      variables: variables,
    });

    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };

  public getOrderConfirmation = async (
    variables: GetOrderConfirmationQueryVariables
  ): Promise<{
    data: GetOrderConfirmationQuery;
    error?: ApolloError;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetOrderConfirmationQuery> = await this.gqlAgent.query({
      query: GetOrderConfirmationDocument,
      variables: variables,
    });

    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };

  public getUserInformation = async (): Promise<{
    data: GetUserInformationQuery;
    error: ApolloError | undefined;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetUserInformationQuery> = await this.gqlAgent.query({
      query: GetUserInformationDocument,
    });

    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };

  public getProductCards = async (): Promise<{
    data: ProductCardFragment[];
    error: ApolloError | undefined;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetProductCardsQuery> = await this.gqlAgent.query({
      query: GetProductCardsDocument,
    });

    return {
      data: res.data.productsCards as ProductCardFragment[],
      error: res.error,
      loading: res.loading,
    };
  };

  public getEligibilityOffers = async (): Promise<{
    data: GetEligibilityOffersQuery;
    error: ApolloError | undefined;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetEligibilityOffersQuery> = await this.gqlAgent.query({
      query: GetEligibilityOffersDocument,
    });

    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };

  public getCardContainer = async (
    variables: GetCardContainerQueryVariables
  ): Promise<{
    data: GetCardContainerQuery;
    error?: ApolloError;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetCardContainerQuery> = await this.gqlAgent.query({
      query: GetCardContainerDocument,
      variables: variables,
    });

    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };

  public getTermsAndContionMenus = async (
    variables: GetMenuSectionListQueryVariables
  ): Promise<{
    data: GetMenuSectionListQuery;
    error?: ApolloError;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetMenuSectionListQuery> = await this.gqlAgent.query({
      query: GetMenuSectionListDocument,
      variables: variables,
    });

    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };

  public getTermsAndContionContent = async (
    variables: GetTermsConditionsQueryVariables
  ): Promise<{
    data: GetTermsConditionsQuery;
    error?: ApolloError;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetTermsConditionsQuery> = await this.gqlAgent.query({
      query: GetTermsConditionsDocument,
      variables: variables,
    });
    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };

  public getComposePage = async (
    variables: GetComposePageQueryVariables
  ): Promise<{
    data: GetComposePageQuery;
    error?: ApolloError;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetComposePageQuery> = await this.gqlAgent.query({
      query: GetComposePageDocument,
      variables: variables,
    });
    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };
  public getMenuListItem = async (
    variables: GetMenuListItemQueryVariables
  ): Promise<{
    data: GetMenuListItemQuery;
    error?: ApolloError;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<GetMenuListItemQuery> = await this.gqlAgent.query({
      query: GetMenuListItemDocument,
      variables: variables,
    });
    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };
  public getComponentCardSection = async (
    variables: ComponentCardSectionQueryVariables
  ): Promise<{
    data: ComponentCardSectionQuery;
    error?: ApolloError;
    loading: boolean;
  }> => {
    const res: ApolloQueryResult<ComponentCardSectionQuery> = await this.gqlAgent.query({
      query: ComponentCardSectionDocument,
      variables: variables,
    });
    return {
      data: res.data,
      error: res.error,
      loading: res.loading,
    };
  };
}
