import cx from 'classnames';
import React from 'react';
import { Field } from 'react-jeff';

import { DigiInput, caret } from '@sky-tv-group/components';

interface FormProps {
  onFormSubmit: Function;
}
export function Form({ onFormSubmit, ...props }: FormProps & React.HTMLProps<HTMLFormElement>) {
  return (
    <form
      {...props}
      onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();
        onFormSubmit();
      }}
    />
  );
}

interface InputProps {
  label?: string;
  id: string;
  field: Field<string>;
}
export function Input({ label, id, field, ...props }: InputProps & React.HTMLProps<HTMLInputElement>) {
  let { onChange, ...rest } = field.props;
  // only show first one;
  let error = field.dirty ? field.errors[0] ?? '' : '';
  return (
    <div className="relative">
      {label && (
        <label
          className={cx('block sky-label md:sky-label mb-2', {
            'text-error': error,
          })}
          htmlFor={`input-${id}`}>
          {label}
        </label>
      )}
      <input
        id={`input-${id}`}
        className={cx('block w-full sky-input sky-input--text', {
          'sky-input--error': error,
        })}
        {...rest}
        {...props}
        onChange={event => {
          onChange(event.currentTarget.value);
        }}
      />
      <div className="absolute top-100 left-0 text-error whitespace-no-wrap">{error}</div>
    </div>
  );
}

interface SelectProps {
  label: string;
  id: string;
  field: Field<string>;
  options: { value: string; label: string }[];
}
export function Select({ label, id, field, options, ...props }: SelectProps & React.HTMLProps<HTMLSelectElement>) {
  let { onChange, ...rest } = field.props;
  // only show first one;
  let error = field.dirty ? field.errors[0] ?? '' : '';
  return (
    <div className="relative">
      <label
        className={cx('block sky-label md:sky-label mb-2', {
          'text-error': error,
        })}
        htmlFor={`input-${id}`}>
        {label}
      </label>
      <div className="relative">
        <select
          id={`input-${id}`}
          className={cx('appearance-none  block w-full sky-input sky-input--text', {
            'sky-input--error': error,
          })}
          {...rest}
          {...props}
          onChange={event => {
            onChange(event.currentTarget.value);
          }}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="w-10 h-12 py-2 absolute text-gray-darker top-0 right-0 pointer-events-none">{caret}</div>
      </div>
      <div className="absolute top-100 left-0 text-error whitespace-no-wrap">{error}</div>
    </div>
  );
}

interface DigiInputWrapperProps {
  label: string;
  labels?: string[];
  field: Field<string[]>;
}
export function DigiInputWrapper({
  label,
  labels,
  field,
  ...props
}: DigiInputWrapperProps & Omit<React.ComponentProps<typeof DigiInput>, 'value' | 'onChange'>) {
  let { onChange, value, ...rest } = field.props;
  let error = !field.focused ? field.errors[0] ?? '' : '';
  return (
    <div className={cx('relative', { 'text-error': error })}>
      {!labels && <label className="block mx-2 font-bold">{label}</label>}
      <DigiInput value={value} labels={labels} onChange={onChange} {...rest} {...props} error={!!error} />
      <div className="absolute mx-2 top-100 left-0 text-error whitespace-no-wrap">{error}</div>
    </div>
  );
}
