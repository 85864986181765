import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-jeff';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { calendar } from '../icon';

interface IDateValidation {
  fieldValidation: Field<Date | null, string>;
  formValidation: Form<string>;
  id: string;
  placeholderText: string;
  className: string;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  selected?: Date;
  excludeDates?: Date[];
  includeDates?: Date[];
  showYearDropdown?: boolean;
  showMonthDropDown?: boolean;
  disabled?: boolean;
}

const ValidationDatePicker: React.FunctionComponent<IDateValidation> = ({
  fieldValidation,
  formValidation,
  placeholderText,
  className,
  dateFormat,
  id,
  minDate,
  maxDate,
  selected,
  excludeDates,
  includeDates,
  showYearDropdown,
  showMonthDropDown,
  disabled,
}: IDateValidation) => {
  const [date, setDate] = useState<Date | null>(selected ? selected : null);

  const handleChange = (date: Date) => {
    setDate(date);
    fieldValidation.props.onChange(date);
  };

  // prevents manual enter of invalid date formats: defaults to empty instead of max/min values
  const handleChangeRaw = (value: string) => {
    // https://jira.skytv.co.nz/browse/WEBDXP-2074
    // this if check fixes the issue that if user manually inputs the date, and picks the same date on popup, the input will be cleared
    if (value) {
      fieldValidation.props.onChange(null);
    }
  };

  useEffect(() => {
    //set default date to selected value if a date hasn't been set already
    if (selected && !date) {
      fieldValidation.props.onChange(date);
    }
  }, []);

  useEffect(() => {
    setDate(fieldValidation.value);
  }, [fieldValidation.value]);

  const errorPresent = fieldValidation.errors.length > 0;
  const inputClassName = cx(className, { 'sky-input--error': errorPresent }, 'sky-input sky-input--text sky-h7-reg');
  const errorClassName = cx(className, { 'text-error': errorPresent });

  return (
    <>
      <div className="relative">
        <DatePicker
          onChange={handleChange}
          className={inputClassName}
          onChangeRaw={event => handleChangeRaw(event.target.value)}
          dateFormat={dateFormat ?? 'dd/MM/yyyy'}
          selected={date}
          id={id}
          placeholderText={placeholderText}
          autoComplete="off"
          minDate={minDate}
          maxDate={maxDate}
          required={fieldValidation.required}
          excludeDates={excludeDates}
          includeDates={includeDates}
          showMonthDropdown={showMonthDropDown}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          {...(disabled ? { disabled } : {})}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
          <div className="w-8">{calendar}</div>
        </div>
      </div>
      {errorPresent && <p className={errorClassName}>{fieldValidation.errors[0]}</p>}
    </>
  );
};

export { ValidationDatePicker };
