import React from 'react';

interface Props {
  address: string;
}

const BroadbandAddress = ({ address }: Props) => {
  return (
    <div className="pt-4 pb-4 w-full">
      <label className="block mb-2 sky-h4-bold">We have your address as</label>
      <div className="sky-h4-bold">{address}</div>
    </div>
  );
};

export { BroadbandAddress };
