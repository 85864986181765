import { MostPopularPlan } from './screens/MostPopularPlan';
import { BuildMyPlan } from './screens/BuildMyPlan';
import { HelpMeChoose } from './screens/HelpMeChoose';
import { SkyAppsPathName } from './types';
import { MyAccountAppScreen } from './screens/SkyApps/MyAccountApp';
import { SkyGoScreen } from './screens/SkyApps/SkyGo';
import { SkytvGuideScreen } from './screens/SkyApps/SkyTvGuide';

export let routes = [
  {
    path: '/build-my-plan',
    title: 'Build my plan',
    component: BuildMyPlan,
    exact: false,
  },

  {
    path: '/most-popular-plan',
    title: 'Most popular plan',
    component: MostPopularPlan,
    exact: false,
  },
  {
    path: '/help-me-choose',
    title: 'Help me choose',
    component: HelpMeChoose,
    exact: true,
  },
];

export const skyAppsRoutes = [
  {
    path: SkyAppsPathName.MY_ACCOUNT_APP,
    title: 'My Account App',
    component: MyAccountAppScreen,
    exact: true,
  },
  {
    path: SkyAppsPathName.SKY_GO,
    title: 'Sky Go',
    component: SkyGoScreen,
    exact: true,
  },
  {
    path: SkyAppsPathName.SKY_TV_GUIDE,
    title: 'Sky TV Guide',
    component: SkytvGuideScreen,
    exact: true,
  },
];
