import React, { ReactNode } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { SkyAppMenu } from './SkyAppMenu';
import { SkyAppsPathName } from '../../types';
import { myAccountV2SWR } from '../../services';
import { BlockLoader } from '@sky-tv-group/components';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

const renderOptions = {
  preserveWhiteSpace: true,
  renderNode: {
    [BLOCKS.HEADING_2]: (node: any, children: ReactNode) => (
      <h1 className="container relative bottom-4 lg:left-4 lg:bottom-0 text-white sky-h1-black sm:sky-h1-black md:sky-h1-black">
        {children}
      </h1>
    ),
    [BLOCKS.UL_LIST]: (node: any, children: ReactNode) => <ul className="list-disc  text-xl text-navy">{children}</ul>,
  },
};

export const SkyGoScreen = () => {
  const slug = SkyAppsPathName.SKY_GO?.replace('/', '');
  const { data: skyGoContent, isValidating } = myAccountV2SWR.useGetComposePageGQL(slug);
  useDocumentTitle(skyGoContent?.data?.composePage?.[0]?.title as string);
  const [skyGoWebContentBanner, skyGoComponent, skyGoTvComponent, skyGoLinkSection, downloadAppSection] =
    skyGoContent?.data?.composePage?.[0]?.content?.content ?? [];
  return (
    <>
      <SkyAppMenu selectedLink={SkyAppsPathName.SKY_GO} />
      {isValidating ? (
        <div className="h-128">
          <BlockLoader />
        </div>
      ) : (
        <>
          {skyGoWebContentBanner?.bannerContent && (
            <>
              <div
                className="hidden lg:flex relative w-100 h-100 items-center justify-start bg-center bg-cover md:text-center lg:text-left px-6"
                style={{
                  backgroundImage: `url('${skyGoWebContentBanner?.bannerContent?.heroImage?.url}')`,
                  backgroundSize: 'cover',
                  height: '512px',
                }}>
                {documentToReactComponents(skyGoWebContentBanner?.bannerContent?.heading, renderOptions)}
              </div>
              <div
                className="flex lg:hidden relative w-100 h-100 items-end justify-start bg-center bg-cover md:text-center lg:text-left px-6"
                style={{
                  backgroundImage: `url('${skyGoWebContentBanner?.bannerContent?.heroImageMobile?.url}')`,
                  backgroundSize: 'cover',
                  height: '512px',
                }}>
                {documentToReactComponents(skyGoWebContentBanner?.bannerContent?.heading, renderOptions)}
              </div>
            </>
          )}
          <div className="container flex flex-col justify-center items-center gap-6 mb-20 mx-auto">
            {skyGoComponent?.componentCards?.[0] && (
              <div className="flex flex-col justify-center items-center py-4">
                <img src={skyGoComponent?.componentCards?.[0]?.cardImageUrl?.url!} className="w-1/3" />
                <h2 className="sky-h3-bold md:sky-h2-bold text-navy">
                  {skyGoComponent?.componentCards?.[0]?.cardTitle}
                </h2>
                <div className="container px-12 py-3">
                  {documentToReactComponents(
                    skyGoComponent?.componentCards?.[0]?.cardDetailedDescription,
                    renderOptions
                  )}
                </div>
              </div>
            )}
            {skyGoTvComponent?.componentCards && (
              <div className="flex flex-col gap-6 md:flex-row justify-center items-center md:items-start md:gap-4">
                {skyGoTvComponent?.componentCards?.map(({ cardTitle, cardDescription, cardImageUrl }) => (
                  <div className="flex w-11/12 md:w-1/3 flex-col gap-2">
                    <img src={cardImageUrl?.url!} />
                    <div className="sky-h5-bold md:sky-h4-bold">{cardTitle}</div>
                    <div className="text-lg">{cardDescription}</div>
                  </div>
                ))}
              </div>
            )}
            {skyGoLinkSection?.componentCards && (
              <div className="flex flex-col md:flex-row justify-center items-center p-4 gap-4 md:gap:8">
                {skyGoLinkSection?.componentCards?.map(({ linkText, linkUrl }) => (
                  <a href={linkUrl!} className="sky-button sky-button--secondary-light btn btn-lg min-w-40">
                    {linkText}
                  </a>
                ))}
              </div>
            )}
            {downloadAppSection?.componentCards && downloadAppSection?.componentCardSectionTitle && (
              <div className="flex flex-col justify-center items-center p-4">
                <h2
                  className="text-2xl font-bold md:sky-h4 mb-6 text-center"
                  dangerouslySetInnerHTML={{ __html: downloadAppSection?.componentCardSectionTitle }}
                />
                <div className="flex flex-col md:flex-row justify-center items-center gap-4">
                  {downloadAppSection?.componentCards?.map(({ cardImageUrl, cardImageLinkUrl }) => (
                    <a target="_blank" href={cardImageLinkUrl!}>
                      <img className="w-80 md:w-40 h-auto" src={cardImageUrl?.url!} />
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
