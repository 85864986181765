import React from 'react';
import { Field } from 'react-jeff';
import cx from 'classnames';
import { Loader } from '../../../index';
import { formatPhoneNumber } from '@sky-tv-group/shared';
import { useEffect } from 'react';

interface HomePhoneSelectorProps {
  labelText: string;
  reservationText: string;
  numbers: string[];
  field: Field<string, string>;
  loading: boolean;
}
const HomePhoneSelector: React.FunctionComponent<HomePhoneSelectorProps> = ({
  labelText,
  reservationText,
  numbers,
  field,
  loading,
}) => {
  const errorPresent = field.errors.length > 0;
  const errorClassName = cx({ 'text-error': errorPresent });

  useEffect(() => {
    field.validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="w-full py-4">
        <label className="block sky-h7-bold mb-4">{labelText}</label>
        <div className="block sky-h7-reg sky-text-gray-darker mb-2">{reservationText}</div>
        {errorPresent && <p className={errorClassName}>{field.errors[0]}</p>}
        <div className="py-8 sky-bg-gray-lightest">
          {loading ? (
            <div className="relative h-32">
              <Loader bgColor="sky-bg-transparent" bgOpacity={false} />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 sm:gap-3 lg:grid-cols-4 lg:gap-2">
              {numbers.map(num => {
                const className = cx(
                  {
                    'text-orange border-orange mx-2 sm:mx-4 md:mx-8 sky-bg-white': num === field.props.value,
                    'border-transparent': num !== field.props.value,
                  },
                  'cursor-pointer border border-solid rounded sky-h6-bold text-center p-4 text-navy'
                );
                return (
                  <h5
                    className={className}
                    key={num}
                    onClick={() => {
                      field.props.onChange(num);
                    }}>
                    {formatPhoneNumber(num, false)}
                  </h5>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { HomePhoneSelector };
