import { freeProductStoreApi } from '../../store/freeProductStore';
import { T_Coupon, T_Product } from '../../types';
import { buildRemovalText } from './removalTextBuilder';

function useRemoveTextFormatter() {
  /**
   * Based on case, generates text what should be shown to the user when removing X item.
   * Incorporates free products / offers.
   * @param isOfferAssociated - is an offer present or not (offer must be related to the product = product name)
   * @param productName - name of product that is toggled or being attempted to be removed
   * @param couponProducts - for removing coupon with multiple products
   * @param couponObject - only for weird case where free product is in promotion
   * @param isFreeRelated - is the removal associated with the free product
   */
  const removalText = (
    isOfferAssociated: boolean,
    productName?: string,
    couponProducts?: Pick<T_Product, 'name' | 'priceIncTax' | 'id'>[],
    couponObject?: Pick<T_Coupon, 'custom1'>,
    isFreeRelated?: boolean
  ): string => {
    const { freeProduct, initiallyAdded } = freeProductStoreApi.getState();
    return buildRemovalText(
      isOfferAssociated,
      initiallyAdded,
      productName,
      couponProducts,
      couponObject,
      isFreeRelated,
      freeProduct?.product
    );
  };

  return {
    removalText,
  };
}
export { useRemoveTextFormatter };
