import React from 'react';
import Tippy from '@tippyjs/react';
import { Field, Form } from 'react-jeff';
import { PHONE_AREA_CODES } from '@sky-tv-group/shared';
import { ValidatedPhoneNumberDropdown } from '../../validation/phonenumberDropdownValidated';
import { blueIcon } from '../../icon';

interface Props {
  message: string;
  form: Form<string>;
  showTooltip: boolean;
  mobileNumberAreaCodeField: Field<string, string>;
  mobileNumberLineNumberField: Field<string, string>;
}

const PhoneNumber = ({ message, form, showTooltip, mobileNumberAreaCodeField, mobileNumberLineNumberField }: Props) => {
  return (
    <div className="pb-4 w-full">
      <div className="flex">
        <label className="block sky-label md:sky-sub mb-2 text-midnight">{message}</label>
        {showTooltip && (
          <Tippy content="To sort out your installation easily and ensure you can be reached if your home phone is disconnected, it’s important you give us your mobile number here.">
            <div className="p-2 pt-0">{blueIcon}</div>
          </Tippy>
        )}
      </div>
      <ValidatedPhoneNumberDropdown
        areaCodeField={mobileNumberAreaCodeField}
        areaCodeFieldContainerClasses={'w-3/12 xl:w-1/12'}
        areaCodeOptions={PHONE_AREA_CODES}
        lineNumberField={mobileNumberLineNumberField}
        lineNumberFieldPlaceholderText={'0000000'}
        lineNumberFieldContainerClasses={'w-9/12 xl:w-5/12'}
        id="account-details-your-details"
        form={form}
      />
    </div>
  );
};

export { PhoneNumber };
