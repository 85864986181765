import React, { ReactNode } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { SkyAppMenu } from './SkyAppMenu';
import { SkyAppsPathName } from '../../types';
import { myAccountV2SWR } from '../../services';
import { BlockLoader } from '@sky-tv-group/components';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

const renderOptions = {
  preserveWhiteSpace: true,
  renderText: (text: string) =>
    text
      .split('\n')
      .reduce(
        (children: any, textSegment: any, index: number) => [...children, index > 0 && <br key={index} />, textSegment],
        []
      ),
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
    [MARKS.ITALIC]: (text: ReactNode) => <i>{text}</i>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => <p className="text-xl text-black">{children}</p>,
    [BLOCKS.UL_LIST]: (node: any, children: ReactNode) => <ul className="list-disc text-xl text-black">{children}</ul>,
    [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
      <a href={node?.data?.uri} className="sky-text-daylight">
        {children}
      </a>
    ),
  },
};

export const SkytvGuideScreen = () => {
  const slug = SkyAppsPathName.SKY_TV_GUIDE?.replace('/', '');
  const { data: skyTvGuideContent, isValidating } = myAccountV2SWR.useGetComposePageGQL(slug);
  useDocumentTitle(skyTvGuideContent?.data?.composePage?.[0]?.title as string);
  const [
    skyTvGuideWebContentBanner,
    skyTvGuideDescription,
    downloadAppSection,
    skyTvGuideBenifitsSection,
    skyTvGuideTermsAndConditions,
  ] = skyTvGuideContent?.data?.composePage?.[0]?.content?.content ?? [];
  return (
    <>
      <SkyAppMenu selectedLink={SkyAppsPathName.SKY_TV_GUIDE} />
      {isValidating ? (
        <div className="h-128">
          <BlockLoader />
        </div>
      ) : (
        <>
          {skyTvGuideWebContentBanner?.bannerContent && (
            <div
              className="flex relative w-100 h-100 items-center justify-start bg-center bg-cover md:text-center lg:text-left px-6"
              style={{
                backgroundImage: `url('${skyTvGuideWebContentBanner?.bannerContent?.heroImage?.url}')`,
                backgroundSize: 'cover',
                height: '500px',
              }}
            />
          )}
          <div className="container flex flex-col justify-center items-center gap-12 my-10 mx-auto">
            {skyTvGuideDescription?.componentCards?.[0] && (
              <div className="px-6 text-center">
                {documentToReactComponents(
                  skyTvGuideDescription?.componentCards?.[0]?.cardDetailedDescription,
                  renderOptions
                )}
              </div>
            )}
            {downloadAppSection?.componentCards && (
              <div className="flex flex-col md:flex-row justify-center items-center gap-4">
                {downloadAppSection?.componentCards?.map(({ cardImageUrl, cardImageLinkUrl }) => (
                  <a target="_blank" href={cardImageLinkUrl!}>
                    <img className="w-80 md:w-40 h-auto" src={cardImageUrl?.url!} />
                  </a>
                ))}
              </div>
            )}
            {skyTvGuideBenifitsSection?.componentCards && skyTvGuideBenifitsSection?.componentCardSectionTitle && (
              <div className="px-4 flex flex-col gap-12 items-center justify-start">
                <h2 className="text-center sky-h2 md:sky-h2-markprobold sky-text-black">
                  {skyTvGuideBenifitsSection?.componentCardSectionTitle}
                </h2>
                <div className="flex flex-col gap-6 justify-center items-center md:flex-row md:justify-between md:items-start">
                  <div className="w-11/12 md:w-1/2">
                    {documentToReactComponents(
                      skyTvGuideBenifitsSection?.componentCards?.[0]?.cardDetailedDescription,
                      renderOptions
                    )}
                  </div>
                  <div className="w-11/12 md:w-1/2">
                    <img
                      className="w-full h-auto"
                      src={skyTvGuideBenifitsSection?.componentCards?.[0]?.cardImageUrl?.url!}
                    />
                  </div>
                </div>
              </div>
            )}
            {skyTvGuideTermsAndConditions?.componentCards?.[0] && (
              <div className="px-6 md:px-0">
                {documentToReactComponents(
                  skyTvGuideTermsAndConditions?.componentCards?.[0]?.cardDetailedDescription,
                  renderOptions
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
