import React, { useState } from 'react';
import { ImgPlaceholder } from './ImagePlaceholder';

interface Props
  extends Omit<
    React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    'src' | 'alt' | 'onError'
  > {
  src: string;
  alt: string;
  placeHolderDefaultSize?: number;
}
const Image: React.FunctionComponent<Props> = ({ src, alt, placeHolderDefaultSize, ...props }) => {
  const [error, setError] = useState(false);

  if (error || !src) {
    return (
      <div className={props.className} style={props.style}>
        <ImgPlaceholder height={placeHolderDefaultSize} />
      </div>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      onError={() => {
        setError(true);
      }}
      {...props}
    />
  );
};

export { Image };
