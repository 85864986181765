import { AxiosInstance } from 'axios';

import { T_CURATED_BUNDLE, T_CURATED_PRODUCT, T_ROI_PAGE } from '../types';

export class LiferayConnectService {
  private agent: AxiosInstance;
  constructor(agent: AxiosInstance) {
    this.agent = agent;
  }
  public getProducts = async () => {
    try {
      const { data } = await this.agent.get<T_CURATED_PRODUCT[]>(`/products/all`);
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  public getBundles = async () => {
    try {
      const { data } = await this.agent.get<T_CURATED_BUNDLE[]>(`/bundles/all`);

      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  public getRoiPage = async () => {
    try {
      const { data } = await this.agent.get<T_ROI_PAGE[]>(`/roi/roipage`);
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}
