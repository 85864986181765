import { Button } from '@sky-tv-group/components';
import React from 'react';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { TVBroadband } from './TVBroadband';

export let routes = [
  {
    path: '/tv-broadband',
    title: 'TV & Broadband',
    component: TVBroadband,
    exact: true,
  },
  {
    path: '/broadband',
    title: 'Broadband',
    component: () => <div>place holder2</div>,
    exact: true,
  },
  {
    path: '/tv-only',
    title: 'TV only',
    component: () => <div>place holder3</div>,
    exact: true,
  },
];

export let MostPopularPlan = () => {
  let { path: parentPath } = useRouteMatch();

  return (
    <div className="container  m-auto">
      <h2 className="md:sky-h2-bold text-blue-dark text-center p-8">Most popular</h2>
      <div className="flex justify-between">
        <div className="border-b flex justify-start my-2">
          {routes.map(({ exact, path, title }) => (
            <NavLink
              style={{ minWidth: 150 }}
              className={'md:sky-h7 py-2 mr-12 text-center '}
              exact={exact}
              key={path}
              to={parentPath + path}
              activeClassName={'md:sky-h7-black text-blue-pure border-b border-blue-pure'}>
              {title}
            </NavLink>
          ))}
        </div>

        <Button>placeholder</Button>
      </div>

      <Switch>
        {routes.map(({ path, exact, component }) => (
          <Route key={path} exact={exact} path={parentPath + path} component={component} />
        ))}
        <Redirect to={parentPath + routes[0].path} from={parentPath} />
      </Switch>
    </div>
  );
};
