import { T_Coupon, T_Product } from '../../../types';
import {
  removeProductRemovesOfferAndFreeProductAndChargesItAtDefault,
  removeProductRemovesOfferAndFreeProduct,
  removeOfferRemovesMultipleProductsAndFreeProductAndChargesItAtDefault,
  removeOfferRemovesMultipleProductsAndFreeProduct,
  removeOfferRemovesMultipleProducts,
} from '../helper/text';

const bothFreeProductOfferRemoval = (
  isInitiallyAdded: boolean,
  productName?: string,
  couponProducts?: Pick<T_Product, 'name' | 'priceIncTax' | 'id'>[],
  freeProduct?: Pick<T_Product, 'id' | 'name' | 'priceIncTax'>
) => {
  const isSingleItemOffer = productName && !couponProducts;
  const isMultipleItemOffer = couponProducts;

  // Removing a specific product that will trigger removal of offer and free product
  if (isSingleItemOffer) {
    return singleItemOfferAndFreeProductRemoval(productName!, freeProduct!, isInitiallyAdded);
  }

  // Removing of the offer with multiple products - that may impact free product
  if (isMultipleItemOffer) {
    // if there is a product name here, it means that the product being removed is the free product
    const freeProductDeleted = productName ? true : false;
    return multipleItemOfferAndFreeProductRemoval(freeProduct!, couponProducts!, freeProductDeleted, isInitiallyAdded);
  }

  return '';
};
/**
 * Removal of a single product that removes offer and free product
 * @param productName
 * @param freeProductObject
 * @param initiallyAdded
 */
const singleItemOfferAndFreeProductRemoval = (
  productName: string,
  freeProductObject: Pick<T_Product, 'name' | 'priceIncTax'>,
  initiallyAdded?: boolean
) => {
  if (initiallyAdded) {
    // don't remove the free product
    return removeProductRemovesOfferAndFreeProductAndChargesItAtDefault(
      productName,
      freeProductObject.name,
      freeProductObject.priceIncTax
    );
  } else {
    // remove free product from cart
    return removeProductRemovesOfferAndFreeProduct(productName, freeProductObject.name);
  }
};

/**
 * Removes offer with multiple products and handles free soho if present
 * @param freeProductObject
 * @param couponProducts
 * @param freeProductDeleted
 * @param initiallyAdded
 */
const multipleItemOfferAndFreeProductRemoval = (
  freeProductObject: Pick<T_Product, 'id' | 'name' | 'priceIncTax'>,
  couponProducts: Pick<T_Product, 'name' | 'id'>[],
  freeProductDeleted: boolean,
  initiallyAdded?: boolean
) => {
  // filter out the free product from coupon products
  let filteredCouponProducts = couponProducts.filter(p => p.id !== freeProductObject.id);

  if (freeProductDeleted) {
    // means it will be removing free product
    if (initiallyAdded) {
      // free product is part of offer - removal of offer should remove others but keep the free product
      // don't remove the free product if any chance it was part of the text
      return removeOfferRemovesMultipleProductsAndFreeProductAndChargesItAtDefault(
        filteredCouponProducts,
        freeProductObject.name,
        freeProductObject.priceIncTax
      );
    } else {
      // free product is part of offer - removal of offer should remove others but keep the free product
      // don't remove the free product if any chance it was part of the text
      return removeOfferRemovesMultipleProductsAndFreeProduct(filteredCouponProducts, freeProductObject.name);
    }
  } else {
    // free product is part of offer - removal of offer should remove others but keep the free product
    // don't remove the free product if any chance it was part of the text
    return removeOfferRemovesMultipleProducts(filteredCouponProducts);
  }
};

export { bothFreeProductOfferRemoval };
