import React from 'react';

export function ChevronUp() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chevron-up"
      className="svg-inline--fa fa-chevron-up fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512">
      <path
        fill="currentColor"
        d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path>
    </svg>
  );
}

export function ChevronDown() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chevron-down"
      className="svg-inline--fa fa-chevron-down fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512">
      <path
        fill="currentColor"
        d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
    </svg>
  );
}

export function CheckCircle() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="check-circle"
      className="svg-inline--fa fa-check-circle fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
    </svg>
  );
}

export function TimesCircle() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="times-circle"
      className="svg-inline--fa fa-times-circle fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
    </svg>
  );
}

export function Bin() {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66667 12.3054L2.66667 4H3.33333H8.66667H9.33333V12.3186C9.32909 12.4143 9.32542 12.4822 9.32162 12.5326C9.30996 12.5398 9.28596 12.5531 9.24588 12.567C9.18041 12.5897 9.09486 12.6058 9 12.6058H2.69757C2.68741 12.5512 2.68033 12.468 2.66667 12.3054ZM10.6667 4V12.3479L10.666 12.3625C10.6603 12.4936 10.6544 12.6071 10.6453 12.7013C10.6365 12.7924 10.6217 12.9031 10.5843 13.015C10.5036 13.2565 10.3344 13.413 10.2833 13.4602L10.2775 13.4656L10.2714 13.4714C9.94761 13.7952 9.44961 13.9392 9 13.9392H2.66667C2.52981 13.9392 2.34193 13.9078 2.1618 13.8382C1.98981 13.7718 1.69884 13.6217 1.53706 13.2981C1.37938 12.9828 1.35688 12.6754 1.34134 12.4631C1.33943 12.437 1.33762 12.4123 1.33568 12.3892L1.33333 12.3613L1.33334 4H0.666667C0.298477 4 0 3.70152 0 3.33333C0 2.96514 0.298477 2.66667 0.666667 2.66667H1.33334H3.33333L3.33333 0.866731L3.34137 0.815447L3.34434 0.795632C3.34558 0.787212 3.34754 0.77403 3.34992 0.75978C3.35464 0.731426 3.36303 0.685877 3.37874 0.633391C3.41495 0.512395 3.47816 0.393441 3.57893 0.285292C3.68748 0.1688 3.80977 0.112089 3.87328 0.086344C3.94622 0.0567782 4.01486 0.0394741 4.06571 0.0289379C4.1639 0.00859122 4.26255 0 4.33333 0H7.5C7.71264 0 7.99348 0.0333553 8.21522 0.15234L8.24163 0.166344C8.25283 0.172342 8.2771 0.185406 8.30429 0.202594C8.36615 0.241693 8.46372 0.314444 8.54194 0.440098C8.6129 0.554083 8.63684 0.662607 8.647 0.719035C8.65699 0.774539 8.66167 0.830183 8.66446 0.864463L8.66667 0.891508L8.66667 2.66667H10.6667H11.3333C11.7015 2.66667 12 2.96514 12 3.33333C12 3.70152 11.7015 4 11.3333 4H10.6667ZM7.33333 2.66667L7.33324 1.33333H4.66667L4.66667 2.66667H7.33333ZM4 6.66667C4 6.29848 4.29848 6 4.66667 6C5.03486 6 5.33333 6.29848 5.33333 6.66667V10C5.33333 10.3682 5.03486 10.6667 4.66667 10.6667C4.29848 10.6667 4 10.3682 4 10V6.66667ZM7.33333 6C6.96514 6 6.66667 6.29848 6.66667 6.66667V10C6.66667 10.3682 6.96514 10.6667 7.33333 10.6667C7.70152 10.6667 8 10.3682 8 10V6.66667C8 6.29848 7.70152 6 7.33333 6Z"
        fill="#0057FF"
      />
    </svg>
  );
}
