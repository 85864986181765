import React from 'react';
import { useModal } from '../ExploreModal/useModal';
import { AddBlue } from '../icon';
import { MultiroomModal, MultiroomModalProps } from './MultiroomModal';

interface AddMultiRoomButtonProps {
  title?: string;
  modalProps: Omit<MultiroomModalProps, 'header' | 'toggleModal'>;
}

export const AddMultiRoomButton = ({ modalProps, title = 'Add multiroom' }: AddMultiRoomButtonProps) => {
  const { showModal, toggleModal } = useModal();
  return (
    <>
      <div className="md:block">
        <div
          className="w-full h-full min-w-72 min-h-330px border-dashed border rounded flex justify-center items-center flex-col bg-gray-cool cursor-pointer"
          onClick={toggleModal}>
          <div className="w-20 h-20">
            <AddBlue />
          </div>
          <span className="sky-h5-black mt-3">{title}</span>
        </div>
      </div>

      {showModal && (
        <MultiroomModal
          header={modalProps.isPrimary ? 'Add Primary Device' : 'Add Multiroom'}
          toggleModal={toggleModal}
          {...modalProps}
        />
      )}
    </>
  );
};
