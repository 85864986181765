import { DeliveryAddress, Service } from '../../../types';
import { IUpgradableBoxesStore } from '@sky-tv-group/shared/src/store/upgradableBoxesStore';
import {
  additionalDecoderCodes,
  decoderHDCompatibilityCodes,
  productSkuIds,
  VTVMigrationType,
  VTVMigrationTypeCode,
} from '../../../config';
import _uniq from 'lodash.uniq';

/**
 *
 * @param addedCableItems
 */
export const createCableMultiroomOccurrences = (
  addedCableItems: string[],
  upgradableBoxDetails:
    | Pick<IUpgradableBoxesStore, 'boxInstallRequired' | 'installationDate' | 'installationTime'>
    | undefined,
  cableServices: Service[],
  fusionRegion?: string,
  vtvTransfer?: boolean,
  vtvTransferType?: VTVMigrationType,
  deliveryDetails?: DeliveryAddress
): Service[] => {
  // Get date time for multiroom as installation is required.
  if (upgradableBoxDetails) {
    upgradableBoxDetails.boxInstallRequired = true;
  }

  /** Add required decoder provisioning codes. */
  let svcCode2Add = [...addedCableItems, ...additionalDecoderCodes];

  if (fusionRegion) {
    svcCode2Add.push(fusionRegion.replace(/\s+/g, ''));
  }

  const isSkyPodAdded = svcCode2Add.includes(productSkuIds.skyPod.primary);

  if (isSkyPodAdded) {
    // For Dart UPGRADE, if Sky Pod was chosen remove recording and HD services.
    let nonCompatSvcCodes: string[] = [
      productSkuIds.arrowBoxRecording.primary,
      productSkuIds.noRecording.primary,
      ...decoderHDCompatibilityCodes,
    ];
    svcCode2Add = svcCode2Add.filter(svcCode => !nonCompatSvcCodes.includes(svcCode));
  }

  if (vtvTransfer) {
    // some magic numbers to identify if we are migrating a stand-alone or logged in vtv customer
    svcCode2Add.push(
      vtvTransferType === VTVMigrationType.TRANSFER ? VTVMigrationTypeCode.TRANSFER : VTVMigrationTypeCode.STANDALONE
    );
  }

  cableServices.push({
    actions: [
      {
        add: _uniq(svcCode2Add).sort(),
      },
    ],
    details: [],
    ...(deliveryDetails && { deliveryDetails: deliveryDetails }),
  });

  return cableServices;
};
