import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ELIGIBILITY_ACQUISITION_TYPE,
  ELIGIBILITY_FOR,
  parseQuery,
  productSkuIds,
  SPLITIO_KEY,
  T_EligibilityOffer,
  useCustomerStore,
  useOrderStore,
  useProductStore,
  useSplitIO,
} from '../../entry';

function useEligibilityOffers() {
  let eligibilityId = null;
  const location = useLocation();
  const eligibilityOffers = useCustomerStore().getEligibilityOffers();
  const publicEligibilityOffers = useCustomerStore().getPublicEligibilityOffers();
  const products = useProductStore().products;
  const { order } = useOrderStore();

  const [showNewSkyBox] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX);

  const isArrowBoxAvailable =
    eligibilityOffers?.find(o => o.eligibleFor === ELIGIBILITY_FOR.Arrow_Loyalty_Discount) !== undefined;

  const arrowLoyaltyOffers = eligibilityOffers?.filter(
    o => o.eligibleFor === ELIGIBILITY_FOR.Arrow_Loyalty_Discount && o.campaign && o.campaign !== ''
  );

  const mySkyOffers = eligibilityOffers?.filter(
    o => o.eligibleFor === ELIGIBILITY_FOR.MySky_Upgrade_1 && o.campaign && o.campaign !== ''
  );

  const anyArrowLoyaltyOffers = arrowLoyaltyOffers && arrowLoyaltyOffers.length > 0;
  const anyMySkyOffers = mySkyOffers && mySkyOffers.length > 0;

  // Assume there is only one offer per VTV customer
  const vtvOffer = eligibilityOffers?.find(o => o.eligibleFor === ELIGIBILITY_FOR.VTV_Migration);

  // Get MySky offer.
  const mySkyOffer = eligibilityOffers?.find(o => o.eligibleFor === ELIGIBILITY_FOR.MySky_Upgrade_1);

  const anyOneOffFeeAlreadyInTheOrder = order?.orderProducts.find(
    op => op.product.sku === productSkuIds.arrowUpfrontBoxFee.primary
  );

  const anyMySkyAlreadyInTheOrder = order?.orderProducts.find(
    op => op.product.sku === productSkuIds.arrowBoxRecording.primary
  );

  // The intention is a soft launch, where say 100 customers will be loaded into the EE with an associated loyalty discount.
  const newSkyBoxVisible = showNewSkyBox || anyArrowLoyaltyOffers;

  /*************** START - Determine acquisiton journey ************* */
  let acquisitionType = ELIGIBILITY_ACQUISITION_TYPE.Standard_Acquisition;
  const eligibilityOfferData = publicEligibilityOffers?.find(
    o => o.eligibleFor === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone
  );

  if (eligibilityOfferData) {
    acquisitionType = ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone;
    eligibilityId = eligibilityOfferData.eligibilityID;
  }
  /*************** END - Determine acquisiton journey ************* */

  const currentUserArrowLoyaltyOfferDetails = (): T_EligibilityOffer => {
    let offerDetails: T_EligibilityOffer = {
      discount: undefined,
      originalPrice: undefined,
      discountedPrice: undefined,
      coupon: undefined,
    };

    try {
      if (anyArrowLoyaltyOffers) {
        //Assumption - Arrow loyalty discount will be always for the "arrowUpfrontBoxFee"
        let originalPrice = products.find(p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary)?.price0 ?? 0;
        let discount = Number(arrowLoyaltyOffers?.[0].promotion?.custom3);
        let percentageValue = (discount / 100) * originalPrice;
        let discountedPrice = arrowLoyaltyOffers?.[0].promotion?.custom4
          ? originalPrice - percentageValue
          : originalPrice - discount;

        offerDetails.originalPrice = originalPrice;
        offerDetails.discount = arrowLoyaltyOffers?.[0].promotion?.custom4 ? percentageValue : discount;
        offerDetails.discountedPrice = discountedPrice;
        offerDetails.coupon = arrowLoyaltyOffers?.[0].promotion.coupon;
      }
    } catch (error) {
      console.log(error);
    }

    return offerDetails;
  };

  const discountedBoxFeeIfAvailable = (): { isDiscounted: boolean; price: number; originalPrice: number } => {
    let boxPrice = products.find(p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary)?.price0 ?? 0;

    if (!anyArrowLoyaltyOffers || !isArrowBoxAvailable || anyOneOffFeeAlreadyInTheOrder)
      return {
        isDiscounted: false,
        price: boxPrice,
        originalPrice: boxPrice,
      };

    return {
      isDiscounted: true,
      price: currentUserArrowLoyaltyOfferDetails().discountedPrice ?? 0,
      originalPrice: boxPrice,
    };
  };

  return {
    isArrowBoxAvailable,
    acquisitionType,
    eligibilityId,
    eligibilityOfferData,
    anyArrowLoyaltyOffers,
    anyMySkyOffers,
    arrowLoyaltyOffers,
    mySkyOffers,
    currentUserArrowLoyaltyOfferDetails,
    discountedBoxFeeIfAvailable,
    vtvOffer,
    mySkyOffer,
    anyOneOffFeeAlreadyInTheOrder,
    anyMySkyAlreadyInTheOrder,
    newSkyBoxVisible,
    eligibilityOffers,
  };
}

export { useEligibilityOffers };
