import create from 'zustand';
import { ReduxDevTools } from '../helpers/storeLogger';
import { LocalStatePersistence } from '../helpers/storePersister';
import { Box, T_Product } from '../types';
import { localStorage } from '../helpers/localStorage';

const storageStateName = 'Boxes Store';
let getSessionStorageState = {};
try {
  // fix for app
  getSessionStorageState = JSON.parse(localStorage.getItem(storageStateName) ?? '{}');
} catch (err) {}

export enum BoxTypes {
  SUBSCRIBED = 'SUBSCRIBED',
  PENDING = 'PENDING',
  UPGRADE = 'UPGRADE',
  NEW = 'NEW',
}

//Store boxes of the current uder journey
interface BoxesStore {
  boxes: Box[];
  addBox: (box: Box) => void;
  addMultipleBoxesforAbandoned: (box: any) => void;
  removeBox: (id: string) => void;
  getUpgradingBoxFromOccurenceNo: (occNumber: string) => Box;
  clearBoxStore: () => void;
  getBoxFromOccurenceNo: (occNumber: string) => Box;
  updateBoxProducts: (products: T_Product[]) => void;
  getPrimaryBox: () => Box | undefined;
}

const [useboxesStore, boxesStoreApi] = create<BoxesStore>(
  ReduxDevTools(
    LocalStatePersistence(
      (set, get) => ({
        boxes: [],
        ...getSessionStorageState,

        addBox: box => {
          const { boxes } = get();
          set({ boxes: [...boxes, box] }, `Added ${box.name} to store`);
        },
        addMultipleBoxesforAbandoned: boxes => {
          //const { boxes } = get();
          set({ boxes: boxes }, `Added multiple boxes to store`);
        },

        removeBox: id => {
          const { boxes } = get();
          set(
            {
              boxes: boxes.filter(b => b.id !== id),
            },
            `Removed ${id} from store`
          );
        },

        getUpgradingBoxFromOccurenceNo: occNumber => {
          return get().boxes.find(b => b.upgradeFrom && b.upgradeFrom.occurrenceNumber === occNumber)!;
        },

        getBoxFromOccurenceNo: occNumber => {
          return get().boxes.find(b => b.occurence?.occurrenceNumber === occNumber)!;
        },

        getPrimaryBox: () => {
          return get().boxes.find(b => b.primary);
        },

        clearBoxStore() {
          set({
            boxes: [],
          });
        },

        /**
         * Updates the products of each box in the store from product store,
         * to show discounts and promotions calculated by the KK service
         *
         * @param products Products from store
         */
        updateBoxProducts(products: T_Product[]) {
          const { boxes } = get();
          set(
            {
              boxes: boxes.map(b => {
                b.products = products
                  .filter(p => p.occurrenceNumber === b.id && p.quantityInCart > 0)
                  .sort((a, b) => a.price0 - b.price0);
                return b;
              }),
            },
            `updateBoxProducts`
          );
        },
      }),
      storageStateName
    ),
    'Boxes details'
  )
);

export { useboxesStore, boxesStoreApi };
