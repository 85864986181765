import React from 'react';
import { Button } from '../button';
import { T_Notification } from '@sky-tv-group/shared';

interface Props {
  notification: T_Notification;
  dismissAction: (id: string) => void;
  engageAction: (id: string, url: string) => void;
}

export let Notification = ({ notification, dismissAction, engageAction }: Props) => {
  return (
    <div className="w-full mb-px bg-navy bg-opacity-70 py-4 px-8 flex flex-col sm:flex-row min-h-24">
      <div className="flex-grow text-white flex flex-col justify-center py-4">
        <div className="font-bold sky-h5 md:sky-h7">{notification.title}</div>
        <div className="sky-h5 md:sky-h7">
          {notification.text && (
            <>
              {notification.text}{' '}
              <a href={notification.tcLink} className="underline">
                {notification.tcLinkLabel}
              </a>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center justify-around pt-4 sm:pt-0">
        <Button onClick={() => dismissAction(notification.id)} variant="secondary" colorMode="dark">
          {notification.dismissBtnLabel ?? 'Close'}
        </Button>
        {notification.actionBtnUrl && (
          <Button
            onClick={() => engageAction(notification.id, notification.actionBtnUrl)}
            variant="primary"
            colorMode="dark">
            {notification.actionBtnLabel}
          </Button>
        )}
      </div>
    </div>
  );
};
