import React, { useEffect } from "react";
import { Button } from "../button";
import { Box, T_Product } from "@sky-tv-group/shared/src/types";
import { useEligibilityOffers } from "@sky-tv-group/shared/dist/helpers/eligibilityOffers/useEligibilityOffers";
import { ModalConfirmParams } from "../multiroom-modal";
import { useState } from "react";
import {createPortal} from 'react-dom';
import { SkyBoxDescription } from "../SkyBoxDescription";
import { Success } from "../icon";
import { productSkuIds, useCartContainer, useboxesStore } from "@sky-tv-group/shared";
import { Bin, BlockLoader, Loader, Modal } from "../..";
interface BoxSelectableCardProps {
  box: T_Product;
  confirm:(params:ModalConfirmParams)=> void;
  className?:String;
  isPrimary:Boolean;
  cancel?: (box:Box) => void;
  setParentLoading: (b:boolean)=>void;
  parentLoading:boolean;

}
interface BoxLineItemProps {
  box:Box;
  confirm:(params:ModalConfirmParams)=> void;
  cancel?: (box:Box) => void;

}


function BoxLineItem ({box, confirm, cancel}:BoxLineItemProps) {
  let b = box;
  const [recordingLoading ,setRecordingLoading] = useState(false);
  const [removeDeviceloading ,setRemoveDeviceLoading] = useState(false);
  let isReacordingAvailable = (box:Box | undefined)=>{
    if(box){
      let recording = box.products.find(p=>p.sku === productSkuIds.arrowBoxRecording.primary);
      return recording;
    }
    return;
      }
  return(
<>  <div className="border-t flex-1 mx-4"></div>
      <div className={`boxadd flex flex-col flex-1 p-2 gap-2`}>
        <div className="nameSection flex-1 flex  justify-between">
        <p className="text-sm font-black">{b.serviceCode === productSkuIds.arrowBox.primary ? "Sky Box multiroom":"Sky Pod multiroom"}</p>
        <p><Success ></Success></p>
        </div>
        <div className="RecordingContainer flex-1">
        <div className='recordingContainer flex flex-col justify-center w-full relative'>{
          recordingLoading ?<div className="w-20 h-6 relative"> <Loader bgColor="bg-white"></Loader></div>:
          (<> {b.serviceCode === productSkuIds.skyPod.primary ? <p className='text-sm font-bold my-1'> Recording not available.</p> :isReacordingAvailable(b) ?<p className='flex font-semibold text-sm my-1'style={{fontFamily:"MarkPro",}}><span className='mr-2 cursor-pointer' onClick={()=>{
           document.body.style.pointerEvents="none"
            setRecordingLoading(true);
           setTimeout(async ()=>{
            await  confirm({
              selected:b.serviceCode!,
              oneoff: true,
              recording: false,
              primary: false,
              editing: b,
          })
          setRecordingLoading(false);
          document.body.style.pointerEvents="all"
        },1000)
           }}><Bin /></span ><span className=' font-black mr-2'>1TB </span> My Sky recording</p> :<><p className='text-sm ' > <span className='text-blue-pure cursor-pointer font-black' onClick={()=>{
           document.body.style.pointerEvents="none"
            setRecordingLoading(true);
            setTimeout(async ()=>{
              await confirm({
                selected: b.serviceCode!,
                oneoff: true,
                recording: true,
                primary: false,
                editing: b,
            })
            setRecordingLoading(false)
           document.body.style.pointerEvents="all"
            ;},1000)}}><span className='text-xl text-blue-pure '>+</span> Add My Sky recording</span> for $15/mth</p>

</>}</>)}</div>
        </div>
        <div className="removedeviceSection flex-1">
        <Button variant='secondary' colorMode='pure' className=' relative w-full h-12 text-xs md:text-sm m-0'
            onClick={async ()=>{
            document.body.style.pointerEvents="none"
              setRemoveDeviceLoading(true);
              setTimeout(async ()=>{
            await  cancel!(b)
            setRemoveDeviceLoading(false)
            document.body.style.pointerEvents="all"
          },1000)
            }}>{removeDeviceloading ? <div className="relative h-4 w-24"><Loader bgColor="bg-white"></Loader></div>:"Remove device"}</Button>
        </div>
      </div></>
  )
}
export function BoxSelectableCard({parentLoading,setParentLoading, box, confirm, className, isPrimary, cancel,}:BoxSelectableCardProps){

  const discountedBoxFeeIfAvailable = useEligibilityOffers().discountedBoxFeeIfAvailable();
  const [showModal, setShowModal]= useState(false);

  const {boxes, removeBox }= useboxesStore();
  const multiroomBoxes = boxes.filter(b => !b.primary);
  const boxTypes = boxes.filter(b => !b.primary && b.serviceCode === box.sku);

  function modalClose(e:any){
    document.getElementById('overlay')?.removeEventListener('click', modalClose,true);

    e.stopPropagation();
  setShowModal(false);
  document.getElementById('root')!.style.opacity ="1"

  }
  let isReacordingAvailable = (box:Box | undefined)=>{
    if(box){
      let recording = box.products.find(p=>p.sku === productSkuIds.arrowBoxRecording.primary);
      return recording;
    }
    return;
      }
      const closex = (
        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" enableBackground="new 0 0 100 100" viewBox="0 0 100 125">
          <path
            fill="rgba(0, 177, 235)"
            d="M52.8 50l17.6 17.6-2.8 2.8L50 52.8 32.4 70.4l-2.8-2.8L47.2 50 29.6 32.4l2.8-2.8L50 47.2l17.6-17.6 2.8 2.8L52.8 50z"></path>
        </svg>
      );
      const [loading ,setLoading] = useState(false);
  return (
  <>
    {isPrimary &&( <div className={`${className} flex flex-col border-2 rounded-lg flex-1`} style={{scrollSnapAlign:"start"}} >
      <div className="h-40 w-full rounded-t-lg" style ={{background:  "#FBFBFB"}}>
        <img src={box.sku === productSkuIds.skyPod.primary ?  "https://static.sky.co.nz/sky/box/sky_pod_without_remote.png":box.custom1} alt="box.img" className="object-contain w-full"  style={{maxHeight:"100%"}}/>
      </div>
      <div className=" flex flex-col">
      <div className="text-xl font-black my-4 text-center" style={{fontFamily:"MarkPro", letterSpacing:"0.4px"}}>{box.name}</div>
      <div className="text-center mb-4 font-black">{box.sku=== productSkuIds.arrowBox.primary  ? "My Sky Recording available":"Recording not available"}</div>
      <div className="text-center mb-4 flex justify-center">
        <div className="text-xs sky-text-red line-through">{box.sku===productSkuIds.arrowBox.primary  ? "$200":"$100"}</div>
        <div className="text-xl font-black"><span className="text-xs relative ml-1" style={{top:"-8px"}}>$</span>0.00</div>
        <div className="text-xs self-end">/one-off charge</div>
        </div>
      <div className="mb-4 w-full px-4"><Button data-testid="Add Device"
              colorMode="pure"
              variant="primary"
              disabled={parentLoading || loading ? true:false} className={`sky-button--primary-pure w-full mx-0 ${loading ? "py-5 pt-6":''}`}
              onClick={()=>{
               document.body.style.pointerEvents="none"
                setParentLoading(true);
                setLoading(true);
               setTimeout(async ()=>{
                await confirm({
                  selected: box.sku,
                  oneoff: true,
                  recording: false,
                  primary: true,
                  editing: undefined,
                })
              setLoading(false);
            setParentLoading(false)
          document.body.style.pointerEvents="all"
        },1000);
              }}>{loading ? <div className="relative"><Loader ></Loader></div>:"Add Device"}</Button></div>
      <div className="px-8">
        <ul className="text-xs md:text-sm" style={{listStyle:"disc"}}>
          <li className="mb-2">Internet connection required. Data usage fees apply.</li>
          <li>{box.sku === productSkuIds.arrowBox.primary ? "Additional charge of $15/mth applies for My Sky recording.": "TVNZ’s live channels are not currently available on the Sky Pod TV Guide."}</li>
        </ul>
      </div>
      </div>
      <div className="underline text-blue-pure text-sm pl-4 py-4 cursor-pointer" onClick ={(e)=>{
        e.preventDefault()
        document.getElementById('root')!.style.opacity = "0.5";
        document.getElementById('overlay')?.addEventListener('click',modalClose,true)
        setShowModal(true)}}>
        See more things to know
      </div>
              {showModal && (<Modal isOpen={showModal}> <div className="modalOverlay w-full h-full rounded-none" id="overlay" style={{backgroundColor:"rgba(0, 0, 0, 0.5)"}} onClick={modalClose}><div className="modalOverlay px-10 md:px-20 z-40 flex-col pb-20"  onClick={(e)=>{e.preventDefault();e.stopPropagation(); }}><div className="flex justify-between items-center w-full mt-4 lg:mb-4"><div className="text-2xl"><span className="font-black">Things to Know</span> about the {box.sku===productSkuIds.arrowBox.primary?"new Sky Box":"Sky Pod"} :</div>
               <div className="w-14 h-14 relative leftalign"  onClick={(e)=>{e.preventDefault();
               e.stopPropagation();setShowModal(false); document.getElementById('root')!.style.opacity="1"; document.getElementById('overlay')!.removeEventListener('click',modalClose,true)}}>{closex}</div></div><SkyBoxDescription
                      isSkyPodSelected={box.sku===productSkuIds.skyPod.primary}
                      isArrowSelected={box.sku===productSkuIds.arrowBox.primary }
                      source={"WEB-ICE"}
                    />
                  </div></div></Modal>)}
    </div> )}
    {!isPrimary && (<div className="flex flex-1 flex-col border-2 rounded-lg self-start">
      <div className="multiroomContainer flex justify-between flex-1 gap-2  rounded-lg p-2">
      <div className="informationContainer w-2/3 flex flex-col gap-2 self-start">
      <div className="flex-1 font-black"><p>{ box.sku===productSkuIds.arrowBox.primary ? "new Sky Box" :box.name}</p></div>
      <div className="flex-1 flex">
      <div className="text-xs sky-text-red line-through font-bold mr-1">{box.sku===productSkuIds.arrowBox.primary  ? "$200":"$100"}</div>
        <div className="text-lg font-bold"><span className="text-xs relative ml-1" style={{top:"-8px"}}>$</span>0.00</div>
        <div className="text-xs self-end font-bold">/one-off charge</div>
      </div>
      {multiroomBoxes.length <5 && (   <div className="  flex-1 text-blue-pure font-black cursor-pointer relative" onClick={()=>{
       document.body.style.pointerEvents="none"
       setLoading(true)
       setTimeout(async ()=>{
        await confirm({
          selected: box.sku,
          oneoff: true,
          recording: false,
          primary: false,
          editing: undefined,
        })
      setLoading(false)
      document.body.style.pointerEvents="all"
      },1000)
      }}>{loading? <div className="relative w-32 h-6"><Loader bgColor="bg-white"></Loader></div> :<><span className="mr-3 text-2xl">+</span> <span>Add device</span></>}</div>)}
      </div>
      <div className="imgcontainer w-1/3 flex flex-col justify-center items-center gap-2">
        <div className="img">
        <img src={box.sku === productSkuIds.skyPod.primary ?  "https://static.sky.co.nz/sky/box/sky_pod_without_remote.png":box.custom1} alt="box.img" className="object-contain w-full"  style={{maxHeight:"100%"}}/>
        </div>
        {boxTypes.length > 0 && (<div className="numberofIndividualdevice font-black text-sm">{boxTypes.length} added</div>)}
      </div>
    </div>
    { boxTypes.map( (b,i)=> <BoxLineItem box={b} key={i} confirm={confirm} cancel={cancel}  />)}
    </div>)}
    </>
  );
}
