import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AccountForm } from './AccountForm';
import { DTHForm } from './DTHForm';
import { Confirm } from './Confirm';
import { BBForm } from './BBForm';
import { VoiceForm } from './VoiceForm';
import { ELIGIBILITY_ACQUISITION_TYPE, useEligibilityOffers } from '@sky-tv-group/shared';

export const routes = [
  {
    path: '/account-form',
    title: 'account form',
    component: AccountForm,
    exact: true,
  },
  {
    path: '/dth-form',
    title: 'DTH form',
    component: DTHForm,
    exact: true,
  },
  {
    path: '/broadband-form',
    title: 'broadband form',
    component: BBForm,
    exact: true,
  },
  {
    path: '/voice-form',
    title: 'voice form',
    component: VoiceForm,
    exact: true,
  },
  {
    path: '/confirm',
    title: 'Confirm',
    component: Confirm,
    exact: true,
  },
];

const Checkout = () => {
  let { path: parentPath } = useRouteMatch();
  const { acquisitionType } = useEligibilityOffers();

  return (
    <Switch>
      {routes
        .filter(r =>
          acquisitionType && acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone
            ? r.path !== '/broadband-form'
            : true
        )
        .map(({ path, exact, component }) => (
          <Route key={path} exact={exact} path={parentPath + path} component={component} />
        ))}
      <Redirect to={parentPath + routes[0].path} from={parentPath} />
    </Switch>
  );
};

export { Checkout };
