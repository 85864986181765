import { Service, ServiceOccurrence, ServiceStatus } from '../../../types';

/**
 *
 * @param addedCableItems
 * @param occurrence
 */
export const createCableServicesOccurrences = (
  addedCableItems: string[],
  removedCableItems: string[],
  occurrence: Pick<ServiceOccurrence, 'status' | 'occurrenceNumber'>[]
): Service[] => {
  // Create the services array.
  let cableServices: Service[] = [];

  for (let i = 0; i < occurrence.length; i++) {
    // Only push services that are from active occurences.
    if (
      occurrence[i]?.status === ServiceStatus.Active &&
      (addedCableItems.length > 0 || removedCableItems.length > 0)
    ) {
      cableServices.push({
        id: occurrence[i]?.occurrenceNumber.toString(),
        installDate: '',
        installTime: '',
        actions: [
          {
            add: [...addedCableItems],
            remove: [...removedCableItems],
          },
        ],
        details: [],
      });
    }
  }
  return cableServices;
};
