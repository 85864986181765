import create from 'zustand';
import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';
import { NullableAddressLookup } from '../types';
import { sessionStorage } from '../helpers/sessionStorage';

const sessionStorageStateName = 'Arrow Delivery Store';
const getSessionStorageState = (): Partial<ArrowDeliveryStore> => {
  const state = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}');
  return state;
};

export interface IArrowDelivery {
  contactName: string;
  mobileNumberAreaCode: string;
  mobileNumberLineNumber: string;
  email: string;
  installationAddress: string;
  useDifferentDeliveryAddress?: boolean;
  defaultDeliveryAddress: string[];
  deliveryAddress?: NullableAddressLookup;
}

interface ArrowDeliveryStore {
  details?: IArrowDelivery;
  isValid: boolean;
  setArrowDeliveryDetails: (value: IArrowDelivery) => void;
  setIsValid: (value: boolean) => void;
  clearArrowDeliveryStore: () => void;
}

let [useArrowDeliveryStore, arrowDeliveryStoreAPI] = create<ArrowDeliveryStore>(
  ReduxDevTools(
    StatePersistence(set => {
      return {
        details: undefined,
        isValid: false,
        prequalData: undefined,
        ...getSessionStorageState(),
        setArrowDeliveryDetails: (value: IArrowDelivery) => {
          set({ details: value }, 'setArrowDeliveryDetails');
        },
        setIsValid: (value: boolean) => {
          set({ isValid: value }, 'setIsValid');
        },
        clearArrowDeliveryStore: () => {
          set({ details: undefined, isValid: false }, 'clearArrowDeliveryStore');
          sessionStorage.removeItem(sessionStorageStateName);
        },
      };
    }, sessionStorageStateName),
    'Arrow Delivery Store'
  )
);

export { useArrowDeliveryStore, arrowDeliveryStoreAPI };
