import create from 'zustand';

import { ReduxDevTools } from '../helpers/storeLogger';
import { StatePersistence } from '../helpers/storePersister';
import { T_OrderProduct } from '../types';
import { sessionStorage } from '../helpers/sessionStorage';

/**
 * Manages the FreeProduct portion of Konakart.
 */
interface FreeProductStore {
  freeProduct?: T_OrderProduct;

  fromFreeProduct?: T_OrderProduct; // Free product that will become paid product

  initiallyAdded: boolean;

  setFreeProduct: (freeProduct: T_OrderProduct, initiallyAdded?: boolean) => void;

  setFromFreeProduct: (fromFreeProduct: T_OrderProduct) => void;

  clearFreeProduct: () => void;

  clearFromFreeProduct: () => void;
}

const sessionStorageStateName = 'Free Product Store';
const getSessionStorageState = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}');

const [useFreeProductStore, freeProductStoreApi] = create<FreeProductStore>(
  ReduxDevTools(
    StatePersistence(
      set => ({
        freeProduct: undefined,
        fromFreeProduct: undefined,
        initiallyAdded: false,
        ...getSessionStorageState,
        setFreeProduct: (freeProduct, initiallyAdded) => {
          set({ freeProduct: freeProduct, initiallyAdded: initiallyAdded }, 'added free product');
        },
        setFromFreeProduct: fromFreeProduct => {
          set({ fromFreeProduct: { ...fromFreeProduct, price: fromFreeProduct.product.price0 } });
        },
        clearFromFreeProduct: () => set({ fromFreeProduct: undefined }),
        clearFreeProduct: () => {
          set({ freeProduct: undefined, initiallyAdded: false }, 'removed free product');
        },
      }),
      sessionStorageStateName
    ),
    'Free Product Store'
  )
);

export { useFreeProductStore, freeProductStoreApi };
