import { AxiosInstance } from 'axios';
export class TrackerService {
  private trackerAgent: AxiosInstance;
  constructor(trackerAgent: AxiosInstance) {
    this.trackerAgent = trackerAgent;
  }

  public getTracker = async (accountNumber: string) => {
    let url = `/tracker/order-detail/${accountNumber}`;
    const data = (await this.trackerAgent.get<Tracker>(url)).data;
    return data;
  };

  public getTrackerData = async (
    accountNumber: string,
    houseNumber: string,
    lastCompletedStep: number = 0,
    errorStep: number = 0
  ) => {
    let url = `/tracker/order-detail/${accountNumber}/${houseNumber}?lastCompletedStep=${lastCompletedStep}&errorStep=${errorStep}`;
    const data = (await this.trackerAgent.get<TrackerJourney>(url)).data;
    data.numSteps = data.steps?.length ?? 0;
    return data;
  };

  public getBroadbandDetails = async (houseNumber: string) => {
    try {
      let url = `/tracker/broadband-detail?houseNumber=${houseNumber}`;
      const data = (await this.trackerAgent.get(url)).data;
      return data;
    } catch (err) {
      return '';
    }
  };
}

//types

export interface Tracker {
  product: TrackerProduct[];
}

export interface TrackerProduct {
  type: 'DATA' | '';
  details: TrackerDetails;
}

export interface TrackerDetails {
  consentRequired: 'true' | 'false';
  providerAccountId: string;
  providerUserId: string;
  lfc: string;
  lfcScopingDate: string;
  lfcScheduledDate: string;
  lfcStatus: string;
  courierUrl?: string;
  hardwareStatus: string;
}

export enum TrackerStatus {
  New = 'New',
  Placed = 'Placed',
  Scheduled = 'Scheduled',
  DateScheduledReceived = 'DateScheduledReceived',
  ServiceGiven = 'ServiceGiven',
  CloseCompleted = 'CloseCompleted',
  Rejected = 'Rejected',
  ConsentRequired = 'ConsentRequired',
  HardwareDispatched = 'HardwareDispatched',
  Error = 'Error',
  Unknown = '',
  Scoping = 'Scoping',
  FibreReady = 'FibreReady',
}

export enum TrackerStepStatus {
  Pending = 'pending',
  Completed = 'completed',
  OnHold = 'on hold',
  InProgress = 'in progress',
  NextStep = 'next step',
}

export enum TrackerStageCode {
  AC = 'AC',
  AA = 'AA',
  SC = 'SC',
  SG = 'SG',
  CR = 'CR',
  CL = 'CL',
  CC = 'CC',
}
export interface TrackerMessageData {
  code: string;
  data: any[];
}

export interface TrackerJourney {
  steps: TrackerStep[];
  timings: TrackerTiming[];
  numSteps: number;
  errorStep: number;
  journey: string;
  lfc: string;
  orderSubmissionDate: string;
  workOrderId: string;
  coreProductId: string;
}

export interface TrackerStep {
  title: string;
  index: number;
  type: TrackerStatus;
  status: TrackerStepStatus;
  activationDate?: string;
  scopingDate?: string;
  trackingNumber?: string;
  trackingUrl?: string;
  errorCode?: TrackerStageCode;
  errorDescription?: string;
  errorResolutionCode?: string;
  stageCode?: string;
  message: TrackerMessageData;
  isMobile?: boolean;
  isFirstStepInDuration?: boolean;
  isLastStepInDuration?: boolean;
}

export interface TrackerTiming {
  startStep: number;
  endStep: number;
  duration: string;
  lfc: string | undefined;
}

export interface TrackerMessageAction {
  name: string;
  target?: (step: TrackerStep) => string;
  isRescheduleAction: boolean;
}
export interface TrackerMessage {
  message: string;
  action?: TrackerMessageAction;
  data?: string[];
}

let trackerMessages: { [key: string]: TrackerMessage } = {
  OTM: { message: '', action: undefined },
  OTM00: { message: "Thanks for ordering Sky Broadband. We're onto it!", action: undefined },
  OTM01: {
    message: "Yippee! Your Sky Broadband activation date's locked and loaded on %1 between %2 and %3",
    action: {
      name: 'Reschedule',
      isRescheduleAction: true,
    },
  },
  OTM02: {
    message: "And we're off! Your order's underway and the property consent process has begun.",
    action: undefined,
  },
  OTM04: {
    message:
      "Yay! Consent’s been gained and work's underway getting the fibre from the street to your property. This can take from 3 to 6 weeks.",
    action: undefined,
  },
  OTM05: {
    message: "And we’re off! Your local fibre company's working on getting fibre to your property.",
    action: undefined,
  },
  OTM06a: {
    message:
      "Good news! Your local fibre company's finished scoping the job and your fibre install is happening on %1 between %2 and %3. Please make sure someone's at home.",
    action: {
      name: 'Reschedule',
      isRescheduleAction: true,
    },
  },
  OTM06b: {
    message:
      "Good news! Your installation date's now confirmed for %1 between %2 and %3. Please make sure someone with authority to make decisions is at home. Thanks.",
    action: {
      name: 'Reschedule',
      isRescheduleAction: true,
    },
  },
  OTM07a: {
    message:
      "Good news! Your scoping appointment with your local fibre company's locked and loaded for %1 between %2 and %3. Please make sure someone with the authority to make decisions is at home. Thanks.",
    action: {
      name: 'Reschedule',
      isRescheduleAction: true,
    },
  },
  OTM07b: {
    message:
      "Good news! Your scoping appointment with your local fibre company's locked and loaded for  %1 between %2 and %3. Please make sure someone with the authority to make decisions is at home. Thanks.",
    action: {
      name: 'Reschedule',
      isRescheduleAction: true,
    },
  },
  OTM07c: {
    message:
      "Your installation date's also confirmed for  %1 between %2 and %3. Someone will need to be home for this one too.",
    action: {
      name: 'Reschedule',
      isRescheduleAction: true,
    },
  },
  OTM08: { message: "It's done! Sky Broadband's up and running at your place. Enjoy.", action: undefined },
  OTM09: {
    message:
      "It’s done! Sky Broadband's up and running at your place and your home phone will be activated shortly. Enjoy.",
    action: undefined,
  },
  OTM10: {
    message: "Your hardware's on its way. You can track its progress at NZ Post # %1",
    action: {
      name: 'Track Order',
      target: step => step.trackingUrl || '',
      isRescheduleAction: false,
    },
  },
  OTM10b: {
    message: 'Haven’t received your hardware yet? Track it down here: %1',
    action: {
      name: 'Track Order',
      target: step => step.trackingUrl || '',
      isRescheduleAction: false,
    },
  },
  OTM12: {
    message:
      "It's all go! Your order's being reviewed by your local fibre company. Consent may be required, we’ll keep you posted.",
    action: undefined,
  },
  OTM111: {
    message:
      "Oops. Something's gone wrong with your order. Hold tight, we're on the case and will be in touch if they need more info.",
    action: undefined,
  },
};

//get correct tracker messages
export let getTrackerMessage = (step: TrackerStep) => {
  let result = {
    message: '',
    action: undefined,
    data: [],
  } as TrackerMessage;

  if (step.message.code === 'OTM') {
    return result;
  }

  let messageText = trackerMessages[step.message.code].message;

  result = {
    message: messageText,
    action: trackerMessages[step.message.code].action,
    data: step.message.data,
  };
  return result;
};
