import React from 'react';
import { AddIcon, ButtonLoader, CheckIcon, EditIcon, ErrorIcon } from './icon';
import cx from 'classnames';
import { SKY_REWARD_TEMPLATE_TYPE } from '@sky-tv-group/shared';
export interface ButtonProps {
  variant?: 'primary' | 'secondary';
  dataTestId?: string;
  colorMode?:
    | 'special'
    | 'dark'
    | 'light'
    | 'error'
    | 'warning'
    | 'success'
    | 'offer'
    | 'inverse'
    | 'midnight'
    | 'dull'
    | 'disabled'
    | 'pure'
    | 'pink'
    | 'light-with-white-hover';
  icon?: 'add' | 'check' | 'error' | 'addBluePure' | 'edit' | 'editBluePure' | 'loading';
  small?: boolean;
  iconOnLeft?: boolean;
  additionalClasses?: string;
  templateType?: SKY_REWARD_TEMPLATE_TYPE | null;
  iconStyle?: boolean;
}
const iconMap = {
  add: <AddIcon />,
  addBluePure: <AddIcon color={'#0057FF'} />,
  check: <CheckIcon />,
  edit: <EditIcon />,
  editBluePure: <EditIcon color={'#0057FF'} />,
  loading: <ButtonLoader />,
  error: <ErrorIcon />,
};

export const Button = ({
  disabled,
  dataTestId,
  className = '',
  variant = 'primary',
  colorMode = 'light',
  icon,
  style,
  children,
  small,
  iconOnLeft,
  additionalClasses,
  templateType,
  iconStyle = false,
  ...rest
}: ButtonProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  //check for rewards offer in children then add an inline style to the button
  const isRewardsUpgradeButton =
    (templateType === SKY_REWARD_TEMPLATE_TYPE.REWARD ||
      templateType === SKY_REWARD_TEMPLATE_TYPE.NON_REWARD ||
      templateType === SKY_REWARD_TEMPLATE_TYPE.ROI) &&
    !disabled;

  if (isRewardsUpgradeButton) {
    style = { ...style, background: 'linear-gradient(rgb(59, 75, 161), rgb(129, 45, 205))' };
  }

  const cn = cx(`sky-button--${variant}-${colorMode}`, { small: small });
  const iconComp = icon ? iconMap[icon] : null;
  const iconAlignment = iconOnLeft ? 'justify-start mr-2' : !iconOnLeft && iconStyle ? 'justify-end  ml-2' : 'justify-end mb-1' ;

  return (
    <button
      data-testid={dataTestId}
      disabled={disabled}
      className={`sky-button ${isRewardsUpgradeButton ? '' : cn} ${className} ${additionalClasses} ${className}`}
      style={{ ...style }}
      {...rest}>
      <div className="flex justify-between items-center">
        {iconOnLeft && <div className={`flex-1 flex ${iconAlignment}`}>{iconComp}</div>}
        <div className="flex-1"></div>
        <div className="flex-1">{children}</div>
        {!iconOnLeft && <div className={`flex-1 flex ${iconAlignment}`}>{iconComp}</div>}
      </div>
    </button>
  );
};
