import create from 'zustand';

import { ReduxDevTools } from '../helpers/storeLogger';
import { KonakartService } from '../services/konakart';
import { LocalStatePersistence } from '../helpers/storePersister';
import { localStorage } from '../helpers/localStorage';
import { T_Offer, T_Profile } from '../types';
import { getCustomerId } from './customerStoreHelper';
import { REACT_APP_KK_CUSTOMER_ID_EXPIRE_MS } from '../config';
import { boxesStoreApi } from './boxesStore';
import { orderStoreApi } from './orderStore';
import { EligibilityOffer, Offer } from '@sky-tv-group/graphql';
import { couponStoreApi } from './couponStore';

// the store for managing products in the cart
interface CustomerStore {
  /* This is the customerId. */
  kk: string;

  kkExpireAt?: number;
  sessionId: string;
  isActiveGASession: boolean;
  broadbandBackUrl: string;
  isCustomerQualifiedForBroadband: boolean;
  isAbandonedCartSession: boolean;
  isStaffAccount: boolean;
  hasPendingCableWorkOrder: boolean;
  hasPendingVTVWorkOrder: boolean;
  address: string;
  addressId?: string;
  eligibilityOffers: EligibilityOffer[];
  publicEligibilityOffers: T_Offer[];
  getTempCustomerId: (kkService: KonakartService, initialId?: string, abandonedcart?: boolean) => Promise<string>;
  getCustomerIdFromProfile: (profile: T_Profile) => string;
  getEligibilityOffers: () => EligibilityOffer[] | undefined;
  getPublicEligibilityOffers: () => T_Offer[] | undefined;
  setExistingCustomerId: (customerId: string) => void;
  setBroadbandBackUrl: (url: string) => void;
  setSessionId: (newSessionId: string) => void;
  setActiveGASession: (sessionActive: boolean) => void;
  setCustomerQualifiedForBroadband: (isQualified: boolean) => void;
  setIsAbandonedCartSession: (isAbandoned: boolean) => void;
  setIsStaffAccount: (isStaff: boolean) => void;
  setHasPendingCableWorkOrder: (hasPendingCableWorkOrder: boolean) => void;
  setHasPendingVTVWorkOrder: (hasPendingVTVWorkOrder: boolean) => void;
  setAddress: (address: string) => void;
  setAddressId: (addressId: string) => void;
  setEligibilityOffers: (offers: EligibilityOffer[]) => void;
  setPublicEligibilityOffers: (offers: T_Offer[]) => void;
}

const storageStateName = 'Customer Store';
let getStorageState = {} as any;
try {
  // fix for app
  getStorageState = JSON.parse(localStorage.getItem(storageStateName) ?? '{}');
} catch (err) {}

/**
 * Responsible for holding the customerId that is tied to konakart. Adds to session storage.
 * IMPORTANT: Does not do any update to konakart. That should be done via order store.
 */
const [useCustomerStore, customerStoreApi] = create<CustomerStore>(
  ReduxDevTools(
    LocalStatePersistence(
      (set, get) => ({
        kk: '',
        bbSpaCartUrl: '',
        sessionId: '',
        isActiveGASession: true,
        isCustomerQualifiedForBroadband: false,
        isAbandonedCartSession: false,
        isStaffAccount: false,
        hasPendingCableWorkOrder: false,
        hasPendingVTVWorkOrder: false,
        address: '',
        addressId: '',
        ...getStorageState,
        getTempCustomerId: async (kkService, initialId, abandonedcart) => {
          let { kk, kkExpireAt } = get();
          // Clearing box store, order store and setting a new customer id if:
          // - initial customer id is provided, initial id is present when coming from wizard
          // - no existing customer id in storage
          // - session expires
          if (initialId || !kk || (kkExpireAt ?? Infinity) < Date.now() || abandonedcart) {
            // Clearing all persistent caches for showing boxes and upgrades
            // on customer id change
            boxesStoreApi.setState({ boxes: [] });
            orderStoreApi.setState({ orderRequest: undefined });
            if (abandonedcart) {
              couponStoreApi.setState({ coupons: [] });
            }
            const customerId = initialId || (await kkService.getTempCustomerId()).r;
            set(
              { kk: customerId, kkExpireAt: REACT_APP_KK_CUSTOMER_ID_EXPIRE_MS + Date.now() },
              'get temporary customer id'
            );
            return customerId;
          } else {
            return kk;
          }
        },
        getCustomerIdFromProfile: profile => {
          return getCustomerId(profile);
        },
        setExistingCustomerId: newCustomerId => {
          set({ kk: newCustomerId }, 'set existing customer id');
        },
        setBroadbandBackUrl: url => {
          set({ broadbandBackUrl: url });
        },
        setSessionId: newSessionId => {
          set({ sessionId: newSessionId });
        },
        setActiveGASession: activeSession => {
          set({ isActiveGASession: activeSession });
        },
        setCustomerQualifiedForBroadband: isQualified => {
          set({ isCustomerQualifiedForBroadband: isQualified });
        },
        setIsAbandonedCartSession: isAbandoned => {
          set({ isAbandonedCartSession: isAbandoned });
        },
        setIsStaffAccount: isStaff => {
          set({ isStaffAccount: isStaff });
        },
        setHasPendingCableWorkOrder: hasPendingCableWorkOrder => {
          set({ hasPendingCableWorkOrder: hasPendingCableWorkOrder });
        },
        setHasPendingVTVWorkOrder: hasPendingVTVWorkOrder => {
          set({ hasPendingVTVWorkOrder: hasPendingVTVWorkOrder });
        },
        setAddress: address => {
          set({ address: address }, 'set address');
        },
        setAddressId: addressId => {
          set({ addressId: addressId }, 'set addressId');
        },
        setEligibilityOffers: eligibilityOffers => {
          set({ eligibilityOffers: eligibilityOffers }, 'set eligibility offers');
        },
        setPublicEligibilityOffers: publicEligibilityOffers => {
          set({ publicEligibilityOffers: publicEligibilityOffers }, 'set public eligibility offers');
        },
        getEligibilityOffers: () => {
          return get().eligibilityOffers;
        },
        getPublicEligibilityOffers: () => {
          return get().publicEligibilityOffers;
        },
      }),
      storageStateName
    ),
    storageStateName
  )
);

export { useCustomerStore, customerStoreApi };
