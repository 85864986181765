import React from 'react';
import { Notification } from './Notification';
import { useNotification } from './useNotification';

interface Props {
  notificationsEndpoint: string;
  isSignedIn?: boolean;
  domain: string;
  specialHandler?: string;
}

export let NotificationManager = ({ notificationsEndpoint, isSignedIn = false, domain, specialHandler }: Props) => {
  let { notifications, dismissNotification, engageNotification } = useNotification({
    notificationsEndpoint,
    isSignedIn,
    domain,
    specialHandler,
  });

  return (
    <div className="w-full fixed z-50">
      {notifications.map(notif => {
        return (
          <Notification
            key={notif.id}
            notification={notif}
            dismissAction={dismissNotification}
            engageAction={engageNotification}
          />
        );
      })}
    </div>
  );
};
