import React from 'react';

export const VTVStandaloneHero = ({ dartPreorderEnabled }: { dartPreorderEnabled: boolean }) => {
  const backgroundImage = 'https://static.sky.co.nz/sky/box/VTV-standalone-hero-image.png';
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className="flex relative rounded-md bg-cover bg-bottom bg-no-repeat mx-1 mt-8 lg:mt-0 mb-8 min-h-280px sm:min-h-200px">
        <div className="flex justify-center absolute w-full flex-col items-center mt-8 px-8 px-4 xl:px-24 text-center text-white">
          <h2 className="sky-h2-black sm:sky-h2-black lg:sky-h3-black">
            {dartPreorderEnabled ? 'Pre-Order today to keep enjoying Sky' : ' Order today to keep enjoying Sky'}
          </h2>
          <p className="sky-h6-reg md:sky-h6-reg pt-8 md:pt-3">
            Plus no fixed term contract required to receive a special offer of one month on us on your Sky TV
            subscription.
          </p>
        </div>
        <div className="flex flex-col absolute w-full items-center justify-center bottom-2">
          <p className="sm:sky-sub md:sky-sub text-white">
            <a target="_blank" rel="noopener noreferrer" href={'https://www.sky.co.nz/vtv-1-month-offer-terms'}>
              T&Cs apply
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
