import React from 'react';
import cx from 'classnames';
import { Field, Form } from 'react-jeff';
import { DateRange } from '@sky-tv-group/shared';
import { ValidationDatePicker } from '../../validation/dateValidated';

interface Props {
  activationDateField: Field<Date | null, string>;
  activationDates: DateRange;
  form: Form<string>;
  hidden: boolean;
  deliveryIsHidden?: boolean;
}

const BroadbandActivationDate = ({ activationDateField, activationDates, form, hidden, deliveryIsHidden }: Props) => {
  const classes = cx('pb-4', { hidden }, 'pb-10', { deliveryIsHidden });

  return (
    <div className={classes}>
      <div className="pb-5 w-full md:w-1/2">
        <label className="block sky-h7-reg mb-2 text-midnight">Ideal activation date</label>
        <ValidationDatePicker
          fieldValidation={activationDateField}
          placeholderText="dd/mm/yyyy"
          id="broadband-activation-date"
          formValidation={form}
          className="sky-input sky-input--text sky-h7-reg w-full rounded"
          minDate={activationDates.earliest}
          maxDate={activationDates.latest}
          selected={activationDateField?.value ?? activationDates.earliest}
          excludeDates={activationDates.exclude}
        />
      </div>
      <p className="sky-h7-reg text-midnight w-full">
        We will do our best to get you connected on this date. We will keep you updated if there's any change.
      </p>
    </div>
  );
};

export { BroadbandActivationDate };
