import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Simple functional component that leverages react-router-dom to scroll top on Route Change
 * NOTE: This cannot be added to shared
 * -- useLocation() doesnt seem to work when imported from '@sky-tv-group/shared' and only works when in the same package
 * @param param0
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export { ScrollToTop };
