import React from 'react';
import { Field } from 'react-jeff';
import { CheckboxInput } from '../../input';

interface ConditionsCheckboxProps {
  onChange: (value: boolean) => void;
  field?: Field<boolean, string>;
  id?: string;
  isAbandoned?: boolean;
  isChecked?:boolean
}

const ConditionsCheckbox = ({ children, field, id, onChange,isAbandoned,isChecked }: React.PropsWithChildren<ConditionsCheckboxProps>) => {
  return (
    <div className={`w-full flex  md:items-start pb-2 ${isAbandoned ? 'items-start' : 'items-center'}`}>
      <div className="">
        <CheckboxInput
          {...field?.props}
          id={id}
          checked={isChecked ?? field?.props.value}
          onChange={onChange}
          className={`rounded ${isAbandoned ? 'mt-1' : ''} `}
        />
      </div>
      <p className="sky-h6-reg pl-4 py-1">{children}</p>
    </div>
  );
};

export { ConditionsCheckbox };
