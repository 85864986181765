import React, { useState, useEffect } from 'react';
import { Accordion } from './accordion';
import { faqService } from '../../services';
import { article } from '@sky-tv-group/shared/src/types';

interface FaqProps {
  filter: string;
  title: string;
}

export const Faq = ({ filter, title }: FaqProps) => {
  const [faqs, setFaqs] = useState<article[]>();
  const [hideFaq, setHideFaq] = useState(false);

  let faqsData = faqs?.filter((faq) => {
    return faq.title !== "How can I test my speed?" && faq.title !== "Why’s my internet in the slow lane?" && faq.title !== "Let’s get you Set Up" && faq.title !== "Home Phone Set Up"
  });

  useEffect(() => {
    const GetFaqs = async () => {
      let _faqContent = await faqService.getArticles('');

      if (_faqContent === undefined || _faqContent.articles === undefined) {
        setHideFaq(true);
      } else {
        let _filteredArticles = _faqContent.articles.filter(article =>
          article.categoryGroups.find(ag => ag.groupName === filter)
        );

        if (_filteredArticles.length <= 0) {
          setHideFaq(true);
        } else {
          setFaqs(_filteredArticles);
        }
      }
    };
    GetFaqs();
  }, [filter]);

  return (
    <div className="sky-bg-white">
      {!hideFaq && (
        <div className="md:container mx-auto ">
          <h4 className="text-center sky-h2 md:block py-8 font-bold">{title}</h4>
          <div className="m-auto">
            {faqsData?.map(faq => (
              <Accordion key={faq.title} title={faq.title} summary={faq.summary} url={faq.url} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
