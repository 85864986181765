import React from 'react';
import Tippy from '@tippyjs/react';
import cx from 'classnames';
import { Field, Form } from 'react-jeff';
import { numberFilter, ServiceProvider } from '@sky-tv-group/shared';
import { SelectElement } from '../../validation/selectValidated';
import { ValidatedTextInput } from '../../validation/inputValidated';
import { blueIcon } from '../../icon';

interface Props {
  form: Form<string>;
  providers: ServiceProvider[];
  isFibreSwap: boolean;
  isNotChorus: boolean;
  hidden: boolean;
  currentServiceProviderField: Field<string, string>;
  otherServiceProviderField: Field<string, string>;
  providerAccountNumberField: Field<string, string>;
  hasSkyBroadband?: boolean;
}

const BroadbandProviderDetails = ({
  form,
  providers,
  isFibreSwap,
  isNotChorus,
  hidden,
  currentServiceProviderField,
  providerAccountNumberField,
  hasSkyBroadband,
}: Props) => {
  const classes = cx('w-full', { hidden });
  return (
    <div className={classes}>
      {isFibreSwap && !hasSkyBroadband && (
        <div className="flex flex-col md:flex-row justify-between w-full">
          <div className="pb-4 w-full md:w-1/2">
            <label className="block sky-h7-reg mb-2 text-midnight">Select your current broadband provider</label>
            <SelectElement
              id="broadband-current-provider-full"
              className="sky-input sky-input--text sky-h7-reg w-full"
              fieldValidation={currentServiceProviderField}
              greyTextWhenEmpty={false}
              formValidation={form}
              onChange={currentServiceProviderField.props.onChange}
              value={currentServiceProviderField.value}>
              <option disabled={true} value="" key="">
                Select your broadband provider
              </option>
              {providers.map((item, idx) => (
                <option
                  key={item.code === '-' ? 'sep' + idx : item.code}
                  value={item.code}
                  disabled={item.code === '-'}>
                  {item.code === '-' ? '--------------------------------------------------' : item.name}
                </option>
              ))}
            </SelectElement>
          </div>
          {/* Only show other textbox for Other service provider. }
          {fields.currentServiceProviderField.value === 'Other' && (
            <div className="pb-4 w-full md:w-1/2 md:pr-2">
              <label className="block sky-label md:sky-label mb-2">Enter your current provider’s name</label>
              <ValidatedTextInput
                className="w-full"
                type="text"
                fieldValidation={fields.otherServiceProviderField}
                formValidation={form}
                id="broadband-other-service-provider"
                placeholder="Enter your provider name"
                onChange={fields.otherServiceProviderField.props.onChange}
              />
            </div>
          )*/}
        </div>
      )}

      {isNotChorus && isFibreSwap && !hasSkyBroadband && (
        <div className="pb-4 w-full md:w-1/2 md:pr-2">
          <div className="flex">
            <label className="block sky-h7-reg text-midnight mb-2">What’s your current account number?</label>
            <Tippy content="So we can easily swap you over please supply your current provider’s account number.">
              <div className="p-1">{blueIcon}</div>
            </Tippy>
          </div>
          <ValidatedTextInput
            id="broadband-account-number"
            className="sky-h7-reg w-full mt-1"
            fieldValidation={providerAccountNumberField}
            formValidation={form}
            onChange={numberFilter(providerAccountNumberField.props.onChange)}></ValidatedTextInput>
        </div>
      )}
    </div>
  );
};

export { BroadbandProviderDetails };
