import React from 'react';

interface MarketingRectangleProps {
  mRectTag?: string;
  mRectColor?: string;
  top?: number;
  left?: number;
  mRectTagClasses?: string;
  containerClasses?: string;
  containerStyle?: React.CSSProperties;
}

export const MarketingRectangle = ({
  mRectColor = 'linear-gradient(270deg, #F94CB1 -40.79%, #EF45B4 -34.66%, #9100D4 32.74%, #344AA2 112.41%)',
  mRectTag,
  top = -16,
  left = 20,
  mRectTagClasses = 'text-white text-center sky-h5-bold py-2 pl-3 pr-4',
  containerClasses,
  containerStyle,
}: MarketingRectangleProps) => {
  return (
    <div
      className={'z-10 absolute overflow-hidden ' + containerClasses ?? ''}
      style={{
        borderRadius: '4px',
        transform: 'skew(-10deg)',
        transformOrigin: '0 0',
        marginTop: `${top}px`,
        marginLeft: `${left}px`,
        ...containerStyle,
      }}>
      <div
        className={mRectTagClasses}
        style={{
          wordBreak: 'keep-all',
          whiteSpace: 'nowrap',
          background: mRectColor,
          borderRadius: '4px',
          transform: 'skew(10deg)',
          transformOrigin: '0 0',
        }}>
        {mRectTag}
      </div>
    </div>
  );
};
