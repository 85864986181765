import { T_Product } from '../../../types';
import { removeProductRemovesOffer, removeOfferRemovesMultipleProducts } from '../helper/text';

export const onlyOfferRemoval = (productName?: string, couponProducts?: Pick<T_Product, 'name'>[]): string => {
  const isSingleItemRemoval = productName && !couponProducts;
  const isMultipleItemRemoval = !productName && couponProducts;

  // remove of a product that has an offer associated with it
  if (isSingleItemRemoval) {
    return removeProductRemovesOffer(productName!);
  }

  // remove of a offer and all its associated products
  if (isMultipleItemRemoval) {
    return removeOfferRemovesMultipleProducts(couponProducts!);
  }

  // edge case - shouldn't be hit
  return '';
};
